import support from './i18-support-locale-ru';

const locale = {
  easyInstallWizard: {
    step1NextButton: 'Создать',
    step2NextButton: 'Создать',
    step3NextButton: 'Создать',
    step4NextButton: 'Добавить',
    step5NextButton: 'Создать',
    badgeSidebarInProgress: 'в процессе',
    whereIsLookingForThisSettings: 'Где в дальнейшем искать эти настройки',
    easyInstallButtonInSidebar: 'Простая установка',
    headerCaption: 'Создание сети Wi-Fi с порталом',
    step1Caption: 'Создание Wi-Fi сети',
    step1Header: 'Шаг 1: Создание Wi-Fi сети',
    step1SuiteRequiredsError: 'Поле Тип шифрования обязательно для заполнения',
    step1WhereIsLookingForThisSettings: 'НМС -> Сети Wi-Fi ',
    step2Caption: 'Создание Страницы',
    step2Header: 'Шаг 2: Создание Страницы',
    step2FieldPageName: 'Имя страницы',
    step2WhereIsLookingForThisSettings: 'Портал -> Конфигурация -> Страницы ',
    step3Caption: 'Создание Правила Редиректа',
    step3Header: 'Шаг 3: Создание Правила Редиректа',
    step3WhereIsLookingForThisSettings: 'НМС -> Гостевой контроль ',
    step3PrevStepPageURL: 'URL созданной на предыдущем шаге страницы портала',
    step4Caption: 'Добавление ранее созданной сети Wi-Fi на необходимые точки доступа',
    step4Header: 'Шаг 4: Добавление сети Wi-Fi на необходимые точки доступа',
    step4WhereIsLookingForThisSettings: 'НМС -> Точки',
    step4Hint: 'Выберите точки, для добавления на них ранее созданной сети Wi-Fi',
    step4CPESPlaceholder: 'Выберите одну или несколько точек доступа',
    step4NOCPEs: 'Нет точек доступа',
    step5Caption: 'Создание профиля',
    step5Header: 'Шаг 5: Создание профиля',
    step5WhereIsLookingForThisSettings: 'Портал -> Конфигурация -> Профили'
  },
  NewVersionChecker: {
    modalHeader: 'Версия ПО обновлена',
    modalText:
      'Доступно обновление. Пожалуйста, нажмите «Обновить» ниже для корректной дальнейшей работы. Вы также можете принять эти обновления, обновив страницу в браузере',
    modalReloadButton: 'Обновить'
  },
  paywallForAuthFreemium: {
    contactToUsCaption: 'Если вы хотите включить данный раздел — пишите нам'
  },
  GuestControl: {
    walledGardenIPTooltip:
      'НЕОБХОДИМО чтобы все IP адреса доменного имени с редиректом (URL платформы) находились в Списке IP, доступных неавторизованным пользователям ',
    wizard2Caption: 'Подтвердите создание нового Правила Редиректа',
    wizard1Caption: 'Заполните базовые параметры для Правила Редиректа',
    // walledGarden: 'Список IP доступных неавторизованным пользователям',
    walledGarden: 'Walled garden',
    deleteRedirectRuleModalTitle: 'Удалить Правило Редиректа',
    deleteRedirectRuleModalText: 'Подтвердить удаление',
    addRule: 'Создать Правило Редиректа',
    allCpes: 'Все точки доступа',
    allWlans: 'Все Wi-Fi сети',
    create: 'Создание Правила Редиректа',
    domain: 'Домен',
    domainCaption:
      'Домен для перенаправления. Адрес должен указывать туда, где развёрнут Wimark Portal относительно клиента. Если это общедоступный домен, в Walled garden достаточно указать только IP адрес. Если нет, в Walled garden также необходимо указать домен. Домен должен быть указан без протокола.',
    domainName: 'Доменное имя',
    enterName: 'Введите имя для Правила',
    expertHide: 'Выключить экспертный режим',
    expertShow: 'Включить экспертный режим',
    howTo: 'как заполнить?',
    ipAddress: 'IP Адрес',
    macList: 'Белый список MAC',
    // noMasquerade: 'Без маскарадинга',
    noMasquerade: 'Без NAT/PAT',
    redirectIP: 'IP Редиректа',
    redirectURL: 'URL Портала Авторизации, для перенаправления пользователей',
    redirectURLCaption: 'Адрес, на который клиент перенаправляется после успешной авторизации',
    redirectUrl: 'Адрес Редиректа',
    ruleSettings: 'Обязательные параметры',
    switchURL: 'URL платформы',
    switchURLCaption:
      'Адрес платформы (IP или доменное имя), на который перенаправляется клиент. Должен содержать только имя хоста (например, "www.example.com"). Протокол, порт и URL-путь указывать не нужно.',
    tableHeading: 'Правила редиректа для Портала',
    targetingLocation: 'Локация для таргетинга',
    urlList: 'Белый список URL',
    wlanCaption: 'Если правило будет работать на Wimark Appliance (на проводном интерфейсе), ID должен быть явно задан',
    notificationRedirectUpdatedSuccessfully: 'Редирект успешно обновлен',
    notificationRedirectCreatedSuccessfully: 'Редирект успешно создан',
    notificationRedirectDeletedSuccessfully: 'Редирект успешно обновлен',
    notificationRedirectSuccessfull: 'Успешно'
  },
  RRM: {
    deleteRRMHeader: 'Удалить RRM группу',
    showAll: 'показать все',
    modalCPEs: 'Точки доступа',
    modalNOCPEs: 'Нет ТД',
    algorithmGreed: 'Интеллектуальный',
    algorithmBlind: 'Базовый',
    modalGroupTimeoutPlaceHolder: 'Введите таймаут',
    modalGroupNamePlaceHolder: 'Введите имя группы',
    modalTitle: 'Добавить новую группу',
    tableRRMSeconds: 'сек',
    tableRRMCPEsTooltip: 'Показать ТД',
    tableRRMCPEs: 'ТД',
    multipleEditSelectGroup: 'Выберите группу',
    multipleEdit: 'Мультиредактирование',
    tableLoad: 'Загрузить',
    tableOutOf: 'ТД из',
    tableView: '',
    tableItemsOf: 'из',
    tableSelected: 'выбрано',
    Movetoselectedgroup: 'Переместить в выбранную RRM группу',
    RemovefromRRMgroups: 'Удалить из RRM групп',
    algo: 'Алгоритм',
    allGroups: 'Все группы',
    group: 'Группа',
    groups: 'Группы',
    noGroup: 'Без группы',
    power: 'Мощность',
    timeout: 'Таймаут (в сек.)',
    notificationRRMGroupCreated: 'RRM группа успешно создана',
    notificationRRMGroupUpdated: 'RRM группа успешно обновлена',
    notificationRRMGroupDeleted: 'RRM группа успешно удалена',
    notificationRRMGroupForceCall: 'RRM успешно вызван для данной группы',
    rrmForceCallButtonInModal: 'Принудительный вызов RRM',
    rrmForceCallButtonInModalDisabledHint: 'Перед принудительным вызовом RRM необходимо сохранить настройки группы',
    forGroup: 'Для группы',
    alertAction: 'Настроить радио интерфейсы.',
    alertText: 'Должно быть включено сканирование эфира на радио интерфейсах.'
  },
  accessControl: {
    accessControl: 'Настройки доступа',
    firewall: 'Брандмауэр',
    wanBlockAccess: 'Запрет доступа через WAN'
  },
  adsprofile: {
    videoLoadingErrorReload: 'обновить',
    videoLoadingError: 'Проблема с загрузкой видео',
    changeRedirectButtonColorAlert: 'Чтобы изменения вступили в силу нужно нажать кнопку Сохранить',
    changePollsColorThemeAlert: 'Чтобы изменения вступили в силу нужно нажать кнопку Сохранить',
    activeAd: 'Идёт показ',
    add: 'Добавить Рекламу или Опрос',
    addVariant: 'Добавить вариант ответа',
    addanswer: 'Введите вариант ответа',
    additionalSettings: 'Дополнительные настройки',
    ads: 'Реклама или опрос',
    adsStats: 'Статистика просмотров по всей рекламе',
    adsStatsAllAuthorizationTypes: 'Просмотры со всеми типами авторизации',
    adsStatsAllAuthorizationTypesHeader: 'Статистика просмотров и переходов по всем типам авторизации',
    adsStatsAllAuthorizationTypesShort: 'Просмотры',
    adsStatsAllFollowsAuthorizationTypesShort: 'Переходы',
    adsStatsClose: 'к Рекламе',
    adsStatsFor: 'для',
    adsStatsSepareteAuthorizationTypesHeader: 'Статистика просмотров по типам авторизации',
    adsStatsSepareteFacebook: 'Facebook',
    adsStatsSepareteFree: 'Бесплатный',
    adsStatsSepareteInstagram: 'instagram',
    adsStatsSepareteSponsor: 'Спонсорский',
    adsStatsSepareteStaff: 'Служебный',
    adsStatsSepareteVk: 'Vk',
    adsStatsSepareteVoucher: 'Ваучер',
    adsStatsbyId: 'Статистика просмотров',
    adtype: 'Тип рекламы или опроса',
    dayOfWeek: 'Дни недели',
    dayOfWeekCaption: 'Дни недели для показа',
    dayOfWeekFriday: 'Пятница',
    dayOfWeekMonday: 'Понедельник',
    dayOfWeekSaturday: 'Суббота',
    dayOfWeekSunday: 'Воскресенье',
    dayOfWeekThursday: 'Четверг',
    dayOfWeekTuesday: 'Вторник',
    dayOfWeekWednesday: 'Среда',
    doneAd: 'Показ окончен',
    duration: 'Время проигрывания рекламы или опроса (в секундах)',
    edit: 'Редактирование Рекламы или Опроса',
    enableSex: 'Спрашивать пол пользователя',
    enableBirthDate: 'Спрашивать дату рождения пользователя',
    enterRedirectButtonColor: 'Введите цвет кнопки',
    enterRedirectButtonText: 'Введите текст и цвет кнопки редиректа',
    enterRedirectButtonTextPlaceholder: 'Подробнее',
    fileLimitation:
      'Поддерживаются следующие форматы изображений: .jpg, .png. Максимальный размер файла: {KB} килобайт.',
    flash: 'флеш',
    hasDesktop: 'Загрузить версию рекламы или опроса для широких экранов',
    hasPollPictures: 'Добавить изображения к вариантам ответа',
    hasTheme: 'Установить особую цветовую тему для опроса',
    iframe: 'iframe',
    image: 'изображение',
    new: 'Новая Реклама или Опрос',
    noColor: 'Нет цвета',
    numberOfVisits: 'Количество посещений',
    numberOfVisitsCaption:
      'Можно настроить показ новым клиентам (равно 0), заходящим 10 раз (равно 10), заходившим больше 5 раз (больше 5) и т.д.',
    numberOfVisitsHeader: 'Настройка показов по количеству посещений',
    numberOfVisitsSign: 'Условие',
    numberOfVisitsSignequal: 'Равно',
    numberOfVisitsSigngreater: 'Больше',
    numberOfVisitsSignless: 'Меньше',
    os: 'Операционные системы',
    osCaption: 'Список операционных систем, для которых будет показываться реклама или опрос. Например iOS или Android',
    osFromList: 'Выберете Операционную систему из списка',
    osInput: 'или введите',
    osInstruction:
      'Таргетирование по ОС. Если не указана ни одна операционная система, то реклама или опрос показывается на устройствах со всеми операционными системами',
    osNoSpecified: 'Операционные системы не выбраны',
    osSpecified: 'Выбранные операционные системы',
    plannedAd: 'Запланировано',
    platform: 'Площадка',
    platformCaption: 'Площадка для показа',
    platformDesktop: 'ПК',
    platformMobile: 'Мобильные',
    platformTablet: 'Планшеты',
    poll: 'опрос',
    pollImagesCaption: 'Вариант ответа должен содержать и текст, и изображение.',
    pollQualityVariants: 'Количество вариантов ответа (количество звездочек)',
    poll_quality: 'опрос оценки качества',
    poll_user_data: 'запрос данных пользователя',
    PollSelfVariant: 'Свой ответ',
    pollSelfVariantPlaceholder: 'Напишите свой вариант',
    pollUserData: {
      getInfo: 'Какие данные о пользователе собирать',
      sex: 'Пол',
      date_of_birth: 'День Рождения',
      dateOfBirth: 'Введите дату рождения',
      selectGender: 'Выберите пол',
      genders: {
        female: 'Женский',
        male: 'Мужской'
      }
    },
    pollResults: 'Результаты опроса',
    pollvariants: 'Варианты ответа',
    poolQualityUpdateVariants: 'Применить',
    poolQualityVariantsValidate: 'Количество вариантов ответа должно быть больше 1 и меньше 5',
    preview: 'Предпросмотр',
    previewDesktop: 'Десктоп шаблон',
    previewMobile: 'Мобильный шаблон',
    priority: 'Приоритет',
    priorityCaption: 'От 0 (наименьший) до 100 (наибольший)',
    question: 'Вопрос',
    redirectURL: 'URL редиректа при клике',
    redirectURLCaption: 'Оставьте пустым, если редирект не нужен',
    resolutionCaption:
      'По умолчанию файл выше будет использован для как мобильных устройств, так и для ноутбуков / стационарных компьютеров.',
    sameAs: 'Такие же настройки',
    schedule: 'Расписание',
    scheduleCaption: 'Период активности рекламы',
    scheduleDate: 'По состоянию начиная с:',
    scheduleEmpty: 'Активная реклама на указанную дату отсутствует',
    self_variant: 'Открытый вопрос (возможен свой вариант ответа)',
    setSchedule: 'Пожалуйста, укажите расписание',
    skip: 'Пропуск Рекламы',
    skipafter: 'Время пропуска рекламы (опроса) (в секундах)',
    statistics: 'Статистика',
    statisticsDuration: 'Длительность просмотров',
    statisticsMeanDuration: 'Средняя длительность просмотра',
    statisticsPoll: 'Результаты опроса',
    statisticsShows: 'Статистика показов',
    statisticsSkipped: 'Просмотров с пропуском',
    statisticsTotal: 'Всего просмотров',
    switchPollWarning:
      'Это действие приведет к удалению сохранённых вариантов ответа. Пожалуйста, подтвердите их удаление.',
    targeting: 'Настройки таргетинга',
    textOnly: 'Только текст',
    timeOfDay: 'Время суток',
    timeOfDayAfternoon: 'День',
    timeOfDayCaption: 'Время суток для показа',
    timeOfDayEvening: 'Вечер',
    timeOfDayMorning: 'Утро',
    timeOfDayNight: 'Ночь',
    url: 'Ссылка',
    vendor: 'Производители',
    vendorCaption: 'Список производителей, для которых будет показываться реклама или опрос. Например Apple или Intel',
    vendorNoSpecified: 'Производители не выбраны',
    vendorSpecified: 'Выбранные производители',
    vendorinstruction:
      'Таргетирование по производителям. Если не указано ни одного производителя, то реклама или опрос показывается на устройствах всех производителей',
    video: 'видео',
    views: 'Кол-во показов',
    viewsCaption: '0 для бесконечного количества просмотров',
    warnUsage: 'Пожалуйста, обратите внимание, что удалении Рекламы, которая используется Порталом, приведёт к {0}.',
    warnUsageAccent: 'некорректной работе Портала',
    withImages: 'Картинки и текст',
    massAdsEditScheduleAndTargeting: 'Редактировать Расписание и Таргетинг',
    massAdsEditSchedule: 'Редактировать Расписание',
    massAdsEditTargeting: 'Редактировать Таргетинг',
    massAdsEditSelected: 'выбрано',
    massAdsEditSelectAll: 'выбрать всё',
    massAdsUpdatedSuccess: 'Данные успешно обновлены',
    notificationAdPollCreatedSuccessfully: 'Реклама или опрос успешно созданы',
    notificationAdPollUpdatedSuccessfully: 'Реклама или опрос успешно обновлены',
    notificationAdPollDeletedSuccessfully: 'Реклама или опрос успешно удалены'
  },
  aps: {
    deleteTemplate: 'Удалить шаблон',
    goBackToClients: 'Назад к Клиентам',
    ntpSettingsHeader: 'NTP',
    ntpSettingsEnable: 'Включить NTP',
    ntpSettingsServersListCounter: 'В списке серверов',
    ntpSettingsServersHeader: 'NTP cерверы',
    ntpSettingsServersAdd: 'Добавить',
    ntpSettingsServersDelete: 'Удалить',
    ntpSettingsServersClear: 'Очистить',
    ntpSettingsServersAlreadyAdded: 'Сервер уже добавлен',
    WPA3AddToNoWPA3CPEMultiEditError: 'Некоторые из выбранных ТД не поддерживают WPA3. Нельзя добавить WPA3  Wi-FI сеть на неподдерживающую WPA3 ТД',
    WPA3AddToNoWPA3CPEError: 'Нельзя добавить WPA3  Wi-FI сеть на неподдерживающую WPA3 ТД',
    WPA3Capable: 'WPA3-совместимо',
    goBackToCpeStatistic: 'Назад в Статистику ТД',
    DHCPCaptureFilter: 'Захват DHCP пакетов',
    DHCPCaptureFilterEnabled: 'DHCP захват вкл.',
    DHCPCaptureFilterDisabled: 'DHCP захват выкл.',
    DHCPCaptureFilterEnabledShort: 'вкл.',
    DHCPCaptureFilterDisabledShort: 'выкл.',
    wiredConfigAccounting: 'Подсчет статистики (Accounting)',
    wiredConfigAccountingEnable: 'Включить подсчет статистики',
    wiredConfigFakeWlan: 'Привязать статистику к WLAN',
    selectChannelsMoreThanForCorrectWork: 'Для корректной работы выбирайте каналы после',
    selectChannelsLessThanForCorrectWork: 'Для корректной работы выбирайте каналы до',
    differentHostAddrMessage: 'Внимание! У выбраных точек разный host_addr',
    selectHostAddress: 'выбрать',
    cpeFwCustomAddress: 'адрес сервера',
    cpeFwCustomAddressAndSchema: 'Адрес и протокол сервера с обновлениями',
    tunnelAndWLANtunnelsAreNotMatchForCPETable:
      'Туннель и туннель WLAN не совпадают в одном из конфигов проводного интерфейса',
    selectedAndWLANtunnelsAreNotMatch: 'Выбранный туннель и туннель выбранной WLAN не совпадают',
    positionBlockSelectLocation: 'Выберите локацию',
    positionBlockCopyFromSelectedLocationButtonCaption: 'Скопировать из выбранной локации',
    positionBlockCopyFromSelectedLocationButtonInfo:
      'Позволяет скопировать координаты (широту и долготу) из выбранной локации',
    positionBlockForMultiEditButtonCaption: 'Установить местоположение',
    positionBlockCopyFromLocationCaption: 'Копирование из Локации',
    positionBlockSearchAddressCaption: 'Поиск координат по адресу',
    positionBlockEnterAddressToSearch: 'Введите адрес для поиска координат',
    positionBlockCopyFromLocationButtonCaption: 'Скопировать из',
    positionBlockCopyFromLocationButtonInfo:
      'Позволяет скопировать координаты (широту и долготу) из локации, в которой находится точка доступа',
    positionBlockCoords: 'Координаты',
    positionBlockCoordsLat: 'шир.',
    positionBlockCoordsLatFull: 'Широта',
    positionBlockCoordsLng: 'долг.',
    positionBlockCoordsLngFull: 'Долгота',
    positionBlockHeader: 'Местоположение',
    fwSelectFirmwareCaption: 'Выберите файл с ПО',
    fwFileUploadSuccessfullyMsgCaption: 'Файл с ПО успешно загружен',
    fwFileUploadedCaption: 'загружен',
    errorCopyToClipBoardCaption: 'Копировать в буфер обмена',
    clickToWatchEventsCaptionInTooltip: 'Нажмите, чтобы посмотреть события',
    errorCaptionInCpeEditPanel: 'Ошибка',
    recommendationCaptionInCpeEditPanel: 'Рекомендация',
    eth0StateSinceRebootCaption: 'с момента перезагрузки',
    eth0StateCaption: 'Состояние Ethernet интерфейса',
    eth0IsOff: 'Интерфейс выключен',
    cpeConfigSetCpeListCaption: 'Конфигурация будет применена для следующих ТД',
    cpeConfigSetCpeListNameCaptionAfterApplying: 'Имя (после применения)',
    cpeConfigSetCpeListNameCaptionBeforeApplying: 'Имя (до применения)',
    cpeConfigSetErrorsInUploadedData: 'В данных для конфигурации имеются ошибки. Проверьте их правильность.',
    cpeConfigSetButtonTooltipText: 'Загрузить конфигурацию для точек доступа из ранее сохраненного файла',
    cpeConfigSetButtonText: 'Загрузить конфигурацию из файла',
    cpeConfigShowTooltipForButtonInCpe: 'Скачать конфигурацию ТД в виде файла',
    cpeConfigSetFromUploadedFileIsertToFieldCaption: 'или вставьте конфигурацию в поле',
    cpeConfigSetFromUploadedFileButtonCaption: 'Загрузить из файла',
    cpeConfigSetCopyFromClipboardButtonCaption: 'Вставить из буфера обмена',
    cpeConfigSetTitleHeader: 'Применить конфигурацию ТД',
    cpeConfigShowCopyToClipboardSuccess: 'Скопировано в буфер обмена',
    cpeConfigShowCopyToClipboardError: 'Не удалось скопировать в буфер обмена',
    cpeConfigShowSaveToFileError: 'Не удалось сохранить в файл',
    cpeConfigShowCopyButtonCaption: 'Копировать в буфер обмена',
    cpeConfigShowDownloadButtonCaption: 'Сохранить в файл',
    cpeConfigShowTitleHeader: 'Конфигурация ТД',
    configNotSendCaption: 'Пересылать конфигурацию каждый раз',
    syncTime: 'Синхронизировать время',
    isCpeModelEqualRTBR24WFN2Ev61ErrorMsgForLan2:
      'Нельзя установить VLAN 0 при выбранном lan 2 для точек Rotek RT-BR24-WFN2E v6.1',
    isCpeModelEqualRTBR24WFN2Ev61InfoMsgForLan2:
      'Нельзя устанавливать VLAN 0 при выбранном lan 2 для точек Rotek RT-BR24-WFN2E v6.1',
    tunnelTypeForWLANInfo: 'Туннель',
    natNetworkInfoMsg: 'Не используйте 10.9.0.1/16 для точек с IPSec',
    natNetworkIpaddr: 'IP для NAT сети',
    natNetworkNetmask: 'Маска для NAT сети',
    natNetworkIpaddrPlaceholder: 'Введите IP для NAT сети',
    natNetworkNetmaskPlaceholder: 'Введите маску для NAT сети',
    filterByCompanyDisableSortingByLocationMsg:
      'Для сортировки по локациям необходимо выключить сортировку по имени компании',
    setTimeUsingNTPSuccessMsg: 'Время успешно установлено',
    ntpServerAddressPlaceholder: 'адрес сервера (IP или домен)',
    setTimeUsingNTPEnterServerAddress: 'Введите адрес сервера (IP или домен)',
    setTimeUsingNTP: 'Установить время с помощью NTP',
    filterByUpdatingStatus: 'Обновляемые',
    sortByConnectionnOrDisconnectionTimeNoSort: 'Нет',
    sortByLastDisconnection: 'По последнему отключению',
    sortByLastConnection: 'По последнему подключению',
    sortByFirstConnection: 'По первому подключению',
    sortByConnectionnOrDisconnectionTime: 'Сортировка по времени подкл./откл.',
    sortByConnectionnOrDisconnectionTimeButton: 'По времени подкл./откл.',
    sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption: 'Фильтр по промежутку времени',
    sortByConnectionnOrDisconnectionTimeDateRangeFilterButton: 'Включить',
    sortByConnectionnOrDisconnectionTimeDateRangeCaption: 'Временной промежуток',
    errorMessageOnlyIfLeastOneCPEHaveError:
      'Сброс ошибок доступен только если хотя бы одна из выбранных ТД имеет ошибки',
    discardErrorsNotificationErrorsDiscardedSuccessfully: 'Ошибки успешно сброшены',
    discardErrorsButton: 'Сбросить ошибки для ТД',
    inputForChangeDefaultServerPlaceholder: 'Введите для изменения сервера по умолчанию',
    inputForChangeDefaultServerPortPlaceholder: 'Введите для изменения порта по умолчанию',
    inputForChangeDefaultPlaceholder: 'Введите для изменения значения по умолчанию',
    currentWhitelistItems: 'Элементов белого списка',
    currentBlacklistItems: 'Элементов черного списка',
    none: 'нет',
    wifiRadar: 'Wi-Fi Таргет',
    whitelist: 'белый список',
    blacklist: 'черный список',
    wiredTunnel: 'Тунель',
    wiredPorts: 'Порты',
    wiredVLANNAtive: 'без тега',
    wiredNoTunel: 'Нет',
    wiredSet0forNative: 'установите 0 для работы без тега',
    rrmInfoTooltip: 'RRM - алгоритм управления радиоресурсами',
    firmwareModeOn: 'вкл.',
    firmwareModeOff: 'выкл.',
    firmwareModeCheck: 'проверять самостоятельно',
    rateControl: 'Контроль минимальной скорости',
    cpeStatusTooltipActive: 'Активно',
    cpeStatusTooltipUpdating: 'Обновление',
    cpeStatusTooltipNotActive: 'Не активно',
    registerCpeRegisterFollowCPEs: 'Добавить следующие ТД',
    registerCpeExample: 'Пример',
    registerCpeFileTip: 'Используйте .csv файл',
    buttonsBack: 'Назад',
    modalStatTitle: 'Статистика',
    modalLogsTitle: 'Логи',
    modalWiFiRadarTitle: 'Wi-fi Таргет',
    modalFirewallTitle: 'Брандмауэр',
    modalYes: 'Да',
    modalNo: 'Нет',
    modalEnabled: 'Включено',
    modalDisabled: 'Выключено',
    modalNoWLANs: 'Нет сетей Wi-Fi',
    modalWLANsTitle: 'Сети Wi-Fi',
    modalModelTitle: 'Модель',
    modalLocationTitle: 'Локация',
    modalConfigFromTitle: 'Конфигурация из',
    modalUUIDOptionalTitle: 'UUID (опционально)',
    modalAddUUIDPlaceHolder: 'Добавить UUID',
    modalTemplateDescriptionPlaceHolder: 'Введите описание',
    modalEnterTemplateNamePlaceHolder: 'Введите имя шаблона',
    modalAddTagPlaceHolder: 'Добавить тег',
    buttonsApply: 'Применить',
    modalSelectTemplatePlaceHolder: 'Выберите шаблон',
    modalFirmwareFileInfo: 'Информация о файле ПО',
    modalFirmwareFileInfoSize: 'Размер',
    buttonsUpgrade: 'Обновить',
    modalBeAttention: 'Будьте внимательны!',
    modalBeAttentionFirst: 'При принудительном обновлении точек доступа режим автоматического обновления отключится.',
    modalBeAttentionSecond: 'Если загруженное ПО такое же как на выбранной точке доступа - обновления не произойдет.',
    modalUploadFWfile: 'Загрузить файл с ПО',
    modalNoUpdatesAvailable: 'Нет доступных обновлений',
    modalCurrentFirmwareVersion: 'Текущая версия ПО',
    modalBrokerAddress: 'Адрес брокера',
    modalBrokerAddressPlaceHolder: 'Введите чтобы изменить адрес брокера по умолчанию',
    buttonsYesReboot: 'Да, перезагрузить',
    buttonsYesReset: 'Да, сбросить',
    errorMessageOnlyForCPEsWithEqualHostAddr: 'Доступно только для ТД с одинаковым адресом брокера',
    errorMessageOnlyForAPsWithEqualModel: 'Эта настройка доступна только для ТД одинаковой модели',
    errorMessageOnlyForConnectedCPEs: 'Эта настройка доступна только для подключенных ТД',
    errorMessageOnlyForIpsecConnectedCPEs: 'Эта настройка доступна только для подключенных через ipsec ТД',
    errorMessageOnlyForDisconnectedCPEs: 'Эта настройка доступна только для отключенных ТД',
    errorMessageNoAvailableCapabilities: 'Нет доступных возможностей',
    errorMessageNoAvailableCapabilitiesNote: 'Комбинация Band / Mode / Channel не корректна. Отключите, удалите, перезагрузите и заново создайте устройство',
    errorMessageNoCPEsModelData: 'Нет данных о модели выбранных ТД',
    cpeEditPanelNamePlaceHolder: 'Введите имя ТД',
    cpeEditPanelDescriptionPlaceHolder: 'Введите описание ТД',
    wlansCaption: 'Сети Wi-Fi',
    wlansSelectorPlaceHolder: 'Выбрать cети Wi-Fi',
    wlansSelectorTip: 'Нажмите enter для выбора',
    cpeEditRadioSettings: 'Настройки радио интерфейса',
    cpeEditCaptionOf: 'из',
    cpeEditCaptionUnlimited: 'не огранич.',
    tableLoad: 'Загрузить',
    tableOutOf: 'ТД из',
    tableView: '',
    tableItemsOf: 'из',
    emptyWatcher: 'Перезагружать устройство при пустом списке MAC адресов',
    emptyWatcherInfoTooltip: 'Обязательно для точек TP-Link mr3020 из-за особенностей драйвера',
    maxQuiet: 'Число пустого сбора перед перезагрузкой',
    maxQuietCaption: 'через сколько пустых попыток перезагружать',
    maxQuietShort: 'длина очереди',
    maxCacheQueue: 'Сколько MAC адресов хранить в оффлайне',
    maxCacheQueueCaption: 'когда точка доступа не подключена к контроллеру',
    maxCacheQueueShort: 'длина кэша очереди',
    Advanced: 'Дополнительные настройки',
    Config: 'Конфиг',
    FWAutoUpgrade: 'Обновление ПО',
    FWversion: 'Версия ПО',
    Guestcontrol: 'Гостевой контроль',
    IPsec: 'IPsec',
    IPsecModalLabel: 'Выберите действие',
    IPsecTurnOff: 'Выключить',
    IPsecTurnOn: 'Включить',
    IPsecTurnOnUnencrypted: 'Включить без шифрования',
    Input: 'Вход',
    LBSFilterMode: 'Режим ограничения Wi-Fi Таргет',
    Log: 'Лог',
    Output: 'Выход',
    OutputWarning: 'Выберите хотя бы одну опцию',
    OutputWarningForNonRootLocationUsers: 'Включите NAT',
    Ports: 'Порты',
    Redirect: 'Редирект',
    Stats: 'Стат',
    Tunnel: 'Туннель',
    WLANsstatus: 'Состояние Wi-Fi сетей',
    WiredConfig: 'Проводная конфигурация',
    accessControlSettings: 'Контроль доступа',
    active: 'Активные',
    addCommonTemplates: 'Применить шаблон',
    addCommonWlans: 'Добавить общие сети',
    addConfig: 'добавить конфигурацию',
    addCpe: 'Добавить точку',
    advancedSettings: 'Дополнительные настройки',
    allWlans: 'Все сети',
    assignTemplateHint: 'Выбрать шаблон для выбранных ТД',
    assignCommonWlans: 'Присвоить общие сети',
    // assignCommonWlansHint: 'Select common wlans for selected CPEs',
    assignCommonWlansHint: 'Выберите общие сети WLAN для выбранных ТД',
    assignCommonWlansWarn: 'Внимание! Выбранные точки имеют разные частоты интерфейсов',
    assignTemplate: 'Применить шаблон',
    auto: 'Авто',
    back: 'Назад',
    backgroundScaning: 'Сканирование радиоэфира',
    bandMode: 'Режим',
    bandwidth: 'Ширина',
    brokerHost: 'Хост брокера',
    cancel: 'Отмена',
    channel: 'Канал',
    channels: 'Каналы',
    clientTimeout: 'Период кэша (в сек)',
    clients: 'Клиенты',
    close: 'Закрыть',
    commandOutput: 'Результат',
    commonWlans: 'Общие сети',
    config: 'конфиг',
    configModalLabel: 'Введите скрипт',
    configModalTitle: 'Отправить скрипт настройки',
    configStatus: 'Конфиг статус',
    confirm: 'Ок',
    connected: 'Подключено',
    connectionInfo: 'Информация о подключении',
    country: 'Код страны',
    cpe: 'ТД',
    cpeEvents: 'Посмотреть события этой ТД',
    showCurrentClients: 'Показать текущих клиентов',
    showCurrentStats: 'Показать текущую статистику',
    showCurrentStatsModalTitle: 'Текущая статистика для ТД',
    showCurrentStatsNoDataForCPE: 'Нет данных для данной ТД',
    showCurrentStatsUptimeCaption: 'Время работы',
    showCurrentStatsRamChartYAxisTitle: 'Мбайт',
    showCurrentStatsRamChartTooltip: 'МБ',
    showCurrentStatsRamChartTotalRam: 'Всего памяти',
    showCurrentStatsRamChartFreeRam: 'Свободная память',
    showCurrentStatsRamChartChartTitle: 'Память',
    showCurrentStatsInterfacesHeader: 'Интерфейсы ТД',
    showCurrentStatsInterfacesInterfaceNameTableCaption: 'Интерфейс ТД',
    showCurrentStatsInterfacesInterfaceTableHeaderCaption: 'Интерфейс',
    showCurrentStatsInterfacesTxRxChartTitle: 'Входящий и исходящий трафик для',
    showCurrentStatsInterfacesTxRxChartTx: 'Исходящий трафик',
    showCurrentStatsInterfacesTxRxChartRx: 'Входящий трафик',
    showCurrentStatsInterfacesTxRxChartYAxisTitleMB: 'Мбайт',
    showCurrentStatsInterfacesTxRxChartYAxisTitleKB: 'Кбайт',
    showCurrentStatsInterfacesTxRxChartYAxisTitleB: 'Байт',
    showCurrentStatsInterfacesTxRxChartYAxisTitleGB: 'Гбайт',
    showCurrentStatsInterfacesTxRxChartTooltipMB: 'МБ',
    showCurrentStatsInterfacesTxRxChartTooltipKB: 'КБ',
    showCurrentStatsInterfacesTxRxChartTooltipB: 'Байт',
    showCurrentStatsInterfacesTxRxChartTooltipGB: 'ГБ',
    showCurrentStatsInterfacesTxRxSpeedChartTitle: 'Входящая и исходящая скорость для',
    showCurrentStatsInterfacesTxRxSpeedChartTx: 'Исходящая скорость',
    showCurrentStatsInterfacesTxRxSpeedChartRx: 'Входящая скорость',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleMB: 'Мбайт/с',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleKB: 'Кбайт/с',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleB: 'Байт/c',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleGB: 'Гбайт/с',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipMB: 'МБ/с',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipKB: 'КБ/с',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipB: 'Байт/с',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipGB: 'ГБ/с',
    showCurrentStatsInterfacesTxRxDeltaChartTitle: 'Входящая и исходящая дельта трафика для',
    showCurrentStatsInterfacesTxRxDeltaChartTx: 'Исходящая дельта трафика',
    showCurrentStatsInterfacesTxRxDeltaChartRx: 'Входящая дельта трафика',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleMB: 'Мбайт',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleKB: 'Кбайт',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleB: 'Байт',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleGB: 'Гбайт',
    showCurrentStatsInterfacesTxRxDeltaChartTooltip: 'МБ',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipMB: 'МБ',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipKB: 'КБ',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipB: 'байт',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipGB: 'ГБ',
    showCurrentStatsMbytes: 'МБайты',
    showCurrentStatsKbytes: 'КБайты',
    showCurrentStatsBytes: 'Байты',
    showCurrentStatsGbytes: 'ГБайты',
    showCurrentStatsUnits: 'Единицы измерения',
    cpeId: 'ID',
    cpeInfo: 'Информация о Т.Д.',
    cpesList: 'Точки доступа',
    createTemplateFromCpe: 'Создать шаблон из настроек ТД',
    currentState: 'Текущее состояние',
    dbm: 'дБм',
    deleteAllTags: 'Удалить все теги',
    deleteAllWlans: 'Удалить все сети',
    deleteCommonTags: 'Удалить все теги',
    deleteCommonWlans: 'Удалить общие сети',
    deleteCpe: 'Удалить точку доступа',
    deleteCpeConfirm: 'Подтвердить удаление выбранной точки доступа',
    deleteCpes: 'Удалить точки доступа',
    deleteCpesConfirm: 'Подтвердить удаление ',
    deleteTagsConfirm: 'Подтвердить удаление тегов с выбранных точек',
    deleteWlansConfirm: 'Подтвердить удаление сетей на выбранных точках',
    description: 'Описание',
    disabled: 'Выключено',
    disconnected: 'Отключено',
    downloadSpeed: 'Ограничение скорости загрузки',
    downloadSpeedDescription: 'Ограничение скорости загрузки, равномерное для всех клиентов',
    downloadSpeedMax: 'Общий размер ограничения',
    downloadSpeedMin: 'Ограничение на клиента',
    downloadSpeedType: 'Единица измерения',
    dualBand: 'Мультичастотные',
    editCommonParameters: 'Редактировать общие параметры точек',
    editLogAndStatSettings: 'Редактирование логов, статистики, Wi-fi Таргет, SNMP, NTP и захвата DHCP пакетов',
    editSelectedCpes: 'Редактировать выбранные точки',
    editSettingsCpes: 'Редактировать настройки интерфейсов',
    editTags: 'Добавить теги',
    editingMode: 'Режим редактирования',
    empty: 'пусто',
    error: 'ошибка',
    forFollowingCPEs: 'Изменение будет применено для следующих ТД',
    syncTimeForCPES: 'Синхронизация времени будет проведена для следующих ТД',
    syncTimeButton: 'Синхронизировать',
    syncTimeSuccessMsg: 'Время успешно синхронизировано',
    fwUpgrade: 'Обновление ПО',
    general: 'Основные',
    ghz: 'ГГц',
    header: 'Точки доступа',
    hideDescription: 'скрыть описание',
    hostAddr: 'Адрес брокера',
    interfaceDisabled: 'Интерфейс выключен',
    interfaceType: 'Тип интерфейса',
    interfaces: 'Интерфейсы',
    ipaddr: 'Адрес локальной сети',
    lastError: 'Проблемные',
    lbs: 'Wi-Fi Таргет',
    lbsConfig: 'Wi-Fi Таргет конфиг.',
    legacyRates: 'Поддержка 802.11b',
    limitperWlan: 'Лимит на каждую сеть',
    localAddr: 'Адрес локального туннеля',
    location: 'Локация',
    log: 'Лог',
    logConfig: 'Конфиг. логов',
    logLevel: 'Уровень логирования',
    logLevelMode: 'ON - дебаг режим, OFF - обычный режим',
    macaddr: 'MAC',
    massRegistrationMode: 'Массовое добавление ТД',
    maxClients: 'Ограничение кол-ва клиентов',
    mode: 'Режим',
    model: 'Модель',
    multiEdit: 'Мультиредактирование',
    multiEditMode: 'Режим Мультиредактирования',
    name: 'Имя',
    nasIp: 'NAS IP адрес',
    natAccess: 'Доступ к точке из NAT',
    noDataToDisplay: 'Нет данных для отображения',
    notActive: 'Не активные',
    noTags: 'Нет тэгов',
    notConnected: 'Не подключено',
    offline: 'офлайн',
    ok: 'онлайн',
    oneBand: 'Одночастотные',
    pending: 'ожидание',
    radar: 'Wi-Fi Таргет',
    readOnlyMode: 'Режим чтения',
    rebootCpe: 'Перезагрузка ТД',
    rebootCpeAgent: 'Перезагрузка агента ТД',
    rebootCpeAgentModalText: 'Пожалуйста, подтвердите перезагрузку агента ТД',
    rebootCpeConfirm: 'Подтвердить перезагрузку ТД',
    rebootCpeRedundant: 'Перезагрузка через резервный канал',
    rebootCpeRedundantModalText: 'Пожалуйста, подтвердите перезагрузку через резервный канал',
    rebooting: 'перезагрузка',
    register: 'Добавить',
    registerCPE: 'Добавление Точки Доступа',
    registerCPEs: 'Добавление Точек Доступа',
    registerDescription: '',
    registerFollowCPEs: 'Добавить следующие ТД?',
    reportPeriod: 'Период отчета (в сек)',
    resetCpe: 'Сброс ТД',
    resetCpeConfirm: 'Подтвердить сброс ТД',
    resetting: 'сброс',
    restartCpeAgent: 'Перезапустить Агент ТД',
    save: 'Сохранить',
    search: 'Поиск',
    sendConfigCmd: 'Отправить команду настройки',
    getLogsFromCpe: 'Получить лог с ТД',
    logFromCpe: 'Лог с ТД',
    showDescription: 'показать описание',
    singleRegistrationMode: 'Одиночный режим',
    statConfig: 'Конфиг статистики',
    statConfigCapture: 'период статистики ТД и клиентов',
    tableTab_clients: 'Клиенты',
    tableTab_five: '5 ГГц',
    tableTab_interface: 'Интерфейсы',
    tableTab_location: 'Локация, Компания',
    tableTab_model: 'Модель',
    tableTab_name: 'Имя',
    tableTab_radar: 'Wi-Fi Таргет',
    tableTab_status: 'Статус',
    tableTab_tags: 'Теги',
    tableTab_two: '2.4 ГГц',
    tableTab_vlan: 'VLAN',
    tableTab_wired: 'Проводной',
    tableTab_wlans: 'Сети',
    tableTab_firstConnection: 'Перв. подкл.',
    tableTab_lastConnection: 'Посл. подкл.',
    tableTab_lastDisconnection: 'Посл. откл.',
    templates: 'Шаблоны',
    txPower: 'Мощность',
    txPowerAdjust: 'Мощность сигнала',
    type: 'Тип',
    unlim: 'нет',
    update: 'Обновить',
    updating: 'обновление',
    upgrading: 'обновление ПО',
    wanBlockAccess: 'Запрет доступа через WAN',
    warnStatistics: 'Пожалуйста, обратите внимание, что удаление точки доступа приведёт к {0}.',
    warnStatisticsLoss: 'потере статистики',
    width: 'Ширина',
    wifiLock: 'Заблокировать изменение настройки Wi-Fi',
    wired: 'Проводные',
    wlans: 'Сети',
    notificationTemplateCreatedSuccessfully: 'Шаблон успешно создан',
    notificationLocationSuccessfullyChanged: 'Локация успешно изменена',
    notificationCPEUpdatedSuccessfully: 'Точка доступа успешно обновлена',
    notificationCPECreatedSuccessfully: 'Точка доступа успешно создана',
    notificationCPEOperationSuccessfull: 'Успешно',
    dhcpPacketCapture: 'Захват DHCP пакетов',
    statAndRadarCaptionInCpeInfoBlock: 'Статистика, Wi-fi Таргет, Логи, SNMP, NTP и захват DHCP пакетов',
    statAndRadarCaptionInModalBlock: 'Статистика, Wi-fi Таргет, Логи, SNMP, NTP и захват DHCP пакетов',
    maxinactivityTitle: 'Таймаут неактивности',
    maxinactivityPlaceholder: 'Введите период в сек. (0 для знач. по умолчанию в 180 сек.)',
    maxinactivityTooltip:
      'Таймаут неактивности это Max inactivity timeout из конфига hostapd. Кликните, чтобы узнать больше',
    maxinactivityAboutModalTitle: 'О таймауте неактивности',
    maxinactivityAboutModalText: 'Таймаут неактивности это Max inactivity timeout из конфига hostapd',
    snmp: 'SNMP',
    snmpCommunity: 'Community',
    snmpLocation: 'Локация',
    snmpinterfaces: 'Интерфейсы',
    snmpinterfacesListItems: 'В списке интерфейсов',
    snmpListenInterface: 'Интерфейс прослушивания',
    zeroVLANIfNativeVlanOnSinglePortInfoMsg:
      'Не устанавливайте VLAN 0 на интерфейс, с которого устройство подключается к сети. Это приведет к потере устройства',
    cpeClientsListFromCpeCommandModalHeader: 'Клиенты ТД',
    cpeClientsListFromCpeCommandLink: 'Показать текущих клиентов (данные с ТД)',
    cpeClientsListFromCpeNoClients: 'Нет клиентов для отображения',
    cpeClientsListFromCpeTooltipHeader: 'Информация о wlan',
    cpeClientsListFromCpeChannellCaption: 'Канал',
    cpeClientsListFromCpeClientsTableHeader: 'Клиенты',
    cpeClientsListFromCpeClientsMac: 'MAC',
    cpeClientsListFromCpeClientsInactiveTime: 'Inactive time',
    cpeClientsListFromCpeClientsRxBytes: 'Rx bytes',
    cpeClientsListFromCpeClientsRxPackets: 'Rx packets',
    cpeClientsListFromCpeClientsTxBytes: 'Tx bytes',
    cpeClientsListFromCpeClientsTxPackets: 'Tx packets',
    cpeClientsListFromCpeClientsTxRetries: 'Tx retries',
    cpeClientsListFromCpeClientsTxFailed: 'Tx failed',
    cpeClientsListFromCpeClientsRxDropMisc: 'Rx drop misc',
    cpeClientsListFromCpeClientsSignal: 'Signal',
    cpeClientsListFromCpeClientsSignalAvg: 'Signal avg',
    cpeClientsListFromCpeClientsTxBitrate: 'Tx bitrate',
    cpeClientsListFromCpeClientsRxBitrate: 'Rx bitrate',
    cpeClientsListFromCpeClientsRxDuration: 'Rx duration',
    cpeClientsListFromCpeClientsAuthorized: 'Authorized',
    cpeClientsListFromCpeClientsAuthenticated: 'Authenticated',
    cpeClientsListFromCpeClientsAssociated: 'Associated',
    cpeClientsListFromCpeClientsPreamble: 'Preamble',
    cpeClientsListFromCpeClientsWMMWME: 'WMM/WME',
    cpeClientsListFromCpeClientsMFP: 'MFP',
    cpeClientsListFromCpeClientsTDLSPeer: 'TDLS peer',
    cpeClientsListFromCpeClientsDTIMPeriod: 'DTIM period',
    cpeClientsListFromCpeClientsBeaconInterval: 'Beacon interval',
    cpeClientsListFromCpeClientsShortSlotTime: 'Short slot time',
    cpeClientsListFromCpeClientsConnectedTime: 'Connected time',
    // for combined columns
    cpeClientsListFromCpeClientsRX: 'RX',
    cpeClientsListFromCpeClientsTX: 'TX',
    cpeClientsListFromCpeClientsCombinedSignal: 'Signal',
    cpeClientsListFromCpeClientsAuthorizedAuthenticatedAssociated: 'Authorized, Authenticated, Associated',
    cpeClientsListFromCpeClientsBitrate: 'Bitrate',
    //
    cpeClientsListFromCpeClientsConnectedCaption: 'подключено',
    cpeClientsListFromCpeClientsInfoCaption:
      'Позволяет получить информацию о текущих клиентах непосредственно с самой ТД',
    tunnels: {
      captionInCpeModal: 'Туннели',
      noTunnels: 'Нет Туннелей'
    }
  },
  clientDistance: {
    chartDistance: 'Дистанция (в метрах)',
    chartHeader: 'Удаленность пользователя',
    noTags: 'нет тегов',
    tableView: '',
    tableItemsOf: 'из'
  },
  clients: {
    goBackToClientsStatistic: 'Назад в Статистику Клиентов',
    clientMac: 'MAC клиента',
    eventsTooltipCaption: 'Позволяет перейти в раздел События и просмотреть события по выбранному клиенту',
    eventsAndRssiTooltipCaption: 'Позволяет посмотреть события и RSSI по выбранному клиенту на графике',
    eventsButtonCaption: 'События',
    eventsAndRssiButtonCaption: 'События и RSSI',
    eventsTableCaption: 'События',
    authNo: 'Нет',
    authTable: 'Авторизация',
    authInfo: 'Авторизация инфо',
    authUsername: 'имя',
    authType: 'тип',
    authDevice: 'устройство',
    authOs: 'ОС',
    authOsVersion: 'версия ОС',
    authUseragent: 'useragent',
    tableInterface: 'интерфейс',
    tableNotFound: 'не найдено',
    tableNo: 'нет',
    tableNoData: 'нет данных',
    editPanelDescriptionPlaceHolder: 'Введите описание',
    editPanelOther: 'Беспроводной',
    editPanelCamera: 'Камера',
    editPanelWired: 'Проводной',
    editPanelCameraRtspStream: 'Rtsp Поток',
    editPanelCameraRtspStreamLinkPlaceHolder: 'Введите ссылку для Rtsp Потока',
    authorization: 'Авторизация',
    cpe: 'ТД',
    wlan: 'Сеть Wi-Fi',
    lastConnected: 'Последнее подключение',
    chipManufacturer: 'Производитель чипа',
    showAllClients: 'Показать всех клиентов',
    showAllClientsWhenCPESelected: 'Показать всех клиентов для данной ТД',
    noConnectedClientsToDisplay: 'Нет подключенных клиентов для отображения',
    noConnectedClientsToDisplayWhenCPESelected: 'Нет подключенных клиентов для отображения для данной ТД',
    auth: 'Авторизация',
    load: 'Загрузить',
    clientsOutOf: 'клиентов из',
    view: '',
    itemsOf: 'из',
    CPE: 'ТД',
    WLAN: 'Сеть',
    authorized: 'Авторизованные',
    camera: 'Камера',
    channel: 'Канал',
    clients: 'Клиенты',
    connected: 'Подключенные',
    disconnected: 'Отключенные',
    frequency: 'Частота',
    other: 'Беспроводные',
    tableTab_channel: 'Канал',
    tableTab_cpe: 'ТД',
    tableTab_description: 'Описание',
    tableTab_frequency: 'Частота',
    tableTab_mac: 'MAC',
    tableTab_rssi: 'RSSI',
    tableTab_state: 'Последнее Состояние',
    tableTab_type: 'Тип',
    tableTab_wlan: 'Сеть',
    tableTab_healthScore: 'Health Статус',
    wired: 'Проводные',
    notificationClientUpdated: 'Клиент успешно обновлен',
    scores: 'балл.',
    healthInactive: 'Неактивно',
    healthPoor: 'Плохо',
    healthFair: 'Неплохо',
    healthGood: 'Хорошо',
    healthOffline: 'Оффлайн',
    healthScoresInfoTooltip: 'Нажмите, чтобы посмотреть больше информации о Health статусе',
    healthStatusModalInfoTitle: 'О Health Статусе',
    healthStatusModalInfoCaption: 'Health Статус может принимать следующие значения',
    healthStatusButtonsInfo: 'только для фильтра Подключенные'
  },
  clientsRF: {
    clientRFChartTitle: 'Состояние Wi-Fi клиента',
    tableView: '',
    tableItemsOf: 'из',
    clientRFSidebar: 'Состояние Wi-Fi клиента',
    clientRFTableTitle: 'Состояние Wi-Fi клиента',
    noData: 'нет данных',
    authNo: 'Нет',
    authTable: 'Авторизация',
    authInfo: 'Авторизация инфо',
    authUsername: 'имя',
    authType: 'тип',
    authDevice: 'устройство',
    authOs: 'ОС',
    authOsVersion: 'версия ОС',
    authUseragent: 'useragent',
    clientRF: 'Клиентский RF',
    expectedThroughput: 'Ожидаемая пропускная способность',
    noise: 'Шум',
    rx: 'Прием',
    signal: 'Сигнал',
    tx: 'Передача',
    rating: 'Рейтинг',
    table: 'Таблица',
    signalP10: 'Сигнал P10',
    signalP90: 'Сигнал P90',
    snrAvg: 'SNR среднее',
    clientRFP: 'Клиентский RFP',
    numberOfEntries: 'Количество записей',
    paginationClientsOutOf: 'Клиентов из',
    paginationLoad: 'Загрузить'
  },
  controller: {
    notificationControllerUpdatedSuccessfully: 'Контроллер успешно обновлен',
    notificationControllerCreatedSuccessfully: 'Контроллер успешно создан',
    notificationControllerDeletedSuccessfully: 'Контроллер успешно обновлен',
    notificationControlleOperationSuccessfull: 'Успешно',
    FWversion: 'Версия ПО',
    SSHkey: 'SSH ключ',
    accessSettings: 'Настройки доступа',
    active: 'Активность',
    addController: 'Добавить контроллер',
    controller: 'контроллер',
    controllerHeader: 'Контроллер',
    controllersList: 'Список контроллеров',
    deleteControllerHeader: 'Удалить контроллер',
    confirmDelete: 'Подтверждаете удаление',
    password: 'Пароль',
    status: 'Статус',
    username: 'Имя пользователя',
    vendor: 'Вендор',
    serialNumber: 'Серийный номер',
    newController: 'Новый контроллер',
  },
  dashboard: {
    goBackToDashboard: 'Назад на главную',
    goBackToClients: 'Назад к Клиентам',
    goToCpeStatistic: 'Статистика',
    goToCpeSettings: 'Настройки ТД',
    cpeChartSeriesName: 'ТД',
    cpeStatusChartSeriesName: 'ТД',
    CPE: 'ТД',
    ERROR: 'ОШИБКА',
    Events: 'События',
    INFO: 'ИНФО',
    WARNING: 'ПРЕДУПРЕЖДЕНИЕ',
    ads: 'Реклама',
    adsCounter: 'Просмотры',
    adsStatistic: 'Статистика просмотров рекламы',
    adsStatisticCaption: 'за этот месяц, показаны лучшие по показам дни',
    adsStatisticTotal: 'Всего просмотров',
    adsTotal: 'Всего реклам',
    adsType: 'Тип',
    available: 'Доступно',
    cache: 'Кэш',
    configCPEs: 'Статус ТД',
    connected: 'Подключенные',
    connectedCPEs: ' Подключенные ТД',
    connectionsStat: 'Статистика подключений',
    connectionsStatCaption: 'последние 7 дней',
    cpu: 'Процессор',
    cpuCores: 'Кол-во ядер',
    cpuCount: 'Кол-во процессоров',
    cpuLoad: 'Загрузка процессора',
    empty: 'Пустая',
    free: 'Свободно',
    gb: 'Гбайт',
    kb: 'Кбайт',
    last10events: 'События за 24ч',
    load: 'Нагрузка',
    load1: 'За 1 минуту',
    load15: 'За 15 минут',
    load5: 'За 5 минут',
    load_core: 'Загрузка на ядро',
    locationAddress: 'Адрес',
    locationCreated: 'Создано',
    locationManager: 'Ответственный',
    locationOwner: 'Владелец',
    locations: 'Локации',
    locationsCaption: '3 случайных локации',
    locationsMap: 'Карта локаций',
    locationsMapCaption: 'показаны только локации, имеющие координаты',
    locationsTotal: 'Всего локаций',
    locationsWhereManager: 'Локации, где Вы ответственный',
    locationsWhereManagerCaption: 'показаны только локации, где Вы ответственное лицо',
    mb: 'Мбайт',
    memory: 'Память',
    memoryFree: 'Свободно памяти',
    memoryTotal: 'Общая память',
    memoryUsed: 'Использовано памяти',
    model: 'Модель',
    modelCPU: 'Процессор',
    mostactiveCPEs: 'Самые активные ТД',
    mostactiveclients: 'Самые активные клиенты',
    mostloadCPEs: 'Самые нагруженные ТД',
    notConnected: 'Отключенные',
    offline: 'Офлайн',
    online: 'Онлайн',
    os: 'ОС',
    radar: 'Wi-Fi Таргет',
    received: 'Скачано',
    rule: 'Правило',
    serverInfo: 'Сервер',
    service: 'Сервисы',
    toAds: 'Просмотреть Рекламы',
    toAdsStatistic: 'Просмотреть статистику',
    toCPEs: 'Просмотреть ТД',
    toClients: 'Просмотреть Клиентов',
    toConnectionsStat: 'Просмотреть статистику',
    toEvents: 'Просмотреть События',
    toLocations: 'Просмотреть Локации',
    toMarketing: 'Просмотреть аналитику',
    total: 'Общая',
    trafficIn: 'Вх. трафик',
    trafficOut: 'Вых. трафик',
    transmitted: 'Отправлено',
    updating: 'В процессе',
    uptime: 'Дней работы',
    used: 'Использовано'
  },
  drPicker: {
    always: 'Постоянно',
    apply: 'Выбрать',
    april_full: 'Апреля',
    april_short: 'Апр',
    august_full: 'Августа',
    august_short: 'Авг',
    cancel: 'Отмена',
    chooseInterval: 'Выберите интервал',
    custom: 'Выбрать',
    days: 'дней',
    december_full: 'Декабря',
    december_short: 'Дек',
    february_full: 'Февраля',
    february_short: 'Фев',
    friday_short: 'Пт',
    from: 'От',
    hours: 'часов',
    january_full: 'Января',
    january_short: 'Янв',
    july_full: 'Июля',
    july_short: 'Июл',
    june_full: 'Июня',
    june_short: 'Июн',
    last: 'Последние',
    last24: 'За 24 часа',
    last30: 'За 30 дней',
    last48: 'За 48 часов',
    last7: 'За 7 дней',
    march_full: 'Марта',
    march_short: 'Мар',
    may_full: 'Мая',
    may_short: 'Май',
    minutes: 'минут',
    monday_short: 'Пн',
    month: 'За этот месяц',
    months: 'месяцев',
    next: 'Следующие',
    november_full: 'Ноября',
    november_short: 'Ноя',
    october_full: 'Октября',
    october_short: 'Окт',
    prev_month: 'За предыдущий месяц',
    prev_week: 'За предыдущую неделю',
    saturday_short: 'Сб',
    seconds: 'секунд',
    september_full: 'Сентября',
    september_short: 'Сен',
    sunday_short: 'Вс',
    thursday_short: 'Чт',
    to: 'До',
    today: 'За сегодня',
    tuesday_short: 'Вт',
    wednesday_short: 'Ср',
    week: 'За эту неделю',
    weeks: 'недель',
    years: 'лет',
    yesterday: 'За вчера',
    maxDateRangeLimit: 'Максимальный интервал для выбора'
  },
  events: {
    timestamp: 'Время',
    subjectId: 'ID субъекта',
    description: 'описание',
    goBackToClients: 'Назад к Клиентам',
    noDataForSelectedDateRangeForSelectedCPE: 'Нет данных для выбранной ТД. Попробуйте выбрать другой временной период',
    noData: 'Нет данных. Попробуйте выбрать другой временной период и/или другие фильтры',
    goBackToCPE: 'Вернуться к ТД',
    cpeName: 'Имя точки',
    clientRFAndEventsNumberOfEventsCaption: 'Количество событий',
    clientRFAndEventsConnectedChartSeries: 'Подключение',
    clientRFAndEventsDisconnectedChartSeries: 'Отключение',
    clientRFAndEventsAuthorizationChartSeries: 'Авторизация',
    clientRFAndEventsDhcp_AckChartSeries: 'DHCP ACK',
    clientRFAndEventsClientCaption: 'КЛИЕНТА',
    clientRFAndEventsRFHeader: 'Состояние Wi-Fi',
    clientRFAndEventsEventHeader: 'Событие',
    clientRFAndEventsButtonCaption: 'События и RSSI клиента',
    clientRFAndEventsModalCaption: 'События и RSSI клиента',
    clientRFAndEventsChartEventsCaption: 'События',
    copyToClipboard: 'Скопировать',
    errorEvents: 'Ошибка',
    eventData: 'Информация по событию',
    events: 'События',
    level: 'Уровень',
    showPayload: 'Посмотреть весь объект',
    status: 'Статус',
    subject: 'Объект',
    summary: 'Сводка',
    time: 'Время',
    type: 'Тип',
    paginationEventsOutOf: 'Событий из',
    paginationLoad: 'Загрузить',
    eventsChart: 'График Событий',
    eventsTable: 'События',
    chartEventsAlert: 'На графике показаны только загруженные в таблицу события',
    in24Hours: 'за 24 часа',
    placeHolderSearch: 'Поиск',
    filterINFO: 'ИНФО',
    filterWARNING: 'ПРЕДУПРЕЖДЕНИЕ',
    filterERROR: 'ОШИБКА',
    filterLOG: 'ЛОГ',
    filterCPE: 'ТОЧКА ДОСТУПА',
    filterCLIENT: 'КЛИЕНТ',
    filterSERVICE: 'СЕРВИС',
    filterFIRMWARE: 'ПРОШИВКА',
    filterRULE: 'ПРАВИЛО',
    'filterCLIENT AUTH': 'АВТОРИЗАЦИЯ',
    filterCONNECTED: 'ПОДКЛЮЧЕНИЕ',
    filterDISCONNECTED: 'ОТКЛЮЧЕНИЕ',
    filterAUTHORIZATION: 'АВТОРИЗАЦИЯ',
    filterACK: 'DHCP ACK',
    view: '',
    itemsOf: 'из',
    sendConfigButton: 'Отправить скрипт настройки на проблемные ТД',
    sendConfigDuccessMessage: 'Скрипт успешно отправлен',
    sendConfigModalHintMessage: 'Скрипт будет отправлен на ТД с ошибками следующего вида'
  },
  firewall: {
    ANY: 'ЛЮБОЙ',
    IN: 'ВХОДЯЩИЙ',
    OUT: 'ИСХОДЯЩИЙ',
    modalDeleteFirewallTitle: 'Удалить Брандмауэр',
    modalDeleteFirewallText: 'Подтвердить удаление',
    wizardNoRules: 'Нет правил',
    confirmButton: 'Подтвердить',
    deleteRuleButton: 'Удалить Правило',
    fromPortModal: 'Порт источника',
    toPortModal: 'Порт целевой',
    fromMACaddress: 'MAC адрес источника',
    toMACaddress: 'MAC адрес целевой',
    fromIPaddress: 'IP адрес источника',
    toIPaddress: 'IP адрес целевой',
    enterPortPlaceholder: 'Введите порт',
    enterIPPlaceholder: 'Введите IP адрес',
    enterMACPlaceholder: 'Введите MAC адрес',
    editRule: 'Редактирование правила',
    fromMAC: 'MAC источника',
    toMAC: 'MAC целевой',
    fromIP: 'IP источника',
    toIP: 'IP целевой',
    fromPort: 'Порт источника',
    toPort: 'Порт целевой',
    ruleProtocol: 'Протокол',
    ruleIPProtocol: 'IP Протокол',
    infoMessage:
      'Наш брандмауэр основан на Iptables. Правила создаются в его нотации. Документацию по правилам можно найти тут',
    infoTooltip: 'нажмите, чтобы просмотреть информацию о Брандмауэре',
    firewallNamePlaceHolder: 'Введите имя Брандмауэра',
    firewall: 'Брандмауэр',
    wizard3Caption: 'Подтвердите создание нового Брандмауэра',
    wizard2Caption: 'Заполните правила Брандмауэра',
    wizard1Caption: 'Заполните обязательные параметры Брандмауэра',
    Action: 'Действие',
    AddRule: 'Добавить правило',
    Direction: 'Направление трафика',
    Firewalls: 'Firewalls',
    Internetlayer: 'Сетевой уровень',
    Linklayer: 'Канальный уровень',
    // Policy: 'Действие по ум.',
    Policy: 'Политика по ум.',
    RuleSettings: 'Настройка правила',
    Rules: 'Правила',
    SaveRule: 'Сохранить правило',
    Transportlayer: 'Транспортный уровень',
    addFirewall: 'Добавить Брандмауэр',
    addNewRule: 'Добавление нового правила Брандмауэра',
    createFirewall: 'Создание Брандмауэра',
    direction: 'Направление трафика',
    firewallSettings: 'Параметры Брандмауэра',
    // policy: 'Действие по умолчанию',
    policy: 'Политика по умолчанию',
    rules: 'Правила',
    notificationFirewallUpdatedSuccessfully: 'Брандмауэр успешно обновлен',
    notificationFirewallCreatedSuccessfully: 'Брандмауэр успешно создан',
    notificationFirewallDeletedSuccessfully: 'Брандмауэр успешно удален',
    notificationFirewallOperationSuccessfull: 'Успешно'
  },
  rules: {
    minTrafficThresholdCaptionBytesForTableColumn: 'байт',
    minTrafficThresholdCaptionBytes: 'в байтах',
    minTrafficThresholdCaption: 'Минимальный порог трафика',
    minTrafficThresholdPlaceholder: 'Порог трафика (в байтах)',
    minTrafficThresholdInfoCaption: 'Если пришло меньше трафика за расчетный период, то выполняется скрипт',
    noRules: 'нет',
    triggerScript: 'Скрипт',
    addRule: 'добавить',
    namePlaceHolderInWizard: 'Введите имя для правила',
    scriptPlaceHolderInWizard: 'Введите скрипт',
    scriptInfoCaptionInWizard:
      'Вставьте однострочный sh скрипт. Возможные переменные окружения: TEXT - event payload, CPE - AP uuid',
    ruleTypes: {
      customer_activity: 'Точка доступа: Активность точки доступа',
      cpu_load: 'Точка доступа: Загрузка процессора',
      free_ram: 'Точка доступа: Свободная память',
      config_error: 'Точка доступа: Ошибка конфигурации',
      iface_error: 'Точка доступа: Ошибка интерфейса',
      connected: 'Точка доступа: Подключение',
      disconnected: 'Точка доступа: Отключение',
      client_far: 'Клиент: Дистанция от ТД',
      client_con: 'Клиент: Подключение',
      client_dis: 'Клиент: Отключение'
    },
    notificationTypeCaption: 'Тип действия',
    notificationTypes: {
      telegram: 'Telegram',
      email: 'Эл. почта',
      mqtt: 'MQTT'
    },
    script: 'Выполнение скрипта',
    notifySettingsTelegramTokenCaption: 'Токен',
    notifySettingsTelegramTokenPlaceholder: 'Токен',
    notifySettingsTelegramTokenInfo: 'Токен, получаемый у @BotFather',
    notifySettingsTelegramChatIdCaption: 'ID чата',
    notifySettingsTelegramChatIdPlaceholder: 'ID чата',
    notifySettingsTelegramChatIdInfo: 'Уникальный идентификатор telegram чата',
    notifySettingsTelegramTextCaption: 'Текст',
    notifySettingsTelegramTextPlaceholder: 'Текст для уведомления',
    notifySettingsEmailToCaption: 'Кому',
    notifySettingsEmailToPlaceholder: 'Адрес email',
    notifySettingsEmailSubjectCaption: 'Тема',
    notifySettingsEmailSubjectPlaceholder: 'Тема письма',
    notifySettingsEmailTextCaption: 'Текст',
    notifySettingsEmailTextPlaceholder: 'Текст письма',
    notifySettingsMQTTTopicCaption: 'Топик',
    notifySettingsMQTTTopicPlaceholder: 'Топик',
    notifySettingsMQTTTopicInfo: 'MQTT Топик',
    notifySettingsMQTTTextCaption: 'Текст',
    notifySettingsMQTTTextPlaceholder: 'Текст',
    notifySettingsForTableCaption: 'Параметры действия',
    notifySettingsForTableForTelegramToChatId: 'Сообщение в чат c id',
    notifySettingsForTableForEmailToAddress: 'Письмо на адрес',
    notifySettingsForTableForMQTTToTopic: 'Сообщение в топик',
    notifySettingsForTableShowMore: 'подробнее',
    notifySettingsForTableHide: 'скрыть'
  },
  sessions: {
    noSessions: 'Нет сессий'
  },
  general: {
    filtersTownPlaceholder: 'Название города',
    confirmDeleting: 'Подтверждаете удаление',
    noData: 'нет данных',
    mhz: 'МГц',
    mbitS: 'Мбит/с',
    object: 'Объект',
    ofTotal: 'из всех',
    another: 'Другие',
    locationSelect: 'Выбор локации',
    locationsAll: 'Все',
    previouslySelected: 'Ранее выбранные',
    minimize: 'Свернуть',
    expand: 'Развернуть',
    for: 'для',
    byDefault: 'по умолчанию',
    clear: 'очистить',
    noOptionsForSearch: 'Начните ввод для поиска',
    noResultForSearch: 'Ничего не найдено',
    dateRangeLimitExceededError: 'Выбран промежуток более чем',
    maxOneElement: 'Максимум выбранных опций. Сначала удалите выбранный вариант, чтобы выбрать другой',
    selectLocation: 'Выберите локацию',
    selected: 'Выбрано',
    selectedForTables: 'выбрано',
    rub: 'руб.',
    select: 'выбрать',
    copyToClipBoardCaption: 'Копировать в буфер обмена',
    notFound: 'не найдено',
    sessionReturnClients: 'Повторные клиенты для сессии',
    sessionFirstClients: 'Первые клиенты для сессии',
    sessionCount: 'Счетчик сессии',
    sessionAverageTime: 'Среднее время сессии',
    trafficDownloaded: 'Входящий трафик',
    trafficUploaded: 'Исходящий трафик',
    time: 'время',
    connectedShort: 'подкл.',
    disconnectedShort: 'откл.',
    connectedDisconnectedShort: 'Подкл./Откл.',
    start: 'Cтарт',
    stop: 'Cтоп',
    withChild: 'с дочерними',
    company: 'Компания',
    allStatuses: 'Все статусы',
    allCompanys: 'Все компании',
    of: 'из',
    found: 'Найдено',
    enable: 'Включить',
    enableShort: 'Вкл.',
    dayShort: 'дн',
    minShort: 'мин',
    hourShort: 'ч',
    secShort: 'сек',
    advancedParameters: 'Дополнительные параметры',
    disabled: 'выключено',
    enabled: 'включено',
    noTags: 'нет тегов',
    addTags: 'Добавить теги',
    frequency: 'Частота',
    showDescription: 'показать описание',
    hideDescription: 'скрыть описание',
    multiSelectDeselectLabel: 'Нажмите Enter для отмены выбора',
    multiSelectSelected: 'Выбрано',
    multiSelectPressToSelect: 'Нажмите Enter для выбора',
    cpeOne: 'ТД',
    cpeSeveral: 'ТД',
    wlanOne: 'сетей Wi-Fi',
    wlanSeveral: 'сетей Wi-Fi',
    modalChangeLocationsCaption: 'Изменить локацию для следующих',
    searchPlaceHolder: 'Поиск',
    allWlans: 'все сети',
    infoShort: 'инфо',
    generatePassword: 'Сгенерировать пароль',
    locations: 'Локации',
    Always: 'Всегда',
    Auto: 'Авто',
    Mode: 'Режим',
    Off: 'Выкл',
    On: 'Вкл',
    Settings: 'Настройки',
    Timeout: 'Таймаут',
    account: 'Профиль',
    active: 'Актив',
    add: 'Добавить',
    address: 'адрес',
    all: 'Все',
    allLocations: 'Все локации',
    allModels: 'Все модели',
    allTags: 'Все теги',
    apply: 'Применить',
    applyFilters: 'Применить фильтры',
    auto: 'авто',
    back: 'Назад',
    baseLocation: 'Базовая Локация',
    basicParameters: 'Базовые параметры',
    cancel: 'Отмена',
    change: 'Изменить',
    changeLocations: 'Смена локаций',
    chooseFile: 'Выберите файл',
    clickAndDrag: 'Нажмите и перетащите, чтобы увеличить масштаб. Удерживайте клавишу Shift для панорамирования.',
    clientRF: 'Client RF',
    clients: 'Клиенты',
    close: 'Закрыть',
    closeModal: 'Закрыть окно',
    closeModalConfirm: 'Пожалуйста, подтвердите закрытие окна.\nНесохраненные данные будут потеряны.',
    confirm: 'Создать',
    confirmDeletingText: 'Пожалуйста, подтвердите удаление',
    confirmDeletingTitle: 'Подтверждение удаления',
    copy: 'Копировать',
    cpe: 'Точка Доступа',
    cpes: 'Точки Доступа',
    cpuLoad: 'Загрузка процессора',
    create: 'Создать',
    csvFileUUIds: 'Файл формата CSV со списком UUID',
    day: 'день',
    delete: 'Удалить',
    delete_sm: 'удалить',
    description: 'Описание',
    download: 'Скачать',
    downloadAllPng: 'Скачать общий отчёт',
    downloadCsv: 'Скачать .csv',
    downloadPdf: 'Скачать .pdf',
    downloadPng: 'Сохранить изображение',
    downloadXls: 'Скачать .xls',
    downloadXlsx: 'Скачать .xlsx',
    downloadFiles: 'Загрузить таблицу с данными',
    duration12h: '12 часов',
    durationDay: 'Сутки',
    durationDays: '{days} дней',
    durationMonth: 'Месяц',
    durationWeek: 'Неделя',
    durationYear: 'Год',
    edit: 'изменить',
    Edit: 'Изменить',
    editingMode: 'Режим редактирования',
    email: 'Почта',
    empty: 'Пусто',
    filter: 'фильтр',
    filters: 'Фильтры',
    filtersCity: 'Город проживания',
    filtersGender: 'Пол',
    filtersHomeTown: 'Родной город',
    filtersNasId: 'NAS ID',
    filtersPageLimit: 'Макс. количество записей',
    filterPageLimitSmall: 'Макс. записей',
    filtersPageOffset: 'Показать начиная с',
    filtersSocialNetwork: 'Соц. сеть',
    filtersSubscription: 'Статус подписки',
    filtersSort: 'Сортировка',
    filtersVisitsAll: 'Посещения',
    filtersYearOfBirth: 'Год рождения',
    firstConnected: 'Первое подключение',
    feltersDateRange: 'Диапазон дат',
    filtersSubscriptionStatus: 'Статус подписки',
    filtersSubscriptionAtTime: 'Дата подписки',
    freeRAM: 'Свободная память',
    from: 'с',
    hideid: 'скрыть id',
    hour: 'час',
    info: 'Информация',
    insec: 'в сек.',
    interface: 'Интерфейс',
    kbit: 'Кбит',
    lastDisconnected: 'Последнее отключение',
    location: 'Локация',
    mac: 'MAC',
    mbit: 'Мбит',
    memoryLoad: 'Исп. памяти',
    min: 'мин',
    model: 'Модель',
    month: 'месяц',
    name: 'Имя',
    next: 'Далее',
    no: 'Нет',
    noDataToDisplay: 'Нет данных для отображения',
    noDataToDisplayWhenCPESelected: 'Нет данных для отображения для данной ТД',
    noDataToDisplayWhenCPESelectedAndTimeRange: 'Нет данных для отображения для данной ТД за выбранный промежуток времени c выбранными настройками фильтров',
    noModel: 'Модель отсутствует',
    noTag: 'Без тега',
    notActive: 'Не актив',
    off: 'выкл',
    on: 'вкл',
    orderAsc: 'По возрастанию',
    orderDesc: 'По убыванию',
    password: 'Пароль',
    pending: 'Ожидание',
    port: 'порт',
    readOnlyMode: 'Режим чтения',
    removeAll: 'убрать все',
    resetAllFilters: 'Сбросить все фильтры',
    allRoles: 'Все роли',
    role: {
      admin: 'админ',
      marketer: 'маркетолог',
      operator: 'оператор',
      hotel_employee: 'отельный представитель',
      advertising: 'рекламный агент',
    },
    rules: 'Правила',
    save: 'Сохранить',
    search: 'Поиск',
    sec: 'сек',
    selectAll: 'выбрать все',
    serialNumber: 'С/Н',
    server: 'сервер',
    sessions: 'Сессия (подкл./откл.)',
    showid: 'показать id',
    skip: 'Пропустить',
    status: 'Статус',
    step1: 'Шаг 1',
    step2: 'Шаг 2',
    step3: 'Шаг 3',
    step4: 'Шаг 4',
    step5: 'Шаг 5',
    step6: 'Шаг 6',
    step7: 'Шаг 7',
    step8: 'Шаг 8',
    step9: 'Шаг 9',
    step10: 'Шаг 10',
    step11: 'Шаг 11',
    submit: 'Отправить',
    summary: 'Подтверждение',
    summaryConfirmation: 'Проверьте введенные параметры',
    system: 'Системные',
    tags: 'Теги',
    till: 'до',
    totalClients: 'Клиенты',
    trafficTxRx: 'Трафик (вх./исх.)',
    type: 'Тип',
    tz: 'Часовой пояс (UTC)',
    uniqueClients: 'Уник. клиенты',
    unset: 'Не указано',
    updating: 'Обновление',
    warning: 'Предупреждение',
    wlan: 'Сеть Wi-Fi ',
    wlans: 'Сети Wi-Fi',
    yes: 'Да',
    yesDelete: 'Да, удалить',
    hours: 'Часы',
    mo: 'Пн',
    tu: 'Вт',
    we: 'Ср',
    th: 'Чт',
    fr: 'Пт',
    sa: 'Сб',
    su: 'Вс',
    forCurrentPeriod: 'За текущий период',
    forPreviousPeriod: 'За предыдущий период',
    differenceBetweenPeriods: 'Разница',
    differenceGrow: 'рост на',
    differenceLoss: 'падение на'
  },
  header: {
    typeUPDATE: 'МОДИФИКАЦИЯ',
    typeDELETE: 'УДАЛЕНИЕ',
    typeCREATE: 'СОЗДАНИЕ',
    type: 'Тип',
    total: 'всего',
    pending: 'ожидание',
    success: 'успешно',
    errors: 'ошибки',
    tooltipShowErrorsOnly: 'показать только ошибки',
    tooltipShowAll: 'показать все',
    tooltipDeleteAll: 'удалить все',
    tooltipDelete: 'удалить',
    current: 'Текущие',
    account: 'Аккаунт',
    clearListOperations: 'очистить список операций',
    create: 'создание',
    delete: 'удаление',
    inputIntervalPlaceholder: 'Интервал в секундах',
    last: 'Последние',
    logout: 'Выйти',
    max: 'макс.',
    noCurrOperations: 'Нет операций',
    off: 'выкл',
    operations: 'операции',
    reboot: 'перезагрузка',
    reset: 'сброс',
    sec: 'сек.',
    setRefreshInterval: 'Интервал обновления',
    settings: 'Настройки',
    update: 'обновление'
  },
  hotspot: {
    selectClass: 'Выберите класс',
    organizationID: 'ID организации',
    noItems: 'нет элементов',
    realmNoAuth: 'нет авторизаций',
    enterRealmNamePlaceholder: 'Введите имя Realm',
    realmNoData: 'Нет данных для отображения',
    wizard1Title: 'Заполните основные и сетевые параметры Hotspot',
    wizard2Title: 'Заполните дополнительные параметры Hotspot',
    wizard3Title: 'Заполните дополнительные параметры Hotspot',
    wizard4Title: 'Подтвердите создание Hotspot',
    wizardGeneral: 'Основные настройки',
    wizardNamePlaceholder: 'Введите имя для Hotspot 2.0',
    WANMetrics: 'Метрика WAN',
    addHS20: 'Добавить HS 2.0 профиль',
    addRealm: 'Добавить Realm',
    additionalStep: 'Требуется дополнительный шаг авторизации',
    authSubType: 'Подтип авторизации',
    authType: 'Тип авторизации',
    cellular: ' Сотовая сеть',
    connectionCapabilityList: 'Возможности подключения',
    dgaf: 'Групповая переадресация (DGAF)',
    domain: 'Домен',
    domainList: 'Список доменов',
    domains: 'Домены',
    eapMethod: 'Метод EAP',
    emergencyServices: 'Доступность экстренных вызовов',
    group: 'Группа',
    hessid: 'HESSID',
    hotspotWizard: 'Создание Hotspot 2.0',
    hs20profiles: 'Профили HS 2.0',
    internet: 'Интернет',
    ipAvailability: 'Доступность IP адресов',
    language: 'Язык',
    naiRealmList: 'Список NAI Realm',
    network: 'Сеть',
    networkType: 'Тип сети',
    operatingClassIndication: 'Индикация рабочих каналов',
    operatorFriendlyNameList: 'Отображаемое имя оператора/провайдера',
    port: 'Порт',
    protocol: 'Протокол',
    realm: 'NAI Realm',
    roaming: 'Роуминг',
    roamingConsortiumList: 'Список Roaming Consortium',
    status: 'Статус',
    threeGPP: 'Список сотовых сетей 3gpp',
    type: 'Тип',
    unauthenticatedEmergency: 'Доступность экстренных вызовов для неавторизованных пользователей',
    venue: 'Локация',
    notificationHotspotUpdatedSuccessfully: 'Hotspot успешно обновлен',
    notificationHotspotCreatedSuccessfully: 'Hotspot успешно создан',
    notificationHotspotDeletedSuccessfully: 'Hotspot успешно удален',
    notificationRADIUSOperationSuccessfull: 'Успешно'
  },
  licenses: {
    avgValueCaption: 'Среднее значение',
    avgValuesCaption: 'Средние значения',
    forTheSelectedPeriodCaption: 'за выбранный период',
    licenses: 'Лицензии',
    licensesAllConnected: 'Подключенные Точки доступа, Wi-Fi Таргет и Тепловые Карты',
    licensesStats: 'Статистика подключенных лицензий',
    licensesStatsClose: 'К Лицензиям',
    types: {
      heatmap: 'Тепловые карты',
      cpes: 'Точки доступа',
      radar: 'Wi-Fi Таргет'
    },
    notificationNewLicenseSuccessfullyAdded: 'Новая лицензия успешно добавлена',
    notificationNewLicenseFileSuccessfullyAdded: 'Новый файл лицензии успешно добавлен',
    notificationWrongLicenseCode: 'Неверный код лицензии',
    notificationWrongLicenseFile: 'Неверный файл лицензии'
  },
  location: {
    deleteLocationDescriptionMsg:
      'Все объекты удаляемой локации перейдут в локацию верхнего уровня. Например, при удалении локации "/wimark/office" все ТД локации "/office" перейдут в локацию "/wimark"',
    innValidatorErrorMsg: 'Поле ИНН может содержать только цифры',
    ogrnValidatorErrorMsg: 'Поле ОГРН может содержать только цифры',
    kppValidatorErrorMsg: 'Поле КПП может содержать только цифры',
    byUser: 'пользователем',
    someFieldsHaveErrors: 'в некоторых полях есть ошибки',
    innCaptionForTable: 'ИНН',
    companyCaptionForTable: 'Компания',
    phoneCaptionForTable: 'Тел.',
    searchAddressCaption: 'Поиск адреса',
    searchСompanCaption: 'Поиск компании',
    addressNotFound: 'Адрес не найден',
    enterAddressToSearch: 'Введите адрес для поиска координат на карте',
    companyNotFound: 'Компания не найдена',
    enterCompanyNameToSearch: 'Введите имя компании для поиска',
    sameAsSelecteted: 'Скопировать настройки из',
    interfaceColorsAndLogo: 'Логотип и цвета интерфейса',
    mapCaption: 'Карта',
    phoneRequiredErrorMsg: 'Необходимо заполнить поле Телефон, т.к по нему происходит билинг',
    formal_name: 'Формальное название',
    inn: 'ИНН',
    kpp: 'КПП',
    name: 'Название',
    ogrn: 'ОГРН',
    phone: 'Телефон',
    company: 'Компания',
    locId: 'Id локации',
    address: 'Адрес',
    coords: 'Координаты',
    coordsLat: 'шир.',
    coordsLatFull: 'Широта',
    coordsLng: 'долг.',
    coordsLngFull: 'Долгота',
    created: 'Создано',
    delete: 'Удалить локацию',
    deleteText:
      'При удалении локации также будут удалены все дочерние локации и все пользователи таких локаций. Пожалуйста, подтвердите удаление.',
    descendant: "Для создания вложенных локаций используйте символ '/' как разделитель",
    description: 'Описание',
    edit: 'Редактировать локацию',
    list: 'Список локаций',
    limits: {
      header: 'Ограничения',
      heatmap: 'Тепловые карты',
      cpe: 'Точки доступа',
      portal: 'Портал',
      radar: 'Wi-Fi Таргет'
    },
    manager: 'Ответственное лицо',
    managerCaption: 'Если поле не заполнено, Вы будете назначены ответственным лицом',
    managerShort: 'Ответственный',
    new: 'Добавить локацию',
    owner: 'Владелец',
    path: 'Имя локации (путь)',
    custom_styles_enable: 'Включить изменение цветов для локации',
    custom_logo: 'Специальный логотип для локации',
    custom_colors: 'Изменение цветов',
    header: 'Хэдер',
    sidebar: 'Боковое меню',
    footer: 'Футер',
    main_block: 'Основной блок',
    background_color: 'Цвет фона',
    text_color: 'Цвет текста',
    active_menu_item_color: 'Цвет активного пункта меню',
    hover_menu_item_color: 'Цвет пункта меню при наведении мыши',
    menu_item_text_color: 'Цвет текста пункта меню',
    img_upload_error: 'Файл должен быть изображением и его размер должен быть меньше 300kB',
    notificationLocationCreatedSuccessfully: 'Локация успешно создана',
    notificationLocationUpdatedSuccessfully: 'Локация успешно обновлена',
    notificationLocationDeletedSuccessfully: 'Локация успешно удалена'
  },
  login: {
    inactivityLogoutInfoModalHeader: 'Произошел автоматический выход',
    inactivityLogoutInfoModalText: 'Произошел автоматический выход, так как пользователь не был активен в течение {minutes} мин.',
    customPasswordValidation: 'Поле может содержать только следующие спецсимволы: _ - # $ & ^ * @ !',
    login: 'Войти',
    loginHeader: 'Вход',
    password: 'Пароль',
    signHeader: 'Войдите в аккаунт',
    support: 'поддержка',
    username: 'Имя пользователя',
    resetPassword: 'Сбросить пароль',
    resetPasswordHeader: 'Сбросить пароль',
    resetPasswordHeaderCaption: 'Введите имя пользователя, чтобы сбросить пароль',
    resetPasswordPageSendButton: 'Отправить ссылку для сброса пароля',
    resetPasswordPageBackButton: 'Назад',
    resetPasswordPageSuccessMessage: 'Ссылка на сброс пароля отправлена на ваш e-mail.',
    resetPasswordNewPasswordHeader: 'Новый пароль',
    resetPasswordNewPasswordCaption: 'Введите новый пароль',
    resetPasswordNewPasswordSubmitButton: 'Отправить',
    resetPasswordNewPassword: 'Введите новый пароль.',
    resetPasswordNewPasswordConfirm: 'Введите новый пароль еще раз.',
    resetPasswordNewPasswordConfirmationError: 'Пароли не совпадают.',
    resetPasswordNewPasswordSuccessMessage: 'Новый пароль был успешно установлен.',
    resetPasswordNewPasswordTokenExpiredMessage: 'Токен просрочен. Запросите сброс пароля еще раз',
    passwordSuccessfullySet: 'Пароль успешно установлен',
    goToLoginButton: 'Перейти на страницу логина',
    newPasswordField: 'Новый пароль',
    newPasswordConfirmationField: 'Подтверждение нового пароля'
    // passwordValidationMessage: 'Пароль может содержать только следующие специальные символы: _ - # $ & ^ * @ !'
  },
  maps: {
    highlightCPEsInfoCaption: 'Подсветка информации о ТД',
    highlightCPEsInfoHint: 'Подсвечивает информацию о ТД на карте для лучшей читаемости. Позволяет улучшить видимость на черно-белых картах',
    heatmapLegendHeader: 'Легенда (посетители)',
    noData: 'нет данных',
    sortTypeName: 'По имени',
    sortTypeCreated: 'По времени созд.',
    sortTypeUpdated: 'По времени обновл.',
    filteringNightClientsModalCaption: 'Фильтрация посетителей',
    filteringNightClientsModalText:
      'Режим фильтрации посетителей позволяет отфильтровать посетителей, замеченых в нерабочее время объекта. Для возможности включения необходимо установить три настройки времени в окне редактирования карты (начало, окончание, часовой пояс)',
    filteringNightClientsCaption: 'Фильт. посетит.',
    workTimeTimezoneMultiselectPlaceholder: 'Выберете часовой пояс',
    workTimeStartPlaceholder: 'в формате ЧЧ:ММ',
    workTimeStopPlaceholder: 'в формате ЧЧ:ММ',
    workTimeCaption: 'Настройки времени работы',
    workTimeHint:
      'Позволяет установить время работы объекта для лучшей фильтрации данных посетителей. Для работы нужно заполнить все три поля.',
    workTimeStart: 'Начало',
    workTimeStop: 'Окончание',
    workTimeTimezone: 'Часовой пояс',
    workTimeStartHint: 'Время начала работы в формате ЧЧ:ММ (например 10:00)',
    workTimeStopHint: 'Время окончания работы в формате ЧЧ:ММ (например 21:00)',
    noCpeConnectedUsersToDisplay: 'Нет подключенных клиентов',
    cpeUsers: 'подключенные клиенты',
    cpeInfoAboutUsers: 'Информация о клиентах ТД',
    cpeStatisticMemory: 'Пам.',
    cpeStatisticCPULoad: 'ЦП',
    cpeStatisticUsers: 'Клиенты',
    Clients: 'Посетители',
    Created: ' Дата создания',
    byCPEs: 'по ТД',
    close: 'закрыть',
    connected: 'подк.посетит.',
    editScaleMode: 'режим изм.масштаба',
    found: 'Найдено посетителей',
    foundOne: 'Найден',
    heatmap: 'тепл.карта',
    lastSeen: 'Посл. появление',
    lastUpdated: 'Последнее обновление',
    layersControl: 'Слои',
    mapActions: 'Режимы',
    passerby: 'прохожие',
    planingMode: 'режим планирования',
    refresh: 'обновить',
    showCPEs: 'ТД',
    showClients: 'Посетители',
    showZones: 'Зоны',
    showMode: 'режим просмотра',
    showPath: 'показ. путь',
    timeline: 'динамика',
    timelineTitle: 'Временной график по Посетителям',
    traffic: 'трафик',
    user: 'посетитель',
    users: 'посетителей',
    vendor: 'Вендор',
    playButton: 'Старт',
    stopButton: 'Стоп',
    onlyOnePointInPath: 'В пути только одна точка.',
    cpeShowMore: 'больше информации',
    notFound: 'не найдено',
    lastConnected: 'Последнее подключение',
    cpe: 'Точка',
    zoneEditMode: 'режим изм.зон',
    zonesAddNewName: 'Имя для новой зоны',
    zoneNameCaption: 'Имя зоны',
    zoneName: 'Имя зоны',
    zoneCreating: 'Новая зона',
    error: 'Ошибка',
    zoneIntersectionError: 'Зоны не должны пересекаться',
    zoneNameCaptionInZoneTooltip: 'Зона',
    zoneClientsCounterCaptionInZoneTooltip: 'Посетители',
    notificationMapCreatedSuccessfully: 'Карта успешно создана',
    notificationMapDeletedSuccessfully: 'Карта успешно удалена',
    notificationMapCPEpositionupdated: 'Позиция точки доступа успешно обновлена',
    notificationMapZonesUpdated: 'Зоны успешно обновлены',
    notificationMapScaleUpdated: 'Масштаб карты успешно обновлен',
    addMapButton: 'Добавить карту',
    allLocations: 'Все локации',
    mapsList: 'Список карт',
    availableCPEs: 'Доступные ТД',
    noAvailableCPEs: 'Нет доступных ТД',
    cpeNamePlaceHolder: 'Имя ТД',
    saveAndHandleClients: 'Сохранить изменения',
    changedCPEsModalTitle: 'Есть несохраненные изменения',
    changedCPEsModalText:
      'Есть несохраненные изменения точек доступа. Вы уверены, что хотите закрыть режим планирования',
    closeAndShowOtherMaps: 'закрыть и показать другие карты',
    closeCurrentMap: 'закрыть текущую карту',
    cpeControlTitle: 'Список ТД',
    scaleModeDistance: 'Дистанция',
    scaleModeSetTwoPoints: 'Сначала установите две точки на карте',
    scaleModeTypeDistance: 'Теперь введите дистанцию между ними',
    scaleModePlaceholder: 'Введите дистанцию',
    scaleModeSave: 'Сохранить',
    scaleModeDangerText: 'Все предыдущие данные о посетителях будут недоступны',
    scaleModeOf: 'из',
    enterZoneNamePlaceholder: 'Введите имя зоны',
    newZoneNameError: 'Имя зоны должно быть больше 0 и меньше чем 22 символа',
    dynamicsTitle: 'Динамика',
    countersTitle: 'Счетчики',
    statisticsTitle: 'Статистика',
    passerbyHint: 'Посетители проходящие около точки доступа',
    connectedHint: 'Подключенные к Wi-Fi посетители',
    trafficHint: 'Данные по трафику',
    clientCoordsButton: 'посетители.',
    clientCoordsHint: 'Последние координаты путей посетителей',
    mapsWizardTitle: 'Создание карты',
    mapsWizardStep1Title: 'Выберете файл изображение для карты',
    mapsWizardStep2Title: 'Заполните обязательные поля',
    mapsWizardStep3Title: 'Настройки карты',
    mapsWizardWidth: 'ширина',
    mapsWizardHeight: 'высота',
    mapsWizardPx: 'пикс',
    mapsWizardFillMapParameters: 'Заполните параметры карты',
    enterMapNamePlaceholder: 'Введите имя карты',
    locationPlaceholder: 'Выберите локацию',
    saveAndCreateButton: 'Сохранить и создать',
    setMapUnitsPerPixel: 'Установите единицы карты',
    setMapUnitsPerPixelNew: 'Задайте масштаб',
    deleteMapModalTitle: 'Удалить карту',
    deleteMapModalText: 'Вы действительно хотите удалить карту',
    bublePasserbyClients: 'прохожие',
    bubleConnectedClients: 'подкл. посетители',
    bubbleRX: 'вх.',
    bubbleTX: 'исх.',
    cpePopUpID: 'ID',
    cpePopUpModel: 'Модель',
    cpePopUpIP: 'IP',
    cpePopUpMAC: 'MAC',
    cpePopUpWifiRadar: 'Wi-fi Таргет',
    timelinePasserbyTitle: 'Временной график по Прохожим',
    timelinePasserbyTooltipCaption: 'Прохожие',
    timelineConnectedTitle: 'Временной график по Подкл. посетит.',
    timelineConnectedTooltipCaption: 'Подкл. посетит.',
    timelineTrafficTitle: 'Временной график по Трафику',
    timelineTrafficTooltipCaption: 'Трафик',
    passerbyCounterTitle: 'Прохожие',
    connectedCounterTitle: 'Подключенные',
    trafficCounterTitle: 'Трафик',
    editMapModalTitle: 'Редактирование карты',
    editMapModalOpenButton: 'Редактировать',
    tableOutOf: 'карт из',
    serchByClientMAC: 'Поиск посетителей по MAC адресу',
    zonesForTable: 'Зоны',
    cpesTooltipForTable: 'ТД',
    zonesTooltipForTable: 'Зоны',
    noCpeData: 'Нет данных о ТД',
    noCpeDataFull: 'Нет данных о ТД. Возможно ТД из локации, недоступной вам',
    availableCPEHint: 'Точки доступа в той же локации что и карта и которых еще нет на карте'
  },
  marketing: {
    avgLoadingDataTimeMsg: 'Среднее время ожидания загрузки данных с выбраным выбранным интервалом составляет',
    infoAboutWifiTargetBegin: 'При выбранных условиях фильтрации имеется ',
    infoAboutWifiTargetEnd: 'c включенным Wi-FI Таргет',
    visits: 'Визиты',
    columnPercentsForDownloads: 'Проценты',
    columnNameForDownloads: 'Название',
    columnValueForDownloads: 'Значение',
    dateForDownloads: 'Дата',
    allVisitors: 'Все',
    showDescription: 'показать описание',
    hideDescription: 'скрыть описание',
    selectLocationPlaceholder: 'Выберите Локацию',
    selectCompanyPlaceholder: 'Выберите Компанию',
    datePickerCaption: 'Диапазон дат',
    workTimeFilterEnabled: 'вкл.',
    workTimeTimezoneNoSelectError: 'Поле Часовой пояс обязательно для заполнения',
    workTimeTimezoneMultiselectPlaceholder: 'Выберете часовой пояс',
    workTimeStartPlaceholder: 'в формате ЧЧ:ММ',
    workTimeStopPlaceholder: 'в формате ЧЧ:ММ',
    workTimeBlockCaption: 'Фильтрация по времени работы',
    workTimeHint: 'Позволяет установить время работы для фильтрации данных.',
    workTimeStart: 'Начало работы',
    workTimeStop: 'Окончание работы',
    workTimeTimezone: 'Часовой пояс',
    workTimeStartHint: 'Время начала работы в формате ЧЧ:ММ (например 10:00)',
    workTimeStopHint: 'Время окончания работы в формате ЧЧ:ММ (например 21:00)',
    // createExportButtonInfo: 'Позволяет перейти в раздел Сегмент Wi-Fi Таргет и приступить к созданию новой выгрузки используя выбранные Точки доступа, Локацию и диапазон дат',
    createExportButtonInfo:
      'Позволяет перейти в раздел Сегментов Wi-Fi Таргет и приступить к созданию нового сегмента используя выбранные Точки доступа, Локацию и диапазон дат',
    // createExportButtonCaption: 'Создать выгрузку',
    createExportButtonCaption: 'Создать сегмент',
    allRadars: 'Все Wi-Fi Таргет',
    radar: 'Wi-Fi Таргет',
    all: 'Все ТД',
    allCpes: 'Все ТД',
    allCpesSelected: 'Выбраны все ТД',
    avgDwellTime: 'Ср. продолж. визита',
    avgVisitFreq: 'Ср. частота визитов',
    byDefault: 'по умолчанию',
    cVisits: 'Визиты',
    cVisitsVisitors: 'Посетители',
    count: 'Кол-во',
    counters: 'Кассы',
    cpes: 'ТД',
    date: 'Время',
    deleteBenchmark: 'удалить сравнение',
    downloadData: 'выгрузить данные',
    exitWithoutPurchase: 'Выход без покупки',
    fittingRoom: 'Примерочные',
    footTraffic: 'Посещаемость',
    h: 'ч.',
    heatmap: 'Тепловые карты',
    housewares: 'Товары для дома',
    location: 'Локация',
    mall: 'Магазин',
    menDept: 'Мужской отдел',
    min: 'мин.',
    new: 'Новые',
    percent: 'Процент',
    rVbyF: 'Анализ повторных визитов',
    repeat: 'Повторные',
    repeatVisitors: 'Повторные посетители',
    report: 'Отчет',
    sales: 'Покупатели',
    sec: 'сек.',
    submit: 'Обновить',
    timeframe: 'Период',
    trafficByZone: 'Детализация по отделам',
    uniqueVisitors: 'Уникальные посетители',
    vendor: 'Производитель',
    vendors: 'Производители',
    venue: 'Магазин',
    visitors: 'Посетители',
    visits1: '1 визит',
    visits10: '>= 10 визитов',
    visits2: '2 визита',
    visits34: '3-4 визита',
    visits59: '5-9 визитов',
    withS: 'Остановились около касс',
    withoutS: 'Вышли без покупки',
    womenDept: 'Женский отдел',
    zone: 'Отделы'
  },
  misc: {
    current: 'Выбранные',
    items: 'Элементы',
    none: 'Нет',
    no_errors: 'Нет ошибок',
    whitelist: 'Whitelist',
    blacklist: 'Blacklist',
    no_data: 'Нет данных',
    no_name: 'Нет имени',
    $gt: 'Больше чем',
    $lt: 'Меньше чем',
    $eq: 'Равно'
  },
  monitor: {
    addRule: 'Добавить Правило',
    analyzerInfoMessage:
      'Анализатор WIFI работает только если в настройках интерфейса включено Сканирование радиоэфира.',
    clientsEvents: 'Клиентские события',
    cpesEvents: 'События ТД',
    cpesState: 'Статус ТД',
    cpeState: 'Статус ТД',
    cpuLoad: 'Загр. процессора',
    createRule: 'Создание Правила',
    data: 'Данные',
    enterRuleName: 'Введите имя правила',
    error: 'Ошибок',
    freeRAM: 'Своб. память',
    info: 'Событий',
    lastScanDate: 'Время последнего сканирования: ',
    range: 'Диапазон',
    ruleConfirmDeleting: 'Подтвердите удаление',
    ruleDelete: 'Удаление Правила',
    ruleDescription:
      'Укажите границу правила (красная линия). При заходе значений на данный диапазон будет приходить уведомление.',
    ruleEvents: 'Правила',
    ruleParameters: 'Заполните требуемые поля',
    ruleRange: 'Диапазон Правила',
    ruleScript: 'Скрипт',
    ruleType: 'Тип Правила',
    ruleWizard: 'Создание Правила',
    rules: 'Правила',
    script: 'Скрипт',
    sessionConnected: 'По макс. времени работы',
    sessionDisconnected: 'По мин. времени работы',
    sessions: 'Время работы',
    systemEvents: 'События системы',
    warning: 'Предупреждений',
    wifiAnalyzer: 'Анализ Wi-Fi',
    notificationRuleCopyingScriptWasSuccessful: 'Скрипт успешно скопирован',
    notificationRuleCopyingScriptWasUnsuccessful: 'Ошибка при копировании скрипта',
    notificationRuleCreatedSuccessfully: 'Правило успешно создано'
  },
  pageprofile: {
    'themeType-default': 'по умолчанию',
    'themeType-minimalistic': 'минималистичная',
    'themeType-default-beeline': 'по умолчанию-beeline',
    'themeType-minimalistic-beeline': 'минималистичная-beeline',
    themeTypeMinimalisticOrNot: 'Вид темы',
    add: 'Добавить Страницу',
    back: 'Фон',
    background: 'Фоновое изображение',
    backgroundInPreview: 'Фон',
    contrast: 'Контрастная',
    edit: 'Редактирование Страницы',
    openPage: 'Открыть страницу',
    enterAgreement: 'Пожалуйста, вставьте текст соглашения в поле ниже (в виде обычного текста)',
    facebook: 'Facebook',
    free: 'Бесплатный',
    hasAgreement: 'Есть пользовательское соглашение',
    hasSupport: 'Есть контакты службы поддержки',
    hotel_voucher: 'Отельный ваучер',
    instagram: 'Instagram',
    interface: 'Интерфейс',
    isExternalUrl: 'Внешний ресурс',
    light: 'Светлая',
    logo_footer: 'Логотип внизу',
    logo: 'Логотип',
    main_color: 'Основной цвет',
    new: 'Новая Страница',
    pages: 'Страницы',
    portalurl: 'Ссылка на портал',
    portalurlCaption: 'Используйте вместе со своим доменом на странице настройки Гостевой контроль\n',
    portalurlID: 'ID для ссылки на портал',
    preview: 'Предпросмотр Портала',
    saveurl: 'Сохраните этот url. Он потребуется в редиректе',
    sponsor: 'Спонсорский',
    staff: 'Служебный',
    support: 'Контакты будут отображены пользователю Портала в случае ошибки',
    theme: 'Цветовая тема',
    themeType: 'Тип темы',
    title: 'Заголовок страницы',
    ToS: 'Условия использования',
    urlToExternalTOS: 'URL внешнего ресурса с Условиями использования',
    urlToFileTOS: 'URL с Условиями использования',
    vk: 'VK',
    voucher: 'Платный доступ по ваучерам',
    subscription: 'Доступ по подписке',
    warnUsage: 'Пожалуйста, обратите внимание, что удалении Страницы, которая используется Порталом, приведёт к {0}.',
    warnUsageAccent: 'некорректной работе Портала',
    imageUploadedNotification: 'Изменения изображений сохранятся только после нажатия на кнопку Сохранить внизу окна.',
    imagesBlock: 'Изображения',
    authorizationButton: 'Окно авторизации',
    changeColorAlert: 'Тема в окне предпросмотра изменится после сохранения настроек!',
    schedule: 'Расписание',
    dayOfWeek: 'День недели',
    timeOfDay: 'Время дня',
    noSchedule: 'Нет расписания',
    timeRange: 'Расписание',
    scheduleAShow: 'Запланировать',
    deleteSchedule: 'Удалить расписание',
    scheduleInfoMessage: 'Для сохранений настроек расписания необходимо нажать кнопку Сохранить',
    hoursforShowing: 'Часы для показа',
    dateRangeforShowing: 'Диапазон дат для показа',
    scheduleEmpty: 'Страницы с включенным расписанием на указанную дату отсутствуют',
    showHours: 'Часы показа',
    showHoursAllDay: 'весь день',
    showHoursNotAllDay: 'не весь день',
    pageClonedSuccessfully: 'Страница успешно скопирована',
    pageCreatedSuccessfully: 'Страница успешно создана',
    pageUpdatedSuccessfully: 'Страница успешно обновлена',
    pageDeletedSuccessfully: 'Страница успешно удалена',
    scheduleClonedSuccessfully: 'Расписание успешно скопировано',
    scheduleCreatedSuccessfully: 'Расписание успешно создано',
    scheduleUpdatedSuccessfully: 'Расписание успешно обновлено',
    scheduleDeletedSuccessfully: 'Расписание успешно удалено',
    pageScheduleStatusTextForTooltipAciveAndDayMatch: 'Показ идет',
    pageScheduleStatusTextForTooltipAciveAndDayNotMatch:
      'В выбранный день показ не производится из-за настроек дня недели для показа'
  },
  paymentSystems: {
    add: 'Добавить метод пополнения баланса',
    directions: 'Инструкция',
    edit: 'Редактирование метода пополнения баланса',
    hashKey: 'Ключ хэша',
    head: 'Заголовок инструкции',
    identity: 'Внутренний ID',
    identityCaption: 'На текущий момент доступны следующие ID: Humo, MegafonLife, Terminal',
    image: 'Изображение',
    merchant: 'Мерчант',
    merchantInfo:
      'Уникальный номер, передаваемый поставщику Платежной системы. Нужен при пользовании API пополнения виртуального баланса платного Wi-Fi',
    merchantCaption: 'ID должен быть уникальным',
    name: 'Название',
    new: 'Новый метод пополнения баланса',
    preview: 'Предпросмотр',
    profile: 'Профиль',
    templateHash: 'Хеш шаблона',
    templateURL: 'URL шаблона',
    title: 'Платежные системы',
    tranUnique: 'Проверять ID транзакции на уникальность',
    type: {
      ext: 'Внешний шлюз',
      ext_url: 'Внешний шлюз с генерацией URL',
      guide: 'Инструкция',
      type: 'Тип'
    },
    warnUsage:
      'Пожалуйста, обратите внимание, что удаление метода пополнения баланса, который используется Порталом, приведёт к {0}.',
    warnUsageAccent: 'некорректной работе Портала',
    notificationPaymentSystemCreatedSuccessfully: 'Метод пополнения баланса успешно создан',
    notificationPaymentSystemUpdatedSuccessfully: 'Метод пополнения баланса успешно обновлен',
    notificationPaymentSystemDeletedSuccessfully: 'Метод пополнения баланса успешно удален'
  },
  portal: {
    gatewaySettings: {
      // hintForPOSTMethod: 'В URL и в Payload вы можете использовать следующие шаблонные переменные:\n {PHONE} - идентификатор клиента,\n {MESSAGE} - сообщение из поля "Текст одноразового кода" текущего профиля,\n {CODE} - одноразовый код',
      // hintForGETMethod: 'В URL вы можете использовать следующие шаблонные переменные:\n  {PHONE} - идентификатор клиента,\n {MESSAGE} - сообщение из поля "Текст одноразового кода" текущего профиля,\n {CODE} - одноразовый код',
      payload_textarea: 'Payload',
      blockHeader: 'Настройки шлюза отправки сообщений',
      method: 'Метод',
      methodNoSelected: 'Не выбран',
      url: 'URL адрес',
      content_type: 'Content type',
      headers: 'Заголовки',
      params: 'Параметры',
      add: 'Добавить',
      delete: 'Удалить',
      clear: 'Очистить',
      header: 'Имя заголовка',
      param: 'Имя параметра',
      value: 'Значение',
      hide: 'Скрыть',
      edit: 'Изменить',
      cancel: 'Отмена',
      save: 'Применить',
      noHeaders: 'Нет заголовков',
      noParams: 'Нет параметров'
    },
    thereAreUsersInList: 'В списке есть пользователи',
    noUsersInList: 'В списке нет пользователей',
    accessList: 'Список доступа',
    add: 'Добавить Профиль',
    adsFollow: 'Включить интернет после клика на рекламу(опрос)',
    adsRotation: 'Показывать рекламу(опросы) в случайном порядке',
    ads_to_watch: 'Кол-во реклам или опросов для просмотра',
    allPrefix: 'Любой префикс',
    auth: {
      attachedURL: 'Прикрепленная ссылка',
      groupURL: 'Ссылка на группу',
      message: 'Сообщение',
      redirectURL: 'Сайт для перехода'
    },
    authGroup: 'Авторизация и подписка на группу',
    authOnly: 'Авторизация',
    authPost: 'Авторизация и публикация поста',
    auth_timeout: 'Таймаут авторизации',
    authentication: 'Идентификация',
    authorization: 'Авторизация',
    blackList: 'Список заблокированных MAC',
    block_after: 'Время пользования Интернетом до блокировки (в сек.)',
    block_afterCaption: 'Должно быть заполнено, если заполнено поле ниже',
    block_expire: 'Время сброса данных о блокировке (в сек.)',
    block_expireCaption: 'Должно быть заполнено, если заполнено поле выше',
    code: 'Код страны',
    download_limit: 'Лимит скачивания (в кб.)',
    duration3Days: '3 дня',
    edit: 'Редактирование Профиля',
    errorMessage: 'Сообщение об ошибке при валидации',
    errorMessageCaption: 'Сообщение об ошибке, отображаемое при ошибке валидации',
    max_sessions: 'Максимальное кол-во сессий',
    mustContainCode: 'Обязательно должен содержать: {CODE}',
    nasId: 'NAS ID',
    new: 'Новый Профиль',
    notification: 'Предупреждение об окончании времени сессии',
    notificationText: 'Текст сообщения',
    notificationTime: 'Время до окончания сессии (сек.)',
    otp: 'Длина One-Time Password',
    otpCodeText: 'СМС текст для ОТР',
    paginationAdsOutOf: 'из оставшихся',
    paginationLoad: 'Загрузить',
    paginationOf: 'из',
    paginationPagesOutOf: 'из оставшихся',
    paginationPaymentSystemsOutOf: 'из оставшихся',
    paginationProfilesOutOf: 'из оставшихся',
    paginationTariffsOutOf: 'из оставшихся',
    paginationUserAccountsOutOf: 'из оставшихся',
    paginationVouchersOutOf: 'из оставшихся',
    paymentSystems: 'Платежные системы',
    pollquestionValidationError: 'Не могут использоваться символы: $ (доллар), . (точка)',
    portalprofiles: 'Профили',
    prefix: 'Префикс',
    redirect: 'Адрес редиректа на лендинг',
    regex: 'Регулярное выражение для валидации',
    regexCaption: 'Регулярное выражение для валидации пользовательского идентификатора на Портале',
    remember: 'Время запоминания (в сек.)',
    rememberCaption: 'Время, на которое клиентская аутентификация запоминается и повторно не запрашивается',
    rememberQuick: 'Быстрый выбор:',
    skipFinalPage: 'Пропустить последнюю страницу',
    smsCodeValidationError: 'Текст должен содержать {CODE}',
    socialURLValidationError: 'URL должен содержать https://vk.com, https://facebook.com or https://instagram.com',
    timeout: 'Время сессии (в сек.)',
    tzCaption: 'Например: 3 (Москва), -4 (Нью-Йорк), 2 (Барселона)',
    voucherBuyHeader: 'Заголовок покупки ваучера',
    voucherBuyInfo: 'Информация о ваучерах',
    voucherCodeText: 'СМС текст для ваучеров',
    whiteList: 'Список разрешенных MAC',
    transferable: 'Перенос сессии',
    transferableInfo: 'Возможность разбить сессию на несколько в рамках одного дня'
  },
  portalBirthdays: {
    users: 'Пользователи',
    birthdays: 'Дни Рождения',
    selectedDate: 'Выбранная дата'
  },
  portalProfiles: {
    authenTypecallfront: 'Исходящий звонок',
    authenTypecallback: 'Бесплатный звонок',
    authenTypeuserpass: 'Персональный код',
    authenTypeesia: 'Госуслуги',
    authenTypeemail: 'Email',
    authenTypesms: 'СМС',
    countryCode: 'Код страны',
    phonePrefixOptional: 'Префикс (опционально)',
    lineCabinetAddressCaption: 'Адрес личного кабинета управления подпиской',
    lineCabinetCaption: 'Личный кабинет управления подпиской',
    lineCabinetEnableSwitcher: 'Включение личного кабинета управления подпиской',
    socialAuthRequiredFieldsNotForgetToFillNotificationMsg: 'не забудьте заполнить обязательные поля',
    generalSettingsForAllAuthZApplyButton: 'Применить',
    generalSettingsForAllAuthZInfoMsg:
      'Позволяют применить некоторые настройки сразу ко всем включеным авторизациям. После применения изменений необходимо сохранить Профиль нажатием кнопки "Сохранить"',
    generalSettingsForAllAuthZCaption: 'Общие настройки авторизаций (настройки сессии)',
    generalSettingsForAllAuthNApplyButton: 'Применить',
    generalSettingsForAllAuthNInfoMsg:
      'Позволяют применить некоторые настройки сразу ко всем включенным идентификациям. После применения изменений необходимо сохранить Профиль нажатием кнопки "Сохранить"',
    generalSettingsForAllAuthNCaption: 'Общие настройки идентификаций',
    checkAuthNsWhenAuthenSkipIsFalseErrorText:
      'При выключенном режиме "Пропускать идентификацию" должна быть включена хотя бы одна идентификация',
    authnSkipCaption: 'Пропускать идентификацию',
    authzDefaultCaption: 'Авторизация по умолчанию',
    callbackInfo: '',
    callfrontInfo: 'Идентификация с использованием исходящего от пользователя звонка',
    condition: 'Условия для работы',
    access: 'Кому доступен',
    otpCodeText: 'Текст одноразового кода',
    sessionSettings: 'Настройки сессии',
    advertismentSettings: 'Настройки показа рекламы или опроса',
    vouchersSettings: 'Настройки ваучера',
    nasForTable: 'NAS',
    customTimeInterval: 'Другой',
    chooseCustomInterval: 'Выберите произвольный интервал',
    profileEdit: 'Редактирование профиля',
    noDataToDisplay: 'нет данных',
    noDescription: 'нет описания',
    noCPEs: 'нет точек доступа',
    noWLANs: 'нет сетей Wi-Fi',
    noNAS: 'нет NAS',
    noAuthentication: 'нет идентификаций',
    noAuthorization: 'нет авторизаций',
    settingsForAuthZandAuthN: 'Настройки',
    cutDownButton: ' Свернуть ',
    showMacSettingsView: 'Показывать инструкцию при подключении частных MAC адресов',
    conditionInfo:
      'Профиль работает для указанных параметров. \nНельзя указывать одинаковые Условия работы портального профиля (списка WLAN, AP, NAS ID) на нескольких профилях - это приведет к непредсказуемому поведению!',
    accessInfo: 'Кому доступен или недоступен профиль',
    authenInfo: 'Способ идентификации (входа) в профиль на Портале авторизации. Доступно до 5 способов идентификаций.',
    otpInfo: 'Текст сообщения с одноразовым кодом (OTP). Обязательно должно содержать {CODE}',
    emailInfo: 'Идентификация с использованием e-mail. OTP код придет на указанную клиентом почту.',
    smsInfo: 'Идентификация с использованием SMS. OTP код придет на указанный пользователем телефон.',
    esiaInfo: 'Идентификация с использованием сайта Госуслуги',
    userpassInfo:
      'Идентификация с использованием заранее полученного кода. Доступна только для пользователей из Списка доступа.',
    tosInfo: 'Показывать пользователю Условия использования и спрашивать его согласие с ними',
    authInfo: 'Способ авторизации на Портале авторизации. Доступно до 5 способов авторизаций.',
    freeInfo: 'Бесплатный доступ к Интернету',
    staffInfo: 'Бесплатный доступ к Интернету для сотрудников',
    voucherInfo: 'Доступ к Интернету после ввода кода ваучера',
    sponsorInfo: 'Бесплатный доступ к Интернету от Спонсора',
    facebookInfo: 'Бесплатный доступ к Интернету после авторизации через Facebook',
    instagramInfo: 'Бесплатный доступ к Интернету после авторизации через Instagram',
    skipInfo: 'Бесплатный доступ в Интернет без авторизации',
    subscriptionInfo: 'Доступ в Интернет после оформления подписки',
    hotel_voucherInfo: 'Доступ к Интернету после ввода кода ваучера',
    vkInfo: 'Бесплатный доступ к Интернету после авторизации через VK',
    validatorErrors: 'Есть ошибки в заполнении полей. Проверьте все поля, в т.ч и в свернутых категориях',
    sameAsForAuthN: 'Такие же настройки',
    sameAsForAuthZ: 'Такие же настройки',
    notificationProfileCreatedSuccessfully: 'Профиль успешно создан',
    notificationProfileUpdatedSuccessfully: 'Профиль успешно обновлен',
    notificationProfileDeletedSuccessfully: 'Профиль успешно удален'
  },
  portalPreview: {
    fillData: 'Пожалуйста, заполните следующие поля',
    params: 'Параметры предпросмотра',
    portalLink: 'Ссылка на страницу портала',
    preview: 'Предпросмотр',
    default: 'Стандартная',
    minimalistic: 'Минималистичная'
  },
  portalSessions: {
    authZTypesubscription: 'Подписка',
    oneOrMoreDateTimeFiltersAreEnabled: 'один или несколько фильтров включены',
    dateTimeFiltersCaption: 'Фильтры по дате и времени',
    creationSessionForTable: 'Время создания сессии',
    expirationSessionForTable: 'Время истечения сессии',
    cpeTableCaption: 'ТД',
    wlanTableCaption: 'Сеть Wi-Fi',
    socialNetworksIdTableEmail: 'Email',
    socialNetworksIdTableBirthday: 'Дата рождения',
    socialNetworksIdTableCity: 'Город',
    socialNetworksIdTableCaption: 'Id',
    socialNetworksNameTableCaption: 'Имя',
    socialNetworksFirstNameTableCaption: 'Имя',
    socialNetworksSecondNameTableCaption: 'Фамилия',
    authZTimeBeginTableCaption: 'Начало авторизации пользователя',
    authZTimeEndTableCaption: 'Окончание авторизации пользователя',
    authZTypenotSelect: 'Все',
    authZTypenone: '',
    authZTypefree: 'Бесплатный',
    authZTypesponsor: 'Спонсорский',
    authZTypetype: 'Тип',
    authZTypevk: 'Вконтакте',
    authZTypevoucher: 'Ваучер',
    authZTypefacebook: 'Facebook',
    authZTypefb: 'Facebook',
    authZTypeinstagram: 'Instagram',
    authZTypehotel_voucher: 'Отельный ваучер',
    authZTypeskip: 'Skip',
    authZTypestaff: 'Служебный',
    authZTypeunset: 'Не указано',
    authZTypeTableCaption: 'Тип авторизации',
    authZStateunset: 'Все',
    authZStatenone: 'Авторизация не пройдена',
    authZStatechecked: 'Авторизация пройдена',
    authZStateTableCaption: 'Статус авторизации',
    sessionCreationDate: 'Время создания сессии',
    sessionCreationExpirationDate: 'Время создания сессии / Время истечения сессии',
    sessionIsDelitedunset: 'Все',
    sessionIsDelitedtrue: 'Удалена',
    sessionIsDelitedfalse: 'Не удалена',
    sessionIsDelitedTitleCaption: 'Сессия удалена',
    authenTypeunset: 'Все',
    authenTypecallfront: 'Исходящий звонок',
    authenTypecallback: 'Бесплатный звонок',
    authenTypeuserpass: 'Персональный код',
    authenTypeesia: 'Госуслуги',
    authenTypeemail: 'Email',
    authenTypesms: 'СМС',
    authenTypeTableCaption: 'Тип идентификации',
    authenStateunset: 'Все',
    authenStatesent: 'Ожидается ввод кода или звонок',
    authenStateneed: 'Идентификация не пройдена',
    authenStatechecked: 'Идентификация пройдена',
    authenStateTableCaption: 'Статус идентификации',
    tabeleViewTypeCaption: 'Вид отображаемых данных',
    tableViewTypestandardView: 'Стандартный',
    tableViewTypesocialNetworksView: 'Социальные сети',
    tableViewTypeauthenticationView: 'Идентификация',
    tableViewTypeauthorizationView: 'Авторизация',
    tableViewTypesubscriptionsView: 'Подписки',
    identityTableCaption: 'Идентификатор',
    stateTypeauthenticate: 'Идентификация',
    stateTypeauthorize: 'Авторизация',
    stateTypeadvertise: 'Реклама или опрос',
    stateTypepass: 'Пропущен в Интернет',
    stateTypeunset: 'Все',
    stateTableCaption: 'Статус сессии',
    isDeletedForDownloadsYes: 'Да',
    isDeletedForDownloadsNo: 'Нет',
    isDeletedForDownloadsForTableCaption: 'Удалена',
    undoDeleteSessionModalUndoButton: 'Отменить удаление',
    undoDeleteSessionModalHeader: 'Отмена удаления сессии',
    undoDeleteSessionModalConfirm: 'Подтверждаете отмену удаления сессии',
    deleteSessionButtonCaption: 'Удалить сессию',
    undoDeleteSessionButtonCaption: 'Отменить удаление сессии',
    deleteSessionModalHeader: 'Удаление сессии',
    deleteSessionModalConfirm: 'Подтверждаете удаление сессии',
    costForTableInfo: 'Стоимость указана для сессий с типом идентификации СМС',
    costForTable: 'Стоимость СМС',
    allCpes: 'Все ТД',
    allWlans: "Все WLAN'ы",
    browser: 'Браузер',
    cpe: 'ТД',
    create_at: 'Дата',
    create: 'Дата',
    getSessions: 'Показать {num} сессий',
    ip: 'IP',
    mac: 'MAC',
    nas_id: 'NAS ID',
    os: 'ОС',
    osVersion: 'Версия ОС',
    profile: 'Профиль',
    sessions: 'Пользовательские сессии',
    title: 'Пользовательские сессии',
    wlan: 'WLAN',
    ua: {
      desktop: 'Десктоп',
      mobile: 'Смартфон',
      tablet: 'Планшет',
      bot: 'Бот'
    }
  },
  portalAccessServers: {
    validatorErrors: 'Есть ошибки в заполнении полей. Проверьте поля на корректность',
    notificationPortalAccessServerUpdatedSuccessfully: 'Сервер Доступа успешно обновлен',
    notificationPortalAccessServerCreatedSuccessfully: 'Сервер Доступа успешно создан',
    notificationPortalAccessServerDeletedSuccessfully: 'Сервер Доступа успешно удален',
    preAuthVsaForTable: 'Атрибуты предварит. авториз.',
    postAuthVsaForTable: 'Атрибуты, отправляемые после авториз.',
    coaVsaForTable: 'Атрибуты CoA авториз.',
    modalLocationFieldPlaceholder: 'Местоположение',
    modalPostAuthVsaFieldPlaceholder: 'Атрибуты, отправляемые после авторизации',
    modalPreAuthVsaFieldPlaceholder: 'Атрибуты предварительной авторизации',
    modalExternalIPFieldPlaceholder: 'Внешний IP',
    modalCoaVsaFieldPlaceholder: 'Атрибуты CoA авторизации',
    modalCoaSecretFieldPlaceholder: 'CoA Пароль',
    modalCoaPortFieldPlaceholder: 'CoA Порт (3799 по-умолчанию)',
    modalPasswordFieldPlaceholder: 'Пароль',
    modalLoginFieldPlaceholder: 'Логин',
    modalNasIpFieldPlaceholder: 'NAS IP адрес',
    modalNasIdFieldPlaceholder: 'NAS ID',
    modalDescriptionFieldPlaceholder: 'Описание',
    modalNameFieldPlacheolder: 'Имя Сервера Доступа',
    modalUpdateAccessServerHeader: 'Редактирование Сервера Доступа',
    modalCreateAccessServerHeader: 'Добавление Сервера Доступа',
    createAccessServerButtonCaption: 'Добавить Сервер Доступа',
    noForTable: 'нет',
    tableCaption: 'Серверы доступа',
    bson: 'bson',
    coaPort: 'CoA Порт',
    coaSecret: 'CoA Пароль',
    coaVsa: 'Атрибуты CoA авторизации',
    description: 'Описание',
    externalIP: 'Внешний IP',
    location: 'Местоположение',
    login: 'Логин',
    macAuth: 'MAC Авторизация',
    name: 'Имя',
    nasId: 'NAS ID',
    nasIP: 'NAS IP',
    password: 'Пароль',
    preAuthVsa: 'Атрибуты предварительной авторизации',
    postAuthVsa: 'Атрибуты, отправляемые после авторизации',
    enable: 'Включено',
    enableForTable: 'вкл.',
    disableForTable: 'выкл.',
    type: 'Тип'
  },
  portalStats: {
    smsCostInfoMsg:
      'Этот показатель не зависит от фильтров выбора Локации и Профиля. При выборе чего-либо в этих фильтрах они игнорируются для этого показателя',
    historyData: 'Исторические данные',
    OS: 'ОС',
    accounts: 'Пользователи',
    allAccounts: 'Все пользователи',
    allProfiles: 'Все профили',
    allLocations: 'Все локации',
    allTariffs: 'Все тарифы',
    auth: {
      free: 'Бесплатный',
      sponsor: 'Спонсорский',
      type: 'Тип',
      vk: 'Вконтакте',
      voucher: 'Ваучер',
      facebook: 'Facebook',
      fb: 'Facebook',
      instagram: 'Instagram',
      hotel_voucher: 'Отельный ваучер',
      skip: 'Skip',
      staff: 'Служебный',
      unset: 'Не указано'
    },
    authFull: 'Способы авторизации',
    authen: {
      callfront: 'Исходящий звонок',
      userpass: 'Персональный код',
      email: 'E-mail',
      sms: 'СМС',
      type: 'Тип'
    },
    authenFull: 'Тип идентификации',
    average: 'Среднее значение',
    browser: 'Браузер',
    browserFull: 'Браузеры',
    desktop: 'Компьютер',
    locale: {
      en: 'Английский',
      locale: 'Языки',
      ru: 'Русский',
      tg: 'Таджикский'
    },
    mobile: 'Смартфон',
    newUsers: 'Новые пользователи',
    newAndRepeatUsers: 'Новые и повторные пользователи',
    notNewUsers: 'Повторные пользователи',
    os: 'Операционные системы',
    other: 'Другие',
    profiles: 'Профили',
    range: 'Интервал',
    selectAccount: 'Выберите пользователя',
    selectAccountPlaceholder: 'Выберите пользоват...',
    selectAds: 'Выберите рекламы',
    selectPage: 'Выберите страницу',
    selectProfile: 'Выберите профиль',
    selectLocation: 'Выберите локацию',
    selectLocationWithChild: 'С дочерними',
    profileSelection: 'Профили',
    selectRange: 'Выберите период',
    selectRules: 'Выберите правила',
    selectTariff: 'Выберите тариф',
    selectTheme: 'Выберите тему',
    selectWlan: 'Выберите WLAN',
    selectCpe: 'Выберите ТД',
    stats: 'Статистика',
    tariffs: 'Тарифы',
    totalConnections: 'Всего соединений',
    type: 'Тип',
    typeFull: 'Типы устройств',
    uniqClients: 'Уникальные клиенты',
    users: {
      new: 'Новые пользователи',
      repeat: 'Повторные пользователи',
      type: 'Пользователи'
    },
    usersForTable: {
      new: 'Новые',
      repeat: 'Повторные',
      type: 'Тип'
    },
    usersFull: 'Пользователи',
    vendors: 'Производители',
    vendorsForTable: 'Произв-ли',
    vendorsFull: 'Производители устройств',
    vouchers: 'Ваучеры',
    vouchersAmount: 'Всего ваучеров',
    realTimeData: 'Данные, обновляемые в реальном времени',
    nonRealTimeData: 'Данные, обновляемые не в реальном времени',
    dataToYesterdayInclusive: 'данные не включают сегодняшний день (данные по вчерашний день включительно)',
    percent: 'Процент',
    count: 'Кол-во',
    authenN: 'Идентификация',
    authenZ: 'Авторизация',
    showSelectPreviousPeriodBlockButton: 'Сравнить с другим периодом',
    compareWithPreviousPeriodButton: 'Сравнить',
    comparePeriodsPeriod: 'Выбраный период',
    comparePeriodsPeriodForCompare: 'Период для сравнения'
  },
  radar: {
    maximumCpesWithEnabledWiFiTargetSelectedErrorMoreModalHeader: 'Об ограничениях',
    maximumCpesWithEnabledWiFiTargetSelectedError:
      'Превышено ограничение на количество точек с включенным сбором данных Wi-Fi Таргет для заданного временного периода',
    maximumCpesWithEnabledWiFiTargetSelectedErrorMore: 'Подробнее об ограничениях',
    wifiTargetDisabledOnAllSelectedCPES:
      'Внимание! Все выбранные точки доступа с выключенным сбором данных Wi-Fi Таргет',
    wifiTargetEnabledShort: 'Wi-Fi Таргет вкл.',
    wifiTargetEnabled: 'Wi-Fi Таргет включен',
    cpes: 'Точки доступа',
    refreshButtonCaption: 'Обновить',
    exportNameForBeelineForModalCaption: 'Имя сегмента',
    settingsForBeelineForModalCaption: 'Настройки',
    createdForCTNForBeelineForTableCaption: 'Создана для клиента с CTN',
    createdForBeelineForTableCaption: 'Дата создания',
    audienceForBeelineForTableCaption: 'Аудитория',
    statusForBeelineForTableCaption: 'Статус обработки сегмента',
    exportNameForBeelineForTableCaption: 'Имя сегмента',
    tableHeaderForBeelineForTableCaption: 'Список сегментов',
    cabinet: 'В кабинет БиПро',
    user: 'Пользователь',
    businessIdErrorShow:
      'Необходимо чтобы был выбран пользователь с Business id (и Business id не должен быть равен 0)',
    ctnErrorShow: 'Необходимо чтобы был выбран пользователь с CTN',
    ctnCaption: 'CTN',
    selectedCTNCaption: 'Выбраный CTN',
    usersWithBusinessIdsPlaceholder: 'Выберите Пользователя или введите имя для поиска',
    settingsBeepro: 'Настройки БиПродвижения',
    businessIdsCaption: 'Business ID',
    selectedBusinessIdsCaption: 'Выбраный Business ID',
    addressesOrBusinessId: 'Адреса / Business ID',
    addressesOrCTN: 'Адреса / CTN',
    beepro: 'БиПродвижение',
    createCopy: 'создалть копию',
    wrongEmailMsg: 'Неправильный email',
    sortTypeName: 'По имени',
    sortTypeCreated: 'По времени созд.',
    sortTypeUpdated: 'По времени обновл.',
    dataRangeFilterTypeButtonCreated: 'Создана',
    dataRangeFilterTypeButtonUpdated: 'Обновлена',
    addressesCountCaption: 'Всего',
    addresses: 'Адреса',
    cpeListPlaceholder: 'Выберите точки доступа или введите имя для поиска',
    cpeListNoOptionsMsg: 'Нет точек доступа',
    cpeListNoOptionsMsgWhenFilterEnabled: 'Нет точек доступа, соответствующих условиям фильтров',
    cpeListNoResultMsg: 'Не найдено Точек Доступа с таким именем',
    cpeListNoResultMsgWhenFilterEnabled: 'Не найдено точек доступа с таким именем, соответствующих условиям фильтров',
    radarFiltringForCpeListResetFiltersButton: 'сбросить фильтр',
    radarFiltringForCpeListIsEnabled: 'вкл.',
    radarEnableStatusForCpeFilterCaption: 'Статус Wi-Fi Таргет на точке',
    radarEnableStatusForCpeFilterEnabled: 'Wi-Fi Таргет включен',
    radarEnableStatusForCpeFilterDisabled: 'Wi-Fi Таргет выключен',
    radarEnableStatusForCpeFilterAny: 'Любой',
    filtersForCpeListSwitcherLocationCaption: 'Локация точки доступа',
    filtersForCpeListSwitcherInfoTooltip:
      'Фильтрация упрощает поиск нужной точки доступа в списке выбора точек доступа. Позволяет отфильтровать точки доступа по их локации и по состоянию включения или отключения Wi-Fi Таргет на них',
    filtersForCpeListSwitcherCaption: 'Фильтрация списка точек доступа',
    // deleteModalTitle: 'Удаление выгрузки',
    deleteModalTitle: 'Удаление сегмента',
    deleteModalText: 'Подтвердите удаление',
    // addNew: 'Добавить выгрузку',
    addNew: 'Добавить сегмент',
    allStatuses: 'Все статусы',
    allVisitors: 'Все',
    audience: 'Аудитория',
    auto: 'Периодичность',
    autoPeriod: 'Периодичность обновления (в часах)',
    autoPlaceholder: 'Укажите периодичность',
    continuously: 'Непрерывно',
    created: 'Создана',
    description: 'Описание',
    descriptionPlaceholder: 'Укажите описание',
    email: 'Электронная почта',
    filter: 'Фильтр по посетителям',
    finished: 'Завершена',
    format: 'Формат',
    hash: 'Хэшировать данные',
    hashInfo: 'Маска данных, полученная путем шифрования. Служит для однозначного определения объекта или сущности',
    infinity: 'Без ограничения',
    items: 'Всего адресов',
    match: 'совпадение',
    matched: 'найдено',
    mytarget: 'myTarget',
    name: 'Название',
    // namePlaceholder: 'Укажите название выгрузки',
    namePlaceholder: 'Укажите название сегмента',
    newVisitors: 'Только новые',
    period: 'Период',
    processed: 'Обработана',
    returnVisitors: 'Только повторные',
    running: 'Запущена',
    selectPlaceholder: 'Выберите ТД',
    settingsEmail: 'Настройки E-mail',
    settingsExport: 'Настройки Экспорта',
    settingsGeneral: 'Общие настройки',
    share: 'Адреса',
    status: 'Статус',
    subject: 'Тема',
    subjectPlaceholder: 'Укажите тему',
    title: 'Сегменты Wi-FI Таргет',
    type: 'Тип',
    updated: 'Последнее обновление',
    valid: 'выгружено',
    yandex: 'Яндекс',
    yandexCaption: 'При типе экспорта «Яндекс» необходимо использовать адрес электронной почты формата «@yandex.ru»',
    // notificationRadarExportCreatedSuccessfully: 'Выгрузка успешно создана',
    // notificationRadarExportUpdatedSuccessfully: 'Выгрузка успешно обновлена',
    // notificationRadarExportDeletedSuccessfully: 'Выгрузка успешно удалена',
    notificationRadarExportCreatedSuccessfully: 'Сегмент успешно создан',
    notificationRadarExportUpdatedSuccessfully: 'Сегмент успешно обновлен',
    notificationRadarExportDeletedSuccessfully: 'Сегмент успешно удален',
    selectAllCpesButtonCaption: 'Добавить все отфильтр. точки',
    cancelSelectAllCpesButtonCaption: 'Очистить все выбранные точки',
    cpeSelectedCounterCaption: 'Всего выбрано',
    selectedCPEsCaption: 'Выбрано',
    usersManualLinkCaption: 'Инструкция пользователя'
  },
  radius: {
    daeSecretPlaceholder: 'Shared DAE secret',
    daePortPlaceholder: 'DAE порт (3799 по умолчанию)',
    daeClientPlaceholder: 'DAE клиент (ip адрес)',
    daePortHintInfoMsg: 'Порт Dynamic Authorization Extension сервера',
    daeSecretHintInfoMsg: 'DAE Секрет',
    daeClientHintInfoMsg:
      'Dynamic Authorization Extension клиент. Этот клиент может отправлять пакеты “Disconnect-Request” или “CoA-Request” чтобы принудительно отключить клиента или изменить парамтеры подключения. Wildcard IP (например 0.0.0.0) не работает',
    daeBlockHintInfoMsg: 'Dynamic Authorization Extension',
    daePort: 'DAE Порт',
    daeSecret: 'DAE Секрет',
    daeClient: 'DAE Клиент',
    daeSettings: 'Dynamic Authorization Extension (RFC 5176)',
    wizardAdvancedParameters: 'Дополнительные параметры',
    acctPortPlaceholder: 'Введите порт учета данных',
    authPortPlaceholder: 'Введите порт авторизации',
    secretPlaceholder: 'Введите пароль',
    namePlaceholder: 'Введите имя',
    ipPlaceholder: 'Введите адрес',
    // acctPort: 'Порт учета данных',
    acctPort: 'Порт аккаунтинга',
    authPort: 'Порт авторизации',
    addRadius: 'Добавить RADIUS',
    advancedSettings: 'Настройка дополнительных параметров',
    basicParameters: 'Настройка базовых параметров',
    confirmRadius: 'Проверка введенных данных',
    local: 'Локальный',
    port: 'Порт',
    portal: 'Портал',
    radius: 'RADIUS',
    radiusForSidebar: 'Серверы RADIUS',
    radiusList: 'Список RADIUS',
    radiusWizard: 'Создание RADIUS',
    secret: 'Пароль',
    password: 'Пароль',
    notificationRADIUSUpdatedSuccessfully: 'RADIUS успешно обновлен',
    notificationRADIUSCreatedSuccessfully: 'RADIUS успешно создан',
    notificationRADIUSDeletedSuccessfully: 'RADIUS успешно удален',
    notificationRADIUSOperationSuccessfull: 'Успешно',
    addressField: 'Адрес',
    addressFieldHint: 'IP адрес или доменное имя',
    addressInTableTitle: 'Адрес',
    deleteRADIUSModalTitle: 'Удалить RADIUS',
    confirmDeletingModal: 'Подтвердите удаление'
  },
  recommendations: {
    moveRecommendationsBottom: 'переместить вниз',
    moveRecommendationsTop: 'переместить вверх',
    knowledgeBase: {
      button: 'Перейти',
      text: 'Раздел с описанием основных разделов платформы, поможет вам познакомиться с платформой поближе',
      title: 'База знаний'
    },
    easyInstallWizard: {
      button: 'Начать',
      text: 'Пройдите шаги визарда для простой установки и настройки сети WLAN с порталом',
      title: 'Простая установка'
    },
    addAdds: {
      button: 'Настроить рекламу',
      text: 'Добавьте рекламу на Портал для увеличения продаж.',
      title: 'Добавьте рекламу'
    },
    addBanners: {
      button: 'Включить баннеры',
      text: 'Добавляйте рекламные баннеры, чтобы привлечь внимание посетителей Портала.',
      title: 'Добавьте баннеры'
    },
    addPolls: {
      button: 'Включить опросы',
      text: 'Опросы позволят узнать мнение посетителей Портала или развлечь их.',
      title: 'Добавьте опросы'
    },
    addPollsQuality: {
      button: 'Включить опросы качества',
      text: 'Опросы качества позволят узнать мнение посетителей Портала о чем угодно.',
      title: 'Добавьте опросы качества'
    },
    addPollsUserDataBirthday: {
      button: 'Настроить опросы',
      text: 'Запрос данных пользователя позволят узнать информацию о днях рождения посетителей Портала.',
      title: 'Добавить запрос данных пользователя'
    },
    // addRadarExport: {
    //   button: 'Настроить выгрузку',
    //   text:
    //     'Настройте периодическую выгрузку отчётов Wi-Fi Таргет, чтобы своевременно получать информацию о собранных данных.',
    //   title: 'Отчёты Wi-Fi Таргет'
    // },
    addRadarExport: {
      button: 'Настроить выгрузку сегментов',
      text:
        'Настройте периодическую выгрузку сегментов Wi-Fi Таргет, чтобы своевременно получать информацию о собранных данных.',
      title: 'Отчёты Wi-Fi Таргет'
    },
    addVideo: {
      button: 'Включить видео',
      text: 'Используйте видео для продвижения товаров и услуг.',
      title: 'Покажите видео'
    },
    allGood: {
      text: 'Все рекомендации выполнены.',
      title: 'Поздравляем!'
    },
    distressedCPEs: {
      button: 'Показать ТД',
      text:
        'Обнаружены Точки доступа с ошибками в работе. Необходимо проверить конфигурацию, исправить и/или перезапустить агент AP. Пожалуйста, обратитесь к администратору.',
      title: 'Ошибки ТД'
    },
    heading: 'Рекомендации',
    highCPULoad: {
      text: 'Загрузка процессора превысила 90%. Пожалуйста, обратитесь к администратору сервера.',
      title: 'Высокая загрузка процессора'
    },
    showAdsStats: {
      button: 'Просмотреть статистику',
      text: 'Узнайте, насколько эффективно работает Ваша реклама.',
      title: 'Статистика рекламы'
    }
  },
  reports: {
    types: {
      summary: 'За период',
      current: 'На текущий момент'
    },
    typestypeForClientsSubject: {
      summary: 'За период',
      current: 'На текущий момент',
      clients: 'Клиенты',
      clients_auth: 'Авторизованные клиенты'
    },
    subjects: {
      clients: 'Клиенты',
      events: 'События',
      cpes: 'Точки доступа',
      cpes_common: 'Обобщенная статистика по ТД'
    },
    formats: {
      csv: 'csv',
      json: 'json',
      xlsx: 'xlsx'
    },
    add: 'Добавить Отчет',
    addCharts: 'Добавить графики',
    addChartsCaption: "Доступно только для объекта выгрузки 'Обобщенная статистика по ТД' и типа отчёта 'xlsx'",
    collect_period: 'Период',
    confirmDeleting: 'Подтверждаете удаление',
    dayMonth: 'День',
    dayWeek: 'День недели',
    deleteReport: 'Удаление отчета',
    editReport: 'Редактирование отчета',
    format: 'Тип отчета',
    hour: 'Час',
    newReport: 'Новый отчет',
    period: {
      day: 'Каждый день',
      month: 'Каждый месяц',
      now: 'Сейчас',
      week: 'Каждую неделю'
    },
    placedescr: 'Введите описание отчета',
    placename: 'Введите имя отчета',
    subject: 'Объект выгрузки',
    type: 'Тип выгрузки',
    week: {
      0: 'Воскресенье',
      1: 'Понедельник',
      2: 'Вторник',
      3: 'Среда',
      4: 'Четверг',
      5: 'Пятница',
      6: 'Суббота'
    },
    notificationReportCreatedSuccessfully: 'Отчет успешно создан',
    notificationReportUpdatedSuccessfully: 'Отчет успешно обновлен',
    notificationReportDeletedSuccessfully: 'Отчет успешно удален',
    tableTooltipStart: 'С',
    tableTooltipStop: 'По',
    placeHolderAddEmail: 'Введите адрес электронной почты'
  },
  sidebar: {
    ConfigExportImport: 'Экспорт/Импорт конфигурации',
    PeriodicTasks: 'Периодические задачи',
    DBState: 'Состояние БД',
    DITStat: 'Статистика ДИТ',
    DITExport: 'Экспорт ДИТ',
    PortalAccessServers: 'Серверы доступа',
    SNMPStat: 'Статистика SNMP',
    SNMPMonitoringConfig: 'Конфигурация мониторинга по SNMP',
    cpu: 'ЦП',
    cpuLoad: 'Загрузка ЦП',
    badgeTooltipTotal: 'Всего',
    badgeTooltip24h: 'Всего (последние 24 часа)',
    badgeTooltipConnected: 'Подключенные',
    locations: 'Локации',
    accounts: 'Пользователи',
    ads: 'Реклама и опросы',
    analiticsAndData: 'Аналитика и данные',
    analytics: 'Аналитика',
    birthdays: 'Дни Рождения',
    buyers: 'Покупатели',
    clientDistance: 'Удаленность пользователя',
    clientRF: 'Client RF',
    clients: 'Клиенты',
    controllers: 'Контроллеры',
    cpes: 'Точки',
    dashboard: 'Состояние',
    events: 'События',
    export: 'Экспорт',
    firewall: 'Брандмауэр',
    GuestControl: 'Гостевой контроль',
    heatmap: 'Тепловые карты',
    hotelVouchers: 'Отельные ваучеры',
    hotspots: 'Hotspot 2.0',
    licenses: 'Лицензии',
    maps: 'Карты',
    monitor: 'Монитор',
    network: 'Сеть',
    nms: 'НМС',
    pages: 'Страницы',
    paidInternet: 'Платный интернет',
    paymentSystems: 'Платежные системы',
    portal: 'Портал',
    portalConfiguration: 'Конфигурация',
    preview: 'Предпросмотр',
    profiles: 'Профили',
    radar: 'Wi-Fi Таргет',
    // radius: 'RADIUS',
    radius: 'RADIUS серверы',
    reports: 'Отчёты',
    rrm: 'RRM',
    rules: 'Правила',
    scanner: 'Сканер',
    sessions: 'Найти сессию',
    settings: 'Настройки',
    statistics: 'Статистика',
    system: 'Система',
    systemInfo: 'Системная информация',
    tariffs: 'Тарифы',
    template: 'Шаблоны',
    troubleshooter: 'Подключение ТД',
    visitors: 'Посетители',
    vouchers: 'Ваучеры',
    wifiAnalyzer: 'Анализатор Wi-fi',
    wlans: 'Сети Wi-Fi',
    portalDashboard: 'Рабочий стол',
    support: 'База знаний'
  },
  statistics: {
    filteringByCPE: 'Фильтрация по ТД',
    goToClientsSettings: 'К настройкам Клиента',
    goToCPESettings: 'К настройкам ТД',
    userData: 'Трафик пользователей',
    transmitted: 'Исходящий',
    received: 'Входящий',
    transmittedShort: 'исх.',
    receivedShort: 'вх.',
    goToWLANSettings: 'К настройкам Wi-Fi сети',
    paginationLoad: 'Загрузить',
    paginationCPERaitngsOutOf: 'из оставшихся',
    macForDownload: 'MAC',
    authorizationDataForDownload: 'Данные авторизации',
    cpeForDownload: 'ТД',
    wlanForDownload: 'Wi-Fi',
    vlanForDownload: 'VLAN',
    ssidForDownload: 'SSID',
    startForDownload: 'Начало',
    stopForDownload: 'Завершение',
    trafficDownloadedForDownload: 'Трафик входящий',
    trafficUploadedForDownload: 'Трафик исходящий',
    ipsForDownload: 'IPs',
    locationForDownload: 'Локация',
    companyForDownload: 'Компания',
    totalDurationForDownload: 'Всего продолжительность (сек.)',
    sessionDurationForDownload: 'Продолжительность сессии (сек.)',
    noCompanyNameTooltipForTable: 'Нет имени компании',
    allCpes: 'Все точки доступа',
    allUsers: 'Все пользователи',
    authorized: 'Только авторизованные',
    byCPULoad: 'По загрузке CPU',
    byDuration: 'По длительности',
    byLocation: 'По локациям',
    byMemoryLoad: 'По исп. памяти',
    byRxTraffic: 'По вх. трафику',
    byTraffic: 'По трафику',
    byTxTraffic: 'По исх. трафику',
    byconnectedClients: 'По подключенным клиентам',
    bydisconnectedClients: 'По отключенным клиентам',
    bypasserbyClients: 'По проходящим клиентам',
    bytotalClients: 'По всем клиентам',
    chooseCPEs: 'Выберите ТД',
    chooseDate: 'Выберите дату',
    chooseDateRange: 'Выберите период',
    clients: 'Клиенты',
    createAndDownload: 'Скачать',
    export: 'Экспорт данных',
    exportStat: 'Экспорт данных Wi-Fi Таргет',
    field: 'Поле',
    filtersCPE: 'Имя точки доступа',
    filtersRadio: 'Имя интерфейса (radio или VLAN)',
    filtersUsers: 'Пользователи',
    filtersWLAN: 'Имя сети wi-fi',
    from: 'Начало',
    limit: 'Количество',
    offset: 'Начиная с',
    order: 'Порядок',
    pagination: 'Пагинация',
    pan: 'Кликните и потяните для зума. Удерживайте shift для перемещения.',
    passerbyClients: 'Проход. клиенты',
    period: 'Период',
    rawButton: 'Отчёт по сессиям',
    rawConfirmation: 'Пожалуйста, подтвердите формирование отчёта по клиентским сессиям со следующими параметрами: ',
    rawModal: 'Формирование отчёта',
    rawRequest: 'Сформировать отчёт',
    showClient: 'показать инфо по клиенту',
    to: 'Конец',
    username: 'Данные последней авторизации',
    wlan: 'WLAN',
    wlanForReportModal: 'Сеть Wi-Fi',
    txRxChartTitle: 'Всего вх./исх.',
    txRxChartMbytes: 'Мбайт',
    txRxChartTX: 'исх.',
    txRxChartRX: 'вх.',
    txRxChartTXandRX: 'вх. + исх.',
    txRxRateChartTitle: 'Вх./исх. скорость',
    txRxRateChartMbytess: 'Мбайт/c',
    txRxRateChartTXRate: 'исх. скорость',
    txRxRateChartRXRate: 'вх. скорость',
    txRxRateChartTXandRXRate: 'вх. + исх. скорость',
    totalClientsChartTitle: 'Всего клиентов',
    totalClientsChartAxis: 'Клиенты',
    totalClientsChartSeries: 'Клиенты',
    totalTxRxPerUserChartTitle: 'Всего вх./исх. на пользователя',
    totalTxRxPerUserChartMbytes: 'Мбайт',
    totalTxRxPerUserChartTX: 'исх.',
    totalTxRxPerUserChartRX: 'вх.',
    totalTxRxPerUserChartTXandRX: 'вх. + исх.',
    txRxRatePerUserChartTitle: 'Вх./исх. скорость на пользователя',
    txRxRatePerUserChartMbytess: 'Мбайт/c',
    txRxRatePerUserChartTXRate: 'исх. скорость',
    txRxRatePerUserChartRXRate: 'вх. скорость',
    txRxRatePerUserChartTXandRXRate: 'вх. + исх. скорость',
    cpuLoadChartTitle: 'Загрузка процессора',
    cpuLoadChartAxis: 'Загрузка процессора',
    cpuLoadChartSeries: 'Процессор',
    memoryLoadChartTitle: 'Использование памяти',
    memoryLoadChartAxis: 'Исп. памяти',
    memoryLoadChartSeries: 'Память',
    statCpesTooltipSession: 'сессия',
    statCpesTooltipCount: 'количество',
    statCpesTooltipAvgTime: 'ср. время',
    statCpesTooltipRx: 'вх.',
    statCpesTooltipTx: 'исх.',
    statCpesTooltipFirst: 'первый ',
    statCpesTooltipReturn: 'повторный',
    sessionClientsChartTitle: 'Сессии клиента',
    sessionClientsChartSeriesConnected: 'Подключен',
    sessionClientsChartSeriesDisconnected: 'Отключен',
    sessionClientsChartAxisOffline: 'оффлайн',
    sessionClientsChartAxisOnline: 'онлайн',
    sessionClientsChartTooltipCPE: 'ТД',
    sessionClientsChartTooltipFrequency: 'Частота',
    sessionClientsChartTooltipMode: 'Режим',
    sessionClientsChartTooltipWLAN: 'Сеть Wi-Fi'
  },
  system: {
    byFile: 'С помощью файла',
    byText: 'С помощью кода',
    licenceActivationCaption: 'Активация лицензии',
    uploadFileButton: 'Отправить',
    selectFile: 'Выбрать файл',
    selectedFile: 'Выбраный файл',
    selectedFileName: 'Имя выбранного файла',
    import: 'Импортировать',
    addCode: 'Вставьте код лицензии',
    addLicense: 'Добавить лицензию',
    current: 'Текущие ТД',
    limit: 'Максимум ТД',
    portalCurrent: 'Текущие Порталы',
    portalLimit: 'Максимум Порталов',
    analyticsCurrent: 'Текущие Аналитики',
    analyticsLimit: 'Максимум Аналитик',
    analyticsHint: 'Аналитики - это число включенных Wi-Fi Таргет на точках доступа'
  },
  tags: {
    addTags: 'Добавить теги',
    availlableTags: 'Доступные теги',
    currentTags: 'Добавленные теги',
    deleteAllTags: 'Удалить все теги'
  },
  tariffs: {
    add: 'Добавить Тариф',
    amount: 'Стоимость',
    edit: 'Редактирование Тарифа',
    name: 'Название',
    new: 'Новый Тариф',
    session: 'Длина сессии',
    speed: 'Скорость',
    title: 'Тарифы',
    notificationTariffCreatedSuccessfully: 'Тариф успешно создан',
    notificationTariffUpdatedSuccessfully: 'Тариф успешно обновлен',
    notificationTariffDeletedSuccessfully: 'Тариф успешно удален',
    tariffTypeCaption: 'Тип тарифа',
    tariffTypeVoucher: 'Ваучер',
    tariffTypeSubscription: 'Подписка',
    recurringCaption: 'Периодичность подписки',
    recurringSwitcherCaption: 'Включить периодичность подписки',
    recurringPeriodCaption: 'Период',
    recurringPeriodDay: 'День',
    recurringPeriodWeek: 'Неделя',
    recurringPeriodMonth: 'Месяц',
    subscriptionDescriptionCaption: 'Описание',
    subscriptionDescriptionAdvice: 'Вставьте текст описания в поле ниже',
    locale: {
      en: 'Английский',
      locale: 'Языки',
      ru: 'Русский',
      tg: 'Таджикский'
    },
    profileSelectorTip: 'Нажмите enter для выбора',
    selectProfile: 'Выберете Профиль',
    profileField: 'Профиль',
    profileCaptionInTable: 'Профиль',
    noProfileSelectedError: 'Необходимо выбрать Профиль'
  },
  template: {
    allUUIDs: 'Все UUIDs',
    confirmButton: 'Подтвердить',
    wizardrConfigParametersTitle: 'Настройка конфигурации',
    wizardWLANsPlaceHolder: 'Выберите сеть Wi-Fi',
    wizardWLANs: 'Сети Wi-Fi',
    fieldMustBeCsvFile: 'Поле должно быть csv файлом',
    wrongDataInFile: 'Неверные данные в файле',
    templateAddUUIDPlaceHolder: 'Добавить UUID',
    wizardSelectModelPlaceholder: 'Выберите модель',
    templateDescriptionPlaceHolder: 'Введите описание',
    templateNamePlaceHolder: 'Введите имя шаблона',
    tableOn: 'вкл.',
    tableOff: 'выкл.',
    tableCPEsUUID: 'Точки доступа',
    addTemplate: 'Добавить Шаблон',
    advancedParameters: 'Настройка дополнительных параметров',
    advancedSettings: 'Дополнительные настройки',
    alwaysApply: 'Применять всегда',
    autoConfig: 'Авто конфиг.',
    autoConfiguration: 'Конфигурация при подключении ТД',
    basicParameters: 'Настройка базовых параметров',
    configFor: 'Конфигурация',
    configGeneral: 'Настройка конфигурации',
    configRadio: 'Настройка интерфейса',
    cpeModel: 'Модель ТД',
    CPEsTemplates: 'Шаблоны ТД',
    macPrefix: 'MAC префикс',
    manualConfig: 'Ручной конфиг.',
    networkMask: 'Сетевая маска',
    requiredConfigParameters: 'Настройка конфигурации',
    statLogLBSSettings: 'Настройка статистики, логирования и Wi-Fi Таргет',
    template: 'Шаблон',
    templateSettings: 'Настройки Шаблона',
    templateWizard: 'Создание Шаблона',
    notificationTemplateDeletedSuccessfully: 'Шаблон успешно удален',
    notificationTemplateUpdatedSuccessfully: 'Шаблон успешно обновлен'
  },
  tooltip: {
    Ads: 'Раздел позволяет настроить рекламу для порталов авторизации.',
    Aps: 'В разделе содержится информация о подключенных точках доступа / AP, настройка интерфейсов.',
    Clients: 'В разделе содержится информация о клиентских устройствах.',
    Dashboard:
      'На рабочем столе представлена актуальная сводка по работе платформы и статистика по подключенным клиентам, сетям, порталам авторизации.',
    Events: 'В разделе содержится информация о событиях в сети и в системе.',
    Firewall:
      'Раздел позволяет создавать и изменять правила управления брандмауэром (firewall) с последующим добавлением на WLAN или точки доступа.',
    GuestControl:
      'Раздел позволяет настраивать правила перенаправлением трафика неавторизованных пользователей в сетях с порталом авторизации.',
    Hotspot: 'Раздел позволяет настраивать профили Hotspot 2.0.',
    Marketing: 'Раздел отображает статистику по посетителям.',
    NotFound: 'Описание раздела не найдено.',
    PaymentSystems: 'Раздел позволяет управлять платежными системами для платного интернета.',
    PortalPages:
      'Раздел отвечает за визуальное представление портала авторизации. Одна страница может использоваться со многими Профилями. На странице Вы можете настроить логотипы, фон, цветовую схему и заголовок.',
    PortalProfilesNew:
      'Раздел отвечает за логику работы портала авторизации и по параметрам ТД / SSID / NAS ID понимает, что делать с пользователем: какие идентификации и авторизации ему доступны, какую рекламу показывать и на сколько пропускать в Интернет.',
    PortalPreview:
      'На этой странице Вы можете выбрать Страницу и Профиль и проверить, все ли отображается так, как задумано Вами.',
    PortalAdsNew:
      'Реклама и опросы созданы чтобы продвигать, продавать, а еще чтобы узнавать больше о том, что Вам интересно у ваших посетителей.',
    PortalBirthdays:
      'Данные о днях рождениях ваших гостей, собранные из социальных сетей при авторизации и с помощью опросов. Все данные можно скачать в PDF, CSV и XLS.',
    PortalStats:
      'В разделе собраны данные по новым, повторным пользователям и уникальным, повторяющимся подключениям пользователей с разбивкой по типу Операционной системы, типу устройства, по языкам, по производителям и конечно по способам идентификации и авторизации.\n Все данные можно выгрузить за период и сохранить в форматах PDF, CSV и XLS.',
    PortalHotelVouchers:
      'В разделе представлены данные о доступных и уже активированных ваучерах. Есть возможность скачать доступные ваучеры в формате XLS.',
    PortalAdsStats: 'В разделе содержится статистика о просмотрах по типам авторизаций.',
    RRM: 'Раздел позволяет настраивать работу RRM (бесшовный Wi-Fi) для выбранных точек доступа',
    // Radar: 'Раздел позволяет делать выгрузку данных по Wi-Fi Таргет.',
    Radar: 'Раздел позволяет делать выгрузку сегментов Wi-Fi Таргет',
    Radius: 'Раздел позволяет создавать и изменять настройки для клиента RADIUS.',
    Rules: 'Раздел позволяет настроить выполнение необходимых скриптов при наступлении определенных событий.',
    Sessions: 'Раздел содержит графики подключения точек доступа, сведения об их сессиях и времени онлайн.',
    Statclients: 'Раздел содержит статистику по клиентам.',
    Statcpes: 'Раздел содержит статистику по точкам доступа.',
    Statreports: 'Раздел отображает сводные данные по отчётам, а также позволяет их настраивать.',
    Statsystem: 'Раздел содержит статистику по локациям (площадкам).',
    Statwlans: 'Раздел содержит статистику по беспроводным сетям.',
    System:
      'Раздел позволяет просматривать и управлять лицензиями для подключения большего числа точек доступа к платформе.',
    Tariffs: 'Раздел позволяет управлять тарифами на доступ к Wi-Fi.',
    Templates:
      'Раздел предназначен для создания и настройки шаблонов с автоматическим конфигурированием точек доступа при первом или при каждом подключении.',
    UserAccounts:
      'Гости Ваших Порталов авторизации. Аккаунт гостя постоянно пополняется: у каждого гостя может быть несколько устройств, авторизация через разные социальные сети. Это позволяет точно знать, когда у пользователя день рождения и откуда он. Все данные можно выгрузить и сохранить в форматах PDF, CSV и XLS.',
    Vouchers: 'Раздел отображает и позволяет управлять доступными ваучерами.',
    Wlans: 'В разделе содержится информация о Wi-Fi сетях и их всевозможной конфигурации.',
    maps: 'Раздел предназначен для визуализации информацию о точках доступа.'
  },
  userAccount: {
    unsetForFilter: 'Не указано',
    notSubscribed: 'Не подписан',
    subscribed: 'Подписан',
    subscribedFromDate: 'с',
    subscriptionName: 'Название подписки',
    subscriptionTableTitle: 'Подписка',
    unset: 'не указан',
    vk: 'Вконтакте',
    fb: 'Facebook',
    instagram: 'Instagram',
    actions: 'Действия',
    add: 'Добавить Пользователя',
    addition: 'Пополнение',
    addToBlacklist: 'Добавить в ЧС',
    authCode: 'Код идентификация',
    authCodeInfo:
      'Код генерируется при нажатии "Создать код доступа" в колонке "Идентификация". Именно его нужно вводить клиенту для доступа в интернет',
    amount: 'Сумма',
    balance: 'Баланс',
    balanceInfo: 'Баланс виртуального счета в случае платного доступа',
    birthday: 'День Рождения',
    city: 'Город проживания',
    createAt: 'Дата создания:',
    createdOn: 'Дата',
    currency: 'Валюта',
    dataFromSocials: 'Информация о пользователе из соц. сетей',
    deleteAccessListCode: 'Удалить код доступа',
    deleteFromBlacklist: 'Удалить из ЧС',
    edit: 'Редактирование Пользователя',
    email: 'E-mail',
    firstName: 'Имя',
    gender: {
      type: 'Пол',
      male: 'Мужской',
      female: 'Женский',
      unset: 'Не указан',
      '<nil>': '',
      мужик: 'Мужской'
    },
    generateAccessListCode: 'Создать код доступа',
    getTransactions: 'Показать {num} транзакций',
    goToLink: 'Перейти на {link}',
    history30d: 'История транзакций за последние 30 дней',
    homeTown: 'Место рождения',
    identityInfo: 'Телефон, Email, MAC адрес - в зависимости от выбранного типа идентификации',
    macs: 'MAC',
    name: 'Имя',
    nameFromSocial: 'Имя из соц. сетей',
    new: 'Новый Пользователь',
    next: 'след.',
    noTransactions: 'Нет пополнений или списаний за последние 30 дней',
    of: 'из',
    prev: 'пред.',
    profile: 'Профиль',
    purchase: 'Списание',
    rowsPerPage: 'Строк на странице',
    surname: 'Фамилия',
    socialLink: 'Ссылка на соц. сеть',
    socialLinks: 'Соц. сети',
    title: 'Пользователи',
    transactions: 'История транзакций',
    transactionsInfo: 'Список всех платежных манипуляций пользователя (списание и пополнение)',
    type: 'Тип',
    universities: 'Образование',
    username: 'Идентификатор',
    visits: 'Подключений',
    isUserAddedToBlacklist: 'Добавлен ли пользователь в ЧС?',
    isUserAddedToBlacklistYes: 'Да',
    isUserAddedToBlacklistNo: 'Нет',
    notificationUserAccountCreatedSuccessfully: 'Пользователь успешно создан',
    notificationUserAccountUpdatedSuccessfully: 'Пользователь успешно обновлен',
    notificationUserAccountDeletedSuccessfully: 'Пользователь успешно удален',
    notificationUserAccountSuccess: 'Успешно'
  },
  users: {
    deleteUserHeader: 'Удалить пользователя',
    isBlocked: 'Заблокирован',
    firstLoginForUserEditPanel: 'Первый вход',
    lastLoginForUserEditPanel: 'Последний вход',
    createdByForUserEditPanel: 'Создан пользователем',
    // beepro: 'БиПродвижение',
    bee_pro_bis_id: 'BusinessID',
    bee_pro_ctn: 'CTN',
    userHasCTNButHasNOtBisIDTooltip: 'У пользователя есть CTN но нет BusinessID (или он равен 0)',
    wrongEmailMsg: 'Неправильный email',
    newUsernameInfoMsg: 'Это имя будет использоваться как логин при входе в личный кабинет',
    updUsernameInfoMsg: 'Это имя используется как логин при входе в личный кабинет',
    blockedUsers: 'Заблокированные',
    noBlockedUsers: 'Незаблокированные',
    notificationUserBlockedSuccessfully: 'Пользователь успешно заблокирован',
    notificationUserUnblockedSuccessfully: 'Пользователь успешно разблокирован',
    blockUserCaption: 'Заблокировать пользователя',
    unblockUserCaption: 'Разблокировать пользователя',
    copyFromLocation: 'скопировать из локации',
    setActiveUntillForUser: 'Поставить ограничение на срок действия пользователя',
    enterForPasswordChangePlaceHolder: 'Введите для изменения',
    enterEmailPlaceHolder: 'Введите адрес почты',
    enterPasswordPlaceHolder: 'Введите пароль',
    enterUsernamePlaceHolder: 'Введите имя пользователя',
    Role: 'Роль',
    addUser: 'Добавить пользователя',
    company: 'Компания',
    createdBy: 'Кем создан',
    creationDate: 'Когда создан',
    description: 'Должность',
    lastLogin: 'Последний вход',
    loginAsSubordinateForButton: 'Войти',
    logoutAsSubordinateForButton: 'Продолжить как',
    noLogin: 'Вход не выполнялся',
    password: 'Пароль',
    phone: 'Телефон',
    username: 'Имя пользователя',
    users: 'Пользователи',
    usersList: 'Список пользователей',
    mac_mask: 'Маскировать MAC адреса для этого пользователя',
    notificationUserCreatedSuccessfully: 'Пользователь успешно создан',
    notificationUserUpdatedSuccessfully: 'Пользователь успешно обновлен',
    notificationUserDeletedSuccessfully: 'Пользователь успешно удален',
    addUserWithDemoData: 'Добавить пользователя с демо-данными',
    newUserPasswordValidationMessage: 'Пароль может содержать только следующие специальные символы: _ - # $ & ^ * @ ! %'
  },
  vouchers: {
    noAccountSelectedInModalErr: 'Поле аккаунт обязательно для заполнения',
    selectAccount: 'Выберете счет',
    allAccounts: 'Все пользователи',
    account: 'Счёт',
    add: 'Добавить Ваучер',
    backToVouchers: 'Назад к ваучерам',
    code: 'Код',
    codeLength: 'Длина кода',
    createVouchers: 'Создать ваучеры',
    createHotelVouchers: 'Создать отельные ваучеры',
    download: 'Скачать для печати',
    duration: 'Время действия',
    edit: 'Редактирование Ваучера',
    editHotel: 'Редактирование отельного ваучера',
    expire_at: 'Время окончания',
    new: 'Новый Ваучер',
    newVouchers: 'Новые ваучеры',
    newHotelVouchers: 'Новые отельные ваучеры',
    number_of_vouchers: 'Количество ваучеров',
    profile: 'Профиль',
    session: 'Время доступа в интернет после активации',
    tariff: 'Тариф',
    title: 'Ваучеры',
    titleHotel: 'Отельные Ваучеры',
    used: 'Использован',
    validFor: 'Действителен {day} дней',
    validForShort: 'Действителен',
    notificationVoucherCreatedSuccessfully: 'Ваучер успешно создан',
    notificationVoucherUpdatedSuccessfully: 'Ваучер успешно обновлен',
    notificationVoucherDeletedSuccessfully: 'Ваучер успешно удален',
    notificationHotelVoucherCreatedSuccessfully: 'Отельный ваучер успешно создан',
    notificationHotelVoucherUpdatedSuccessfully: 'Отельный ваучер успешно обновлен',
    notificationHotelVoucherDeletedSuccessfully: 'Отельный ваучер успешно удален'
  },
  wlans: {
    SSHWebAccess: 'SSH / Web доступ',
    goBackToClients: 'Назад к Клиентам',
    securitySettingsCaption: 'Настройки безопасности',
    L2TabCaption: 'Уровень L2',
    L3TabCaption: 'Уровень L3',
    AAATabCaption: 'AAA',
    addRedirectRule: 'Добавить Redirect',
    addRADIUS: 'Добавить RADIUS',
    goBackToWlanStatistic: 'Назад в Статистику Wi-Fi сетей',
    selectSingleInterface: 'Выберете Интерфейс',
    selectSingleTunnel: 'Выберете Туннель',
    selectSingleWlan: 'Выберете сеть Wi-Fi',
    nas_generateInfo: 'Позволяет более качественно обеспечивать работоспособность роуминга 802.11r',
    nas_generateCaption: 'Генерировать NAS ID',
    signalStrikesForLastWizardStep: 'Количество проверок',
    signalDropReasonForBadge: 'Код причины',
    signalPollTimeForBadgeSec: 'сек.',
    signalPollTimeForBadge: 'Время обновления',
    signalStrikesForBadge: 'Количество проверок',
    signalStayForBadge: 'Минимальный порог RSSI',
    signalDropReasonMoreInfoForModal: 'Подробнее о кодах можно прочитать тут',
    signalDropReasonMoreInfo: 'Подробнее о кодах',
    signalDropReason: 'Код причины',
    signalDropReasonHint: '3 по умолчанию',
    signalDropReasonPlaceholder: 'Код причины, 3 по умолчанию',
    signalDropReasonForInfo: 'Код причины, отправляемый клиенту',
    signalPollTime: 'Время обновления',
    signalPollTimeHint: '5 сек. по умолчанию',
    signalPollTimePlaceholder: 'Время обновления, 5 сек. по умолчанию',
    signalPollTimeForInfo: 'Время обновления, 5 сек. по умолчанию',
    signalStrikes: 'Количество проверок перед отключением',
    signalStrikesHint: '3 по умолчанию',
    signalStrikesPlaceholder: 'Количество проверок, 3 по умолчанию',
    signalStrikesForInfo: 'Количество проверок уровня мощности перед отключением клиента',
    signalStay: 'Минимальный порог RSSI',
    signalStayHint: 'от -100 до -40 dbm, 0 для отключения',
    signalStayPlaceholder: 'от -100 до -40 dbm, 0 для отключения',
    signalStayForInfo:
      'Для подключенных клиентов при обнаружении уменьшения мощности сигнала меньше указанного, в течении заданного количества раз, происходит их отключение',
    signalBlockCaption:
      'Настройки порога RSSI, при уменьшении которого пользователя отключает от WI-Fi сети с указанным кодом',
    // overTheDS: 'Over The DS',
    overTheDS: 'Over DS / Over Air',
    rssiThreshold: 'RSSI порог',
    rssiThresholdHint: 'Допустимый уровень сигнала клиента при подключении (от -90 до -60 dbm)',
    rssiThresholdHintRange: 'от -90 до -60 dbm, 0 для отключения',
    rssiThresholdHintForInfo: 'Допустимый уровень сигнала клиента при подключении',
    rssiThresholdPlaceholder: 'от -90 до -60 dbm, 0 для отключения',
    bandSteering: 'Включить Band steering',
    bandSteeringForBadge: 'Band steering',
    bandSteeringForLastStepWlanWizard: 'Band steering',
    wizardSkipButtonInfo:
      'Пропустить все последующие шаги и перейти к завершающему шагу. Настройки заданные на текущем шаге будут применены.',
    wizardSkipButton: 'Пропустить след. шаги',
    natEnabledForTable: 'есть',
    natDisabledForTable: 'нет',
    peerAddressPlaceholder: 'Введите IP EoGRE сервера',
    peerAddress: 'EoGRE сервер',
    proto: 'Тип',
    tunnelTypeForTable: 'Туннель',
    natNetworkInfoMsg: 'Не используйте 10.9.0.1/16 для точек с IPSec',
    natNetworkIpaddr: 'IP для NAT сети',
    natNetworkNetmask: 'Маска для NAT сети',
    natNetworkIpaddrPlaceholder: 'Введите IP для NAT сети',
    natNetworkNetmaskPlaceholder: 'Введите маску для NAT сети',
    qrSaveQRCode: 'Сохранить QR код',
    qrForWLANConnet: 'QR код для подключения к сети  Wi-FI',
    notAvailableForCisco: 'Недоступно для Cisco',
    radiusAdditionalSettings: 'Дополнительные настройки RADIUS',
    deleteTagsModalTitile: 'Удаление тегов',
    deleteTagsModalText: 'Подтвердите удаление всех тегов у выбранной сети Wi-Fi',
    addTag: 'Добавить тег',
    tunneling: 'Туннелирование до сервера',
    tunnelingShort: 'Туннелирование',
    centralized: 'Централизованный',
    vlanNative: 'Без тега',
    wizardAdditionalSettings: 'Дополнительные параметры',
    wizardSecuritySettings: 'Параметры безопасности',
    UAPSD: 'UAPSD',
    WMM: 'WMM',
    onlyForWPAEnterprise: 'Только для WPA Enterprise',
    currentWhitelistItems: 'Текущие элементы Белого списка',
    currentBlacklistItems: 'Текущие элементы Черного списка',
    filterWhitelistMode: 'Режим Белого списка',
    filterWhitelistModeCaption: 'Только выбранные клиенты могут подключаться к Wi-Fi сети',
    filterBlacklistMode: 'Режим Черного списка',
    filterBlacklistModeCaption: 'Все, кроме выбранных клиентов, могут подключиться к Wi-Fi сети',
    filterNoneModeCaption: 'Все клиенты могут подключаться к Wi-Fi сети',
    MACRADIUSAuthentication: 'MAC RADIUS Авторизация',
    none: 'Нет',
    firewall: 'Брандмауэр',
    redirect: 'Перенаправление',
    unlim: 'без огранич.',
    download: 'Загрузка',
    upload: 'Выгрузка',
    enterNASPortIdPlaceholder: 'Введите NAS порт идентификатор',
    enterNASIdentifierPlaceholder: 'Введите NAS идентификатор',
    enterRSNAPlaceholder: 'Введите RSNA',
    enterVLANPlaceHolder: 'Введите VLAN',
    noInterfaces: 'Нет интерфейсов',
    selectInterface: 'Выберите интерфейс',
    suitesPlaceholder: 'Тип шифрования',
    hotspotProfileNone: 'Нет',
    hotspotProfileTitle: 'Hotspot 2.0 профиль',
    selectRADIUSPlaceholder: 'Выберите RADIUS',
    enterWlanPasswordPlaceholder: 'Введите пароль для Wi-Fi',
    ssidPlaceholder: 'Введите SSID',
    descriptionPlaceholder: 'Введите описание',
    noCurrentCPEs: 'Нет ТД',
    statusActive: 'активна',
    statusNotActive: 'не активна',
    statusPending: 'ожидание',
    statusEmpty: 'пусто',
    whiteList: 'Белый список',
    blackList: 'Черный список',
    tableNone: 'нет',
    securityOpen: 'Открытая',
    load: 'Загрузить',
    WLANsOufOf: 'Wi-Fi сетей из',
    selected: 'выбрано',
    view: '',
    itemsOf: 'из',
    L2isolation: 'L2 изоляция',
    NASidentifier: 'NAS идентификатор',
    NASportid: 'NAS порт идентификатор',
    PMKcaching: 'Кэширование PMK',
    accessControl: 'Контроль доступа',
    addWLAN: 'Добавить Wi-Fi сеть',
    advancedSettings: 'Настройка дополнительных параметров',
    basicParameters: 'Настройка базовых параметров',
    bridge: 'Для интерфейсов типа Bridge VLAN игнорируется',
    confirmDeleting: 'Подтверждаете удаление',
    confirmWlans: 'Проверка введенных данных',
    deleteMulti: 'Удалить выбранные Wi-Fi сети',
    deleteWLAN: 'Удаление Wi-Fi сети',
    deleteWLANs: 'Удаление Wi-Fi сетей',
    // fastTransition: 'Бесшовный роуминг (IEEE 802.11r)',
    fastTransition: 'Быстрый роуминг (IEEE 802.11r)',
    filter: 'Фильтр',
    filterMode: 'Режим ограничения',
    hidden: 'Скрытая',
    interface: 'Интерфейс',
    mirroring: 'Зеркалирование',
    mirroringHint: 'Дубрирование отправки сразу на несколько RADIUS серверов',
    // mobility: 'Роуминг',
    mobility: 'Настройки роуминга',
    networkEncapsulation: 'Сетевая инкапсуляция',
    dataTrafficManagement: 'Управление трафиком данных',
    localSwitching: 'Локальная терминация',
    // centralSwitching: 'Терминация на сервере',
    centralSwitching: 'Централизованная коммутация',
    optionalParameters: 'Настройка дополнительных параметров. Вы можете их настроить позднее.',
    radiusAccounting: 'RADIUS сервер учета',
    radiusAccountingForWLANModalAAA: 'RADIUS сервер учета',
    radiusAccountingInterval: 'RADIUS сервер учета интервал',
    rsnaCapture: 'Если оставить пустым, будет заполнено в зависимости от настроек безопасности (OPEN / WPA / RSNA)',
    rsnaSettings: 'Настройки RSNA',
    rsnaSettingsV2: 'Изменить поле Calling Station ID',
    security: 'Безопасность',
    securityParameters: 'Настройка параметров безопасности',
    speedControl: 'Ограничение скорости',
    speedDownload: 'Скорость загрузки',
    speedUpload: 'Скорость выгрузки',
    status: 'Статус',
    suites: 'Тип шифрования',
    tableTab_clients: 'Клиенты',
    tableTab_cpes: 'ТД',
    tableTab_filter: 'Фильтр',
    tableTab_location: 'Локация',
    tableTab_security: 'Безопасность',
    tableTab_ssid: 'SSID',
    tableTab_status: 'Статус',
    tableTab_tags: 'Теги',
    tableTab_hasPortal: 'Портал',
    tableTab_visibility: 'Видимость',
    tableTab_vlan: 'VLAN',
    tableTab_encapsulation: 'Инкапсуляция',
    tableTab_dataTrafficManagement: 'Управление трафиком данных',
    tableTab_wlans: 'Сети',
    tunnel: 'Тунель',
    visibility: 'Видимость',
    visible: 'Видимая',
    warnService: 'Пожалуйста, обратите внимание, что выбрана активна Wi-Fi сеть. Её удаление приведёт к {0}.',
    warnServiceLoss: 'потере сервиса',
    warnStatistics: 'Пожалуйста, обратите внимание, что удаление Wi-Fi сети приведёт к {0}.',
    warnStatisticsLoss: 'потере статистики',
    wlanWizard: 'Создание Wi-Fi сети',
    wlanWizardV2: 'Создание Wi-Fi сети',
    wlans: 'Сети Wi-Fi',
    wlansList: 'Список Wi-Fi сетей',
    notificationWLANUpdatedSuccessfully: 'Wi-Fi сеть успешно обновлена',
    notificationWLANCreatedSuccessfully: 'Wi-Fi сеть успешно создана',
    notificationWLANDeletedSuccessfully: 'Wi-Fi сеть успешно удалена',
    notificationWLANOperationSuccessfull: 'Успешно',
    wizardVisibility: 'Видимость',
    visibilityHidden: 'скрытая',
    visibilityVisible: 'видимая',
    wizardRADIUSAccounting: 'RADIUS сервер учета',
    wizardRADIUSAccountingStepCaption: 'Настройка параметров RADIUS сервера учета',
    optionalStep: 'Вы можете настроить их позднее',
    wizardNetworkEncapsulationStepCaption: 'Настройка параметров Сетевой инкапсуляции',
    wizardRADIUSAdditionalSettingsStepCaption: 'Настройка дополнительных параметров RADIUS',
    wizardRADIUSAdditionalSettings: 'Дополнительные параметры RADIUS',
    wizardSpeedControlSettingsStepCaption: 'Настройка параметров ограничения скорости',
    wizardSpeedControlSettings: 'Ограничение скорости',
    enterKbitPlaceholder: 'Введите значение в kbit',
    wizardGuestControlStepCaption: 'Настройка параметров контроля доступа',
    wizardGuestControl: 'Контроль доступа',
    wizardMobilityStepCaption: 'Настройки параметров роуминга',
    // wizardMobility: 'Роуминг',
    wizardMobility: 'Настройки роуминга',
    notForOpen: 'Не для Открытой сети Wi-Fi',
    wizardWMMStepCaption: 'Настройки параметров WMM',
    wizardWMM: 'WMM',
    wizardBlackWhiteListItem: 'зап.',
    ieee80211k: '802.11k',
    rrm_neighbor_report: 'Отчет о соседях',
    rrm_beacon_report: 'Отчет о Beacon',
    ieee80211v: '802.11v',
    wnm_sleep_mode: 'Возможность перехода в спящий режим',
    wnm_sleep_modeInfo: 'Включает возможность перехода в спящий режим для энергосбережения',
    wnm_sleep_modeForWizardLastStep: 'Спящ. режим',
    // bss_transition: 'BSS Transition',
    bss_transition: 'Быстрый переход BSS',
    ieee80211kForBadge: '802.11k',
    rrm_neighbor_reportForBadge: 'Отчет о соседях',
    rrm_beacon_reportForBadge: 'Отчет о Beacon',
    ieee80211vForBadge: '802.11v',
    wnm_sleep_modeForBadge: 'Возможность спящего режима',
    bss_transitioForBadge: 'BSS Transition',
    bss_transitionForBadge: 'BSS Transition',
    enabledForBadge: 'вкл.',
    disabledForBadge: 'выкл.',
    loadBalancing: 'Балансировка клиентов',
    loadBalancingInfoMsg: 'Работает корректно только с 802.11v',
    wpa3IsNotSupportedOnAllCPE: 'WPA3 поддерживается не на всех ТД'
  },
  wmm: {
    Background: 'Background (BK)',
    BestEffort: 'BestEffort  (BE) ',
    Video: 'Video (VI)',
    Voice: 'Voice (VO) ',
    default: 'по умолчанию',
    configPlaceHolder: 'Введите, чтобы изменить значение по умолчанию',
    custom: 'включены',
    infoTooltip: 'нажмите, чтобы просмотреть подробности о настройках WMM',
    modalInfoTitle: 'О настройках WMM',
    modalInfomoreInfo: 'Больше информации'
  },
  portalLocationsStat: {
    clicks: 'Переходы',
    views: 'Просмотры',
    allVisits: 'Все посещения',
    uniqueVisit: 'Уникальные посещения',
    firstVisit: 'Первые посещения',
    repeatVisit: 'Повторные посещения',
    adStat: 'Статистика реклам и опросов',
    clientsStat: 'Статистика клиентов',
    locationsStat: 'Статистика локаций',
    location: 'Локация',
    total: 'Всего'
  },
  portalDashboard: {
    smsCost: 'Стоимость СМС',
    connections: 'Подключения',
    connectionsPeriod: 'последние 7 дней',
    connectionsTotal: 'Всего',
    connectionsNew: 'Новые пользователи',
    connectionsRepeat: 'Повторные пользователи',
    connectionsUnique: 'Уникальные клиенты',
    authentications: 'Идентификации',
    authenticationsPeriod: 'последние 7 дней',
    authenticationsTotal: 'Всего',
    authenticationsSms: 'SMS',
    authenticationsEmail: 'E-mail',
    authorizations: 'Авторизации',
    authorizationsPeriod: 'последние 7 дней',
    locations: 'Локации',
    locationsTotal: 'Всего',
    locationsWhenManager: 'всего',
    locationsAll: 'Все локации',
    locationsWhereCurrentUserisManager: 'Локации, где вы ответственный',
    locationsMap: 'Карта локаций',
    support: 'Поддержка',
    supportText: 'Возникла проблема? Напишите нам, мы можем помочь.',
    recomendationVKFB: 'Рекомендация',
    birthdays: 'Дни рождения',
    os: 'Операционные системы',
    osPeriod: 'последние 7 дней',
    adsViewsPeriod: 'за этот месяц',
    adsPeriod: 'за все время',
    advertisement: 'Реклама',
    advertisementPeriod: 'просмотры за все время',
    advertisementStatistic: 'Просмотры рекламы',
    advertisementStatisticByDates: 'по датам',
    advertisementStatisticPeriod: 'за этот месяц',
    advertisementTop3: 'лучшие 3',
    advertisementTop5: 'лучшие',
    advertisementdays: 'лучшие дни по просмотрам',
    advertisementTotal: 'Всего',
    advertisementStatisticTotal: 'Всего просмотров',
    advertisementViews: 'просмотров',
    recomendation: 'Рекомендация',
    showMore: 'и другие',
    noLocations: 'нет локаций',
    connectionsCaption: 'Количество подключений'
  },
  wifiAnalyzer: {
    channel: 'Канал',
    quality: 'Качество',
    signal: 'Сигнал',
    wlanChars: 'Характеристики WLAN'
  },
  account: {
    enterOldPasswordPlaceholder: 'Введите старый пароль',
    enterNewPasswordPlaceholder: 'Введите новый пароль',
    confirmNewPasswordPlaceholder: 'Повторно введите новый пароль',
    enterEmailPlaceholder: 'Введите адрес почты',
    addEmail: 'добавить e-mail',
    save: 'Сохранить',
    changePassword: 'Сменить пароль',
    oldPassword: 'Старый пароль',
    newPassword: 'Новый пароль',
    confirmNewPassword: 'Подтвердите новый пароль',
    change: 'Сменить',
    attention: 'Внимание!',
    attentionMessage: 'Все пользовательские сеансы этого клиента будут отключены. Подтвердите смену пароля?',
    confirm: 'Подтвердить',
    newPhoto: 'Новое фото',
    changePasswordButton: 'Сменить пароль'
  },
  imgAndVideoRecommendations: {
    adsAndPollsImgMobile: 'Для смартфонов. Рекомендованный размер 1080*1920',
    adsAndPollsImgDesktop: 'Для планшетов и ноутбуков. Рекомендованный размер 1920*1080',
    adsAndPollsVideo: 'Рекомендованный размер 1080*1920',
    pagesLogoTop: 'Рекомендованная ширина 400px',
    pagesLogoFooter: 'Рекомендованная ширина 400px',
    pagesBackground: ''
  },
  usersDemoWizard: {
    headerCaption: 'Создание пользователя с демо-данными',
    step1Caption: 'Создание Локации',
    step2Caption: 'Создание Администратора для созданной локации',
    step3Caption: 'Создание Wi-Fi сети',
    step4Caption: 'Создание Профиля',
    step5Caption: 'Создание Страницы',
    step1Header: 'Шаг 1: Создание Локации',
    step2Header: 'Шаг 2: Создание Администратора для локации',
    step3Header: 'Шаг 3: Создание Wi-Fi сети',
    step4Header: 'Шаг 4: Создание Профиля',
    step5Header: 'Шаг 5: Создание Страницы',
    email: 'Электронная почта',
    step3SuiteRequiredsError: 'Поле Тип шифрования обязательно для заполнения',
    step4FieldProfileName: 'Имя Профиля',
    step5FieldPageName: 'Имя страницы',
    step1ErrorLocationWithThisNameAlredyExists: 'Локация с этим именем уже существует',
    step2ErrorUserWithThisNameAlredyExists: 'Пользователь с этим именем уже существует'
  },
  SNMPMonitoringConfig: {
    tableColumnTitleServer: 'Сервер',
    tableColumnTitleSNMPStatus: 'SNMP Статус',
    tableColumnTitleIP: 'IP',
    SNMPMonitoringConfigTitle: 'Конфигурация SNMP мониторинга',
    SNMPMonitoringConfigEnabled: 'Включение SNMP',
    SNMPMonitoringConfigCheckAgent: 'Cледить за агентом и его состоянием',
    SNMPMonitoringConfigSaveState: 'Сохранять состояние запроса в БД',
    SNMPMonitoringConfigCommunity: 'Community',
    SNMPMonitoringConfigCommunityPlaceHolder: 'Введите community',
    SNMPMonitoringConfigNotificationCreated: 'Конфигурация SNMP мониторинга успешно создана',
    SNMPMonitoringConfigNotificationUpdated: 'Конфигурация SNMP мониторинга успешно обновлена',
    SNMPMonitoringConfigNotificationDeleted: 'Конфигурация SNMP мониторинга успешно удалена',
    SNMPMonitoringNeedToEnableSNMPInCPESettings:
      'Для корректной работы SNMP мониторинга необходимо включить работу SNMP агента в настройках ТД',
    SNMPMonitoringCPEMustBeAccessibleByIPFromServer: 'Точка должна быть доступна по IP с сервера'
  },
  SNMPStat: {
    SNMPStatTitle: 'Статистика SNMP',
    id: 'Идентиф.',
    name: 'Имя',
    nas_ip: 'NAS IP',
    client: 'Клиент',
    address: 'Адрес',
    availability: 'Доступность',
    uptime: 'Время работы',
    time_stamp: 'Таймстамп',
    associated_users: 'Ассоциированные пользователи',
    associated_users_small: 'Ассоц. польз.',
    wlans: 'Сети Wi-Fi',
    traffic_tx_volume: 'Исх. трафик',
    traffic_rx_volume: 'Вх. трафик',
    traffic_tx_speed: 'Скорость исх. трафика',
    traffic_rx_speed: 'Скорость вх. трафика',
    availabilityAvailably: 'Доступно',
    availabilityNOTAvailably: 'Недоступно',
    sortUptime: 'По времени работы',
    sortAvailability: 'По доступности',
    sortAssociatedUsers: 'По ассоц. польз.',
    sortTrafficTX: 'По исх. трафик',
    sortTrafficRX: 'По вх. трафик',
    sortTrafficTXSpeed: 'По скорости исх. трафика',
    sortTrafficRXSpeed: 'По скорости вх. трафика',
    sortClient: 'По имени клиента',
    sortNumberOfWLANs: 'По количеству Wi-Fi сетей',
    sortLocation: 'По названию локации',
    sortName: 'По имени',
    sortNASIP: 'По NAS IP',
    sortTimestamp: 'По времени',
    filtersAvailablyAll: 'Все',
    filtersAvailablyAvailably: 'Доступно',
    filtersAvailablyNotAvailably: 'Недоступно'
  },
  support,
  alert: {
    licenseEnds: {
      text: 'Ваша лицензия заканчивается!',
      link: 'Перейти к настройкам.'
    },
    lowOfLicenses: {
      text:
        'Осталось лицензий всего на одну ТД (или меньше). Если вы хотите использовать больше ТД, следует добавить лицензии',
      link: 'Перейти к лицензиям.'
    },
    lowOfLicensesPortals: {
      text:
        'Осталось лицензий всего на один Портал (или меньше). Если вы хотите использовать больше Порталов, следует добавить лицензии',
      link: 'Перейти к лицензиям.'
    },
    lowOfLicensesAnalytics: {
      text:
        'Осталось лицензий всего на одну Аналитику (или меньше). Если вы хотите использовать больше Аналитик, следует добавить лицензии',
      link: 'Перейти к лицензиям.'
    }
  },
  registerMyCPEPlease: {
    cpeId: 'Идентификатор устройства',
    cpeRegistrationHeader: 'Регистрация устройства',
    cpeRegisterButton: 'Зарегистрировать',
    goToAllCpeListButton: 'Перейти к списку всех устройств',
    cpeAlreadyRegisteredInfoMessage: 'Устройство с данным идентификатором уже зарегистрировано',
    deviceMoreInfo: 'Устройство — точка доступа или сервер'
  },
  DITStat: {
    tableTitle: 'Выгрузки статистики ДИТ',
    tableColumnId: 'ID',
    tableColumnCPE: 'ТД',
    tableColumnInterval: 'Интервал выгрузки',
    tableColumnFormat: 'Формат',
    tableColumnFtpServer: 'Сервер FTP',
    tableColumnFtpLogin: 'Имя пользователя',
    tableColumnFtpPassword: 'Пароль',
    tableColumnPrivateKey: 'Ключ',
    tableColumnPrivateKeyInfo: 'Ключ может использоваться только для sftp серверов',
    tableColumnEnabled: 'Статус',
    modalHeaderForNew: 'Создание выгрузки ДИТ',
    modalHeaderForEdit: 'Редактирование выгрузки ДИТ',
    modalEnebledCaption: 'Включено',
    modalCPEsCaption: 'Точки доступа',
    modalFTPServerCaption: 'Сервер FTP',
    modalFTPServerPlaceholder: 'Укажите адрес FTP сервера в формате ip:port (например 127.0.0.1:21)',
    modalLoginCaption: 'Имя пользователя FTP сервера',
    modalLoginPlaceholder: 'Укажите имя пользователя FTP сервера',
    modalPasswordCaption: 'Пароль FTP сервера',
    modalPasswordPlaceholder: 'Укажите пароль для FTP сервера',
    modalPrivateKeyCaption: 'Приватный ключ для SFTP сервера',
    modalPrivateKeyPlaceholder: 'Укажите приватный ключ для SFTP сервера',
    modalSFTPAuthTypeCaption: 'Выберете тип аутентификации для SFTP сервера',
    modalPrivateKeyChangeButton: 'Изменить приватный ключ',
    SFTPAuthTypePassword: 'Пароль',
    SFTPAuthTypePrivateKey: 'Приватный ключ',
    modalIntervalCaption: 'Интервал выгрузки',
    modalIntervalPlaceholder: 'Укажите интервал в минутах для выгрузки статистики',
    modalIntervalMinutes: 'в минутах',
    modalFormatCaption: 'Формат выгрузки',
    DITStatNotificationCreated: 'Выгрузка статистики ДИТ успешно создана',
    DITStatNotificationUpdated: 'Выгрузка статистики ДИТ успешно обновлена',
    DITStatNotificationDeleted: 'Выгрузка статистики ДИТ успешно удалена',
    createDitButton: 'Создать выгрузку ДИТ',
    deleteDitModalHeader: 'Удаление выгрузки ДИТ',
    deleteDitModalText: 'Удаление выгрузки ДИТ ',
    filtersForCpeListSwitcherLocationCaption: 'Локация точки доступа',
    filtersForCpeListSwitcherInfoTooltip:
      'Фильтрация упрощает поиск нужной точки доступа в списке выбора точек доступа. Позволяет отфильтровать точки доступа по их локации',
    filtersForCpeListSwitcherCaption: 'Фильтрация списка ТД',
    filtersForCpeListFiltringForCpeListIsEnabled: 'вкл.',
    selectAllCpesButtonCaption: 'Добавить все отфильтр. ТД',
    cancelSelectAllCpesButtonCaption: 'Очистить все выбранные ТД',
    filtersForCpeListResetFiltersButton: 'сбросить фильтр',
    modalCpeListPlaceholder: 'Выберите ТД или введите имя для поиска',
    modalCpeListNoOptionsMsg: 'Нет ТД',
    modalCpeListNoOptionsMsgWhenFilterEnabled: 'Нет ТД, соответствующих условиям фильтров',
    modalCpeListNoResultMsg: 'Не найдено ТД с таким именем',
    modalCpeListNoResultMsgWhenFilterEnabled: 'Не найдено ТД с таким именем, соответствующих условиям фильтров',
    modalSelectedCPEsCaption: 'Выбрано',
    modalSelectedCpesLengthError: 'Выберете хотя бы одну точку доступа',
    sftpSwitcherCaption: 'Включить SFTP',
    sftpSwitcherInfo: 'Позволяет использовать SFTP сервер для выгрузки'
  },
  DBState: {
    tableTitle: 'Состояние БД',
    tableColumnNs: 'Имя коллекции',
    tableColumnCount: 'Количество объектов',
    tableColumnSize: 'Размер данных',
    tableColumnStorageSize: 'Размер данных на диске',
    sortingSortByNs: 'По имени коллекции',
    sortingSortByCount: 'По количеству объектов',
    sortingSortBySize: 'По размеру данных',
    sortingSortByStorageSize: 'По размеру данных на диске',
    Mbytes: 'МБайты',
    Kbytes: 'КБайты',
    Bytes: 'Байты',
    Gbytes: 'ГБайты',
    MbytesShort: 'МБ',
    KbytesShort: 'КБ',
    BytesShort: 'Б',
    GbytesShort: 'ГБ',
    units: 'Единицы измерения'
  },
  PeriodicTasks: {
    tableTitle: 'Периодические задачи',
    PeriodicTasksNotificationCreated: 'Периодическая задача успешно создана',
    PeriodicTasksNotificationUpdated: 'Периодическая задача успешно обновлена',
    PeriodicTasksNotificationDeleted: 'Периодическая задача успешно удалена',
    createPeriodicTaskButton: 'Создать периодическую задачу',
    modalHeaderForNew: 'Новая периодическая задача',
    modalHeaderForEdit: 'Редактирование периодической задачи',
    modalNameCaption: 'Имя',
    modalNamePlaceholder: 'Имя',
    modalActionTypeCaption: 'Тип действия',
    modalActionTypePlaceholder: 'Тип действия',
    modalNotifyTypeCaption: 'Тип уведомления',
    modalNotifyTypePlaceholder: 'Тип уведомления',
    actionType: {
      change_password: 'Смена пароля Wi-Fi сети',
      reboot_point: 'Перезагрузка точки'
    },
    modalSpecCaption: 'Периодичность',
    modalSpecPlaceholder: 'Периодичность',
    modalSpecRawCaption: 'Периодичность в пользовательском формате (cron-выражение)',
    modalSpecRawPlaceholder: 'Периодичность в пользовательском формате (cron-выражение)',
    modalSpecRawInfoMsg: 'Подробнее о формате ввода можно прочитать тут',
    notifyType: {
      telegram: 'Telegram',
      email: 'Эл. почта',
      mqtt: 'MQTT'
    },
    tableColumnName: 'Имя',
    tableColumnActionType: 'Тип действия',
    tableColumnCPEs: 'ТД',
    tableColumnWlans: 'Сети WI-FI',
    tableColumnObjects: 'ТД/Wi-FI',
    tableTooltipCPEs: 'Точки доступа',
    tableTooltipWlans: 'Сети WI-Fi',
    tableColumnSpec: 'Периодичность',
    tableColumnNotifyType: 'Тип уведомления',
    tableColumnNotifyParameters: 'Параметры уведомления',
    deletePeriodicTaskModalHeader: 'Удаление периодической задачи',
    deletePeriodicTaskModalText: 'Удаление периодической задачи',
    notifySettingsTelegramTokenCaption: 'Токен',
    notifySettingsTelegramTokenPlaceholder: 'Токен',
    notifySettingsTelegramTokenInfo: 'Токен, получаемый у @BotFather',
    notifySettingsTelegramChatIdCaption: 'ID чата',
    notifySettingsTelegramChatIdPlaceholder: 'ID чата',
    notifySettingsTelegramChatIdInfo: 'Уникальный идентификатор telegram чата',
    notifySettingsTelegramTextCaption: 'Текст',
    notifySettingsTelegramTextPlaceholder: 'Текст для уведомления',
    notifySettingsEmailToCaption: 'Кому',
    notifySettingsEmailToPlaceholder: 'Адрес email',
    notifySettingsEmailSubjectCaption: 'Тема',
    notifySettingsEmailSubjectPlaceholder: 'Тема письма',
    notifySettingsEmailTextCaption: 'Текст',
    notifySettingsEmailTextPlaceholder: 'Текст письма',
    notifySettingsMQTTTopicCaption: 'Топик',
    notifySettingsMQTTTopicPlaceholder: 'Топик',
    notifySettingsMQTTTopicInfo: 'MQTT Топик',
    notifySettingsMQTTTextCaption: 'Текст',
    notifySettingsMQTTTextPlaceholder: 'Текст',
    notifySettingsForTableCaption: 'Параметры действия',
    notifySettingsForTableForTelegramToChatId: 'Сообщение в чат c id',
    notifySettingsForTableForEmailToAddress: 'Письмо на адрес',
    notifySettingsForTableForMQTTToTopic: 'Сообщение в топик',
    notifySettingsForTableShowMore: 'подробнее',
    notifySettingsForTableHide: 'скрыть',
    filtersForCpeListSwitcherLocationCaption: 'Локация точки доступа',
    filtersForCpeListSwitcherInfoTooltip:
      'Фильтрация упрощает поиск нужной точки доступа в списке выбора точек доступа. Позволяет отфильтровать точки доступа по их локации',
    filtersForCpeListSwitcherCaption: 'Фильтрация списка ТД',
    filtersForCpeListFiltringForCpeListIsEnabled: 'вкл.',
    filtersForWlanListSwitcherLocationCaption: 'Локация сети Wi-Fi',
    filtersForWlanListSwitcherInfoTooltip:
      'Фильтрация упрощает поиск нужной сети Wi-Fi в списке выбора сетей. Позволяет отфильтровать сети Wi-Fi по их локации',
    filtersForWlanListSwitcherCaption: 'Фильтрация списка Wi-Fi',
    filtersForWlanListFiltringForWlanListIsEnabled: 'вкл.',
    selectAllCpesButtonCaption: 'Добавить все отфильтр. ТД',
    selectAllWlansButtonCaption: 'Добавить все отфильтр. сети',
    cancelSelectAllCpesButtonCaption: 'Очистить все выбранные ТД',
    cancelSelectAllWlansButtonCaption: 'Очистить все выбранные сети',
    filtersForCpeListResetFiltersButton: 'сбросить фильтр',
    modalCpeListPlaceholder: 'Выберите ТД или введите имя для поиска',
    modalCpeListNoOptionsMsg: 'Нет ТД',
    modalCpeListNoOptionsMsgWhenFilterEnabled: 'Нет ТД, соответствующих условиям фильтров',
    modalCpeListNoResultMsg: 'Не найдено ТД с таким именем',
    modalCpeListNoResultMsgWhenFilterEnabled: 'Не найдено ТД с таким именем, соответствующих условиям фильтров',
    modalSelectedCPEsCaption: 'Выбрано',
    modalSelectedCpesLengthError: 'Выберете хотя бы одну точку доступа',
    modalWlanListPlaceholder: 'Выберите сеть Wi-Fi или введите имя для поиска',
    modalWlanListNoOptionsMsg: 'Нет сетей Wi-Fi',
    modalWlanListNoOptionsMsgWhenFilterEnabled: 'Нет сетей Wi-Fi, соответствующих условиям фильтров',
    modalWlanListNoResultMsg: 'Не найдено сетей Wi-Fi с таким именем',
    modalWlanListNoResultMsgWhenFilterEnabled:
      'Не найдено сетей Wi-Fi с таким именем, соответствующих условиям фильтров',
    modalSelectedWlansCaption: 'Выбрано',
    modalSelectedWlansLengthError: 'Выберете хотя бы одну сеть Wi-Fi',
    modalCPEsCaption: 'Точки доступа',
    modalWlansCaption: 'Сети Wi-Fi',
    'specFieldCaption@every': 'Период',
    'specFieldInfo@every': 'Запускается с заданной периодичностью',
    'specFieldCaption@hourly': 'Ежечасно',
    'specFieldInfo@hourly': 'Запускается раз в час, в начале часа',
    'specFieldCaption@daily': 'Ежедневно',
    'specFieldInfo@daily': 'Запускается раз в день, в полночь',
    'specFieldCaption@weekly': 'Еженедельно',
    'specFieldInfo@weekly': 'Запускается раз в неделю, в полночь между сб / вс',
    'specFieldCaption@monthly': 'Ежемесячно',
    'specFieldInfo@monthly': 'Запускается раз в месяц в полночь первого числа месяца',
    'specFieldCaption@yearly': 'Ежегодно',
    'specFieldInfo@yearly': 'Запускается раз в год, в полночь, 1 января',
    specFieldCaptionraw: 'Пользовательский формат',
    specFieldInforaw: 'строка в пользовательском формате (cron-выражение)',
    daysShort: 'дн',
    hoursShort: 'ч',
    minutesShort: 'мин',
    secondsShort: 'сек',
    days: 'Дни',
    hours: 'Часы',
    minutes: 'Минуты',
    seconds: 'Секунды',
    allZerosInSpecEveryDurationObjectError: 'Хотя бы одно поле (дни, часы, минуты, секунды) должно быть больше 0',
    period: 'Период',
    sortName: 'По имени',
    sortActionType: 'По типу действия',
    sortCpesCount: 'По количеству ТД',
    sortObjectsCount: 'По количеству объектов',
    sortNotifyType: 'По типу уведомления',
    onlyForWpaWpa2PersonalWLANSMsg:
      'Смена пароля работает только для WPA/WPA2 Personal Wi-FI сетей (В списке для выбора сетей показаны только такие сети)',
    onlyForWpaPersonalWLANSMsg:
      'Смена пароля работает только для WPA Personal Wi-FI сетей (В списке для выбора сетей показаны только такие сети)'
  },
  downloadFilesNames: {
    visitors: 'Посетители',
    users: 'Пользователи',
    statSystem: 'Статистика-Локации',
    statCPEs: 'Статистика-Точки',
    statWLANs: 'Статистика-Сети WI-FI',
    statClients: 'Статистика-Клиенты',
    statClientsSessions: 'Статистика-Клиенты-Сессии',
    statSNMP:'Статистика-SNMP',
    monitoringEvents:'Монитор-События',
    monitoringCPEConnectivity: 'Монитор-Подключение ТД',
    monitoringDBState: 'Монитор-Состояние БД',
    cpes: 'Точки',
    clients: 'Клиенты',
    licenses: 'Лицензии',
    portalHotelVouchers: 'Отельные ваучеры',
    portalInAdsAndPollsStatisticSection: 'Статистика просмотров рекламы',
    portalInAdsAndPollsStatisticSectionAll: 'по всем типам',
    portalInAdsAndPollsStatisticSectionBy: 'по типам авторизации',
    portalAccessServers: 'Портал-Серверы доступа',
    portalAccounts: 'Портал-Пользователи',
    portalBirthdays: 'Портал-Дни Рождения для',
    portalAdsAndPollsStatisticSection: 'Статистика просмотров рекламы',
    portalAdsAndPollsStatisticSectionAll: 'по всем типам',
    portalAdsAndPollsStatisticSectionBy: 'по типам авторизации',
    portalVouchers: 'Ваучеры',
    analyticsVisitors: 'Аналитика-Посетители',
    portalStatistic: 'Портал-Статистика',
    portalStatisticPieChartUsers: 'Пользователи',
    portalStatisticPieChartBrowsers: 'Браузеры',
    portalStatisticPieChartOperatingSystems: 'Операционные системы',
    portalStatisticPieChartLocale: 'Языки',
    portalStatisticPieChartDeviceType: 'Типы устройств',
    portalStatisticPieChartVendors: 'Производители устройств',
    portalStatisticPieChartAuthorizationType: 'Способы авторизации',
    portalStatisticPieChartAuthenticationType: 'Тип идентификации',
    portalStatisticLineChartNewAndRepeatUsers: 'Новые и повторные пользователи',
    portalStatisticLineChartOperatingSystems: 'Операционные системы',
    portalStatisticLineChartLocale: 'Языки',
    portalStatisticLineChartDeviceType: 'Типы устройств',
    portalStatisticLineChartVendors: 'Производители устройств',
    portalStatisticLineChartAuthorizationType: 'Способы авторизации',
    portalStatisticLineChartAuthenticationType: 'Тип идентификации',
  },
  configExportImport: {
    configExportHeader: 'Экспорт конфигурации',
    configImportHeader: 'Импорт конфигурации',
    exportButtonCaption: 'Экспортировать',
    importButtonCaption: 'Импортировать',
    downloadButtonCaption: 'Скачать файл',
    saveToFileError: 'Не удалось сохранить в файл',
    fileParsingError: 'Ошибка при разборе выбранного файла',
    forLocation: 'Импорт для локации',
    withChildsTrue: 'с дочерними локациями',
    withChildsFalse: 'без дочерних локаций',
    configSuccessfullyImported: 'Конфигурация успешно импортирована',
    chooseAFile: 'Выберите файл',
    selectedFile: 'Выбраный файл',
    fileImportCpation: 'Файл для импорта',
    viewFileCaption: 'Просмотреть файл'
  }
};

export default locale;
