<template>
  <section v-if="cpeModelData.caps.wired && Object.keys(cpeModelData.caps.wired).length">
    <div class="form-group interface-block" v-for="wiredInterface in objWiredConfig" :key="wiredInterface.id">
      <div class="interface-block-header mb-h" v-if="cpeModelData.caps.wired[wiredInterface.id]">
        <div class="mb-1 interface-block-title__wired">
          <h6>
            <img class="interface-block-icon" src="/static/img/ethernet1.svg" />
            <strong>{{ wiredInterface.id }}</strong>
          </h6>
        </div>
      </div>
      <div
        v-for="(vlanConfig, index) in wiredInterface.vlans"
        class="animated fadeIn mb-2"
        v-if="wiredInterface.vlans.length"
      >
        <div class="setting-block-header">
          <div class="setting-block-status">
            <p class="h6 mb-1 pr-h setting-block-title">{{ $t('aps.Config') }} #{{ index + 1 }}</p>
          </div>
        </div>

        <p class="small m-0">
          <strong>{{ $t('aps.currentState') }}</strong>
          <span
            v-if="checkAreSelectedAndWLANtunnelsNotMatch(vlanConfig) && !vlanConfig.nat"
            class="has-tooltip ml-q"
            v-tooltip.right="{ content: `${$t('aps.selectedAndWLANtunnelsAreNotMatch')}` }"
          >
            <i class="fa fa-warning text-danger"></i>
          </span>
        </p>

        <small>
          <span>VLAN:</span>
          <strong>{{ vlanConfig.vlan || `${$t('aps.wiredVLANNAtive')}` }}</strong>
          <span class="ml-q">{{ $t('aps.wiredPorts') }}:</span>
          <strong v-if="cpeModelData.caps.wired[wiredInterface.id].ports.length && vlanConfig.ports.length">
            <span
              v-for="port in cpeModelData.caps.wired[wiredInterface.id].ports"
              v-if="
                !singlePorts[wiredInterface.id] &&
                (port.role === 'lan' || port.role === 'wan') &&
                port.type === 'socket' &&
                vlanConfig.ports.includes(port.index)
              "
            >
              {{ port.role }}{{ port.index }}
            </span>
          </strong>
          <strong v-else class="text-muted">{{ $t('misc.none') }}</strong>
          <!--Redirect:-->
          <!--<strong>-->
          <!--<span :class="{'text-muted': !vlanConfig.guest_control.captive_redirect}">-->
          <!--{{vlanConfig.guest_control.captive_redirect ? commonService.redirectNamebyId(vlanConfig.guest_control.captive_redirect) : 'none' }},-->
          <!--</span>-->
          <!--</strong>-->
          <!--MAC RADIUS Authentication:-->
          <!--<strong>-->
          <!--<span-->
          <!--v-if="!vlanConfig.guest_control.mac_radius_auth_servers.length"-->
          <!--class="text-muted"-->
          <!--&gt;-->
          <!--none-->
          <!--</span>-->
          <!--<span v-else v-for="radius in vlanConfig.guest_control.mac_radius_auth_servers">-->
          <!--{{ commonService.radiusNamebyId(radius) }}-->
          <!--</span>-->
          <!--</strong>,-->

          <span v-if="isRootLocationUser" class="ml-q">{{ $t('aps.wiredTunnel') }}:</span>
          <strong v-if="isRootLocationUser" :class="{ 'text-muted': !vlanConfig.tunnel }">
            {{ vlanConfig.tunnel || `${$t('aps.wiredNoTunel')}` }}
          </strong>
          <span class="ml-q">NAT:</span>
          <strong :class="{ 'text-muted': !vlanConfig.nat }">
            {{ vlanConfig.nat ? $t('general.On') : $t('general.Off') }}
          </strong>
          <span class="ml-q">{{$t('aps.wiredConfigAccounting')}}:</span>
          <strong :class="{ 'text-muted': !vlanConfig.acct }">
            {{ vlanConfig.acct ? $t('general.On') : $t('general.Off') }}
          </strong>
        </small>

        <p class="wired-actions" v-if="!isDisable">
          <a href="#" class="wired-action" @click.prevent="showWiredConfig(wiredInterface, vlanConfig, index)">
            {{ $t('general.edit') }}
          </a>
          <a href="#" class="wired-action text-danger" @click.prevent="removeVLAN(wiredInterface.vlans, index)">
            {{ $t('general.delete_sm') }}
          </a>
        </p>
        <hr class="hr-settings mb-2" />
      </div>

      <div v-if="!isDisable">
        <a href="#" class="text-success" @click.prevent="addWiredConfig(wiredInterface)">+ {{ $t('aps.addConfig') }}</a>
      </div>
    </div>

    <modal close-btn  @cancel="cancelWiredConfigModal" title="Profile" class="modal-success" v-model="wiredConfigModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.WiredConfig') }}</h4>

      <div v-if="currentWiredInterface && currentVlanConfig">
        <h6 class="mt-1 mb-1">{{ $t('aps.Input') }}</h6>
        <div class="form-group mb-0">
          <p class="mb-q">{{ $t('aps.Ports') }}:</p>
          <label
            class="custom-control custom-checkbox"
            :class="{ 'custom-control__disabled': isDisable }"
            v-for="port in cpeModelData.caps.wired[currentWiredInterface.id].ports"
            :key="port.num"
            v-if="
              !singlePorts[currentWiredInterface.id] &&
              (port.role === 'lan' || port.role === 'wan') &&
              port.type === 'socket'
            "
          >
            {{ port.role }}{{ port.index }}
            <!--            <input-->
            <!--              type="checkbox"-->
            <!--              class="custom-control-input"-->
            <!--              :disabled="isDisable"-->
            <!--              :checked="currentVlanConfig.ports.includes(port.index)"-->
            <!--              @click="changePorts(currentVlanConfig.ports, port.index)"-->
            <!--            />-->
            <input
              type="checkbox"
              class="custom-control-input"
              :disabled="isDisable"
              :checked="currentVlanConfig.ports.includes(port.index)"
              @click="changePorts(currentVlanConfig.ports, port.index)"
            />
            <info
              class="isCpeModelEqualRTBR24WFN2Ev61-info-msg"
              v-if="isCpeModelEqualRTBR24WFN2Ev61AndLan2Checked && port.role === 'lan' && port.index == '2'"
              :content="$t('aps.isCpeModelEqualRTBR24WFN2Ev61InfoMsgForLan2')"
            />
            <span
              aria-hidden="true"
              class="custom-control-indicator custom-control-indicator--vlan"
              :class="{ 'custom-control-indicator--danger': currentVlanConfig.vlan === 0 && port.role === 'wan' }"
            ></span>
          </label>
        </div>

        <div class="form-group mb-1">
          <label for="vlan" class="">
            VLAN
            <small class="text-muted ml-h">{{ $t('aps.wiredSet0forNative') }}</small>
          </label>
          <input
            type="text"
            id="vlan"
            v-if="!isOperator"
            name="vlan"
            :class="{
              input: true,
              'is-danger':
                errors.has('vlan') ||
                isSystemVlan(currentWiredInterface.id, currentVlanConfig.vlan) ||
                // isNativeVlanOnSinglePort(currentWiredInterface.id, currentVlanConfig.vlan) ||
                isNativeVlanOnWanPort(currentWiredInterface.id, currentVlanConfig.vlan, currentVlanConfig.ports)
            }"
            v-validate="
              `required|numeric|between:${checkMinVlan(
                currentVlanConfig.ports,
                wanIndex[currentWiredInterface.id],
                currentWiredInterface.id
              )},4094|excluded:${systemVlans[currentWiredInterface.id]}`
            "
            placeholder="VLAN"
            class="form-control"
            v-model.number="currentVlanConfig.vlan"
            :disabled="isDisable"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('vlan') }"
            v-if="errors.has('vlan')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
          <span
            v-tooltip.top-center="{ content: 'VLAN not supported' }"
            v-if="isSystemVlan(currentWiredInterface.id, currentVlanConfig.vlan)"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
          <!--          <span-->
          <!--            v-tooltip.top-center="{ content: 'VLAN 0 brakes connectivity to Access Point' }"-->
          <!--            v-if="isNativeVlanOnSinglePort(currentWiredInterface.id, currentVlanConfig.vlan)"-->
          <!--            class="help is-danger error_tooltip"-->
          <!--          >-->
          <!--            <i class="fa fa-warning text-error"></i>-->
          <!--          </span>-->
          <span
            v-if="isNativeVlanOnSinglePort(currentWiredInterface.id, currentVlanConfig.vlan)"
            class="help is-danger location-danger"
          >
            {{ $t('aps.zeroVLANIfNativeVlanOnSinglePortInfoMsg') }}
          </span>
          <span
            v-tooltip.top-center="{ content: 'VLAN 0 selection on WAN brakes connectivity to Access Point' }"
            v-if="isNativeVlanOnWanPort(currentWiredInterface.id, currentVlanConfig.vlan, currentVlanConfig.ports)"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
          <span
            v-tooltip.top-center="{ content: $t('aps.isCpeModelEqualRTBR24WFN2Ev61ErrorMsgForLan2') }"
            v-if="isCpeModelEqualRTBR24WFN2Ev61AndLan2CheckedAndVlanIs0"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>

        <h6 class="mt-3">{{ $t('aps.Guestcontrol') }}</h6>
        <div class="form-group">
          <label for="captive_redirect" class="d-flex justify-content-between">
            <b>{{ $t('aps.Redirect') }}</b>
          </label>
          <select
            name="captive_redirect"
            v-if="!isOperator"
            id="captive_redirect"
            v-model="currentVlanConfig.guest_control.captive_redirect"
            class="form-control"
            :disabled="isDisable"
            :class="{ 'select-disabled': isDisable }"
          >
            <option value="" checked>{{ $t('misc.none') }}</option>
            <option v-for="redirect in redirectsList" :value="redirect.id" :key="redirect.id">
              {{ redirect.name }}
            </option>
          </select>
          <span v-else>{{ commonService.wlanNamebyId(currentVlanConfig.fake_wlan) || 'None' }}</span>
        </div>

        <h6 class="mt-3 mb-1">
          <small class="float-right text-danger" v-if="isNoOutput && isRootLocationUser">
            {{ $t('aps.OutputWarning') }}
          </small>
          <small class="float-right text-danger" v-if="isNoOutput && !isRootLocationUser">
            {{ $t('aps.OutputWarningForNonRootLocationUsers') }}
          </small>
        </h6>
        <div class="form-group mb-h">
          <span class="m-0 mt-1 mr-1"><b>NAT</b></span>
          <Switch-component
            v-model="currentVlanConfig.nat"
            :disabled="isDisable || (currentVlanConfig.nat === true && !isRootLocationUser)"
            :class="{
              'disable-turned-on-switcher-in-wired-config':
                isDisable || (currentVlanConfig.nat === true && !isRootLocationUser)
            }"
            :id="'nat'"
            @change="handleNATUncheck"
          />
        </div>
        <div v-if="currentVlanConfig.nat === true && currentVlanConfig.hasOwnProperty('nat_network')">
          <span class="text-muted">{{ $t('aps.natNetworkInfoMsg') }}</span>
          <div class="form-group mt-1" v-show="currentVlanConfig.nat === true">
            <label for="nat_network_ipaddr">
              {{ $t('aps.natNetworkIpaddr') }}
            </label>
            <input
              type="text"
              :key="'nat_network_ipaddr'"
              id="nat_network_ipaddr"
              autocomplete="off"
              name="nat_network_ipaddr"
              v-validate="'ipv4ForNatNetwork'"
              data-vv-validate-on="change"
              :class="{ input: true, 'is-danger': errors.has('nat_network_ipaddr') }"
              :placeholder="$t('aps.natNetworkIpaddrPlaceholder')"
              class="form-control"
              v-model="currentVlanConfig.nat_network.ipaddr"
            />
            <span v-show="errors.has('nat_network_ipaddr')" class="help is-danger">
              {{ errors.first('nat_network_ipaddr') }}
            </span>
          </div>
          <div class="form-group mt-1 mb-2" v-show="currentVlanConfig.nat === true">
            <label for="nat_network_netmask">
              {{ $t('aps.natNetworkNetmask') }}
            </label>
            <input
              type="text"
              :key="'nat_network_netmask'"
              id="nat_network_netmask"
              autocomplete="off"
              name="nat_network_netmask"
              v-validate="'maskForNatNetwork'"
              data-vv-validate-on="change"
              :class="{ input: true, 'is-danger': errors.has('nat_network_netmask') }"
              :placeholder="$t('aps.natNetworkNetmaskPlaceholder')"
              class="form-control"
              v-model="currentVlanConfig.nat_network.netmask"
            />
            <span v-show="errors.has('nat_network_netmask')" class="help is-danger">
              {{ errors.first('nat_network_netmask') }}
            </span>
          </div>
        </div>

        <div v-if="currentVlanConfig.nat" class="form-group mb-h">
          <span class="m-0 mt-1 mr-1">
            <b>{{ $t('aps.natAccess') }}</b>
          </span>
          <Switch-component
            v-model="currentVlanConfig.nat_access"
            :disabled="isDisable"
            :label="''"
            :id="'nat-access'"
            @change="handleNATUncheck"
          />
        </div>

        <div v-if="currentVlanConfig.nat">
          <h6 class="mt-1 mb-0">{{ $t('aps.downloadSpeed') }}</h6>
          <small class="text-muted mb-h d-block">{{ $t('aps.downloadSpeedDescription') }}</small>
          <div class="form-group mb-h">
            <label for="download-max">
              {{ $t('aps.downloadSpeedMax') }}
            </label>
            <input
              type="number"
              class="form-control"
              id="download-max"
              name="download-max"
              v-model.number="currentVlanConfig.speed_download.max"
              :disabled="isDisable"
              :class="{ input: true, 'is-danger': errors.has('download-max') }"
              v-validate="'min_value:0'"
            />
            <span
              v-tooltip.top-center="{ content: errors.first('download-max') }"
              v-if="errors.has('download-max')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
          </div>

          <div class="form-group mb-h">
            <label for="download-min">
              {{ $t('aps.downloadSpeedMin') }}
            </label>
            <input
              type="number"
              class="form-control"
              id="download-min"
              name="download-min"
              v-model.number="currentVlanConfig.speed_download.min"
              :disabled="isDisable"
              :class="{ input: true, 'is-danger': errors.has('download-min') }"
              v-validate="'min_value:0'"
            />
            <span
              v-tooltip.top-center="{ content: errors.first('download-min') }"
              v-if="errors.has('download-min')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
          </div>

          <div class="form-group mb-h">
            <label for="download-type">
              {{ $t('aps.downloadSpeedType') }}
            </label>
            <select
              class="form-control input"
              id="download-type"
              name="download-type"
              v-model="currentVlanConfig.speed_download.type"
              :disabled="isDisable"
              :class="{ 'select-disabled': isDisable }"
            >
              <option value="mbit" selected>{{ $t('general.mbit') }}</option>
              <option value="kbit">{{ $t('general.kbit') }}</option>
            </select>
          </div>
        </div>

        <div class="form-group mt-h" v-if="isRootLocationUser">
          <label for="tunnel">
            <b>{{ $t('aps.Tunnel') }}</b>
          </label>
          <div v-if="!isOperator && interfacesWithDetailsFromAllHostsAsArray.length">
<!--            <Multiselect-->
<!--              :customLabel="customLabelForTunnelMiltiselect"-->
<!--              id='tunnel'-->
<!--              v-model="currentVlanConfig.tunnel"-->
<!--              :multiple="false"-->
<!--              :options="interfacesWithDetailsFromAllHostsAsArray.map(interfaceWithdetails=>interfaceWithdetails.name) || []"-->
<!--              :closeOnSelect="true"-->
<!--              :hideSelected="true"-->
<!--              :placeholder="$t('wlans.selectSingleTunnel')"-->
<!--              :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--              :disabled="isDisable || currentVlanConfig.nat"-->
<!--              :class="{ 'multiselect-disabled': isDisable || currentVlanConfig.nat}"-->
<!--              :allowEmpty="true"-->
<!--            >-->
<!--              <template v-slot:noOptions>-->
<!--                {{ $t('general.noDataToDisplay') }}-->
<!--              </template>-->
<!--              <template v-slot:singleLabel>-->
<!--                {{labelForSelectedInTunnelMiltiselect()}}-->
<!--              </template>-->
<!--              <template v-slot:noResult>-->
<!--                {{ $t('general.noResultForSearch') }}-->
<!--              </template>-->
<!--            </Multiselect>-->
            <Multiselect
              label="nameWithType"
              trackBy="name"
              @input="selectTunnel"
              id='tunnel'
              v-model="tunnelInMultiselector"
              :multiple="false"
              :options="interfacesWithDetailsFromAllHostsAsArray || []"
              :closeOnSelect="true"
              :hideSelected="true"
              :placeholder="$t('wlans.selectSingleTunnel')"
              :selectLabel="$t('aps.wlansSelectorTip')"
              :disabled="isDisable || currentVlanConfig.nat"
              :class="{ 'multiselect-disabled': isDisable || currentVlanConfig.nat}"
              :allowEmpty="true"
            >
              <template v-slot:noOptions>
                {{ $t('general.noDataToDisplay') }}
              </template>
<!--              <template v-slot:singleLabel>-->
<!--                {{labelForSelectedInTunnelMiltiselect()}}-->
<!--              </template>-->
              <template v-slot:noResult>
                {{ $t('general.noResultForSearch') }}
              </template>
            </Multiselect>
            <a v-if="!(isDisable || currentVlanConfig.nat)" href="#" class="ml-q "
               @click.prevent="clearCurrentVlanConfigTunnel">
              <span >{{ $t('general.clear') }}</span>
            </a>
          </div>

          <span
            v-tooltip.top-center="{ content: errors.first('tunnel') }"
            v-if="errors.has('tunnel')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error"></i>
          </span>
        </div>

<!--        <div class="form-group mt-h" v-if="isRootLocationUser">-->
<!--          <label for="tunnel">-->
<!--            <b>{{ $t('aps.Tunnel') }}</b>-->
<!--          </label>-->
<!--          <select-->
<!--            class="form-control input"-->
<!--            id="tunnel"-->
<!--            v-if="!isOperator && hostsList[0]"-->
<!--            v-model="currentVlanConfig.tunnel"-->
<!--            name="tunnel"-->
<!--            data-vv-as="tunnel"-->
<!--            :disabled="isDisable || currentVlanConfig.nat"-->
<!--            :class="{-->
<!--              'select-disabled': isDisable || currentVlanConfig.nat,-->
<!--              'is-danger': errors.has('tunnel')-->
<!--            }"-->
<!--          >-->
<!--            <option value="" selected>{{ $t('aps.wiredNoTunel') }}</option>-->
<!--            <option v-for="hostInterface in hostsList[0].interfaces" :value="hostInterface">{{ hostInterface }}</option>-->
<!--          </select>-->
<!--          <span-->
<!--            v-tooltip.top-center="{ content: errors.first('tunnel') }"-->
<!--            v-if="errors.has('tunnel')"-->
<!--            class="help is-danger error_tooltip"-->
<!--          >-->
<!--            <i class="fa fa-warning text-error"></i>-->
<!--          </span>-->
<!--        </div>-->

        <h6 class="mt-3 mb-1">{{$t('aps.wiredConfigAccounting')}}</h6>
        <div class="form-group mb-h">
          <span class="m-0 mt-1 mr-1"><b>{{$t('aps.wiredConfigAccountingEnable')}}</b></span>
          <Switch-component v-model="currentVlanConfig.acct" :disabled="isDisable" :label="''" :id="'accounting'" />
        </div>

        <div class="form-group">
          <label for="tunnel">{{ $t('aps.wiredConfigFakeWlan') }}</label>
          <div v-if="!isOperator">
<!--            <Multiselect-->
<!--              id='wlanMultiselect'-->
<!--              v-model="currentVlanConfig.fake_wlan"-->
<!--              :multiple="false"-->
<!--              :customLabel="customLabelForWlanMiltiselect"-->
<!--              :options="wlansListForWlansSearch || []"-->
<!--              :closeOnSelect="true"-->
<!--              :hideSelected="true"-->
<!--              :placeholder="$t('wlans.selectSingleWlan')"-->
<!--              :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--              :disabled="isDisable"-->
<!--              :loading="loadingDataForWlansList"-->
<!--              @search-change="asyncSearchWlanWhenSearchChange"-->
<!--              @open="asyncSearchWlanWhenOpenSelector"-->

<!--            >-->
<!--              <template v-slot:singleLabel>-->
<!--                {{labelForSelectedInWlanMiltiselect()}}-->
<!--              </template>-->
<!--              <template v-slot:noOptions>-->
<!--                {{ $t('general.noOptionsForSearch') }}-->
<!--              </template>-->
<!--              <template v-slot:noResult>-->
<!--                {{ $t('general.noResultForSearch') }}-->
<!--              </template>-->
<!--            </Multiselect>-->
            <Multiselect
              id='wlanMultiselect'
              :internalSearch = "false"
              v-model="currentVlanConfig.fake_wlan"
              :multiple="false"
              :customLabel="customLabelForWlanMiltiselect"
              :options="wlansListForWlansSearch.map(wlan=>wlan.id) || []"
              :closeOnSelect="true"
              :hideSelected="true"
              :placeholder="$t('wlans.selectSingleWlan')"
              :selectLabel="$t('aps.wlansSelectorTip')"
              :disabled="isDisable"
              :loading="loadingDataForWlansList"
              :class="{ 'multiselect-disabled': isDisable }"
              @search-change="asyncSearchWlanWhenSearchChange"
              @open="asyncSearchWlanWhenOpenSelector"
              :allowEmpty="true"

            >
              <template v-slot:singleLabel>
                {{labelForSelectedInWlanMiltiselect()}}
              </template>
              <template v-slot:noOptions>
                {{ $t('general.noOptionsForSearch') }}
              </template>
              <template v-slot:noResult>
                {{ $t('general.noResultForSearch') }}
              </template>
            </Multiselect>
<!--            <span @click="clearCurrentVlanConfigFakeWlan">x</span>-->
            <a v-if="!isDisable" href="#" class="ml-q "
               @click.prevent="clearCurrentVlanConfigFakeWlan">
              <span >{{ $t('general.clear') }}</span>
            </a>
          </div>

          <span v-else>{{ commonService.wlanNamebyId(currentVlanConfig.fake_wlan) || 'None' }}</span>
          <transition name="interface">
            <div v-if="!currentVlanConfig.nat && areSelectedAndWLANtunnelsNotMatchForModal"
                 class="form-group mt-1">
              <Alert class="alert-danger">
                <template #text>
                  <span class="mr-h">{{ $t('aps.selectedAndWLANtunnelsAreNotMatch') }}</span>
                </template>
              </Alert>
            </div>
          </transition>
        </div>

<!--        <div class="form-group">-->
<!--          <label for="tunnel">Fake WLAN</label>-->
<!--          <select-->
<!--            name="wlan_eth"-->
<!--            v-if="!isOperator"-->
<!--            id="wlan_eth"-->
<!--            v-model="currentVlanConfig.fake_wlan"-->
<!--            class="form-control"-->
<!--            :disabled="isDisable"-->
<!--            :class="{ 'select-disabled': isDisable }"-->
<!--          >-->
<!--            <option value="" checked>{{ $t('misc.none') }}</option>-->
<!--            <option v-for="wlan in wlansList" :value="wlan.id" :key="wlan.id">-->
<!--              {{ wlan.ssid }}-->
<!--              <span v-if="wlan.hasOwnProperty('default_tunnel') && wlan.default_tunnel !== ''">-->
<!--                ({{ $t('aps.Tunnel').toLowerCase() }} {{ wlan.default_tunnel }})-->
<!--              </span>-->
<!--              &lt;!&ndash;            <span v-if="wlan.hasOwnProperty('default_tunnel') && wlan.default_tunnel === ''">   ({{$t('aps.Tunnel').toLowerCase()}} {{$t('general.no').toLowerCase()}})</span>&ndash;&gt;-->
<!--            </option>-->
<!--          </select>-->
<!--          <span v-else>{{ commonService.wlanNamebyId(currentVlanConfig.fake_wlan) || 'None' }}</span>-->

<!--          <transition name="interface">-->
<!--            <div v-if="!currentVlanConfig.nat && areSelectedAndWLANtunnelsNotMatchForModal" class="form-group mt-1">-->
<!--              <Alert class="alert-danger">-->
<!--                <template #text>-->
<!--                  <span class="mr-h">{{ $t('aps.selectedAndWLANtunnelsAreNotMatch') }}</span>-->
<!--                </template>-->
<!--              </Alert>-->
<!--            </div>-->
<!--          </transition>-->
<!--        </div>-->
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="cancelWiredConfigModal()">
          {{ !isDisable ? $t('general.cancel') : $t('general.close') }}
        </button>
        <button
          v-if="!isDisable"
          type="button"
          class="btn btn-outline-success"
          @click="saveWiredConfig()"
          :disabled="errors.any()"
        >
          {{ $t('general.save') }}
        </button>
      </div>
    </modal>
  </section>
</template>

<script>
import Vue from 'vue';
import { Multiselect } from 'vue-multiselect';
import Alert from '@/components/Universal/alert/alert.vue';
import Modal from '../Modal.vue';
import commonService from '../../services/commonService';
import radiusService from '../../services/radiusService';
import SwitchComponent from '../Universal/Switch-component.vue';
import Info from '../Universal/info-icon.vue';
import wlanService from '../../services/wlanService';
import helpers from "../../helpers";

export default {
  name: 'WiredConfig',
  components: {
    Modal, Multiselect, SwitchComponent, Info, Alert
  },
  inject: ['$validator'],
  props: {
    cpeModelData: {
      type: Object
    },
    objWiredConfig: {
      type: Array
    },
    objCurrentWiredConfig: {
      type: Array
    },
    objWiredState: {
      type: Object
    },
    isRootUser: {
      type: Boolean
    },
    isDisable: {
      type: Boolean
    },
    isOperator: {
      type: Boolean
    },
    hostsList: {
      type: Array
    },
    wlansList: {
      type: Array
    },
    redirectsList: {
      type: Array
    }
  },
  data() {
    return {
      tunnelInMultiselector: {},
      wiredConfigModal: false,
      wiredConfig: {},
      currentVlanConfig: false,
      currentWiredInterface: false,
      currentConfigIndex: false,
      currentWiredRADIUS: [],
      isWiredConfigShow: {},
      systemVlans: {},
      singlePorts: {},
      wanIndex: {},
      isNoOutput: false,
      loadingDataForWlansList: false,
      wlansListForWlansSearch: [],
      timeoutForListForWlansSearch: undefined
    };
  },
  watch: {
    objWiredConfig() {
      this.resetWiredConfig();
    },
    wiredConfigModal(val) {
      if (!this.$parent.changePosition) {
        return;
      }

      if (val) {
        this.$parent.changePosition('static');
      } else {
        this.$parent.handleScroll();
      }
    }
  },
  computed: {
    interfacesWithDetailsFromAllHostsAsArray() {
      return Object.values(this.createInterfacesWithDetailsFromAllHostsList(this.hostsList));
    },
    interfacesWithDetailsFromAllHostsAsObjectWithIdKeys() {
      return this.createInterfacesWithDetailsFromAllHostsList(this.hostsList);
    },
    areSelectedAndWLANtunnelsNotMatchForModal() {
      // возвращаем true если туннель и туннель выбраной wlan (если он есть) не совпадают
      return this.checkAreSelectedAndWLANtunnelsNotMatch(this.currentVlanConfig);
      // try {
      //   const selectedTunnel = this.currentVlanConfig.tunnel;
      //   const selectedWlanId = this.currentVlanConfig.fake_wlan;
      //   let selectedWlanTunnel = '';
      //
      //   for (const wlan of this.wlansList) {
      //     if (wlan.id === selectedWlanId) {
      //       if (Object.prototype.hasOwnProperty.call(wlan, 'default_tunnel')) {
      //         selectedWlanTunnel = wlan.default_tunnel;
      //       }
      //       break;
      //     }
      //   }
      //
      //   if (selectedWlanTunnel !== '' && selectedWlanTunnel !== selectedTunnel) {
      //     return true;
      //   }
      //   return false;
      // } catch (e) {
      //   console.log(e);
      //   return false;
      // }
    },
    isCpeModelEqualRTBR24WFN2Ev61AndLan2CheckedAndVlanIs0() {
      try {
        // выдаем true если точка с моделью RT-BR24-WFN2Ev6.1 и выбрана галочка на lan2 и при этом
        // vlan 0
        if (
          this.isCpeModelEqualRTBR24WFN2Ev61AndLan2Checked &&
          this.currentVlanConfig.hasOwnProperty('vlan') &&
          this.currentVlanConfig.vlan === 0
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isCpeModelEqualRTBR24WFN2Ev61AndLan2Checked() {
      try {
        // тут проверка для задачи   WNE-2266
        // проверяем для точек с моделью RT-BR24-WFN2Ev6.1 есть ли порт lan2 и включена ли на нем галочка в блоке VLAN модалки
        // проверяем все только если редактируется нужная точка
        if (this.isCpeModelEqualRTBR24WFN2Ev61) {
          let hasLan2Port = false;
          // смотрим есть ли вобще lan2 порт
          if (
            this.cpeModelData.hasOwnProperty('caps') &&
            this.cpeModelData.caps.hasOwnProperty('wired') &&
            this.cpeModelData.caps.wired.hasOwnProperty(this.currentWiredInterface.id) &&
            this.cpeModelData.caps.wired[this.currentWiredInterface.id].hasOwnProperty('ports') &&
            Array.isArray(this.cpeModelData.caps.wired[this.currentWiredInterface.id].ports)
          ) {
            for (const port of this.cpeModelData.caps.wired[this.currentWiredInterface.id].ports) {
              if (port.role === 'lan' && port.num === 2) {
                hasLan2Port = true;
                break;
              }
            }
          }
          // если есть порт то смотрим стоит ли галочка на этом lan2 и если стоит возвращаем
          // что минимальный Vlan это 1
          if (hasLan2Port) {
            // console.log('есть lan2 порт')
            if (
              this.currentVlanConfig.hasOwnProperty('ports') &&
              Array.isArray(this.currentVlanConfig.ports) &&
              this.currentVlanConfig.ports.includes('2')
            ) {
              return true;
            }
          }
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isCpeModelEqualRTBR24WFN2Ev61() {
      try {
        // проверяем не RT-BR24-WFN2Ev6.1 ли точка ( модели точки)
        // нужно для задачки WNE-2266, чтобы запретить lan2 при vlan0 именно для этой модели точек

        // проверяем на название модели
        if (Object.prototype.hasOwnProperty.call(this.cpeModelData, 'name')) {
          if (this.cpeModelData.name === 'rotek,rt-br24-wfn2e-v6.1') {
            return true;
          }
        }
        // на всякий случай еще проверяем на название описания модели
        if (Object.prototype.hasOwnProperty.call(this.cpeModelData, 'description')) {
          if (this.cpeModelData.description === 'Rotek RT-BR24-WFN2E v6.1') {
            return true;
          }
        }
        // console.log(this.cpeModelData)
        return false;
      } catch (e) {
        return false;
      }
    },
    commonService() {
      return commonService;
    },
    radiusList() {
      return this.$store.state.radiusList;
    },
    isAdmin() {
      try {
        return this.$store.state.userData.role === 'admin';
      } catch (e) {
        return false;
      }
    },
    isRootLocationUser() {
      return this.isRootUser;
    }
  },
  methods: {
    selectTunnel(payload) {
      // console.log('payload', payload)
      // console.log(payload)
      this.currentVlanConfig.tunnel = payload.name
    },
    customLabelForTunnelMiltiselect(tunnelName) {
      try {
        return this.createTunnelCaptionByName(tunnelName)
      } catch (e) {
        return tunnelName;
      }
    },
    labelForSelectedInTunnelMiltiselect() {
      const tunnelName = this.currentVlanConfig.tunnel
      return this.createTunnelCaptionByName(tunnelName);
    },
    createTunnelCaptionByName(tunnelName) {
      try {
        return `${tunnelName} (${this.$t('general.type').toLowerCase()} ${this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[tunnelName].type})`
      } catch (e) {
        return tunnelName
      }
    },
    createInterfacesWithDetailsFromAllHostsList(hosts) {
      // return helpers.createInterfacesWithDetailsFromAllHostsList(hosts)
      return helpers.createInterfacesWithDetailsAndNameWithTypeFromAllHostsList(this, hosts);
      // try {
      //   const result = {}
      //   for (const host of hosts) {
      //     if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
      //       Array.isArray(host.interfaces_details) &&
      //       host.interfaces_details.length > 0) {
      //       for (const interfaceWithDetails of host.interfaces_details) {
      //         if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
      //           interfaceWithDetails.name !== '' &&
      //           !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
      //           result[interfaceWithDetails.name] = interfaceWithDetails;
      //         }
      //       }
      //     }
      //   }
      //   return result;
      // } catch (e) {
      //   console.log(e)
      //   return {}
      // }
    },
    async asyncSearchWlanWhenOpenSelector(id) {
      return this.asyncSearchWlan('', 0);
    },
    async asyncSearchWlanWhenSearchChange(query) {
      return this.asyncSearchWlan(query);
    },
    async asyncSearchWlan(query, timeout = 1000) {
      if (this.timeoutForListForWlansSearch !== undefined) {
        clearTimeout(this.timeoutForListForWlansSearch)
      }
      this.loadingDataForWlansList = true;
      this.timeoutForListForWlansSearch = setTimeout(async () => {

        // console.log(query);
        // console.log(id);
        // this.loadingDataForWlansList = true;
        try {
          const response = await wlanService.requestWlansWithSearch(query);
          this.wlansListForWlansSearch = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlansListForWlansSearch = [];
        } finally {
          this.loadingDataForWlansList = false;

          // console.log(this.wlansListForWlansSearch[id]);
        }
      }, timeout);
    },
    clearCurrentVlanConfigFakeWlan() {
      this.currentVlanConfig.fake_wlan = '';
    },
    clearCurrentVlanConfigTunnel() {
      this.currentVlanConfig.tunnel = '';
      // this.tunnelInMultiselector = {};
      this.$set(this, 'tunnelInMultiselector', '');
    },
    // customLabelForWlanMiltiselect(wlanObj) {
    //   return this.createWlanMultiselectCaptionByWlan(wlanObj)
    // },
    customLabelForWlanMiltiselect(wlanId) {
      const wlanObject = this.wlansList.find(x => x.id === wlanId);
      if (!wlanObject) {
        return wlanId;
      }
      if (wlanObject.hasOwnProperty('default_tunnel') && wlanObject.default_tunnel !== '') {
        return `${wlanObject.ssid} (${this.$t('aps.Tunnel').toLowerCase()} ${wlanObject.default_tunnel})`
      }
      return wlanObject.ssid;
    },
    labelForSelectedInWlanMiltiselect() {
      for (const wlan of this.wlansList) {
        if (wlan.id === this.currentVlanConfig.fake_wlan) {
          return this.createWlanMultiselectCaptionByWlan(wlan);
        }
      }
      return this.currentVlanConfig.fake_wlan;
    },
    createWlanMultiselectCaptionByWlan(wlanObj) {
      if (wlanObj.hasOwnProperty('default_tunnel') && wlanObj.default_tunnel !== '') {
        return `${wlanObj.ssid} (${this.$t('aps.Tunnel').toLowerCase()} ${wlanObj.default_tunnel})`
      }
      return wlanObj.ssid
    },
    handleWlanMiltiselectInput(value) {
      // console.log(value)
      this.currentVlanConfig.fake_wlan = value.id;
    },
    checkAreSelectedAndWLANtunnelsNotMatch(vlanConfig) {
      // возвращаем true если туннель и туннель выбраной wlan (если он есть) не совпадают
      try {
        const selectedTunnel = vlanConfig.tunnel;
        const selectedWlanId = vlanConfig.fake_wlan;
        let selectedWlanTunnel = '';

        for (const wlan of this.wlansList) {
          if (wlan.id === selectedWlanId) {
            if (Object.prototype.hasOwnProperty.call(wlan, 'default_tunnel')) {
              selectedWlanTunnel = wlan.default_tunnel;
            }
            break;
          }
        }
        // console.log('selectedWlanTunnel', selectedWlanTunnel)
        // console.log('selectedTunnel', selectedTunnel)
        if (selectedWlanTunnel !== '' && selectedWlanTunnel !== selectedTunnel) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    isSystemVlan(id, currentVlan) {
      return this.systemVlans[id] && this.systemVlans[id].includes(currentVlan);
    },
    isNativeVlanOnSinglePort(id, currentVlan) {
      return this.singlePorts[id] && currentVlan === 0;
    },
    isNativeVlanOnWanPort(id, currentVlan, ports) {
      return this.wanIndex[id] && ports.includes(this.wanIndex[id].toString()) && currentVlan === 0;
    },
    addWiredConfig(wiredInterface) {
      if (this.isDisable) {
        return;
      }
      const newVLAN = {};
      Vue.set(newVLAN, 'vlan', '');
      Vue.set(newVLAN, 'ports', []);
      Vue.set(newVLAN, 'tunnel', '');
      Vue.set(newVLAN, 'fake_wlan', '');
      Vue.set(newVLAN, 'acct', false);
      Vue.set(newVLAN, 'guest_control', {});
      Vue.set(newVLAN.guest_control, 'captive_redirect', '');
      Vue.set(newVLAN.guest_control, 'mac_radius_auth_servers', []);
      Vue.set(newVLAN, 'speed_download', {});
      if (!this.isRootLocationUser) {
        // включаем по умолчанию NAT если пользователь не из root локации.
        // поскольку для сохранения необходимы или настройки NAT или настроки тунеля
        // а редактирование тунелей пользователям из не root локаций недоступно
        Vue.set(newVLAN, 'nat', true);
      }

      this.showWiredConfig(wiredInterface, newVLAN);
    },
    removeVLAN(vlans, index) {
      if (this.isDisable) {
        return;
      }
      if (vlans[index]) {
        vlans.splice(index, 1);
      }
    },
    changePorts(ports, index) {
      if (this.isDisable) {
        return;
      }
      const vlanPorts = ports;
      const portInedx = index;

      if (vlanPorts.includes(portInedx)) {
        vlanPorts.splice(vlanPorts.indexOf(portInedx), 1);
      } else {
        vlanPorts.push(portInedx);
      }
    },
    checkSystemVlans(wiredInterfaces) {
      if (!wiredInterfaces) {
        this.systemVlans = {};
        return;
      }
      this.systemVlans = {};
      for (const id in wiredInterfaces) {
        this.systemVlans[id] = [];
        wiredInterfaces[id].vlans.forEach((vlan) => {
          if (vlan.system) {
            this.systemVlans[id].push(vlan.vid);
          }
        });
      }
    },
    checkSinglePort(wiredInterfaces) {
      if (!wiredInterfaces) {
        this.singlePorts = {};
        return;
      }

      this.singlePorts = {};
      for (const id in wiredInterfaces) {
        this.singlePorts[id] = null;

        const socketPorts = wiredInterfaces[id].ports.filter((port) => port.type === 'socket');
        this.singlePorts[id] = socketPorts.length === 1;
      }
    },
    checkMinVlan(ports, unusableWan, id) {
      let isWanIncluded = false;
      if (ports && unusableWan) {
        isWanIncluded = ports.includes(unusableWan.toString());
      }

      let minVlan = 0;
      // if (this.singlePorts[id] || isWanIncluded) {
      //   return (minVlan = 1);
      // }
      if (isWanIncluded) {
        return (minVlan = 1);
      }
      if (this.isCpeModelEqualRTBR24WFN2Ev61AndLan2Checked) {
        return (minVlan = 1);
      }
      return minVlan;
    },
    getWanPort(wiredInterfaces) {
      this.wanIndex = {};
      for (const id in wiredInterfaces) {
        this.wanIndex[id] = null;
        for (const port of wiredInterfaces[id].ports) {
          if (port.role === 'wan') {
            this.wanIndex[id] = port.num;
          }
        }
      }
    },
    resetWiredConfig() {
      if (this.objWiredState) {
        this.checkSystemVlans(this.objWiredState);
      }
      this.checkSinglePort(this.cpeModelData.caps.wired);
      this.getWanPort(this.cpeModelData.caps.wired);
    },
    // showWiredConfig(wiredInterface, vlanConfig, index) {
    //   this.resetConfig();
    //   this.currentConfigIndex = index;
    //   this.currentVlanConfig = JSON.parse(JSON.stringify(vlanConfig));
    //   // console.log(this.currentVlanConfig)
    //   if (!this.currentVlanConfig.hasOwnProperty('nat_network')) {
    //     this.$set(this.currentVlanConfig, 'nat_network', { ipaddr: '', netmask: '' });
    //   }
    //   this.currentWiredRADIUS = this.currentVlanConfig.guest_control.mac_radius_auth_servers.map((radiusId) => commonService.radiusObjbyId(radiusId));
    //   this.currentWiredInterface = wiredInterface;
    //   this.wiredConfigModal = true;
    // },

    showWiredConfig(wiredInterface, vlanConfig, index) {
      this.resetConfig();
      this.currentConfigIndex = index;
      this.currentVlanConfig = JSON.parse(JSON.stringify(vlanConfig));
      // console.log(this.currentVlanConfig)
      if (!this.currentVlanConfig.hasOwnProperty('nat_network')) {
        this.$set(this.currentVlanConfig, 'nat_network', { ipaddr: '', netmask: '' });
      }
      this.currentWiredRADIUS = this.currentVlanConfig.guest_control.mac_radius_auth_servers.map((radiusId) => commonService.radiusObjbyId(radiusId));
      this.currentWiredInterface = wiredInterface;
      this.wiredConfigModal = true;


      const currentTunnel = this.currentVlanConfig.tunnel;
      // this.tunnelInMultiselector = {};
      this.$set(this, 'tunnelInMultiselector', '');
      // console.log('currentDefaultTunnel', currentDefaultTunnel)
      if (currentTunnel && currentTunnel !== '') {
        const currentTunnelAsObject = this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[currentTunnel];
        if (currentTunnelAsObject) {
          // console.log('here')
          this.tunnelInMultiselector = JSON.parse(JSON.stringify(currentTunnelAsObject));
        } else {
          // console.log('here2')
          this.tunnelInMultiselector = {
            name: this.currentVlanConfig.tunnel,
            nameWithType: this.currentVlanConfig.tunnel
          }
        }
      }
    },

    resetConfig() {
      this.isNoOutput = false;
      this.currentConfigIndex = false;
      this.currentVlanConfig = false;
      this.currentWiredInterface = false;
    },
    cancelWiredConfigModal() {
      this.wiredConfigModal = false;
      this.resetConfig();
    },
    saveWiredConfig() {
      if (!this.currentVlanConfig.tunnel && !this.currentVlanConfig.nat) {
        this.isNoOutput = true;
        return;
      }

      this.isNoOutput = false;

      this.$validator
        .validateAll({
          vlan: this.currentVlanConfig.vlan,
          hosts_interface: this.currentVlanConfig.tunnel
        })
        .then((result) => {
          if (result) {
            for (const wiredConfig of this.objWiredConfig) {
              if (wiredConfig.id === this.currentWiredInterface.id) {
                this.currentVlanConfig.guest_control.mac_radius_auth_servers = this.currentWiredRADIUS.map(
                  (radius) => radius.id
                );
                if (this.currentConfigIndex || this.currentConfigIndex === 0) {
                  Vue.set(wiredConfig.vlans, this.currentConfigIndex, this.currentVlanConfig);
                } else {
                  wiredConfig.vlans.push(this.currentVlanConfig);
                }
                this.wiredConfigModal = false;
                return;
              }
            }
          }
        });
    },
    handleNATUncheck() {
      if (!this.currentVlanConfig.nat) {
        this.currentVlanConfig.nat_access = false;
        this.currentVlanConfig.tunnel = '';
        // this.tunnelInMultiselector = {};
        this.$set(this, 'tunnelInMultiselector', '');
        this.currentVlanConfig.speed_download = {};
        // this.currentVlanConfig.nat_network = {
        //   ipaddr: '',
        //   netmask: ''
        // };
        // this.$set(this.currentVlanConfig, 'nat_network', { ipaddr: '', netmask: '' });
      }
    }
  },
  created() {
    this.resetWiredConfig();
    radiusService.getRadius(this);
  }
};
</script>

<style lang="scss" scoped>
.isCpeModelEqualRTBR24WFN2Ev61-disbale-port-checking {
  cursor: not-allowed !important;
}
.isCpeModelEqualRTBR24WFN2Ev61-info-msg {
  position: relative;
  top: 0.3em;
  left: 0.2em;
}
.wired-table {
  width: 100%;
  & tr td {
    position: relative;
    padding: 0.15rem 0.15rem;
    padding-bottom: 15px;
  }
}

.wired-table .custom-control {
  margin: 0;
}
.wired-table th {
  padding: 1px;
}
.wired-table .wired-add-vlan {
  text-align: left;
  padding-top: 10px;
}
.wired-add-vlan-link {
  border-bottom: 1px dashed #4dbd74;
  cursor: pointer;
}
.wired-add-vlan-link:hover {
  border-bottom: 1px solid #4dbd74;
}
.wired-table .custom-control-indicator {
  width: 22px;
  height: 22px;
}
.wired-table .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #4dbd74;
}

.wired-table .custom-control-input:checked ~ .custom-control-indicator.custom-control-indicator--danger {
  background-color: #ff3860;
}

.wired-table .form-control.input.is-danger {
  border-color: #ff3860;
}
.wired-table .form-control {
  border: 1px solid rgba(0, 0, 0, 0.15);
  /*max-width: 60px;*/
}
.wired-table select.form-control {
  padding-right: 23px;
  height: 1.7125rem !important;
}

.interface-block-title {
  &__wired {
    display: flex;
    justify-content: space-between;
  }
}

.vlan-not-supported {
  position: absolute;
  bottom: -1px;
  font-size: 80%;
  white-space: nowrap;
}

.setting-block-header {
  align-items: center;
}

.wired-actions {
  margin-top: 0.7rem;
}
.wired-action {
  padding: 1rem 15px 1rem 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #4dbd74;
}
</style>
<style>
.disable-turned-on-switcher-in-wired-config .switch-primary > .switch-input:checked ~ .switch-label {
  background-color: #94a0b2 !important;
}
</style>
