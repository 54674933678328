import Vue from 'vue';
import VueI18n from 'vue-i18n';
import localeEN from '../locales/i18-locale-en';
import localeRU from '../locales/i18-locale-ru';

const lsLOCALE = localStorage.getItem('NMS_LOCALE');
window.LOCALE = process.env.LOCALE;

if (!lsLOCALE && !process.env.LOCALE) {
  localStorage.setItem('NMS_LOCALE', 'ru');
  window.LOCALE = 'ru';
} else if (lsLOCALE && lsLOCALE !== process.env.LOCALE) {
  window.LOCALE = lsLOCALE;
} else if (!lsLOCALE && process.env.LOCALE) {
  window.LOCALE = process.env.LOCALE.toLowerCase();
  localStorage.setItem('NMS_LOCALE', window.LOCALE);
}

Vue.use(VueI18n);
// Setting Locales
const messages = {
  en: localeEN,
  ru: localeRU
};
const i18n = new VueI18n({
  locale: window.LOCALE ? window.LOCALE.toLowerCase() : 'en',
  messages
});

export const eventsChart = {
  chart: {
    type: 'column',
    height: '180px'
  },
  title: {
    text: ''
  },
  legend: {
    labelFormatter() {
      let total = 0;
      for (let i = this.yData.length; i--;) {
        total += this.yData[i];
      }
      return `${this.name}: ${total}`;
    },
    enabled: true,
    align: 'center'
  },
  xAxis: {
    type: 'datetime'
    // labels: {
    //   format: '{value:%H:%M}'
    // },
    // tickInterval: 1000 * 60 * 60 // one hour
  },
  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: i18n.t('dashboard.Events')
    },
    labels: {
      enabled: true
    },
    endOnTick: false
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:2px"><b>{point.y:.0f}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true
    // xDateFormat: '%H:%M:%S %d.%m.%Y'
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      pointWidth: 18,
      pointPadding: 0,
      borderWidth: 0.5
    },
    series: {
      minPointLength: 4
    }
  },
  series: [
    {
      name: i18n.t('dashboard.ERROR'),
      lineWidth: 5,
      data: [],
      color: '#f86c6b'
    },
    {
      name: i18n.t('dashboard.WARNING'),
      data: [],
      color: '#f8cb00'
    },
    {
      name: i18n.t('dashboard.INFO'),
      data: [],
      color: '#63c2de'
    }
  ]
};
