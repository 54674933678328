/* eslint-disable import/prefer-default-export */
export const themeControl = async () => {
  if (window.CUSTOM_BUILD === 'BEELINE-WIMARK') {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      await import('../public/static/scss/style-beeline-dark.scss');
    } else {
      await import('../public/static/scss/style-beeline.scss');
    }
  }
  if (window.CUSTOM_BUILD === 'MTS-WIMARK') {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      await import('../public/static/scss/style-mts-dark.scss');
    } else {
      await import('../public/static/scss/style-mts.scss');
    }
  }
  if (window.CUSTOM_BUILD === '' || window.CUSTOM_BUILD === 'WIMARK' || window.CUSTOM_BUILD === 'QTECH-WIMARK') {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      await import('../public/static/scss/style-dark.scss');
    } else {
      await import('../public/static/scss/style.scss');
    }
  }
};
