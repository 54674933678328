<template>
  <dropdown class="dropdown-extract" type="info" :disabled="disabled || manualDisable">
    <template v-if="!smallDownloadButton" slot="button">
      <i class="fa fa-download mr-h"></i>
      {{ text }}
      <span class="caret"></span>
    </template>
    <template v-else slot="button">
      <i
        @mouseenter="hasHover = true"
        @focus="hasHover = true"
        @mouseleave="hasHover = false"
        @blur="hasHover = false"
        class="fa fa-download custom-styles-for-download-button"
      ></i>
      <span class="caret"></span>
      <p v-if="hasHover && showTooltipWhenSmallDownloadButton" class="save-for-small-download-button__tooltip">
        {{ $t('general.downloadFiles') }}
      </p>
    </template>
    <ul slot="dropdown-menu" class="dropdown-menu dropdown-extract__menu">
      <!-- // FIXME: delete this if no bugs found -->
      <li v-if="type === 'data' && downloadsFormatsForShow.oldCsv === true">
        <a href="#dropdown" class="dropdown-extract__link">
          <download-csv :data="data" :labels="labelsCSV" :name="`${getFilename()}.csv`">
            {{ $t('general.downloadCsv') }}
          </download-csv>
        </a>
      </li>
      <!-- // FIXME: delete this if no bugs found -->

      <li v-if="type === 'data' && downloadsFormatsForShow.newCsv === true">
        <a href="#dropdown" class="dropdown-extract__link">
<!--          <download-excel :data="data" :fields="createFieldsExcel" type="csv" :name="`${getFilename()}.csv`">-->
<!--            {{ $t('general.downloadCsv') }}-->
<!--          </download-excel>-->
          <download-excel
            :data="cutDotsForColumnNamesForNewCSVAndOldXLSFiles ? dataWithoutDotsInKeys : data"
            :fields="createFieldsExcel" type="csv" :name="`${getFilename()}.csv`">
            {{ $t('general.downloadCsv') }}
          </download-excel>
        </a>
      </li>
      <li v-if="type === 'data' && downloadsFormatsForShow.oldXls === true">
        <a href="#dropdown" class="dropdown-extract__link">
<!--          <download-excel :data="data" :fields="createFieldsExcel" type="xls" :name="`${getFilename()}.xls`">-->
<!--            {{ $t('general.downloadXls') }}-->
<!--          </download-excel>-->
          <download-excel
            :data="cutDotsForColumnNamesForNewCSVAndOldXLSFiles ? dataWithoutDotsInKeys : data"
            :fields="createFieldsExcel" type="xls" :name="`${getFilename()}.xls`">
            {{ $t('general.downloadXls') }}
          </download-excel>
        </a>
      </li>
      <li
        v-if="
          type === 'data' && dataForExcel && Object.keys(dataForExcel).length && downloadsFormatsForShow.newXls === true
        "
      >
        <a href="#dropdown" class="dropdown-extract__link" @click="exportExcel('xlsx')">
          {{ $t('general.downloadXlsx') }}
        </a>
      </li>
      <!-- <li>
        <a href="#dropdown" class="dropdown-extract__link" @click="exportExcel('csv')">
          {{ $t('general.downloadCsv') }}
        </a>
      </li> -->
      <li v-if="type == 'data' && downloadsFormatsForShow.pdf === true">
        <a href="#dropdown" class="dropdown-extract__link" @click="getPdf">
          {{ $t('general.downloadPdf') }}
        </a>
      </li>
      <li v-if="type === 'data' && consolidated.enabled">
        <a href="#dropdown" class="dropdown-extract__link" @click="getConsolidated">
          {{ $t('general.downloadAllPng') }}
        </a>
      </li>
      <li v-if="type === 'vouchers'">
        <a href="#dropdown" class="dropdown-extract__link" @click="exportVouchers">
          {{ $t('general.downloadXls') }}
        </a>
      </li>
    </ul>
  </dropdown>
</template>

<script>
import XLSX from 'xlsx';
import { dropdown } from 'vue-strap';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import helpers from '../helpers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'DownloadExtractV2',
  props: {
    data: {
      type: [Object, Array],
      required: false,
      default() {
        return [];
      }
    },
    dataForExcel: { type: [Object, Boolean], required: false, default: false },
    type: { type: String, required: false, default: 'data' },
    excelFileName: { type: String, required: false, default: 'export' },
    excelFileType: { type: String, required: false, default: 'xlsx' },
    filename: { type: String, required: true },
    consolidated: {
      type: Object,
      default: () => ({
        enabled: false,
        selector: 'body',
        customFilename: ''
      })
    },
    text: {
      type: [String, Boolean],
      default() {
        return this.$t('general.download');
      }
    },
    // Принудительно задаем самый большой размер листа.
    // Нужно например в компоненте Users, в котором список юхеров самого NMS.
    // Там в pdf запихивается хэш пароля, он - длинная строка и на меньших листах он уползает за поля
    // Если же его разорвать, например  с помощью \n, то все разорвется и в CSV файлах
    forceEnableA2PdfPageSize: { type: Boolean, default: false },
    smallDownloadButton: { type: Boolean, default: false },
    showTooltipWhenSmallDownloadButton: { type: Boolean, default: true },
    pdfTableColumnsWidths: { default: undefined }, // массив, позволяющий задачть ширну колонок в pdf,
    pdfTableColumnsShowHeadings: { type: Boolean, default: true }, // позволяет выключить названия столбцов в pdf
    newXlsAutoColumnsWidth: { type: Boolean, default: false }, // при включении автоматом задает ширину колонок, подсчитывая максимальное  колиество символов в каждой из них
    pdfHeaderForPdf: { type: String, default: '' },
    newXlsHeaderForExcel: { type: String, default: '' },
    cutDotsForColumnNamesForNewCSVAndOldXLSFiles: { type: Boolean, default: false }, // при включении из названий колонок в newcsv и oldXLS вырезаются символы '.', которые иногда аффектят вывод
    downloadsFormatsForShow: {
      type: Object,
      default() {
        return {
          oldCsv: true,
          newCsv: true,
          oldXls: true,
          newXls: true,
          pdf: true
        };
      }
    },
    // для принудительного блокирования копки из вне с помощью пропса
    manualDisable: { type: Boolean, default: false }
  },
  data() {
    return {
      hasHover: false,
      disabled: false,
      labelsCSV: {
        'data-mac': 'MAC',
        'data-auth_type': 'Authorization type',
        'data-authen_type': 'Authentication type',
        'data-radio_id': 'Radio ID',
        'data-session_id': 'Session ID',
        'data-session_timeout': 'Session timeout',
        'data-useragent': 'User Agent',
        'data-username': 'User account',
        'data-cpe_id': 'CPE ID',
        'data-wlan_id': 'WLAN ID',
        'data-build': 'Build',
        description: 'Description',
        subject_id: 'Subject ID',
        type: 'Type',
        timestamp: 'Timestamp',
        level: 'Level',
        id: 'ID'
      },
      excelData: false
    };
  },
  components: {
    dropdown
  },
  computed: {
    // NOTE: use these fields for events OR use ALL fields
    // eslint-disable-next-line vue/return-in-computed-property
    createFieldsExcel() {
      if (this.data.length) {
        const keys = Object.keys(this.data[0]);

        if (keys.includes('level')) {
          return {
            Date: {
              field: 'timestamp',
              callback: (value) => new Date(value * 1000).toLocaleString('ru')
            },
            'User account': 'data-username',
            Level: 'level',
            MAC: 'data-mac',
            'Authorization type': 'data-auth_type',
            'Authentication type': 'data-authen_type',
            'Radio ID': 'data-radio_id',
            'Session ID': 'data-session_id',
            'Session timeout': 'data-session_timeout',
            'CPE ID': 'data-cpe_id',
            'WLAN ID': 'data-wlan_id',
            Build: 'data-build',
            Description: 'description',
            'Subject ID': 'subject_id',
            Type: 'type',
            'User Agent': 'data-useragent'
          };
        }
      }
    },
    dataWithoutDotsInKeys() {
      // вырезаем из названий полей символ '.',
      // он аффектит вывод как nrwCSV или oldXLS - в колонке c таким символом просто не выводится никакой инфы
      try {
        const result = [];
        for (const itemNumber in this.data) {
          // console.log(itemNumber)
          const itemWithKeysWithoutDots = {};
          // for (const keyName in this.data[itemNumber]) {
          //   if (Object.prototype.hasOwnProperty.call(this.data[itemNumber], keyName) && keyName.includes('.')) {
          //     const keyNameWithoutDots = keyName.replace('.', ' ');
          //     itemWithKeysWithoutDots[keyNameWithoutDots] = this.data[itemNumber][keyName];
          //   } else if (Object.prototype.hasOwnProperty.call(this.data[itemNumber], keyName)) {
          //     itemWithKeysWithoutDots[keyName] = this.data[itemNumber][keyName];
          //   }
          // }

          Object.keys(this.data[itemNumber]).forEach((keyName) => {
            if (keyName.includes('.')) {
              const keyNameWithoutDots = keyName.replace('.', ' ');
              itemWithKeysWithoutDots[keyNameWithoutDots] = this.data[itemNumber][keyName];
            } else {
              itemWithKeysWithoutDots[keyName] = this.data[itemNumber][keyName];
            }
          });

          result.push(itemWithKeysWithoutDots);
        }
        return result;
      } catch (e) {
        console.log(e);
        return this.data;
      }
    }
  },
  watch: {
    data() {
      if (!this.data.length || !this.dataForExcel?.length) this.disabled = true;
      if (this.data.length || this.dataForExcel?.length) this.disabled = false;
    }
  },
  methods: {
    getTimestamp: helpers.getTimestamp,
    getFilename() {
      // return `${this.filename}_${helpers.getTimestamp()}`;
      return `${this.filename}`;
    },
    getPdf() {
      if (!this.data.length) console.warn('No data');
      if (this.data.length) {
        const documentDefinition = {
          pageOrientation: 'landscape',
          pageSize: 'A4',
          content: [
            {
              table: {
                body: []
              }
            }
          ]
        };
        const tableBody = documentDefinition.content[0].table.body;
        const headings = Object.keys(this.data[0]);

        // Pick page size based on number of columns
        const MAX_HEADINGS = 9;
        if (headings.length <= MAX_HEADINGS) {
          documentDefinition.pageSize = 'A4';
        } else if (headings.length <= MAX_HEADINGS * 2) {
          documentDefinition.pageSize = 'A3';
        } else {
          documentDefinition.pageSize = 'A2';
        }
        if (this.forceEnableA2PdfPageSize) {
          // console.log('a2')
          documentDefinition.pageSize = 'A2';
        }

        // если передан пропс с массивом с шириной колонок
        if (
          this.pdfTableColumnsWidths !== undefined &&
          Array.isArray(this.pdfTableColumnsWidths) &&
          this.pdfTableColumnsWidths.length === headings.length
        ) {
          // console.log(documentDefinition)
          // устанавливаем ширину для колонок pdf'a
          // (https://pdfmake.github.io/docs/0.1/document-definition-object/tables/)
          documentDefinition.content[0].table.widths = JSON.parse(JSON.stringify(this.pdfTableColumnsWidths));
        }

        // Populate table with data
        // tableBody.push(headings);

        if (this.pdfTableColumnsShowHeadings) {
          tableBody.push(headings);
        }
        this.data.forEach((row) => {
          tableBody.push(Object.values(row));
        });

        if (this.pdfHeaderForPdf && this.pdfHeaderForPdf !== '') {
          documentDefinition.content.unshift({
            text: this.pdfHeaderForPdf,
            style: 'header',
            tocItem: true
          });
          documentDefinition.styles = {
            header: {
              fontSize: 16,
              bold: true,
              lineHeight: 2
            },
          }

        }

        pdfMake.createPdf(documentDefinition).download(`${this.getFilename()}.pdf`);
      }
    },
    getConsolidated() {
      html2canvas(document.querySelector(this.consolidated.selector), {
        scale: 2
      }).then((canvas) => {
        const filename = this.consolidated.customFilename ? this.consolidated.customFilename : this.filename;
        canvas.toBlob((blob) => {
          const link = document.createElement('a');
          // link.download = `${filename}_${this.getTimestamp()}.png`;
          link.download = this.consolidated.customFilename ? `${filename}.png`: `${filename}_${this.getTimestamp()}.png`;
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        }, 'image/png');
      });
    },
    exportExcel(fileType) {
      // On Click Excel download button
      // console.info(this.dataForExcel);

      // A workbook is the name given to an Excel file
      const wb = XLSX.utils.book_new(); // make Workbook of Excel

      const tabNames = Object.keys(this.dataForExcel);

      tabNames.forEach((tab) => {
        let workPage;
        if (this.newXlsHeaderForExcel && this.newXlsHeaderForExcel !== '') {
          const headerRow = XLSX.utils.aoa_to_sheet([[`${this.newXlsHeaderForExcel}`]]);
          workPage = XLSX.utils.sheet_add_aoa(headerRow, this.dataForExcel[tab], { origin: "A3" });
        } else {
          workPage = XLSX.utils.aoa_to_sheet(this.dataForExcel[tab]);
        }
        // console.log(workPage)
        if (this.newXlsAutoColumnsWidth) {
          if (this.dataForExcel[tab].length > 0) {
            const numberOfColumns = this.dataForExcel[tab][0].length;
            const maxStringSizeInByColumns = new Array(numberOfColumns).fill(0);
            for (const row of this.dataForExcel[tab]) {
              const rowLen = row.length;
              for (const index in row) {
                const value = row[index];
                let valueLen = 0;
                if (value !== undefined) {
                   valueLen = value.length;
                }
                if (maxStringSizeInByColumns[index] < valueLen) {
                  maxStringSizeInByColumns[index] = valueLen;
                }
                // if (maxStringSizeInByColumns[index] < valueLen) {
                //   maxStringSizeInByColumns[index] = valueLen;
                //   const rowLenTmp = rowLen - 1
                //   if (index != rowLenTmp && maxStringSizeInByColumns[index] > 20) {
                //     maxStringSizeInByColumns[index] = 20
                //   }
                // }
              }
            }
            workPage['!cols'] = [];
            for (const columnLen of maxStringSizeInByColumns) {
              workPage['!cols'].push({ width: columnLen + 1 });
            }
          }
        }
        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, workPage, tab); // sheetAName is name of Worksheet
      });

      // export Excel file
      XLSX.writeFile(wb, `${this.excelFileName}.${fileType}`, { bookType: fileType }); // name of the file is 'book.xlsx'
    },
    exportVouchers() {
      // On Click Excel download button
      const fileType = 'xlsx';

      const size = 2; // размер подмассива
      // console.log(this.data, 'data');
      const subarray = []; // массив в который будет выведен результат.
      for (let i = 0; i < Math.ceil(this.data.length / size); i += 1) {
        subarray[i] = this.data.slice(i * size, i * size + size);
      }

      // A workbook is the name given to an Excel file
      const wb = XLSX.utils.book_new(); // make Workbook of Excel

      const workPage = XLSX.utils.aoa_to_sheet(subarray);
      workPage['!cols'] = Array(size).fill({ wpx: 250 });
      workPage['!rows'] = Array(Math.ceil(this.data.length / size)).fill({ hpx: 50 });
      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, workPage); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, `${this.filename}.${fileType}`, { bookType: fileType }); // name of the file is 'book.xlsx'
    }
  }
};
</script>

<style lang="scss">
/* fix global style collisions */
/* .dropdown-extract button:not(.dropdown-extract__button) {
  margin: 0;
  border: 0;
  padding: 0;
} */

.dropdown-extract__button::after {
  display: inline-block !important;
}

.dropdown-extract__button {
  margin: 0 !important;
}

.dropdown-extract__link.dropdown-extract__link {
  padding: 8px 20px;
}

.dropdown-extract__menu {
  min-width: unset;
  left: 50%;
  transform: translateX(-50%);
}
.save-for-small-download-button__tooltip {
  position: absolute;
  top: 30px;
  left: 50%;

  margin: 0;
  padding: 5px;

  color: #444d58;

  background-color: white;
  text-align: center;
  border: 1px solid #94a0b2;

  transform: translateX(-50%);
  font-size: 0.8rem;
  font-family: Arial, sans-serif !important;
}
</style>
<style lang="scss" scoped>
.custom-styles-for-download-button {
  font-size: 0.7em;
}
</style>
