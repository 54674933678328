<template>
  <div class="d-flex flex-wrap">
    <ConfigExport></ConfigExport>
    <ConfigImport></ConfigImport>
  </div>
</template>

<script>
import ConfigExport from './ConfigExport';
import ConfigImport from './ConfigImport';

export default {
  name: 'ConfigExportImport',
  components: { ConfigExport, ConfigImport },
  data() {
    return {};
  },
  created() {
    if (this.$store.state.userData.role !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }
  },
  computed: {
    isAdmin() {
      try {
        return this.$store.state.userData.role === 'admin';
      } catch (e) {
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
