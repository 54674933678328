<template>
  <div class="location-selector-in-header-wrapper">
    <SelectComponentV3ForUseInLocationSelectors
      show-delete-button
      v-if="showLocationFilter"
      v-model="selectedLocationObject"
      track-by="name"
      option-id-name="id"
      :no-options-found="$t('general.noResultForSearch')"
      :async-function="requestLocations.bind(this)"
      :placeholder="$t('general.selectLocation')"
      @select="selectLocation"
    >
      <template #dropdown-start>
        <div v-if="showpreviouslySelectedLocationsObjectsBlock">
          <span class="ml-h text-muted">{{ $t('general.previouslySelected') }}</span>
          <span
            :key="location.id"
            @click.stop.prevent="selectInpreviouslySelectedLocationsObjects(location)"
            v-for="location in previouslySelectedLocationsObjects"
            class="select__option select__option_clickable"
          >
            {{ location.name }}
          </span>
          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>
          <span class="ml-h text-muted">{{ $t('general.locations') }}</span>
        </div>
        <div class="ml-h mt-h" :class="{ 'mt-1': !showpreviouslySelectedLocationsObjectsBlock }">
          <Switch-component
            @input="switchWithChildsHandle"
            :label="$t('general.withChild')"
            v-model="withChilds"
            class=""
          />
        </div>
      </template>
    </SelectComponentV3ForUseInLocationSelectors>
  </div>
</template>

<script>
// import SelectComponentV2 from './Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from './Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import locationService from '../services/locationService';
import SwitchComponent from './Universal/Switch-component.vue';

export default {
  name: 'LocationSelectorInHeader',
  components: {
    // SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors,
    SwitchComponent
  },
  data() {
    return {
      showLocationFilter: true,
      withChilds: true,
      selectedLocationID: '',
      selectedLocationAndChildsLocationsIDs: [],
      selectedLocationObject: [],
      previouslySelectedLocationsObjects: [],
      userBaseLocation: '',
    };
  },
  created() {
    // this.$store.commit('loadInHeaderLocationSelectBlockDataFromLocalStorage')
    this.initHeaderLocationSelectBlockData();
  },
  methods: {
    setInHeaderLocationSelectBlockDataChangedTimestamp(){
      this.$store.commit('setInHeaderLocationSelectBlockDataChangedTimestamp')
    },
    initHeaderLocationSelectBlockData() {
      this.withChilds = this.$store.state.inHeaderLocationSelectBlockData.withChilds;
      this.selectedLocationID = this.$store.state.inHeaderLocationSelectBlockData.selectedLocationID;
      this.selectedLocationAndChildsLocationsIDs =
        this.$store.state.inHeaderLocationSelectBlockData.selectedLocationAndChildsLocationsIDs;
      this.selectedLocationObject =
        this.$store.state.inHeaderLocationSelectBlockData.selectedLocationObject;
      // this.previouslySelectedLocationsObjects = this.$store.state.inHeaderLocationSelectBlockData.previouslySelectedLocationsObjects;
      this.previouslySelectedLocationsObjects = this.$store.state.inHeaderPreviouslySelectedLocationsObjects;
      this.userBaseLocation = this.userBaseLocationFromStore
      this.toggleShowLocationFilter()
    },
    headerLocationSelectBlockDataSave() {
      this.$store.commit(
        'saveInHeaderLocationSelectBlockDataFToStoreAndLocalStorage',
        this.headerLocationSelectBlockDataForSaving
      );
      // this.$store.commit('setDefaultLocationForRequests', this.headerLocationSelectBlockDataForSaving.selectedLocationID)
      // this.$store.commit('setDefaultWithChildsForRequests', this.headerLocationSelectBlockDataForSaving.withChilds)
      this.$store.commit('saveInHeaderPreviouslySelectedLocationsObjects', this.headerLocationSelectBlockDataForSaving.previouslySelectedLocationsObjects)

      // а еще сохраняем для отображения на дашборде выбраной локации
      this.$store.commit('saveDashboardBlocksLocationSettingsForGetStatisticToStoreAndLocalStorage', {
        loctionId: this.headerLocationSelectBlockDataForSaving.selectedLocationID,
        with_child: this.headerLocationSelectBlockDataForSaving.withChilds
      })

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      return locationService.requestLocationsWithSortForUseInHeaderLocationSelector(searchQuery);
    },
    selectLocation(location) {
      // console.log('selected', location)
      // console.log(location)
      this.userBaseLocation = this.userBaseLocationFromStore;
      if (!location) {
        // const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
        // this.selectedLocationID = userBaseLocation;
        this.selectedLocationID = '';
        this.withChilds = true;
      } else {
        this.selectedLocationID = location.id;
        this.addLocationInpreviouslySelectedLocationsObjects(location);
      }
      this.refreshSelectedLocationAndChildsLocationsIDs();
      this.setInHeaderLocationSelectBlockDataChangedTimestamp();
    },
    addLocationInpreviouslySelectedLocationsObjects(location) {
      const existingpreviouslySelectedLocationsObjects = JSON.parse(JSON.stringify(this.previouslySelectedLocationsObjects));
      const isExistingPassedToFuncLocation = existingpreviouslySelectedLocationsObjects.find((existingLocation) => {
        return existingLocation.id === location.id;
      });
      // console.log(isExistingPassedToFuncLocation)
      if (!isExistingPassedToFuncLocation) {
        existingpreviouslySelectedLocationsObjects.unshift(location);
        this.previouslySelectedLocationsObjects = existingpreviouslySelectedLocationsObjects.splice(0, 3);
      }
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      });
    },
    selectInpreviouslySelectedLocationsObjects(location) {
      this.selectLocation(location);
      this.selectedLocationObject = location;
      this.toggleShowLocationFilter();
    },
    switchWithChildsHandle() {
      this.refreshSelectedLocationAndChildsLocationsIDs();
      this.setInHeaderLocationSelectBlockDataChangedTimestamp();
    },
    refreshSelectedLocationAndChildsLocationsIDs() {
      this.selectedLocationAndChildsLocationsIDs =
        this.$store.getters.getParentLocationIdAndChildLocationsIDsConcatenatedArrayByLocationId(
          this.selectedLocationID
        );
    }
  },
  watch: {
    headerLocationSelectBlockDataForSaving: {
      deep: true,
      handler() {
        this.headerLocationSelectBlockDataSave();
      }
    }
  },
  computed: {
    userBaseLocationFromStore() {
      return this.$store.state.userData.base_location;
    },
    headerLocationSelectBlockDataForSaving() {
      return {
        withChilds: this.withChilds,
        selectedLocationID: this.selectedLocationID,
        selectedLocationAndChildsLocationsIDs: this.selectedLocationAndChildsLocationsIDs,
        selectedLocationObject: this.selectedLocationObject,
      };
    },
    showpreviouslySelectedLocationsObjectsBlock() {
      try {
        if (Array.isArray(this.previouslySelectedLocationsObjects) && this.previouslySelectedLocationsObjects.length) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  }
};
</script>

<style scoped>
.location-selector-in-header-wrapper {
}
</style>
<style lang="scss">
.location-selector-in-header-wrapper {
  // min-height: 27px !important;
  // width: 100%;
  width: 180px;
}

.location-selector-in-header-wrapper .select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  height: 22px !important;
  align-items: center;
}

.location-selector-in-header-wrapper .select__input {
  // min-height: 27px !important;
  margin-bottom: 0px !important;
}

.location-selector-in-header-wrapper .arrow {
  min-height: 27px !important;
}

.location-selector-in-header-wrapper .select__control {
  max-width: 100% !important;
}

.location-selector-in-header-wrapper .select__tag {
  margin-bottom: 0px;
  height: 22px !important;
  max-width: 100% !important;
}

.location-selector-in-header-wrapper .select__tag > span {
  max-width: 90px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location-selector-in-header-wrapper .select__input {
  width: 100% !important;
  text-overflow: ellipsis !important;
  padding-right: 0 !important;
}

.location-selector-in-header-wrapper .select__list-container {
  width: 400px;
}
.location-selector-in-header-wrapper .select__control.focused{
    border: 1px solid rgba(130, 130, 130, 0.15) !important;
}
</style>
