<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class=" h-100">
        <div class="card-block">
<!--          <div class="h4 m-0">-->
<!--            {{ $t('general.step1') }}-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"-->
<!--              @click="lastStepButtonClickHandler"-->
<!--            >-->
<!--              {{ $t('wlans.wizardSkipButton') }}-->
<!--              <info :content="$t('wlans.wizardSkipButtonInfo')" />-->
<!--            </button>-->
<!--          </div>-->
          <span class="text-muted step-caption-in-wlan-wizard mb-1">{{ $t('general.basicParameters') }}</span>
          <form action="">
            <div class="form-group mt-1">
              <label for="ssid">SSID</label>
              <input
                type="text"
                name="ssid"
                id="ssid"
                :placeholder="$t('wlans.ssidPlaceholder')"
                class="form-control"
                v-validate="'required|ssid'"
                :class="{ input: true, 'is-danger': errors.has('ssid') }"
                v-model="newWlanData.ssid"
              />
              <span v-show="errors.has('ssid')" class="help is-danger">{{ errors.first('ssid') }}</span>
            </div>
            <div class="form-group">
              <label for="description">{{ $t('general.description') }}</label>
              <input
                type="text"
                id="description"
                name="description"
                :placeholder="$t('wlans.descriptionPlaceholder')"
                class="form-control"
                v-validate="'max:100'"
                :class="{ input: true, 'is-danger': errors.has('description') }"
                v-model="newWlanData.description"
              />
              <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('description') }}</span>
            </div>

            <div class="form-group">
              <label for="location">{{ $t('general.location') }}</label>
              <select v-model="newWlanData.base_location" id="location" name="location" class="form-control">
                <option v-for="location in locationsList" :key="location.id" :value="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('wlans.wizardVisibility') }}</label>
              <div>
                <Switch-component v-model="newWlanData.hidden" :label="$t('wlans.hidden')" id="hidden" />
              </div>
            </div>

            <!--            <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
          </form>
        </div>
<!--        <div class="buttons-block-warpper">-->
<!--          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
<!--            {{ $t('general.next') }}-->
<!--          </button>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-outline-info float-right mr-h"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @click="lastStepButtonClickHandler">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t('wlans.wizardSkipButton') }} <info :content="$t('wlans.wizardSkipButtonInfo')"/>&ndash;&gt;-->
<!--          &lt;!&ndash;          </button>&ndash;&gt;-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import commonService from '../../../services/commonService';
import SwitchComponent from '../../../components/Universal/Switch-component.vue';
import helpers from '../../../helpers';
import Info from '../../../components/Universal/info-icon.vue';

export default {
  name: 'Step1',
  components: {
    SwitchComponent,
    Info
  },
  data() {
    return {
      newWlanData: {
        base_location: '',
        ssid: '',
        description: ''
        // hidden: false
      }
    };
  },
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    }
  },
  methods: {
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    // setNewWlanData(event, toLastStepClicked = false) {
    //   this.$validator
    //     .validateAll({
    //       ssid: this.newWlanData.ssid,
    //       description: this.newWlanData.description
    //     })
    //     .then(
    //       (result) => {
    //         if (result) {
    //           this.$store.commit('setNewWlanData', this.newWlanData);
    //           // this.$parent.nextStep();
    //           // console.log(toLastStepClicked)
    //           if (toLastStepClicked === false) {
    //             this.$parent.nextStep();
    //           } else {
    //             // this.$parent.nextStep(true);
    //             this.$parent.lastStep();
    //           }
    //         }
    //       },
    //       () => {
    //         console.warn('Validation failed');
    //       }
    //     );
    // },
    validateAndSetNewWlanData(currentTab, nextTab) {
      this.setNewWlanData(currentTab,nextTab);
    },
    setNewWlanData(currentTab, nextTab) {
      // console.log('setNewWlanData in step 1')
      this.$validator
        .validateAll({
          ssid: this.newWlanData.ssid,
          description: this.newWlanData.description
        })
        .then(
          (result) => {
            if (result) {
              this.$store.commit('setNewWlanData', this.newWlanData);
              // this.$parent.nextStep();
              // console.log(toLastStepClicked)
              this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
            }else{
               this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
            }
          },
          () => {
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
            console.warn('Validation failed');
          }
        );
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    }
  },
  created() {
    // this.newWlanData = {
    //   base_location: '',
    //   ssid: '',
    //   description: ''
    // };
    this.$store.commit('wlanWizardGoingToLastStepAfterClickSkipButtonEnd');
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
    if (!this.newWlanData.base_location) {
      this.newWlanData.base_location = this.$store.state.userData.base_location;
    }
  }
};
</script>

<style>
.step-caption-in-wlan-wizard {
  font-size: 1.2em !important;
  font-weight: bold;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
