import VueNotifications from 'vue-notifications';
import support from './i18-support-locale-en';

const locale = {
  easyInstallWizard: {
    step1NextButton: 'Create',
    step2NextButton: 'Create',
    step3NextButton: 'Create',
    step4NextButton: 'Add',
    step5NextButton: 'Create',
    badgeSidebarInProgress: 'in progress',
    whereIsLookingForThisSettings: 'Where to look for these settings in the future',
    easyInstallButtonInSidebar: 'Easy Install',
    headerCaption: 'WLAN with portal creating wizard',
    step1Caption: 'Create WLAN',
    step1Header: 'Step 1: Create WLAN',
    step1SuiteRequiredsError: 'The suites field is required',
    step1WhereIsLookingForThisSettings: 'NMS -> WLANS ',
    step2Caption: 'Create Page',
    step2Header: 'Step 2: Create Page',
    step2FieldPageName: 'Page Name',
    step2WhereIsLookingForThisSettings: 'Portal  -> Configuration -> Pages ',
    step3Caption: 'Create Redirect Rule',
    step3Header: 'Step 3 Create Redirect Rule',
    step3WhereIsLookingForThisSettings: 'NMS -> Guest Control ',
    step3PrevStepPageURL: 'URL of the portal page created in the previous step',
    step4Caption: 'Adding a previously created WLAN to the APs',
    step4Header: 'Step 4: Adding a previously created WLAN to the APs',
    step4WhereIsLookingForThisSettings: 'NMS -> APs',
    step4Hint: 'Select a APs to add a previously created WLAN network to them',
    step4CPESPlaceholder: 'Select AP',
    step4NOCPEs: 'No APs',
    step5Caption: 'Create profile',
    step5Header: 'Step 5: Create profile',
    step5WhereIsLookingForThisSettings: 'Portal -> Configuration -> Profiles'
  },
  NewVersionChecker: {
    modalHeader: 'Software version updated',
    modalText:
      'An update is available. Please click "Reload" below for correct further work. You can also accept these updates by refreshing your browser page',
    modalReloadButton: 'Reload'
  },
  paywallForAuthFreemium: {
    contactToUsCaption: 'If you want to enable this section - write to us'
  },
  GuestControl: {
    walledGardenIPTooltip: 'All IPs of Redirect Domain name REQUIRED to be in Walled garden',
    wizard2Caption: 'Confirm new Redirect Rule creation',
    wizard1Caption: 'Fill required basic Redirect Rule parameters',
    // walledGarden: 'Walled garden',
    walledGarden: 'Walled garden',
    deleteRedirectRuleModalTitle: 'Delete Redirect Rule',
    deleteRedirectRuleModalText: 'Confirm deleting',
    addRule: 'Add Redirect Rule',
    allCpes: 'All APs',
    allWlans: 'All WLANs',
    create: 'Create Redirect Rule Wizard',
    domain: 'Domain',
    domainCaption:
      'Redirect domain. Address should point to where Wimark Portal is relative to the client. If this is a public access domain, only IP address needs to be specified in Walled garden. Otherwise domain must also be specified in Walled garden. Domain name should not include protocol.',
    domainName: 'Domain name',
    enterName: 'Enter Redirect Rule name',
    expertHide: 'Hide expert mode',
    expertShow: 'Show expert mode',
    howTo: 'how to fill?',
    ipAddress: 'IP Address',
    macList: 'MAC whitelist',
    // noMasquerade: 'No Masquerade',
    noMasquerade: 'No NAT/PAT',
    redirectIP: 'Captive portal IP',
    redirectURL: 'Redirect URL',
    redirectURLCaption: 'Address where client is transferred after successful authorization',
    redirectUrl: 'Redirect URL template',
    ruleSettings: 'Redirect Rule Settings',
    switchURL: 'Switch URL',
    switchURLCaption:
      'Platform address (IP or domain) where client is switched (authorized). Must contain only hostname (e.g. "www.example.com"). Protocol, port or URL-path needs not to be specified',
    tableHeading: 'Redirect rules for Captive portal',
    targetingLocation: 'Location for targeting',
    urlList: 'URL whitelist',
    wlanCaption:
      'If rule will be applied to a Wimark Appliance (a ethernet interface), an ID must be explicitly provided',
    notificationRedirectUpdatedSuccessfully: 'Redirect Updated Successfully',
    notificationRedirectCreatedSuccessfully: 'Redirect Created Successfully',
    notificationRedirectDeletedSuccessfully: 'Redirect Deleted Successfully',
    notificationRedirectSuccessfull: 'Redirect Successfull'
  },
  RRM: {
    deleteRRMHeader: 'Delete RRM group',
    showAll: 'show all',
    modalCPEs: 'APs',
    modalNOCPEs: 'No APs',
    algorithmGreed: 'Greed',
    algorithmBlind: 'Blind',
    modalGroupTimeoutPlaceHolder: 'Enter timeout',
    modalGroupNamePlaceHolder: 'Enter group name',
    modalTitle: 'Add new group',
    tableRRMSeconds: 'sec',
    tableRRMCPEsTooltip: 'Show APs',
    tableRRMCPEs: 'APs',
    multipleEditSelectGroup: 'Select group',
    multipleEdit: 'Multiple Edit',
    tableLoad: 'Load',
    tableOutOf: 'APs ouf of',
    tableView: 'view',
    tableItemsOf: 'items of',
    tableSelected: 'selected',
    Movetoselectedgroup: 'Move to selected group',
    RemovefromRRMgroups: 'Remove from RRM groups',
    algo: 'Algorithm',
    allGroups: 'No RRM filter',
    group: 'Group',
    groups: 'Groups',
    noGroup: 'No group',
    power: 'Manage Power',
    timeout: 'Timeout (in sec.)',
    notificationRRMGroupCreated: 'RRM group created successfully',
    notificationRRMGroupUpdated: 'RRM group updated successfully',
    notificationRRMGroupDeleted: 'RRM group deleted successfully',
    notificationRRMGroupForceCall: 'RRM has been called successfully for this group',
    rrmForceCallButtonInModal: 'Force RRM call',
    rrmForceCallButtonInModalDisabledHint: 'Group setting must be saved before forcing RRM call',
    forGroup: 'For a group',
    alertAction: 'Configure interfaces.',
    alertText: 'Background scanning on radio interfaces must be enabled.'
  },
  accessControl: {
    accessControl: 'Access Control',
    firewall: 'Firewall',
    wanBlockAccess: 'SSH / Web access block from WAN'
  },
  adsprofile: {
    videoLoadingErrorReload: 'reload',
    videoLoadingError: 'Video loading problem',
    changeRedirectButtonColorAlert: 'Click the Save button for the changes to take effect',
    changePollsColorThemeAlert: 'Click the Save button for the changes to take effect',
    activeAd: 'Active',
    add: 'Add Ad or Poll',
    addVariant: 'Add poll variant',
    addanswer: 'Add answer',
    additionalSettings: 'More settings',
    ads: 'Advertisement',
    adsStats: 'All Ads Views Statistics',
    adsStatsAllAuthorizationTypes: 'All authorization types views',
    adsStatsAllAuthorizationTypesHeader: 'Views and clicks statistics for all authorization types',
    adsStatsAllAuthorizationTypesShort: 'Views',
    adsStatsAllFollowsAuthorizationTypesShort: 'Clicks',
    adsStatsClose: 'to Ads',
    adsStatsFor: 'for',
    adsStatsSepareteAuthorizationTypesHeader: 'View statistics by authorization type',
    adsStatsSepareteFacebook: 'Facebook',
    adsStatsSepareteFree: 'Free',
    adsStatsSepareteInstagram: 'Instagram',
    adsStatsSepareteSponsor: 'Sponsor',
    adsStatsSepareteStaff: 'Staff',
    adsStatsSepareteVk: 'Vk',
    adsStatsSepareteVoucher: 'Voucher',
    adsStatsbyId: 'Views statistics',
    adtype: 'Advertisement or poll Type',
    dayOfWeek: 'Days of the week',
    dayOfWeekCaption: 'Days of the week to display',
    dayOfWeekFriday: 'Friday',
    dayOfWeekMonday: 'Monday',
    dayOfWeekSaturday: 'Saturday',
    dayOfWeekSunday: 'Sunday',
    dayOfWeekThursday: 'Thursday',
    dayOfWeekTuesday: 'Tuesday',
    dayOfWeekWednesday: 'Wednesday',
    doneAd: 'Not Active',
    duration: 'Ad or poll duration (in sec.)',
    edit: 'Edit Ad or Poll',
    enableSex: "Ask user's gender",
    enableBirthDate: "Ask user's birthday",
    enterRedirectButtonColor: 'Enter button color',
    enterRedirectButtonText: 'Enter the text and color of the redirect button',
    enterRedirectButtonTextPlaceholder: 'More details',
    fileLimitation: 'The following image formats are supported: .jpg, .png. Maximum file size is {KB} KB.',
    flash: 'flash',
    hasDesktop: 'Upload desktop/laptop ad or poll version',
    hasPollPictures: 'Add images to poll variants',
    hasTheme: 'Set custom color theme for the poll',
    iframe: 'iframe',
    image: 'image',
    new: 'New Ad or Poll',
    noColor: 'No color',
    numberOfVisits: 'Number of visits',
    numberOfVisitsCaption:
      'You can configure to new customers (equal 0), visited 10 times (equal 10), visited more than 5 times (greater 5) etc.',
    numberOfVisitsHeader: 'Configure by number of visits',
    numberOfVisitsSign: 'Condition',
    numberOfVisitsSignequal: 'Equal',
    numberOfVisitsSigngreater: 'Greater',
    numberOfVisitsSignless: 'Less',
    os: 'Operating systems',
    osCaption: 'List of operating systems for display ad or poll. iOS or Android for example',
    osFromList: 'Select operating system from the list',
    osInput: 'or enter',
    osInstruction:
      'OS targeting. If no operating systems are specified, then the ad or poll is shown on devices of all operating systems',
    osNoSpecified: 'Operating systems are no specified',
    osSpecified: 'Specified operating systems',
    plannedAd: 'Planned',
    platform: 'Platform',
    platformCaption: 'Platform for display ad',
    platformDesktop: 'Desktop',
    platformMobile: 'Mobile',
    platformTablet: 'Tablet',
    poll_quality: 'poll quality',
    poll_user_data: 'poll user data',
    poll: 'poll',
    pollImagesCaption: 'Poll variant must contain both image and text.',
    pollQualityVariants: 'Quality poll number of variants (number of stars)',
    PollSelfVariant: 'Your answer',
    pollSelfVariantPlaceholder: 'Write your own option',
    pollUserData: {
      getInfo: "Get information about user's",
      sex: 'Gender',
      date_of_birth: 'Birthday',
      dateOfBirth: 'Enter your date of birth',
      selectGender: 'Enter your gender',
      genders: {
        female: 'Female',
        male: 'Male'
      }
    },
    pollResults: 'Poll results',
    pollvariants: 'Poll variants',
    poolQualityUpdateVariants: 'Set',
    poolQualityVariantsValidate: 'Quality poll number field must be greater than 1 and less than 5',
    preview: 'Preview',
    previewDesktop: 'Desktop template',
    previewMobile: 'Mobile template',
    priority: 'Priority',
    priorityCaption: 'From 0 (lowest) to 100 (highest)',
    question: 'Question',
    redirectURL: 'Redirect on click URL',
    redirectURLCaption: 'Leave blank if redirect is not required.',
    resolutionCaption: 'By default the file above will be used for both mobile and desktop/laptop devices.',
    sameAs: 'Same settings as',
    schedule: 'Schedule',
    scheduleCaption: 'Ad activity period',
    scheduleDate: 'Starting from date:',
    scheduleEmpty: 'No active ads for this date',
    self_variant: 'Open question (custom answer is possible)',
    setSchedule: 'Please select schedule',
    skip: 'Skip Ad',
    skipafter: 'Skip Ad or Poll after (in sec.)',
    statistics: 'Statistics',
    statisticsDuration: 'Views duration',
    statisticsMeanDuration: 'Mean view duration',
    statisticsPoll: 'Poll results',
    statisticsShows: 'Ad impression statistics',
    statisticsSkipped: 'Views with skip',
    statisticsTotal: 'Total views',
    switchPollWarning:
      'This action will delete saved poll variants. Please confirm that you wish to delete poll variants.',
    targeting: 'Targeting settings',
    textOnly: 'Text-only',
    timeOfDay: 'Time of day',
    timeOfDayAfternoon: 'Afternoon',
    timeOfDayCaption: 'Time of day to display',
    timeOfDayEvening: 'Evening',
    timeOfDayMorning: 'Morning',
    timeOfDayNight: 'Night',
    url: 'Url',
    vendor: 'Vendors',
    vendorCaption: 'List of vendors for display ad or poll. Apple or Intel for example',
    vendorNoSpecified: 'Vendors are no specified',
    vendorSpecified: 'Specified vendors',
    vendorinstruction: 'Vendors targeting. If no vendors are specified, then the ad is shown on devices of all vendors',
    video: 'video',
    views: 'Views',
    viewsCaption: '0 for infinite views',
    warnUsage: 'Please note that if you delete an Ad in use by a Portal, {0}.',
    warnUsageAccent: 'such Portal will not work properly',
    withImages: 'Images with Text',
    massAdsEditScheduleAndTargeting: 'Edit Schedule And Targeting',
    massAdsEditSchedule: 'Edit Schedule',
    massAdsEditTargeting: 'Edit Targeting',
    massAdsEditSelected: 'selected',
    massAdsEditSelectAll: 'select all',
    massAdsUpdatedSuccess: 'Ads & Polls updated successfully',
    notificationAdPollCreatedSuccessfully: 'Ad or Poll Created Successfully',
    notificationAdPollUpdatedSuccessfully: 'Ad or Poll Updated Successfully',
    notificationAdPollDeletedSuccessfully: 'Ad or Poll Deleted Successfully'
  },
  aps: {
    deleteTemplate: 'Delete Template',
    goBackToClients: 'Back To Clients',
    ntpSettingsHeader: 'NTP',
    ntpSettingsEnable: 'Enable',
    ntpSettingsServersHeader: 'NTP servers',
    ntpSettingsServersListCounter: 'Servers list items',
    ntpSettingsServersAdd: 'Add',
    ntpSettingsServersDelete: 'Del',
    ntpSettingsServersClear: 'Clear',
    ntpSettingsServersAlreadyAdded: 'Server already added',
    WPA3AddToNoWPA3CPEMultiEditError: 'Some of the selected APs are no capable WPA3. You can\'t add a WPA3 network to a non WPA3 capable AP',
    WPA3AddToNoWPA3CPEError: 'You can\'t add a WPA3 network to a non WPA3 capable AP',
    WPA3Capable: 'WPA3 capable',
    goBackToCpeStatistic: 'Back to AP Statistic',
    DHCPCaptureFilter: 'DHCP Capture',
    DHCPCaptureFilterEnabled: 'DHCP Capture on',
    DHCPCaptureFilterDisabled: 'DHCP Capture off',
    DHCPCaptureFilterEnabledShort: 'on',
    DHCPCaptureFilterDisabledShort: 'off',
    wiredConfigAccounting: 'Accounting',
    wiredConfigAccountingEnable: 'Enable Accounting',
    wiredConfigFakeWlan: 'Fake WLAN',
    selectChannelsMoreThanForCorrectWork: 'For correct work select channels more than',
    selectChannelsLessThanForCorrectWork: 'For correct work select channels less than',
    differentHostAddrMessage: 'Attention! Selected points have different host_addr',
    selectHostAddress: 'select',
    cpeFwCustomAddress: 'server address',
    cpeFwCustomAddressAndSchema: 'FW server address and protocol',
    tunnelAndWLANtunnelsAreNotMatchForCPETable:
      'Tunnel and WLAN tunnel do not match in one of the wired interface configs',
    selectedAndWLANtunnelsAreNotMatch: 'The selected tunnel and the selected WLAN tunnel do not match',
    positionBlockSelectLocation: 'Select Location',
    positionBlockCopyFromSelectedLocationButtonCaption: 'Copy from selected location',
    positionBlockCopyFromSelectedLocationButtonInfo:
      'Allows to copy coordinates (latitude and longitude) from the selected location',
    positionBlockForMultiEditButtonCaption: 'Set Position',
    positionBlockCopyFromLocationCaption: 'Copy from Location',
    positionBlockSearchAddressCaption: 'Сoordinates by address search',
    positionBlockEnterAddressToSearch: 'Enter the address to find coordinates',
    positionBlockCopyFromLocationButtonCaption: 'Copy from',
    positionBlockCopyFromLocationButtonInfo:
      'Allows you to copy coordinates (latitude and longitude) from the location where the access point is located',
    positionBlockCoords: 'Coordinates',
    positionBlockCoordsLat: 'lat.',
    positionBlockCoordsLatFull: 'Latitude',
    positionBlockCoordsLng: 'lng.',
    positionBlockCoordsLngFull: 'Longitude',
    positionBlockHeader: 'Position',
    fwSelectFirmwareCaption: 'Select firmware',
    fwFileUploadSuccessfullyMsgCaption: 'FW File Uploaded Successfully',
    fwFileUploadedCaption: 'uploaded',
    errorCopyToClipBoardCaption: 'Copy to clipboard',
    clickToWatchEventsCaptionInTooltip: 'Click to watch events',
    errorCaptionInCpeEditPanel: 'Error',
    recommendationCaptionInCpeEditPanel: 'Recommendation',
    eth0StateSinceRebootCaption: 'since reboot',
    eth0StateCaption: 'Ethernet interface state',
    eth0IsOff: 'Interface is off',
    cpeConfigSetCpeListCaption: 'The configuration will apply to the following APs',
    cpeConfigSetCpeListNameCaptionAfterApplying: 'Name (after applying)',
    cpeConfigSetCpeListNameCaptionBeforeApplying: 'Name (before applying)',
    cpeConfigSetErrorsInUploadedData: 'There are errors in the configuration data. Check if they are correct.',
    cpeConfigSetButtonTooltipText: 'Load configuration for APs from a previously saved file',
    cpeConfigSetButtonText: 'Load configuration from file',
    cpeConfigShowTooltipForButtonInCpe: 'Download AP configuration as a file',
    cpeConfigSetFromUploadedFileIsertToFieldCaption: 'or paste the config into the field',
    cpeConfigSetFromUploadedFileButtonCaption: 'Load from file',
    cpeConfigSetCopyFromClipboardButtonCaption: 'Paste from clipboard',
    cpeConfigSetTitleHeader: 'Apply configuration for APs',
    cpeConfigShowCopyToClipboardSuccess: 'Copied to clipboard',
    cpeConfigShowCopyToClipboardError: 'Failed to copy to clipboard',
    cpeConfigShowSaveToFileError: 'Failed to save to file',
    cpeConfigShowCopyButtonCaption: 'Copy to clipboard',
    cpeConfigShowDownloadButtonCaption: 'Save to a file',
    cpeConfigShowTitleHeader: 'AP configuration',
    configNotSendCaption: 'Resend configuration every time',
    syncTime: 'Synchronize time',
    isCpeModelEqualRTBR24WFN2Ev61ErrorMsgForLan2:
      "Can't set VLAN 0 when lan 2 is selected for Rotek RT-BR24-WFN2E v6.1",
    isCpeModelEqualRTBR24WFN2Ev61InfoMsgForLan2: "Can't set VLAN 0 when lan 2 is selected for Rotek RT-BR24-WFN2E v6.1",
    tunnelTypeForWLANInfo: 'Tunnel',
    natNetworkInfoMsg: "Don't use 10.9.0.1/16 for IPSec APs",
    natNetworkIpaddr: 'IP for NAT network',
    natNetworkNetmask: 'Mask for the NAT network',
    natNetworkIpaddrPlaceholder: 'Enter IP for NAT network',
    natNetworkNetmaskPlaceholder: 'Enter the mask for the NAT network',
    filterByCompanyDisableSortingByLocationMsg: 'To sort by location, you must turn off sorting by company name.',
    setTimeUsingNTPSuccessMsg: 'Time set successfully',
    ntpServerAddressPlaceholder: 'server address (IP or domain)',
    setTimeUsingNTPEnterServerAddress: 'Enter server address (IP or domain)',
    setTimeUsingNTP: 'Set time using NTP',
    filterByUpdatingStatus: 'Updating',
    sortByConnectionnOrDisconnectionTimeNoSort: 'No sort',
    sortByLastDisconnection: 'By Last Disconnection',
    sortByLastConnection: 'By Last Connection',
    sortByFirstConnection: 'By First Connection',
    sortByConnectionnOrDisconnectionTime: 'Sort by conn./disconn. time',
    sortByConnectionnOrDisconnectionTimeButton: 'Conn./disconn. time',
    sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption: 'Filtering by date range',
    sortByConnectionnOrDisconnectionTimeDateRangeFilterButton: 'Enable',
    sortByConnectionnOrDisconnectionTimeDateRangeCaption: 'Date range',
    errorMessageOnlyIfLeastOneCPEHaveError:
      'Error discard is available only if at least one of the selected APs has errors',
    discardErrorsNotificationErrorsDiscardedSuccessfully: 'Errors discarded successfully',
    discardErrorsButton: 'Discard errors for AP',
    inputForChangeDefaultServerPlaceholder: 'Input for change default server',
    inputForChangeDefaultServerPortPlaceholder: 'Input for change default server port',
    inputForChangeDefaultPlaceholder: 'Input for change default',
    currentWhitelistItems: 'Current whitelist items',
    currentBlacklistItems: 'Current blacklist items',
    none: 'none',
    wifiRadar: 'Wi-Fi Target',
    whitelist: 'whitelist',
    blacklist: 'blacklist',
    wiredTunnel: 'Tunnel',
    wiredPorts: 'Ports',
    wiredVLANNAtive: 'native',
    wiredNoTunel: 'No tunnel',
    wiredSet0forNative: 'set 0 for native',
    rrmInfoTooltip: 'RRM - radio resource control algorithm',
    firmwareModeOn: 'on',
    firmwareModeOff: 'off',
    firmwareModeCheck: 'check',
    rateControl: 'Rate Control',
    cpeStatusTooltipActive: 'Active',
    cpeStatusTooltipUpdating: 'Updating',
    cpeStatusTooltipNotActive: 'Not active',
    registerCpeRegisterFollowCPEs: 'Register follow APs',
    registerCpeExample: 'Example',
    registerCpeFileTip: 'Use .csv file',
    buttonsBack: 'Back',
    modalStatTitle: 'Statistic',
    modalLogsTitle: 'Logs',
    modalWiFiRadarTitle: 'Wi-fi Target',
    modalFirewallTitle: 'Firewall',
    modalYes: 'Yes',
    modalNo: 'No',
    modalEnabled: 'Enabled',
    modalDisabled: 'Disabled',
    modalNoWLANs: 'No WLANs',
    modalWLANsTitle: 'WLANs',
    modalModelTitle: 'Model',
    modalLocationTitle: 'Location',
    modalConfigFromTitle: 'Config from',
    modalUUIDOptionalTitle: 'UUID (optional)',
    modalAddUUIDPlaceHolder: 'Add UUID',
    modalTemplateDescriptionPlaceHolder: 'Enter description',
    modalEnterTemplateNamePlaceHolder: 'Enter template name',
    modalAddTagPlaceHolder: 'Add tag',
    buttonsApply: 'Apply',
    modalSelectTemplatePlaceHolder: 'Select template',
    modalFirmwareFileInfo: 'Firmware file info',
    modalFirmwareFileInfoSize: 'Size',
    buttonsUpgrade: 'Upgrade',
    modalBeAttention: 'Be attention!',
    modalBeAttentionFirst: 'While force upgrading the access points, the auto upgrade mode will turn off.',
    modalBeAttentionSecond: 'If uploaded firmware is same for chosen AP - nothing will be done.',
    modalUploadFWfile: 'Upload FW file',
    modalNoUpdatesAvailable: 'No updates available',
    modalCurrentFirmwareVersion: 'Current firmware version',
    modalBrokerAddress: 'Broker address',
    modalBrokerAddressPlaceHolder: 'Input for change default broker address',
    buttonsYesReboot: 'Yes, reboot',
    buttonsYesReset: 'Yes, reset',
    errorMessageOnlyForCPEsWithEqualHostAddr: 'Available only for APs with the same broker address',
    errorMessageOnlyForAPsWithEqualModel: 'This settings available only for APs with equal Model',
    errorMessageOnlyForConnectedCPEs: 'This setting available only for connected APs',
    errorMessageOnlyForIpsecConnectedCPEs: 'This setting available only for ipsec connected APs',
    errorMessageOnlyForDisconnectedCPEs: 'This setting available only for disconnected APs',
    errorMessageNoAvailableCapabilities: 'No available capabilities',
    errorMessageNoAvailableCapabilitiesNote: 'Band / Mode / Channel combination not correct. Please turn off, delete from Platfrom and recreate AP from scratchо',
    errorMessageNoCPEsModelData: 'No model data for selected APs',
    cpeEditPanelNamePlaceHolder: 'Enter AP name',
    cpeEditPanelDescriptionPlaceHolder: 'Enter AP description',
    wlansCaption: 'WLANs',
    wlansSelectorPlaceHolder: 'Select WLAN',
    wlansSelectorTip: 'Press enter to select',
    cpeEditRadioSettings: 'Radio Settings',
    cpeEditCaptionOf: 'of',
    cpeEditCaptionUnlimited: 'unlimited',
    tableLoad: 'Load',
    tableOutOf: 'APs ouf of',
    tableView: 'view',
    tableItemsOf: 'items of',
    emptyWatcher: 'Reboot AP when MAC address list is empty',
    emptyWatcherInfoTooltip: 'Mandatory for TP-Link MR3020 APs due to driver features',
    maxQuiet: 'Empty collections times before reboot',
    maxQuietCaption: 'after how many empty attempts reboot',
    maxQuietShort: 'empty collections times',
    maxCacheQueue: 'How many MAC addresses to store offline',
    maxCacheQueueCaption: 'when AP is not connected to the controller',
    maxCacheQueueShort: 'MAC addresses to store offline',
    Advanced: 'Advanced',
    Config: 'Config',
    FWAutoUpgrade: 'FW Auto Upgrade',
    FWversion: 'Firmware version',
    Guestcontrol: 'Guest control',
    IPsec: 'IPsec',
    IPsecModalLabel: 'Please choose action',
    IPsecTurnOff: 'Turn Off',
    IPsecTurnOn: 'Turn On',
    IPsecTurnOnUnencrypted: 'Turn On Without Encryption',
    Input: 'Input',
    LBSFilterMode: 'Wi-Fi Target filter mode',
    Log: 'Log',
    Output: 'Output',
    OutputWarning: 'Choose at least one option',
    OutputWarningForNonRootLocationUsers: 'Enable NAT',
    Ports: 'Ports',
    Redirect: 'Redirect',
    Stats: 'Stat',
    Tunnel: 'Tunnel',
    WLANsstatus: 'WLANs status',
    WiredConfig: 'Wired Config',
    accessControlSettings: 'Edit Access Control Settings',
    active: 'Active',
    addCommonTemplates: 'Set Template',
    addCommonWlans: 'Set Common WLANs',
    addConfig: 'add config',
    addCpe: 'Add AP',
    advancedSettings: 'Advanced Settings',
    allWlans: 'All wlans',
    assignCommonWlans: 'Assign Common WLANs',
    assignCommonWlansHint: 'Select common wlans for selected APs',
    assignCommonWlansWarn: 'Be careful. Selected APs have different radios',
    assignTemplate: 'Set template',
    assignTemplateHint: 'Select template for selected APs',
    auto: 'Auto',
    back: 'Back',
    backgroundScaning: 'Background Scanning',
    bandMode: 'Bandmode',
    bandwidth: 'Bandwidth',
    brokerHost: 'Broker host',
    cancel: 'Cancel',
    channel: 'Channel',
    channels: 'Channels',
    clientTimeout: 'Client Timeout (in sec)',
    clients: 'Clients',
    close: 'Close',
    commandOutput: 'Output',
    commonWlans: 'Common WLANs',
    config: 'config',
    configModalLabel: 'Please enter the script',
    configModalTitle: 'Send Config Script',
    configStatus: 'Status',
    confirm: 'Confirm',
    connected: 'Connected',
    connectionInfo: 'Connection info',
    country: 'Country',
    cpe: 'APs',
    cpeEvents: 'Show AP events',
    showCurrentClients: 'Show Current Clients',
    showCurrentStats: 'Show Current Stats',
    showCurrentStatsModalTitle: 'AP Current Stats for',
    showCurrentStatsNoDataForCPE: 'There is no data for this AP',
    showCurrentStatsUptimeCaption: 'Uptime',
    showCurrentStatsRamChartYAxisTitle: 'Mbytes',
    showCurrentStatsRamChartTooltip: 'MB',
    showCurrentStatsRamChartTotalRam: 'Total memory',
    showCurrentStatsRamChartFreeRam: 'Free memory',
    showCurrentStatsRamChartChartTitle: 'Memory',
    showCurrentStatsInterfacesHeader: 'AP Interfaces',
    showCurrentStatsInterfacesInterfaceNameTableCaption: 'AP Interface',
    showCurrentStatsInterfacesInterfaceTableHeaderCaption: 'Interface',
    showCurrentStatsInterfacesTxRxChartTitle: 'TX and RX for',
    showCurrentStatsInterfacesTxRxChartTx: 'TX',
    showCurrentStatsInterfacesTxRxChartRx: 'RX',
    showCurrentStatsInterfacesTxRxChartYAxisTitleMB: 'Mbytes',
    showCurrentStatsInterfacesTxRxChartYAxisTitleKB: 'Kbytes',
    showCurrentStatsInterfacesTxRxChartYAxisTitleB: 'Bytes',
    showCurrentStatsInterfacesTxRxChartYAxisTitleGB: 'Gbytes',
    showCurrentStatsInterfacesTxRxChartTooltipMB: 'MB',
    showCurrentStatsInterfacesTxRxChartTooltipKB: 'KB',
    showCurrentStatsInterfacesTxRxChartTooltipB: 'Bytes',
    showCurrentStatsInterfacesTxRxChartTooltipGB: 'GB',
    showCurrentStatsInterfacesTxRxSpeedChartTitle: 'TX and RX speed for',
    showCurrentStatsInterfacesTxRxSpeedChartTx: 'TX speed',
    showCurrentStatsInterfacesTxRxSpeedChartRx: 'RX speed',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleMB: 'Mbytes/sec',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleKB: 'Kbytes/sec',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleB: 'Bytes/sec',
    showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleGB: 'Gbytes/sec',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipMB: 'MB/sec',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipKB: 'KB/sec',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipB: 'Bytes/sec',
    showCurrentStatsInterfacesTxRxSpeedChartTooltipGB: 'GB/sec',
    showCurrentStatsInterfacesTxRxDeltaChartTitle: 'TX and RX traffic delta for',
    showCurrentStatsInterfacesTxRxDeltaChartTx: 'TX traffic delta',
    showCurrentStatsInterfacesTxRxDeltaChartRx: 'RX traffic delta',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleMB: 'Mbytes',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleKB: 'Kbytes',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleB: 'Bytes',
    showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleGB: 'Gbytes',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipMB: 'MB',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipKB: 'KB',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipB: 'Bytes',
    showCurrentStatsInterfacesTxRxDeltaChartTooltipGB: 'GB',
    showCurrentStatsMbytes: 'MBytes',
    showCurrentStatsKbytes: 'KBytes',
    showCurrentStatsBytes: 'Bytes',
    showCurrentStatsGbytes: 'GBytes',
    showCurrentStatsUnits: 'Units',

    cpeId: 'AP ID',
    cpeInfo: 'AP info',
    cpesList: 'APs List',
    createTemplateFromCpe: 'Create template from APs config',
    currentState: 'Current state',
    dbm: 'dBm',
    deleteAllTags: 'Delete All Tags',
    deleteAllWlans: 'Delete All WLANs',
    deleteCommonTags: 'Delete Common Tags',
    deleteCommonWlans: 'Delete Common WLANs',
    deleteCpe: 'Delete AP',
    deleteCpeConfirm: 'Confirm deleting ',
    deleteCpes: 'Delete APs',
    deleteCpesConfirm: 'Confirm deleting',
    deleteTagsConfirm: 'Confirm deleting all Tags on selected APs',
    deleteWlansConfirm: 'Confirm deleting all WLANs on selected APs',
    description: 'Description',
    disabled: 'Disabled',
    disconnected: 'Disconnected',
    downloadSpeed: 'Download Speed Limit',
    downloadSpeedDescription: 'Download speed limit evenly applied to all clients',
    downloadSpeedMax: 'Total Limit',
    downloadSpeedMin: 'Limit Per Client',
    downloadSpeedType: 'Unit',
    dualBand: 'Dual radio',
    editCommonParameters: 'Edit common APs parameters',
    editLogAndStatSettings: 'Edit Log, Stat, SNMP, NTP & DHCP Packet Capture Settings & Wi-Fi Target',
    editSelectedCpes: 'Edit Selected APs',
    editSettingsCpes: 'Edit Radio Settings',
    editTags: 'Add Tags',
    editingMode: 'Editing Mode',
    empty: 'empty',
    error: 'error',
    forFollowingCPEs: 'for the following APs',
    syncTimeForCPES: 'Time synchronization will be done for the following APs',
    syncTimeButton: 'Synchronize',
    syncTimeSuccessMsg: 'Time successfully synchronized',
    fwUpgrade: 'Firmware Upgrade',
    general: 'General',
    ghz: 'GHz',
    header: 'Access Points',
    hideDescription: 'hide description',
    hostAddr: 'Broker address',
    interfaceDisabled: 'Interface disabled',
    interfaceType: 'WAN Type',
    interfaces: 'Interfaces',
    ipaddr: 'Local network address',
    lastError: 'Distressed',
    lbs: 'Wi-Fi Target',
    lbsConfig: 'Wi-Fi Target Config',
    legacyRates: 'Legacy rates (802.11b support)',
    limitperWlan: 'Limit per WLAN',
    localAddr: 'Local tunnel address',
    location: 'Location',
    log: 'Log',
    logConfig: 'Log Config',
    logLevel: 'Log level',
    logLevelMode: 'ON - debug mode, OFF - normal mode',
    macaddr: 'MAC',
    massRegistrationMode: 'Mass registration mode',
    maxClients: 'Max clients',
    mode: 'Mode',
    model: 'Model',
    multiEdit: 'Multiple Edit',
    multiEditMode: 'Multiple edit mode',
    name: 'Name',
    nasIp: 'NAS IP address',
    natAccess: 'Access from NAT',
    noDataToDisplay: 'No data to display',
    notActive: 'Not active',
    noTags: 'No tags',
    notConnected: 'Not Connected',
    off: 'Off',
    offline: 'offline',
    ok: 'online',
    on: 'On',
    oneBand: 'One radio',
    pending: 'pending',
    radar: 'Wi-Fi Target',
    readOnlyMode: 'Read Only Mode',
    rebootCpe: 'Reboot AP',
    rebootCpeAgent: 'Reboot AP Agent',
    rebootCpeAgentModalText: 'Please confirm AP Agent reboot',
    rebootCpeConfirm: 'Confirm reboot',
    rebootCpeRedundant: 'Reboot via Redundant Channel',
    rebootCpeRedundantModalText: 'Please confirm reboot via redundant channel',
    rebootCpes: 'Reboot APs',
    rebooting: 'rebooting',
    register: 'Register',
    registerCPE: 'Register AP',
    registerCPEs: 'Register APs',
    registerDescription: '',
    registerFollowCPEs: 'Register follow APs?',
    reportPeriod: 'Report Period (in sec)',
    resetCpe: 'Reset AP',
    resetCpeConfirm: 'Confirm reset',
    resetCpes: 'Reset APs',
    resetting: 'resetting',
    restartCpeAgent: 'Restart AP Agent',
    save: 'Save',
    search: 'Search',
    sendConfigCmd: 'Send config command',
    getLogsFromCpe: 'Get Log From AP',
    logFromCpe: 'Log From AP',
    showDescription: 'show description',
    singleRegistrationMode: 'Single registration mode',
    statConfig: 'Stat Config',
    statConfigCapture: 'AP and client statistics period',
    tableTab_clients: 'Clients',
    tableTab_five: '5 Ghz',
    tableTab_interface: 'Interface',
    tableTab_location: 'Location, Company',
    tableTab_model: 'Model',
    tableTab_name: 'Name',
    tableTab_radar: 'Wi-Fi Target',
    tableTab_status: 'Status',
    tableTab_tags: 'Tags',
    tableTab_two: '2.4 Ghz',
    tableTab_wired: 'Wired',
    tableTab_wlans: 'WLANs',
    tableTab_firstConnection: 'First conn.',
    tableTab_lastConnection: 'Last conn.',
    tableTab_lastDisconnection: 'Last disconn.',
    templates: 'Templates',
    txPower: 'TX Power',
    txPowerAdjust: 'TX Power',
    type: 'Type',
    unlim: 'unlim',
    update: 'Update',
    updating: 'updating',
    upgrading: 'upgrading',
    wanBlockAccess: 'SSH / Web access block from WAN',
    warnStatistics: 'Please note that deletion of AP will result in {0}.',
    warnStatisticsLoss: 'loss of statistics',
    width: 'Width',
    wifiLock: 'Lock Wi-Fi config',
    wired: 'Wired',
    wlans: 'WLANs',
    notificationTemplateCreatedSuccessfully: 'Template Created Successfully',
    notificationLocationSuccessfullyChanged: 'Location is successfully changed',
    notificationCPEUpdatedSuccessfully: 'AP Updated Successfully',
    notificationCPECreatedSuccessfully: 'AP Created Successfully',
    notificationCPEOperationSuccessfull: 'Operation Successful',
    dhcpPacketCapture: 'DHCP Packet Capture',
    statAndRadarCaptionInCpeInfoBlock: 'Statistic & Wi-fi Target & Log info & SNMP & NTP & DHCP Packet Capture',
    statAndRadarCaptionInModalBlock: 'Statistic & Wi-fi Target & Log info & SNMP & NTP & DHCP Packet Capture',
    maxinactivityTitle: 'Max inactivity timeout',
    maxinactivityPlaceholder: 'Enter period in sec. (0 for default 180 sec. value)',
    maxinactivityTooltip: 'Max inactivity timeout from hostapd config. Click to view more info',
    maxinactivityAboutModalTitle: 'About Max inactivity',
    maxinactivityAboutModalText: 'Max inactivity timeout from hostapd config',
    snmp: 'SNMP',
    snmpCommunity: 'Community',
    snmpLocation: 'Location',
    snmpinterfaces: 'Interfaces',
    snmpinterfacesListItems: 'Interfaces list items',
    snmpListenInterface: 'Listen Interface',
    zeroVLANIfNativeVlanOnSinglePortInfoMsg:
      'Do not set VLAN 0 on the interface from which the device connects to the network. This will result in the loss of the device',
    cpeClientsListFromCpeCommandModalHeader: 'AP Clients',
    cpeClientsListFromCpeCommandLink: 'Show Current Clients (data from AP)',
    cpeClientsListFromCpeNoClients: 'No clients to display',
    cpeClientsListFromCpeTooltipHeader: 'Wlan information',
    cpeClientsListFromCpeChannellCaption: 'Channel',
    cpeClientsListFromCpeClientsTableHeader: 'Clients',
    cpeClientsListFromCpeClientsMac: 'MAC',
    cpeClientsListFromCpeClientsInactiveTime: 'Inactive time',
    cpeClientsListFromCpeClientsRxBytes: 'Rx bytes',
    cpeClientsListFromCpeClientsRxPackets: 'Rx packets',
    cpeClientsListFromCpeClientsTxBytes: 'Tx bytes',
    cpeClientsListFromCpeClientsTxPackets: 'Tx packets',
    cpeClientsListFromCpeClientsTxRetries: 'Tx retries',
    cpeClientsListFromCpeClientsTxFailed: 'Tx failed',
    cpeClientsListFromCpeClientsRxDropMisc: 'Rx drop misc',
    cpeClientsListFromCpeClientsSignal: 'Signal',
    cpeClientsListFromCpeClientsSignalAvg: 'Signal avg',
    cpeClientsListFromCpeClientsTxBitrate: 'Tx bitrate',
    cpeClientsListFromCpeClientsRxBitrate: 'Rx bitrate',
    cpeClientsListFromCpeClientsRxDuration: 'Rx duration',
    cpeClientsListFromCpeClientsAuthorized: 'Authorized',
    cpeClientsListFromCpeClientsAuthenticated: 'Authenticated',
    cpeClientsListFromCpeClientsAssociated: 'Associated',
    cpeClientsListFromCpeClientsPreamble: 'Preamble',
    cpeClientsListFromCpeClientsWMMWME: 'WMM/WME',
    cpeClientsListFromCpeClientsMFP: 'MFP',
    cpeClientsListFromCpeClientsTDLSPeer: 'TDLS peer',
    cpeClientsListFromCpeClientsDTIMPeriod: 'DTIM period',
    cpeClientsListFromCpeClientsBeaconInterval: 'Beacon interval',
    cpeClientsListFromCpeClientsShortSlotTime: 'Short slot time',
    cpeClientsListFromCpeClientsConnectedTime: 'Connected time',
    // for combined columns
    cpeClientsListFromCpeClientsRX: 'RX',
    cpeClientsListFromCpeClientsTX: 'TX',
    cpeClientsListFromCpeClientsCombinedSignal: 'Signal',
    cpeClientsListFromCpeClientsAuthorizedAuthenticatedAssociated: 'Authorized, Authenticated, Associated',
    cpeClientsListFromCpeClientsBitrate: 'Bitrate',
    //
    cpeClientsListFromCpeClientsConnectedCaption: 'connected',
    cpeClientsListFromCpeClientsInfoCaption: 'Allows you to get information about current clients directly from the AP',
    tunnels: {
      captionInCpeModal: 'Tunnels',
      noTunnels: 'There are no tunnels'
    }
  },
  clientDistance: {
    chartDistance: 'Distance (meters)',
    chartHeader: 'Client Distance',
    noTags: 'no tags',
    tableView: 'view',
    tableItemsOf: 'items of'
  },
  clients: {
    goBackToClientsStatistic: 'Back to Clients Statistic',
    clientMac: 'Client MAC',
    eventsTooltipCaption: 'Allows you to go to the Events section and view events for the selected client',
    eventsAndRssiTooltipCaption: 'Allows you to view events and RSSI for the selected client on the chart',
    eventsButtonCaption: 'Events',
    eventsAndRssiButtonCaption: 'Events and RSSI',
    eventsTableCaption: 'Events',
    authNo: 'No',
    authTable: 'Auth',
    authInfo: 'Auth info',
    authUsername: 'username',
    authType: 'type',
    authDevice: 'device',
    authOs: 'os',
    authOsVersion: 'os version',
    authUseragent: 'useragent',
    tableInterface: 'interface',
    tableNotFound: 'not found',
    tableNo: 'no',
    tableNoData: 'no data',
    editPanelDescriptionPlaceHolder: 'Enter description',
    editPanelOther: 'Wireless',
    editPanelCamera: 'Camera',
    editPanelWired: 'Wired',
    editPanelCameraRtspStream: 'Rtsp Stream',
    editPanelCameraRtspStreamLinkPlaceHolder: 'Enter link for Rtsp Stream',
    authorization: 'Authorization',
    cpe: 'AP',
    wlan: 'WLAN',
    lastConnected: 'Last connected',
    chipManufacturer: 'Chip manufacturer',
    showAllClients: 'Show all clients',
    showAllClientsWhenCPESelected: 'Show all clients for selected AP',
    noConnectedClientsToDisplay: 'No connected clients to display.',
    noConnectedClientsToDisplayWhenCPESelected: 'No connected clients to display for selected AP',
    auth: 'Auth',
    load: 'Load',
    clientsOutOf: 'Clients out of',
    view: 'view',
    itemsOf: 'items of',
    CPE: 'AP',
    WLAN: 'WLAN',
    authorized: 'Authorized',
    camera: 'Camera',
    channel: 'Channel',
    clients: 'Clients',
    connected: 'Connected',
    disconnected: 'Disconnected',
    frequency: 'Frequency',
    other: 'Wireless',
    tableTab_channel: 'Channel',
    tableTab_cpe: 'AP (Freq / Channel / RSSI)',
    tableTab_description: 'Description',
    tableTab_frequency: 'Frequency',
    tableTab_mac: 'MAC',
    tableTab_rssi: 'RSSI',
    tableTab_state: 'Last State',
    tableTab_type: 'Type',
    tableTab_wlan: 'WLAN',
    tableTab_healthScore: 'Health Status',
    wired: 'Wired',
    notificationClientUpdated: 'Client updated successfully',
    scores: 'points',
    healthInactive: 'Inactive',
    healthPoor: 'Poor',
    healthFair: 'Fair',
    healthGood: 'Good',
    healthOffline: 'Offline',
    healthScoresInfoTooltip: 'Click to see more info about the Health Status',
    healthStatusModalInfoTitle: 'About Health Status',
    healthStatusModalInfoCaption: 'Health Status can take the following values',
    healthStatusButtonsInfo: 'only for Connected clients filter'
  },
  clientsRF: {
    clientRFChartTitle: 'Client RF',
    tableView: 'view',
    tableItemsOf: 'items of',
    clientRFSidebar: 'Client RF',
    clientRFTableTitle: 'Client RF',
    noData: 'no data',
    authNo: 'No',
    authTable: 'Auth',
    authInfo: 'Auth info',
    authUsername: 'username',
    authType: 'type',
    authDevice: 'device',
    authOs: 'os',
    authOsVersion: 'os version',
    authUseragent: 'useragent',
    clientRF: 'Client RF',
    expectedThroughput: 'Expected throughput',
    noise: 'Noise',
    rx: 'Receive',
    signal: 'Signal',
    tx: 'Transmit',
    rating: 'Rating',
    table: 'Table',
    signalP10: 'Signal P10',
    signalP90: 'Signal P90',
    snrAvg: 'SNR average',
    clientRFP: 'Client RFP',
    numberOfEntries: 'Number of entries',
    paginationClientsOutOf: 'Clients out of',
    paginationLoad: 'Load'
  },
  controller: {
    notificationControllerUpdatedSuccessfully: 'Controller Updated Successfully',
    notificationControllerCreatedSuccessfully: 'Controller Created Successfully',
    notificationControllerDeletedSuccessfully: 'Controller Deleted Successfully',
    notificationControlleOperationSuccessfull: 'Successfully',
    FWversion: 'FW version',
    SSHkey: 'SSH key',
    accessSettings: 'Access Settings',
    active: 'Active',
    addController: 'Add controller',
    controller: 'controller',
    controllerHeader: 'Controller',
    controllersList: 'Controllers list',
    deleteControllerHeader: 'Delete controller',
    confirmDelete: 'Confirm deleting',
    password: 'Password',
    status: 'Status',
    username: 'Username',
    vendor: 'Vendor',
    serialNumber: 'Serial number',
    newController: 'New controller',
  },
  dashboard: {
    goBackToDashboard: 'Back to Dashboard',
    goBackToClients: 'Back To Clients',
    goToCpeStatistic: 'Statistic',
    goToCpeSettings: 'Settings',
    cpeChartSeriesName: 'APs',
    cpeStatusChartSeriesName: 'APs',
    CPE: 'AP',
    ERROR: 'ERROR',
    Events: 'Events',
    INFO: 'INFO',
    WARNING: 'WARNING',
    ads: 'Advertisement',
    adsCounter: 'Views',
    adsStatistic: 'Advertisement Statistic',
    adsStatisticCaption: 'for this month, the best view days are shown',
    adsStatisticTotal: 'Total Ads Views',
    adsTotal: 'Total Ads',
    adsType: 'Type',
    available: 'Available',
    cache: 'Cache',
    configCPEs: 'Status APs',
    connected: 'Connected',
    connectedCPEs: 'Connected APs',
    connectionsStat: 'Connections Statistic',
    connectionsStatCaption: 'last 7 days',
    cpu: 'CPU',
    cpuCores: 'Cores',
    cpuCount: 'CPU',
    cpuLoad: 'CPU Load',
    empty: 'Empty',
    free: 'Free',
    gb: 'Gb',
    kb: 'KB',
    last10events: 'Events Summary 24h',
    load: 'Load',
    load1: 'Last minute',
    load15: 'Last 15 minutes',
    load5: 'Last 5 minutes',
    load_core: 'Load per core',
    locationAddress: 'Address',
    locationCreated: 'Created',
    locationManager: 'Manager',
    locationOwner: 'Owner',
    locations: 'Locations',
    locationsCaption: '3 random locations',
    locationsMap: 'Map of locations',
    locationsMapCaption: 'Only locations with coordinates are displayed',
    locationsTotal: 'Total locations',
    locationsWhereManager: 'Locations where you are a manager ',
    locationsWhereManagerCaption: 'only locations where You are a manager are displayed',
    mb: 'Mb',
    memory: 'Memory',
    memoryFree: 'Memory free',
    memoryTotal: 'Memory total',
    memoryUsed: 'Memory Used',
    model: 'Model',
    modelCPU: 'Model CPU',
    mostactiveCPEs: 'Most active APs',
    mostactiveclients: 'Most active clients',
    mostloadCPEs: 'Most load APs',
    notConnected: 'Not connected',
    offline: 'Offline',
    online: 'Online',
    os: 'OS',
    radar: 'Wi-Fi Target',
    received: 'Received',
    rule: 'RULE',
    serverInfo: 'Server Info',
    service: 'Service',
    toAds: 'View Advertisement',
    toAdsStatistic: 'View Ads Views Statistic',
    toCPEs: 'View APs',
    toClients: 'View Clients',
    toConnectionsStat: 'View Connections Statistic',
    toEvents: 'View Events',
    toLocations: 'View Locations',
    toMarketing: 'View Analytics',
    total: 'Total',
    trafficIn: 'Traffic In',
    trafficOut: 'Traffic Out',
    transmitted: 'Transmitted',
    updating: 'In process',
    uptime: 'Uptime day',
    used: 'Used'
  },
  drPicker: {
    always: 'Always',
    apply: 'Select',
    april_full: 'April',
    april_short: 'Apr',
    august_full: 'August',
    august_short: 'Aug',
    cancel: 'Cancel',
    chooseInterval: 'Choose time period',
    custom: 'Custom',
    days: 'days',
    december_full: 'December',
    december_short: 'Dec',
    february_full: 'February',
    february_short: 'Feb',
    friday_short: 'Fr',
    from: 'From',
    hours: 'hours',
    january_full: 'January',
    january_short: 'Jan',
    july_full: 'July',
    july_short: 'July',
    june_full: 'June',
    june_short: 'June',
    last: 'Last',
    last24: 'Last 24 Hours',
    last30: 'Last 30 Days',
    last48: 'Last 48 Hours',
    last7: 'Last 7 Days',
    march_full: 'March',
    march_short: 'Mar',
    may_full: 'May',
    may_short: 'May',
    minutes: 'minutes',
    monday_short: 'Mo',
    month: 'This Month',
    months: 'months',
    next: 'Next',
    november_full: 'November',
    november_short: 'Nov',
    october_full: 'October',
    october_short: 'Oct',
    prev_month: 'Previous Month',
    prev_week: 'Previous Week',
    saturday_short: 'Sa',
    seconds: 'seconds',
    september_full: 'September',
    september_short: 'Sept',
    sunday_short: 'Su',
    thursday_short: 'Th',
    to: 'To',
    today: 'Today',
    tuesday_short: 'Tu',
    wednesday_short: 'We',
    week: 'This Week',
    weeks: 'weeks',
    years: 'years',
    yesterday: 'Yesterday',
    maxDateRangeLimit: 'Maximum interval for selection'
  },
  events: {
    timestamp: 'Time',
    subjectId: 'subject ID',
    description: 'description',
    goBackToClients: 'Back To Clients',
    noDataForSelectedDateRangeForSelectedCPE: 'There is no data for the selected AP. Try to choose a different time period',
    noData: 'No data. Try another time period and/or other filters',
    goBackToCPE: 'Go Back to AP',
    cpeName: 'AP',
    clientRFAndEventsNumberOfEventsCaption: 'Number of events',
    clientRFAndEventsConnectedChartSeries: 'Connected',
    clientRFAndEventsDisconnectedChartSeries: 'Disconnected',
    clientRFAndEventsAuthorizationChartSeries: 'Authorization',
    clientRFAndEventsDhcp_AckChartSeries: 'DHCP ACK',
    clientRFAndEventsClientCaption: 'CLIENT',
    clientRFAndEventsRFHeader: 'RF data',
    clientRFAndEventsEventHeader: 'Event data',
    clientRFAndEventsButtonCaption: 'Events and RSSI',
    clientRFAndEventsModalCaption: 'Events and RSSI',
    clientRFAndEventsChartEventsCaption: 'Events',
    copyToClipboard: 'Copy to clipboard',
    errorEvents: 'Ошибка',
    eventData: 'Event data',
    events: 'Events',
    level: 'Level',
    showPayload: 'show full payload',
    status: 'Status',
    subject: 'Subject',
    summary: 'Summary',
    time: 'Time',
    type: 'Type',
    paginationEventsOutOf: 'Events out of',
    paginationLoad: 'Load',
    eventsChart: 'Events chart',
    eventsTable: 'Events',
    chartEventsAlert: 'There are only loaded in table events showed on chart',
    in24Hours: '24 hours',
    placeHolderSearch: 'Search',
    filterINFO: 'INFO',
    filterWARNING: 'WARNING',
    filterERROR: 'ERROR',
    filterLOG: 'LOG',
    filterCPE: 'AP',
    filterCLIENT: 'CLIENT',
    filterSERVICE: 'SERVICE',
    filterFIRMWARE: 'FIRMWARE',
    filterRULE: 'RULE',
    'filterCLIENT AUTH': 'CLIENT AUTH',
    filterCONNECTED: 'CONNECTED',
    filterDISCONNECTED: 'DISCONNECTED',
    filterAUTHORIZATION: 'AUTHORIZATION',
    filterACK: 'DHCP ACK',
    view: 'view',
    itemsOf: 'items of',
    sendConfigButton: 'Send Config Script to problem APs',
    sendConfigDuccessMessage: 'Config Script successfully sent',
    sendConfigModalHintMessage: 'The Script will be sent to APs with errors of the following type'
  },
  firewall: {
    ANY: 'ANY',
    IN: 'IN',
    OUT: 'OUT',
    modalDeleteFirewallTitle: 'Delete Firewall',
    modalDeleteFirewallText: 'Confirm deleting',
    wizardNoRules: 'No rules',
    confirmButton: 'Confirm',
    deleteRuleButton: 'Delete Rule',
    fromPortModal: 'From Port',
    toPortModal: 'To Port',
    fromMACaddress: 'From MAC address',
    toMACaddress: 'To MAC address',
    fromIPaddress: 'From IP address',
    toIPaddress: 'To IP address',
    enterPortPlaceholder: 'Enter port',
    enterIPPlaceholder: 'Enter IP address',
    enterMACPlaceholder: 'Enter MAC address',
    editRule: 'Edit Rule',
    fromMAC: 'From MAC',
    toMAC: 'To MAC',
    fromIP: 'From IP',
    toIP: 'To IP',
    fromPort: 'From Port',
    toPort: 'To Port',
    ruleProtocol: 'Protocol',
    ruleIPProtocol: 'IP Protocol',
    infoMessage:
      'Our firewall is based on Iptables. Rules are created in its notation. The documentation for the rules can be found here.',
    infoTooltip: 'click to view info about Firewall',
    firewallNamePlaceHolder: 'Enter Firewall name',
    firewall: 'Firewall',
    wizard3Caption: 'Confirm new Firewall creation',
    wizard2Caption: 'Fill Firewall rules',
    wizard1Caption: 'Fill required basic Firewall parameters',
    Action: 'Action',
    AddRule: 'Add Rule',
    Firewalls: 'Firewalls',
    Internetlayer: 'Internet layer',
    Linklayer: 'Link layer',
    Policy: 'Policy',
    RuleSettings: 'Rule settings',
    Rules: 'Rules',
    SaveRule: 'Save Rule',
    Transportlayer: 'Transport layer',
    addFirewall: 'Add Firewall',
    addNewRule: 'Add new Rule',
    createFirewall: 'Create Firewall Wizard',
    direction: 'Direction',
    firewallSettings: 'Firewall Settings',
    policy: 'Policy',
    rules: 'Rules',
    notificationFirewallUpdatedSuccessfully: 'Firewall Updated Successfully',
    notificationFirewallCreatedSuccessfully: 'Firewall Created Successfully',
    notificationFirewallDeletedSuccessfully: 'Firewall Deleted Successfully',
    notificationFirewallOperationSuccessfull: 'Operation Successful'
  },
  rules: {
    minTrafficThresholdCaptionBytesForTableColumn: 'bytes',
    minTrafficThresholdCaptionBytes: 'in bytes',
    minTrafficThresholdCaption: 'Minimum traffic threshold',
    minTrafficThresholdPlaceholder: 'Traffic threshold (in bytes)',
    minTrafficThresholdInfoCaption: 'If less traffic came during the period, then the script is executed',
    noRules: 'none',
    triggerScript: 'Trigger script',
    addRule: 'add',
    namePlaceHolderInWizard: 'Enter Rule name',
    scriptPlaceHolderInWizard: 'Enter script',
    scriptInfoCaptionInWizard: 'Insert one line sh script. Possible env variables TEXT - event payload, CPE - AP uuid',
    ruleTypes: {
      customer_activity: 'AP: Activity',
      cpu_load: 'AP: CPU load',
      free_ram: 'AP: Free RAM',
      config_error: 'AP: Config error',
      iface_error: 'AP: Interface error',
      connected: 'AP: Connected',
      disconnected: 'AP: Disconnected',
      client_far: 'Client: Distance from AP',
      client_con: 'Client: Connected',
      client_dis: 'Client: Disconnected'
    },
    notificationTypeCaption: 'Action type',
    notificationTypes: {
      telegram: 'Telegram',
      email: 'Email',
      mqtt: 'MQTT'
    },
    script: 'Script executing ',
    notifySettingsTelegramTokenCaption: 'Token',
    notifySettingsTelegramTokenPlaceholder: 'Token',
    notifySettingsTelegramTokenInfo: 'Token obtained from @BotFather',
    notifySettingsTelegramChatIdCaption: 'Chat Id',
    notifySettingsTelegramChatIdPlaceholder: 'Chat Id',
    notifySettingsTelegramChatIdInfo: 'Unique identifier of the telegram chat',
    notifySettingsTelegramTextCaption: 'Text',
    notifySettingsTelegramTextPlaceholder: 'Text For Notify',
    notifySettingsEmailToCaption: 'To',
    notifySettingsEmailToPlaceholder: 'Email address',
    notifySettingsEmailSubjectCaption: 'Subject',
    notifySettingsEmailSubjectPlaceholder: 'Subject for email',
    notifySettingsEmailTextCaption: 'Text',
    notifySettingsEmailTextPlaceholder: 'Text email',
    notifySettingsMQTTTopicCaption: 'Topic',
    notifySettingsMQTTTopicPlaceholder: 'Topic',
    notifySettingsMQTTTopicInfo: 'MQTT Topic',
    notifySettingsMQTTTextCaption: 'Text',
    notifySettingsMQTTTextPlaceholder: 'Text',
    notifySettingsForTableCaption: 'Action parameters',
    notifySettingsForTableForTelegramToChatId: 'Message to chat with id',
    notifySettingsForTableForEmailToAddress: 'Mail to address',
    notifySettingsForTableForMQTTToTopic: 'Message into topic',
    notifySettingsForTableShowMore: 'show more',
    notifySettingsForTableHide: 'hide'
  },
  sessions: {
    noSessions: 'No sessions'
  },
  general: {
    filtersTownPlaceholder: 'Town Name',
    confirmDeleting: 'Confirm deleting',
    noData: 'no data',
    mhz: 'MHz',
    mbitS: 'Mbit/s',
    object: 'Object',
    ofTotal: 'of total',
    another: 'Another',
    locationSelect: 'Select location',
    locationsAll: 'All',
    previouslySelected: 'Previously selected ',
    minimize: 'Minimize',
    expand: 'Expand',
    for: 'for',
    byDefault: 'by default',
    clear: 'clear',
    noOptionsForSearch: 'Start typing for search',
    noResultForSearch: 'No search results',
    dateRangeLimitExceededError: 'The selected range more then',
    maxOneElement: 'Maximum of options selected. First remove a selected option to select another',
    selectLocation: 'Select location',
    selected: 'Selected',
    selectedForTables: 'selected',
    rub: 'rub.',
    select: 'select',
    copyToClipBoardCaption: 'Copy to clipboard',
    notFound: 'not found',
    sessionReturnClients: 'Session Return Clients',
    sessionFirstClients: 'Session First Clients',
    sessionCount: 'Session Count',
    sessionAverageTime: 'Session Average Time',
    trafficDownloaded: 'Traffic Downloaded',
    trafficUploaded: 'Traffic Uploaded',
    time: 'time',
    connectedShort: 'conn.',
    disconnectedShort: 'disconn.',
    connectedDisconnectedShort: 'Conn./Disconn.',
    start: 'Start',
    stop: 'Stop',
    withChild: 'with child',
    company: 'Company',
    allStatuses: 'All statuses',
    allCompanys: 'All companies',
    of: 'of',
    found: 'Found',
    enable: 'Enable',
    enableShort: 'Enabled',
    dayShort: 'day',
    minShort: 'min',
    hourShort: 'hour',
    secShort: 'sec',
    disabled: 'disabled',
    enabled: 'enabled',
    noTags: 'no tags',
    addTags: 'Add tags',
    frequency: 'Frequency',
    showDescription: 'show description',
    hideDescription: 'hide description',
    multiSelectDeselectLabel: 'Press enter to remove',
    multiSelectSelected: 'Selected',
    multiSelectPressToSelect: 'Press enter to select',
    cpeOne: 'AP',
    cpeSeveral: 'APs',
    wlanOne: 'WLAN',
    wlanSeveral: 'WLANs',
    modalChangeLocationsCaption: 'Change locations for the following',
    searchPlaceHolder: 'Search',
    allWlans: 'all wlans',
    infoShort: 'info',
    generatePassword: 'Generate password',
    locations: 'Locations',
    Always: 'Always',
    Auto: 'Auto',
    Mode: 'Mode',
    Off: 'Off',
    On: 'On',
    Settings: 'Settings',
    Timeout: 'Timeout',
    account: 'Account',
    active: 'Active',
    add: 'Add',
    address: 'address',
    advancedParameters: 'Advanced parameters',
    all: 'All',
    allLocations: 'All locations',
    allModels: 'All models',
    allTags: 'All tags',
    apply: 'Apply',
    applyFilters: 'Apply Filters',
    auto: 'auto',
    back: 'Back',
    baseLocation: 'Base Location',
    basicParameters: 'Basic parameters',
    cancel: 'Cancel',
    change: 'Change',
    changeLocations: 'Change locations',
    chooseFile: 'Choose File',
    clientRF: 'Client RF',
    clients: 'Clients',
    close: 'Close',
    closeModal: 'Close window',
    closeModalConfirm: 'Please confirm that you wish to close the window.\nUnsaved data will be lost.',
    confirm: 'Confirm',
    confirmDeletingText: 'Please confirm deleting',
    confirmDeletingTitle: 'Deletion Confirmation',
    copy: 'Copy',
    cpe: 'AP',
    cpes: 'APs',
    cpuLoad: 'CPU Load',
    create: 'Create',
    csvFileUUIds: 'CSV file with UUIDs',
    day: 'day',
    delete: 'Delete',
    delete_sm: 'delete',
    description: 'Description',
    download: 'Download',
    downloadAllPng: 'Download consolidated report',
    downloadCsv: 'Download .csv',
    downloadPdf: 'Download .pdf',
    downloadPng: 'Save image',
    downloadXls: 'Download .xls',
    downloadXlsx: 'Download .xlsx',
    downloadFiles: 'Download table with data',
    duration12h: '12 hours',
    durationDay: 'Day',
    durationDays: '{days} days',
    durationMonth: 'Month',
    durationWeek: 'Week',
    durationYear: 'Year',
    edit: 'edit',
    Edit: 'Edit',
    editingMode: 'Editing mode',
    email: 'Email',
    empty: 'Empty',
    filter: 'filter',
    filters: 'Filters',
    filtersCity: 'Current city',
    filtersGender: 'Gender',
    filtersHomeTown: 'City of birth',
    filtersNasId: 'NAS ID',
    filtersPageLimit: 'Number of entries',
    filterPageLimitSmall: 'Numb. of entr.',
    filtersPageOffset: 'Show starting from',
    filtersSocialNetwork: 'Social network',
    filtersSubscription: 'Subscription status',
    filtersSort: 'Sort',
    filtersVisitsAll: 'Visits',
    filtersYearOfBirth: 'Year of birth',
    feltersDateRange: 'Date range',
    filtersSubscriptionStatus: 'Subscription status',
    filtersSubscriptionAtTime: 'Subscription date',
    firstConnected: 'First connected',
    freeRAM: 'Free RAM',
    from: 'from',
    hideid: 'hide id',
    hour: 'hour',
    info: 'Info',
    insec: 'in sec.',
    interface: 'Interface',
    kbit: 'Kbit',
    lastDisconnected: 'Last disconnected',
    location: 'Location',
    mac: 'MAC',
    mbit: 'Mbit',
    memoryLoad: 'Memory Load',
    min: 'min',
    model: 'Model',
    month: 'month',
    name: 'Name',
    next: 'Next',
    no: 'No',
    noDataToDisplay: 'No data to display',
    noDataToDisplayWhenCPESelected: 'No data to display for selected AP',
    noDataToDisplayWhenCPESelectedAndTimeRange: 'There is no data to display for this AP for the selected period of time with the selected filter settings',
    noModel: 'No model',
    noTag: 'No tag',
    notActive: 'Not active',
    off: 'off',
    on: 'on',
    orderAsc: 'Ascending',
    orderDesc: 'Descending',
    password: 'Password',
    pending: 'Pending',
    port: 'port',
    readOnlyMode: 'Read only mode',
    removeAll: 'remove all',
    resetAllFilters: 'Reset all filters',
    allRoles: 'All roles',
    role: {
      admin: 'admin',
      marketer: 'marketer',
      operator: 'operator',
      hotel_employee: 'hotel representative',
      advertising: 'advertising agent'
    },
    rules: 'Rules',
    save: 'Save',
    search: 'Search',
    sec: 'sec',
    selectAll: 'select all',
    serialNumber: 'S/N',
    server: 'server',
    sessions: 'Sessions (conn./disconn.)',
    showid: 'show id',
    skip: 'Skip',
    status: 'Status',
    step1: 'Step 1',
    step2: 'Step 2',
    step3: 'Step 3',
    step4: 'Step 4',
    step5: 'Step 5',
    step6: 'Step 6',
    step7: 'Step 7',
    step8: 'Step 8',
    step9: 'Step 9',
    step10: 'Step 10',
    step11: 'Step 11',
    submit: 'Submit',
    summary: 'Summary',
    summaryConfirmation: 'Summary and confirmation',
    system: 'System',
    tags: 'Tags',
    till: 'till',
    totalClients: 'Total Clients',
    trafficTxRx: 'Traffic (rx/tx)',
    type: 'Type',
    tz: 'Time zone (UTC)',
    uniqueClients: 'Unique Clients',
    unset: 'Unset',
    updating: 'Updating',
    warning: 'Warning',
    wlan: 'WLAN',
    wlans: 'WLANs',
    yes: 'Yes',
    yesDelete: 'Yes, delete',
    hours: 'Hours',
    mo: 'Mo',
    tu: 'Tu',
    we: 'We',
    th: 'Th',
    fr: 'Fr',
    sa: 'Sa',
    su: 'Su',
    forCurrentPeriod: 'For the current period',
    forPreviousPeriod: 'For the previous period',
    differenceBetweenPeriods: 'Difference',
    differenceGrow: 'growth',
    differenceLoss: 'loss'
  },
  header: {
    typeUPDATE: 'UPDATE',
    typeDELETE: 'DELETE',
    typeCREATE: 'CREATE',
    type: 'Type',
    total: 'total',
    pending: 'pending',
    success: 'success',
    errors: 'errors',
    tooltipShowErrorsOnly: 'show errors only',
    tooltipShowAll: 'show all',
    tooltipDeleteAll: 'delete all',
    tooltipDelete: 'delete',
    current: 'Current',
    account: 'Account',
    clearListOperations: 'Clear list operations',
    create: 'create',
    delete: 'delete',
    inputIntervalPlaceholder: 'Input interval in sec',
    last: 'Last',
    logout: 'Logout',
    max: 'max',
    noCurrOperations: 'No current operations',
    off: 'off',
    operations: 'operations',
    reboot: 'reboot',
    reset: 'reset',
    sec: 'sec',
    setRefreshInterval: 'Set refresh interval',
    settings: 'Settings',
    update: 'update'
  },
  hotspot: {
    selectClass: 'Select class',
    organizationID: 'Organization ID',
    noItems: 'no items',
    realmNoAuth: 'no auth',
    enterRealmNamePlaceholder: 'Enter Realm name',
    realmNoData: 'No data to display',
    wizard1Title: 'Fill basic and network Hotspot parameters',
    wizard2Title: 'Fill advanced Hotspot parameters',
    wizard3Title: 'Fill advanced Hotspot parameters',
    wizard4Title: 'Confirm new Hotspot creation',
    wizardGeneral: 'General',
    wizardNamePlaceholder: 'Enter Hotspot 2.0 name',
    WANMetrics: 'WAN Metrics',
    addHS20: 'Add Hotspot 2.0 Profile',
    addRealm: 'Add Realm',
    additionalStep: 'Additional step required for access',
    authSubType: 'Auth subtype',
    authType: 'Auth type',
    cellular: 'Cellular',
    connectionCapabilityList: 'Connection Capability List',
    dgaf: 'Downstream Group-Addressed Forwarding (DGAF)',
    domain: 'Domain',
    domainList: 'Domain List',
    domains: 'Domains',
    eapMethod: 'EAP Method',
    emergencyServices: 'Emergency services reachable',
    group: 'Group',
    hessid: 'HESSID',
    hotspotWizard: 'Create Hotspot 2.0 Wizard',
    hs20profiles: 'Hotspot 2.0 profiles',
    internet: 'Internet',
    ipAvailability: 'IP Address Type Availability',
    language: 'Language',
    naiRealmList: 'NAI Realm List',
    network: 'Network',
    networkType: 'Network Type',
    operatingClassIndication: 'Operating Class Indication',
    operatorFriendlyNameList: 'Operator Friendly Name List',
    port: 'Port',
    protocol: 'Protocol',
    realm: 'NAI Realm',
    roaming: 'Roaming Consortium',
    roamingConsortiumList: 'Roaming Consortium List',
    status: 'Status',
    threeGPP: '3GPP Cellular Network List',
    type: 'Type',
    unauthenticatedEmergency: 'Unauthenticated emergency services accessible',
    venue: 'Venue',
    notificationHotspotUpdatedSuccessfully: 'Hotspot Updated Successfully',
    notificationHotspotCreatedSuccessfully: 'Hotspot Created Successfully',
    notificationHotspotDeletedSuccessfully: 'Hotspot Deleted Successfully',
    notificationRADIUSOperationSuccessfull: 'Operation Successful'
  },
  licenses: {
    avgValueCaption: 'Average value',
    avgValuesCaption: 'Average values',
    forTheSelectedPeriodCaption: 'for the selected period',
    licenses: 'Licenses',
    licensesAllConnected: 'Connected APs, Wi-Fi Target and Heatmaps',
    licensesStats: 'Licenses statistics',
    licensesStatsClose: 'Back to licenses',
    types: {
      heatmap: 'Heatmaps',
      cpes: 'APs',
      radar: 'Wi-Fi Target'
    },
    notificationNewLicenseSuccessfullyAdded: 'New license successfully added',
    notificationNewLicenseFileSuccessfullyAdded: 'New license file successfully added',
    notificationWrongLicenseCode: 'Wrong license code',
    notificationWrongLicenseFile: 'Wrong license file'
  },
  location: {
    deleteLocationDescriptionMsg:
      'All objects of the deleted location will go to the top-level location. For example, when deleting the location "/wimark/office", all APs of the location "/office" will go to the location "/wimark"',
    innValidatorErrorMsg: 'The INN field can only contain digits',
    ogrnValidatorErrorMsg: 'The OGRN field can only contain digits',
    kppValidatorErrorMsg: 'The KPP field can only contain digits',
    byUser: 'by user',
    someFieldsHaveErrors: 'some fields have errors',
    innCaptionForTable: 'INN',
    companyCaptionForTable: 'Company',
    phoneCaptionForTable: 'Phone',
    searchAddressCaption: 'Address search',
    searchСompanCaption: 'Company search',
    addressNotFound: 'Address not found',
    enterAddressToSearch: 'Enter the address to find coordinates on the map',
    companyNotFound: 'Company Not Found',
    enterCompanyNameToSearch: 'Enter company name for search',
    sameAsSelecteted: 'Copy settings from',
    interfaceColorsAndLogo: 'Logo and interface colors',
    mapCaption: 'Map',
    phoneRequiredErrorMsg: 'It is necessary to fill in the Phone field, because billing takes place on it',
    formal_name: 'Formal name',
    inn: 'INN',
    kpp: 'KPP',
    name: 'Name',
    ogrn: 'OGRN',
    phone: 'Phone',
    company: 'Company',
    locId: 'Location Id',
    address: 'Address',
    coords: 'Coordinates',
    coordsLat: 'lat.',
    coordsLatFull: 'Latitude',
    coordsLng: 'lng.',
    coordsLngFull: 'Longitude',
    created: 'Created',
    delete: 'Delete Location',
    deleteText:
      'By deleting this location you will delete all the descendant locations and all the users of these locations. Are you sure?',
    descendant: "You may create descendant locations, dividing them with a '/' character",
    description: 'Description',
    descriptionCaption: 'Other miscellaneous information',
    edit: 'Edit location',
    list: 'Location List',
    limits: {
      header: 'Limits',
      heatmap: 'Heatmap',
      cpe: 'AP',
      portal: 'Portal',
      radar: 'Wi-Fi Target'
    },
    manager: 'Location manager',
    managerCaption: 'If not specified, you will be assigned as location manager',
    managerShort: 'Manager',
    new: 'Add location',
    owner: 'Owner',
    path: 'Location name (path)',
    custom_styles_enable: 'Enable custom colors for location',
    custom_logo: 'Custom Logo for location',
    custom_colors: 'Custom colors',
    header: 'Header',
    sidebar: 'Sidebar',
    footer: 'Footer',
    main_block: 'Main block',
    background_color: 'Background color',
    text_color: 'Text color',
    active_menu_item_color: 'Active menu item color',
    hover_menu_item_color: 'Hover menu item color ',
    menu_item_text_color: 'Menu item text color',
    img_upload_error: 'File must be image and image size must be less 300kB',
    notificationLocationCreatedSuccessfully: 'Location Created Successfully',
    notificationLocationUpdatedSuccessfully: 'Location Updated Successfully',
    notificationLocationDeletedSuccessfully: 'Location Deleted Successfully'
  },
  login: {
    inactivityLogoutInfoModalHeader: 'An automatic logout has occurred',
    inactivityLogoutInfoModalText: 'An automatic logout occurred because the user has not been active for {minutes} min.',
    customPasswordValidation: 'The password field may contain only the following special character: _ - # $ & ^ * @ !',
    login: 'Login',
    loginHeader: 'Login',
    password: 'Password',
    signHeader: 'Sign In to your account',
    support: 'support',
    username: 'Username',
    resetPassword: 'Reset password',
    resetPasswordHeader: 'Reset password',
    resetPasswordHeaderCaption: 'Enter username to password reset',
    resetPasswordPageSendButton: 'Send password reset link',
    resetPasswordPageBackButton: 'Back',
    resetPasswordPageSuccessMessage: 'Password reset link sent to your e-mail',
    resetPasswordNewPasswordHeader: 'New password',
    resetPasswordNewPasswordCaption: 'Enter the new password',
    resetPasswordNewPasswordSubmitButton: 'Submit',
    resetPasswordNewPassword: 'Enter the new password.',
    resetPasswordNewPasswordConfirm: 'Enter the new password again.',
    resetPasswordNewPasswordConfirmationError: 'The passwords are different.',
    resetPasswordNewPasswordSuccessMessage: 'New password has been set successfully.',
    resetPasswordNewPasswordTokenExpiredMessage: 'Token expired. Request password reset again.',
    passwordSuccessfullySet: 'Password successfully set',
    goToLoginButton: 'Go to login page',
    newPasswordField: 'New password',
    newPasswordConfirmationField: 'New password confirmation'
    // passwordValidationMessage: 'The password field may contain only the following special character: _ - # $ & ^ * @ !'
  },
  maps: {
    highlightCPEsInfoCaption: 'Highlight AP info',
    highlightCPEsInfoHint: 'Highlights AP information on the map for better readability. Allows you to improve visibility on black and white maps',
    heatmapLegendHeader: 'Legend (visitors)',
    noData: 'no data',
    sortTypeName: 'By name',
    sortTypeCreated: 'By created',
    sortTypeUpdated: 'By updated',
    filteringNightClientsModalCaption: 'Filtering visitors',
    filteringNightClientsModalText:
      'Visitor filtering mode allows you to filter out visitors seen outside of business hours of the facility. To be able to turn it on, you need to set three time settings in the map editing window (start, end, time zone)',
    filteringNightClientsCaption: 'Visit. filter.',
    workTimeTimezoneMultiselectPlaceholder: 'Choose timezone',
    workTimeStartPlaceholder: 'HH:MM format',
    workTimeStopPlaceholder: 'HH:MM format',
    workTimeCaption: 'Opening times settings',
    workTimeHint:
      'Allows you to set the opening hours of the object for better filtering of visitors. To work, you need to fill in all three fields',
    workTimeStart: 'Start time',
    workTimeStop: 'Stop time',
    workTimeTimezone: 'Timezone',
    workTimeStartHint: 'Start time in HH:MM format (for example 10:00)',
    workTimeStopHint: 'End time in HH:MM format (for example 21:00)',
    noCpeConnectedUsersToDisplay: 'There are no connected clients',
    cpeUsers: 'сonnected clients',
    cpeInfoAboutUsers: 'Clients information',
    cpeStatisticMemory: 'Mem.',
    cpeStatisticCPULoad: 'CPU',
    cpeStatisticUsers: 'Clients',
    Clients: 'Visitors',
    Created: 'Created',
    byCPEs: 'by APs',
    close: 'close',
    connected: 'connected',
    editScaleMode: 'edit scale mode',
    found: 'Found visitors',
    foundOne: 'Found',
    heatmap: 'heatmap',
    lastSeen: 'Last seen',
    lastUpdated: 'Last updated',
    layersControl: 'Layers Control',
    mapActions: 'Map actions',
    passerby: 'passerby',
    planingMode: 'planing mode',
    refresh: 'refresh',
    showCPEs: 'Show APs',
    showClients: 'Show visitors',
    showZones: 'Show zones',
    showMode: 'show mode',
    showPath: 'show path',
    timeline: 'timeline',
    timelineTitle: 'Visitors’ Timeline',
    traffic: 'traffic',
    user: 'visitor',
    users: 'visitors',
    vendor: 'Vendor',
    playButton: 'Play',
    stopButton: 'Stop',
    onlyOnePointInPath: 'This is only one point in the path.',
    cpeShowMore: 'more info',
    notFound: 'not found',
    lastConnected: 'Last connected',
    cpe: 'AP',
    zoneEditMode: 'edit zones',
    zonesAddNewName: 'Name for new zone',
    zoneNameCaption: 'Zone name',
    zoneName: 'Zone name',
    zoneCreating: 'New Zone',
    error: 'Error',
    zoneIntersectionError: 'Zones should not intersects',
    zoneNameCaptionInZoneTooltip: 'Zone',
    zoneClientsCounterCaptionInZoneTooltip: 'Visitors',
    notificationMapCreatedSuccessfully: 'Map Created Successfully',
    notificationMapDeletedSuccessfully: 'Map Deleted Successfully',
    notificationMapCPEpositionupdated: 'AP position updated',
    notificationMapZonesUpdated: 'Zones updated',
    notificationMapScaleUpdated: 'Map scale updated',
    addMapButton: 'Add Map',
    allLocations: 'All locations',
    mapsList: 'Maps List',
    availableCPEs: 'Available APs',
    noAvailableCPEs: 'No Available APs',
    cpeNamePlaceHolder: 'AP name',
    saveAndHandleClients: 'Save and handle visitors',
    changedCPEsModalTitle: 'There are unsaved changes',
    changedCPEsModalText: 'There are unsaved AP changes. Are you sure you want to close planing mode',
    closeAndShowOtherMaps: 'close and show other maps',
    closeCurrentMap: 'close current map',
    cpeControlTitle: 'AP List',
    scaleModeDistance: 'Distance',
    scaleModeSetTwoPoints: 'First set two points on the map',
    scaleModeTypeDistance: 'Now type distance in real units',
    scaleModePlaceholder: 'Enter distance',
    scaleModeSave: 'Save',
    scaleModeDangerText: 'All previous visitors data will be not available',
    scaleModeOf: 'of',
    enterZoneNamePlaceholder: 'Enter zone name',
    newZoneNameError: 'Zone name must be greater than 0 and less than 22 characters',
    dynamicsTitle: 'Dynamics',
    countersTitle: 'Counters',
    statisticsTitle: 'Statistics',
    passerbyHint: 'Visitors passing near the AP',
    connectedHint: 'Wi-Fi connected visitors',
    trafficHint: 'Traffic data',
    clientCoordsButton: 'visitors',
    clientCoordsHint: 'Visitors last coordinates',
    mapsWizardTitle: 'Create Map Wizard',
    mapsWizardStep1Title: 'Choose map image file',
    mapsWizardStep2Title: 'Fill required fields',
    mapsWizardStep3Title: 'Minimal map settings',
    mapsWizardWidth: 'width',
    mapsWizardHeight: 'height',
    mapsWizardPx: 'px',
    mapsWizardFillMapParameters: 'Fill Map Parameters',
    enterMapNamePlaceholder: 'Enter Map Name',
    locationPlaceholder: 'Location',
    saveAndCreateButton: 'Save and create',
    setMapUnitsPerPixel: 'Set Map Units Per Pixel',
    setMapUnitsPerPixelNew: 'Set the scale',
    deleteMapModalTitle: 'Delete Map',
    deleteMapModalText: 'Are you sure to delete map',
    bublePasserbyClients: 'passerby visitors',
    bubleConnectedClients: 'connected visitors',
    bubbleRX: 'RX',
    bubbleTX: 'TX',
    cpePopUpID: 'ID',
    cpePopUpModel: 'Model',
    cpePopUpIP: 'IP',
    cpePopUpMAC: 'MAC',
    cpePopUpWifiRadar: 'Wi-fi Target',
    timelinePasserbyTitle: 'Passerbys Timeline',
    timelinePasserbyTooltipCaption: 'Passerbys',
    timelineConnectedTitle: 'Connected Timeline',
    timelineConnectedTooltipCaption: 'Connected',
    timelineTrafficTitle: 'Traffic Timeline',
    timelineTrafficTooltipCaption: 'Traffic',
    passerbyCounterTitle: 'Passerby',
    connectedCounterTitle: 'Connected',
    trafficCounterTitle: 'Traffic',
    editMapModalTitle: 'Edit Map',
    editMapModalOpenButton: 'Edit Map',
    tableOutOf: 'maps of',
    serchByClientMAC: 'Visitor search by MAC',
    zonesForTable: 'Zones',
    cpesTooltipForTable: 'APs',
    zonesTooltipForTable: 'Zones',
    noCpeData: 'No data about AP',
    noCpeDataFull: 'No AP data. Perhaps a AP from a location inaccessible to you',
    availableCPEHint: 'APs in the same location as the map and which are not yet on the map'
  },
  marketing: {
    avgLoadingDataTimeMsg: 'The average waiting time for data to load with the selected time interval is',
    infoAboutWifiTargetBegin: 'There are',
    infoAboutWifiTargetEnd: 'with selected filtering conditions with enabled Wi-Fi Target',
    visits: 'Visits',
    columnPercentsForDownloads: 'Percents',
    columnNameForDownloads: 'Name',
    columnValueForDownloads: 'Value',
    dateForDownloads: 'Date',
    allVisitors: 'All',
    showDescription: 'show description',
    hideDescription: 'hide description',
    selectLocationPlaceholder: 'Select Location',
    selectCompanyPlaceholder: 'Select Company',
    datePickerCaption: 'Date range',
    workTimeFilterEnabled: 'enabled',
    workTimeTimezoneNoSelectError: 'The Timezone field is required',
    workTimeTimezoneMultiselectPlaceholder: 'Choose timezone',
    workTimeStartPlaceholder: 'HH:MM format',
    workTimeStopPlaceholder: 'HH:MM format',
    workTimeBlockCaption: 'Filtering by worktime',
    workTimeHint: 'Allows you to set the opening hours for filtering visitors',
    workTimeStart: 'Start work time',
    workTimeStop: 'End work time',
    workTimeTimezone: 'Timezone',
    workTimeStartHint: 'Start time in HH:MM format (for example 10:00)',
    workTimeStopHint: 'End time in HH:MM format (for example 21:00)',
    // createExportButtonInfo: 'Allows you to go to the Wi-Fi Target Segment export section and start creating a new export using the selected APs, Location and Date Range',
    createExportButtonInfo:
      'Allows you to go to the Wi-Fi Target Segment export section and start creating a new segment using the selected APs, Location and Date Range',
    // createExportButtonCaption: 'Create export',
    createExportButtonCaption: 'Create segment',
    allRadars: 'All Wi-Fi Target',
    radar: 'Wi-Fi Target',
    all: 'All',
    allCpes: 'All APs',
    allCpesSelected: 'All APs selected',
    avgDwellTime: 'Avg. dwell time',
    avgVisitFreq: 'Avg. visit frequency',
    byDefault: 'by default',
    cVisits: 'Visits',
    cVisitsVisitors: 'Visitors',
    count: 'Count',
    counters: 'Counters',
    cpes: 'APs',
    date: 'Date',
    deleteBenchmark: 'delete benchmark',
    downloadData: 'download data',
    exitWithoutPurchase: 'Exit without purchase',
    fittingRoom: 'Fitting Room',
    footTraffic: 'Foot traffic',
    h: 'h.',
    heatmap: 'Heatmap',
    housewares: 'Housewares',
    location: 'Location',
    mall: 'Mall',
    menDept: 'Men Dept',
    min: 'min.',
    new: 'New',
    percent: 'Percent',
    rVbyF: 'Repeat visitors by visit frequency',
    repeat: 'Repeat',
    repeatVisitors: 'Repeat visitors',
    report: 'Report',
    sales: 'Sales',
    sec: 'sec.',
    submit: 'Refresh',
    timeframe: 'Timeframe',
    trafficByZone: 'Traffic by zone',
    uniqueVisitors: 'Unique visitors',
    vendor: 'Vendor',
    vendors: 'Vendors',
    venue: 'Venue',
    visitors: 'Visitors',
    visits1: '1 visits',
    visits10: '>= 10 visits',
    visits2: '2 visits',
    visits34: '3-4 visits',
    visits59: '5-9 visits',
    withS: 'Stopped near the counter',
    withoutS: 'Exited without purchase',
    womenDept: 'Women Dept',
    zone: 'Zone'
  },
  misc: {
    current: 'Current',
    items: 'Items',
    none: 'None',
    no_errors: 'No errors',
    whitelist: 'Whitelist',
    blacklist: 'Blacklist',
    no_data: 'No data',
    no_name: 'No name',
    $gt: 'Greater than',
    $lt: 'Less than',
    $eq: 'Equal'
  },
  monitor: {
    addRule: 'Add Rule',
    analyzerInfoMessage: 'Analyzer will work only if background scanning is on on the interface',
    clientsEvents: 'Clients Events',
    cpesEvents: 'APs Events',
    cpesState: 'APs state',
    cpeState: 'AP state',
    cpuLoad: 'CPU Load',
    createRule: 'Create Rule',
    data: 'Data',
    enterRuleName: 'Enter Rule name',
    error: 'ERROR',
    freeRAM: 'Free RAM',
    info: 'INFO',
    lastScanDate: 'Last scan in ',
    range: 'Range',
    ruleConfirmDeleting: 'Confirm deleting',
    ruleDelete: 'Delete Rule',
    ruleDescription:
      'Point out the range of the rule (red line).  Once exceeding the border  one will get a notification.',
    ruleEvents: 'Rule Events',
    ruleParameters: 'Fill required Rule parameters',
    ruleRange: 'Rule range',
    ruleScript: 'Post Script',
    ruleType: 'Rule Type',
    ruleWizard: 'Create Rule Wizard',
    rules: 'Rules',
    script: 'Script',
    sessionConnected: 'Connected',
    sessionDisconnected: 'Disconnected',
    sessions: 'Sessions',
    systemEvents: 'System Events',
    warning: 'WARNING',
    wifiAnalyzer: 'Wi-Fi analyzer',
    notificationRuleCopyingScriptWasSuccessful: 'Copying script was successful!',
    notificationRuleCopyingScriptWasUnsuccessful: 'Copying script was unsuccessful!',
    notificationRuleCreatedSuccessfully: 'Rule Created Successfully'
  },
  pageprofile: {
    'themeType-default': 'default',
    'themeType-minimalistic': 'minimalistic',
    'themeType-default-beeline': 'default-beeline',
    'themeType-minimalistic-beeline': 'minimalistic-beeline',
    themeTypeMinimalisticOrNot: 'Theme view',
    add: 'Add Page',
    back: 'Background',
    background: 'Background image',
    backgroundInPreview: 'Background',
    contrast: 'Contrast',
    edit: 'Edit Page',
    openPage: 'Open Page',
    enterAgreement: 'Please paste agreement text below (in plaintext)',
    facebook: 'Facebook',
    free: 'Free',
    hasAgreement: 'Has User Agreement',
    hasSupport: 'Has Support Contacts',
    hotel_voucher: 'Hotel vouchers',
    instagram: 'Instagram',
    interface: 'Interface',
    isExternalUrl: 'External resource',
    light: 'Light',
    logo_footer: 'Footer Logo',
    logo: 'Logo',
    main_color: 'Main color',
    new: 'New Page',
    pages: 'Pages',
    portalurl: 'Portal URL',
    portalurlCaption: 'Use with your domain on the Guest Control settings page',
    portalurlID: 'Portal URL ID',
    preview: 'Portal Preview',
    saveurl: 'Save this url to use in redirect rule',
    sponsor: 'Sponsor',
    staff: 'Staff',
    support: 'Contacts will be displayed if user encounters an error on Portal',
    theme: 'Color Theme',
    themeType: 'Theme Type',
    title: 'Page title',
    ToS: 'Terms of Service',
    urlToExternalTOS: 'URL to external resource with your ToS file',
    urlToFileTOS: 'Path to your ToS file',
    vk: 'VK',
    voucher: 'Paid Internet with vouchers',
    subscription: 'Internet with subscription',
    warnUsage: 'Please note that if you delete a Page in use by a Portal, {0}.',
    warnUsageAccent: 'such Portal will not work properly',
    imageUploadedNotification:
      'Images changes will be saved only after clicking the Save button at the bottom of that window.',
    imagesBlock: 'Images',
    authorizationButton: 'Authorization window',
    changeColorAlert: 'The theme in the preview window will change after saving the settings!',
    schedule: 'Schedule',
    dayOfWeek: 'Day of week',
    timeOfDay: 'Time of day',
    noSchedule: 'No schedule',
    timeRange: 'Schedule',
    scheduleAShow: 'Schedule a show',
    deleteSchedule: 'Delete schedule',
    scheduleInfoMessage: 'To save the schedule settings, click the Save button',
    hoursforShowing: 'Hours for showing',
    dateRangeforShowing: 'Date range for showing',
    scheduleEmpty: 'No scheduled pages for this date',
    showHours: 'Show hours',
    showHoursAllDay: 'all day',
    showHoursNotAllDay: 'not all day',
    pageClonedSuccessfully: 'Page Cloned Successfully',
    pageCreatedSuccessfully: 'Page Created Successfully',
    pageUpdatedSuccessfully: 'Page Updated Successfully',
    pageDeletedSuccessfully: 'Page Deleted Successfully',
    scheduleClonedSuccessfully: 'Schedule Cloned Successfully',
    scheduleCreatedSuccessfully: 'Schedule Created Successfully',
    scheduleUpdatedSuccessfully: 'Schedule Updated Successfully',
    scheduleDeletedSuccessfully: 'Schedule Deleted Successfully',
    pageScheduleStatusTextForTooltipAciveAndDayMatch: 'Showing',
    pageScheduleStatusTextForTooltipAciveAndDayNotMatch:
      'On the selected day, the showing is not performed due to the settings of the day of the week for showing'
  },
  paymentSystems: {
    add: 'Add Payment System',
    directions: 'Instruction',
    edit: 'Edit Payment System',
    hashKey: 'Hash Key',
    head: 'Guide header',
    identity: 'Internal ID',
    identityCaption: 'Currently the following ID are available: Humo, MegafonLife, Terminal',
    image: 'Image',
    merchant: 'Merchant',
    merchantInfo:
      'This is a unique number transmitted to the Payment system provider. Required when using the API for replenishing the virtual balance of paid Wi-Fi',
    merchantCaption: 'ID should be unique',
    name: 'Name',
    new: 'New Payment System',
    preview: 'Preview',
    profile: 'Profile',
    templateHash: 'Template Hash',
    templateURL: 'Template URL',
    title: 'Payment Systems',
    tranUnique: 'Check transaction ID for uniqueness',
    type: {
      ext: 'External gateway',
      ext_url: 'External gateway with generating URL',
      guide: 'Guide',
      type: 'Type'
    },
    warnUsage: 'Please note that if you delete a Payment System in use by a Portal, {0}.',
    warnUsageAccent: 'such Portal will not work properly',
    notificationPaymentSystemCreatedSuccessfully: 'Payment System Created Successfully',
    notificationPaymentSystemUpdatedSuccessfully: 'Payment System Updated Successfully',
    notificationPaymentSystemDeletedSuccessfully: 'Payment System Deleted Successfully'
  },
  portal: {
    gatewaySettings: {
      // hintForPOSTMethod: 'You can use possible template variables in URL and Payload:\n {PHONE} - client identifier, \n {MESSAGE} - message from the field "OTP text" of the current profile, \n {CODE} - one-time password',
      // hintForGETMethod: 'You can use possible template variables in URL:\n {PHONE} - client identifier,\n {MESSAGE} - message from the field "OTP text" of the current profile,\n {CODE} - one-time password',
      payload_textarea: 'Payload',
      blockHeader: 'Send gateway',
      method: 'Method',
      methodNoSelected: 'No selected',
      url: 'URL',
      content_type: 'Content type',
      headers: 'Headers',
      params: 'Parameters',
      add: 'Add',
      delete: 'Delete',
      clear: 'Очистить',
      header: 'Header name',
      param: 'Parameter name',
      value: 'Value',
      hide: 'Hide',
      edit: 'Edit',
      save: 'Apply',
      cancel: 'Cancel',
      noHeaders: 'No headers',
      noParams: 'No parameters'
    },
    thereAreUsersInList: 'There are users in list',
    noUsersInList: 'No users in list',
    accessList: 'Access list',
    add: 'Add Profile',
    ads_to_watch: 'Number of Ads or Polls to Watch',
    adsFollow: 'Allow internet and redirect to URL on user click',
    adsRotation: 'Show ads or polls in random order',
    allPrefix: 'Any prefix',
    auth: {
      attachedURL: 'Attached URL',
      groupURL: 'Group URL',
      message: 'Post message',
      redirectURL: 'Redirect URL'
    },
    authGroup: 'Auth and subscribe',
    authOnly: 'Auth only',
    authPost: 'Auth and post',
    auth_timeout: 'Authorization Timeout',
    authentication: 'Authentication',
    authorization: 'Authorization',
    blackList: 'Black list',
    block_after: 'Timeout for Online before Block (in sec.)',
    block_afterCaption: 'Must be completed if field below is completed',
    block_expire: 'Timeout for Block Expiration (in sec.)',
    block_expireCaption: 'Must be completed if field above is completed',
    code: 'Country code',
    download_limit: 'Download Limit (in kb.)',
    duration3Days: '3 Days',
    edit: 'Edit Profile',
    errorMessage: 'Validation Error Message',
    errorMessageCaption: 'Error message to be displayed on validation error',
    max_sessions: 'Max Sessions',
    mustContainCode: 'Must contain: {CODE}',
    nasId: 'NAS ID',
    new: 'New Profile',
    notification: 'Session end time notification',
    notificationText: 'Notification text',
    notificationTime: 'Time until the end of the session (sec.)',
    otp: 'Length of One-Time Password',
    otpCodeText: 'SMS text for OTP',
    paginationAdsOutOf: 'Ads out of',
    paginationLoad: 'Load',
    paginationOf: 'items of',
    paginationPagesOutOf: 'Pages out of',
    paginationPaymentSystemsOutOf: 'Payment Systems out of',
    paginationProfilesOutOf: 'Profiles out of',
    paginationTariffsOutOf: 'Tariffs out of',
    paginationUserAccountsOutOf: 'Accounts out of',
    paginationVouchersOutOf: 'Vouchers out of',
    paymentSystems: 'Payment Systems',
    pollquestionValidationError: 'The following symbols are not allowed: $ (dollar), . (dot)',
    portalprofiles: 'Profiles',
    prefix: 'Prefix',
    redirect: 'Redirect to Landing Page',
    regex: 'Validation Regexp',
    regexCaption: 'Regular expression used for user ID validation on the Portal',
    remember: 'Remember time (in sec)',
    rememberCaption: 'Time period for which client authentication is remembered and not requested repeatedly',
    rememberQuick: 'Quick options:',
    skipFinalPage: 'Skip final page',
    smsCodeValidationError: 'Text must contain {CODE}',
    socialURLValidationError: 'URL must contain vk.com, facebook.com or instagram.com',
    timeout: 'One Session Timeout (in sec.)',
    tzCaption: 'Examples: 3 (Moscow), -4 (New-York), 2 (Barcelona)',
    voucherBuyHeader: 'Voucher purchase header',
    voucherBuyInfo: 'Voucher purchase information',
    voucherCodeText: 'SMS text for voucher',
    whiteList: 'White list',
    transferable: 'Transferable',
    transferableInfo: 'Possibility to split a session into several within one day'
  },
  portalBirthdays: {
    users: 'Users',
    birthdays: 'Birthdays',
    selectedDate: 'Current selected date'
  },
  portalProfiles: {
    authenTypecallfront: 'Outgoing call',
    authenTypecallback: 'Free call',
    authenTypecserpass: 'Personal code',
    authenTypeesia: 'Esia',
    authenTypeemail: 'Email',
    authenTypesms: 'Sms',
    countryCode: 'Country code',
    phonePrefixOptional: 'Prefix (optional)',
    lineCabinetAddressCaption: 'Line Cabinet for subscription management URL',
    lineCabinetCaption: 'Line Cabinet for subscription management',
    lineCabinetEnableSwitcher: 'Enable Line Cabinet for subscription management',
    socialAuthRequiredFieldsNotForgetToFillNotificationMsg: "don't forget to fill in the required fields",
    generalSettingsForAllAuthZApplyButton: 'Apply',
    generalSettingsForAllAuthZInfoMsg:
      'Allows you to apply some settings to all enabled authorizations at once. After applying the changes, you need to save the Profile by clicking the "Save" button',
    generalSettingsForAllAuthZCaption: 'General authorization settings (session settings)',
    generalSettingsForAllAuthNApplyButton: 'Apply',
    generalSettingsForAllAuthNInfoMsg:
      'Allows you to apply some settings to all enabled authentications at once. After applying the changes, you need to save the Profile by clicking the "Save" button',
    generalSettingsForAllAuthNCaption: 'General authentications settings',
    checkAuthNsWhenAuthenSkipIsFalseErrorText:
      'When the "Skip Authentication" mode is disabled, at least one Authentication must be enabled',
    authnSkipCaption: 'Skip Authentication',
    authzDefaultCaption: 'Default Authorization',
    callbackInfo: '',
    callfrontInfo: 'Authentication using an outgoing call from the user',
    condition: 'Condition',
    access: 'Access',
    otpCodeText: 'OTP text',
    sessionSettings: 'Session settings',
    advertismentSettings: 'Advertisement or poll settings',
    vouchersSettings: 'Vouchers settings',
    nasForTable: 'NAS',
    customTimeInterval: 'Custom',
    chooseCustomInterval: 'Choose custom time period',
    profileEdit: 'Profile Edit',
    noDataToDisplay: 'no data',
    noDescription: 'no description',
    noCPEs: 'no APs',
    noWLANs: 'no WLANs',
    noNAS: 'no NAS',
    noAuthentication: 'no Authentications',
    noAuthorization: 'no Authorizations',
    settingsForAuthZandAuthN: 'Settings',
    cutDownButton: ' Hide ',
    showMacSettingsView: 'Show instruction when connecting private MAC addresses',
    conditionInfo: 'Profile works for specified parameters',
    accessInfo: 'To whom the profile is available or inaccessible',
    authenInfo: 'Authentication (login) profile method on the Authorization Portal. Up to 5 authentications available.',
    otpInfo: 'OTP message text. Must contain {CODE} ',
    emailInfo: 'Authentication using e-mail. OTP code will be sent to the mail specified by the client.',
    userpassInfo: 'Authentication using a previously obtained code. Available only to users from the Access List.',
    smsInfo: 'Authentication using SMS. OTP code will be sent to the phone specified by the user. ',
    esiaInfo: 'Authentication using ESIA',
    tosInfo: 'Show user Terms of Service and ask for their consent to them',
    authInfo: 'Authorization method on the Authorization Portal. Up to 5 authorizations available.',
    freeInfo: 'Free Internet Access',
    staffInfo: 'Free Internet access for staff',
    voucherInfo: 'Access to the Internet after entering the voucher code',
    sponsorInfo: 'Free Internet Access from Sponsor',
    facebookInfo: 'Free access to the Internet after logging in via Facebook',
    instagramInfo: 'Free access to the Internet after authorization through Instagram',
    skipInfo: 'Free Internet access without authorization',
    subscriptionInfo: 'Access to the Internet after subscribe',
    hotel_voucherInfo: 'Access to the Internet after entering the voucher code',
    vkInfo: 'Free access to the Internet after authorization through VK',
    validatorErrors: 'There are validation errors. Check fields and fields in hidden categories.',
    sameAsForAuthN: 'Same settings as',
    sameAsForAuthZ: 'Same settings as',
    notificationProfileCreatedSuccessfully: 'Profile Created Successfully',
    notificationProfileUpdatedSuccessfully: 'Profile Updated Successfully',
    notificationProfileDeletedSuccessfully: 'Profile Deleted Successfully'
  },
  portalPreview: {
    fillData: 'Please fill in the following fields',
    params: 'Preview params',
    portalLink: 'Link to portal page',
    preview: 'Preview',
    default: 'Default',
    minimalistic: 'Minimalistic'
  },
  portalSessions: {
    authZTypesubscription: 'Subscription',
    oneOrMoreDateTimeFiltersAreEnabled: 'one or more filters are enabled',
    dateTimeFiltersCaption: 'Date and time filters',
    creationSessionForTable: 'Session creation time',
    expirationSessionForTable: 'Session expiration time',
    cpeTableCaption: 'AP',
    wlanTableCaption: 'WLAN',
    socialNetworksIdTableEmail: 'Email',
    socialNetworksIdTableBirthday: 'Birthday',
    socialNetworksIdTableCity: 'City',
    socialNetworksIdTableCaption: 'id',
    socialNetworksNameTableCaption: 'Name',
    socialNetworksFirstNameTableCaption: 'First Name',
    socialNetworksSecondNameTableCaption: 'Second Name',
    authZTimeBeginTableCaption: 'Authorization begin time',
    authZTimeEndTableCaption: 'Authorization end time',
    authZTypenone: '',
    authZTypenotSelect: 'All',
    authZTypefree: 'Free',
    authZTypesponsor: 'Sponsor',
    authZTypetype: 'Type',
    authZTypevk: 'VK',
    authZTypevoucher: 'Voucher',
    authZTypefacebook: 'Facebook',
    authZTypefb: 'Facebook',
    authZTypehotel_voucher: 'Hotel Voucher',
    authZTypeinstagram: 'Instagram',
    authZTypeskip: 'Skip',
    authZTypestaff: 'Staff',
    authZTypeunset: 'Unset',
    authZTypeTableCaption: 'Authorization type',
    authZStatenone: 'No authorization',
    authZStatechecked: 'Authorization passed',
    authZStateunset: 'All',
    authZStateTableCaption: 'Authorization state',
    sessionCreationDate: 'Session creation time',
    sessionCreationExpirationDate: 'Session creation time / Session expiration time',
    sessionIsDelitedunset: 'All',
    sessionIsDelitedtrue: 'Delited',
    sessionIsDelitedfalse: 'Not delited',
    sessionIsDelitedTitleCaption: 'Is session deleted',
    authenTypeunset: 'All',
    authenTypecallfront: 'Outgoing call',
    authenTypecallback: 'Free call',
    authenTypecserpass: 'Personal code',
    authenTypeesia: 'Esia',
    authenTypeemail: 'Email',
    authenTypesms: 'Sms',
    authenTypeTableCaption: 'Authentication type',
    authenStateunset: 'All',
    authenStatesent: 'Code entry or call pending',
    authenStateneed: 'No Authentication',
    authenStatechecked: 'Authentication passed',
    authenStateTableCaption: 'Authentication state',
    tabeleViewTypeCaption: 'Displayed data type',
    tableViewTypestandardView: 'Standard',
    tableViewTypesocialNetworksView: 'Social Networks:',
    tableViewTypeauthenticationView: 'Authentication',
    tableViewTypeauthorizationView: 'Authorization',
    tableViewTypesubscriptionsView: 'Subscriptions',
    identityTableCaption: 'Identifier',
    stateTypeauthenticate: 'Authentication',
    stateTypeauthorize: 'Authorization',
    stateTypeadvertise: 'Advertise or Poll',
    stateTypepass: 'Pass to Internet',
    stateTypeunset: 'All',
    stateTableCaption: 'Session state',
    isDeletedForDownloadsYes: 'Yes',
    isDeletedForDownloadsNo: 'No',
    isDeletedForDownloadsForTableCaption: 'Deleted',
    undoDeleteSessionModalUndoButton: 'Undo deleting',
    undoDeleteSessionModalHeader: 'Undo deleting the session',
    undoDeleteSessionModalConfirm: 'Are you confirming the undo deletion of the session',
    deleteSessionButtonCaption: 'Delete session',
    undoDeleteSessionButtonCaption: 'Undo deleting session',
    deleteSessionModalHeader: 'Deleting the session',
    deleteSessionModalConfirm: 'Are you confirming the deletion of the session',
    costForTableInfo: 'The cost is indicated for sessions with the authentication type of SMS',
    costForTable: 'SMS cost',
    allCpes: 'All APs',
    allWlans: 'All WLANs',
    browser: 'Browser',
    cpe: 'AP',
    create_at: 'Date',
    create: 'Date',
    getSessions: "Show {num} user's sessions",
    ip: 'IP',
    mac: 'MAC',
    nas_id: 'NAS ID',
    os: 'OS',
    osVersion: 'OS version',
    profile: 'Profile',
    sessions: 'Sessions',
    title: 'User sessions',
    wlan: 'WLAN',
    ua: {
      desktop: 'Desktop',
      mobile: 'Mobile',
      tablet: 'Tablet',
      bot: 'Bot'
    }
  },
  portalAccessServers: {
    validatorErrors: 'There are validation errors. Check fields',
    notificationPortalAccessServerUpdatedSuccessfully: 'Access Server Updated Successfully',
    notificationPortalAccessServerCreatedSuccessfully: 'Access Server Created Successfully',
    notificationPortalAccessServerDeletedSuccessfully: 'Access Server Deleted Successfully',
    preAuthVsaForTable: 'Pre Auth Attrib.',
    postAuthVsaForTable: 'Post Auth Attrib.',
    coaVsaForTable: 'CoA Attrib.',
    modalLocationFieldPlaceholder: 'Location',
    modalPostAuthVsaFieldPlaceholder: 'Post Auth Attributes',
    modalPreAuthVsaFieldPlaceholder: 'Pre Auth Attributes',
    modalExternalIPFieldPlaceholder: 'External IP',
    modalCoaVsaFieldPlaceholder: 'CoA Attributes',
    modalCoaSecretFieldPlaceholder: 'CoA Secret',
    modalCoaPortFieldPlaceholder: 'CoA Port (3799 by default)',
    modalPasswordFieldPlaceholder: 'Password',
    modalLoginFieldPlaceholder: 'Login',
    modalNasIpFieldPlaceholder: 'NAS IP address',
    modalNasIdFieldPlaceholder: 'NAS ID',
    modalDescriptionFieldPlaceholder: 'Description',
    modalNameFieldPlacheolder: 'Access Server Name',
    modalUpdateAccessServerHeader: 'Edit Access Server',
    modalCreateAccessServerHeader: 'Add Access Server',
    createAccessServerButtonCaption: 'Add Access Server',
    noForTable: 'no',
    tableCaption: 'Access Servers',
    bson: 'bson',
    coaPort: 'CoA Port',
    coaSecret: 'CoA Secret',
    coaVsa: 'CoA Attributes',
    description: 'Description',
    externalIP: 'External IP',
    location: 'Location',
    login: 'Login',
    macAuth: 'MAC Auth',
    name: 'Name',
    nasId: 'NAS ID',
    nasIP: 'NAS IP',
    password: 'Password',
    preAuthVsa: 'Pre Auth Attributes',
    postAuthVsa: 'Post Auth Attributes',
    enable: 'Enabled',
    enableForTable: 'enable',
    disableForTable: 'disable',
    type: 'Type'
  },
  portalStats: {
    smsCostInfoMsg:
      'This metric is independent of the Location or Profile selection filters. When you select anything in these filters, they are ignored for this metric',
    historyData: 'Historical data',
    OS: 'OS',
    accounts: 'Accounts',
    allAccounts: 'All accounts',
    allProfiles: 'All profiles',
    allLocations: 'All locations',
    allTariffs: 'All tariffs',
    auth: {
      free: 'Free',
      sponsor: 'Sponsor',
      type: 'Type',
      vk: 'VK',
      voucher: 'Voucher',
      facebook: 'Facebook',
      fb: 'Facebook',
      hotel_voucher: 'Hotel Voucher',
      instagram: 'Instagram',
      skip: 'Skip',
      staff: 'Staff',
      unset: 'Unset'
    },
    authFull: 'Authorization type',
    authen: {
      callfront: 'Outgoing call',
      userpass: 'Personal Code',
      email: 'E-mail',
      sms: 'SMS',
      type: 'Type'
    },
    authenFull: 'Authentication type',
    average: 'Average',
    browser: 'Browsers',
    browserFull: 'Browsers',
    desktop: 'Desktop',
    locale: {
      en: 'English',
      locale: 'Locale',
      ru: 'Russian',
      tg: 'Tajik'
    },
    mobile: 'Mobile',
    newUsers: 'New users',
    newAndRepeatUsers: 'New and repeat users',
    notNewUsers: 'Repeat users',
    os: 'Operating Systems',
    other: 'Other',
    profiles: 'Profiles',
    range: 'Range',
    selectAccount: 'Select account',
    selectAccountPlaceholder: 'Select account',
    selectAds: 'Select ads',
    selectPage: 'Select page',
    selectProfile: 'Select profile',
    selectLocation: 'Select location',
    selectLocationWithChild: 'With child',
    profileSelection: 'Profiles',
    selectRange: 'Select period',
    selectRules: 'Select rules',
    selectTariff: 'Select tariff',
    selectTheme: 'Select theme',
    selectWlan: 'Select WLAN',
    selectCpe: 'Select AP',
    stats: 'Statistics',
    tariffs: 'Tariffs',
    totalConnections: 'Total connections',
    type: 'Type',
    typeFull: 'Device type',
    uniqClients: 'Unique clients',
    users: {
      new: 'New users',
      repeat: 'Repeat users',
      type: 'Users'
    },
    usersForTable: {
      new: 'New',
      repeat: 'Repeat',
      type: 'Users'
    },
    usersFull: 'Users',
    vendors: 'Vendors',
    vendorsForTable: 'Vendors',
    vendorsFull: 'Vendors',
    vouchers: 'Vouchers',
    vouchersAmount: 'Total vouchers amount',
    realTimeData: 'Real time data',
    nonRealTimeData: 'Non-real time data',
    dataToYesterdayInclusive: 'data not include today (data for yesterday inclusive)',
    percent: 'Percent',
    count: 'Count',
    authenN: 'Authentication',
    authenZ: 'Authorization',
    showSelectPreviousPeriodBlockButton: 'Compare with another period',
    compareWithPreviousPeriodButton: 'Compare',
    comparePeriodsPeriod: 'Selected period',
    comparePeriodsPeriodForCompare: 'Period for compare '
  },
  radar: {
    maximumCpesWithEnabledWiFiTargetSelectedErrorMoreModalHeader: 'About restrictions',
    maximumCpesWithEnabledWiFiTargetSelectedError:
      'Exceeded the limit on the number of APs with enabled Wi-Fi Target for a specified time period',
    maximumCpesWithEnabledWiFiTargetSelectedErrorMore: 'More about restrictions',
    wifiTargetDisabledOnAllSelectedCPES: 'Wi-Fi Target disabled on all selected APs',
    wifiTargetEnabledShort: 'Wi-Fi Target enab.',
    wifiTargetEnabled: 'Wi-Fi Target enabled',
    cpes: 'APs',
    refreshButtonCaption: 'Refresh',
    exportNameForBeelineForModalCaption: 'Segment name',
    settingsForBeelineForModalCaption: 'Settings',
    createdForCTNForBeelineForTableCaption: 'Created for client with CTN',
    createdForBeelineForTableCaption: 'Date of creation',
    audienceForBeelineForTableCaption: 'Audience',
    statusForBeelineForTableCaption: 'Segment processing status',
    exportNameForBeelineForTableCaption: 'Segment name',
    tableHeaderForBeelineForTableCaption: 'Segment list',
    cabinet: 'To BeePro',
    user: 'User',
    businessIdErrorShow: 'A user with Business id must be selected (and Business id must not be 0)',
    ctnErrorShow: 'A user with CTN must be selected',
    ctnCaption: 'CTN',
    selectedCTNCaption: 'Selected CTN',
    usersWithBusinessIdsPlaceholder: 'Select User or enter name for search',
    settingsBeepro: 'Beepro Settings',
    businessIdsCaption: 'Business ID',
    selectedBusinessIdsCaption: 'Selected Business ID',
    addressesOrBusinessId: 'Addresses / Business ID',
    addressesOrCTN: 'Addresses / CTN',
    beepro: 'Beepro',
    createCopy: 'create copy',
    wrongEmailMsg: 'Wrong email',
    sortTypeName: 'By name',
    sortTypeCreated: 'By created',
    sortTypeUpdated: 'By updated',
    dataRangeFilterTypeButtonCreated: 'Created',
    dataRangeFilterTypeButtonUpdated: 'Updated',
    addressesCountCaption: 'Total',
    addresses: 'Addresses',
    cpeListPlaceholder: 'Select APs or enter name for search',
    cpeListNoOptionsMsg: 'No APs',
    cpeListNoOptionsMsgWhenFilterEnabled: 'No APs matching filter conditions',
    cpeListNoResultMsg: 'No APs with the same name was found',
    cpeListNoResultMsgWhenFilterEnabled: 'No APs with the same name matching the filter conditions was found',
    radarFiltringForCpeListResetFiltersButton: 'reset filters',
    radarFiltringForCpeListIsEnabled: 'enabled',
    radarEnableStatusForCpeFilterCaption: 'Wi-Fi Target status on APs',
    radarEnableStatusForCpeFilterEnabled: 'Wi-Fi Target is enabled',
    radarEnableStatusForCpeFilterDisabled: 'Wi-Fi Target is disabled',
    radarEnableStatusForCpeFilterAny: 'Any',
    filtersForCpeListSwitcherLocationCaption: 'AP location',
    filtersForCpeListSwitcherInfoTooltip:
      'Filtering makes it easy to find the desired AP in the AP selection list. Allows you to filter access points by their location and by the state of enabling or disabling the Wi-Fi Target on them',
    filtersForCpeListSwitcherCaption: 'Filtering a list of AP',
    // deleteModalTitle: 'Delete Export',
    deleteModalTitle: 'Delete Segment',
    deleteModalText: 'Confirm deleting',
    // addNew: 'Add new export',
    addNew: 'Add new segment',
    allStatuses: 'All Statuses',
    allVisitors: 'All',
    audience: 'Audience',
    auto: 'Periodicity',
    autoPeriod: 'Auto export periodicity (in hours)',
    autoPlaceholder: 'Enter autoperiod',
    continuously: 'Continuously',
    created: 'Created',
    description: 'Description',
    descriptionPlaceholder: 'Enter description',
    email: 'Email',
    filter: 'Visitors Filter',
    finished: 'Finished',
    format: 'Format',
    hash: 'Hash data',
    hashInfo: 'This is a data mask obtained by encryption. Serves to unambiguously identify an object or entity',
    infinity: 'Infinity',
    items: 'Current items',
    match: 'rate',
    matched: 'matched',
    mytarget: 'myTarget',
    name: 'Name',
    // namePlaceholder: 'Enter export name',
    namePlaceholder: 'Enter segment name',
    newVisitors: 'Only new',
    period: 'Period',
    processed: 'Processed',
    returnVisitors: 'Only returning',
    running: 'Running',
    selectPlaceholder: 'Select APs',
    settingsEmail: 'Email settings',
    settingsExport: 'Export Settings',
    settingsGeneral: 'General Settings',
    share: 'Share',
    status: 'Status',
    subject: 'Subject',
    subjectPlaceholder: 'Enter Subject',
    title: 'Wi-Fi Target Segment',
    type: 'Type',
    updated: 'Last updated',
    valid: 'exported',
    yandex: 'Yandex',
    yandexCaption: 'Email format of ‘@yandex.ru’ must be used with ‘Yandex’ export type',
    // notificationRadarExportCreatedSuccessfully: 'Export Created Successfully',
    // notificationRadarExportUpdatedSuccessfully: 'Export Updated Successfully',
    // notificationRadarExportDeletedSuccessfully: 'Export Deleted Successfully',
    notificationRadarExportCreatedSuccessfully: 'Segment Created Successfully',
    notificationRadarExportUpdatedSuccessfully: 'Segment Updated Successfully',
    notificationRadarExportDeletedSuccessfully: 'Segment Deleted Successfully',
    selectAllCpesButtonCaption: 'Add All Filtered APs',
    cancelSelectAllCpesButtonCaption: 'Clear all selected APs',
    cpeSelectedCounterCaption: 'Selected APs',
    selectedCPEsCaption: 'Selected',
    usersManualLinkCaption: "User's Manual"
  },
  radius: {
    daeSecretPlaceholder: 'Shared DAE secret',
    daePortPlaceholder: 'DAE port (3799 by default)',
    daeClientPlaceholder: 'DAE Client (ip address)',
    daePortHintInfoMsg: 'Port the Dynamic Authorization Extension server listens on',
    daeSecretHintInfoMsg: 'Shared DAE secret',
    daeClientHintInfoMsg:
      'Dynamic Authorization Extension client. This client can send “Disconnect-Request” or “CoA-Request” packets to forcibly disconnect a client or change connection parameters. Wildcard IP (like 0.0.0.0) are not working - only direct is allowed',
    daeBlockHintInfoMsg: 'Dynamic Authorization Extension (RFC 5176)',
    daePort: 'DAE Port',
    daeSecret: 'DAE Secret',
    daeClient: 'DAE Client',
    daeSettings: 'Dynamic Authorization Extension (RFC 5176)',
    wizardAdvancedParameters: 'Advanced parameters',
    acctPortPlaceholder: 'Enter Acct Port',
    authPortPlaceholder: 'Enter Auth Port',
    secretPlaceholder: 'Enter RADIUS Secret',
    namePlaceholder: 'Enter RADIUS Name',
    ipPlaceholder: 'Enter RADIUS Address',
    // acctPort: 'Acct port',
    acctPort: 'Accounting port',
    // authPort: 'Auth port',
    authPort: 'Auhtorization port',
    addRadius: 'Add RADIUS',
    advancedSettings: 'Fill required advanced RADIUS parameters',
    basicParameters: 'Fill required basic RADIUS parameters',
    confirmRadius: 'Confirm new RADIUS creation',
    local: 'Local',
    port: 'Port',
    portal: 'Portal',
    radius: 'RADIUS',
    radiusForSidebar: 'RADIUS servers',
    radiusList: 'RADIUS List',
    radiusWizard: 'Create RADIUS Wizard',
    secret: 'Secret',
    password: 'Password',
    notificationRADIUSUpdatedSuccessfully: 'RADIUS Updated Successfully',
    notificationRADIUSCreatedSuccessfully: 'RADIUS Created Successfully',
    notificationRADIUSDeletedSuccessfully: 'RADIUS Deleted Successfully',
    notificationRADIUSOperationSuccessfull: 'Operation Successful',
    addressField: 'Address',
    addressFieldHint: 'IP Address Or Domain Name',
    addressInTableTitle: 'Address',
    deleteRADIUSModalTitle: 'Delete RADIUS',
    confirmDeletingModal: 'Confirm deleting'
  },
  recommendations: {
    moveRecommendationsBottom: 'move down',
    moveRecommendationsTop: 'move up',
    knowledgeBase: {
      button: 'Go',
      text: 'A section describing the main sections of the platform will help you get to know the platform better',
      title: 'Knowledge base'
    },
    easyInstallWizard: {
      button: 'Go',
      text: 'Follow the steps of the wizard to easily set up and configure a WLAN with a portal',
      title: 'Easy install'
    },
    addAdds: {
      button: 'Setup Ads',
      text: 'Add ads to Portal to boost the sales.',
      title: 'Add Portal Ads'
    },
    addBanners: {
      button: 'Setup Banners',
      text: 'Add banner ads to attract attention of Portal visitors.',
      title: 'Add Banners'
    },
    addPolls: {
      button: 'Setup Polls',
      text: 'Polls allow to collect opinions of Portal visitors or entertain them.',
      title: 'Add Polls'
    },
    addPollsQuality: {
      button: 'Setup Quality Reviews',
      text: 'Quality Reviews allow you to collect opinions of Portal visitors or entertain them.',
      title: 'Add Quality Reviews'
    },
    addPollsUserDataBirthday: {
      button: 'Setup Polls',
      text: 'User Data polls allow you to collect info of Portal visitors birthdays',
      title: 'Setup User Data polls'
    },
    // addRadarExport: {
    //   button: 'Setup Exports',
    //   text: 'Setup periodic Wi-Fi Target Segments export to receive up-to-date reports on gathered data.',
    //   title: 'Wi-Fi Target Segments'
    // },
    addRadarExport: {
      button: 'Setup Segments',
      text: 'Setup periodic Wi-Fi Target Segments export to receive up-to-date reports on gathered data.',
      title: 'Wi-Fi Target Segments'
    },
    addVideo: {
      button: 'Setup Video',
      text: 'Use video for promotion of products and services.',
      title: 'Show Video'
    },
    allGood: {
      text: 'All recommendations are implemented.',
      title: 'Well done!'
    },
    distressedCPEs: {
      button: 'Show APs',
      text:
        'APs with errors detected. Configuration must be checked, AP Agent must be fixed and/or restarted. Please contact the administrator.',
      title: 'AP Error'
    },
    heading: 'Recommendations',
    highCPULoad: {
      text: 'CPU Load exceeds 90%. Please contact the server administrator.',
      title: 'High CPU Load'
    },
    showAdsStats: {
      button: 'See Statistics',
      text: 'Find out how effective your ads are.',
      title: 'Ads Statistics'
    }
  },
  reports: {
    types: {
      summary: 'summary',
      current: 'current'
    },
    typestypeForClientsSubject: {
      summary: 'summary',
      current: 'current',
      clients: 'clients',
      clients_auth: 'authorized clients'
    },
    subjects: {
      clients: 'Clients',
      events: 'Events',
      cpes: 'APs',
      cpes_common: 'APs common'
    },
    formats: {
      csv: 'csv',
      json: 'json',
      xlsx: 'xlsx'
    },
    add: 'Add Report',
    addCharts: 'Add charts',
    addChartsCaption: "Available only for 'aps_common' subject and 'xlsx' format",
    collect_period: 'Collect period',
    confirmDeleting: 'Confirm deleting',
    dayMonth: 'Day (month)',
    dayWeek: 'Day (week)',
    deleteReport: 'Delete Report',
    editReport: 'Edit Report',
    format: 'Format',
    hour: 'Hour',
    newReport: 'New Report',
    period: {
      day: 'Every day',
      month: 'Every month',
      now: 'Now',
      week: 'Every week'
    },
    placedescr: 'Enter Report description',
    placename: 'Enter Report name',
    subject: 'Subject',
    type: 'Type',
    week: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Tuesday',
      4: 'Wednesday',
      5: 'Friday',
      6: 'Saturday'
    },
    notificationReportCreatedSuccessfully: 'Report Created Successfully',
    notificationReportUpdatedSuccessfully: 'Report Updated Successfully',
    notificationReportDeletedSuccessfully: 'Report Deleted Successfully',
    tableTooltipStart: 'Start',
    tableTooltipStop: 'Stop',
    placeHolderAddEmail: 'Enter Email'
  },
  sidebar: {
    ConfigExportImport: 'Config Export / Import',
    PeriodicTasks: 'Periodic Tasks',
    DBState: 'DB State',
    DITStat: 'DIT Statistic',
    DITExport: 'DIT Export',
    PortalAccessServers: 'Access Servers',
    SNMPStat: 'SNMP Statistic',
    SNMPMonitoringConfig: 'SNMP Monitoring Config',
    cpu: 'CPU',
    cpuLoad: 'CPU Load',
    badgeTooltipTotal: 'Total',
    badgeTooltip24h: 'Total (last 24h)',
    badgeTooltipConnected: 'Connected',
    locations: 'Locations',
    accounts: 'Accounts',
    ads: 'ADs & Polls',
    analiticsAndData: 'Analytics & Data',
    analytics: 'Analytics',
    birthdays: 'Birthdays',
    buyers: 'Sales',
    clientDistance: 'Client Distance',
    clientRF: 'Client RF',
    clients: 'Clients',
    controllers: 'Controllers',
    cpes: 'APs',
    dashboard: 'Dashboard',
    events: 'Events',
    export: 'Export',
    firewall: 'Firewall',
    GuestControl: 'Guest control',
    heatmap: 'Heatmap',
    hotelVouchers: 'Hotel vouchers',
    hotspots: 'Hotspot 2.0',
    licenses: 'Licenses',
    maps: 'Maps',
    monitor: 'Monitoring',
    network: 'Network',
    nms: 'NMS',
    pages: 'Pages',
    paidInternet: 'Paid Internet',
    paymentSystems: 'Payment Systems',
    portal: 'Portal',
    portalConfiguration: 'Configuration',
    portalDashboard: 'Dashboard',
    preview: 'Preview',
    profiles: 'Profiles',
    radar: 'Wi-Fi Target',
    // radius: 'RADIUS',
    radius: 'RADIUS servers',
    reports: 'Reports',
    rrm: 'RRM',
    rules: 'Rules',
    scanner: 'Scanner',
    sessions: 'Find session',
    settings: 'Settings',
    statistics: 'Statistics',
    system: 'System',
    systemInfo: 'System Info',
    tariffs: 'Tariffs',
    template: 'Templates',
    troubleshooter: 'AP connectivity',
    visitors: 'Visitors',
    vouchers: 'Vouchers',
    wifiAnalyzer: 'Wifi Analyzer',
    wlans: 'WLANs',
    support: 'Knowledge base'
  },
  statistics: {
    filteringByCPE: 'Filtering by AP',
    goToClientsSettings: 'Go to Clients Settings',
    goToCPESettings: 'Go to AP Settings',
    userData: 'User Data',
    transmitted: 'Transmitted',
    received: 'Received',
    transmittedShort: 'tx.',
    receivedShort: 'rx.',
    goToWLANSettings: 'Go to WLAN Settings',
    paginationLoad: 'Load',
    paginationCPERaitngsOutOf: 'out of',
    macForDownload: 'MAC',
    authorizationDataForDownload: 'Authorization data',
    cpeForDownload: 'AP',
    wlanForDownload: 'WLAN',
    vlanForDownload: 'VLAN',
    ssidForDownload: 'SSID',
    startForDownload: 'Start',
    stopForDownload: 'Stop',
    trafficDownloadedForDownload: 'Traffic Downloaded',
    trafficUploadedForDownload: 'Traffic Uploaded',
    ipsForDownload: 'IPs',
    locationForDownload: 'Location',
    companyForDownload: 'Company',
    totalDurationForDownload: 'Total Duration (sec.)',
    sessionDurationForDownload: 'Session Duration (sec.)',
    noCompanyNameTooltipForTable: 'No company name',
    allCpes: 'All APs',
    allUsers: 'All users',
    authorized: 'Only authorized',
    byCPULoad: 'By CPU load',
    byDuration: 'By duration',
    byLocation: 'By location',
    byMemoryLoad: 'By memory load',
    byRxTraffic: 'By download traffic',
    byTraffic: ' By traffic',
    byTxTraffic: 'By upload traffic',
    byconnectedClients: 'By connected clients',
    bydisconnectedClients: 'By disconnected clients',
    bypasserbyClients: 'By Passerby clients',
    bytotalClients: 'By total clients',
    chooseCPEs: 'Choose APs',
    chooseDate: 'Choose date',
    chooseDateRange: 'Choose date range',
    clients: 'Clients',
    createAndDownload: 'Create and download',
    export: 'Export',
    exportStat: 'Export Wi-Fi Target data',
    field: 'Field',
    filtersCPE: 'AP',
    filtersRadio: 'Interface name (radio or VLAN)',
    filtersUsers: 'Users',
    filtersWLAN: 'WLAN',
    from: 'From',
    limit: 'Quantity',
    offset: 'Starting from',
    order: 'Order',
    pagination: 'Pagination',
    pan: 'Click and drag to zoom in. Hold down shift key to pan',
    passerbyClients: 'Passerby Clients',
    period: 'Period',
    rawButton: 'Sessions Report',
    rawConfirmation: 'Please confirm client sessions report generation with the following parameters:',
    rawModal: 'Generate Report',
    rawRequest: 'Generate Report',
    showClient: 'show client info',
    to: 'To',
    username: 'Last Authorization data',
    wlan: 'WLAN',
    wlanForReportModal: 'WLAN',
    txRxChartTitle: 'Total TX/RX',
    txRxChartMbytes: 'Mbytes',
    txRxChartTX: 'TX',
    txRxChartRX: 'RX',
    txRxChartTXandRX: 'TX+RX',
    txRxRateChartTitle: 'TX/RX Rate',
    txRxRateChartMbytess: 'MByte/s',
    txRxRateChartTXRate: 'TX Rate',
    txRxRateChartRXRate: 'RX Rate',
    txRxRateChartTXandRXRate: 'TX+RX Rate',
    totalClientsChartTitle: 'Total Clients',
    totalClientsChartAxis: 'Clients',
    totalClientsChartSeries: 'Clients',
    totalTxRxPerUserChartTitle: 'Total TX/RX per user',
    totalTxRxPerUserChartMbytes: 'Mbytes',
    totalTxRxPerUserChartTX: 'TX',
    totalTxRxPerUserChartRX: 'RX',
    totalTxRxPerUserChartTXandRX: 'TX+RX',
    txRxRatePerUserChartTitle: 'TX/RX Rate per user',
    txRxRatePerUserChartMbytess: 'MByte/s',
    txRxRatePerUserChartTXRate: 'TX Rate',
    txRxRatePerUserChartRXRate: 'RX Rate',
    txRxRatePerUserChartTXandRXRate: 'TX+RX Rate',
    cpuLoadChartTitle: 'CPU load',
    cpuLoadChartAxis: 'CPU load',
    cpuLoadChartSeries: 'CPU',
    memoryLoadChartTitle: 'Memory load',
    memoryLoadChartAxis: 'Memory load',
    memoryLoadChartSeries: 'Memory',
    statCpesTooltipSession: 'session',
    statCpesTooltipCount: 'count',
    statCpesTooltipAvgTime: 'avg time',
    statCpesTooltipRx: 'rx',
    statCpesTooltipTx: 'tx',
    statCpesTooltipFirst: 'first',
    statCpesTooltipReturn: 'return',
    sessionClientsChartTitle: 'Client session',
    sessionClientsChartSeriesConnected: 'Connected',
    sessionClientsChartSeriesDisconnected: 'Disconnected',
    sessionClientsChartAxisOffline: 'offline',
    sessionClientsChartAxisOnline: 'online',
    sessionClientsChartTooltipCPE: 'AP',
    sessionClientsChartTooltipFrequency: 'Frequency',
    sessionClientsChartTooltipMode: 'Mode',
    sessionClientsChartTooltipWLAN: 'WLAN'
  },
  system: {
    byFile: 'By file',
    byText: 'By code',
    licenceActivationCaption: 'License activation',
    uploadFileButton: 'Submit',
    selectFile: 'Select File',
    selectedFile: 'Selected file',
    selectedFileName: 'Selected file name',
    import: 'Import',
    addCode: 'Insert license code',
    addLicense: 'Add new license',
    current: 'Current APs',
    limit: 'Limit APs',
    portalCurrent: 'Current Portals',
    portalLimit: 'Limit Portals',
    analyticsCurrent: 'Current Analytics',
    analyticsLimit: 'Limit Analytics',
    analyticsHint: 'Analytics is the number of enabled Wi-Fi Targets on access points'
  },
  tags: {
    addTags: 'Add Tags',
    availlableTags: 'Available tags',
    currentTags: 'Current Tags',
    deleteAllTags: 'Delete All Tags'
  },
  tariffs: {
    add: 'Add Tariff',
    amount: 'Price',
    edit: 'Edit Tariff',
    name: 'Name',
    new: 'New Tariff',
    session: 'Session length',
    speed: 'Speed',
    title: 'Tariffs',
    notificationTariffCreatedSuccessfully: 'Tariff Created Successfully',
    notificationTariffUpdatedSuccessfully: 'Tariff Updated Successfully',
    notificationTariffDeletedSuccessfully: 'Tariff Deleted Successfully',
    tariffTypeCaption: 'Tariff type',
    tariffTypeVoucher: 'Voucher',
    tariffTypeSubscription: 'Subscription',
    recurringCaption: 'Subscription recurring',
    recurringSwitcherCaption: 'Enable Subscription Recurring',
    recurringPeriodCaption: 'Recurring period',
    recurringPeriodDay: 'Day',
    recurringPeriodWeek: 'Week',
    recurringPeriodMonth: 'Month',
    subscriptionDescriptionCaption: 'Description',
    subscriptionDescriptionAdvice: 'Paste your Description text in the field below',
    locale: {
      en: 'English',
      locale: 'Locale',
      ru: 'Russian',
      tg: 'Tajik'
    },
    profileSelectorTip: 'Press Enter to select',
    selectProfile: 'Select Profile',
    profileField: 'Profile',
    profileCaptionInTable: 'Profile',
    noProfileSelectedError: 'You must select a Profile'
  },
  template: {
    allUUIDs: 'All UUIDs',
    confirmButton: 'Confirm',
    wizardrConfigParametersTitle: 'Config Parameters',
    wizardWLANsPlaceHolder: 'Select WLAN',
    wizardWLANs: 'WLANs',
    fieldMustBeCsvFile: 'The field must be a csv file',
    wrongDataInFile: 'Wrong data in file',
    templateAddUUIDPlaceHolder: 'Add UUID',
    wizardSelectModelPlaceholder: 'Select model',
    templateDescriptionPlaceHolder: 'Enter description',
    templateNamePlaceHolder: 'Enter template name',
    tableOn: 'on',
    tableOff: 'off',
    tableCPEsUUID: 'APs',
    addTemplate: 'Add Template',
    advancedSettings: 'Advanced Settings',
    alwaysApply: 'Every Connection',
    autoConfig: 'Auto configuration',
    autoConfiguration: 'Enable',
    basicParameters: 'Fill required basic Templates parameters',
    configFor: 'Config for',
    configGeneral: 'General Config Settings',
    configRadio: 'Interfaces Settings',
    cpeModel: 'AP Model',
    CPEsTemplates: 'APs Templates',
    macPrefix: 'MAC prefix',
    manualConfig: 'Manual configuration',
    networkMask: 'Network mask',
    requiredConfigParameters: 'Fill required config parameters',
    statLogLBSSettings: 'Stat & Wi-Fi Target & Log Settings',
    template: 'Template',
    templateSettings: 'Template Settings',
    templateWizard: 'Create Templates Wizard',
    notificationTemplateDeletedSuccessfully: 'Template Deleted Successfully',
    notificationTemplateUpdatedSuccessfully: 'Template Updated Successfully'
  },
  tooltip: {
    Ads: 'Section allows to configure ads for authorization portals.',
    Aps: 'Section displays list and general information about access points and allows to configure them.',
    Clients: "Section displays information on end clients connected to platform's wireless and wired networks.",
    Dashboard: 'Section displays general platform statistics.',
    Events: 'Section provides information on all events in the system.',
    Firewall: 'Section allows to create and edit firewall rules, which are further added to WLANs and access points.',
    GuestControl:
      'Sections allows to configure redirection rules for traffic of unauthorized users in networks with an authorization portal.',
    Hotspot: 'Section allows to configure Hotspot 2.0 profiles.',
    Marketing: 'Section displays visitors statistics.',
    NotFound: 'Description not found.',
    PaymentSystems: 'Section allows to manage payment systems for paid internet.',
    PortalPages: 'Section allows to configure pages of authorization portals.',
    PortalProfilesNew: 'Section allows to configure profiles of authorization portals.',
    PortalPreview:
      'On this page you can select a Page and Profile and check if everything is displayed as you intended.',
    PortalAdsNew:
      'ADs & Polls are designed to promote, sell, and to learn more about what you are interested in from your visitors.',
    PortalBirthdays:
      'The data on the birthdays of your guests, collected from social networks during authorization and through polls. All data can be downloaded in PDF, CSV and XLS.',
    PortalStats:
      'The section contains data on new, repeat users and unique, repeating user connections by type of Operating System, device type, language, manufacturer and, of course, identification and authorization methods.\n All data can be uploaded for a period and saved in formats PDF, CSV and XLS.',
    PortalHotelVouchers:
      'The section presents data on available and already activated vouchers. It is possible to download available vouchers in XLS format.',
    PortalAdsStats: 'The section contains statistics on views by type of authorization.',
    RRM: 'Section allows to configure RRM (seamless Wi-Fi) for selected access points',
    // Radar: 'Section allows to make Wi-Fi Target data extracts.',
    Radar: 'Section allows to make Wi-Fi Target segments extracts.',
    Radius: 'Section allows to create and edit configurations for RADIUS client.',
    Rules: 'Section allows to configure execution of necessary scripts upon specific events.',
    Sessions: 'Section displays charts for access points connection, information on their sessions and online time.',
    Statclients: "Section displays clients' statistics.",
    Statcpes: 'Section displays access points statistics.',
    Statreports: 'Section displays reports statistics and allows to configure individual reports.',
    Statsystem: 'Section displays locations statistics.',
    Statwlans: 'Section displays wireless networks statistics.',
    System:
      'Sections allows to view and manage licenses required for connection of additional access points to the platform.',
    Tariffs: 'Sections allows to manager Wi-Fi access tariffs.',
    Templates:
      'Section allows to create and setup templates with automated configuration of access points upon initial or every connection.',
    UserAccounts: 'Section allows to manage user accounts.',
    Vouchers: 'Section allows to view and manager vouchers.',
    Wlans:
      'Section allows to create and setup wireless networks. \nYou cannot specify the same Portal profile operating conditions (WLAN, AP, NAS ID list) on several profiles - this will lead to unpredictable behavior!',
    maps: 'Section visualizes access points data.'
  },
  userAccount: {
    unsetForFilter: 'Unset',
    notSubscribed: 'Not subscribed',
    subscribed: 'Subscribed',
    subscribedFromDate: 'from',
    subscriptionName: 'Subscription name',
    subscriptionTableTitle: 'Subscription',
    unset: 'unset',
    vk: 'VK',
    fb: 'Facebook',
    instagram: 'Instagram',
    actions: 'Actions',
    add: 'Add Account',
    addition: 'Addition',
    addToBlacklist: 'Add to blacklist',
    amount: 'Amount',
    authCode: 'Authentication code',
    authCodeInfo:
      'The code is generated by clicking "Create access code" in the "Authentication" column. This is what the client needs to enter to access the Internet',
    balance: 'Balance',
    balanceInfo: 'Virtual account balance in case of paid access',
    birthday: 'Birthday',
    city: 'Current city',
    createAt: 'Date of creation:',
    createdOn: 'Date',
    currency: 'Currency',
    dataFromSocials: 'User data from social networks',
    deleteFromBlacklist: 'Delete from blacklist',
    deleteAccessListCode: 'Delete access code',
    edit: 'Edit Account',
    email: 'E-mail',
    firstName: 'First Name',
    gender: {
      type: 'Gender',
      male: 'Male',
      female: 'Female',
      unset: 'Unset',
      '<nil>': '',
      мужик: 'Male'
    },
    generateAccessListCode: 'Generate access code',
    getTransactions: "Show {num} user's transactions",
    goToLink: 'Go to {link}',
    history30d: 'Transactions history for last 30 days',
    homeTown: 'City of birth',
    identityInfo: 'Phone, Email, MAC address - depending on the selected authentication type',
    macs: 'MAC',
    name: 'Name',
    nameFromSocial: 'Name from Social Network',
    new: 'New Account',
    next: 'next',
    noTransactions: 'No additions or purchases for the last 30 days',
    of: 'of',
    prev: 'prev',
    profile: 'Profile',
    purchase: 'Purchase',
    rowsPerPage: 'Rows per page',
    socialLink: 'Social network link',
    socialLinks: 'Social networks',
    surname: 'Last Name',
    title: 'User Accounts',
    transactions: 'Transactions history',
    transactionsInfo: 'A list of all payment manipulations of the user',
    type: 'Type',
    universities: 'Education',
    username: 'Username',
    visits: 'Connections',
    isUserAddedToBlacklist: 'Is User added to blacklist?',
    isUserAddedToBlacklistYes: 'Yes',
    isUserAddedToBlacklistNo: 'No',
    notificationUserAccountCreatedSuccessfully: 'Account Created Successfully',
    notificationUserAccountUpdatedSuccessfully: 'Account Updated Successfully',
    notificationUserAccountDeletedSuccessfully: 'Account Deleted Successfully',
    notificationUserAccountSuccess: 'Successfully'
  },
  users: {
    deleteUserHeader: 'Delete user',
    isBlocked: 'Blocked',
    firstLoginForUserEditPanel: 'First login',
    lastLoginForUserEditPanel: 'Last login',
    createdByForUserEditPanel: 'Created by',
    // beepro: 'Beepro',
    bee_pro_bis_id: 'BusinessID',
    bee_pro_ctn: 'CTN',
    userHasCTNButHasNOtBisIDTooltip: 'User has CTN but no BusinessID (or is 0)',
    wrongEmailMsg: 'Wrong email',
    newUsernameInfoMsg: 'This name will be used as a login when entering your personal account',
    updUsernameInfoMsg: 'This name is used as a login when entering  personal account',
    blockedUsers: 'Blocked',
    noBlockedUsers: 'No blocked',
    notificationUserBlockedSuccessfully: 'User Blocked Successfully',
    notificationUserUnblockedSuccessfully: 'User Unblocked Successfully',
    blockUserCaption: 'Block user',
    unblockUserCaption: 'Unblock user',
    copyFromLocation: 'copy from location',
    setActiveUntillForUser: 'Limit user expiration date',
    enterForPasswordChangePlaceHolder: 'Input for change',
    enterEmailPlaceHolder: 'Enter email',
    enterPasswordPlaceHolder: 'Enter password',
    enterUsernamePlaceHolder: 'Enter username',
    Role: 'Role',
    addUser: 'Add User',
    company: 'Company',
    createdBy: 'Created by',
    creationDate: 'Creation date',
    description: 'Position',
    lastLogin: 'Last Login',
    loginAsSubordinateForButton: 'Login',
    logoutAsSubordinateForButton: 'Continue as',
    noLogin: 'No login yet',
    password: 'Password',
    phone: 'Phone',
    username: 'Username',
    users: 'Users',
    usersList: 'Users List',
    mac_mask: 'Mask MAC addresses for this user',
    notificationUserCreatedSuccessfully: 'User Created Successfully',
    notificationUserUpdatedSuccessfully: 'User Updated Successfully',
    notificationUserDeletedSuccessfully: 'User Deleted Successfully',
    addUserWithDemoData: 'Add user with demo data',
    newUserPasswordValidationMessage:
      'The password field may contain only the following special character: _ - # $ & ^ * @ ! %'
  },
  vouchers: {
    noAccountSelectedInModalErr: 'Account field is required',
    selectAccount: 'Select account',
    allAccounts: 'All accounts',
    account: 'Account',
    add: 'Add Voucher',
    backToVouchers: 'Back to vouchers',
    code: 'Code',
    codeLength: 'Code length',
    createVouchers: 'Create vouchers',
    createHotelVouchers: 'Create hotel vouchers',
    download: 'Download for print',
    duration: 'Duration',
    edit: 'Edit Voucher',
    editHotel: 'Edit hotel voucher',
    expire_at: 'Expiration time',
    new: 'New Voucher',
    newVouchers: 'New vouchers',
    newHotelVouchers: 'New hotel vouchers',
    number_of_vouchers: 'Vouchers amount',
    profile: 'Profile',
    session: 'Internet access time after activation',
    tariff: 'Tariff',
    title: 'Vouchers',
    titleHotel: 'Hotel vouchers',
    used: 'Used',
    validFor: 'Valid for {day} day(s)',
    validForShort: 'Valid for',
    notificationVoucherCreatedSuccessfully: 'Voucher Created Successfully',
    notificationVoucherUpdatedSuccessfully: 'Voucher Updated Successfully',
    notificationVoucherDeletedSuccessfully: 'Voucher Deleted Successfully',
    notificationHotelVoucherCreatedSuccessfully: 'Hotel voucher Created Successfully',
    notificationHotelVoucherUpdatedSuccessfully: 'Hotel voucher Updated Successfully',
    notificationHotelVoucherDeletedSuccessfully: 'Hotel voucher Deleted Successfully'
  },
  wlans: {
    SSHWebAccess: 'SSH / Web access',
    goBackToClients: 'Back To Clients',
    securitySettingsCaption: 'Security Settings',
    L2TabCaption: 'Layer L2',
    L3TabCaption: 'Layer L3',
    AAATabCaption: 'AAA',
    addRedirectRule: 'Add Redirect',
    addRADIUS: 'Add RADIUS',
    goBackToWlanStatistic: 'Back to WLANs Statistic',
    selectSingleInterface: 'Select Interface',
    selectSingleTunnel: 'Select Tunnel',
    selectSingleWlan: 'Select WLAN',
    nas_generateInfo: 'Enables better work quality for 802.11r roaming',
    nas_generateCaption: 'Generate NAS ID',
    signalStrikesForLastWizardStep: 'Number of checks',
    signalDropReasonForBadge: 'Drop Reason code',
    signalPollTimeForBadgeSec: 'sec.',
    signalPollTimeForBadge: 'Poll Time',
    signalStrikesForBadge: 'Number of checks',
    signalStayForBadge: 'Minimum RSSI Threshold',
    signalDropReasonMoreInfoForModal: 'You can read more about codes here',
    signalDropReasonMoreInfo: 'More info about codes',
    signalDropReason: 'Drop Reason code',
    signalDropReasonHint: '3 by default',
    signalDropReasonPlaceholder: 'Drop Reason code, 3 by default',
    signalDropReasonForInfo: 'Drop Reason code sent to the client',
    signalPollTime: 'Poll Time',
    signalPollTimeHint: '5 sec. default',
    signalPollTimePlaceholder: 'Poll Time, 5 sec. default',
    signalPollTimeForInfo: 'Poll Time, 5 sec. default',
    signalStrikes: 'Number of checks before disconnect',
    signalStrikesHint: '3 by default',
    signalStrikesPlaceholder: 'Number of checks, 3 by default',
    signalStrikesForInfo: 'Number of signal power level checks before disconnecting the client',
    signalStay: 'Minimum RSSI Threshold',
    signalStayHint: 'from -100 to -40 dbm, 0 to disable',
    signalStayPlaceholder: 'from -100 to -40 dbm, 0 to disable',
    signalStayForInfo:
      'For connected clients, when detecting a decrease in the signal power less than the specified one, within a specified number of times, they are disconnected',
    signalBlockCaption:
      'Settings for the RSSI threshold, when reduced, the user is disconnected from the WLAN network with the specified code',
    // overTheDS: 'Over The DS',
    overTheDS: 'Over DS / Over Air',
    rssiThreshold: 'RSSI threshold',
    rssiThresholdHint: 'Allowed client signal level when connecting (from -90 to -60 dbm)',
    rssiThresholdHintRange: 'from -90 to -60 dbm, 0 to disable',
    rssiThresholdHintForInfo: 'Allowed client signal level when connecting',
    rssiThresholdPlaceholder: 'from -90 to -60 dbm, 0 to disable',
    bandSteering: 'Band steering',
    bandSteeringForBadge: 'Band steering',
    bandSteeringForLastStepWlanWizard: 'Band steering',
    wizardSkipButtonInfo:
      'Skip all subsequent steps and go to the last step. The settings specified in the current step will be applied.',
    wizardSkipButton: 'Skip next steps',
    natEnabledForTable: 'enabled',
    natDisabledForTable: 'disabled',
    peerAddressPlaceholder: 'Enter the EoGRE server IP',
    peerAddress: 'EoGRE server',
    proto: 'Type',
    tunnelTypeForTable: 'Tunnel',
    natNetworkInfoMsg: "Don't use 10.9.0.1/16 for IPSec APs",
    natNetworkIpaddr: 'IP for NAT network',
    natNetworkNetmask: 'Mask for the NAT network',
    natNetworkIpaddrPlaceholder: 'Enter IP for NAT network',
    natNetworkNetmaskPlaceholder: 'Enter the mask for the NAT network',
    qrSaveQRCode: 'Save QR',
    qrForWLANConnet: 'QR code for connect to  WLAN',
    notAvailableForCisco: 'Not available for Cisco',
    radiusAdditionalSettings: 'RADIUS additional settings',
    deleteTagsModalTitile: 'Delete Common Tags',
    deleteTagsModalText: 'Confirm deleting all Tags on selected WLANs',
    addTag: 'Add tag',
    tunneling: 'Tunneling',
    tunnelingShort: 'Tunneling',
    centralized: 'Centralized',
    vlanNative: 'Native',
    wizardAdditionalSettings: 'Additional settings',
    wizardSecuritySettings: 'Security settings',
    UAPSD: 'UAPSD',
    WMM: 'WMM',
    onlyForWPAEnterprise: 'Only for WPA Enterprise',
    currentWhitelistItems: 'Current whitelist items',
    currentBlacklistItems: 'Current blacklist items',
    filterWhitelistMode: 'Whitelist mode',
    filterWhitelistModeCaption: 'Only the chosen clients can connect to the WLAN',
    filterBlacklistMode: 'Blacklist mode',
    filterBlacklistModeCaption: 'Everyone except the chosen clients can connect to the WLAN',
    filterNoneModeCaption: 'All the clients can connect the WLAN',
    MACRADIUSAuthentication: 'MAC RADIUS Authentication',
    none: 'None',
    firewall: 'Firewall',
    redirect: 'Redirect',
    unlim: 'unlim',
    download: 'Download',
    upload: 'Upload',
    enterNASPortIdPlaceholder: 'Enter NAS port id',
    enterNASIdentifierPlaceholder: 'Enter NAS identifier',
    enterRSNAPlaceholder: 'Enter RSNA',
    enterVLANPlaceHolder: 'Enter VLAN',
    noInterfaces: 'No interfaces',
    selectInterface: 'Select interface',
    suitesPlaceholder: 'Suites',
    hotspotProfileNone: 'None',
    hotspotProfileTitle: 'Hotspot 2.0 Profile',
    selectRADIUSPlaceholder: 'Select RADIUS',
    enterWlanPasswordPlaceholder: 'Enter wlan password',
    ssidPlaceholder: 'Enter wlan SSID',
    descriptionPlaceholder: 'Enter wlan description',
    noCurrentCPEs: 'no current APs',
    statusActive: 'active',
    statusNotActive: 'not active',
    statusPending: 'pending',
    statusEmpty: 'empty',
    whiteList: 'Whitelist',
    blackList: 'Blacklist',
    tableNone: 'none',
    securityOpen: 'Open',
    load: 'Load',
    WLANsOufOf: 'WLANs ouf of',
    selected: 'selected',
    view: 'view',
    itemsOf: 'items of',
    L2isolation: 'L2 isolation',
    NASidentifier: 'NAS identifier',
    NASportid: 'NAS port id',
    PMKcaching: 'PMK caching',
    accessControl: 'Guest Control',
    addWLAN: 'Add WLAN',
    advancedSettings: 'Fill required advanced WLAN parameters',
    basicParameters: 'Fill required basic WLAN parameters',
    bridge: 'For Bridge interfaces VLAN is ignored',
    confirmDeleting: 'Confirm deleting',
    confirmWlans: 'Confirm new WLAN creation',
    deleteMulti: 'Delete WLANs',
    deleteWLAN: 'Delete WLAN',
    deleteWLANs: 'Delete WLANs',
    // fastTransition: '802.11r fast transition',
    fastTransition: 'Fast roaming (802.11r)',
    filter: 'Filter',
    filterMode: 'Filter mode',
    hidden: 'Hidden',
    interface: 'Interface',
    mirroring: 'Mirroring',
    mirroringHint: 'Duplicated sending for multiple RADIUS servers',
    // mobility: 'Mobility',
    mobility: 'Mobility settings',
    networkEncapsulation: 'Network encapsulation',
    dataTrafficManagement: 'Data traffic management',
    localSwitching: 'Local switching',
    centralSwitching: 'Central switching',
    optionalParameters: 'Fill optional WLAN parameters, you can skip and do it later',
    radiusAccounting: 'RADIUS accounting',
    radiusAccountingForWLANModalAAA: 'RADIUS Accounting Server',
    radiusAccountingInterval: 'Radius Accounting interval',
    rsnaCapture: 'If left blank, it will be filled depending on Security (OPEN / WPA / RSNA)',
    rsnaSettings: 'RSNA Settings',
    rsnaSettingsV2: 'Change Calling Station ID',
    security: 'Security',
    securityParameters: 'Choose your new WLAN security profile',
    speedControl: 'Speed Control',
    speedDownload: 'Download',
    speedUpload: 'Upload',
    status: 'Status',
    suites: 'Suites',
    tableTab_clients: 'Clients',
    tableTab_cpes: 'APs',
    tableTab_filter: 'Filter',
    tableTab_location: 'Location',
    tableTab_security: 'Security',
    tableTab_ssid: 'SSID',
    tableTab_status: 'Status',
    tableTab_tags: 'Tags',
    tableTab_hasPortal: 'Portal',
    tableTab_visibility: 'Visibility',
    tableTab_vlan: 'VLAN',
    tableTab_encapsulation: 'Encapsulation',
    tableTab_dataTrafficManagement: 'Data traffic management ',
    tableTab_wlans: 'WLANs',
    tunnel: 'Tunnel',
    visibility: 'Visibility',
    visible: 'Visible',
    warnService: 'Please note that an active WLAN is selected. Its deletion will result in {0}.',
    warnServiceLoss: 'loss of service',
    warnStatistics: 'Please note that deletion of WLAN will result in {0}.',
    warnStatisticsLoss: 'loss of statistics',
    wlanWizard: 'Create WLAN Wizard',
    wlanWizardV2: 'Create WLAN',
    wlans: 'WLANs',
    wlansList: 'WLANs List',
    notificationWLANUpdatedSuccessfully: 'WLAN Updated Successfully',
    notificationWLANCreatedSuccessfully: 'WLAN Created Successfully',
    notificationWLANDeletedSuccessfully: 'WLAN Deleted Successfully',
    notificationWLANOperationSuccessfull: 'Operation Successful',
    wizardVisibility: 'Visibility',
    visibilityHidden: 'hidden',
    visibilityVisible: 'visible',
    wizardRADIUSAccounting: 'RADIUS accounting',
    wizardRADIUSAccountingStepCaption: 'RADIUS accounting settings',
    optionalStep: 'You can skip and do it later',
    wizardNetworkEncapsulationStepCaption: 'Network encapsulation settings',
    wizardRADIUSAdditionalSettingsStepCaption: 'RADIUS additional settings',
    wizardRADIUSAdditionalSettings: 'RADIUS additional settings',
    wizardSpeedControlSettingsStepCaption: 'Speed Control settings',
    wizardSpeedControlSettings: 'Speed Control',
    enterKbitPlaceholder: 'Enter kbit',
    wizardGuestControlStepCaption: 'Guest Control settings',
    wizardGuestControl: 'Guest Control',
    wizardMobilityStepCaption: 'Mobility settings',
    // wizardMobility: 'Mobility',
    wizardMobility: 'Mobility settings',
    notForOpen: 'Not for Open WLAN',
    wizardWMMStepCaption: 'WMM settings',
    wizardWMM: 'WMM',
    wizardBlackWhiteListItem: 'items',
    ieee80211k: '802.11k',
    rrm_neighbor_report: 'Neighborhood report',
    rrm_beacon_report: 'Beacon report',
    ieee80211v: '802.11v',
    wnm_sleep_mode: 'Ability to switch to sleep mode',
    wnm_sleep_modeInfo: 'Enables the ability to switch to sleep mode for power saving',
    wnm_sleep_modeForWizardLastStep: 'Sleep mode',
    // bss_transition: 'BSS Transition',
    bss_transition: 'BSS Transition',
    ieee80211kForBadge: '802.11k',
    rrm_neighbor_reportForBadge: 'Neighborhood report',
    rrm_beacon_reportForBadge: 'Beacon report',
    ieee80211vForBadge: '802.11v',
    wnm_sleep_modeForBadge: 'Sleep mode ability',
    bss_transitionForBadge: 'BSS Transition',
    bss_transitioForBadge: 'BSS Transition',
    enabledForBadge: 'on',
    disabledForBadge: 'off',
    loadBalancing: 'Load Balancing',
    loadBalancingInfoMsg: 'Works correctly only with 802.11v',
    wpa3IsNotSupportedOnAllCPE: 'WPA3 is not supported on all APs'
  },
  wmm: {
    Background: 'Background (BK)',
    BestEffort: 'BestEffort  (BE) ',
    Video: 'Video (VI)',
    Voice: 'Voice (VO) ',
    default: 'default',
    configPlaceHolder: 'Input for change default value',
    custom: 'enabled',
    infoTooltip: 'click to view details about WMM config',
    modalInfoTitle: 'About WMM',
    modalInfomoreInfo: 'More info'
  },
  portalLocationsStat: {
    clicks: 'Clicks',
    views: 'Views',
    allVisits: 'All visits',
    uniqueVisit: 'Unique visit',
    firstVisit: 'First visit',
    repeatVisit: 'Repeat Visit',
    adStat: 'Ad and polls stats',
    clientsStat: 'Clients stats',
    locationsStat: 'Locations stats',
    location: 'Location',
    total: 'Total'
  },
  portalDashboard: {
    smsCost: 'Cost of SMS',
    connections: 'Connections',
    connectionsPeriod: 'last 7 days',
    connectionsTotal: 'Total',
    connectionsNew: 'New users',
    connectionsRepeat: 'Repeat users',
    connectionsUnique: 'Unique clients',
    authentications: 'Authentications',
    authenticationsPeriod: 'last 7 days',
    authenticationsTotal: 'Total',
    authenticationsSms: 'SMS',
    authenticationsEmail: 'e-mail',
    authorizations: 'Authorizations',
    authorizationsPeriod: 'last 7 days',
    locations: 'Locations',
    locationsTotal: 'Total',
    locationsWhenManager: 'total',
    locationsAll: 'All Locations',
    locationsWhereCurrentUserisManager: 'Locations where you are a manager',
    locationsMap: 'Locations map',
    support: 'Support',
    supportText: 'Have problem? Text us, we can help.',
    recomendationVKFB: 'Recommendation',
    birthdays: 'Birthdays',
    os: 'Operating Systems',
    osPeriod: 'last 7 days',
    adsViewsPeriod: 'for this month',
    adsPeriod: 'for all time',
    advertisement: 'Advertisement',
    advertisementPeriod: 'views for all time',
    advertisementStatistic: 'Ads Views',
    advertisementStatisticByDates: 'by dates',
    advertisementStatisticPeriod: 'for this month',
    advertisementTop3: 'top 3',
    advertisementTop5: 'top',
    advertisementdays: 'top views days',
    advertisementTotal: 'Total',
    advertisementStatisticTotal: 'Total views',
    advertisementViews: 'views',
    recomendation: 'Recommendation',
    showMore: 'show more',
    noLocations: 'no locations',
    connectionsCaption: 'Connections'
  },
  wifiAnalyzer: {
    channel: 'Channel',
    quality: 'Quality',
    signal: 'Signal',
    wlanChars: 'Characteristics of WLANs'
  },
  account: {
    enterOldPasswordPlaceholder: 'Enter old password',
    enterNewPasswordPlaceholder: 'Enter new password',
    confirmNewPasswordPlaceholder: 'Confirm new password',
    enterEmailPlaceholder: 'Enter email',
    addEmail: 'add email',
    save: 'Save',
    changePassword: 'Change password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    change: 'Change',
    attention: 'Attention!',
    attentionMessage: 'All user sessions of this client will be disabled. Confirm the password change?',
    confirm: 'Confirm',
    newPhoto: 'New photo',
    changePasswordButton: 'Change password'
  },
  imgAndVideoRecommendations: {
    adsAndPollsImgMobile: 'For smartphones. Recommended size 1080*1920',
    adsAndPollsImgDesktop: 'For tablets and laptops. Recommended size 1920*1080',
    adsAndPollsVideo: 'Recommended size 1080*1920',
    pagesLogoTop: 'Recommended width 400px',
    pagesLogoFooter: 'Recommended width 400px',
    pagesBackground: ''
  },
  usersDemoWizard: {
    headerCaption: 'User with demo data creating wizard',
    step1Caption: 'Create Location',
    step2Caption: 'Create Admin for created location',
    step3Caption: 'Create WLAN',
    step4Caption: 'Create Profile',
    step5Caption: 'Create Page',
    step1Header: 'Step 1: Create Location',
    step2Header: 'Step 2: Create Admin for Location',
    step3Header: 'Step 3: Create WLAN',
    step4Header: 'Step 4: Create Profile',
    step5Header: 'Step 5: Create Page',
    email: 'Email',
    step3SuiteRequiredsError: 'The suites field is required',
    step4FieldProfileName: 'Profile Name',
    step5FieldPageName: 'Page Name',
    step1ErrorLocationWithThisNameAlredyExists: 'Location with this name already exists',
    step2ErrorUserWithThisNameAlredyExists: 'User with this name already exists'
  },
  SNMPMonitoringConfig: {
    tableColumnTitleServer: 'Server',
    tableColumnTitleSNMPStatus: 'SNMP Status',
    tableColumnTitleIP: 'IP',
    SNMPMonitoringConfigTitle: 'SNMP Monitoring Config',
    SNMPMonitoringConfigEnabled: 'Enable SNMP',
    SNMPMonitoringConfigCheckAgent: 'Monitor the agent',
    SNMPMonitoringConfigSaveState: 'Save polling state in the database',
    SNMPMonitoringConfigCommunity: 'Community',
    SNMPMonitoringConfigCommunityPlaceHolder: 'Enter community',
    SNMPMonitoringConfigNotificationCreated: 'SNMP Monitoring Config Created Successfully',
    SNMPMonitoringConfigNotificationUpdated: 'SNMP Monitoring Config Updated Successfully',
    SNMPMonitoringConfigNotificationDeleted: 'SNMP Monitoring Config Deleted Successfully',
    SNMPMonitoringNeedToEnableSNMPInCPESettings:
      'For SNMP monitoring to work correctly, you must enable the SNMP agent in the AP settings',
    SNMPMonitoringCPEMustBeAccessibleByIPFromServer: 'The AP must be accessible by IP from the server'
  },
  SNMPStat: {
    SNMPStatTitle: 'SNMP Statistic',
    id: 'Id',
    name: 'Name',
    nas_ip: 'NAS IP',
    client: 'Client',
    address: 'Address',
    availability: 'Availability',
    uptime: 'Uptime',
    time_stamp: 'Timestamp',
    associated_users: 'Associated users',
    associated_users_small: 'Assoc. users',
    wlans: 'WLANs',
    traffic_tx_volume: 'Traffic TX',
    traffic_rx_volume: 'Traffic RX',
    traffic_tx_speed: 'Traffic TX speed',
    traffic_rx_speed: 'Traffic RX speed',
    availabilityAvailably: 'Available',
    availabilityNOTAvailably: 'Not available',
    sortUptime: 'By Uptime',
    sortAvailability: 'By Availability',
    sortAssociatedUsers: 'By Associated users',
    sortTrafficTX: 'By Traffic TX',
    sortTrafficRX: 'By Traffic RX',
    sortTrafficTXSpeed: 'By Traffic TX speed',
    sortTrafficRXSpeed: 'By Traffic RX speed',
    sortClient: 'By Client name',
    sortNumberOfWLANs: 'By Number Of WLANs',
    sortLocation: 'By Location',
    sortName: 'By Name',
    sortNASIP: 'By NAS IP',
    sortTimestamp: 'By Timestamp',
    filtersAvailablyAll: 'All',
    filtersAvailablyAvailably: 'Available',
    filtersAvailablyNotAvailably: 'Not Available'
  },
  support,
  alert: {
    licenseEnds: {
      text: 'Your license ends!',
      link: 'Click here to configure.'
    },
    lowOfLicenses: {
      text: 'There are only one AP (or less) licenses left. If you want to use more APs, you should add licenses',
      link: 'To licenses.'
    },
    lowOfLicensesPortals: {
      text: 'There are only one Portal (or less) licenses left. If you want to use more Portals, you should add licenses',
      link: 'To licenses.'
    },
    lowOfLicensesAnalytics: {
      text: 'There are only one Analytics (or less) licenses left. If you want to use more Analytics, you should add licenses',
      link: 'To licenses.'
    }
  },
  registerMyCPEPlease: {
    cpeId: 'Device ID',
    cpeRegistrationHeader: 'Device Registration',
    cpeRegisterButton: 'Register',
    goToAllCpeListButton: 'Go to the list of devices',
    cpeAlreadyRegisteredInfoMessage: 'A device with this ID is already registered',
    deviceMoreInfo: 'Device — access point or server'
  },
  DITStat: {
    tableTitle: 'DIT Statistics exports',
    tableColumnId: 'ID',
    tableColumnCPE: 'APs',
    tableColumnInterval: 'Export Interval',
    tableColumnFormat: 'Format',
    tableColumnFtpServer: 'FTP Server',
    tableColumnFtpLogin: 'Username',
    tableColumnFtpPassword: 'Password',
    tableColumnPrivateKey: 'Private key',
    tableColumnPrivateKeyInfo: 'The private key can only be used for sftp servers',
    tableColumnEnabled: 'Status',
    modalHeaderForNew: 'New DIT Export',
    modalHeaderForEdit: 'Edit DIT Export',
    modalEnebledCaption: 'Enabled',
    modalCPEsCaption: 'APs',
    modalFTPServerCaption: 'FTP Server',
    modalFTPServerPlaceholder: 'Enter the FTP server address in the ip: port format (for example 127.0.0.1:21)',
    modalLoginCaption: 'Username for FTP Server',
    modalLoginPlaceholder: 'Enter the FTP server username',
    modalPasswordCaption: 'Password for FTP Server',
    modalPasswordPlaceholder: 'Enter the FTP server password',
    modalPrivateKeyCaption: 'Private key for SFTP Server',
    modalPrivateKeyPlaceholder: 'Enter the Private key for SFTP server',
    modalPrivateKeyChangeButton: 'Change the Private key',
    modalSFTPAuthTypeCaption: 'Select the type of authentication for the SFTP server',
    SFTPAuthTypePassword: 'Password',
    SFTPAuthTypePrivateKey: 'Private Key',
    modalIntervalCaption: 'Export Interval',
    modalFormatCaption: 'Export format',
    modalIntervalPlaceholder: 'Enter interval in minutes for exporting statistics',
    modalIntervalMinutes: 'in minutes',
    DITStatNotificationCreated: 'DIT Statistics Export Created Successfully',
    DITStatNotificationUpdated: 'DIT Statistics Export Updated Successfully',
    DITStatNotificationDeleted: 'DIT Statistics Export Deleted Successfully',
    createDitButton: 'Create DIT Export',
    deleteDitModalHeader: 'Deleting DIT Export',
    deleteDitModalText: 'Deleting DIT Export',
    filtersForCpeListSwitcherLocationCaption: 'AP location',
    filtersForCpeListSwitcherInfoTooltip:
      'Filtering makes it easy to find the desired AP in the AP selection list. Allows you to filter access points by their location',
    filtersForCpeListSwitcherCaption: 'Filtering a list of AP',
    filtersForCpeListFiltringForCpeListIsEnabled: 'enabled',
    selectAllCpesButtonCaption: 'Add All Filtered APs',
    cancelSelectAllCpesButtonCaption: 'Clear all selected APs',
    filtersForCpeListResetFiltersButton: 'reset filters',
    modalCpeListPlaceholder: 'Select APs or enter name for search',
    modalCpeListNoOptionsMsg: 'No APs',
    modalCpeListNoOptionsMsgWhenFilterEnabled: 'No APs matching filter conditions',
    modalCpeListNoResultMsg: 'No APs with the same name was found',
    modalCpeListNoResultMsgWhenFilterEnabled: 'No APs with the same name matching the filter conditions was was found',
    modalSelectedCPEsCaption: 'Selected',
    modalSelectedCpesLengthError: 'Select at least one AP',
    sftpSwitcherCaption: 'Enable SFTP',
    sftpSwitcherInfo: 'Allows you to use SFTP server for export'
  },
  DBState: {
    tableTitle: 'DB State',
    tableColumnNs: 'Collection Name',
    tableColumnCount: 'Count of objects',
    tableColumnSize: 'Data size',
    tableColumnStorageSize: 'Data size on disk',
    sortingSortByNs: 'By Collection Name',
    sortingSortByCount: 'By Count of objects',
    sortingSortBySize: 'By Data size',
    sortingSortByStorageSize: 'By Data size on disk',
    Mbytes: 'MBytes',
    Kbytes: 'KBytes',
    Bytes: 'Bytes',
    Gbytes: 'GBytes',
    MbytesShort: 'MB',
    KbytesShort: 'KB',
    BytesShort: 'B',
    GbytesShort: 'GB',
    units: 'Units'
  },
  PeriodicTasks: {
    tableTitle: 'Periodic Tasks',
    PeriodicTasksNotificationCreated: 'Periodic Task Created Successfully',
    PeriodicTasksNotificationUpdated: 'Periodic Task Export Updated Successfully',
    PeriodicTasksNotificationDeleted: 'Periodic Task Export Deleted Successfully',
    createPeriodicTaskButton: 'Create Periodic Task',
    modalHeaderForNew: 'New Periodic Task',
    modalHeaderForEdit: 'Edit Periodic Task',
    modalNameCaption: 'Name',
    modalNamePlaceholder: 'Name',
    modalActionTypeCaption: 'Action Type',
    modalActionTypePlaceholder: 'Action Type',
    modalNotifyTypeCaption: 'Notification type',
    modalNotifyTypePlaceholder: 'Notification type',
    actionType: {
      change_password: 'Change WLAN Password',
      reboot_point: 'Reboot AP'
    },
    modalSpecCaption: 'Periodicity',
    modalSpecPlaceholder: 'Periodicity',
    modalSpecRawCaption: 'Periodicity in custom format (cron-expression)',
    modalSpecRawPlaceholder: 'Periodicity in custom format (cron-expression)',
    modalSpecRawInfoMsg: 'You can read more about the format here',
    notifyType: {
      telegram: 'Telegram',
      email: 'Email',
      mqtt: 'MQTT'
    },
    tableColumnName: 'Name',
    tableColumnActionType: 'Action Type',
    tableColumnCPEs: 'APs',
    tableColumnWlans: 'WLANs',
    tableTooltipCPEs: 'APs',
    tableTooltipWlans: 'WLANs',
    tableColumnObjects: 'APs/WLANs',
    tableColumnSpec: 'Periodicity',
    tableColumnNotifyType: 'Notification Type',
    tableColumnNotifyParameters: 'Notification Parameters',
    deletePeriodicTaskModalHeader: 'Deleting Periodic Task',
    deletePeriodicTaskModalText: 'Deleting DIT Export',
    notifySettingsTelegramTokenCaption: 'Token',
    notifySettingsTelegramTokenPlaceholder: 'Token',
    notifySettingsTelegramTokenInfo: 'Token obtained from @BotFather',
    notifySettingsTelegramChatIdCaption: 'Chat Id',
    notifySettingsTelegramChatIdPlaceholder: 'Chat Id',
    notifySettingsTelegramChatIdInfo: 'Unique identifier of the telegram chat',
    notifySettingsTelegramTextCaption: 'Text',
    notifySettingsTelegramTextPlaceholder: 'Text For Notify',
    notifySettingsEmailToCaption: 'To',
    notifySettingsEmailToPlaceholder: 'Email address',
    notifySettingsEmailSubjectCaption: 'Subject',
    notifySettingsEmailSubjectPlaceholder: 'Subject for email',
    notifySettingsEmailTextCaption: 'Text',
    notifySettingsEmailTextPlaceholder: 'Text email',
    notifySettingsMQTTTopicCaption: 'Topic',
    notifySettingsMQTTTopicPlaceholder: 'Topic',
    notifySettingsMQTTTopicInfo: 'MQTT Topic',
    notifySettingsMQTTTextCaption: 'Text',
    notifySettingsMQTTTextPlaceholder: 'Text',
    notifySettingsForTableCaption: 'Action parameters',
    notifySettingsForTableForTelegramToChatId: 'Message to chat with id',
    notifySettingsForTableForEmailToAddress: 'Mail to address',
    notifySettingsForTableForMQTTToTopic: 'Message into topic',
    notifySettingsForTableShowMore: 'show more',
    notifySettingsForTableHide: 'hide',
    filtersForCpeListSwitcherLocationCaption: 'AP location',
    filtersForCpeListSwitcherInfoTooltip:
      'Filtering makes it easy to find the desired AP in the AP selection list. Allows you to filter access points by their location',
    filtersForCpeListSwitcherCaption: 'Filtering a list of AP',
    filtersForCpeListFiltringForCpeListIsEnabled: 'enabled',
    filtersForWlanListSwitcherLocationCaption: 'WLAN location',
    filtersForWlanListSwitcherInfoTooltip:
      'Filtering makes it easy to find the desired WLAN in the WLAN selection list. Allows you to filter WLANs by their location',
    filtersForWlanListSwitcherCaption: 'Filtering a list of WLAN',
    filtersForWlanListFiltringForWlanListIsEnabled: 'enabled',
    selectAllCpesButtonCaption: 'Add All Filtered APs',
    selectAllWlansButtonCaption: 'Add All Filtered WLANS',
    cancelSelectAllCpesButtonCaption: 'Clear all selected APs',
    cancelSelectAllWlansButtonCaption: 'Clear all selected WLANS',
    filtersForCpeListResetFiltersButton: 'reset filters',
    modalCpeListPlaceholder: 'Select APs or enter name for search',
    modalCpeListNoOptionsMsg: 'No APs',
    modalCpeListNoOptionsMsgWhenFilterEnabled: 'No APs matching filter conditions',
    modalCpeListNoResultMsg: 'No APs with the same name was found',
    modalCpeListNoResultMsgWhenFilterEnabled: 'No APs with the same name matching the filter conditions was was found',
    modalSelectedCPEsCaption: 'Selected',
    modalSelectedCpesLengthError: 'Select at least one AP',
    modalWlanListPlaceholder: 'Select WLAN or enter name for search',
    modalWlanListNoOptionsMsg: 'No WLANS',
    modalWlanListNoOptionsMsgWhenFilterEnabled: 'No WLANS matching filter conditions',
    modalWlanListNoResultMsg: 'No WLANS with the same name was found',
    modalWlanListNoResultMsgWhenFilterEnabled:
      'No WLANS with the same name matching the filter conditions was was found',
    modalSelectedWlansCaption: 'Selected',
    modalSelectedWlansLengthError: 'Select at least one WLAN',
    modalCPEsCaption: 'APs',
    modalWlansCaption: 'WLANS',
    'specFieldCaption@every': 'Period',
    'specFieldInfo@every': 'Runs at a specified frequency',
    'specFieldCaption@hourly': 'Hourly',
    'specFieldInfo@hourly': 'Run once an hour, beginning of hour',
    'specFieldCaption@daily': 'Daily',
    'specFieldInfo@daily': 'Run once a day, midnight',
    'specFieldCaption@weekly': 'Weekly',
    'specFieldInfo@weekly': 'Run once a week, midnight between Sat/Sun ',
    'specFieldCaption@monthly': 'Monthly',
    'specFieldInfo@monthly': 'Run once a month, midnight, first of month',
    'specFieldCaption@yearly': 'Yearly',
    'specFieldInfo@yearly': 'Run once a year, midnight, Jan. 1st',
    specFieldCaptionraw: 'Сustom format',
    specFieldInforaw: 'string in custom format (cron-expression)',
    daysShort: 'd',
    hoursShort: 'h',
    minutesShort: 'm',
    secondsShort: 's',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    allZerosInSpecEveryDurationObjectError: 'At least one field (days, hours, minutes, seconds) must be greater than 0',
    period: 'Period',
    sortName: 'By name',
    sortActionType: 'By Action Type',
    sortCpesCount: 'By AP Count',
    sortObjectsCount: 'By Objects Count',
    sortNotifyType: 'By Notify Type',
    onlyForWpaWpa2PersonalWLANSMsg:
      'Password change works only for WPA / WPA2 Personal WLANs (Only such WLANs are shown in the list for selecting WLANs)',
    onlyForWpaPersonalWLANSMsg:
      'Password change works only for WPA Personal WLANs (Only such WLANs are shown in the list for selecting WLANs)'
  },
  downloadFilesNames: {
    visitors: 'Visitors',
    users: 'Users',
    statSystem: 'Statistic-Locations',
    statCPEs: 'Statistic-APs',
    statWLANs: 'Statistic-WLANs',
    statClients: 'Statistic-Clients',
    statClientsSessions: 'Statistic-Clients-Sessions',
    statSNMP:'Statistic-SNMP',
    monitoringEvents:'Monitoring-Events',
    monitoringCPEConnectivity: 'Monitoring-AP connectivity',
    monitoringDBState: 'Monitoring-DB State',
    cpes: 'APs',
    clients: 'Clients',
    licenses: 'Licenses',
    portalHotelVouchers: 'Hotel vouchers',
    portalInAdsAndPollsStatisticSection: 'Ads Views statistics',
    portalInAdsAndPollsStatisticSectionAll: 'all types',
    portalInAdsAndPollsStatisticSectionBy: 'by authorization type',
    portalAccessServers: 'Portal-Access Servers',
    portalAccounts: 'Portal-Accounts',
    portalBirthdays: 'Portal-Birthdays for',
    portalAdsAndPollsStatisticSection: 'Ads Views statistics',
    portalAdsAndPollsStatisticSectionAll: 'all types',
    portalAdsAndPollsStatisticSectionBy: 'by authorization type',
    portalVouchers: 'Vouchers',
    analyticsVisitors: 'Analytics-Visitors',
    portalStatistic: 'Portal-Statistic',
    portalStatisticPieChartUsers: 'Users',
    portalStatisticPieChartBrowsers: 'Browsers',
    portalStatisticPieChartOperatingSystems: 'Operating Systems',
    portalStatisticPieChartLocale: 'Locale',
    portalStatisticPieChartDeviceType: 'Device type',
    portalStatisticPieChartVendors: 'Vendors',
    portalStatisticPieChartAuthorizationType: 'Authorization type',
    portalStatisticPieChartAuthenticationType: 'Authentication type',
    portalStatisticLineChartNewAndRepeatUsers: 'New and repeat users',
    portalStatisticLineChartOperatingSystems: 'Operating Systems',
    portalStatisticLineChartLocale: 'Locale',
    portalStatisticLineChartDeviceType: 'Device type',
    portalStatisticLineChartVendors: 'Vendors',
    portalStatisticLineChartAuthorizationType: 'Authorization type',
    portalStatisticLineChartAuthenticationType: 'Authentication type',

  },
  configExportImport: {
    configExportHeader: 'Export Config',
    configImportHeader: 'Import Config',
    exportButtonCaption: 'Export Config',
    importButtonCaption: 'Import Config',
    downloadButtonCaption: 'Download a file',
    saveToFileError: 'Failed to save to file',
    fileParsingError: 'Selected file parsing error',
    forLocation: 'Import for location',
    withChildsTrue: 'with childs',
    withChildsFalse: 'without childs',
    configSuccessfullyImported: 'Configuration successfully imported',
    chooseAFile: 'Select a file',
    selectedFile: 'Selected file',
    fileImportCpation: 'File for import',
    viewFileCaption: 'View file'
  }
};

export default locale;
