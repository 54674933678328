<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class="card h-100">
        <div class="card-block">
          <div class="h4 m-0">
            {{ $t('general.step7') }}
            <button
              type="button"
              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"
              @click="lastStepButtonClickHandler"
            >
              {{ $t('wlans.wizardSkipButton') }}
              <info :content="$t('wlans.wizardSkipButtonInfo')" />
            </button>
          </div>
          <span class="text-muted step-caption-in-wlan-wizard" :class="{ 'not-for-cisco': window.CISCO }">
            {{ $t('wlans.wizardGuestControl') }}
          </span>
          <div v-if="window.CISCO">
            <small class="text-danger p-0" v-if="window.CISCO">* {{ $t('wlans.notAvailableForCisco') }}</small>
          </div>
          <form action="">
            <div class="">
              <div class="row mt-2">
                <div class="form-group col-sm-6">
                  <Switch-component
                    v-model="newWlanData.l2isolate"
                    :disabled="isDisable"
                    :label="$t('wlans.L2isolation')"
                    id="l2isolate"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="captive_redirect">{{ $t('wlans.redirect') }}</label>
                <select
                  v-model="newWlanData.guest_control.captive_redirect"
                  name="captive_redirect"
                  id="captive_redirect"
                  :class="{ 'select-disabled': isDisable }"
                  :disabled="isDisable"
                  class="form-control"
                >
                  <option :value="''">
                    {{ $t('wlans.none') }}
                  </option>
                  <option v-for="redirect in redirectsList" :value="redirect.id" :key="redirect.id">
                    {{ redirectName(redirect.id) }}
                  </option>
                </select>
              </div>

              <div class="form-group" :class="{ 'multiselect-disabled-field': isDisable }">
                <label for="radius_mac">{{ $t('wlans.MACRADIUSAuthentication') }}</label>
                <Multiselect
                  id="radius_mac"
                  class="rule-multi"
                  data-vv-name="radius_mac"
                  v-model="updatedWlanRadiusMAC"
                  :multiple="true"
                  :options="radiusList || []"
                  :max="8"
                  trackBy="id"
                  label="name"
                  :disabled="isDisable || !newWlanData.guest_control.captive_redirect"
                  @input="enableSave()"
                  :class="{
                    input: true,
                    'is-danger': errors.has('radius_mac'),
                    'multiselect-disabled': isDisable || !newWlanData.guest_control.captive_redirect
                  }"
                  :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                  :selectLabel="$t('general.multiSelectPressToSelect')"
                  :deselectLabel="$t('general.multiSelectDeselectLabel')"
                  :selectedLabel="$t('general.multiSelectSelected')"
                ></Multiselect>
              </div>

              <div class="form-group">
                <label for="l2_chain">{{ $t('wlans.firewall') }}</label>
                <select
                  v-model="newWlanData.firewall.l2_chain"
                  name="l2_chain"
                  id="l2_chain"
                  :class="{ 'select-disabled': isDisable }"
                  :disabled="isDisable"
                  class="form-control"
                >
                  <option :value="''">
                    {{ $t('wlans.none') }}
                  </option>
                  <option v-for="firewall in firewallsList" :value="firewall.id" :key="firewall.id">
                    {{ firewallName(firewall.id) }}
                  </option>
                </select>
              </div>

              <label for="security">{{ $t('wlans.filterMode') }}</label>
              <div class="form-group mb-0">
                <select
                  id="filtermode"
                  class="form-control"
                  v-model="newWlanData.filtermode"
                  :disabled="isDisable"
                  :class="{ 'select-disabled': isDisable }"
                >
                  <option value="None" :selected="newWlanData.filtermode === 'None'">
                    {{ $t('wlans.none') }}
                  </option>
                  <option value="WhiteList" :selected="newWlanData.filtermode === 'WhiteList'">
                    {{ $t('wlans.whiteList') }}
                  </option>
                  <option value="BlackList" :selected="newWlanData.filtermode === 'BlackList'">
                    {{ $t('wlans.blackList') }}
                  </option>
                </select>
              </div>
              <div class="wlan-filtermode">
                <p v-if="newWlanData.filtermode === 'None'">
                  <small class="text-primary">{{ $t('wlans.none') }}:</small>
                  <small class="text-muted">{{ $t('wlans.filterNoneModeCaption') }}</small>
                </p>
                <p v-if="newWlanData.filtermode === 'WhiteList'">
                  <small class="text-primary">{{ $t('wlans.filterWhitelistMode') }}:</small>
                  <small class="text-muted">{{ $t('wlans.filterWhitelistModeCaption') }}</small>
                </p>
                <p v-if="newWlanData.filtermode === 'BlackList'">
                  <small class="text-primary">{{ $t('wlans.filterBlacklistMode') }}:</small>
                  <small class="text-muted">
                    {{ $t('wlans.filterBlacklistModeCaption') }}
                  </small>
                </p>
              </div>

              <div class="form-group mb-0" v-if="newWlanData.filtermode != 'None'">
                <div class="input-group">
                  <input
                    type="text"
                    @keyup.13="addMacAddress"
                    @input="enableSaveChanges = true"
                    name="mac"
                    class="form-control"
                    placeholder="00:00:00:00:00:00"
                    :disabled="isDisable"
                    v-validate="'mac'"
                    data-vv-validate-on="input"
                    v-model="newMacAddress"
                    :class="{ input: true, 'is-danger': errors.has('mac') }"
                  />
                  <span class="input-group-btn">
                    <button
                      class="btn btn-success mb-0"
                      type="button"
                      @click="addMacAddress"
                      :disabled="(this.newMacAddress == '')"
                    >
                      {{ $t('general.add') }}
                    </button>
                  </span>
                </div>
              </div>

              <div class="ml-h mb-q" v-if="newWlanData.filtermode !== 'None'">
                <small v-if="newWlanData.filtermode === 'WhiteList'">
                  <strong>{{ $t('wlans.currentWhitelistItems') }}: {{ filterList.whitelist.length }}</strong>
                </small>
                <small v-if="newWlanData.filtermode === 'BlackList'">
                  <strong>{{ $t('wlans.currentBlacklistItems') }}: {{ filterList.blacklist.length }}</strong>
                </small>
              </div>
              <ul class="items_list p-0 ml-h">
                <li v-for="(mac, index) in filterList.whitelist" v-if="newWlanData.filtermode === 'WhiteList'">
                  <small
                    data-list="whitelist"
                    :data-index="index"
                    :data-mac="mac"
                    :class="{ 'item-for-delete': !isDisable }"
                    @click="removeMac"
                    class="text-uppercase"
                  >
                    {{ mac }}
                    <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                  </small>
                </li>
                <li v-for="(mac, index) in filterList.blacklist" v-if="newWlanData.filtermode === 'BlackList'">
                  <small
                    data-list="blacklist"
                    :data-index="index"
                    :data-mac="mac"
                    :class="{ 'item-for-delete': !isDisable }"
                    @click="removeMac"
                    class="text-uppercase"
                  >
                    {{ mac }}
                    <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                  </small>
                </li>
              </ul>
            </div>

            <!--            <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
        <div class="buttons-block-warpper">
          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">
            {{ $t('general.next') }}
          </button>
          <!--          <button type="button" class="btn btn-outline-info float-right mr-h"-->
          <!--                  @click="lastStepButtonClickHandler">-->
          <!--            {{ $t('wlans.wizardSkipButton') }}-->
          <!--            <info :content="$t('wlans.wizardSkipButtonInfo')"/>-->
          <!--          </button>-->
          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">
            {{ $t('general.back') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import SwitchComponent from '../../../components/Universal/Switch-component.vue';
import redirectsService from '../../../services/redirectsService';
import radiusService from '../../../services/radiusService';
import commonService from '../../../services/commonService';
import helpers from '../../../helpers';
import Info from '../../../components/Universal/info-icon.vue';

export default {
  name: 'Step7',
  components: { Multiselect, SwitchComponent, Info },
  data() {
    return {
      newMacAddress: '',
      filterList: {
        blacklist: [],
        whitelist: []
      },
      isDisable: false,
      enableSaveChanges: true,
      updatedWlanRadiusMAC: [],
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      }
      // showWlanPass: false,
      // updatedWlanRadiusAuthentication: [],
      // updatedWlanRadiusAccounting: []
    };
  },
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    window() {
      return window;
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    radiusList() {
      return this.$store.state.radiusList;
    },
    firewallsList() {
      return this.$store.state.firewallsList;
    }
  },
  methods: {
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    removeMac(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.filterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.filterList.blacklist.splice(index, 1);
      }
    },
    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.newWlanData.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.filterList.whitelist.push(this.newMacAddress);
              this.newMacAddress = '';
              return;
            }
            return;
          }
          if (this.newWlanData.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.filterList.blacklist.push(this.newMacAddress);
              this.newMacAddress = '';
              return;
            }
            return;
          }
          this.enableSaveChanges = true;
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    firewallName(id) {
      const showFirewallFromId = commonService.firewallNamebyId(id);
      return showFirewallFromId;
    },
    redirectName(id) {
      const redirectName = commonService.redirectNamebyId(id);
      return redirectName;
    },
    enableSave() {
      this.enableSaveChanges = true;
    },
    setNewWlanData(event, toLastStepClicked = false) {
      if (this.newWlanData.filtermode === 'WhiteList' && this.newMacAddress) {
        if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
          this.filterList.whitelist.push(this.newMacAddress);
          this.newMacAddress = '';
          return;
        }
        return;
      }
      if (this.newWlanData.filtermode === 'BlackList' && this.newMacAddress) {
        if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
          this.filterList.blacklist.push(this.newMacAddress);
          this.newMacAddress = '';
          return;
        }
        return;
      }
      const radiusMACIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusMAC);
      this.newWlanData.guest_control.mac_radius_auth_servers = radiusMACIds;
      this.newWlanData.whitelist = this.filterList.whitelist;
      this.newWlanData.blacklist = this.filterList.blacklist;

      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            if (toLastStepClicked === false) {
              this.$parent.nextStep();
            } else {
              // this.$parent.nextStep(true);
              this.$parent.lastStep();
            }
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    redirectsService.getRedirectsRule(this);
    radiusService.getRadius(this);
    // this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
    // цикл ниже чтобы заполннить поле 'MAC RADIUS Авторизация' прежними значениями если возвращаемся на этот шаг
    // из какого-нибудь другого шага
    for (const radiusId of this.newWlanData.guest_control.mac_radius_auth_servers) {
      for (const radiusObj of this.radiusList) {
        if (radiusId === radiusObj.id) {
          this.updatedWlanRadiusMAC.push(radiusObj);
        }
      }
    }
    // наполняем черны и белый списки если возвращаемся на этот шаг
    // из какого-нибудь другого шага
    this.filterList.whitelist = JSON.parse(JSON.stringify(this.newWlanData.whitelist));
    this.filterList.blacklist = JSON.parse(JSON.stringify(this.newWlanData.blacklist));

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
