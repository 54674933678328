/* eslint-disable import/first */
/* eslint-disable import/order */

import { setEnviroment } from './env-vars';

setEnviroment();

// import '../public/static/scss/style.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import accessControl from './access-control';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueNotifications from 'vue-notifications';
import miniToastr from 'mini-toastr';
import VeeValidate from 'vee-validate';
import en from 'vee-validate/dist/locale/en';
import ru from 'vee-validate/dist/locale/ru';
import Validators from './validators';
import i18n from './locales';
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import heatmap from 'highcharts/modules/heatmap';
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import VueMasonry from 'vue-masonry-css';

import leafletheat from 'leaflet.heat/dist/leaflet-heat';
import leafletfullscreen from 'leaflet-fullscreen/dist/Leaflet.fullscreen.min';
import leafletsearch from 'leaflet-search/dist/leaflet-search.min.css';
import JsonCSV from 'vue-json-csv';
import JsonExcel from 'vue-json-excel';
import DownloadExtract from './components/download-extract.vue';
import DownloadExtractV2 from './components/download-extractV2.vue';
// fix for vue2leaflet
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { themeControl } from './theme-control';
import LoaderSpinner from './components/loaderSpinner.vue';
import LoaderSpinnerSmall from './components/loaderSpinnerSmall.vue';
import TextHighlight from 'vue-text-highlight';

Vue.component('text-highlight', TextHighlight);

themeControl();

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

miniToastr.init();
function toast({
  title, message, type, timeout, cb
}) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

Vue.use(VueNotifications, options);
Vue.use(VueAxios, axios);
Vue.use(VTooltip);
Vue.use(VueClipboard);
Vue.component('downloadCsv', JsonCSV);
Vue.component('downloadExcel', JsonExcel);
Vue.component('DownloadExtract', DownloadExtract);
Vue.component('DownloadExtractV2', DownloadExtractV2);
Vue.use(VeeValidate, {
  locale: window.LOCALE ? window.LOCALE.toLowerCase() : 'en',
  fieldsBagName: 'veeFields',
  dictionary: {
    en,
    ru
  }
});
Vue.use(VueMasonry);
Vue.use(accessControl);
Vue.use(VueHighcharts);
Vue.use(VueHighcharts, { Highcharts });
heatmap(Highcharts);
Vue.component('LoaderSpinner', LoaderSpinner);
Vue.component('LoaderSpinnerSmall', LoaderSpinnerSmall);

// Custom Validators
const validatorNames = Object.keys(Validators);
validatorNames.forEach((name) => {
  VeeValidate.Validator.extend(name, Validators[name]);
});

const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: { App }
});
export default app;
