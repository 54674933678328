<template>
  <modal v-model="isOpen" class="modal-warning" effect="fade/zoom">
    <h4 slot="modal-title" class="modal-title">{{ $t('general.closeModal') }}</h4>
    <span>{{ $t('general.closeModalConfirm') }}</span>
    <div slot="modal-footer" class="modal-footer">
      <button type="button" class="btn btn-secondary ml-auto" @click="handleYes">
        {{ $t('general.yes') }}, {{ $t('general.close').toLowerCase() }}
      </button>
      <button type="button" class="btn btn-secondary ml-1" @click="handleNo">
        {{ $t('general.no') }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'CloseDialog',
  components: { Modal },
  props: {
    isOpen: { type: Boolean, required: true },
    handleYes: { type: Function, default: () => {} },
    handleNo: { type: Function, default: () => {} }
  }
};
</script>
