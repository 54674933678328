<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class="card h-100">
        <div class="card-block">
          <div class="h4 m-0">
            {{ $t('general.step2') }}
            <button
              type="button"
              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"
              @click="lastStepButtonClickHandler"
            >
              {{ $t('wlans.wizardSkipButton') }}
              <info :content="$t('wlans.wizardSkipButtonInfo')" />
            </button>
          </div>
          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('wlans.wizardSecuritySettings') }}</span>
          <form action="">
            <transition name="infoMessageForWPA3InCreatingWlanWizard" mode="out-in">
              <div v-if="checkWPA3Type(newWlanData.security.type)"
                   class="form-group mt-1">
                <Alert class="alert-info">
                  <template #text>
                    <span class="mr-h">{{ $t('wlans.wpa3IsNotSupportedOnAllCPE') }}</span>
                  </template>
                </Alert>
              </div>
            </transition>
            <div class="form-group mt-1">
              <label for="wlan-security">{{ $t('wlans.security') }}</label>

              <select
                id="wlan-security"
                class="form-control"
                v-model="newWlanData.security.type"
                @change="setSecurityType(newWlanData.security.type)"
              >
                <option value="" :selected="newWlanData.security.type === ''">{{ $t('wlans.securityOpen') }}</option>
                <option value="wpapersonal" :selected="newWlanData.security.type === 'wpapersonal'">
                  WPA Personal
                </option>
                <option value="wpaenterprise" :selected="newWlanData.security.type === 'wpaenterprise'">
                  WPA Enterprise
                </option>
                <option value="wpa2personal" :selected="newWlanData.security.type === 'wpa2personal'">
                  WPA2 Personal
                </option>
                <option value="wpa2enterprise" :selected="newWlanData.security.type === 'wpa2enterprise'">
                  WPA2 Enterprise
                </option>
                <option value="wpa3personal"
                        :selected="newWlanData.security.type === 'wpa3personal'">
                  WPA3 Personal
                </option>
                <option value="wpa3enterprise"
                        :selected="newWlanData.security.type === 'wpa3enterprise'">
                  WPA3 Enterprise
                </option>
                <option value="wpa23personal"
                        :selected="newWlanData.security.type === 'wpa23personal'">
                  WPA2/3 Personal
                </option>
                <option value="wpa23enterprise"
                        :selected="newWlanData.security.type === 'wpa23enterprise'">
                  WPA2/3 Enterprise
                </option>
              </select>
            </div>

            <div class="form-group" v-if="newWlanData.security.type.indexOf('enterprise') !== -1">
              <label for="radius">{{ $t('radius.radius') }}</label>
              <multiselect
                class="rule-multi"
                id="radius"
                v-model="updatedWlanRadiusAuthentication"
                :multiple="true"
                :options="radiusList || []"
                :max="8"
                track-by="id"
                v-validate="'required'"
                label="name"
                name="RADIUS Authentication"
                :class="{ input: true, 'is-danger': errors.has('RADIUS Authentication') }"
                select-label="add"
                deselect-label="remove"
                :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              ></multiselect>
              <span v-show="errors.has('RADIUS Authentication')" class="help is-danger mt-0 mb-1">
                {{ errors.first('RADIUS Authentication') }}
              </span>
            </div>

            <div
              class="form-group"
              v-if="newWlanData.security && newWlanData.security.type.indexOf('enterprise') !== -1"
            >
              <label for="hotspot20_profile">{{ $t('wlans.hotspotProfileTitle') }}</label>
              <select
                v-model="newWlanData.security.data.hotspot20_profile"
                name="hotspot20_profile"
                id="hotspot20_profile"
                class="form-control"
              >
                <option :value="''">
                  {{ $t('wlans.hotspotProfileNone') }}
                </option>
                <option v-for="hotspot in hotspotsList" :value="hotspot.id" :key="hotspot.id">
                  {{ hotspot.name }}
                </option>
              </select>
            </div>

            <div role="group" class="form-group row" v-if="newWlanData.security.type.indexOf('personal') !== -1">
              <div class="col-12">
                <label for="wlan_password">{{ $t('general.password') }}</label>
                <div role="group" class="input-group">
                  <input type="text" style="display: none;" />
                  <input type="password" style="display: none;" />
                  <input
                    :type="showWlanPass ? 'text' : 'password'"
                    id="wlan_password"
                    autocomplete="off"
                    name="wlan_password"
                    :placeholder="$t('wlans.enterWlanPasswordPlaceholder')"
                    class="form-control"
                    v-validate="'required|min:8|max:63'"
                    :class="{ input: true, 'is-danger': errors.has('wlan_password') }"
                    v-model="newWlanData.security.data.psk"
                  />
                  <div class="input-group-addon hovered" @click="showWlanPass = !showWlanPass">
                    <i class="fa fa-eye" v-if="showWlanPass"></i>
                    <i class="fa fa-eye-slash" v-if="!showWlanPass"></i>
                  </div>
                  <Password-generator
                    @passwordGenerated="passwordGeneratedForCreatedWlan"
                    class="ml-1"
                  ></Password-generator>
                </div>
                <span v-show="errors.has('wlan_password')" class="help is-danger">
                  {{ errors.first('wlan_password') }}
                </span>
              </div>
            </div>

            <div class="form-group" v-if="newWlanData.security.type !== ''">
              <label for="suites">{{ $t('wlans.suites') }}</label>
              <multiselect
                id="suites"
                v-model="newWlanData.security.data.suites"
                :multiple="true"
                :options="(newWlanData.security.type.indexOf('2') !== -1 || newWlanData.security.type.indexOf('3') !== -1) ? ['aes', 'tkip'] : ['tkip']"
                :placeholder="$t('wlans.suitesPlaceholder')"
                :class="{ input: true, 'is-danger': isSuitesValidation }"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              ></multiselect>
              <span v-show="isSuitesValidation" class="text-danger">The suites field is required</span>
            </div>

            <!--            <button-->
            <!--              type="button"-->
            <!--              :disabled="isSuitesValidation"-->
            <!--              class="btn btn-outline-success float-right"-->
            <!--              @click="setNewWlanData"-->
            <!--            >-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
        <div class="buttons-block-warpper">
          <button
            type="button"
            :disabled="isSuitesValidation"
            class="btn btn-outline-success float-right"
            @click="setNewWlanData"
          >
            {{ $t('general.next') }}
          </button>
          <!--          <button type="button" class="btn btn-outline-info float-right mr-h"-->
          <!--                  @click="lastStepButtonClickHandler">-->
          <!--            {{ $t('wlans.wizardSkipButton') }} <info :content="$t('wlans.wizardSkipButtonInfo')"/>-->
          <!--          </button>-->
          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">
            {{ $t('general.back') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import radiusService from '../../../services/radiusService';
import helpers from '../../../helpers';
import PasswordGenerator from '../../../components/PasswordGenerator.vue';
import hotspotService from '../../../services/hotspotService';
import Info from '../../../components/Universal/info-icon.vue';
import Alert from '@/components/Universal/alert/alert.vue';

// const WPA3_TYPES = ['wpa3personal', 'wpa3enterprise', 'wpa23personal', 'wpa23enterprise']

export default {
  name: 'Step2',
  components: { Multiselect, PasswordGenerator, Info, Alert },
  data() {
    return {
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      },
      radiusList: [],
      showWlanPass: false,
      updatedWlanRadiusAuthentication: []
    };
  },
  computed: {
    wpa3Types() {
      // return WPA3_TYPES;
      return this.$store.getters.getWPA3Types;
    },
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    hotspotsList() {
      return this.$store.state.hotspotsList;
    },
    isSuitesValidation() {
      return this.newWlanData.security.data.suites.length == 0;
    }
  },
  methods: {
    checkWPA3Type(type) {
      if (this.wpa3Types.includes(type)) {
        return true;
      }
      return false
    },
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    passwordGeneratedForCreatedWlan(generatedPassword) {
      // показываем пароль если был скрыт
      this.showWlanPass = true;
      // console.log(generatedPassword);
      this.newWlanData.security.data.psk = generatedPassword;
    },
    setNewWlanData(event, toLastStepClicked = false) {
      let fieldsToValidate = {};
      if (this.newWlanData.security.type.indexOf('personal') !== -1) {
        fieldsToValidate = {
          password: this.newWlanData.security.data.psk
        };
      }
      if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
        const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
        this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
      }
      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            if (toLastStepClicked === false) {
              this.$parent.nextStep();
            } else {
              // this.$parent.nextStep(true);
              this.$parent.lastStep();
            }
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
  //   setSecurityType(type) {
  //     if (type !== 'open' && type.indexOf('2') === -1) {
  //       const isAesSelected = this.newWlanData.security.data.suites.indexOf('aes');
  //       if (isAesSelected !== -1) {
  //         this.newWlanData.security.data.suites = ['tkip'];
  //       }
  //     }
  //     if (type !== 'open' && type.indexOf('2') != -1) {
  //       this.newWlanData.security.data.suites = ['aes'];
  //     }
  //   }
  // },
    setSecurityType(type) {
      // console.log(type)
      if (type !== 'open' && type.indexOf('2') === -1) {
        const isAesSelected = this.newWlanData.security.data.suites.indexOf('aes');
        if (isAesSelected !== -1) {
          this.newWlanData.security.data.suites = ['tkip'];
        }
      }
      if (type !== 'open' && (type.indexOf('2') != -1 || type.indexOf('3') != -1)) {
        this.newWlanData.security.data.suites = ['aes'];
      }
    }
  },
  created() {
    hotspotService.getHotspots(this);
    radiusService.getRadius(this);
    this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
    // цикл ниже чтобы заполннить поле 'RADIUS' прежними значениями если возвращаемся на этот шаг
    // из какого-нибудь другого шага
    for (const radiusId of this.newWlanData.security.data.radiusauthentication) {
      for (const radiusObj of this.radiusList) {
        if (radiusId === radiusObj.id) {
          this.updatedWlanRadiusAuthentication.push(radiusObj);
        }
      }
    }

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
    .infoMessageForWPA3InCreatingWlanWizard-enter-active,
.infoMessageForWPA3InCreatingWlanWizard-leave-active {
  transition: opacity 0.3s;
}

.infoMessageForWPA3InCreatingWlanWizard-enter,
.infoMessageForWPA3InCreatingWlanWizard-leave-to {
  opacity: 0;
}
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
