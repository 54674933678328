<template></template>

<script>
import auth from '../auth/auth';

export default {
  name: 'UserActivityWatcher',
  data() {
    return {
      lastUserActivityTimestamp: undefined,
      lastUserActivityChangeLocked: false,
      lastUserActivityChangeLockTimeoutID: undefined,
      lastUserActivityTimestampCheckerIntervalID: undefined
    };
  },
  props: {
    elementIdForEventsListenProp: { type: String, default: 'app' },
    compareNowTimestampAndLastUserActivityTimestampIntervalProp: { type: Number, default: 30 }, // время в сек между сравнениями текущего timestamp и timestamp последней активности юзера
    showConsoleLogMessagesProp: { type: Boolean, default: false } // для вывода в консоль информации по времени последней активности
  },
  created() {
    this.lastUserActivityTimestamp = Date.now();
  },
  mounted() {
    if (this.isLogoutAfterUserInactivityEnabled) {
      this.startUserActivityWatch();
    } else {
      console.log('User Activity Watcher Disabled');
    }
  },
  beforeDestroy() {
    if (this.isLogoutAfterUserInactivityEnabled) {
      this.stopUserActivityWatch();
    }
  },
  methods: {
    showInfoInConsole(...params) {
      try {
        if (this.isShowConsoleLogMessages) {
          console.log(...params);
        }
      } catch (e) {
        console.log(e);
      }
    },
    convertTimestampForShowInConsoleMessages(timestamp) {
      try {
        const date = new Date(timestamp);
        return date.toLocaleTimeString();
      } catch (e) {
        console.log(e);
        return timestamp;
      }
    },
    startUserActivityWatch() {
      console.log('User Activity Watcher Started');
      if (!this.lastUserActivityTimestamp) {
        this.lastUserActivityTimestamp = Date.now();
      }
      this.startMousemoveWatch(); // отслеживаем движения мышью
      this.startMousescrollWatch(); // отслеживаем прокручивание колесом мышки
      this.startDocumentVisibilityChangeWatch(); // отслеживаем переключения между вкладками
      this.startLastUserActivityTimestampChecker(); // запускаем проверки текущего времени и времени последней актвности
    },
    stopUserActivityWatch() {
      console.log('User Activity Watcher Stoped');
      this.stopMousemoveWatch();
      this.stopMousescrollWatch();
      this.stopDocumentVisibilityChangeWatch();
      this.clearLastUserActivityChangeLockTimeout();
      this.stopLastUserActivityTimestampChecker();
    },
    startLastUserActivityTimestampChecker() {
      this.clearLastUserActivityTimestampCheckerInterval();
      this.lastUserActivityTimestampCheckerIntervalID = setInterval(() => {
        const nowTimestamp = Date.now();
        const lastUserActivityTimestamp = this.lastUserActivityTimestamp;

        if (nowTimestamp - lastUserActivityTimestamp >= this.logoutAfterUserInactivityTimeInMilliSeconds) {
          console.log('User not active');
          console.log('Last User Activity Time', this.convertTimestampForShowInConsoleMessages(lastUserActivityTimestamp));
          console.log('Now Time', this.convertTimestampForShowInConsoleMessages(nowTimestamp));

          this.clearLastUserActivityTimestampCheckerInterval();
          this.userNotActiveHandler();
        } else {
          this.showInfoInConsole('User is active');
          this.showInfoInConsole('Last User Activity Time', this.convertTimestampForShowInConsoleMessages(lastUserActivityTimestamp));
          this.showInfoInConsole('Now Time', this.convertTimestampForShowInConsoleMessages(nowTimestamp));
          this.showInfoInConsole('Logout Time will be after', this.convertTimestampForShowInConsoleMessages(lastUserActivityTimestamp + this.logoutAfterUserInactivityTimeInMilliSeconds));
          this.showInfoInConsole('---------------------')
        }
      }, this.compareNowTimestampAndLastUserActivityTimestampIntervalInMilliSeconds);
    },
    stopLastUserActivityTimestampChecker() {
      this.clearLastUserActivityTimestampCheckerInterval();
    },
    startMousemoveWatch() {
      const elementForEvents = document.getElementById(this.elementIdForEventsListen);
      if (elementForEvents) {
        elementForEvents.addEventListener('mousemove', this.userMousemoveHandler);
      }
    },
    stopMousemoveWatch() {
      const elementForEvents = document.getElementById(this.elementIdForEventsListen);
      if (elementForEvents) {
        elementForEvents.removeEventListener('mousemove', this.userMousemoveHandler);
      }
    },
    startMousescrollWatch() {
      const elementForEvents = document.getElementById(this.elementIdForEventsListen);
      if (elementForEvents) {
        elementForEvents.addEventListener('wheel', this.userMousescrollHandler);
      }
    },
    stopMousescrollWatch() {
      const elementForEvents = document.getElementById(this.elementIdForEventsListen);
      if (elementForEvents) {
        elementForEvents.removeEventListener('wheel', this.userMousescrollHandler);
      }
    },
    startDocumentVisibilityChangeWatch() {
      document.addEventListener('visibilitychange', this.visibilityChangeEventHandler);
    },
    stopDocumentVisibilityChangeWatch() {
      document.removeEventListener('visibilitychange', this.visibilityChangeEventHandler);
    },
    lockLastUserActivityChangingForPeriod(millisecondsPeriod) {
      this.clearLastUserActivityChangeLockTimeout();
      if (!millisecondsPeriod) {
        return;
      }
      this.lastUserActivityChangeLocked = true;
      this.lastUserActivityChangeLockTimeoutID = setTimeout(() => {
        this.lastUserActivityChangeLocked = false;
      }, millisecondsPeriod);
    },
    clearLastUserActivityChangeLockTimeout() {
      if (this.lastUserActivityChangeLockTimeoutID) {
        clearTimeout(this.lastUserActivityChangeLockTimeoutID);
      }
    },
    clearLastUserActivityTimestampCheckerInterval() {
      if (this.lastUserActivityTimestampCheckerIntervalID) {
        clearInterval(this.lastUserActivityTimestampCheckerIntervalID);
      }
    },
    updateLastUserActivityWithLockItForAWhile(msgForShowInConsole) {
      if (!this.lastUserActivityChangeLocked) {
        // лочим на 5 секунд возможность следующего изменения чтобы Date.now() не вызывалось на каждое движение мышью и не вызывало тормозов
        this.lockLastUserActivityChangingForPeriod(5000);
        this.lastUserActivityTimestamp = Date.now();
        if (msgForShowInConsole) {
          this.showInfoInConsole(msgForShowInConsole, this.convertTimestampForShowInConsoleMessages(this.lastUserActivityTimestamp));
        }
      }
    },
    userMousemoveHandler() {
      this.updateLastUserActivityWithLockItForAWhile('Last mouse move activity at');
    },
    userMousescrollHandler() {
      this.updateLastUserActivityWithLockItForAWhile('Last mouse scroll activity at');
    },
    // visibilityChangeEventHandler() {   // <--- версия которая перестает отслеживать когда была последния активность юзера, когда вкладку не видно (и соотвественно не разлогинивает)
    //   try {
    //     if (document.visibilityState === 'visible') {
    //       this.showInfoInConsole('Start Last User Activity Timestamp Checker', this.convertTimestampForShowInConsoleMessages(Date.now()))
    //       this.startLastUserActivityTimestampChecker();
    //       this.lastUserActivityTimestamp = Date.now();
    //       this.showInfoInConsole('Last visibility change activity at', this.convertTimestampForShowInConsoleMessages(this.lastUserActivityTimestamp));
    //     } else {
    //        this.showInfoInConsole('Stop Last User Activity Timestamp Checker', this.convertTimestampForShowInConsoleMessages(Date.now()))
    //        this.stopLastUserActivityTimestampChecker();
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    visibilityChangeEventHandler() {
      try {
        if (document.visibilityState === 'visible') {
          this.lastUserActivityTimestamp = Date.now();
          this.showInfoInConsole('Last visibility change activity at', this.convertTimestampForShowInConsoleMessages(this.lastUserActivityTimestamp));
        }
      } catch (e) {
        console.log(e);
      }
    },
    logout() {
      this.$store.commit('setShowInactivityLogoutInfoModal', true);
      auth.logout(this);
    },
    userNotActiveHandler() {
      // this.showInfoInConsole('User not active');
      this.logout();
    }
  },
  computed: {
    elementIdForEventsListen() {
      // return ELEMENT_ID_FOR_EVENTS_LISTEN;
      return this.elementIdForEventsListenProp;
    },
    isLogoutAfterUserInactivityEnabled() {
      return !!window.LOGOUT_AFTER_USER_INACTIVITY; // разлогинивание после определенного периода бездествия включено или нет
    },
    logoutAfterUserInactivityTime() {
      return window.LOGOUT_AFTER_USER_INACTIVITY_TIME; // время бездействия в минутах
    },
    logoutAfterUserInactivityTimeInSeconds() {
      return this.logoutAfterUserInactivityTime * 60;
    },
    logoutAfterUserInactivityTimeInMilliSeconds() {
      return this.logoutAfterUserInactivityTimeInSeconds * 1000;
    },
    compareNowTimestampAndLastUserActivityTimestampIntervalInSeconds() {
      return this.compareNowTimestampAndLastUserActivityTimestampIntervalProp;
    },
    compareNowTimestampAndLastUserActivityTimestampIntervalInMilliSeconds() {
      return this.compareNowTimestampAndLastUserActivityTimestampIntervalInSeconds * 1000;
    },
    isShowConsoleLogMessages() {
      return this.showConsoleLogMessagesProp;
    }
  }
};
</script>

<style scoped></style>
