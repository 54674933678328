import VueNotifications from 'vue-notifications';
import Vue from 'vue';
import hotspotPollingService from './hotspotPollingService';

function isRULocale() {
  if (window.LOCALE && window.LOCALE.toLowerCase() === 'ru') {
    return true;
  }
  return false
}

export default {
  ACCESS_NETWORK_TYPE: {
    0: {
      name: isRULocale() ? 'Приватная сеть' : 'Private network'
    },
    1: {
      name: isRULocale() ? 'Приватная сеть с гостевым доступом' : 'Private network with guest access'
    },
    2: {
      name: isRULocale() ? 'Платная публичная сеть' : 'Chargeable public network'
    },
    3: {
      name: isRULocale() ? 'Бесплатная публичная сеть' : 'Free public network'
    },
    4: {
      name: isRULocale() ? 'Сеть на персональном устройстве' : 'Personal device network'
    },
    5: {
      name: isRULocale() ? 'Сеть только с экстренными  сервисами' : 'Emergency services only network'
    },
    14: {
      name: isRULocale() ? 'Тестовая или эксперементальная' : 'Test or experimental'
    },
    15: {
      name: 'Wildcard'
    }
  },

  VENUE_GROUP: {
    0: {
      name: isRULocale() ? 'Неопределенно' : 'Unspecified',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        }
      }
    },
    1: {
      name: isRULocale() ? 'Места массового пребывания людей' : 'Assembly',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Арена' : 'Arena'
        },
        2: {
          name: isRULocale() ? 'Стадион' : 'Stadium'
        },
        3: {
          name: isRULocale() ? 'Пассажирский терминал' : 'Passenger terminal'
        },
        4: {
          name: isRULocale() ? 'Амфитеатр' : 'Amphitheater'
        },
        5: {
          name: isRULocale() ? 'Парк с аттракционами' : 'Amusement park'
        },
        6: {
          name: isRULocale() ? 'Место поклонения' : 'Place of worship'
        },
        7: {
          name: isRULocale() ? 'Конференц-центр' : 'Convention center'
        },
        8: {
          name: isRULocale() ? 'Библиотека' : 'Library'
        },
        9: {
          name: isRULocale() ? 'Музей' : 'Museum'
        },
        10: {
          name: isRULocale() ? 'Ресторан' : 'Restaurant'
        },
        11: {
          name: isRULocale() ? 'Театр' : 'Theater'
        },
        12: {
          name: isRULocale() ? 'Бар' : 'Bar'
        },
        13: {
          name: isRULocale() ? 'Кофейный магазин' : 'Coffee shop'
        },
        14: {
          name: isRULocale() ? 'Зоопарк или Аквариум' : 'Zoo or Aquarium'
        },
        15: {
          name: isRULocale() ? 'Центр координации чрезвычайных ситуаций' : 'Emergency coordination center'
        }
      }
    },
    2: {
      name: isRULocale() ? 'Бизнес' : 'Business',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Кабинет врача или стоматолога' : 'Doctor or Dentist office'
        },
        2: {
          name: isRULocale() ? 'Банк' : 'Bank'
        },
        3: {
          name: isRULocale() ? 'Пожарная часть' : 'Fire station'
        },
        4: {
          name: isRULocale() ? 'Полиция' : 'Police station'
        },
        6: {
          name: isRULocale() ? 'Почта' : 'Post office'
        },
        7: {
          name: isRULocale() ? 'Офис' : 'Professional office'
        },
        8: {
          name: isRULocale() ? 'Научно-исследовательский центр' : 'Research and development facility'
        },
        9: {
          name: isRULocale() ? 'Адвокат' : 'Attorney office'
        }
      }
    },
    3: {
      name: isRULocale() ? 'Образование' : 'Educational',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Начальная школа' : 'Primary school'
        },
        2: {
          name: isRULocale() ? 'Средняя школа' : 'Secondary school'
        },
        3: {
          name: isRULocale() ? 'Университет или колледж' : 'University or College'
        }
      }
    },
    4: {
      name: isRULocale() ? 'Промышленность' : 'Factory or Industrial',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Фабрика' : 'Factory'
        }
      }
    },
    5: {
      name: isRULocale() ? 'Социальные объекты' : 'Institutional',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Больница' : 'Hospital'
        },
        2: {
          name: isRULocale() ? 'Учреждение длительного ухода' : 'Long-Term Care Facility (e.g., Nursing home, Hospice, etc.)'
        },
        3: {
          name: isRULocale() ? 'Реабилитационный центр' : 'Alcohol and Drug Rehabilitation Center'
        },
        4: {
          name: isRULocale() ? 'Групповой дом' : 'Group home'
        },
        5: {
          name: isRULocale() ? 'Тюрьма' : 'Prison or Jail'
        }
      }
    },
    6: {
      name: isRULocale() ? 'Торговые объекты' : 'Mercantile',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Розничный магазин' : 'Retail store'
        },
        2: {
          name: isRULocale() ? 'Продуктовый рынок' : 'Grocery market'
        },
        3: {
          name: isRULocale() ? 'Автосервис' : 'Automotive service station'
        },
        4: {
          name: isRULocale() ? 'Торговый центр' : 'Shopping mall'
        },
        5: {
          name: isRULocale() ? 'Автозаправочная станция' : 'Gas station'
        }
      }
    },
    7: {
      name: isRULocale() ? 'Проживание' : 'Residential',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Частная резиденция' : 'Private residence'
        },
        2: {
          name: isRULocale() ? 'Отель или мотель' : 'Hotel or Motel'
        },
        3: {
          name: isRULocale() ? 'Общежитие' : 'Dormitory'
        },
        4: {
          name: isRULocale() ? 'Пансионат' : 'Boarding house'
        }
      }
    },
    8: {
      name: isRULocale() ? 'Хранилище' : 'Storage',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        }
      }
    },
    9: {
      name: isRULocale() ? 'Разное' : 'Utility and Miscellaneous',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        }
      }
    },
    10: {
      name: isRULocale() ? 'Транспорт' : 'Vehicular',
      types: {
        0: {
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          name: isRULocale() ? 'Автомобиль или грузовик' : 'Automobile or Truck'
        },
        2: {
          name: isRULocale() ? 'Самолет' : 'Airplane'
        },
        3: {
          name: isRULocale() ? 'Автобус' : 'Bus'
        },
        4: {
          name: isRULocale() ? 'Паром' : 'Ferry'
        },
        5: {
          name: isRULocale() ? 'Судно или лодка' : 'Ship or Boat'
        },
        6: {
          name: isRULocale() ? 'Поезд' : 'Train'
        },
        7: {
          name: isRULocale() ? 'Мотоцикл' : 'Motor Bike'
        }
      }
    },
    11: {
      name: isRULocale() ? 'Уличное пространство' : 'Outdoor',
      types: {
        0: {
          id: 0,
          name: isRULocale() ? 'Неопределенно' : 'Unspecified'
        },
        1: {
          id: 1,
          name: isRULocale() ? 'Муниципальная мэш сеть' : 'Muni-mesh Network'
        },
        2: {
          id: 2,
          name: isRULocale() ? 'Городской парк' : 'City park'
        },
        3: {
          id: 3,
          name: isRULocale() ? 'Зона отдыха' : 'Rest area'
        },
        4: {
          id: 4,
          name: isRULocale() ? 'Управление трафиком' : 'Traffic control'
        },
        5: {
          id: 5,
          name: isRULocale() ? 'Автобусная остановка' : 'Bus stop'
        },
        6: {
          id: 6,
          name: isRULocale() ? 'Киоск' : 'Kiosk'
        }
      }
    }
  },

  LANGUAGE: [
    {
      id: 'afr',
      name: isRULocale() ? 'Африкаанс' : 'Afrikaans'
    },
    {
      id: 'ara',
      name: isRULocale() ? 'Арабский' : 'Arabic'
    },
    {
      id: 'aze',
      name: isRULocale() ? 'Азербайджанский' : 'Azeri'
    },
    {
      id: 'bel',
      name: isRULocale() ? 'Белорусский' : 'Belarusian'
    },
    {
      id: 'bul',
      name: isRULocale() ? 'Болгарский' : 'Bulgarian'
    },
    {
      id: 'cat',
      name: isRULocale() ? 'Каталонский' : 'Catalan'
    },
    {
      id: 'cze',
      name: isRULocale() ? 'Чешский' : 'Czech'
    },
    {
      id: 'wel',
      name: isRULocale() ? 'Валлийский' : 'Welsh'
    },
    {
      id: 'dan',
      name: isRULocale() ? 'Датский' : 'Danish'
    },
    {
      id: 'ger',
      name: isRULocale() ? 'Немецкий' : 'German'
    },
    {
      id: 'div',
      name: isRULocale() ? 'Дивехи' : 'Divehi'
    },
    {
      id: 'gre',
      name: isRULocale() ? 'Греческий' : 'Greek'
    },
    {
      id: 'eng',
      name: isRULocale() ? 'Английский' : 'English'
    },
    {
      id: 'epo',
      name: isRULocale() ? 'Эсперанто' : 'Esperanto'
    },
    {
      id: 'spa',
      name: isRULocale() ? 'Испанский' : 'Spanish'
    },
    {
      id: 'est',
      name: isRULocale() ? 'Эстонский' : 'Estonian'
    },
    {
      id: 'eus',
      name: isRULocale() ? 'Баскский' : 'Basque'
    },
    {
      id: 'fas',
      name: isRULocale() ? 'Фарси' : 'Farsi'
    },
    {
      id: 'fin',
      name: isRULocale() ? 'Финский' : 'Finnish'
    },
    {
      id: 'fao',
      name: isRULocale() ? 'Фарерский' : 'Faroese'
    },
    {
      id: 'fre',
      name: isRULocale() ? 'Французский' : 'French'
    },
    {
      id: 'glg',
      name: isRULocale() ? 'Галисийский' : 'Galician'
    },
    {
      id: 'guj',
      name: isRULocale() ? 'Гуджарати' : 'Gujarati'
    },
    {
      id: 'heb',
      name: isRULocale() ? 'Иврит' : 'Hebrew'
    },
    {
      id: 'hin',
      name: isRULocale() ? 'Хинди' : 'Hindi'
    },
    {
      id: 'hrv',
      name: isRULocale() ? 'Хорватский' : 'Croatian'
    },
    {
      id: 'hun',
      name: isRULocale() ? 'Венгерский' : 'Hungarian'
    },
    {
      id: 'hye',
      name: isRULocale() ? 'Армянский' : 'Armenian'
    },
    {
      id: 'ind',
      name: isRULocale() ? 'Индонезийский' : 'Indonesian'
    },
    {
      id: 'isl',
      name: isRULocale() ? 'Исландский' : 'Icelandic'
    },
    {
      id: 'ita',
      name: isRULocale() ? 'Итальянский' : 'Italian'
    },
    {
      id: 'jpn',
      name: isRULocale() ? 'Японский' : 'Japanese'
    },
    {
      id: 'kat',
      name: isRULocale() ? 'Грузинский' : 'Georgian'
    },
    {
      id: 'kaz',
      name: isRULocale() ? 'Казахский' : 'Kazakh'
    },
    {
      id: 'kan',
      name: isRULocale() ? 'Каннада' : 'Kannada'
    },
    {
      id: 'kor',
      name: isRULocale() ? 'Корейский' : 'Korean'
    },
    {
      id: 'kok',
      name: isRULocale() ? 'Конкани' : 'Konkani'
    },
    {
      id: 'kir',
      name: isRULocale() ? 'Кыргызский' : 'Kyrgyz'
    },
    {
      id: 'lit',
      name: isRULocale() ? 'Литовский' : 'Lithuanian'
    },
    {
      id: 'lav',
      name: isRULocale() ? 'Латышский' : 'Latvian'
    },
    {
      id: 'mri',
      name: isRULocale() ? 'Маори' : 'Maori'
    },
    {
      id: 'mkd',
      name: isRULocale() ? 'Македонский' : 'FYRO Macedonian'
    },
    {
      id: 'mon',
      name: isRULocale() ? 'Монгольский' : 'Mongolian'
    },
    {
      id: 'mar',
      name: isRULocale() ? 'Маратхи' : 'Marathi'
    },
    {
      id: 'msa',
      name: isRULocale() ? 'Малайский' : 'Malay'
    },
    {
      id: 'mlt',
      name: isRULocale() ? 'Мальтийский' : 'Maltese'
    },
    {
      id: 'nid',
      name: isRULocale() ? 'Голландский' : 'Dutch'
    },
    {
      id: 'nso',
      name: isRULocale() ? 'Северный сото' : 'Northern Sotho'
    },
    {
      id: 'pan',
      name: isRULocale() ? 'Панджаби' : 'Punjabi'
    },
    {
      id: 'pol',
      name: isRULocale() ? 'Польский' : 'Polish'
    },
    {
      id: 'pus',
      name: isRULocale() ? 'Пушту' : 'Pashto'
    },
    {
      id: 'por',
      name: isRULocale() ? 'Португальский' : 'Portuguese'
    },
    {
      id: 'que',
      name: isRULocale() ? 'Кечуа' : 'Quechua'
    },
    {
      id: 'ron',
      name: isRULocale() ? 'Румынский' : 'Romanian'
    },
    {
      id: 'rus',
      name: isRULocale() ? 'Русский' : 'Russian'
    },
    {
      id: 'san',
      name: isRULocale() ? 'Санскрит' : 'Sanskrit'
    },
    {
      id: 'sme',
      name: isRULocale() ? 'Саамский' : 'Sami'
    },
    {
      id: 'slk',
      name: isRULocale() ? 'Словацкий' : 'Slovak'
    },
    {
      id: 'slv',
      name: isRULocale() ? 'Словенский' : 'Slovenian'
    },
    {
      id: 'sqi',
      name: isRULocale() ? 'Албанский' : 'Albanian'
    },
    {
      id: 'swe',
      name: isRULocale() ? 'Шведский' : 'Swedish'
    },
    {
      id: 'swa',
      name: isRULocale() ? 'Суахили' : 'Swahili'
    },
    {
      id: 'syr',
      name: isRULocale() ? 'Сирийский' : 'Syriac'
    },
    {
      id: 'tam',
      name: isRULocale() ? 'Тамильский' : 'Tamil'
    },
    {
      id: 'tel',
      name: isRULocale() ? 'Телугу' : 'Telugu'
    },
    {
      id: 'tha',
      name: isRULocale() ? 'Тайский' : 'Thai'
    },
    {
      id: 'tgl',
      name: isRULocale() ? 'Тагальский' : 'Tagalog'
    },
    {
      id: 'tsn',
      name: isRULocale() ? 'Тсвана' : 'Tswana'
    },
    {
      id: 'tur',
      name: isRULocale() ? 'Турецкий' : 'Turkish'
    },
    {
      id: 'tat',
      name: isRULocale() ? 'Татарский' : 'Tatar'
    },
    {
      id: 'tso',
      name: isRULocale() ? 'Цонга' : 'Tsonga'
    },
    {
      id: 'ukr',
      name: isRULocale() ? 'Украинский' : 'Ukrainian'
    },
    {
      id: 'urd',
      name: isRULocale() ? 'Урду' : 'Urdu'
    },
    {
      id: 'uzb',
      name: isRULocale() ? 'Узбекский' : 'Uzbek'
    },
    {
      id: 'vie',
      name: isRULocale() ? 'Вьетнамский' : 'Vietnamese'
    },
    {
      id: 'xho',
      name: isRULocale() ? 'Коса' : 'Xhosa'
    },
    {
      id: 'zho',
      name: isRULocale() ? 'Китайский' : 'Chinese'
    },
    {
      id: 'zul',
      name: isRULocale() ? 'Зулу' : 'Zulu'
    }
  ],

  EAP_METHODS: {
    13: {
      name: 'EAP-TLS'
    },
    18: {
      name: 'EAP-SIM'
    },
    21: {
      name: 'EAP-TTLS'
    },
    23: {
      name: 'EAP-AKA'
    },
    50: {
      name: "EAP-AKA'"
    }
  },

  EAP_TYPE: {
    2: {
      name: isRULocale() ? 'Внутренняя аутентификация без EAP' : 'Non-EAP Inner Authentication',
      subtype: {
        1: {
          name: 'PAP'
        },
        2: {
          name: 'CHAP'
        },
        3: {
          name: 'MSCHAP'
        },
        4: {
          name: 'MSCHAPv2'
        }
      }
    },
    3: {
      name: isRULocale() ? 'EAP метод внутренней аутентификации' : 'Inner Authentication EAP Method',
      subtype: {
        0: {
          name: isRULocale() ? 'Нет' : 'None'
        }
      }
    },
    5: {
      name: isRULocale() ? 'Учетные данные' : 'Credential',
      subtype: {
        1: {
          name: isRULocale() ? 'SIM карта' : 'SIM'
        },
        2: {
          name: isRULocale() ? 'USIM карта' : 'USIM'
        },
        3: {
          name: isRULocale() ? 'NFC' : 'NFC Secure Element'
        },
        4: {
          name: isRULocale() ? 'Аппаратный токен' : 'Hardware Token'
        },
        5: {
          name: isRULocale() ? 'Программный токен' : 'Softoken'
        },
        6: {
          name: isRULocale() ? 'Сертификат' : 'Certificate'
        },
        7: {
          name: isRULocale() ? 'Имя пользователя/Пароль' : 'Username/Password'
        },
        8: {
          name: isRULocale() ? 'Анонимно' : 'Anonymous'
        },
        9: {
          name: isRULocale() ? 'Специфичный для производителя' : 'Vendor Specific'
        }
      }
    }
  },

  IPV4_TYPE: {
    '-1': {
      name: isRULocale() ? 'Нет' : 'None'
    },
    0: {
      name: isRULocale() ? 'Тип IPv4 недоступен' : 'Address type not available'
    },
    1: {
      name: isRULocale() ? 'Доступен публичный IPv4-адрес' : 'Public IPv4 address available'
    },
    2: {
      name: isRULocale() ? 'Доступен IPv4-адрес с ограничением по портам' : 'Port-restricted IPv4 address available'
    },
    3: {
      name: isRULocale() ? 'Доступен частный IPv4-адрес c одинарным NAT' : 'Single NATed private IPv4 address available'
    },
    4: {
      name: isRULocale() ? 'Доступен частный IPv4-адрес с двойным NAT' : 'Double NATed private IPv4 address available'
    },
    5: {
      name: isRULocale() ? 'Доступны IPv4-адрес с ограничением по портам и одиночный NAT-адрес IPv4' : 'Port-restricted IPv4 address and single NATed IPv4 address available'
    },
    6: {
      name: isRULocale() ? 'Доступны IPv4-адрес с ограничением по портам и двойым NAT': 'Port-restricted IPv4 address and double NATed IPv4 address available'
    },
    7: {
      name: isRULocale() ? 'Доступность типа адреса не известна' : 'Availability of the address type is not known'
    }
  },

  IPV6_TYPE: {
    '-1': {
      name: isRULocale() ? 'Нет' : 'None'
    },
    0: {
      name: isRULocale() ? 'Тип адреса недоступен' : 'Address type not available'
    },
    1: {
      name: isRULocale() ? 'Тип адреса доступен' : 'Address type available'
    },
    2: {
      name: isRULocale() ? 'Доступность типа адреса не известна' : 'Availability of the address type not known'
    }
  },

  IP_PROTOCOLS: {
    1: {
      name: 'ICMP'
    },
    6: {
      name: 'TCP'
    },
    17: {
      name: 'UDP'
    }
  },

  CONN_CAP_STATUS: {
    0: {
      name: isRULocale() ? 'Закрытый' : 'Closed'
    },
    1: {
      name: isRULocale() ? 'Открытый' : 'Open'
    },
    2: {
      name: isRULocale() ? 'Неизвестно' : 'Unknown'
    }
  },

  LINK_STATUS: {
    0: {
      name: 'None'
    },
    1: {
      name: 'Link up'
    },
    2: {
      name: 'Link down'
    },
    3: {
      name: 'Link in test state'
    }
  },

  OPER_CLASSES: {
    81: {
      name: '1-13'
    },
    82: {
      name: '14'
    },
    83: {
      name: '1-9, HT40+'
    },
    84: {
      name: '5-13, HT40-'
    },
    115: {
      name: '36-48, HT20'
    },
    116: {
      name: '36-44, HT40+'
    },
    117: {
      name: '40-48, HT40-'
    },
    118: {
      name: '52-64, HT20'
    },
    119: {
      name: '52-60, HT40+'
    },
    120: {
      name: '56-64, HT40-'
    },
    121: {
      name: '100-140, HT20'
    },
    122: {
      name: '100-132, HT40+'
    },
    123: {
      name: '104-136, HT40-'
    },
    124: {
      name: '149-161, HT20'
    },
    125: {
      name: '165-169, HT20'
    },
    126: {
      name: '149-157, HT40+'
    },
    127: {
      name: '153-161, HT40'
    },
    128: {
      name: 'VHT80'
    },
    129: {
      name: 'VHT160'
    },
    130: {
      name: 'VHT80+80'
    }
  },

  // getHotspots(ctx) {
  //   ctx.$store.commit('toggleLoadingHotspots');
  //
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/hs20profiles`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setHotspotsList', response.data.data.itemslist);
  //         ctx.$store.commit('toggleLoadingHotspots');
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('toggleLoadingHotspots');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingHotspots');
  //       ctx.error = err;
  //     }
  //   );
  // },

  getHotspots(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingHotspots');

    const isUseLocationFilters = useLocationFilter;

    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/hs20profiles`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setHotspotsList', response.data.data.itemslist);
          ctx.$store.commit('toggleLoadingHotspots');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingHotspots');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingHotspots');
        ctx.error = err;
      }
    );
  },

  createHotspot(ctx) {
    Vue.axios.post(`${window.RESTAPI || ''}/api/hs20profiles`, { action: 'C', items: { 0: ctx.newHotspotData } }).then(
      (response) => {
        if (response.data.status === 'success') {
          hotspotPollingService.hadleOperationResponse(response.data.data.items);
          this.getHotspots(ctx);
          ctx.$parent.nextStep();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        this.error = err;
      }
    );
  },

  updateHotspot(ctx) {
    ctx.$store.commit('toggleMuteHotspotEdit', true);
    const currentHotspotData = JSON.parse(JSON.stringify(ctx.updatedHotspot));

    const updateHotspotQuery = { action: 'U', items: { [currentHotspotData.id]: currentHotspotData } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/hs20profiles`, updateHotspotQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          this.getHotspots(ctx);
          hotspotPollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.disableEditMode();
        ctx.$store.commit('toggleMuteHotspotEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteHotspotEdit', false);
        ctx.error = err;
      }
    );
  },

  deleteHotspot(ctx) {
    const deleteHotspotQuery = { action: 'D', items: { [ctx.updatedHotspot.id]: {} } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/hs20profiles`, deleteHotspotQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setHotspotEditId', null);
          ctx.deletingHotspotModal = false;
          hotspotPollingService.hadleOperationResponse(response.data.data.items);
          this.getHotspots(ctx);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  }
};
