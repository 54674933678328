import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import store from '../store';

// Containers
import Full from '../containers/Full.vue';

import Dashboard from './Dashboard';
import Statistics from './Statistics';
import Portal from './Portal';
import Settings from './Settings';
import System from './System';
import Clients from './Clients';
import Radius from './Radius';
import Monitor from './Monitor';
import Maps from './Maps';
import Templates from './Templates';
import Firewall from './Firewall';
import GuestControl from './guestControl';
import Hotspot from './Hotspot';
import Marketing from './Marketing';
import Radar from './Radar';

// Views
import Aps from '../views/Aps.vue';
import RegisterCpe from '../views/RegisterCpe.vue';
import RRM from '../views/RRM.vue';
import Controllers from '../views/Controllers.vue';
import Support from '../views/Support/Support.vue';
import ConfigExportImport from '../views/ConfigExportImport/ConfigExportImport.vue';

import Wlans from '../views/Wlans.vue';
import WlanWizard from '../views/WLANS/Wizard/WlanWizard.vue';
import Step1 from '../views/WLANS/Wizard/Step1.vue';
import Step2 from '../views/WLANS/Wizard/Step2.vue';
import Step3 from '../views/WLANS/Wizard/Step3.vue';
import Step4 from '../views/WLANS/Wizard/Step4.vue';
import Step5 from '../views/WLANS/Wizard/Step5.vue';
import Step6 from '../views/WLANS/Wizard/Step6.vue';
import Step7 from '../views/WLANS/Wizard/Step7.vue';
import Step8 from '../views/WLANS/Wizard/Step8.vue';
import Step9 from '../views/WLANS/Wizard/Step9.vue';
import StepLast from '../views/WLANS/Wizard/StepLast.vue';

import WlanWizardV2 from '../views/WLANS/WizardV2/WlanWizardV2.vue';
// import Step1 from '../views/WLANS/WizardV2/Step1.vue';
// import Step2 from '../views/WLANS/WizardV2/Step2.vue';
// import Step3 from '../views/WLANS/WizardV2/Step3.vue';
// import Step4 from '../views/WLANS/WizardV2/Step4.vue';
// import Step5 from '../views/WLANS/WizardV2/Step5.vue';
// import Step6 from '../views/WLANS/WizardV2/Step6.vue';
// import Step7 from '../views/WLANS/WizardV2/Step7.vue';
// import Step8 from '../views/WLANS/WizardV2/Step8.vue';
// import Step9 from '../views/WLANS/WizardV2/Step9.vue';
// import StepLast from '../views/WLANS/WizardV2/StepLast.vue';


import Page404 from '../views/pages/Page404.vue';
import Login from '../views/pages/Login.vue';
import ResetPassword from '../views/pages/ResetPassword.vue';
import auth from '../auth/auth';
import UsersDemoWizard from '../views/Users/UsersDemoWizard.vue';
import UsersDemoWizardStep1 from '../views/Users/UsersDemoWizardStep1.vue';
import UsersDemoWizardStep2 from '../views/Users/UsersDemoWizardStep2.vue';
import UsersDemoWizardStep3 from '../views/Users/UsersDemoWizardStep3.vue';
import UsersDemoWizardStep4 from '../views/Users/UsersDemoWizardStep4.vue';
import UsersDemoWizardStep5 from '../views/Users/UsersDemoWizardStep5.vue';

// easy install wizard
import easyInstallWizard from '../views/easyInstallWizard/easyInstallWizard.vue';
import EasyInstallWizardStep1 from '../views/easyInstallWizard/EasyInstallWizardStep1.vue';
import EasyInstallWizardstep2 from '../views/easyInstallWizard/EasyInstallWizardstep2.vue';
import EasyInstallWizardstep3 from '../views/easyInstallWizard/EasyInstallWizardstep3.vue';
import EasyInstallWizardstep4 from '../views/easyInstallWizard/EasyInstallWizardstep4.vue';
import EasyInstallWizardstep5 from '../views/easyInstallWizard/EasyInstallWizardstep5.vue';

// for user self-registration of cpes
import RegisterMyCPEPlease from '../views/pages/RegisterMyCPEPlease.vue';

Vue.use(Router);
Vue.use(Meta);

// const userRole = document.querySelector('#app').__vue__.$store;
// console.log(userRole);

const router = new Router({
  mode: 'history', // Demo is living in GitHub.io, so required!
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      beforeRouteEnter(to, from, next) {},
      // redirect: (to) => {
      //   if (router.app.$store.state.userData.role === 'hotel_employee') {
      //     return '/portal';
      //   }
      //   return '/dashboard';
      // },
      redirect: '/dashboard',
      name: 'Home',
      component: Full,
      children: [
        Dashboard,
        Statistics,
        Settings,
        System,
        Clients,
        Radius,
        Templates,
        Firewall,
        GuestControl,
        Hotspot,
        Monitor,
        Marketing,
        Radar,
        Maps,
        Portal,
        {
          path: 'wizard',
          name: 'Wizard',
          redirect: 'wizard/step1',
          component: WlanWizard,
          children: [
            {
              path: 'step1',
              name: 'step1',
              component: Step1
            },
            {
              path: 'step2',
              name: 'step2',
              component: Step2
            },
            {
              path: 'step3',
              name: 'step3',
              component: Step3
            },
            {
              path: 'step4',
              name: 'step4',
              component: Step4
            },
            {
              path: 'step5',
              name: 'step5',
              component: Step5
            },
            {
              path: 'step6',
              name: 'step6',
              component: Step6
            },
            {
              path: 'step7',
              name: 'step7',
              component: Step7
            },
            {
              path: 'step8',
              name: 'step8',
              component: Step8
            },
            {
              path: 'step9',
              name: 'step9',
              component: Step9
            },
            {
              path: 'StepLast',
              name: 'StepLast',
              component: StepLast
            }
          ]
        },
        {
          path: 'wlanWizardV2',
          name: 'wlanWizardV2',
          component: WlanWizardV2
        },
        {
          path: 'aps',
          name: 'Aps',
          component: Aps,
          props: true
        },
        {
          path: 'rrm',
          name: 'RRM',
          component: RRM
        },
        {
          path: 'controllers',
          name: 'Controllers',
          component: Controllers
        },
        {
          path: 'wlans',
          name: 'Wlans',
          component: Wlans
        },
        {
          path: 'registercpe',
          name: 'RegisterCpe',
          component: RegisterCpe
        },
        {
          path: 'support',
          name: 'Support',
          component: Support
        },
        {
          path: 'config_export_import',
          name: 'ConfigExportImport',
          component: ConfigExportImport
        },
        {
          path: 'users-demo-wizard',
          name: 'usersDemoWizard',
          redirect: 'users-demo-wizard/step-1',
          component: UsersDemoWizard,
          children: [
            {
              path: 'step-1',
              name: 'UsersDemoWizardStep1',
              component: UsersDemoWizardStep1
            },
            {
              path: 'step-2',
              name: 'UsersDemoWizardStep2',
              component: UsersDemoWizardStep2
            },
            {
              path: 'step-3',
              name: 'UsersDemoWizardStep3',
              component: UsersDemoWizardStep3
            },
            {
              path: 'step-4',
              name: 'UsersDemoWizardStep4',
              component: UsersDemoWizardStep4
            },
            {
              path: 'step-5',
              name: 'UsersDemoWizardStep5',
              component: UsersDemoWizardStep5
            }
          ]
        },
        {
          path: 'easy-install-wizard',
          name: 'easyInstallWizard',
          component: easyInstallWizard,
          redirect: 'easy-install-wizard/step-1',
          children: [
            {
              path: 'step-1',
              name: 'EasyInstallWizardStep1',
              component: EasyInstallWizardStep1
            },
            {
              path: 'step-2',
              name: 'EasyInstallWizardStep2',
              component: EasyInstallWizardstep2
            },
            {
              path: 'step-3',
              name: 'EasyInstallWizardStep3',
              component: EasyInstallWizardstep3
            },
            {
              path: 'step-4',
              name: 'EasyInstallWizardStep4',
              component: EasyInstallWizardstep4
            },
            {
              path: 'step-5',
              name: 'EasyInstallWizardStep5',
              component: EasyInstallWizardstep5
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/p404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view');
        }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    },
    {
      path: '/resetPassword',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/registerMyCPEPlease',
      name: 'registerMyCPEPlease',
      component: RegisterMyCPEPlease
    }
  ]
});

router.beforeEach((to, from, next) => {
  const routeFromName = from.name;
  if (routeFromName) {
    // console.log('routeFromName ', routeFromName)
    router.app.$store.commit('cancelAllRequestsAndClearAllTokensForComponent', { componentName: routeFromName });
  }
  router.app.$store.commit('setIsClickToGoToAnotherPageTrue');
  if (from.name === 'PortalAdsNew') {
    // эта штука нужна чтобы при попытке перехода в другой раздел, пока не подгрузились медиа, из разделе Реклама и Опросы
    // прервать загрузку этих самых медиа (видяшки),  из за которой  дальнейшие запросы, которые нужны для перехода
    // в другой раздел никак не могут выполнится
    const media = document.getElementsByClassName('loading-media-in-card-on-page');
    if (media) {
      for (let i = media.length - 1; i >= 0; --i) {
        media[i].src = '';
        media[i].load();
        // а теперь удаляем сам элемент чтобы не было сообщений об ошибки в карточке с видео, поскольку
        // src='' генерирует ошибку элемента <video>
        media[i].remove();
      }
    }
    // console.log(videos);
  }
  // const userRole = JSON.parse(localStorage.getItem('userData')).role;
  let userRole = '';
  if (localStorage.getItem('userData')) {
    userRole = JSON.parse(localStorage.getItem('userData')).role;
    // console.log('role is ' + userRole)
  }
  console.info(`Transition from ${from.name} to ${to.name}`);
  if (to.name !== 'Login' && to.name !== 'ResetPassword') {
    if (to.name === 'Dashboard' && userRole === 'hotel_employee') {
      router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
      next('/portal/hotel-vouchers');
    } else if (to.name === 'Dashboard' && userRole === 'advertising') {
      // console.log(to.name)
      // console.log(userRole)
      router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
      next('/portal/ads');
    } else if (to.name === 'Dashboard' && window.PORTAL_ONLY) {
      router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
      next('/portal/dashboard');
    }
    auth.checkAuth().then(
      (response) => {
        if (response.data.status === 'success') {
          console.warn('Logged In');
          auth.user.authenticated = true;
        } else {
          console.warn('Not Logged');
          auth.user.authenticated = false;
        }
        if (auth.user.authenticated) {
          // тут проверка на включеный флаг userRegistersTheCpeHimself в сторе. Он включается если ранее пользователь
          // зашел на /registerMyCPEPlease чтобы зарегать точку, но был не
          // залогинен и переправлен на страницу логина. После ввода  логина и успешной аутентификации смотрим на этот флаг
          // и перенаправляем его обратно на registerMyCPEPlease и прокидывваем в query id регистрируемой  точки
          if (router.app.$store.state.userRegistersTheCpeHimself) {
            router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
            router.push({
              name: 'registerMyCPEPlease',
              query: { cpe_id: router.app.$store.state.userRegistersTheCpeHimselfCpeId }
            });
            // далее очищаем переменные в сторе, необходимые для перенаправления после логина
            // обратно на страницу registerMyCPEPlease
            router.app.$store.commit('userRegistersTheCpeHimselfDisableAndClearCpeID');
            return;
          }
          if (router.app.$can('read', { route: to.name }) || to.name === 'Dashboard') {
            router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
            next();
            return;
          }
          if (!router.app.$can('read', { route: to.name }) && userRole === 'advertising' && to.name !== 'Dashboard') {
            router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
            router.push('/portal/ads');
          }
        } else {
          // тут если пользователь пытлася зайти на /registerMyCPEPlease  с целью зарегать точку
          // но был не залогинен, ставим флаг что он хотел зарегть точку и запоминаем ID точки и потом он
          // перенаправляется на страницу логина
          if (to.name === 'registerMyCPEPlease' && !router.app.$store.state.userRegistersTheCpeHimself) {
            router.app.$store.commit('userRegistersTheCpeHimselfEnable');
            router.app.$store.commit('userRegistersTheCpeHimselfSetCpeId', to.query.cpe_id);
          }
          router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
          router.push('/pages/login');
        }
      },
      (err) => {
        // тут если пользователь пытлася зайти на /registerMyCPEPlease  с целью зарегать точку
        // но был не залогинен, ставим флаг что он хотел зарегть точку и запоминаем ID точки и потом он
        // перенаправляется на страницу логина
        if (to.name === 'registerMyCPEPlease' && !router.app.$store.state.userRegistersTheCpeHimself) {
          router.app.$store.commit('userRegistersTheCpeHimselfEnable');
          router.app.$store.commit('userRegistersTheCpeHimselfSetCpeId', to.query.cpe_id);
        }
        console.warn('Not Logged');
        auth.user.authenticated = false;
        router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
        router.push('/pages/login');
      }
    );
  } else {
    router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
    next();
  }
});

router.afterEach((to, from) => {
  console.log('Cutrrent route name is', to.name);
  router.app.$store.commit('setCurrentRouteComponentName', to.name);
})

export default router;
