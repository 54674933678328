<template>
  <div class="row">
    <div>
      <div class="card">
        <div class="card-block">
          <div class="h6 mb-1">{{ $t('easyInstallWizard.step3PrevStepPageURL') }}:</div>
          <div class="h6 mb-3">
            <span class="text-muted">{{ portalPageUrlWithoutProtocol }}</span>
          </div>
          <div class="h4 m-0">{{ $t('easyInstallWizard.step3Header') }}</div>
          <span class="h6 setting-title">{{ $t('GuestControl.ruleSettings') }}</span>

          <GeneralSettings :redirectRuleData="newRedirectRuleData" :isWizard="true"></GeneralSettings>

          <div class="mt-2">
            <button
              type="button"
              class="btn btn-outline-primary float-right cpe-register-next-button"
              @click="setNewRedirectRuleData"
              :disabled="errors.any() || (newRedirectRuleData.redirect_url && !newRedirectRuleData.url_list.length)"
            >
              {{ $t('easyInstallWizard.step3NextButton') }}
            </button>

            <!--            <button-->
            <!--              type="button"-->
            <!--              class="btn btn-outline-secondary float-left cpe-register-back-button"-->
            <!--              @click="$parent.prevStep"-->
            <!--            >-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import GeneralSettings from '../../components/GuestControl/generalSettingsForEasyInstallWizard.vue';
import commonService from '../../services/commonService';
import redirectPollingService from '../../services/redirectPollingService';
import redirectsService from '../../services/redirectsService';
import wlansPollingService from '../../services/wlansPollingService';
import EasyInstallWizardHelpers from './EasyInstallWizardHelpers';

export default {
  components: { GeneralSettings },
  name: 'EasyInstallWizardstep3',
  data() {
    return {
      portalPageUrl: '',
      // portalUrl: window.PORTAL_BASE_URL,
      portalUrl: '',
      newRedirectRuleData: {
        // name: '',
        redirect_url: '',
        // portalUrl: window.PORTAL_BASE_URL
        portalUrl: ''
      }
    };
  },
  computed: {
    portalBaseUrl() {
      return this.$store.getters.portalBaseUrl;
    },
    portalPageUrlWithoutProtocol() {
      const PROTOCOL_CUTOFF = '://';
      const urlWithoutProtocol = this.portalPageUrl;
      const cutURLPosition = urlWithoutProtocol.indexOf(PROTOCOL_CUTOFF) + PROTOCOL_CUTOFF.length;
      return urlWithoutProtocol.slice(cutURLPosition);
    },
    commonService() {
      return commonService;
    },
    wlanId() {
      return this.$store.state.easyInstallWizardStepsData.step1WlanId;
    }
  },
  methods: {
    setNewRedirectRuleData() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$store.commit('setNewRedirectRuleData', this.newRedirectRuleData);
            // this.nextStep();
            this.confirmRedirectCreation();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    nextStep() {
      this.$router.push({ name: 'EasyInstallWizardStep4' });
    },
    exitFromWizard() {
      this.$router.push({ name: 'Dashboard' });
    },
    prevStep() {
      this.$router.push({ name: 'EasyInstallWizardStep2' });
    },
    confirmRedirectCreation() {
      const correctRedirectRuleData = JSON.parse(JSON.stringify(this.newRedirectRuleData));
      // correctRedirectRuleData.url_list.push({ 'ip': correctRedirectRuleData.redirect_ip, 'domain_name': this.showOnlyDomain(correctRedirectRuleData.redirect_url) });
      // delete correctRedirectRuleData.redirect_ip;
      Vue.axios
        .post(`${window.RESTAPI || ''}/api/network/redirects`, { action: 'C', items: { 0: correctRedirectRuleData } })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              // redirectPollingService.hadleOperationResponse(response.data.data.items);
              // redirectsService.getRedirectsRule(this);
              this.$store.commit('setEasyInstallWizardStep3SavedRedirectObject', response.data.data.items);
              console.log('next step');
              // далее вытаскиваем из созданого редиректа его  id
              const keys = Object.keys(response.data.data.items);
              const createdRedirect = response.data.data.items[keys[0]];
              const createdRedirectId = createdRedirect.model_id;
              this.$store.commit('setEasyInstallWizardStep3SavedRedirectId', createdRedirectId);
              this.addCreatedRedirectToStep1CreatedWlan();
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
              this.$store.commit('toggleLoadingFirewalls', true);
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
            this.error = err;
            this.$store.commit('toggleLoadingFirewalls', true);
          }
        );
    },
    addCreatedRedirectToStep1CreatedWlan() {
      // тут подгружаем объект созданной ранее  WLAN, затем изменяем его, добавив ID редиректа
      // и далее апдейтим эту WLAN
      // а потом перенаправляем на следующий шаг визарда
      const query = {
        action: 'R',
        ids: [this.wlanId]
      };
      Vue.axios.post(`${window.RESTAPI || ''}/api/wlans`, query).then(
        (response) => {
          if (response.data.status === 'success') {
            // получили объект созданной ранее wlan
            // console.log(response.data.data.itemslist);
            const createdAtStep1Wlan = response.data.data.itemslist[0];
            createdAtStep1Wlan.guest_control.captive_redirect = this.$store.state.easyInstallWizardStepsData.step3RedirectId;

            // теперь заапдейтим ее
            const updateWlanQuery = { action: 'U', items: { [this.wlanId]: createdAtStep1Wlan } };
            // console.log(updateWlanQuery)
            Vue.axios.post(`${window.RESTAPI || ''}/api/wlans`, updateWlanQuery).then(
              (responseForUpdate) => {
                if (responseForUpdate.data.status === 'success') {
                  // wlansPollingService.hadleOperationResponse(responseForUpdate.data.data.items);
                  this.nextStep();
                } else if (responseForUpdate.data.status === 'error') {
                  VueNotifications.error({ message: responseForUpdate.data.description });
                }
              },
              (err) => {
                VueNotifications.error({ message: err });
              }
            );
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
        }
      );
    }
  },
  created() {
    const step2PageUrl = this.$store.state.easyInstallWizardStepsData.step2SavedPageObject.url_id || '';
    this.portalPageUrl = `${this.portalBaseUrl}${step2PageUrl}`;
    this.newRedirectRuleData = JSON.parse(JSON.stringify(this.$store.state.newRedirectRuleData));
    this.newRedirectRuleData.base_location =
      this.$store.state.easyInstallWizardStepsData.step1locationId || this.$store.state.userData.base_location;
    this.newRedirectRuleData.redirect_url = this.portalPageUrl || this.portalBaseUrl;

    // проверяем есть какие шаги пройдены, чтобы знакть куда перенаправлять юзера, если он выходил из визарда
    if (this.$router.currentRoute.name !== EasyInstallWizardHelpers.checkStepForUserReditrect(this)) {
      this.$router.push({ name: EasyInstallWizardHelpers.checkStepForUserReditrect(this) });
    }
  }
};
</script>
<style lang="scss" scoped></style>
