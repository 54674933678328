import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import cpeService from './cpeService';

export default {
  getSystemsInfo(ctx, callback) {
    ctx.$store.commit('toggleLoadingSystemInfo');
    ctx.$store.commit('toggleDashboardLoading', true);

    Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)  }).then(
      (response) => {
        if (response.data.status === 'success') {
          if (!response.data.data.items.cpes) {
            response.data.data.items.cpes = {
              by_configstatus: [],
              by_connected: [],
              by_last_cpestat: [],
              by_last_stat: []
            };
          }
          // System Info
          ctx.$store.commit('setSystemInfo', response.data.data.items.system);
          // Wlans Info
          ctx.$store.commit('setWlansInfo', response.data.data.items.wlans);
          // CPEs Info
          ctx.$store.commit('setCpesInfo', response.data.data.items.cpes);
          // Clients Info
          ctx.$store.commit('setClientsInfo', response.data.data.items.clients);

          const cpesIds = [];
          ctx.$store.state.cpesInfo.by_last_cpestat.forEach((cpe) => {
            if (!cpesIds.includes(cpe.id)) {
              cpesIds.push(cpe.id);
            }
          });
          ctx.$store.state.cpesInfo.by_last_stat.forEach((cpe) => {
            if (!cpesIds.includes(cpe.id)) {
              cpesIds.push(cpe.id);
            }
          });
          // cpeService.getSelectedCpes(ctx, cpesIds);
          if (typeof callback === 'function') {
            callback(ctx);
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleDashboardLoading', false);
        ctx.$store.commit('toggleLoadingSystemInfo');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingSystemInfo');
        ctx.error = err;
        ctx.$store.commit('toggleDashboardLoading', false);
      }
    );
  },

  updateSystemsInfo(ctx, callback) {
    Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx) }).then(
      (response) => {
        if (response.data.status === 'success') {
          if (!response.data.data.items.cpes) {
            response.data.data.items.cpes = {
              by_configstatus: [],
              by_connected: [],
              by_last_cpestat: [],
              by_last_stat: []
            };
          }

          ctx.$store.commit('setSystemInfo', response.data.data.items.system);
          ctx.$store.commit('setWlansInfo', response.data.data.items.wlans);
          ctx.$store.commit('setCpesInfo', response.data.data.items.cpes);
          ctx.$store.commit('setClientsInfo', response.data.data.items.clients);

          const cpesIds = [];
          ctx.$store.state.cpesInfo.by_last_cpestat.forEach((cpe) => {
            if (!cpesIds.includes(cpe.id)) {
              cpesIds.push(cpe.id);
            }
          });
          ctx.$store.state.cpesInfo.by_last_stat.forEach((cpe) => {
            if (!cpesIds.includes(cpe.id)) {
              cpesIds.push(cpe.id);
            }
          });
          if (typeof callback === 'function') {
            callback(ctx);
          }
          this.setSystemInfo(ctx);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  updateInfoFormSeparatedApiEndpoints(ctx) {
    this.getOnlySystemsInfo(ctx, this.setSystemInfoV2);
    this.getOnlyWlansInfo(ctx);
    this.getOnlyCpesInfo(ctx, this.setCpesInfo);
    this.getOnlyClientsInfo(ctx);
  },
  setSystemInfo(ctx) {
    const { systemInfo } = ctx.$store.state;

    if (Object.keys(systemInfo).length > 1) {
      if (systemInfo.load) {
        ctx.cpuPercent = parseFloat((systemInfo.load.load_core * 100).toFixed(0));
        ctx.memPercent = parseFloat(systemInfo.mem.usedPercent.toFixed(0));
        if (!ctx.$refs.cpuHighcharts) {
          ctx.cpuCharts.series[0].data.splice(0, 1, ctx.cpuPercent);
        } else {
          ctx.$refs.cpuHighcharts.chart.series[0].setData([ctx.cpuPercent]);
        }
        if (!ctx.$refs.memoryHighcharts) {
          ctx.memoryCharts.series[0].data.splice(0, 1, ctx.memPercent);
        } else {
          ctx.$refs.memoryHighcharts.chart.series[0].setData([ctx.memPercent]);
        }
      } else {
        ctx.cpuCharts = null;
        ctx.memoryCharts = null;
      }
    }

    const cpesConfigArray = ctx.$store.state.cpesInfo.by_configstatus;
    const cpesConnectedArray = ctx.$store.state.cpesInfo.by_connected;

    if (cpesConfigArray && cpesConfigArray.length) {
      cpesConfigArray.forEach((config) => {
        if (config.id === 'ok') {
          ctx.cpeConfigPieCharts.series[0].data[0].y = +config.total;
        }
        if (config.id === 'updating' || config.id === 'rebooting' || config.id === 'upgrading') {
          ctx.cpeConfigPieCharts.series[0].data[1].y = +config.total;
        }
        if (config.id === 'offline') {
          ctx.cpeConfigPieCharts.series[0].data[2].y = +config.total;
        }
        if (config.id === 'empty') {
          ctx.cpeConfigPieCharts.series[0].data[3].y = +config.total;
        }
      });
    }

    if (cpesConnectedArray && cpesConnectedArray.length) {
      cpesConnectedArray.forEach((connected) => {
        if (!connected.id) {
          ctx.cpeConnectedPieCharts.series[0].data[1].y = +connected.total;
        }
        if (connected.id) {
          ctx.cpeConnectedPieCharts.series[0].data[0].y = +connected.total;
        }
      });
    }
  },
  setSystemInfoV2(ctx) {
    const { systemInfo } = ctx.$store.state;

    if (Object.keys(systemInfo).length > 1) {
      if (systemInfo.load) {
        ctx.cpuPercent = parseFloat((systemInfo.load.load_core * 100).toFixed(0));
        ctx.memPercent = parseFloat(systemInfo.mem.usedPercent.toFixed(0));
        if (!ctx.$refs.cpuHighcharts) {
          ctx.cpuCharts.series[0].data.splice(0, 1, ctx.cpuPercent);
        } else {
          ctx.$refs.cpuHighcharts.chart.series[0].setData([ctx.cpuPercent]);
        }
        if (!ctx.$refs.memoryHighcharts) {
          ctx.memoryCharts.series[0].data.splice(0, 1, ctx.memPercent);
        } else {
          ctx.$refs.memoryHighcharts.chart.series[0].setData([ctx.memPercent]);
        }
      } else {
        ctx.cpuCharts = null;
        ctx.memoryCharts = null;
      }
    }
  },
  setCpesInfo(ctx) {
    const cpesConfigArray = ctx.$store.state.cpesInfo.by_configstatus;
    const cpesConnectedArray = ctx.$store.state.cpesInfo.by_connected;

    if (cpesConfigArray && cpesConfigArray.length) {
      cpesConfigArray.forEach((config) => {
        if (config.id === 'ok') {
          ctx.cpeConfigPieCharts.series[0].data[0].y = +config.total;
        }
        if (config.id === 'updating' || config.id === 'rebooting' || config.id === 'upgrading') {
          ctx.cpeConfigPieCharts.series[0].data[1].y = +config.total;
        }
        if (config.id === 'offline') {
          ctx.cpeConfigPieCharts.series[0].data[2].y = +config.total;
        }
        if (config.id === 'empty') {
          ctx.cpeConfigPieCharts.series[0].data[3].y = +config.total;
        }
      });
    }

    if (cpesConnectedArray && cpesConnectedArray.length) {
      cpesConnectedArray.forEach((connected) => {
        if (!connected.id) {
          ctx.cpeConnectedPieCharts.series[0].data[1].y = +connected.total;
        }
        if (connected.id) {
          ctx.cpeConnectedPieCharts.series[0].data[0].y = +connected.total;
        }
      });
    }
  },
  setCpesInfoWithClearCounts(ctx) {
    const cpesConfigArray = ctx.$store.state.cpesInfo.by_configstatus;
    const cpesConnectedArray = ctx.$store.state.cpesInfo.by_connected;
    ctx.cpeConfigPieCharts.series[0].data[0].y = 0;
    ctx.cpeConfigPieCharts.series[0].data[1].y = 0;
    ctx.cpeConfigPieCharts.series[0].data[2].y = 0
    ctx.cpeConfigPieCharts.series[0].data[3].y = 0

    if (cpesConfigArray && cpesConfigArray.length) {
      cpesConfigArray.forEach((config) => {
        if (config.id === 'ok') {
          ctx.cpeConfigPieCharts.series[0].data[0].y = +config.total;
        }
        if (config.id === 'updating' || config.id === 'rebooting' || config.id === 'upgrading') {
          ctx.cpeConfigPieCharts.series[0].data[1].y = +config.total;
        }
        if (config.id === 'offline') {
          ctx.cpeConfigPieCharts.series[0].data[2].y = +config.total;
        }
        if (config.id === 'empty') {
          ctx.cpeConfigPieCharts.series[0].data[3].y = +config.total;
        }
      });
    }

    ctx.cpeConnectedPieCharts.series[0].data[1].y = 0
    ctx.cpeConnectedPieCharts.series[0].data[0].y = 0

    if (cpesConnectedArray && cpesConnectedArray.length) {
      cpesConnectedArray.forEach((connected) => {
        if (!connected.id) {
          ctx.cpeConnectedPieCharts.series[0].data[1].y = +connected.total;
        }
        if (connected.id) {
          ctx.cpeConnectedPieCharts.series[0].data[0].y = +connected.total;
        }
      });
    }
  },
  setTotalObjInfo(ctx) {
    Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard/compact`, { action: 'R', location: undefined, with_childs: true }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setTotalObjInfo', response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  checkDistressedCPEs(ctx) {
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      fields: ['_id'],
      q: { 'last_error.type': { $gt: '' } }
    };
    Vue.axios.post(`${RESTAPI || ''}/api/cpes`, query).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          const distressedCount = response.data.data.count;
          ctx.hasDistressedCPEs = distressedCount > 0;
        } else {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  getOnlySystemsInfo(ctx, callback) {
    ctx.$store.commit('toggleLoadingSystemInfoForDashboard');
    Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard/system`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx) }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setSystemInfo', response.data.data.items.system);
          if (typeof callback === 'function') {
            callback(ctx);
          }
        } else if (response.data.status === 'error') {
          if (helpers.isTimeoutError(response.data.description) === true) {
            console.error(response.data.description);
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        }
        ctx.$store.commit('toggleLoadingSystemInfoForDashboard');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingSystemInfoForDashboard');
        ctx.error = err;
      }
    );
  },
  getOnlyWlansInfo(ctx) {
    ctx.$store.commit('toggleLoadingWlansInfoForDashboard');
    Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard/wlans`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)  }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setWlansInfo', response.data.data.items.wlans);
        } else if (response.data.status === 'error') {
          if (helpers.isTimeoutError(response.data.description) === true) {
            console.error(response.data.description);
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        }
        ctx.$store.commit('toggleLoadingWlansInfoForDashboard');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingWlansInfoForDashboard');
        ctx.error = err;
      }
    );
  },
  // getOnlyCpesInfo(ctx, callback) {
  //   ctx.$store.commit('toggleLoadingCpesInfoForDashboard');
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard/cpes`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         if (!response.data.data.items.cpes) {
  //           response.data.data.items.cpes = {
  //             by_configstatus: [],
  //             by_connected: [],
  //             by_last_cpestat: [],
  //             by_last_stat: []
  //           };
  //         }
  //         ctx.$store.commit('setCpesInfo', response.data.data.items.cpes);
  //         const cpesIds = [];
  //         ctx.$store.state.cpesInfo.by_last_cpestat.forEach((cpe) => {
  //           if (!cpesIds.includes(cpe.id)) {
  //             cpesIds.push(cpe.id);
  //           }
  //         });
  //         ctx.$store.state.cpesInfo.by_last_stat.forEach((cpe) => {
  //           if (!cpesIds.includes(cpe.id)) {
  //             cpesIds.push(cpe.id);
  //           }
  //         });
  //         if (typeof callback === 'function') {
  //           callback(ctx);
  //         }
  //       } else if (response.data.status === 'error') {
  //         if (helpers.isTimeoutError(response.data.description) === true) {
  //           console.error(response.data.description);
  //         } else {
  //           VueNotifications.error({ message: response.data.description });
  //         }
  //       }
  //       ctx.$store.commit('toggleLoadingCpesInfoForDashboard');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingCpesInfoForDashboard');
  //       ctx.error = err;
  //     }
  //   );
  // },

  getOnlyCpesInfo(ctx, callback) {
    ctx.$store.commit('toggleLoadingCpesInfoForDashboard');
    const locationId = ctx.locationForBlocksStatistics ?? undefined;
    const withChilds = ctx.locationWithChildsForBlocksStatistics ?? true;
    const payload = {
      action: 'R',
      with_childs:  ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };
    if (locationId) {
      payload.q = { by_location: locationId, with_childs: withChilds }
    }
    Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard/cpes`, payload).then(
      (response) => {
        if (response.data.status === 'success') {
          if (!response.data.data.items.cpes) {
            response.data.data.items.cpes = {
              by_configstatus: [],
              by_connected: [],
              by_last_cpestat: [],
              by_last_stat: []
            };
          }
          ctx.$store.commit('setCpesInfo', response.data.data.items.cpes);
          const cpesIds = [];
          ctx.$store.state.cpesInfo.by_last_cpestat.forEach((cpe) => {
            if (!cpesIds.includes(cpe.id)) {
              cpesIds.push(cpe.id);
            }
          });
          ctx.$store.state.cpesInfo.by_last_stat.forEach((cpe) => {
            if (!cpesIds.includes(cpe.id)) {
              cpesIds.push(cpe.id);
            }
          });
          if (typeof callback === 'function') {
            callback(ctx);
          }
        } else if (response.data.status === 'error') {
          if (helpers.isTimeoutError(response.data.description) === true) {
            console.error(response.data.description);
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        }
        ctx.$store.commit('toggleLoadingCpesInfoForDashboard');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingCpesInfoForDashboard');
        ctx.error = err;
      }
    );
  },

  // getOnlyClientsInfo(ctx) {
  //   ctx.$store.commit('toggleLoadingClientsInfoForDashboard');
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard/clients`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setClientsInfo', response.data.data.items.clients);
  //       } else if (response.data.status === 'error') {
  //         if (helpers.isTimeoutError(response.data.description) === true) {
  //           console.error(response.data.description);
  //         } else {
  //           VueNotifications.error({ message: response.data.description });
  //         }
  //       }
  //       ctx.$store.commit('toggleLoadingClientsInfoForDashboard');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingClientsInfoForDashboard');
  //       ctx.error = err;
  //     }
  //   );
  // }

  getOnlyClientsInfo(ctx) {
    ctx.$store.commit('toggleLoadingClientsInfoForDashboard');

    const locationId = ctx.locationForBlocksStatistics ?? undefined;
    const withChilds = ctx.locationWithChildsForBlocksStatistics ?? true;
    const payload = {
      action: 'R',
      with_childs:  ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };
    if (locationId) {
      payload.q = {by_location: locationId, with_childs: withChilds}
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/dashboard/clients`, payload).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setClientsInfo', response.data.data.items.clients);
        } else if (response.data.status === 'error') {
          if (helpers.isTimeoutError(response.data.description) === true) {
            console.error(response.data.description);
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        }
        ctx.$store.commit('toggleLoadingClientsInfoForDashboard');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingClientsInfoForDashboard');
        ctx.error = err;
      }
    );
  }
};
