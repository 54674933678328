<template>
  <div class="col-lg-12">
    <div class="card" :class="{ [`card-accent-${cardAccent}`]: cardAccent }">
      <slot name="loader"></slot>
      <slot name="header-close-button" style="position: absolute"></slot>
      <div class="card-header d-flex align-items-center" :class="{'folding-card-header-when-not-open': !isBlockShown }">
        <div class="clickable width_full d-flex" @click="toggleBlockDisplaying">
          <i v-tooltip.top-center="{ content: this.$t('general.minimize'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }" v-if="isBlockShown" class="icon-arrow-down icon-padding"></i>
          <i v-tooltip.top-center="{ content: this.$t('general.expand'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }" v-else class="icon-arrow-right icon-padding"></i>
          <slot name="header-icon"></slot>
          <span>&nbsp;{{ headerTitle }}</span>
          <slot name="header-caption">
            <div class="text-muted add-padding one-line-text">
              <small>{{ headerCaption }}</small>
            </div>
          </slot>
        </div>

        <slot name="header-link" class="one-line-text"></slot>
      </div>
      <div class="card-block" v-if="isBlockShown">
        <div class="row">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Folding-card',
  props: {
    headerTitle: { type: String },
    headerCaption: { type: String },
    cardAccent: {
      type: String,
      default: ''
    },
    isOpened: { type: Boolean, default: true }
  },
  data() {
    return {
      isBlockShown: this.isOpened
    };
  },
  watch: {
    isOpened:{
      handler(val, oldVal) {
        this.isBlockShown = val
      }
    }
  },
  methods: {
    toggleBlockDisplaying() {
      this.isBlockShown = !this.isBlockShown;
      this.$emit('blockDisplayingChange', this.isBlockShown)
    }
  }
};
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
  user-select: none;
}
.add-padding {
  padding-left: 1em;
}
.width_full {
  width: 100%;
}
.one-line-text {
  white-space: nowrap;
}
.icon-padding {
  padding: 3px 7px 3px 0;
}
</style>

<style lang="scss" scoped></style>
