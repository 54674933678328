import MapsStep3 from '../views/Maps/MapsStep3';

const Maps = () => import(/* webpackChunkName: "Maps" */ '../views/Maps/MapsV3');
const MapsWizard = () => import(/* webpackChunkName: "MapsWizard" */ '../views/Maps/MapsWizard');
const MapsStep1 = () => import(/* webpackChunkName: "MapsWizard" */ '../views/Maps/MapsStep1');
const MapsStep2 = () => import(/* webpackChunkName: "MapsWizard" */ '../views/Maps/MapsStep2'); /* TODO leaflet breaks codesplitting */

const MapsRoute = {
  path: 'maps',
  name: 'maps',
  component: Maps,
  children: [
    {
      path: 'maps-wizard',
      name: 'maps-wizard',
      redirect: 'maps-wizard/maps-step-1',
      component: MapsWizard,
      children: [
        {
          path: 'maps-step-1',
          name: 'maps-step-1',
          component: MapsStep1
        },
        {
          path: 'maps-step-2',
          name: 'maps-step-2',
          component: MapsStep2
        },
        {
          path: 'maps-step-3',
          name: 'maps-step-3',
          component: MapsStep3
        }
      ]
    }
  ]
};

export default MapsRoute;
