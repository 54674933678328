import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';

export default {
  ruleTypes: {
    customer_activity: 'Customer Activity',
    cpu_load: 'CPU load',
    free_ram: 'Free RAM',
    config_error: 'Config error',
    iface_error: 'CPE iface error',
    connected: 'CPE connected',
    disconnected: 'CPE disconnected',
    client_far: 'Client distance',
    client_con: 'Client connected',
    client_dis: 'Client disconnected'
  },
  notificationTypes: {
    telegram: 'Telegram',
    email: 'Email',
    mqtt: 'MQTT'
  },

  getRules(ctx) {
    ctx.$store.commit('toggleLoadingRules');
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/rules`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)  }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.ruleList = response.data.data.itemslist;
          ctx.$store.commit('setRulesList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingRules');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingRules');
        ctx.error = err;
      }
    );
  },
  deleteRule(ctx) {
    ctx.$store.commit('toggleLoadingRules');
    const deleteRuleQuery = { action: 'D', items: { [ctx.ruleIdToDelete]: {} } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/rules`, deleteRuleQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.refreshRulesList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.state.editWlanIndex = '';
        ctx.$store.commit('toggleLoadingRules');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingRules');
        ctx.error = err;
      }
    );
  },
  getCpesRules(ctx) {
    ctx.$store.commit('toggleLoadingCpesRules');
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/cpes`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),  }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.cpesRulesList = helpers.transformArrayToObject(response.data.data.itemslist);
          const items = helpers.transformArrayToObject(response.data.data.itemslist);
          ctx.$store.commit('setCpesRulesList', items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingCpesRules');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingCpesRules');
        ctx.error = err;
      }
    );
  },
  refreshCpesRules(ctx) {
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/cpes`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),  }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.cpesRulesList = response.data.data.items;
          const items = helpers.transformArrayToObject(response.data.data.itemslist);
          ctx.$store.commit('setCpesRulesList', items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  createCpeRules(ctx) {
    const cpeRulesList = JSON.parse(JSON.stringify(ctx.cpesRulesList));

    for (const cpe in cpeRulesList) {
      if (cpeRulesList[cpe].rules) {
        cpeRulesList[cpe].rules.forEach((rule, index) => {
          if (typeof rule === 'object') {
            cpeRulesList[cpe].rules[index] = rule.id;
          }
        });
      }
    }

    ctx.tempCpeRules.forEach((ruleObject) => {
      if (cpeRulesList[ruleObject.cpeId]) {
        cpeRulesList[ruleObject.cpeId].rules = ruleObject.rules;
      }
    });

    const createCpeRulesQuery = { action: 'U', items: cpeRulesList };
    console.warn(createCpeRulesQuery);
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/cpes`, createCpeRulesQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.refreshCpeRuleList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        // ctx.$store.state.editWlanIndex= '';
        // ctx.$store.commit('toggleLoadingRules');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        // ctx.$store.commit('toggleLoadingRules');
        ctx.error = err;
      }
    );
  }
};
