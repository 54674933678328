<template>
  <div class="row" v-if="window.CISCO">
    <div class="col-lg-12 mb-1">
      <button class="btn btn-info btn-md float-right" @click="addNewController" v-if="!isOperator">
        {{ $t('controller.addController') }}
      </button>
    </div>

    <div class="col-lg-12">
      <div class="controllers animated fadeIn" :class="{ 'edit-visible': isEditVisible }">
        <div class="loader loader-backdrop" v-if="isControllersLoading"></div>
        <div class="controllers-list">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header">
                  <i class="fa fa-align-justify"></i>
                  {{ $t('controller.controllersList') }}
                </div>
                <div class="card-block">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('general.name') }}</th>
                        <th>{{ $t('controller.status') }}</th>
                        <th v-if="!isEditVisible">MAC</th>
                        <th v-if="!isEditVisible">{{ $t('controller.serialNumber') }}</th>
                        <th>{{ $t('controller.active') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :class="{
                          selected: updatedController && controller.id === updatedController.id,
                          'controller-updating': controllerIdsOperations.includes(controller.id)
                        }"
                        v-for="controller in controllersList"
                        :key="controller.id"
                        :data-id="controller.id"
                        @click="toggleEdit(controller.id)"
                      >
                        <td>{{ controller.name }}</td>
                        <td>
                          <span
                            v-if="controllerIdsOperations.includes(controller.id)"
                            class="text-info config-updating"
                          >
                            <i class="fa fa-circle-o-notch fa-spin"></i>
                            {{ $t('aps.updating') }}
                          </span>
                          <span
                            v-else
                            :class="{
                              'text-success': controller.status === 'connected',
                              'text-danger': controller.status === 'error',
                              'text-muted': controller.status === 'disconnected' || controller.status === 'empty',
                              'text-primary': controller.status === 'provision'
                            }"
                          >
                            {{ controller.status }}
                          </span>
                        </td>
                        <td v-if="!isEditVisible">
                          <span :class="{ 'text-muted': !controller.mac }">
                            {{ controller.mac ? helpers.getFormatMac(controller.mac) : $t('general.noData')}}
                          </span>
                        </td>
                        <td v-if="!isEditVisible">
                          <span :class="{ 'text-muted': !controller.serial }">
                            {{ controller.serial ? controller.serial : $t('general.noData') }}
                          </span>
                        </td>
                        <td>
                          <span
                            :class="{ 'badge-success': controller.enable, 'badge-default': !controller.enable }"
                            class="badge badge-light mr-q"
                          >
                            {{ controller.enable ? $t('general.on') : $t('general.off') }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="!controllersList.length">
                        <td colspan="4" class="no-data-row">
                          <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="controller-edit" v-if="isEditVisible">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-block">
                  <div class="card-top-buttons">
                    <button type="button" class="btn btn-outline-secondary btn-sm" @click="disableEditMode">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <controllerConfig
                    v-if="updatedController"
                    :controllerData="updatedController"
                    :currentControllerData="currentControllerData"
                    :isDisable="isDisable"
                  ></controllerConfig>
                </div>
                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <button
                      v-if="!isDisable"
                      type="button"
                      class="btn btn-outline-success btn-md"
                      @click="updateController()"
                      :disabled="errors.any() || !enableSaveChanges || muteEdit"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </button>
                    <button
                      :disabled="muteEdit"
                      v-if="!isDisable"
                      type="button"
                      class="btn btn-outline-danger btn-md ml-1"
                      @click="openDeletingDialog(updatedController.id)"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </button>
                  </div>

                  <div>
                    <button @click="disableEditMode" type="button" class="btn btn-outline-secondary btn-md">
                      {{ $t('general.close') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal title="Controller" v-model="wizardModal" @ok="addUser = false" effect="fade/zoom" class="modal-success">
      <h4 slot="modal-title" class="modal-title">{{ $t('controller.controllerHeader') }}</h4>
      <controllerConfig
        :controllerData="newControllerData"
        :currentControllerData="{}"
        :isDisable="isDisable"
      ></controllerConfig>
      <div slot="modal-footer" class="">
        <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
          <div>
            <button type="button" class="btn btn-outline-secondary btn-md" @click="wizardModal = false">
              {{ $t('general.close') }}
            </button>
          </div>
          <div>
            <button
              :disabled="muteEdit || errors.any()"
              v-if="!isDisable"
              type="button"
              class="btn btn-outline-success btn-md"
              @click="createNewController"
            >
              <span :class="{ invisible: muteEdit }">{{ $t('general.add') }}</span>
              <span v-if="muteEdit" class="loader loader--mini"></span>
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal title="Delete User" class="modal-danger" v-model="deleteModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{$t('controller.deleteControllerHeader')}}</h4>
      {{$t('controller.confirmDelete')}}
      <strong>{{ updatedController.name }}</strong>
      ?
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="deleteModal = false">{{ $t('general.cancel') }}</button>
        <button type="button" class="btn btn-outline-danger" :disabled="muteEdit" @click="deleteController">
          <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import VeeValidate from 'vee-validate';
import moment from 'moment';
import controllerConfig from '../components/Controllers/controllerConfig.vue';
import Modal from '../components/Modal.vue';
import controllersService from '../services/controllersService';
import controllerPollingService from '../services/controllerPollingService';
import commonService from '../services/commonService';
import helpers from '../helpers';

Vue.use(require('vue-moment'));

export default {
  name: 'Controllers',
  components: {
    Modal,
    controllerConfig
  },
  data() {
    return {
      newControllerData: {
        name: '',
        description: '',
        ip: '',
        enable: false,
        access: {
          username: '',
          password: '',
          key: ''
        }
      },
      enableSaveChanges: false,
      wizardModal: false,
      deleteModal: false,
      updatedController: {},
      currentCpeData: {},
      modalErrors: [],
      refreshIntervalId: ''
    };
  },
  computed: {
    helpers() {
      return helpers;
    },
    window() {
      return window;
    },
    controllerConfig() {
      return controllerConfig;
    },
    isDisable() {
      return (
        this.$store.state.userData.role !== 'admin' ||
        (this.updatedController && this.controllerIdsOperations.includes(this.updatedController.id))
      );
    },
    isOperator() {
      return this.$store.state.userData.role !== 'admin';
    },
    isControllersLoading() {
      return this.$store.state.loadingControllers;
    },
    controllersList() {
      return this.$store.state.controllersList;
    },
    isEditVisible() {
      return Object.keys(this.updatedController).length;
    },
    muteEdit() {
      return this.$store.state.muteControllerEdit;
    },
    controllerIdsOperations() {
      return helpers.combineModelIdsArrayFromObjectsArray(this.$store.state.controllerOperations);
    },
    currentInterval() {
      return this.$store.state.refreshInterval;
    }
  },
  watch: {
    controllersList() {
      controllerPollingService.startPolling(this);
    },
    currentInterval() {
      if (this.refreshIntervalId) {
        clearInterval(this.refreshIntervalId);
      }
      this.resetRefreshInterval();
    },
    updatedController: {
      handler(val, oldVal) {
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    }
  },
  methods: {
    addNewController() {
      this.openWizard();
    },
    resetNewControllerData() {
      this.newControllerData = {
        name: '',
        description: '',
        ip: '',
        enabled: false,
        access: {
          username: '',
          password: '',
          key: ''
        }
      };
    },
    openWizard() {
      this.wizardModal = true;
    },
    toggleEdit(id) {
      for (const controller of this.controllersList) {
        if (controller.id === id) {
          this.updatedController = JSON.parse(JSON.stringify(controller));
          this.currentControllerData = controller;
        }
      }
    },
    disableEditMode() {
      this.updatedController = {};
      this.deleteModal = false;
      this.wizardModal = false;
    },
    openDeletingDialog() {
      this.deleteModal = true;
    },
    ipv4Validate(value) {
      const macRegex = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return macRegex.test(value);
    },
    createNewController() {
      this.$validator.validateAll().then((result) => {
        if (
          !this.newControllerData.name ||
          !this.newControllerData.ip_addr ||
          !this.ipv4Validate(this.newControllerData.ip_addr) ||
          !this.newControllerData.access.username
        ) {
          return;
        }
        if (result) {
          controllersService.addController(this);
        }
      });
    },
    refreshControllers(spinner) {
      controllersService.getControllers(this, spinner);
    },
    deleteController() {
      controllersService.deleteController(this);
    },
    updateController() {
      this.$validator
        .validateAll({
          name: this.updatedController.name,
          ip_address: this.updatedController.ip_addr,
          username: this.updatedController.access.username
        })
        .then((result) => {
          if (result) {
            controllersService.updateController(this);
          }
        });
    },
    resetRefreshInterval() {
      const refreshInterval = this.currentInterval * 1000;

      if (refreshInterval > 2000) {
        this.refreshIntervalId = setInterval(() => {
          if (this.$route.path === '/controllers') {
            this.refreshControllers();
          } else {
            clearInterval(this.refreshIntervalId);
          }
        }, refreshInterval);
      } else {
        clearInterval(this.refreshIntervalId);
      }
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    }
  },
  created() {
    if (!this.window.CISCO) {
      return;
    }
    controllersService.getControllers(this, 'spinner');
    if (this.currentInterval) {
      this.resetRefreshInterval();
    }
  }
};
</script>

<style lang="scss">
.controllers {
  display: flex;
}

/* Users list */
.controllers-list {
  display: inline-block;
  width: 100%;
}

.controllers-list,
.controller-edit {
  transition: width 0.2s;
}

table tr {
  transition: all 0.2s;
  cursor: pointer;
}
table tr .no-data-row {
  cursor: default;
}
table tbody tr:hover {
  background: rgba(236, 236, 236, 0.59);
}

table tr.selected {
  background: #d9e3ec;
}

/* User edit */
.controller-edit {
  display: inline-block;
  width: 0%;
}

.edit-visible .controllers-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 50%;
}

.edit-visible .controller-edit {
  transition: width 0.2s;
  width: 50%;
}

.controller-updating {
  opacity: 0.9;
  background: rgba(236, 236, 236, 0.45);
}

.status {
  position: relative;

  padding-left: 15px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    display: inline-block !important;
    width: 10px;
    height: 10px;
    margin-right: 0.25rem;
    border: 1px solid #fff;
    border-radius: 50em;
  }

  &--connected:after {
    background-color: #4dbd74 !important;
  }
  &--disconnected:after {
    background-color: #bcc5d0 !important;
  }
}

.card-top-buttons {
  display: flex;
  justify-content: flex-end;
  & .btn-sm {
    padding: 0.18rem 0.35rem;
    font-size: 0.675rem;
  }
}
</style>
