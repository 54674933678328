const support = {
  header: 'Описание платформы',
  glossary: {
    header: 'Глоссарий',
    AP: 'ТД, Access Point, AP — Точка Доступа',
    SOFTWARE: 'ПО — Программное обеспечение',
    BSSID: 'BSSID — Basic Service Set IDentifier (MAC адрес сетевого адаптера)',
    // CPE:
    //   'CPE — Customer Premises Equipment (телекоммуникационное оборудование, расположенное в помещении абонента/клиента)',
    CPU: 'CPU — Central Processing Unit (центральный процессор)',
    DHCP:
      'DHCP — Dynamic Host Configuration Protocol (протокол, отвечающий за динамическую выдачу ip-адресов устройствам сети)',
    DNS: 'DNS — Domain Name System (система доменных имён)',
    HTTP: 'HTTP — HyperText Transfer Protocol (протокол передачи гипертекста)',
    HTTPS: 'HTTPS — HyperText Transfer Protocol Secure (расширение протокола HTTP, поддерживающее шифрование)',
    IP: 'IP — Internet Protocol Address (уникальный сетевой адрес узла в компьютерной сети)',
    MAC: 'MAC — Media Access Control (уникальный идентификатор, присваиваемый каждой единице сетевого оборудования)',
    NAS: 'NAS — Network Attached Storage (сервер для хранения данных на файловом уровне)',
    NAT: 'NAT — Network Address Translation (преобразование сетевых адресов)',
    NMS: 'NMS — Network Management System (система управления сетью)',
    OS: 'OS — Operating System (операционная система)',
    RADIUS:
      'RADIUS — Remote Authentication Dial-In User Service (служба дистанционной аутентификации пользователей по коммутируемым линиям)',
    RRM: 'RRM — Radio Resource Management (управление характеристиками радиопередачи в системе)',
    RSSI: 'RSSI — Received Signal Strength Indicator (полная мощность принимаемого приёмником сигнала)',
    SSID: 'SSID — Service Set Identifier (символьное название беспроводной точки доступа Wi-Fi)',
    URL: 'URL — Uniform Resource Locator (система унифицированных адресов электронных ресурсов)',
    UUID: 'UUID — Universally Unique Identifier (универсальный уникальный идентификатор)',
    VLAN:
      'VLAN — Virtual Local Area Network (локальная компьютерная сеть, представляющая собой группу хостов с общим набором требований)',
    WLAN: 'WLAN — Wireless Local Area Network (локальная сеть, построенная на основе беспроводных технологий)',
    WPA: 'WPA — Wi-Fi Protected Access (программа сертификации устройств беспроводной связи)'
    // WSP: 'WSP — WiMark Systems Platform',
    // WSFW: 'WSFW — WiMark Systems Firmware',
    // WSNMS: 'WSNMS — WiMark Systems Network Management System',
    // WSCPEA: 'WSCPEA — WiMark Systems Customer Premises Equipment Agent'
  },
  nms: {
    locationsHeader: 'Локации',
    dashboardHeader: 'Рабочий стол',
    dashboardMain: 'Рабочий стол предоставляет информацию о:',
    dashboard1: 'характеристиках сервера (процессор, загрузка процессора, память);',
    dashboard2: 'событиях за последние 24 ч (уровень, тип, объект, статус, время точек доступа);',
    dashboard3: 'о состоянии и характеристиках перегруженных и самых активных точек доступа;',
    dashboard4: 'количестве новых и повторных подключений, визитов;',
    dashboard5: 'процентном соотношении марок устройств, с которых был выполнен вход.',

    nmsHeader: 'НМС',
    nmsMain: 'В данной платформе управления сетями содержится:',
    nms1: 'статистика по локациям, точкам доступа и клиентам касаемо системной информации;',
    nms2:
      'события за последние 24 ч (присутствовали ранее на рабочем столе), правила(?), подключениях точек доступа (модель, имя, время работы, локация), клиентах, удаленности пользователей;',
    nms3: 'управление характеристиками радиопередачи в системе (RRM);',
    nms4:
      'информация о сетях WiFi (название (SSID), безопасность, количество точек доступа, клиентов, виртуальных локальных сетей (VLAN));',
    nms5: 'служба дистанционной аутентификации пользователей (RADIUS);',
    nms6:
      'осуществляющий контроль и фильтрация проходящего через сеть сетевого трафика в соответствии с заданными правилами (Firewall);',
    nms7:
      'технология Hotspot 2.0, позволяющая автоматически аутентифицироваться и получить доступ в сеть Wi-Fi, предварительно отправив запрос к точке доступа;',
    nms8: 'гостевой контроль (позволяет установить правила редиректа для порталов).',

    statistics: 'Статистика',
    systemHeader: 'Система',
    systemMain1:
      'В данном подразделе происходит сбор данных с каждой локации. В локации объединены все образованные ТД.',
    systemMain2:
      'Здесь видно, сколько каждая локация потребляет трафика, количество клиентов, загруженность процессора и памяти.',

    cpesHeader: 'Точки',
    cpesMain1: 'Более подробные данные каждой ТД можно найти здесь.',
    cpesMain2:
      'По каждой характеристике (потребляемый трафик, количество клиентов, загруженность процессора и памяти) предоставлены данные за определенный устанавливаемый промежуток времени.',

    wlansHeader: 'Сети Wi-Fi',
    wlansMain:
      'Информация о WiFi-сети, которая раздается конкретными ТД отображена на этой вкладке — трафик, количество клиентов и локация, к которой он относится.',

    clientsHeader: 'Клиенты',
    clientsMain:
      'После подключения клиента к WiFi-сети в этом подразделе находится вся информация о его устройстве — MAC-адрес, IP, удаленность от ТД, название сети, к которой он подключен (можно увидеть, нажав напротив устройства кнопку show client info), время сессии подключения к определенному роутеру.',

    reportsHeader: 'Отчёты',
    reportsMain:
      'Отчеты по данным о событиях, количестве клиентов, точках доступа и совокупные отчеты по точкам (Обобщенная статистика по ТД) можно настроить в данном разделе. А также сделать выгрузку отчета по периодам. Доступные форматы — JSON, CSV, XLS. Для XLS у Обобщенная статистика по ТД можно добавить графики зависимости определенной величины от времени (загрузка, выгрузка, клиенты, загруженность процессора).',

    monitoring: 'Монитор',
    eventsHeader: 'События',
    eventsMain1:
      'На графике представлены события за последние 24 ч, связанные с временем и статусом подключенных клиентов.',
    eventsMain2:
      'Так же, нажав на клиента, можно посмотреть подробную информацию о сессии подключения, ТД, WiFi-сети.',

    rulesHeader: 'Правила',
    rulesMain1: 'Каждому AP можно задать конкретные "правила" подключения пользователей.',
    rulesMain2: 'Условия прописываются в Скрипт и подключаются во вкладке Правила.',
    rulesMain3: 'Далее можно выбрать нужное правило к каждой ТД.',

    cpesConnHeader: 'Подключение ТД',
    cpesConnMain:
      'В Подключениях ТД удобно отследить время работы каждой точки. Фильтры позволяют отсортировать по локациям, дате и статусу подключения, что делает мониторинг раздела весьма удобным.',

    wifiAnalyzerHeader: 'Анализатор Wi-Fi',
    wifiAnalyzerMain1:
      'Данный интерфейс сканирует точку доступа и выдает результаты о технических характеристиках ее WiFi-сетей (сигнал, канал, частота, смещение, ширина).',
    wifiAnalyzerMain2:
      'Каждая WiFi-сеть имеет свой график. Для удобства можно выбрать как все доступные WLAN, так и несколько конкретных.',

    clientDistanceHeader: 'Удаленность пользователя',
    clientDistanceMain:
      'Позволяет увидеть, на каком расстоянии до ТД находится клиент. Ползунком под иллюстрацией можно указать конкретное время, в которое вас интересует положение подключенного устройства с точностью до секунд.',
    systemCpesMain1:
      'В данном блоке происходит управление точками доступа — перезагрузка, обновление ПО, сброс настроек и установка новых, создание шаблонов настроек, а также вся системная информация о ней.',
    systemCpesMain2:
      'В дополнительных настройках WiFi-сети можно добавить формирование отчета по статистике по промежуткам времени.',
    SNMPStatHeader: 'Статистика SNMP',
    SNMPStatMain:
      'Позволяет просмотреть статистику SNMP, которая получена с устройств, сконфигурированных в разделе Конфигурация мониторинга по SNMP',
    ClientRFHeader: 'Состояние Wi-Fi клиента',
    ClientRFMain:
      'Позволяет просмотреть состояние подключения клиента к сети на графике. Можно просмотреть такие параметры как скорость приема, скорость передачи, сигнал, шум, SNR. Также доступен просмотр рейтинга клиентов по качеству сигнала (можно просмотреть SNR среднее и т.д.)',
    SNMPMonitoringConfigHeader: 'Конфигурация мониторинга по SNMP',
    SNMPMonitoringConfigMain:
      'Позволяет включить SNMP мониторинг для устройств, включить слежение за агентом и его состоянием',
    RRMHeader: 'RRM',
    RRMMain:
      'RRM - Radio Resource Management - настройка групп для работы запатентованного алгоритма управления радио ресурсами. ',
    TemplatesHeader: 'Шаблоны',
    TemplatesMain: 'Раздел позволяет создавать шаблоны с настройками, для их дальнейшего применения на точках доступа',
    WlansHeader: 'Сети Wi-Fi',
    WlansMain:
      'В данном разделе возможно осуществляется просмотр и настройка сетей Wi-Fi. Можно настроить SSID, локацию сети, параметры безопасности, сетевой инкапсуляции, ограничения скорости и другие. Для сетей с некоторыми типом безопасности возможно сгенерировать QR для подключения к сети',
    ClientsHeader: 'Клиенты',
    ClientsMain:
      'В данном разделе можно просмотреть различную информацию о клиентах, подключенных к сети. Например дату и время первого подключения, последнего подключения, название точки доступа и сети. Также возможно просмотреть Health Status клиента, баллы которого выставляются на основе клиентского RSSI и SNR',
    RadiusHeader: 'RADIUS',
    RadiusMain:
      'RADIUS - добавление внешних серверов AAA (аутентификации, авторизации и аккаунтинга) для организации на WLAN типа сети WPA2-Enterprise или СОРМ',
    HotspotHeader: 'Hotspot 2.0',
    HotspotMain:
      'Управление профилями Hotspot 2.0, с помощью которых можно обеспечить подключение к закрытым сетям типа WPA2-Enterprise с помощью предустановленного на устройство профиля, либо с помощью SIM карты устройства (EAP-SIM, EAP-AKA, EAP-AKA’)',
    FirewallHeader: 'Брандмауэр',
    FirewallMain:
      'Раздел позволяет просматривать и редактировать существующие правила брандмауэра и создавать новые. Наш брандмауэр основан на Iptables. Правила создаются в его нотации. Документацию по правилам можно найти тут: ',
    GuestControlHeader: 'Гостевой контроль',
    GuestControlMain:
      'Управление NAS - Network Access Server - модулем, отвечающим за перенаправление не авторизованных пользователей на портал идентификации и авторизации (Captive Portal).',
    LicensesHeader: 'Лицензии',
    // LicensesMain: 'В данном разделе отображается информация текущем количестве точек доступа, максимальном количестве точек доступа. Возможно просмотреть статистику по точкам доступа, радарам и тепловым картам на графике за выбранный период'
    LicensesMain:
      'В данном разделе отображается информация текущем количестве точек доступа, максимальном количестве точек доступа. Возможно просмотреть статистику по точкам доступа, Wi-Fi таргет и тепловым картам на графике за выбранный период'
  },
  portal: {
    portalHeader: 'Портал',
    portalMain:
      'В данном блоке сосредоточены настройка и статистика по порталам авторизации — специальным веб страницам, на которых пользователи могут проходить этапы идентификации (по SMS или Email) с последующей авторизацией и просмотром рекламы.',
    dashboardHeader: 'Рабочий стол',
    dashboardMain: 'Краткая сводка основных данных по разделу Портал.',
    authHeader: 'Подключения, Аутентификации, Авторизации',
    authMain:
      'Статистика по подключениям, аутентификации и авторизации новых, уникальных пользователей, браузерам, операционным системам, типах и производителях устройств, которые используют клиенты находится в данных разделах. Все диаграммы можно скачать в виде изображений.',
    locationHeader: 'Локация',
    locationMain:
      'На карте можно посмотреть все локации и описание к ним: владельцев, адреса, даты создания. Каждую локацию можно отредактировать в специальном окне.',
    birthdaysHeader: 'Дни рождения',
    birthdaysMain: 'Аналитика по дням рождения пользователей, их социальным сетям и контактам.',
    adsHeader: 'Реклама и опросы',
    adsMain:
      'Статистика просмотров рекламы и опросов, а так же состоянию рекламы в данный момент (идет показ, планируется и т.д.).',
    recommendationHeader: 'Рекомендации',
    recommendationMain:
      'Небольшие подсказки по добавлению рекламы и опросов для более эффективного пользования платформой.',

    configuration: 'Конфигурация',
    profilesHeader: 'Профили',
    profilesMain1: 'Здесь видны все профили, нажав на каждый из которых можно выставить определенные настройки:',
    profilesMain2:
      'У каждого способа авторизации можно настроить сессию — время, количество, действия, которые необходимо выполнить, чтобы получить доступ в интернет.',
    profiles1: 'привязать профиль к локации;',
    profiles2: 'установить часовой пояс;',
    profiles3: 'выставить условия работы (WLANs, APs);',
    profiles4:
      'добавить MAC-адреса пользователей в специальные листы доступа — черный список (доступа к профилю нет), белый список (доступ без аутентификации и авторизации, преимущественно для устройств, на которых нельзя принять SMS), список доступа (вместо аутентификации использовать заранее известный код или ваучер);',
    profiles5: 'указать способ аутентификации (с помощью SMS или Email) и авторизации (через соц.сети, ваучеры и т.д.)',

    previewHeader: 'Предпросмотр',
    previewMain:
      'Для просмотра демо-версии портала необходимо выбрать профиль, страницу и аккаунт. Далее можно перейти по ссылке непосредственно на сам портал и проверить его работу.',

    pagesHeader: 'Страницы',
    pagesMain:
      'В данном интерфейсе настраивается визуальная часть страницы портала  — положение логотипов, фон, тема, заголовок.',

    hotelVouchersHeader: 'Отельные ваучеры',
    hotelVouchersMain1:
      'Ваучеры нужны для входа в интернет без аутентификации (допустим, через SMS) с помощью специального кода.',
    hotelVouchersMain2:
      'Вся информация о доступных ваучерах отображается на данной странице — профиль, время действия, код для доступа, статус (активирован или нет)',
    hotelVouchersMain3: 'Все данные можно редактировать в окне для каждого ваучера.',

    adsAndPollsHeader: 'Реклама и Опросы',
    adsAndPollsMain1: 'Все, что касается настройки рекламы находится здесь.',
    adsAndPollsMain2:
      'Возможно выбрать один из типов рекламы для портала: изображение, видео, опрос по теме, опрос о данных пользователя (например, указать дату дня рождения или пол).',
    adsAndPollsMain3: 'При настройке каждого вида рекламы можно указать:',
    adsAndPolls1: 'период активности рекламы;',
    adsAndPolls2: 'приоритет (чем больше приоритет, тем чаще будет показываться пользователям данная реклама);',
    adsAndPolls3:
      'лимит показов (бесконечное или ограниченное, по истечении лимита, данная реклама перестанет показываться пользователям);',
    adsAndPolls4: 'общее время показа и возможность пропуска рекламы;',
    adsAndPolls5: 'настройки показа по дням недели, времени суток;',
    adsAndPolls6: 'показ определенным (всем) моделям устройств или ОС.',

    data: {
      analyticsAndData: 'Аналитика и Данные',
      accountsHeader: 'Пользователи',
      accountsMain:
        'Здесь находятся гости Ваших Порталов авторизации. Аккаунт гостя постоянно пополняется: у каждого гостя может быть несколько устройств, авторизация через разные социальные сети, пользовательские сессии. Это позволяет точно знать день рождения и местоположение. Все данные можно выгрузить и сохранить в форматах PDF, CSV и XLS.',
      statisticsHeader: 'Статистика',
      statisticsMain:
        'В одном месте собраны данные по новым, повторным пользователям и уникальным, повторяющимся подключениям пользователей с разбивкой по типу Операционной системы, типу устройства, по языкам, по производителям и, конечно, по способам идентификации и авторизации. Все данные можно выгрузить за период и сохранить в форматах PDF, CSV и XLS.',
      birthdaysMain:
        'Данные о днях рождения ваших гостей, собранные из социальных сетей при авторизации и с помощью опросов. Все данные можно скачать в PDF, CSV и XLS.',
      adsAndPollsMain:
        'Статистика просмотров по типам авторизации (бесплатный, спонсорский, служебный) в виде графиков за указанный период времени.',
      portalSessionsHeader: 'Найти сессию',
      portalSessionsMain:
        'Здесь находятся данные о сессиях гостей вашего портала. Можно узнать данные об IP адресе, версии ОС, браузере и профиле сессии. Присутствует возможность фильтрации по различным параметрам',
      portalLocationsStatHeader: 'Локации',
      portalLocationsStatMain:
        'Сводная статистика по каждой из локаций. Есть данные о кликах, просмотрах, визитах (первых, повторных, уникальных, всех) по каждой из локации. Есть возможность фильтрации по профилю, временному промежутку и локациям'
    },
    paidInternet: {
      paidInternetHeader: 'Платный интернет',
      paidInternetMain: 'В данном блоке находятся настройки платного интернета.',

      vouchersHeader: 'Ваучеры',
      vouchersMain1: 'Добавление ваучеров со всеми сопутствующими настройками — время действия и тариф.',
      vouchersMain2: 'В списке видно статус ваучера, код для его использования, время действия и тариф.',

      tariffsHeader: 'Тарифы',
      tariffsMain: 'Добавление тарифов и всех необходимых для него настроек: стоимость, скорость, длина сессии.',

      paymentHeader: 'Платежные системы',
      paymentMain:
        'Здесь происходит настройка методов пополнения баланса: установка типа (внешний шлюз — переход на другой сайт для оплаты, инструкция — выполнения ряда действий для оплаты), изображения, указания мерчанта — уникального номера для предоставления его поставщику Платежной системы. '
    }
  },
  analytics: {
    analyticsHeader: 'Аналитика',
    // analyticsMain1:
    //   'В блоке Wi-Fi аналитика собраны результаты по анализу данных, получаемых на основе так называемого "Wi-Fi радара" - радио интерфейса Wi-Fi, работающего в режиме пассивного монитора эфира, который собирает все запросы на подключение от устройств к Wi-Fi сетям.',
    analyticsMain1:
      'В блоке Wi-Fi аналитика собраны результаты по анализу данных, получаемых на основе так называемого "Wi-Fi Таргет" - радио интерфейса Wi-Fi, работающего в режиме пассивного монитора эфира, который собирает все запросы на подключение от устройств к Wi-Fi сетям.',
    analyticsMain2: 'На основе этих данных получаются следующие метрики:',
    analytics1: 'оценка проходимости некоторой точки, локации;',
    analytics2: 'построение тепловых карт при сопоставлении данных о местоположении с реальными картами;',
    analytics3: 'сбор списка MAC-адресов для загрузки в Яндекс / myTarget для таргетинга в онлайне.',

    visitorsHeader: 'Посетители',
    visitorsMain1:
      'Отчеты по всем собираемым данным о клиентах формируются здесь. С помощью фильтров можно составить отчет как по всем ТД, так и по конкретным. На основе этого отчета можно выявить повторные посещения, продолжительность визитов, устройства клиентов и т.д.',
    // visitorsMain2:
    //   'На странице представлена оценка посещаемости локаций, в которых установлены радары. Непрерывным визитом считается захват пользователя на протяжении 10 минут. Новые пользователи считаются относительно выбранного периода. У одного пользователя может быть несколько визитов. Важно учесть, что данные в аналитике уже лишены шума и рандомных MAC адресов, что позволяет более точно оценить посещаемость и не учитывать одних пользователей по несколько раз.',
    visitorsMain2:
      'На странице представлена оценка посещаемости локаций, в которых установлены Wi-Fi Таргет. Непрерывным визитом считается захват пользователя на протяжении 10 минут. Новые пользователи считаются относительно выбранного периода. У одного пользователя может быть несколько визитов. Важно учесть, что данные в аналитике уже лишены шума и рандомных MAC адресов, что позволяет более точно оценить посещаемость и не учитывать одних пользователей по несколько раз.',
    heatmapHeader: 'Тепловые карты',
    // heatmapMain:
    //   'На странице тепловых карт сопоставляются данные о местоположении точек доступа и клиентов, улавливаемых с помощью Wi-Fi радара с реальными картами. Карты позволяют визуализировать наиболее горячие зоны с точки зрения проходимости за выбранный период, а также оценить динамику.',
    heatmapMain:
      'На странице тепловых карт сопоставляются данные о местоположении точек доступа и клиентов, улавливаемых с помощью Wi-Fi Таргет с реальными картами. Карты позволяют визуализировать наиболее горячие зоны с точки зрения проходимости за выбранный период, а также оценить динамику.',
    // radarExportHeader: 'Выгрузка Радара',
    radarExportHeader: 'Wi-Fi Таргет',
    // radarExportMain:
    //   'Выгрузка радара — выгрузка данных клиентов по Email в MyTarget, Yandex Audience для использования их при показе рекламы.'
    radarExportMain:
      'Wi-Fi Таргет — выгрузка данных клиентов по Email в MyTarget, Yandex Audience для использования их при показе рекламы.'
  }
};
export default support;
