<template>
  <div
    :style="customInlineStylesForModalWrapperDiv"
    role="dialog"
    :class="['modal', effect]"
    @click="backdrop && action(false, 1)"
    @transitionend="transition = false"
  >
    <div
      :class="[
        'modal-dialog',
        {
          'modal-xxxl': xxxlarge,
          'modal-xxl': xxlarge,
          'modal-xl': xlarge,
          'modal-lg': large,
          'modal-sm': small,
          'modal-centered': centeredPosition
        }
      ]"
      role="document"
      :style="{ width: optionalWidth }"
      @click.stop="action(null)"
    >
      <div class="modal-content">
        <slot name="modal-header">
          <div class="modal-header">
            <slot name="modal-title">
              <h4 class="modal-title">
                <slot name="title">
                  {{ title }}
                </slot>
              </h4>
            </slot>
            <button v-if="closeBtn" type="button" class="close" @click="action(false, 2)">
              <span>&times;</span>
            </button>
          </div>
        </slot>
        <slot name="modal-body">
          <div class="modal-body">
            <slot />
          </div>
        </slot>
        <slot name="modal-footer">
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="action(false, 3)">
              {{ cancelText }}
            </button>
            <button type="button" class="btn btn-primary" @click="action(true, 4)">
              {{ okText }}
            </button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
function getScrollBarWidth() {
  if (document.documentElement.scrollHeight <= document.documentElement.clientHeight) {
    return 0;
  }
  const inner = document.createElement('p');
  inner.style.width = '100%';
  inner.style.height = '200px';

  const outer = document.createElement('div');
  outer.style.position = 'absolute';
  outer.style.top = '0px';
  outer.style.left = '0px';
  outer.style.visibility = 'hidden';
  outer.style.width = '200px';
  outer.style.height = '150px';
  outer.style.overflow = 'hidden';
  outer.appendChild(inner);

  document.body.appendChild(outer);
  const w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  let w2 = inner.offsetWidth;
  if (w1 === w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return w1 - w2;
}

export default {
  props: {
    backdrop: { type: Boolean, default: true },
    callback: { type: Function, default: null },
    cancelText: { type: String, default: 'Close' },
    effect: { type: String, default: null },
    large: { type: Boolean, default: false },
    xlarge: { type: Boolean, default: false },
    xxlarge: { type: Boolean, default: false },
    xxxlarge: { type: Boolean, default: false },
    okText: { type: String, default: 'Save changes' },
    small: { type: Boolean, default: false },
    title: { type: String, default: '' },
    value: { type: Boolean, required: true },
    closeBtn: { type: Boolean, default: false },
    width: { default: null },
    optionalZIndex: { default: undefined, type: Number },
    showModalUnderVueNotifications: { default: false, type: Boolean },
    centeredPosition: { type: Boolean, default: false },
    scrollBarWidthEqualPaddingRightForBody: { type: Boolean, default: true }
  },
  data() {
    return {
      transition: false,
      val: null
    };
  },
  computed: {
    optionalWidth() {
      if (this.width === null) {
        return null;
      }
      if (Number.isInteger(this.width)) {
        return `${this.width}px`;
      }
      return this.width;
    },
    customInlineStylesForModalWrapperDiv() {
      let result = '';
      if (this.optionalZIndex) {
        result = result + `z-index: ${this.zIndex};`;
      } else {
        if (this.showModalUnderVueNotifications) {
          result = result + `z-index: 99998`;
        }
      }
      return result;
    }
  },
  watch: {
    transition(val, old) {
      // console.log('transition val', val)
      // console.log('transition old', old)
      if (val === old) {
        return;
      }
      const el = this.$el;
      const { body } = document;
      if (val) {
        // starting
        if (this.val) {
          el.querySelector('.modal-content').focus();
          el.style.display = 'block';
          setTimeout(() => el.classList.add('in'), 0);
          body.classList.add('modal-open');
          if (getScrollBarWidth() !== 0 && this.scrollBarWidthEqualPaddingRightForBody) {
            body.style.paddingRight = `${getScrollBarWidth()}px`;
          }
        } else {
          body.style.paddingRight = null;
          el.classList.remove('in');
        }
      } else {
        // ending
        this.$emit(this.val ? 'opened' : 'closed');
        if (!this.val) {
          el.style.display = 'none';
          body.style.paddingRight = null;
          body.classList.remove('modal-open');
        }
      }
    },
    val(val, old) {
      this.$emit('input', val);
      if (old === null ? val === true : val !== old) this.transition = true;
    },
    value(val, old) {
      if (val !== old) this.val = val;
    }
  },
  mounted() {
    this.val = this.value;
  },
  methods: {
    action(val, p) {
      if (val === null) {
        return;
      }
      if (val && this.callback instanceof Function) this.callback();
      this.$emit(val ? 'ok' : 'cancel', p);
      this.val = val || false;
    }
  }
};
</script>
<style lang="scss">
.modal-centered {
  position: relative !important;
  top: 35% !important;
  bottom: 45% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.modal {
  transition: all 0.3s ease;
}
.modal.in {
  background-color: rgba(0, 0, 0, 0.8);
}
.modal.zoom .modal-dialog {
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  top: 300px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.modal.zoom.in .modal-dialog {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform: translate3d(0, -300px, 0);
  transform: translate3d(0, -300px, 0);
  opacity: 1;
}
</style>
<style lang="scss" scoped>
.modal-xxl {
  max-width: 1500px;
}
.modal-xxxl {
  max-width: 95%;
}
</style>
