<template>
  <div class="row h-100 small-font-wlan-wizard-last-step">
    <div class="col-lg-12 h-100">
      <div class="card h-100">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.summary') }}</div>
          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('general.summaryConfirmation') }}</span>
          <div class="summary-info-in-wlan-wizard-wrapper">
            <div class="summary-info-in-wlan-wizard">
              <div class="first-column-summary-info">
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>{{ $t('general.basicParameters') }}</h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard">
                      SSID:
                      <span class="text-muted">{{ newWlanData.ssid }}</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard">
                      {{ $t('general.description') }}:
                      <span class="text-muted">{{ newWlanData.description }}</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard">
                      {{ $t('general.baseLocation') }}:
                      <span class="text-muted">{{ locationName(newWlanData.base_location) }}</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard">
                      {{ $t('wlans.wizardVisibility') }}:
                      <span class="text-muted">
                        {{
                          newWlanData.hidden ? `${$t('wlans.visibilityHidden')}` : `${$t('wlans.visibilityVisible')}`
                        }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>{{ $t('wlans.wizardSecuritySettings') }}</h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.security') }}:
                      <!--              <span class="text-muted" v-if="newWlanData.security.type">{{ newWlanData.security.type }}</span>-->
                      <span
                        class="text-muted"
                        v-if="newWlanData.security.type && newWlanData.security.type === 'wpapersonal'"
                      >
                        WPA Personal
                      </span>
                      <span
                        class="text-muted"
                        v-else-if="newWlanData.security.type && newWlanData.security.type === 'wpaenterprise'"
                      >
                        WPA Enterprise
                      </span>
                      <span
                        class="text-muted"
                        v-else-if="newWlanData.security.type && newWlanData.security.type === 'wpa2personal'"
                      >
                        WPA2 Personal
                      </span>
                      <span
                        class="text-muted"
                        v-else-if="newWlanData.security.type && newWlanData.security.type === 'wpa2enterprise'"
                      >
                        WPA2 Enterprise
                      </span>

                      <span
                        class="text-muted"
                        v-else-if="newWlanData.security.type && newWlanData.security.type === 'wpa3personal'"
                      >
                        WPA3 Personal
                      </span>
                      <span
                        class="text-muted"
                        v-else-if="newWlanData.security.type && newWlanData.security.type === 'wpa3enterprise'"
                      >
                       WPA3 Enterprise
                      </span>
                      <span
                        class="text-muted"
                        v-else-if="newWlanData.security.type && newWlanData.security.type === 'wpa23personal'"
                      >
                        WPA2/3 Personal
                      </span>
                      <span
                        class="text-muted"
                        v-else-if="newWlanData.security.type && newWlanData.security.type === 'wpa23enterprise'"
                      >
                        WPA2/3 Enterprise
                      </span>

                      <span v-else class="text-muted">{{ $t('wlans.securityOpen') }}</span>
                    </p>
                    <p
                      class="text-info info-text-in-wlan-wizard"
                      v-if="newWlanData.security.type.indexOf('personal') !== -1"
                    >
                      {{ $t('general.password') }}:
                      <span class="text-muted">{{ newWlanData.security.data.psk }}</span>
                    </p>
                    <p
                      class="text-info info-text-in-wlan-wizard"
                      v-if="
                        newWlanData.security.type.indexOf('personal') !== -1 ||
                        newWlanData.security.type.indexOf('enterprise') !== -1
                      "
                    >
                      {{ $t('wlans.suites') }}:
                      <span class="mr-q text-muted" v-for="suite in newWlanData.security.data.suites">{{ suite }}</span>
                    </p>
                    <p
                      class="text-info info-text-in-wlan-wizard"
                      v-if="newWlanData.security.type.indexOf('enterprise') !== -1"
                    >
                      RADIUS:
                      <span class="mr-q text-muted" v-for="radius in newWlanData.security.data.radiusauthentication">
                        {{ radiusName(radius) }}
                      </span>
                    </p>
                    <p
                      class="text-info info-text-in-wlan-wizard"
                      v-if="newWlanData.security.type.indexOf('enterprise') !== -1"
                    >
                      {{ $t('wlans.hotspotProfileTitle') }}:
                      <span v-if="newWlanData.security.data.hotspot20_profile !== ''" class="mr-q text-muted">
                        {{ hotspotName(newWlanData.security.data.hotspot20_profile) }}
                      </span>
                      <span v-else class="mr-q text-muted">{{ $t('general.no') }}</span>
                    </p>
                  </div>
                </div>
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>
                      {{ $t('wlans.networkEncapsulation') }}
                      <span class="wlan-wizard-last-step-not-for-cisco" v-if="window.CISCO">*</span>
                    </h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      NAT:
                      <span class="text-muted">
                        {{ newWlanData.nat ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard" v-if="newWlanData.nat">
                        {{$t('wlans.SSHWebAccess')}}:
                      <span class="text-muted">
                        {{ newWlanData.firewall.nat_access ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <!--            <p class="text-info info-text-in-wlan-wizard" v-if="!newWlanData.nat">-->
                    <!--              {{$t('wlans.tunneling')}}:-->
                    <!--              <span class="text-muted" v-if="newWlanData.vlan != 0">{{ newWlanData.vlan }}</span>-->
                    <!--              <span class="text-muted" v-else>{{$t('wlans.vlanNative')}}</span>-->
                    <!--            </p>-->
                    <p class="text-info info-text-in-wlan-wizard" v-if="!newWlanData.nat">
                      {{ $t('wlans.tunneling') }}:
                      <span class="text-muted">
                        {{ newWlanData.tunneling ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard" v-if="!newWlanData.nat && newWlanData.tunneling">
                      {{ $t('wlans.interface') }}:
                      <span v-if="newWlanData.default_tunnel !== ''" class="text-muted">
                        {{ newWlanData.default_tunnel }}
                      </span>
                      <span v-else class="text-muted">{{ $t('wlans.noInterfaces') }}</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard" v-if="!newWlanData.nat">
                      VLAN:
                      <span class="text-muted" v-if="newWlanData.vlan != 0">{{ newWlanData.vlan }}</span>
                      <span class="text-muted" v-else>{{ $t('wlans.vlanNative') }}</span>
                    </p>
                  </div>
                </div>
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>{{ $t('wlans.wizardRADIUSAccounting') }}</h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.mirroring') }}:
                      <span class="text-muted">
                        {{ newWlanData.radius_acct_mirroring ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard">
                      {{ $t('wlans.radiusAccounting') }}:
                      <template v-if="newWlanData.radius_acct_servers.length > 0">
                        <span class="mr-q text-muted" v-for="radius in newWlanData.radius_acct_servers">
                          {{ radiusName(radius) }}
                        </span>
                      </template>
                      <template v-else>
                        <span class="mr-q text-muted">{{ $t('general.no') }}</span>
                      </template>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard" v-if="newWlanData.beeline_accountng_type !== ''">
                      {{ $t('wlans.rsnaSettings') }}:
                      <span class="text-muted">{{ newWlanData.beeline_accountng_type }}</span>
                    </p>
                    <!--            <p class="text-info info-text-in-wlan-wizard">-->
                    <!--              {{ $t('wlans.visibility') }}:-->
                    <!--              <span class="text-muted">{{ newWlanData.hidden === true ? `${$t('wlans.hidden')}` : `${$t('wlans.visible')}` }}</span>-->
                    <!--            </p>-->
                  </div>
                </div>
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6 v-if="newWlanData.nas_id || newWlanData.nas_port_id">
                      {{ $t('wlans.wizardRADIUSAdditionalSettings') }}
                    </h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1" v-if="newWlanData.nas_id">
                      {{ $t('wlans.NASidentifier') }}:
                      <span class="text-muted">{{ newWlanData.nas_id }}</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard" v-if="newWlanData.nas_port_id">
                      {{ $t('wlans.NASportid') }}:
                      <span class="text-muted">{{ newWlanData.nas_port_id }}</span>
                    </p>
                  </div>
                </div>
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>{{ $t('wlans.wizardSpeedControlSettings') }}</h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1" v-if="newWlanData.speed_download.value !== 0">
                      {{ $t('wlans.speedDownload') }}:
                      <span class="text-muted">{{ newWlanData.speed_download.value }} kbit</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard mt-1" v-if="newWlanData.speed_download.value === 0">
                      {{ $t('wlans.speedDownload') }}:
                      <span class="text-muted">{{ $t('wlans.unlim') }}</span>
                    </p>

                    <p class="text-info info-text-in-wlan-wizard mt-1" v-if="newWlanData.speed_upload.value !== 0">
                      {{ $t('wlans.speedUpload') }}:
                      <span class="text-muted">{{ newWlanData.speed_upload.value }} kbit</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard mt-1" v-if="newWlanData.speed_upload.value === 0">
                      {{ $t('wlans.speedUpload') }}:
                      <span class="text-muted">{{ $t('wlans.unlim') }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="second-column-summary-info">
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>
                      {{ $t('wlans.wizardGuestControl') }}
                      <span class="wlan-wizard-last-step-not-for-cisco" v-if="window.CISCO">*</span>
                    </h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.L2isolation') }}:
                      <span class="text-muted">
                        {{ newWlanData.l2isolate ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p
                      v-if="newWlanData.guest_control.captive_redirect === ''"
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.redirect') }}:
                      <span class="text-muted">{{ $t('general.no') }}</span>
                    </p>
                    <p v-else class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.redirect') }}:
                      <span class="text-muted">{{ redirectName(newWlanData.guest_control.captive_redirect) }}</span>
                    </p>
                    <p
                      v-if="newWlanData.guest_control.captive_redirect !== ''"
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.MACRADIUSAuthentication') }}:
                      <span class="mr-q text-muted" v-for="radius in newWlanData.guest_control.mac_radius_auth_servers">
                        {{ radiusName(radius) }}
                      </span>
                    </p>
                    <p v-if="newWlanData.firewall.l2_chain === ''" class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.firewall') }}:
                      <span class="text-muted">{{ $t('general.no') }}</span>
                    </p>
                    <p v-else class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.firewall') }}:
                      <span class="text-muted">{{ firewallName(newWlanData.firewall.l2_chain) }}</span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.filterMode') }}:
                      <span v-if="newWlanData.filtermode === 'None'" class="text-muted">{{ $t('wlans.none') }}</span>
                      <span v-if="newWlanData.filtermode === 'WhiteList'" class="text-muted">
                        {{ $t('wlans.whiteList') }} ({{ newWlanData.whitelist.length }}
                        {{ $t('wlans.wizardBlackWhiteListItem') }})
                        <li v-for="(mac, index) in newWlanData.whitelist">
                          <small data-list="whitelist" :data-index="index" :data-mac="mac" class="text-uppercase">
                            {{ mac }}
                          </small>
                        </li>
                      </span>
                      <span v-if="newWlanData.filtermode === 'BlackList'" class="text-muted">
                        {{ $t('wlans.blackList') }} ({{ newWlanData.blacklist.length }}
                        {{ $t('wlans.wizardBlackWhiteListItem') }})
                        <li v-for="(mac, index) in newWlanData.blacklist">
                          <small data-list="blacklist" :data-index="index" :data-mac="mac" class="text-uppercase">
                            {{ mac }}
                          </small>
                        </li>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>
                      {{ $t('wlans.wizardMobility') }}
                      <span class="wlan-wizard-last-step-not-for-cisco" v-if="window.CISCO">*</span>
                    </h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.fastTransition') }}:
                      <span class="text-muted">
                        {{ newWlanData.roam80211r ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <!--                ft_over_ds ft over ds-->
                    <p
                      v-if="newWlanData.roam80211r === true"
                      class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1"
                    >
                      {{ $t('wlans.overTheDS') }}
                      :
                      <span class="text-muted">
                        {{ newWlanData.ft_over_ds ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>

                    <!--                nas_generate nas generate-->
                    <p
                      v-if="newWlanData.roam80211r === true"
                      class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1"
                    >
                      {{ $t('wlans.nas_generateCaption') }}
                      :
                      <span class="text-muted">
                        {{ newWlanData.nas_generate ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>

                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.PMKcaching') }}:
                      <span class="text-muted">
                        {{ newWlanData.pmkcaching ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <!--                ieee80211k-->
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.ieee80211k') }}:
                      <span class="text-muted">
                        {{ newWlanData.ieee80211k ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p
                      v-if="newWlanData.ieee80211k === true"
                      class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1"
                    >
                      {{ $t('wlans.rrm_neighbor_report') }}:
                      <span class="text-muted">
                        {{ newWlanData.rrm_neighbor_report ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p
                      v-if="newWlanData.ieee80211k === true"
                      class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1"
                    >
                      {{ $t('wlans.rrm_beacon_report') }}:
                      <span class="text-muted">
                        {{ newWlanData.rrm_beacon_report ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <!--                ieee80211v-->
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.ieee80211v') }}:
                      <span class="text-muted">
                        {{ newWlanData.ieee80211v ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p
                      v-if="newWlanData.ieee80211v === true"
                      class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1"
                    >
                      {{ $t('wlans.wnm_sleep_modeForWizardLastStep') }}
                      <info class="" :content="$t('wlans.wnm_sleep_modeInfo')" />
                      :
                      <span class="text-muted">
                        {{ newWlanData.wnm_sleep_mode ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <p
                      v-if="newWlanData.ieee80211v === true"
                      class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1"
                    >
                      {{ $t('wlans.bss_transition') }}:
                      <span class="text-muted">
                        {{ newWlanData.bss_transition ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>

                    <!--                            RSSI threshold rssi_threshold-->
                    <p
                      v-if="newWlanData.hasOwnProperty('rssi_threshold')"
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.rssiThreshold') }}:
                      <span class="text-muted">{{ newWlanData.rssi_threshold }} dbm</span>
                    </p>

                    <!--                           Signal Stay  signal_stay-->
                    <p v-if="newWlanData.hasOwnProperty('signal_stay')" class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.signalStay') }}:
                      <span class="text-muted">{{ newWlanData.signal_stay }} dbm</span>
                      <info class="" :content="$t('wlans.signalStayForInfo')" />
                    </p>

                    <!--                           Signal Strikes signal_strikes-->
                    <p
                      v-if="
                        newWlanData.hasOwnProperty('signal_stay') &&
                        newWlanData.signal_stay !== 0 &&
                        newWlanData.hasOwnProperty('signal_strikes')
                      "
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.signalStrikesForLastWizardStep') }}:
                      <span class="text-muted">{{ newWlanData.signal_strikes }}</span>
                      <info class="" :content="$t('wlans.signalStrikesForInfo')" />
                    </p>

                    <!--                          signal Poll Time signal_poll_time-->
                    <p
                      v-if="
                        newWlanData.hasOwnProperty('signal_stay') &&
                        newWlanData.signal_stay !== 0 &&
                        newWlanData.hasOwnProperty('signal_poll_time')
                      "
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.signalPollTime') }}:
                      <span class="text-muted">
                        {{ newWlanData.signal_poll_time }} {{ $t('wlans.signalPollTimeForBadgeSec') }}
                      </span>
                    </p>

                    <!--                           signal Drop Reason signal_drop_reason-->
                    <p
                      v-if="
                        newWlanData.hasOwnProperty('signal_stay') &&
                        newWlanData.signal_stay !== 0 &&
                        newWlanData.hasOwnProperty('signal_drop_reason')
                      "
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.signalDropReason') }}:
                      <span class="text-muted">{{ newWlanData.signal_drop_reason }}</span>
                      <info @clicked="showDropReasonModal" class="" :content="$t('wlans.signalDropReasonForInfo')" />
                      <small
                        class="ml-q"
                        style="text-decoration: underline;"
                        @click="showDropReasonModal"
                        role="button"
                      >
                        ({{ $t('wlans.signalDropReasonMoreInfo') }})
                      </small>
                    </p>

                    <!--                     Band Steering band_steering-->
                    <p
                      v-if="newWlanData.hasOwnProperty('band_steering')"
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.bandSteeringForLastStepWlanWizard') }}:
                      <span class="text-muted">
                        {{ newWlanData.band_steering ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>

<!--                    &lt;!&ndash;                     Load Balancing load_balancing&ndash;&gt;-->
<!--                    <p-->
<!--                      v-if="newWlanData.hasOwnProperty('load_balancing')"-->
<!--                      class="text-info info-text-in-wlan-wizard mt-1"-->
<!--                    >-->
<!--                      {{ $t('wlans.loadBalancing') }}:-->
<!--                      <span class="text-muted">-->
<!--                        {{ newWlanData.load_balancing ? `${$t('general.on')}` : `${$t('general.off')}` }}-->
<!--                      </span>-->
<!--                    </p>-->
                    <!--                     Load Balancing load_balancing-->
                    <p
                      v-show="isShowLoadBalancingSwitcher"
                      v-if="newWlanData.hasOwnProperty('load_balancing')"
                      class="text-info info-text-in-wlan-wizard mt-1"
                    >
                      {{ $t('wlans.loadBalancing') }}:
                      <span class="text-muted">
                        {{ newWlanData.load_balancing ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="summary-info-in-wlan-wizard-item">
                  <div class="summary-info-in-wlan-wizard-item-header">
                    <h6>
                      {{ $t('wlans.wizardWMM') }}
                      <span class="wlan-wizard-last-step-not-for-cisco" v-if="window.CISCO">*</span>
                      <info
                        @clicked="showWMMInfoDetails"
                        class="info-tooltip-in-wlans-wmm"
                        :content="$t('wmm.infoTooltip')"
                      />
                    </h6>
                  </div>
                  <div class="summary-info-in-wlan-wizard-item-block">
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('general.enableShort') }}:
                      <span class="text-muted">
                        {{ newWlanData.wmm.disabled ? `${$t('general.no')}` : `${$t('general.yes')}` }}
                      </span>
                    </p>
                    <p class="text-info info-text-in-wlan-wizard mt-1">
                      {{ $t('wlans.UAPSD') }}:
                      <span class="text-muted">
                        {{ newWlanData.wmm.uapsd ? `${$t('general.on')}` : `${$t('general.off')}` }}
                      </span>
                    </p>
                    <div
                      class="text-info info-text-in-wlan-wizard mt-1"
                      v-for="(category, category_key) in CATEGORIES"
                      :key="category_key"
                    >
                      {{ $t(`wmm.${category_key}`) }}:
                      <div
                        class="d-inline"
                        v-if="
                          !newWlanData.wmm.categories ||
                          !newWlanData.wmm.categories[`${category}`] ||
                          Object.keys(newWlanData.wmm.categories[`${category}`]).length === 0
                        "
                      >
                        <span class="text-muted">{{ $t('wmm.default') }}</span>
                      </div>
                      <div class="d-inline" v-else>
                        <p class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1">
                          ACM:
                          <span class="text-muted">
                            {{
                              newWlanData.wmm.categories[`${category}`].hasOwnProperty('cli_acm') &&
                              newWlanData.wmm.categories[`${category}`].cli_acm
                                ? `${$t('general.on')}`
                                : `${$t('general.off')}`
                            }}
                          </span>
                        </p>
                        <p
                          class="text-info info-text-in-wlan-wizard info-text-in-wlan-wizard-level2 mt-1"
                          v-for="(config, config_key) in CONFIGURATION"
                          :key="config_key"
                          v-if="
                            newWlanData.wmm.categories[`${category}`].hasOwnProperty(`${config_key}`) &&
                            `${config_key}` !== 'cli_acm'
                          "
                        >
                          {{ config.name }}:
                          <span class="text-muted">
                            {{ newWlanData.wmm.categories[`${category}`][`${config_key}`] }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--          <div class="summary-info-in-wlan-wizard-item">-->
              <!--            <div class="summary-info-in-wlan-wizard-item-header">-->
              <!--              -->
              <!--            </div>-->
              <!--            <div class="summary-info-in-wlan-wizard-item-block">-->
              <!--              -->
              <!--            </div>-->
              <!--          </div>-->
            </div>
          </div>
          <div class="summary-info-in-wlan-wizard-item" v-if="window.CISCO">
            <small class="text-danger p-0" v-if="window.CISCO">* {{ $t('wlans.notAvailableForCisco') }}</small>
          </div>
          <div class="mt-1"></div>
          <!--          <button type="button" class="btn btn-success float-right"-->
          <!--                  @click="confirmNewWlanAndCreate">-->
          <!--            {{ $t('general.confirm') }}-->
          <!--          </button>-->
          <!--          <button type="button" class="btn btn-outline-secondary float-left"-->
          <!--                  @click="this.$parent.prevStep">-->
          <!--            {{ $t('general.back') }}-->
          <!--          </button>-->
        </div>
        <div class="buttons-block-warpper">
          <button type="button" class="btn btn-success float-right" @click="confirmNewWlanAndCreate">
            {{ $t('general.confirm') }}
          </button>
          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">
            {{ $t('general.back') }}
          </button>
        </div>
      </div>
    </div>
    <Modal title="Show WMM Info" class="modal-info" v-model="showingWMMInfoDetailsModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('wmm.modalInfoTitle') }}</h4>
      <WMMInfo></WMMInfo>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <button type="button" class="btn btn-secondary" @click="showingWMMInfoDetailsModal = false">
          {{ $t('general.close') }}
        </button>
      </div>
    </Modal>
    <Modal
      title="About Drop Reason"
      class="modal-info modal-for-drop-codes-info"
      v-model="showingDropReasonModal"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('wlans.signalDropReason') }}</h4>
      <div>
        <div>
          <span>{{ $t('wlans.signalDropReasonMoreInfoForModal') }}:</span>
        </div>
        <div>
          <span>
            <a href="https://blogs.arubanetworks.com/industries/802-11-reason-codes-and-status-codes/">
              https://blogs.arubanetworks.com/industries/802-11-reason-codes-and-status-codes/
            </a>
          </span>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <button type="button" class="btn btn-secondary" @click="showingDropReasonModal = false">
          {{ $t('general.close') }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import wlansPollingService from '../../../services/wlansPollingService';
import commonService from '../../../services/commonService';
import hotspotService from '../../../services/hotspotService';
import radiusService from '../../../services/radiusService';
import wlanService from '../../../services/wlanService';
import redirectsService from '../../../services/redirectsService';
import WMMInfo from '../../../components/WLANs/WMMInfo.vue';
import Info from '../../../components/Universal/info-icon.vue';
import Modal from '../../../components/Modal.vue';

const CATEGORIES = {
  Background: 'BK',
  BestEffort: 'BE',
  Video: 'VI',
  Voice: 'VO'
};

const CONFIGURATION = {
  cli_cw_min: {
    name: 'Client cwMin',
    type: 'int',
    validation: '0,15'
  },
  cli_cw_max: {
    name: 'Client cwMax',
    type: 'int',
    validation: '0,15'
  },
  cli_aifs: {
    name: 'Client AIFS',
    type: 'int',
    validation: '0,255'
  },
  cli_txop: {
    name: 'TXOP Limit',
    type: 'int',
    validation: '0,65535'
  },
  cli_acm: {
    name: 'ACM',
    type: 'bool'
  },
  ac_cw_min: {
    name: 'AC cwMin',
    type: 'int',
    validation: '0,15'
  },
  ac_cw_max: {
    name: 'AC cwMax',
    type: 'int',
    validation: '0,15'
  },
  ac_aifs: {
    name: 'AC AIFS',
    type: 'int',
    validation: '0,255'
  },
  ac_burst: {
    name: 'Max. Burst',
    type: 'float',
    validation: '0.0, 999.0',
    decimal: '1'
  }
};

export default {
  name: 'StepLast',
  components: { WMMInfo, Info, Modal },
  data() {
    return {
      showingDropReasonModal: false,
      showingWMMInfoDetailsModal: false,
      newWlanData: {}
    };
  },
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    window() {
      return window;
    },
    CATEGORIES() {
      return CATEGORIES;
    },
    CONFIGURATION() {
      return CONFIGURATION;
    },
    isShowLoadBalancingSwitcher() {
      return this.$store.getters.isShowLoadBalancingSwitcher;
    }
  },
  methods: {
    showDropReasonModal() {
      // console.log('show modal');
      this.showingDropReasonModal = true;
    },
    showWMMInfoDetails() {
      // console.log('clickeed');
      this.showingWMMInfoDetailsModal = true;
    },
    confirmNewWlanAndCreate() {
      const transformedQuery = { ...this.newWlanData };
      transformedQuery.vlan = parseInt(transformedQuery.vlan);
      if (!this.newWlanData.vlan) {
        this.newWlanData.vlan = 0;
      }
      // open wlan case
      if (!transformedQuery.security.type) {
        transformedQuery.security = null;
      } else if (transformedQuery.security.type.indexOf('personal') !== -1) {
        delete transformedQuery.security.data.radius_auth;
      } else if (transformedQuery.security.type.indexOf('enterprise') !== -1) {
        delete transformedQuery.security.data.psk;
      }
      this.axios.post(`${window.RESTAPI || ''}/api/wlans`, { action: 'C', items: { 0: transformedQuery } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewWlanData');
          this.$parent.nextStep();
          wlansPollingService.hadleOperationResponse(response.data.data.items);
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    radiusName(id) {
      const radiusName = commonService.radiusNamebyId(id);
      return radiusName;
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    redirectName(id) {
      const redirectName = commonService.redirectNamebyId(id);
      return redirectName;
    },
    firewallName(id) {
      const showFirewallFromId = commonService.firewallNamebyId(id);
      return showFirewallFromId;
    },
    hotspotName(id) {
      const showHotspotFromId = commonService.hotspotNamebyId(id);
      return showHotspotFromId;
    }
  },
  created() {
    this.$store.commit('wlanWizardGoingToLastStepAfterClickSkipButtonEnd');
    radiusService.getRadius(this);
    hotspotService.getHotspots(this);
    wlanService.getHosts(this);
    redirectsService.getRedirectsRule(this);
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
  }
};
</script>

<style scoped>
/*.summary-info-in-wlan-wizard {*/
/*  !*минус высота хедера*!*/
/*  !*max-height: calc(70vh - 60px);*!*/
/*  !*overflow-y: auto;*!*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*  justify-content: space-between;*/
/*}*/
.summary-info-in-wlan-wizard {
  /*минус высота хедера*/
  /*max-height: calc(70vh - 60px);*/
  /*overflow-y: auto;*/
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 600px;
  width: 90%;
}
.first-column-summary-info {
  /*margin-right: 5rem;*/
  min-width: 220px !important;
}
.second-column-summary-info {
  min-width: 220px !important;
}
.summary-info-in-wlan-wizard-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.summary-info-in-wlan-wizard-item {
  margin-top: 1em;
}

.info-text-in-wlan-wizard {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  margin-top: 0.3em !important;
}
.info-text-in-wlan-wizard-level2 {
  padding-left: 1.5em !important;
  margin-top: 0 !important;
}
.wlan-wizard-last-step-not-for-cisco {
  color: red;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.small-font-wlan-wizard-last-step {
  font-size: 0.85em !important;
}

.modal-for-drop-codes-info {
  font-size: 1.2em !important;
}
</style>
