<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block summary">
          <div class="h4 m-0">{{ $t('general.step3') }}</div>
          <small class="text-muted">{{ $t('general.summary') }}</small>
          <h5 class="mt-1 mb-1">{{ $t('template.templateSettings') }}</h5>
          <p class="">
            {{ $t('general.name') }}:
            <span class="text-info">{{ newTemplateData.name }}</span>
          </p>
          <p class="" v-if="newTemplateData.description">
            {{ $t('general.description') }}:
            <span class="text-info">{{ newTemplateData.description }}</span>
          </p>
          <p class="">
            {{ $t('template.cpeModel') }}:
            <span class="text-info" v-if="newTemplateData.model">{{ newTemplateData.model.description }}</span>
            <span class="text-info" v-if="!newTemplateData.model">{{ $t('general.noModel') }}</span>
          </p>
          <p class="">
            {{ $t('general.baseLocation') }}:
            <span class="text-info">{{ locationName(newTemplateData.base_location) }}</span>
          </p>

          <h5 class="mt-1 mt-2">{{ $t('template.configGeneral') }}</h5>
          <!--<h6 class="mt-2">General</h6>-->
          <p
            class=""
            v-if="
              !newTemplateData.template.cpe_config_template.wifi ||
              (newTemplateData.model.caps && !Object.keys(newTemplateData.model.caps).length)
            "
          >
            {{ $t('aps.modalWLANsTitle') }}:
            <span
              class="text-info"
              v-for="wlan in newTemplateData.template.wlans"
              v-if="newTemplateData.template.wlans"
            >
              <i class="fa fa-wifi"></i>
              {{ wlan.ssid }}
            </span>
            <span class="text-info" v-if="!newTemplateData.template.wlans.length">{{ $t('aps.modalNoWLANs') }}</span>
          </p>

          <div
            class="mt-2 mb-2"
            v-for="radio in newTemplateData.template.cpe_config_template.wifi"
            v-if="newTemplateData.model.caps.wifi[radio.id] && newTemplateData.template.cpe_config_template.wifi"
          >
            <h6 class="mt-1">
              <img class="interface-block-icon" src="/static/img/radio.svg" />
              {{ $t('template.configFor') }} {{ radio.id }}
            </h6>
            <p class="">
              {{ $t('aps.bandMode') }}:
              <span class="text-info">{{ radio.bandmode }}</span>
            </p>
            <p class="">
              {{ $t('aps.bandwidth') }}:
              <span class="text-info">{{ radio.bandwidth }}</span>
            </p>
            <p class="">
              {{ $t('aps.channels') }}:
              <span v-for="channel in radio.channels" class="text-info mr-q">{{ channel }}</span>
              <span v-if="!radio.channels.length" class="text-info">Auto</span>
            </p>
            <p class="">
              {{ $t('aps.txPowerAdjust') }}:
              <span class="text-info" v-if="radio.power.range">
                ({{ radio.power.range[0] }} - {{ radio.power.range[1] }}) {{ $t('aps.dbm') }}
              </span>
              <span class="text-info" v-if="radio.txpower == '0'">Auto</span>
            </p>
            <p class="">
              {{ $t('aps.country') }}:
              <span class="text-info">{{ radio.country }}</span>
            </p>
            <p class="">
              {{ $t('aps.modalWLANsTitle') }}:
              <span class="text-info" v-for="wlan in radio.wlans">
                <i class="fa fa-wifi"></i>
                {{ wlan.ssid }}
              </span>
              <span class="text-info" v-if="!radio.wlans.length">{{ $t('aps.modalNoWLANs') }}</span>
            </p>

            <p class="">
              {{ $t('aps.maxClients') }}:
              <span class="text-info">{{ radio.maxclients }}</span>
            </p>
            <p class="" v-if="radio.scanningconfig">
              {{ $t('aps.backgroundScaning') }}:
              <span class="text-info">{{ radio.scanningconfig.enabled ? $t('aps.modalYes') : $t('aps.modalNo') }}</span>
            </p>
          </div>
          <div
            class="mt-2 mb-2"
            v-for="wiredInterface in newTemplateData.template.cpe_config_template.wired"
            v-if="
              newTemplateData.model.caps.wired &&
              newTemplateData.model.caps.wired.length &&
              newTemplateData.model.caps.wired[wiredInterface.id] &&
              newTemplateData.template.cpe_config_template.wired
            "
          >
            <h6 class="">
              <img class="interface-block-icon" src="/static/img/ethernet1.svg" />
              {{ $t('template.configFor') }} {{ wiredInterface.id }}
            </h6>

            <table class="wired-table">
              <tr>
                <th><span class="pl-q pr-q">Acct.</span></th>
                <th>VLAN</th>
                <th>Tunnel</th>
                <th>Fake WLAN</th>
                <th
                  v-for="port in newTemplateData.model.caps.wired[wiredInterface.id].ports"
                  v-if="
                    newTemplateData.model.caps.wired.length &&
                    (port.role === 'lan' || port.role === 'wan') &&
                    port.type === 'socket'
                  "
                  class="text-nowrap"
                  :class="{ 'text-center': wiredInterface.vlans.length, 'text-right': !wiredInterface.vlans.length }"
                >
                  <span class="p-q">{{ port.role }} {{ port.index }}</span>
                </th>
              </tr>
              <tr
                v-for="wiredConfig in wiredInterface.vlans"
                class="animated fadeIn"
                v-if="wiredInterface.vlans.length"
              >
                <td>
                  <span :class="{ 'text-info': wiredConfig.acct, 'text-muted': !wiredConfig.acct }">
                    <span v-if="wiredConfig.acct">{{ $t('general.yes') }}</span>
                    <span v-else>{{ $t('general.no') }}</span>
                  </span>
                </td>
                <td>
                  <span class="text-info">{{ wiredConfig.vlan }}</span>
                </td>
                <td>
                  <span class="text-info">{{ wiredConfig.tunnel }}</span>
                </td>
                <td>
                  <span
                    :class="{
                      'text-info': wlanNamebyId(wiredConfig.fake_wlan),
                      'text-muted': !wlanNamebyId(wiredConfig.fake_wlan)
                    }"
                  >
                    {{ wlanNamebyId(wiredConfig.fake_wlan) || 'None' }}
                  </span>
                </td>
                <td
                  v-for="port in newTemplateData.model.caps.wired[wiredInterface.id].ports"
                  v-if="(port.role === 'lan' || port.role === 'wan') && port.type === 'socket'"
                  class="text-center"
                >
                  <span class="text-info" v-if="wiredConfig.ports.includes(port.index)">incl.</span>
                  <span class="text-muted" v-else>excl.</span>
                </td>
              </tr>
              <tr v-if="!wiredInterface.vlans.length">
                <td
                  :colspan="newTemplateData.model.caps.wired[wiredInterface.id].ports.length + 3"
                  class="pt-h text-center"
                >
                  <span class="text-muted mr-1">no VLAN on this interface</span>
                </td>
              </tr>
            </table>
          </div>
          <h5 class="mt-1 mt-2">{{ $t('template.advancedSettings') }}</h5>

          <div class="mt-1">
            <p class="">
              {{ $t('aps.modalStatTitle') }}:
              <span class="text-info">
                {{
                  newTemplateData.template.cpe_config_template.stats_config.enabled
                    ? $t('aps.modalEnabled')
                    : $t('aps.modalDisabled')
                }}
              </span>
            </p>
            <p class="">
              {{ $t('aps.modalLogsTitle') }}:
              <span class="text-info">
                {{
                  newTemplateData.template.cpe_config_template.log_config.enabled
                    ? $t('aps.modalEnabled')
                    : $t('aps.modalDisabled')
                }}
              </span>
            </p>
            <p class="">
              {{ $t('aps.modalWiFiRadarTitle') }}:
              <span class="text-info">
                {{
                  newTemplateData.template.cpe_config_template.lbs_config.enabled
                    ? $t('aps.modalEnabled')
                    : $t('aps.modalDisabled')
                }}
              </span>
            </p>
          </div>

          <div class="mt-1">
            <p class="">
              {{ $t('aps.modalFirewallTitle') }}:
              <span class="text-info">
                {{
                  newTemplateData.template.cpe_config_template.firewall.l2_chain
                    ? commonService.firewallNamebyId(newTemplateData.template.cpe_config_template.firewall.l2_chain)
                    : $t('aps.modalDisabled')
                }}
              </span>
            </p>
            <p class="">
              {{ $t('aps.wanBlockAccess') }}:
              <span class="text-info">
                {{
                  newTemplateData.template.cpe_config_template.firewall.wan_access_block
                    ? $t('aps.modalEnabled')
                    : $t('aps.modalDisabled')
                }}
              </span>
            </p>
          </div>
          <div class="mt-2">
            <button
              type="button"
              class="btn btn-outline-primary float-right cpe-register-next-button"
              @click="confirmTemplateCreation"
            >
              {{ $t('template.confirmButton') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary float-left cpe-register-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import commonService from '../../services/commonService';
import helpers from '../../helpers';
import templatesService from '../../services/templatesService';

export default {
  name: 'TemplatesStep3',
  data() {
    return {
      newTemplateData: {},
      cpeModelData: false
    };
  },
  computed: {
    commonService() {
      return commonService;
    }
  },
  methods: {
    confirmTemplateCreation() {
      this.$store.commit('toggleLoadingCpeModels', true);
      const newTemplateData = JSON.parse(JSON.stringify(this.$store.state.newTemplateData));
      if (newTemplateData.template.cpe_config_template.wifi && newTemplateData.model) {
        newTemplateData.template.cpe_config_template.wifi.forEach((currIterface) => {
          if (currIterface.frequency === '2.4') {
            if (currIterface.bandmode === '11n') {
              currIterface.bandmode = '11g';
            }
          }
          if (currIterface.frequency === '5') {
            currIterface.bandmode = '11a';
          }
          currIterface.wlans = currIterface.wlans.map((el) => el.id);
          currIterface.scanningconfig.reportperiod = 0;
          currIterface.scanningconfig.scannumber = 0;
          currIterface.scanningconfig.scantimeout = 0;
          currIterface.maxclients = parseInt(currIterface.maxclients);
          newTemplateData.template.cpe_config_template.wifi[currIterface.id] = currIterface;
        });
        if (newTemplateData.template.cpe_config_template.wifi) {
          newTemplateData.template.cpe_config_template.wifi = helpers.transformArrayToObject(
            newTemplateData.template.cpe_config_template.wifi
          );
        }
        if (!newTemplateData.model.caps.wifi.radio0) {
          delete newTemplateData.template.cpe_config_template.wifi.radio0;
        }
        if (!newTemplateData.model.caps.wifi.radio1) {
          delete newTemplateData.template.cpe_config_template.wifi.radio1;
        }
        if (!newTemplateData.model.caps.wifi.radio2) {
          delete newTemplateData.template.cpe_config_template.wifi.radio2;
        }
        if (newTemplateData.template.wlans) {
          newTemplateData.template.wlans = newTemplateData.template.wlans.map((el) => el.id);
        }
        newTemplateData.model = newTemplateData.model.id;
      } else {
        newTemplateData.template.wlans = newTemplateData.template.wlans.map((el) => el.id);
      }
      if (newTemplateData.template.cpe_config_template.wired) {
        newTemplateData.template.cpe_config_template.wired = helpers.transformArrayToObject(
          newTemplateData.template.cpe_config_template.wired
        );
      }
      newTemplateData.template.cpe_config_template.lbs_config.reportperiod = parseFloat(
        newTemplateData.template.cpe_config_template.lbs_config.reportperiod
      );
      newTemplateData.template.cpe_config_template.log_config.reportperiod = parseFloat(
        newTemplateData.template.cpe_config_template.log_config.reportperiod
      );
      newTemplateData.template.cpe_config_template.stats_config.reportperiod = parseFloat(
        newTemplateData.template.cpe_config_template.stats_config.reportperiod
      );
      newTemplateData.template.cpe_config_template.lbs_config.clienttimeout = parseFloat(
        newTemplateData.template.cpe_config_template.lbs_config.clienttimeout
      );

      if (!newTemplateData.template.cpe_config_template.log_config.log_port) {
        newTemplateData.template.cpe_config_template.log_config.log_port = 0;
      }

      Vue.axios
        .post(`${window.RESTAPI || ''}/api/cpe_config_templates`, { action: 'C', items: { 0: newTemplateData } })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              this.$store.commit('toggleLoadingCpeModels', true);
              templatesService.getCpeTemplates(this);
              this.$store.commit('resetNewTemplateData');
              this.$parent.nextStep();
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
              this.$store.commit('toggleLoadingCpeModels', true);
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
            this.error = err;
            this.$store.commit('toggleLoadingCpeModels', true);
          }
        );
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    wlanNamebyId(id) {
      const showWLANFromId = commonService.wlanNamebyId(id);
      return showWLANFromId || '';
    }
  },
  created() {
    this.newTemplateData = JSON.parse(JSON.stringify(this.$store.state.newTemplateData));
  }
};
</script>
<style lang="scss" scoped></style>
