var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['modal', _vm.effect],style:(_vm.customInlineStylesForModalWrapperDiv),attrs:{"role":"dialog"},on:{"click":function($event){_vm.backdrop && _vm.action(false, 1)},"transitionend":function($event){_vm.transition = false}}},[_c('div',{class:[
      'modal-dialog',
      {
        'modal-xxxl': _vm.xxxlarge,
        'modal-xxl': _vm.xxlarge,
        'modal-xl': _vm.xlarge,
        'modal-lg': _vm.large,
        'modal-sm': _vm.small,
        'modal-centered': _vm.centeredPosition
      }
    ],style:({ width: _vm.optionalWidth }),attrs:{"role":"document"},on:{"click":function($event){$event.stopPropagation();return _vm.action(null)}}},[_c('div',{staticClass:"modal-content"},[_vm._t("modal-header",[_c('div',{staticClass:"modal-header"},[_vm._t("modal-title",[_c('h4',{staticClass:"modal-title"},[_vm._t("title",[_vm._v(" "+_vm._s(_vm.title)+" ")])],2)]),(_vm.closeBtn)?_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return _vm.action(false, 2)}}},[_c('span',[_vm._v("×")])]):_vm._e()],2)]),_vm._t("modal-body",[_c('div',{staticClass:"modal-body"},[_vm._t("default")],2)]),_vm._t("modal-footer",[_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){return _vm.action(false, 3)}}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.action(true, 4)}}},[_vm._v(" "+_vm._s(_vm.okText)+" ")])])])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }