<template>
  <div>
    <loader-spinner centered v-if="isShowingSpinner"></loader-spinner>
    <div class="form-group mt-2 mt-2 d-flex flex-column align-items-start justify-content-start">
      <div>
        <h5>
          {{ $t('aps.cpeConfigSetTitleHeader') }}
        </h5>
      </div>
    </div>
    <div class="form-group mt-2 mb-0 d-flex flex-column align-items-center justify-content-center">
      <div class="w-100">
        <div class="form-group">
          <input
            class="form-control-file w-100"
            @change="fileSelectHandler"
            type="file"
            id="selectFiles"
            value="Import"
          />
          <br />
          <p>{{ $t('aps.cpeConfigSetFromUploadedFileIsertToFieldCaption') }}</p>
        </div>
        <textarea
          class="w-100 cpe-config-set-cpe-config-aria"
          @input="handleTextAreaInput"
          v-model="configForSetOnCPEsInTextArea"
        ></textarea>
        <transition name="fade">
          <div class="error-in-uploaded-data-in-cpe-config-set" v-if="areErrorsInJSON">
            {{ $t('aps.cpeConfigSetErrorsInUploadedData') }}
          </div>
        </transition>
        <transition name="fade">
          <div v-if="isApplyInputedConfigButtonEnabled" class="mt-1 w-100">
            <div class="w-100">
              <b>{{ $t('aps.cpeConfigSetCpeListCaption') }}:</b>
            </div>
            <!--          <ul>-->
            <!--            <li v-for="cpe of this.configForSetOnCPEs">-->
            <!--              <span>{{$t('aps.cpeConfigSetCpeListNameCaptionAfterApplying')}}: {{cpe.name}}, Id: {{cpe.id}} </span>-->
            <!--            </li>-->
            <!--          </ul>-->
            <div class="mt-1 w-100">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-left">Id</th>
                    <th class="text-left">{{ $t('aps.cpeConfigSetCpeListNameCaptionBeforeApplying') }}</th>
                    <th class="text-left">{{ $t('aps.cpeConfigSetCpeListNameCaptionAfterApplying') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cpe of this.configForSetOnCPEs">
                    <td class="">{{ cpe.id }}</td>
                    <td class="">
                      <span v-if="getCurrentCpeNameById(cpe.id) !== ''">
                        {{ getCurrentCpeNameById(cpe.id) }}
                      </span>
                      <empty-cell-value v-else></empty-cell-value>
                    </td>
                    <td class="">
                      <span v-if="cpe.name !== ''">
                        {{ cpe.name }}
                      </span>
                      <empty-cell-value v-else></empty-cell-value>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </transition>
        <div class="mt-1">
          <button
            :disabled="!isApplyInputedConfigButtonEnabled"
            type="button"
            class="btn btn-outline-secondary btn-md"
            @click="applyInputedConfig"
          >
            {{ $t('general.apply') }}
          </button>
        </div>
      </div>
    </div>
    <div class="form-group mt-q d-flex flex-row align-items-center justify-content-start">
      <!--            <div>-->
      <!--              <button type="button" class="btn btn-outline-secondary btn-md" @click="copyCpeConfigFromClipboardButtonHandler">-->
      <!--                {{ $t('aps.cpeConfigSetCopyFromClipboardButtonCaption') }}-->
      <!--              </button>-->
      <!--            </div>-->
      <!--      <div class="ml-q">-->
      <!--        <button type="button" class="btn btn-outline-secondary btn-md" @click="setFromUploadedFileButtonHandler">-->
      <!--          {{ $t('aps.cpeConfigSetFromUploadedFileButtonCaption') }}-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import EmptyCellValue from '../empty-cell-value.vue';
import cpeService from '../../services/cpeService';

export default {
  name: 'CpeConfigSet',
  components: { EmptyCellValue },
  data() {
    return {
      currentCpesList: [],
      areErrorsInJSON: false,
      configForSetOnCPEs: [],
      configForSetOnCPEsInTextArea: '',
      isSpinnerShowing: false,
      handleTextAreaInputTimer: null
    };
  },
  created() {
    this.getCurrentCpes();
  },
  computed: {
    isShowingSpinner() {
      return (
        this.isSpinnerShowing ||
        this.isgetCpesCompactFromUploadedConfigRequestExecuting ||
        this.isUpdateCpesConfigFromUploadedConfigExecuting
      );
    },
    isUpdateCpesConfigFromUploadedConfigExecuting() {
      return this.$store.state.updateCpesConfigFromUploadedConfigExecuting;
    },
    isgetCpesCompactFromUploadedConfigRequestExecuting() {
      return this.$store.state.getCpesCompactFromUploadedConfigRequestExecuting;
    },
    isApplyInputedConfigButtonEnabled() {
      try {
        if (!this.areErrorsInJSON && this.configForSetOnCPEs.length !== 0) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    }
  },
  methods: {
    getCurrentCpes() {
      cpeService.getCpesCompactFromUploadedConfig(this);
    },
    getCurrentCpeNameById(cpeId) {
      try {
        for (const cpe of this.currentCpesList) {
          if (cpe.id === cpeId) {
            return cpe.name;
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    checkCpeConfigValidity(cpesConfigs) {
      // console.log(cpesConfigs)
      try {
        if (!Array.isArray(cpesConfigs)) {
          return false;
        }
        for (const cpeConfigItem of cpesConfigs) {
          if (
            typeof cpeConfigItem === 'object' &&
            Object.keys(cpeConfigItem).length > 0 &&
            Object.prototype.hasOwnProperty.call(cpeConfigItem, 'id') &&
            cpeConfigItem.id !== '' &&
            Object.prototype.hasOwnProperty.call(cpeConfigItem, 'base_location') &&
            Object.prototype.hasOwnProperty.call(cpeConfigItem, 'config') &&
            typeof cpeConfigItem.config === 'object' &&
            Object.keys(cpeConfigItem.config).length > 0
          ) {
            return true;
          }
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    whenErrorActions() {
      this.areErrorsInJSON = true;
      // this.configForSetOnCPEsInTextArea = '';
      this.configForSetOnCPEs = [];
    },
    // applyInputedConfig() {
    //   if (this.isApplyInputedConfigButtonEnabled) {
    //     this.$emit('cpesConfigEmit', this.configForSetOnCPEs)
    //   }
    //   // console.log('apply settings');
    // },
    applyInputedConfig() {
      const inputed = this.configForSetOnCPEsInTextArea;
      const inputedAsJsonString = JSON.stringify(JSON.parse(inputed));
      this.$nextTick(() => {
        if (this.handleTextAreaInputTimer !== null) {
          clearTimeout(this.handleTextAreaInputTimer);
          this.handleTextAreaInputTimer = null;
        }
        this.handleInsertedOrUploadedConfigString(inputedAsJsonString);
        this.$nextTick(() => {
          if (this.isApplyInputedConfigButtonEnabled) {
            this.$emit('cpesConfigEmit', this.configForSetOnCPEs);
          }
        });
      });
      // console.log('apply settings');
    },
    handleInsertedOrUploadedConfigString(text) {
      this.areErrorsInJSON = false;
      try {
        const result = JSON.parse(text);
        const formatted = JSON.stringify(result, null, 2);
        this.configForSetOnCPEsInTextArea = formatted;
        this.configForSetOnCPEs = result;
        if (!this.checkCpeConfigValidity(result)) {
          this.whenErrorActions();
        }
      } catch (e) {
        console.log(e);
        // this.areErrorsInJSON =  true
        // // this.configForSetOnCPEsInTextArea = '';
        // this.configForSetOnCPEs = [];
        this.whenErrorActions();
      }
    },
    handleTextAreaInput(e) {
      this.areErrorsInJSON = false;
      if (this.handleTextAreaInputTimer !== null) {
        clearTimeout(this.handleTextAreaInputTimer);
        this.handleTextAreaInputTimer = null;
      }

      this.handleTextAreaInputTimer = setTimeout(() => {
        try {
          const inputed = this.configForSetOnCPEsInTextArea;
          const inputedAsJsonString = JSON.stringify(JSON.parse(inputed));
          this.$nextTick(() => {
            this.handleInsertedOrUploadedConfigString(inputedAsJsonString);
          });
        } catch (e) {
          console.log(e);
          // this.areErrorsInJSON =  true
          // // this.configForSetOnCPEsInTextArea = '';
          // this.configForSetOnCPEs = [];
          this.whenErrorActions();
        }
      }, 2000);
    },
    showSpinner() {
      this.isSpinnerShowing = true;
    },
    hideSpinner() {
      this.isSpinnerShowing = false;
    },
    copyCpeConfigFromClipboardButtonHandler() {
      // console.log('paste form clipboard');
      this.pasteTextToClipboard();
    },
    fallbackPasteTextFromClipboard() {
      try {
        const successful = document.execCommand('paste');
        const msg = successful ? 'successful' : 'unsuccessful';
        const inputedAsJsonString = JSON.stringify(JSON.parse(successful));
        this.handleInsertedOrUploadedConfigString(inputedAsJsonString);
        // console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        this.copyToClipboardErrorShowMsg();
        // console.error('Fallback: Oops, unable to copy', err);
      }
    },
    pasteTextToClipboard() {
      if (!navigator.clipboard) {
        this.fallbackPasteTextFromClipboard();
        return;
      }
      navigator.clipboard.readText().then(
        (text) => {
          // console.log(text)
          const inputedAsJsonString = JSON.stringify(JSON.parse(text));
          this.handleInsertedOrUploadedConfigString(inputedAsJsonString);
          // console.log('Async: Copying to clipboard was successful!');
        },
        (err) => {
          // this.copyToClipboardErrorShowMsg();
          // console.error('Async: Could not copy text: ', err);
        }
      );
    },

    setFromUploadedFileButtonHandler() {
      // console.log('from uploaded file');
      const { files } = document.getElementById('selectFiles');
      // console.log(files);
      if (files.length <= 0) {
        return false;
      }

      const fr = new FileReader();
      this.showSpinner();

      fr.onload = (e) => {
        // console.log(e);
        // let result = JSON.parse(e.target.result);
        // let formatted = JSON.stringify(result, null, 2);
        // this.configForSetOnCPEsInTextArea = formatted;
        // this.configForSetOnCPEs = result;
        this.handleInsertedOrUploadedConfigString(e.target.result);
        this.hideSpinner();
      };

      fr.readAsText(files.item(0));
    },
    fileSelectHandler() {
      this.setFromUploadedFileButtonHandler();
    },

    copyCpeConfigToClipboard() {
      const configAsText = this.cpeConfigForShow;
      this.copyTextToClipboard(configAsText);
    }
  }
};
</script>

<style scoped>
.cpe-config-set-cpe-config-aria {
  height: 300px !important;
}
.error-in-uploaded-data-in-cpe-config-set {
  font-size: 1.1em;
  color: red;
}
</style>
