<template>
  <div class="app flex-row align-items-center">
    <!--    <div class="loader  loader-backdrop-loginpage" v-if="isSpinnerShow"></div>-->
    <!--    <div  v-if="isSpinnerShow"><Loader-spinner centered></Loader-spinner></div>-->
    <!--    <div class = 'locale-switcher-on-login-page'>-->
    <!--      <localeSwitcher @localeSwitcherLocaleSwitched = 'localeSwitched'></localeSwitcher>-->
    <!--    </div>-->
    <!--    <div class = "copyright-info-on-login-page">-->
    <!--      <div class="sidebar-time__copyright">-->
    <!--        <small  v-if="window.BUILD">Version: {{ window.BUILD }}</small>-->
    <!--        <small  v-if="window.BUILD_DATE">Build date: {{ window.BUILD_DATE }}</small>-->
    <!--        <small>-->
    <!--          <a href="http://wimark.com/" target="_blank">Wimark Systems</a>-->
    <!--          &copy; {{ new Date().getFullYear() }}-->
    <!--        </small>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class = "support-contacts-on-login-page">-->
    <!--        <small>{{ $t('login.support') }}-->
    <!--        <a href="mailto:info@wimark.com">info@wimark.com</a>-->
    <!--        </small>-->

    <!--    </div>-->
    <div class="footer-on-login-page">
      <div class="copyright-info-on-login-page" :class="{ 'hide-copyright-info-on-login-page': isBeeline }">
<!--        <div class="sidebar-time__copyright">-->
<!--          <small v-if="window.BUILD">Version: {{ window.BUILD }}</small>-->
<!--          <small v-if="window.BUILD_DATE">Build date: {{ window.BUILD_DATE }}</small>-->
<!--          <small>-->
<!--            <a href="http://wimark.com/" target="_blank">Wimark Systems</a>-->
<!--            &copy; {{ new Date().getFullYear() }}-->
<!--          </small>-->
<!--        </div>-->
      </div>
      <div class="support-contacts-on-login-page">
<!--        <small>-->
<!--          {{ $t('login.support') }}-->
<!--          <a :href="`mailto:${getContactEmail()}`">{{ getContactEmail() }}</a>-->
<!--        </small>-->
      </div>
      <div class="locale-switcher-on-login-page">
        <localeSwitcher @localeSwitcherLocaleSwitched="localeSwitched"></localeSwitcher>
      </div>
    </div>
    <div class="container">
      <div v-if="this.passwordSetSuccessfully === false" class="row justify-content-center">
        <div class="col-md-6">
          <div class="card-group mb-0">
            <div class="card p-2">
              <div class="card-block">
                <h1>{{ $t('login.resetPasswordNewPasswordHeader') }}</h1>
                <p class="text-muted">{{ $t('login.resetPasswordNewPasswordCaption') }}</p>
                <div class="mb-1">
                  <div class="input-group newPassword">
                    <span class="input-group-addon"><i class="icon-lock"></i></span>
                    <input
                      :data-vv-as="$t('login.newPasswordField')"
                      ref="password"
                      type="password"
                      name="password"
                      v-model.trim="newPassword"
                      class="form-control"
                      :placeholder="$t('login.resetPasswordNewPassword')"
                      :disabled="loginLoading"
                      :class="{ input: true, 'is-danger': errors.has('password') }"
                      autocomplete="on"
                      v-validate="mixRulesForUserPasswordCheck('required')"
                      data-vv-validate-on="none"
                    />
                    <span class="input-group-addon hide-show-password" @click="showPassword">
                      <i class="fa fa-eye"></i>
                    </span>
                  </div>
                  <span v-show="errors.has('password')" class="help is-danger animated fadeIn">
                    {{ errors.first('password') }}
                  </span>
                </div>
                <div class="mb-2">
                  <div class="input-group newPasswordConfirm">
                    <span class="input-group-addon"><i class="icon-lock"></i></span>
                    <input
                      @input="clearErrorsOnNewPasswordConfirmInput"
                      :data-vv-as="$t('login.newPasswordConfirmationField')"
                      type="password"
                      name="passwordConfirm"
                      v-model.trim="newPasswordConfirm"
                      class="form-control"
                      :placeholder="$t('login.resetPasswordNewPasswordConfirm')"
                      :disabled="loginLoading"
                      data-vv-validate-on="none"
                      :class="{ input: true, 'is-danger': errors.has('passwordConfirm'), 'is-ok': passwordsAreMatch }"
                      v-validate="'confirmed:password|required'"
                    />
                    <span class="input-group-addon hide-show-password" @click="showPassword">
                      <i class="fa fa-eye"></i>
                    </span>
                  </div>
                  <span v-show="errors.has('passwordConfirm')" class="help is-danger animated fadeIn">
                    {{ $t('login.resetPasswordNewPasswordConfirmationError') }}
                  </span>
                </div>
                <div class="row">
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-primary px-2"
                      @click="submitNewPassword()"
                      :disabled="loginLoading"
                    >
                      <span :class="{ invisible: loading }">
                        {{ $t('login.resetPasswordNewPasswordSubmitButton') }}
                      </span>
                      <span v-if="loading" class="loader loader--mini"></span>
                    </button>
                  </div>
                  <div v-if="passwordSetErrors === true" class="col-6">
                    <button type="button" class="btn btn-primary px-2 float-right" @click="goToLoginPage">
                      <span :class="{ invisible: loading }">{{ $t('login.goToLoginButton') }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row justify-content-center">
        <div class="col-md-6">
          <div class="card-group mb-0">
            <div class="card p-2">
              <div class="card-block text-center">
                <h1 class="mb-1">{{ $t('login.passwordSuccessfullySet') }}</h1>
                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6 text-center">
                    <div>
                      <br />
                    </div>
                    <button type="button" class="btn btn-primary px-2" @click="goToLoginPage">
                      <span :class="{ invisible: loading }">{{ $t('login.goToLoginButton') }}</span>
                    </button>
                  </div>
                  <div class="col-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
// import axios from 'axios';
import VueNotifications from 'vue-notifications';
import VeeValidate from 'vee-validate';
import auth from '../../auth/auth';
// import Router from '../../router';
import localeSwitcher from '../../components/locale-switcher.vue';
import helpers from '../../helpers';

// VeeValidate.Validator.extend('user_password', {
//   getMessage: (field) => {
//     if (localStorage.getItem('NMS_LOCALE') === 'ru' || localStorage.getItem('NMS_LOCALE') === 'RU') {
//       return 'Пароль может содержать только следующие специальные символы: _ - # $ & ^ * @ !';
//     }
//     return 'The password field may contain only the following special character: _ - # $ & ^ * @ !';
//   },
//   validate: (value) => {
//     const strongRegex = new RegExp('^(([a-zA-Z0-9_\\-#$&^*@!])*)$');
//     return strongRegex.test(value);
//   }
// });

export default {
  name: 'ResetPassword',
  components: {
    localeSwitcher
  },
  data() {
    return {
      passwordSetSuccessfully: false,
      passwordSetErrors: false,
      token: '',
      username: '',
      newPassword: '',
      newPasswordConfirm: '',
      isSpinnerShow: false,
      error: '',
      loginLoading: false
    };
  },
  methods: {
    mixRulesForUserPasswordCheck(rules) {
      return helpers.mixRulesForUserPasswordCheck(rules);
    },
    clearErrorsOnNewPasswordConfirmInput() {
      // console.log(this.errors)
      // очищаем все ошибки, для поля passwordConfirm, когда пароли начанают совпадать в результате
      // ввода пользователя
      if (this.errors.has('passwordConfirm')) {
        if (this.passwordsAreMatch) {
          this.errors.remove('passwordConfirm');
        }
      }
    },
    getContactEmail() {
      return helpers.getContactEmail();
    },
    showPassword() {
      const newPassword = document.querySelector('.newPassword input');
      const newPasswordpasswordIcon = document.querySelector('.newPassword .hide-show-password i');
      const newPasswordConfirm = document.querySelector('.newPasswordConfirm input');
      const newPasswordConfirmIcon = document.querySelector('.newPasswordConfirm .hide-show-password i');

      if (newPassword) {
        if (newPassword.type === 'password') {
          newPassword.type = 'text';
          newPasswordpasswordIcon.className = 'fa fa-eye-slash';
          newPasswordConfirm.type = 'text';
          newPasswordConfirmIcon.className = 'fa fa-eye-slash';
        } else {
          newPassword.type = 'password';
          newPasswordpasswordIcon.className = 'fa fa-eye';
          newPasswordConfirm.type = 'password';
          newPasswordConfirmIcon.className = 'fa fa-eye';
        }
      }
    },
    goToLoginPage() {
      this.$router.push({ name: 'Login' });
    },
    hideResetPassword() {
      this.isResetPasswordShow = false;
    },
    showResetPassword() {
      this.isResetPasswordShow = true;
    },
    localeSwitched() {
      this.isSpinnerShow = true;
    },
    submitNewPassword() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.loginLoading = true;
            auth.newPasswordSet(this, this.token, this.username, this.newPassword).then(
              (response) => {
                if (response.data.status === 'error') {
                  this.loginLoading = false;
                  this.passwordSetErrors = true;
                  if (response.data.description && response.data.description === 'token expired') {
                    VueNotifications.error({
                      message: `${this.$t('login.resetPasswordNewPasswordTokenExpiredMessage')}`
                    });
                  } else {
                    VueNotifications.error({ message: response.data.description });
                  }
                } else {
                  this.loginLoading = false;
                  this.newPassword = '';
                  this.newPasswordConfirm = '';
                  this.passwordSetSuccessfully = '';
                  VueNotifications.success({ message: `${this.$t('login.resetPasswordNewPasswordSuccessMessage')}` });
                }
              },
              (err) => {
                VueNotifications.error({ message: err });
                this.passwordSetErrors = true;
                this.error = err;
                this.loginLoading = false;
              }
            );
          }
        },
        () => {
          //                    console.warn('Validation Fialed')
        }
      );
    }
  },
  computed: {
    passwordsAreMatch() {
      if (this.newPassword === '' || this.newPasswordConfirm === '') {
        return false;
      }
      if (this.newPassword === this.newPasswordConfirm) {
        return true;
      }
      return false;
    },
    isBeeline() {
      try {
        if (process.env.VUE_APP_CUSTOM_BUILD === 'BEELINE-WIMARK') {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isUserNameForPasswordResetLength() {
      if (this.userNameForPasswordReset.length > 0) {
        return true;
      }
      return false;
    },
    window() {
      return window;
    },
    loading() {
      return this.loginLoading;
    }
  },
  created() {
    this.isSpinnerShow = false;
    // Проверяем пришел ли пользователь по ссылке сброса пароля, или просто так. Если просто так,
    // то перекидываем на страницу логина, нечего ему тут делать.
    // А если по ссылке сброса, то позволяем ввести новый пароль
    if (!this.$route.query.token || !this.$route.query.username) {
      this.$router.push({ name: 'Login' });
    } else {
      this.token = this.$route.query.token;
      this.username = this.$route.query.username;
    }
  },
  mounted() {
    // убираем прелоадер, которй прописан в /static/index.html
    document.body.classList.add('loaded-preloader');
    document.body.classList.add('loaded_hiding-preloader');
  }
};
</script>

<style lang="scss" scoped>
.input.is-ok {
  border-color: #24b122 !important;
}
.hide-copyright-info-on-login-page {
  visibility: hidden;
}
.locale-switcher-on-login-page {
  position: relative;
  bottom: 1px;
  right: 1px;
}
.copyright-info-on-login-page {
}
.loader-backdrop-loginpage {
  position: absolute;
  top: 0px;
}
.support-contacts-on-login-page {
}
.support-contacts-on-login-page a {
  display: inline;
}
.footer-on-login-page {
  position: fixed;
  left: 5px;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
</style>
