<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class=" h-100">
        <div class="card-block">
<!--          <div class="h4 m-0">-->
<!--            {{ $t('general.step2') }}-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"-->
<!--              @click="lastStepButtonClickHandler"-->
<!--            >-->
<!--              {{ $t('wlans.wizardSkipButton') }}-->
<!--              <info :content="$t('wlans.wizardSkipButtonInfo')" />-->
<!--            </button>-->
<!--          </div>-->
          <div class="custom-tabs-wrapper-in-wlans-wizard-step2">
                                    <ul class="nav nav-tabs row m-0" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: securityTab === 'L2', 'tab-has-error': securityL2TabHasErrors }"
                @click="setSecurityTabTabTo('L2')"
              >
                {{$t('wlans.L2TabCaption')}}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: securityTab === 'L3', 'tab-has-error': securityL3TabHasErrors }"
                @click="setSecurityTabTabTo('L3')"
              >
                {{$t('wlans.L3TabCaption')}}                       <small :class="{'not-for-cisco': isCISCO }">

                      </small>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: securityTab === 'AAA', 'tab-has-error': securityAAATabHasErrors }"
                @click="setSecurityTabTabTo('AAA')"
              >
               {{$t('wlans.AAATabCaption')}}
              </a>
            </li>

          </ul>
          </div>





<!--          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('wlans.wizardSecuritySettings') }}</span>-->
          <form action="">

            <div class="mt-1" v-show="securityTab === 'L2'">

            <transition name="infoMessageForWPA3InCreatingWlanWizard" mode="out-in">
              <div v-if="checkWPA3Type(newWlanData.security.type)"
                   class="form-group mt-1">
                <Alert class="alert-info">
                  <template #text>
                    <span class="mr-h">{{ $t('wlans.wpa3IsNotSupportedOnAllCPE') }}</span>
                  </template>
                </Alert>
              </div>
            </transition>
            <div class="form-group mt-1">
              <label for="wlan-security">{{ $t('wlans.security') }}</label>

              <select
                id="wlan-security"
                class="form-control"
                v-model="newWlanData.security.type"
                @change="setSecurityType(newWlanData.security.type)"
              >
                <option value="" :selected="newWlanData.security.type === ''">{{ $t('wlans.securityOpen') }}</option>
                <option value="wpapersonal" :selected="newWlanData.security.type === 'wpapersonal'">
                  WPA Personal
                </option>
                <option value="wpaenterprise" :selected="newWlanData.security.type === 'wpaenterprise'">
                  WPA Enterprise
                </option>
                <option value="wpa2personal" :selected="newWlanData.security.type === 'wpa2personal'">
                  WPA2 Personal
                </option>
                <option value="wpa2enterprise" :selected="newWlanData.security.type === 'wpa2enterprise'">
                  WPA2 Enterprise
                </option>
                <option value="wpa3personal"
                        :selected="newWlanData.security.type === 'wpa3personal'">
                  WPA3 Personal
                </option>
                <option value="wpa3enterprise"
                        :selected="newWlanData.security.type === 'wpa3enterprise'">
                  WPA3 Enterprise
                </option>
                <option value="wpa23personal"
                        :selected="newWlanData.security.type === 'wpa23personal'">
                  WPA2/3 Personal
                </option>
                <option value="wpa23enterprise"
                        :selected="newWlanData.security.type === 'wpa23enterprise'">
                  WPA2/3 Enterprise
                </option>
              </select>
            </div>

            <div class="form-group" v-if="newWlanData.security.type.indexOf('enterprise') !== -1">
              <label for="radius">{{ $t('radius.radius') }} </label> <small><a href="#" class="ml-q mb-1"  @click.stop.prevent="addNewRadiusClickedHandler">{{$t('wlans.addRADIUS')}}</a></small>
              <multiselect
                class="rule-multi"
                id="radius"
                v-model="updatedWlanRadiusAuthentication"
                :multiple="true"
                :options="radiusList || []"
                :max="8"
                track-by="id"
                v-validate="'required'"
                label="name"
                name="RADIUS Authentication"
                :class="{ input: true, 'is-danger': errors.has('RADIUS Authentication') }"
                select-label="add"
                deselect-label="remove"
                :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              ></multiselect>
              <span v-show="errors.has('RADIUS Authentication')" class="help is-danger mt-0 mb-1">
                {{ errors.first('RADIUS Authentication') }}
              </span>
            </div>
<!--            <div class="form-group" v-if="newWlanData.security.type.indexOf('enterprise') !== -1">-->
<!--            <small class="text-muted caption-for-radius-settings">{{ $t('wlans.wizardRADIUSAccounting') }}</small>-->
<!--              <div class="ml-1">-->

<!--              <div class="mt-1">-->
<!--                <div class="form-group mb-1">-->
<!--                  <Switch-component-->
<!--                    v-model="newWlanData.radius_acct_mirroring"-->
<!--                    :label="$t('wlans.mirroring')"-->
<!--                    id="radius-acct-mirroring"-->
<!--                  />-->
<!--                  <info class="ml-q" :content="$t('wlans.mirroringHint')"/>-->
<!--                </div>-->

<!--                <div class="form-group">-->
<!--                  <label for="radius_acc">{{ $t('wlans.radiusAccounting') }}</label>-->
<!--                  <Multiselect-->
<!--                    id="radius_acc"-->
<!--                    class="rule-multi"-->
<!--                    data-vv-name="radius_acc"-->
<!--                    v-model="updatedWlanRadiusAccounting"-->
<!--                    :multiple="true"-->
<!--                    :options="radiusList || []"-->
<!--                    :max="8"-->
<!--                    trackBy="id"-->
<!--                    label="name"-->
<!--                    @input="enableSave()"-->
<!--                    :class="{-->
<!--                      input: true,-->
<!--                      'is-danger': errors.has('radius_acc')-->
<!--                    }"-->
<!--                    :placeholder="$t('wlans.selectRADIUSPlaceholder')"-->
<!--                    :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--                    :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--                    :selectedLabel="$t('general.multiSelectSelected')"-->
<!--                  ></Multiselect>-->
<!--                </div>-->
<!--                <div class="form-group mt-1" v-if="isBeeline">-->
<!--                  <label for="vlan">-->
<!--                    {{ $t('wlans.rsnaSettingsV2') }}-->
<!--                  </label>-->
<!--                  <input-->
<!--                    :data-vv-as="'Calling Station ID'"-->
<!--                    type="text"-->
<!--                    id="RSNA"-->
<!--                    name="RSNA"-->
<!--                    :class="{ input: true, 'is-danger': errors.has('RSNA') }"-->
<!--                    v-validate="'max:16'"-->
<!--                    class="form-control"-->
<!--                    v-model="newWlanData.beeline_accountng_type"-->
<!--                  />-->
<!--                  <small class="text-muted">-->
<!--                    {{ $t('wlans.rsnaCapture') }}-->
<!--                  </small>-->
<!--                </div>-->
<!--                <span v-show="errors.has('RSNA')" class="help is-danger">-->
<!--                  {{ errors.first('RSNA') }}-->
<!--                </span>-->
<!--              </div>-->
<!--              </div>-->


<!--            </div>-->
<!--             <div class="form-group" v-if="newWlanData.security.type.indexOf('enterprise') !== -1">-->
<!--             <small class="text-muted caption-for-radius-settings">{{ $t('wlans.wizardRADIUSAdditionalSettings') }}</small>-->
<!--              <div class="mt-1 ml-1">-->
<!--                <div class="form-group">-->
<!--                  <label for="nas_id">{{ $t('wlans.NASidentifier') }}</label>-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    id="nas_id"-->
<!--                    name="nas_id"-->
<!--                    :placeholder="$t('wlans.enterNASIdentifierPlaceholder')"-->
<!--                    class="form-control"-->
<!--                    v-model="newWlanData.nas_id"-->
<!--                  />-->
<!--                </div>-->

<!--                <div class="form-group">-->
<!--                  <label for="nas_port_id">{{ $t('wlans.NASportid') }}</label>-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    id="nas_port_id"-->
<!--                    name="nas_port_id"-->
<!--                    :placeholder="$t('wlans.enterNASPortIdPlaceholder')"-->
<!--                    class="form-control"-->
<!--                    v-model="newWlanData.nas_port_id"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->

<!--             </div>-->

            <div
              class="form-group"
              v-if="newWlanData.security && newWlanData.security.type.indexOf('enterprise') !== -1"
            >
              <label for="hotspot20_profile">{{ $t('wlans.hotspotProfileTitle') }}</label>
              <select
                v-model="newWlanData.security.data.hotspot20_profile"
                name="hotspot20_profile"
                id="hotspot20_profile"
                class="form-control"
              >
                <option :value="''">
                  {{ $t('wlans.hotspotProfileNone') }}
                </option>
                <option v-for="hotspot in hotspotsList" :value="hotspot.id" :key="hotspot.id">
                  {{ hotspot.name }}
                </option>
              </select>
            </div>

            <div role="group" class="form-group row" v-if="newWlanData.security.type.indexOf('personal') !== -1">
              <div class="col-12">
                <label for="wlan_password">{{ $t('general.password') }}</label>
                <div role="group" class="input-group">
                  <input type="text" style="display: none;" />
                  <input type="password" style="display: none;" />
                  <input
                    :type="showWlanPass ? 'text' : 'password'"
                    id="wlan_password"
                    autocomplete="off"
                    name="wlan_password"
                    :placeholder="$t('wlans.enterWlanPasswordPlaceholder')"
                    class="form-control"
                    v-validate="'required|min:8|max:63'"
                    :class="{ input: true, 'is-danger': errors.has('wlan_password') }"
                    v-model="newWlanData.security.data.psk"
                  />
                  <div class="input-group-addon hovered" @click="showWlanPass = !showWlanPass">
                    <i class="fa fa-eye" v-if="showWlanPass"></i>
                    <i class="fa fa-eye-slash" v-if="!showWlanPass"></i>
                  </div>
                  <Password-generator
                    @passwordGenerated="passwordGeneratedForCreatedWlan"
                    class="ml-1"
                  ></Password-generator>
                </div>
                <span v-show="errors.has('wlan_password')" class="help is-danger">
                  {{ errors.first('wlan_password') }}
                </span>
              </div>
            </div>

            <div class="form-group" v-if="newWlanData.security.type !== ''">
              <label for="suites">{{ $t('wlans.suites') }}</label>
              <multiselect
                id="suites"
                v-model="newWlanData.security.data.suites"
                :multiple="true"
                :options="(newWlanData.security.type.indexOf('2') !== -1 || newWlanData.security.type.indexOf('3') !== -1) ? ['aes', 'tkip'] : ['tkip']"
                :placeholder="$t('wlans.suitesPlaceholder')"
                :class="{ input: true, 'is-danger': isSuitesValidation }"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              ></multiselect>
              <span v-show="isSuitesValidation" class="text-danger">The suites field is required</span>
            </div>

            <!--            <button-->
            <!--              type="button"-->
            <!--              :disabled="isSuitesValidation"-->
            <!--              class="btn btn-outline-success float-right"-->
            <!--              @click="setNewWlanData"-->
            <!--            >-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
            </div>

              <div class=" mt-1 ml-1" v-show="securityTab === 'L3'">
                <div v-if="window.CISCO"><small class="text-danger p-0" v-if="window.CISCO">
                  * {{ $t('wlans.notAvailableForCisco') }}
                </small></div>

                          <form action="">
            <div class="">
              <div class="row mt-2">
                <div class="form-group col-sm-6">
                  <Switch-component
                    v-model="newWlanData.l2isolate"
                    :disabled="isDisable"
                    :label="$t('wlans.L2isolation')"
                    id="l2isolate"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="captive_redirect">{{ $t('wlans.redirect') }}</label><small><a href="#" class="ml-q mb-1"  @click.stop.prevent="addNewRedirectClickedHandler">{{$t('wlans.addRedirectRule')}}</a></small>
                <select
                  v-model="newWlanData.guest_control.captive_redirect"
                  name="captive_redirect"
                  id="captive_redirect"
                  :class="{ 'select-disabled': isDisable }"
                  :disabled="isDisable"
                  class="form-control"
                >
                  <option :value="''">
                    {{ $t('wlans.none') }}
                  </option>
                  <option v-for="redirect in redirectsList" :value="redirect.id" :key="redirect.id">
                    {{ redirectName(redirect.id) }}
                  </option>
                </select>
              </div>

              <div class="form-group" :class="{ 'multiselect-disabled-field': isDisable }">
                <label for="radius_mac">{{ $t('wlans.MACRADIUSAuthentication') }}</label><small><a href="#" class="ml-q mb-1"  @click.stop.prevent="addNewRadiusClickedHandler">{{$t('wlans.addRADIUS')}}</a></small>
                <Multiselect
                  id="radius_mac"
                  class="rule-multi"
                  data-vv-name="radius_mac"
                  v-model="updatedWlanRadiusMAC"
                  :multiple="true"
                  :options="radiusList || []"
                  :max="8"
                  trackBy="id"
                  label="name"
                  :disabled="isDisable || !newWlanData.guest_control.captive_redirect"
                  @input="enableSave()"
                  :class="{
                    input: true,
                    'is-danger': errors.has('radius_mac'),
                    'multiselect-disabled': isDisable || !newWlanData.guest_control.captive_redirect
                  }"
                  :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                  :selectLabel="$t('general.multiSelectPressToSelect')"
                  :deselectLabel="$t('general.multiSelectDeselectLabel')"
                  :selectedLabel="$t('general.multiSelectSelected')"
                ></Multiselect>
              </div>

              <div class="form-group">
                <label for="l2_chain">{{ $t('wlans.firewall') }}</label>
                <select
                  v-model="newWlanData.firewall.l2_chain"
                  name="l2_chain"
                  id="l2_chain"
                  :class="{ 'select-disabled': isDisable }"
                  :disabled="isDisable"
                  class="form-control"
                >
                  <option :value="''">
                    {{ $t('wlans.none') }}
                  </option>
                  <option v-for="firewall in firewallsList" :value="firewall.id" :key="firewall.id">
                    {{ firewallName(firewall.id) }}
                  </option>
                </select>
              </div>

              <label for="security">{{ $t('wlans.filterMode') }}</label>
              <div class="form-group mb-0">
                <select
                  id="filtermode"
                  class="form-control"
                  v-model="newWlanData.filtermode"
                  :disabled="isDisable"
                  :class="{ 'select-disabled': isDisable }"
                >
                  <option value="None" :selected="newWlanData.filtermode === 'None'">
                    {{ $t('wlans.none') }}
                  </option>
                  <option value="WhiteList" :selected="newWlanData.filtermode === 'WhiteList'">
                    {{ $t('wlans.whiteList') }}
                  </option>
                  <option value="BlackList" :selected="newWlanData.filtermode === 'BlackList'">
                    {{ $t('wlans.blackList') }}
                  </option>
                </select>
              </div>
              <div class="wlan-filtermode">
                <p v-if="newWlanData.filtermode === 'None'">
                  <small class="text-primary">{{ $t('wlans.none') }}:</small>
                  <small class="text-muted">{{ $t('wlans.filterNoneModeCaption') }}</small>
                </p>
                <p v-if="newWlanData.filtermode === 'WhiteList'">
                  <small class="text-primary">{{ $t('wlans.filterWhitelistMode') }}:</small>
                  <small class="text-muted">{{ $t('wlans.filterWhitelistModeCaption') }}</small>
                </p>
                <p v-if="newWlanData.filtermode === 'BlackList'">
                  <small class="text-primary">{{ $t('wlans.filterBlacklistMode') }}:</small>
                  <small class="text-muted">
                    {{ $t('wlans.filterBlacklistModeCaption') }}
                  </small>
                </p>
              </div>

              <div class="form-group mb-0" v-if="newWlanData.filtermode != 'None'">
                <div class="input-group">
                  <input
                    type="text"
                    @keyup.13="addMacAddress"
                    @input="enableSaveChanges = true"
                    name="mac"
                    class="form-control"
                    placeholder="00:00:00:00:00:00"
                    :disabled="isDisable"
                    v-validate="'mac'"
                    data-vv-validate-on="input"
                    v-model="newMacAddress"
                    :class="{ input: true, 'is-danger': errors.has('mac') }"
                  />
                  <span class="input-group-btn">
                    <button
                      class="btn btn-success mb-0"
                      type="button"
                      @click="addMacAddress"
                      :disabled="(this.newMacAddress == '')"
                    >
                      {{ $t('general.add') }}
                    </button>
                  </span>
                </div>
              </div>

              <div class="ml-h mb-q" v-if="newWlanData.filtermode !== 'None'">
                <small v-if="newWlanData.filtermode === 'WhiteList'">
                  <strong>{{ $t('wlans.currentWhitelistItems') }}: {{ filterList.whitelist.length }}</strong>
                </small>
                <small v-if="newWlanData.filtermode === 'BlackList'">
                  <strong>{{ $t('wlans.currentBlacklistItems') }}: {{ filterList.blacklist.length }}</strong>
                </small>
              </div>
              <ul class="items_list p-0 ml-h">
                <li v-for="(mac, index) in filterList.whitelist" v-if="newWlanData.filtermode === 'WhiteList'">
                  <small
                    data-list="whitelist"
                    :data-index="index"
                    :data-mac="mac"
                    :class="{ 'item-for-delete': !isDisable }"
                    @click="removeMac"
                    class="text-uppercase"
                  >
                    {{ mac }}
                    <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                  </small>
                </li>
                <li v-for="(mac, index) in filterList.blacklist" v-if="newWlanData.filtermode === 'BlackList'">
                  <small
                    data-list="blacklist"
                    :data-index="index"
                    :data-mac="mac"
                    :class="{ 'item-for-delete': !isDisable }"
                    @click="removeMac"
                    class="text-uppercase"
                  >
                    {{ mac }}
                    <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                  </small>
                </li>
              </ul>
            </div>

            <!--            <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>








              </div>

              <div class="mt-1 ml-1" v-show="securityTab === 'AAA'">
                            <div class="form-group">
<!--            <small class="text-muted caption-for-radius-settings">{{ $t('wlans.wizardRADIUSAccounting') }}</small>-->
              <div class="">

              <div class="mt-1">
                <div class="form-group mb-1">
                  <Switch-component
                    v-model="newWlanData.radius_acct_mirroring"
                    :label="$t('wlans.mirroring')"
                    id="radius-acct-mirroring"
                  />
                  <info class="ml-q" :content="$t('wlans.mirroringHint')"/>
                </div>

                <div class="form-group">
<!--                  <label for="radius_acc">{{ $t('wlans.radiusAccounting') }}</label>-->
                                    <label for="radius_acc">{{ $t('wlans.radiusAccountingForWLANModalAAA') }}</label> <small><a href="#" class="ml-q mb-1"  @click.stop.prevent="addNewRadiusClickedHandler">{{$t('wlans.addRADIUS')}}</a></small>
                  <Multiselect
                    id="radius_acc"
                    class="rule-multi"
                    data-vv-name="radius_acc"
                    v-model="updatedWlanRadiusAccounting"
                    :multiple="true"
                    :options="radiusList || []"
                    :max="8"
                    trackBy="id"
                    label="name"
                    @input="enableSave()"
                    :class="{
                      input: true,
                      'is-danger': errors.has('radius_acc')
                    }"
                    :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                    :deselectLabel="$t('general.multiSelectDeselectLabel')"
                    :selectedLabel="$t('general.multiSelectSelected')"
                  ></Multiselect>
                </div>
                <div class="form-group mt-1" v-if="isBeeline || isExperementalBuild">
                  <label for="vlan">
                    {{ $t('wlans.rsnaSettingsV2') }}
                  </label>
                  <input
                    :data-vv-as="'Calling Station ID'"
                    type="text"
                    id="RSNA"
                    name="RSNA"
                    :class="{ input: true, 'is-danger': errors.has('RSNA') }"
                    v-validate="'max:16'"
                    class="form-control"
                    v-model="newWlanData.beeline_accountng_type"
                  />
                  <small class="text-muted">
                    {{ $t('wlans.rsnaCapture') }}
                  </small>
                </div>
                <span v-show="errors.has('RSNA')" class="help is-danger">
                  {{ errors.first('RSNA') }}
                </span>
              </div>
              </div>


            </div>
             <div class="form-group">
             <small class="text-muted caption-for-radius-settings">{{ $t('wlans.wizardRADIUSAdditionalSettings') }}</small>
              <div class="mt-1 ">
                <div class="form-group">
                  <label for="nas_id">{{ $t('wlans.NASidentifier') }}</label>
                  <input
                    type="text"
                    id="nas_id"
                    name="nas_id"
                    :placeholder="$t('wlans.enterNASIdentifierPlaceholder')"
                    class="form-control"
                    v-model="newWlanData.nas_id"
                  />
                </div>

                <div class="form-group">
                  <label for="nas_port_id">{{ $t('wlans.NASportid') }}</label>
                  <input
                    type="text"
                    id="nas_port_id"
                    name="nas_port_id"
                    :placeholder="$t('wlans.enterNASPortIdPlaceholder')"
                    class="form-control"
                    v-model="newWlanData.nas_port_id"
                  />
                </div>
              </div>

             </div>







              </div>

          </form>

        </div>
<!--        <div class="buttons-block-warpper">-->
<!--          <button-->
<!--            type="button"-->
<!--            :disabled="isSuitesValidation"-->
<!--            class="btn btn-outline-success float-right"-->
<!--            @click="setNewWlanData"-->
<!--          >-->
<!--            {{ $t('general.next') }}-->
<!--          </button>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-outline-info float-right mr-h"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @click="lastStepButtonClickHandler">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t('wlans.wizardSkipButton') }} <info :content="$t('wlans.wizardSkipButtonInfo')"/>&ndash;&gt;-->
<!--          &lt;!&ndash;          </button>&ndash;&gt;-->
<!--          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
<!--            {{ $t('general.back') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import radiusService from '../../../services/radiusService';
import helpers from '../../../helpers';
import PasswordGenerator from '../../../components/PasswordGenerator.vue';
import hotspotService from '../../../services/hotspotService';
import Info from '../../../components/Universal/info-icon.vue';
import Alert from '@/components/Universal/alert/alert.vue';
import SwitchComponent from '../../../components/Universal/Switch-component.vue';
import redirectsService from "../../../services/redirectsService";
import commonService from "../../../services/commonService";


// const WPA3_TYPES = ['wpa3personal', 'wpa3enterprise', 'wpa23personal', 'wpa23enterprise']

export default {
  name: 'Step2',
  components: { Multiselect, PasswordGenerator, Info, Alert, SwitchComponent },
  data() {
    return {
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      },
      radiusList: [],
      showWlanPass: false,
      updatedWlanRadiusAuthentication: [],
      updatedWlanRadiusAccounting: [],
      securityTab: 'L2',
      newMacAddress: '',
      filterList: {
        blacklist: [],
        whitelist: []
      },
      isDisable: false,
      enableSaveChanges: true,
      updatedWlanRadiusMAC: [],

    };
  },
  watch: {
    redirectOperations(val, oldVal) {
      try {
        // console.log('val', val)
        // console.log('oldVal', oldVal)
        if (oldVal.length > val.length) {
          console.log('refresh redirect list')
          // если завершилось создание RedirectsRule - перезапрашиваем их список
          // console.log('refresh list')
          redirectsService.getRedirectsRule(this);
        }
      } catch (e) {
        console.log(e);
      }
    },
    radiusOperations(val, oldVal) {
      try {
        // console.log('val', val)
        // console.log('oldVal', oldVal)
        if (oldVal.length > val.length) {
          // если завершилось создание RADIUS - перезапрашиваем их список
          // console.log('refresh list')
          radiusService.getRadius(this);
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    firewallsList() {
      return this.$store.state.firewallsList;
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    redirectOperations() {
      return this.$store.state.redirectOperations;
    },
    isExperementalBuild(){
      return this.$store.getters.isExperementalBuild
    },
    window() {
      return window;
    },
    isCISCO(){
      return window.CISCO;
    },
    securityL2TabHasErrors() {
      return this.errors.has('wlan_password') || this.errors.has('RADIUS Authentication') ||
        this.errors.has('suites');
    },
    securityL3TabHasErrors() {
      return this.errors.has('mac');
    },
    securityAAATabHasErrors() {
      return this.errors.has('RSNA');
    },
    isBeeline() {
      return this.$store.getters.isBeeline;
    },
    radiusOperations() {
      return this.$store.state.radiusOperations;
    },
    wpa3Types() {
      // return WPA3_TYPES;
      return this.$store.getters.getWPA3Types;
    },
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    hotspotsList() {
      return this.$store.state.hotspotsList;
    },
    isSuitesValidation() {
      return this.newWlanData.security.data.suites.length == 0;
    }
  },
  methods: {
    saveCurrentDataToStore() {
      let fieldsToValidate = {};
      if (this.newWlanData.security.type.indexOf('personal') !== -1) {
        fieldsToValidate = {
          password: this.newWlanData.security.data.psk
        };
      }
      if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
        const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
        this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
      }
      const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAccounting);
      this.newWlanData.radius_acct_servers = radiusAcctServersIds;

      if (this.newWlanData.filtermode === 'WhiteList' && this.newMacAddress && !this.errors.has('mac')) {
        if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
          this.filterList.whitelist.push(this.newMacAddress);
          this.newMacAddress = '';
          return;
        }
        return;
      }
      if (this.newWlanData.filtermode === 'BlackList' && this.newMacAddress && !this.errors.has('mac')) {
        if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
          this.filterList.blacklist.push(this.newMacAddress);
          this.newMacAddress = '';
          return;
        }
        return;
      }
      const radiusMACIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusMAC);
      this.newWlanData.guest_control.mac_radius_auth_servers = radiusMACIds;
      this.newWlanData.whitelist = this.filterList.whitelist;
      this.newWlanData.blacklist = this.filterList.blacklist;



      this.$store.commit('setNewWlanData', this.newWlanData);

    },
    addNewRedirectClickedHandler() {
      this.saveCurrentDataToStore();
      this.$emit('addNewRedirectClicked');
    },
    addNewRadiusClickedHandler() {
      this.saveCurrentDataToStore();
      this.$emit('addNewRadiusClicked');
    },
    removeMac(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.filterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.filterList.blacklist.splice(index, 1);
      }
    },
    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.newWlanData.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.filterList.whitelist.push(this.newMacAddress);
              this.newMacAddress = '';
              return;
            }
            return;
          }
          if (this.newWlanData.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.filterList.blacklist.push(this.newMacAddress);
              this.newMacAddress = '';
              return;
            }
            return;
          }
          this.enableSaveChanges = true;
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    firewallName(id) {
      const showFirewallFromId = commonService.firewallNamebyId(id);
      return showFirewallFromId;
    },
    redirectName(id) {
      const redirectName = commonService.redirectNamebyId(id);
      return redirectName;
    },
    // addNewRadiusClickedHandler() {
    //   this.saveCurrentDataToStore();
    //   this.$emit('addNewRadiusClicked');
    // },
    setSecurityTabTabTo(tabName){
      this.securityTab = tabName;
      this.$store.commit('setNewWlanWizardSecurityTab', tabName);
    },
    enableSave(){

    },
    // saveCurrentDataToStoreAndGoToRadiusCreate() {
    //   let fieldsToValidate = {};
    //   if (this.newWlanData.security.type.indexOf('personal') !== -1) {
    //     fieldsToValidate = {
    //       password: this.newWlanData.security.data.psk
    //     };
    //   }
    //   if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
    //     const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
    //     this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
    //   }
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //
    //   this.$emit('addNewRadiusClicked')
    //
    //
    // },
    // saveCurrentDataToStoreAndGoToRadiusCreate() {
    //   let fieldsToValidate = {};
    //   if (this.newWlanData.security.type.indexOf('personal') !== -1) {
    //     fieldsToValidate = {
    //       password: this.newWlanData.security.data.psk
    //     };
    //   }
    //   if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
    //     const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
    //     this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
    //   }
    //
    //   const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAccounting);
    //   this.newWlanData.radius_acct_servers = radiusAcctServersIds;
    //
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //
    //   this.$emit('addNewRadiusClicked')
    //
    //
    // },
    checkWPA3Type(type) {
      if (this.wpa3Types.includes(type)) {
        return true;
      }
      return false
    },
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    passwordGeneratedForCreatedWlan(generatedPassword) {
      // показываем пароль если был скрыт
      this.showWlanPass = true;
      // console.log(generatedPassword);
      this.newWlanData.security.data.psk = generatedPassword;
    },

    // setNewWlanData(event, toLastStepClicked = false) {
    //   let fieldsToValidate = {};
    //   if (this.newWlanData.security.type.indexOf('personal') !== -1) {
    //     fieldsToValidate = {
    //       password: this.newWlanData.security.data.psk
    //     };
    //   }
    //   if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
    //     const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
    //     this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
    //   }
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         // this.$parent.nextStep();
    //         if (toLastStepClicked === false) {
    //           this.$parent.nextStep();
    //         } else {
    //           // this.$parent.nextStep(true);
    //           this.$parent.lastStep();
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // },
    validateAndSetNewWlanData(currentTab, nextTab){
      this.setNewWlanData(currentTab, nextTab)
    },
    // setNewWlanData(currentTab, nextTab) {
    //   let fieldsToValidate = {};
    //   if (this.newWlanData.security.type.indexOf('personal') !== -1) {
    //     fieldsToValidate = {
    //       password: this.newWlanData.security.data.psk
    //     };
    //   }
    //   if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
    //     const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
    //     this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
    //   }
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         // this.$parent.nextStep();
    //         this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
    //       }else {
    //         this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
    //       }
    //     },
    //     () => {
    //       this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
    //       console.warn('Validation failed');
    //     }
    //   );
    // },
    setNewWlanData(currentTab, nextTab) {
      let fieldsToValidate = {};
      if (this.newWlanData.security.type.indexOf('personal') !== -1) {
        fieldsToValidate = {
          password: this.newWlanData.security.data.psk
        };
      }
      if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
        const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
        this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
      }
      const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAccounting);
      this.newWlanData.radius_acct_servers = radiusAcctServersIds;

      if (this.newWlanData.filtermode === 'WhiteList' && this.newMacAddress && !this.errors.has('mac')) {
        if (this.filterList.whitelist.indexOf(this.newMacAddress) == -1) {
          this.filterList.whitelist.push(this.newMacAddress);
          this.newMacAddress = '';
          return;
        }
        return;
      }
      if (this.newWlanData.filtermode === 'BlackList' && this.newMacAddress && !this.errors.has('mac')) {
        if (this.filterList.blacklist.indexOf(this.newMacAddress) == -1) {
          this.filterList.blacklist.push(this.newMacAddress);
          this.newMacAddress = '';
          return;
        }
        return;
      }
      const radiusMACIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusMAC);
      this.newWlanData.guest_control.mac_radius_auth_servers = radiusMACIds;
      this.newWlanData.whitelist = this.filterList.whitelist;
      this.newWlanData.blacklist = this.filterList.blacklist;



      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            // console.log(result)
            this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
          }else {
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
            if (this.securityAAATabHasErrors) {
              this.setSecurityTabTabTo('AAA')
            } else if (this.securityL3TabHasErrors) {
              this.setSecurityTabTabTo('L3')
            } else if (this.securityL2TabHasErrors) {
              this.setSecurityTabTabTo('L2')
            }
          }
        },
        () => {
          this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          console.warn('Validation failed');
        }
      );
    },
  //   setSecurityType(type) {
  //     if (type !== 'open' && type.indexOf('2') === -1) {
  //       const isAesSelected = this.newWlanData.security.data.suites.indexOf('aes');
  //       if (isAesSelected !== -1) {
  //         this.newWlanData.security.data.suites = ['tkip'];
  //       }
  //     }
  //     if (type !== 'open' && type.indexOf('2') != -1) {
  //       this.newWlanData.security.data.suites = ['aes'];
  //     }
  //   }
  // },
    setSecurityType(type) {
      // console.log(type)
      if (type !== 'open' && type.indexOf('2') === -1) {
        const isAesSelected = this.newWlanData.security.data.suites.indexOf('aes');
        if (isAesSelected !== -1) {
          this.newWlanData.security.data.suites = ['tkip'];
        }
      }
      if (type !== 'open' && (type.indexOf('2') != -1 || type.indexOf('3') != -1)) {
        this.newWlanData.security.data.suites = ['aes'];
      }
    }
  },
  // created() {
  //   hotspotService.getHotspots(this);
  //   radiusService.getRadius(this);
  //   this.radiusList = this.$store.state.radiusList;
  //   this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
  //   // цикл ниже чтобы заполннить поле 'RADIUS' прежними значениями если возвращаемся на этот шаг
  //   // из какого-нибудь другого шага
  //   for (const radiusId of this.newWlanData.security.data.radiusauthentication) {
  //     for (const radiusObj of this.radiusList) {
  //       if (radiusId === radiusObj.id) {
  //         this.updatedWlanRadiusAuthentication.push(radiusObj);
  //       }
  //     }
  //   }
  //
  //   // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
  //   if (
  //     Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
  //     Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
  //     this.$route.params.lastStepClicked === true
  //   ) {
  //     this.lastStepButtonClickHandler();
  //   }
  // }
  created() {
    hotspotService.getHotspots(this);
    radiusService.getRadius(this);
    redirectsService.getRedirectsRule(this);
    this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
    // цикл ниже чтобы заполннить поле 'RADIUS' прежними значениями если возвращаемся на этот шаг
    // из какого-нибудь другого шага
    for (const radiusId of this.newWlanData.security.data.radiusauthentication) {
      for (const radiusObj of this.radiusList) {
        if (radiusId === radiusObj.id) {
          this.updatedWlanRadiusAuthentication.push(radiusObj);
        }
      }
    }
    for (const radiusId of this.newWlanData.radius_acct_servers) {
      for (const radiusObj of this.radiusList) {
        if (radiusId === radiusObj.id) {
          this.updatedWlanRadiusAccounting.push(radiusObj);
        }
      }
    }

        // цикл ниже чтобы заполннить поле 'MAC RADIUS Авторизация' прежними значениями если возвращаемся на этот шаг
    // из какого-нибудь другого шага
    for (const radiusId of this.newWlanData.guest_control.mac_radius_auth_servers) {
      for (const radiusObj of this.radiusList) {
        if (radiusId === radiusObj.id) {
          this.updatedWlanRadiusMAC.push(radiusObj);
        }
      }
    }
    // наполняем черны и белый списки если возвращаемся на этот шаг
    // из какого-нибудь другого шага
    this.filterList.whitelist = JSON.parse(JSON.stringify(this.newWlanData.whitelist));
    this.filterList.blacklist = JSON.parse(JSON.stringify(this.newWlanData.blacklist));

    this.securityTab = this.$store.state.newWlanWizardSecurityTab;

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
    .tab-has-error{
    border: 0.5px solid #f86c6b !important;
  }
    .infoMessageForWPA3InCreatingWlanWizard-enter-active,
.infoMessageForWPA3InCreatingWlanWizard-leave-active {
  transition: opacity 0.3s;
}

.infoMessageForWPA3InCreatingWlanWizard-enter,
.infoMessageForWPA3InCreatingWlanWizard-leave-to {
  opacity: 0;
}
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
    .custom-tabs-wrapper-in-wlans-wizard-step2 .nav.nav-tabs.row {
  border-radius: 0px 0px 0px 0px !important;
}
  .caption-for-radius-settings {
  font-size: 0.9em !important;
  font-weight: bold;
}
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
