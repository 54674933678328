import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  getPortalPagesSchedules(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalSchedules');
    const config = props.query ?
      {
        params: {
          //   limit: ctx.filters.limit,
          //   offset: ctx.filters.offset,
          //   sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
          // }
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
          with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {
        params: {
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
      };
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/webpage/schedule`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalPagesList', response.data.data);
          ctx.$store.commit('pushPortalPagesSchedulesList', response.data.data);
          // наполняем массив рассписаний для показа в блоке каленадря
          ctx.schedulesForShowingInCalendarBlock = ctx.extractSchedulesForDate(
            ctx.calendarSelectedDate,
            ctx.pagesSchedules
          );
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalSchedules');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalSchedules');
      }
    );
  },
  getAllPortalPagesSchedulesByIds(ctx, pages) {
    ctx.$store.commit('toggleLoadingPortalSchedules');
    const pagesIdsArray = [];
    for (const page of pages) {
      pagesIdsArray.push(page.id);
    }
    // console.log(pagesIdsArray);
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/webpage/schedule`, { params: { ids: pagesIdsArray } }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalPagesList', response.data.data);
          ctx.$store.commit('pushPortalPagesSchedulesList', response.data.data);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalSchedules');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalSchedules');
      }
    );
  },
  createPageSchedule(ctx, schedule) {
    Vue.axios.post(`${RESTAPI || ''}/api/portal/admin/webpage/schedule`, { request: [schedule] }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('pageprofile.scheduleCreatedSuccessfully') });
          // this.getPortalPagesSchedules();
        } else {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  deletePortalPagesSchedules(ctx, scheduleId) {
    ctx.$store.commit('toggleLoadingPortalSchedules');
    // console.log(pagesIdsArray);
    Vue.axios.delete(`${RESTAPI || ''}/api/portal/admin/webpage/schedule`, { params: { ids: [scheduleId] } }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalPagesList', response.data.data);
          // this.getPortalPagesSchedules();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalSchedules');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalSchedules');
      }
    );
  },
  updatePageSchedule(ctx, schedule) {
    Vue.axios.put(`${RESTAPI || ''}/api/portal/admin/webpage/schedule`, { request: [schedule] }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('pageprofile.scheduleUpdatedSuccessfully') });
          // this.getPortalPagesSchedules();
        } else {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  }
};
