export default {
  nextStepName(ctx, currentStepName) {
    if (ctx.$store.getters.isNOPortalBuild) {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'UsersDemoWizardStep2';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep3';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'Users';
      }
      return 'Users';
    } else {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'UsersDemoWizardStep2';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep3';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'UsersDemoWizardStep4';
      }
      if (currentStepName === 'UsersDemoWizardStep4') {
        return 'UsersDemoWizardStep5';
      }
      if (currentStepName === 'UsersDemoWizardStep5') {
        return 'Users';
      }
      return 'Users';
    }
  },
  prevStepName(ctx, currentStepName) {
    if (ctx.$store.getters.isNOPortalBuild) {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'Users';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep1';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'UsersDemoWizardStep2';
      }
      return 'Users';
    } else {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'Users';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep1';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'UsersDemoWizardStep2';
      }
      if (currentStepName === 'UsersDemoWizardStep4') {
        return 'UsersDemoWizardStep3';
      }
      if (currentStepName === 'UsersDemoWizardStep5') {
        return 'UsersDemoWizardStep4';
      }
      return 'Users';
    }
  },
  goToNextStep(ctx, currentStepName) {
    ctx.$router.push({ name: this.nextStepName(ctx, currentStepName) });
  },
  goToPrevStep(ctx, currentStepName) {
    ctx.$router.push({ name: this.prevStepName(ctx, currentStepName) });
  },
  goToFirstStep(ctx) {
    ctx.$router.push({ name: 'UsersDemoWizardStep1' });
  },
  exitFromWizard(ctx) {
    ctx.$router.push({ name: 'Users' });
  }
};
