// eslint-disable-next-line import/prefer-default-export
export const setEnviroment = () => {
  // Setting API HOST for building with environment variables => RESTAPI="'http://desiredhost.ru'" npm run dev
  window.RESTAPI = process.env.VUE_APP_RESTAPI || '';
  window.WSADDR = process.env.VUE_APP_WSADDR || `wss://${window.location.host}/mediaserver/`;
  window.CUSTOM_BUILD = process.env.VUE_APP_CUSTOM_BUILD || '';
  window.BUILD = process.env.VUE_APP_BUILD || '';
  window.BUILD_DATE = process.env.VUE_APP_BUILD_DATE || '';
  // window.CISCO = process.env.VUE_APP_CISCO || '';
  // window.CISCO = process.env.VUE_APP_CISCO ? process.env.VUE_APP_CISCO : (process.env.VUE_APP_EXPEREMENTAL ? process.env.VUE_APP_EXPEREMENTAL : '');
  window.PORTAL = process.env.VUE_APP_PORTAL || '';
  // window.PORTAL_BASE_URL = process.env.VUE_APP_PORTAL_BASE_URL || '';
  window.PAID_INTERNET = process.env.VUE_APP_PAID_INTERNET || '';
  window.ANALYTICS = process.env.VUE_APP_ANALYTICS || '';
  window.FULL = process.env.VUE_APP_FULL || '';
  window.PORTAL_ONLY = process.env.VUE_APP_PORTAL_ONLY || '';
  window.APP_AUTH_FREEMIUM = process.env.VUE_APP_AUTH_FREEMIUM || '';
  window.VUEMODE = process.env.NODE_ENV || '';
  window.LK_URL = process.env.VUE_APP_LK_URL || 'http://aa.wimark.com';
  window.EXPEREMENTAL = process.env.VUE_APP_EXPEREMENTAL || '';

  window.LOGOUT_AFTER_USER_INACTIVITY = process.env.VUE_APP_LOGOUT_AFTER_USER_INACTIVITY || ''; // разлогинивание после определенного периода бездествия
  window.LOGOUT_AFTER_USER_INACTIVITY_TIME = parseInt(process.env.VUE_APP_LOGOUT_AFTER_USER_INACTIVITY_TIME || '10', 0); // время бездействия в минутах

  // включаем CISCO флаг если это CISCO или экспеерментальная сборка
  let ciscoBuildFlag = '';
  if (process.env.VUE_APP_CISCO || process.env.VUE_APP_EXPEREMENTAL){
    ciscoBuildFlag = process.env.VUE_APP_CISCO || process.env.VUE_APP_EXPEREMENTAL;
  }
  window.CISCO = ciscoBuildFlag;

  // дальше  для задачки WNE-2257 флаг и функция чтобы выводить сообщения о не 2xx ошибках в консоль,
  // вместо вывода в отдельное всплывающее окошко-алерат (нужно также чтобы эта функция вызывлась
  // в зависимости результата функции isShowAxiosErrorInConsole во всех обработчиках ошибок axios'a)
  window.SHOW_NON_2XX_API_ERRORS_IN_CONSOLE = true;
  window.showAxiosErrInConsole = function (err) {
    try {
      console.error('----- AXIOS API CALL ERROR -----');
      // console.log(err.request);
      // if (err.hasOwnProperty('response')) {
      //   if (err.response.hasOwnProperty('config') && err.response.config.hasOwnProperty('url')) {
      //     console.error('URL: ' + err.response.config.url);
      //   }
      // }
      // if (err.hasOwnProperty('request') && err.request.responseURL !== '') {
      //   console.error('URL: ' + err.request.responseURL);
      // }
      if (Object.prototype.hasOwnProperty.call(err, 'request') && err.request.responseURL !== '') {
        console.error(`URL: ${err.request.responseURL}`);
      }
      console.error(err);
      console.error('--------------------------------');
    } catch (e) {
      console.error(err);
    }
  };
  window.isShowAxiosErrorInConsole = function (err) {
    try {
      // проверяем конкретную  ошибку. Смотрим есть ли все нужные функции для вывода в консоль в window,
      // смотрим  на флаг SHOW_NON_2XX_API_ERRORS_IN_CONSOLE,
      // проверяем чтобы код ошибки не начанался на 2хх
      // const firstDigitOfErrorStatusCode = err.request.status.toString()[0] || '0';
      const errorStatusCode = err.request.status.toString();
      let firstDigitOfErrorStatusCode = '0';
      if (errorStatusCode.length !== 0) {
        firstDigitOfErrorStatusCode = errorStatusCode[0];
      }
      // if (window.hasOwnProperty('SHOW_NON_2XX_API_ERRORS_IN_CONSOLE') &&
      //   window.SHOW_NON_2XX_API_ERRORS_IN_CONSOLE === true && firstDigitOfErrorStatusCode !== '2' &&
      //   window.hasOwnProperty('showAxiosErrInConsole') && (typeof window.showAxiosErrInConsole === 'function')) {
      //   return true;
      // }
      if (
        Object.prototype.hasOwnProperty.call(window, 'SHOW_NON_2XX_API_ERRORS_IN_CONSOLE') &&
        window.SHOW_NON_2XX_API_ERRORS_IN_CONSOLE === true &&
        firstDigitOfErrorStatusCode !== '2' &&
        Object.prototype.hasOwnProperty.call(window, 'showAxiosErrInConsole') &&
        typeof window.showAxiosErrInConsole === 'function'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  console.log(process.env);
};
// export default setEnviroment;
