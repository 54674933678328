import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';

export default {
  getLocations(additionalQuery) {
    return new Promise((resolve, reject) => {
      const query = { action: 'R' };
      Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, Object.assign(query, additionalQuery)).then(
        (response) => {
          if (response.data.status === 'success') {
            if (!additionalQuery) {
              const items = helpers.transformArrayToObject(response.data.data.itemslist);
              for (const location of response.data.data.itemslist) {
                if (location.name === '/') {
                  store.commit('setBaseLocation', location.id);
                  break;
                }
              }
              store.commit('setLocationsItems', items);
              store.commit('setLocationsList', response.data.data.itemslist);
            }
          }
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
};
