import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import store from '../store';

export default {
  // getLicense(ctx) {
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/license`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setLicense', response.data.data.items);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //     }
  //   );
  // },
  getLicense(ctx, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    const payload = {
      action: 'R'
    }
    // if (!isUseLocationFilters) {
    //   payload.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
    //   payload.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    // }
    // if (ctx.filters?.location && isUseLocationFilters) {
    //   if (ctx.filters.location === 'All locations') {
    //     payload.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
    //     payload.with_childs = true;
    //   } else {
    //     payload.location = ctx.filters.location;
    //     payload.with_childs = ctx.filters.with_childs;
    //   }
    // }

    ctx.$store.commit('setLicencesInfoGetRequestExecutingBegin')
    Vue.axios.post(`${window.RESTAPI || ''}/api/license`, payload).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setLicense', response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({message: response.data.description});
        }
        ctx.$store.commit('setLicencesInfoGetRequestExecutingEnd')
      },
      (err) => {
        ctx.$store.commit('setLicencesInfoGetRequestExecutingEnd')
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({message: err});
        }

        ctx.error = err;
      }
    );
  },

  getLicenseForShowAlertsInFullViewComponent(ctx, callbackFunction) {
    const payload = {
      action: 'R',
      with_childs: true
    }
    ctx.$store.commit('setLicencesInfoGetRequestExecutingBegin')
    Vue.axios.post(`${window.RESTAPI || ''}/api/license`, payload).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setLicense', response.data.data.items);
          if (callbackFunction !== undefined && typeof callbackFunction === 'function'){
            callbackFunction();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({message: response.data.description});
        }
        ctx.$store.commit('setLicencesInfoGetRequestExecutingEnd')
      },
      (err) => {
        ctx.$store.commit('setLicencesInfoGetRequestExecutingEnd')
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({message: err});
        }

        ctx.error = err;
      }
    );
  },


  sendLicenseCode(ctx) {
    const updateLicense = { action: 'U', items: { 0: { token: ctx.licenseCode } } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/license`, updateLicense).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('licenses.notificationNewLicenseSuccessfullyAdded') });
          ctx.getLicense();
          ctx.licenseInput = false;
        } else if (response.data.status === 'error') {
          ctx.licenseInput = false;
          VueNotifications.error({ message: ctx.$t('licenses.notificationWrongLicenseCode') });
        }
      },
      (err) => {
        // VueNotifications.error({ message: response.data.description });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  uploadLicenceFile(ctx, formdata) {
    ctx.$store.commit('setLicenceFileUploadingRequestExecutingBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api-static/license_activate_file`, formdata).then(
      (response) => {
        if (response.data.status === 'success' || response.data === 'success') {
          VueNotifications.success({message: ctx.$t('licenses.notificationNewLicenseFileSuccessfullyAdded')});
          ctx.successUploadHandler();
        } else if (response.data.status === 'error' || response.data === 'error') {
          if (response.data.description === 'invalid license') {
            VueNotifications.error({ message: ctx.$t('licenses.notificationWrongLicenseFile') });
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        } else {
          VueNotifications.success({message: ctx.$t('licenses.notificationNewLicenseFileSuccessfullyAdded')});
          ctx.successUploadHandler();
        }
        ctx.$store.commit('setLicenceFileUploadingRequestExecutingEnd')
      },
      (err) => {
        ctx.$store.commit('setLicenceFileUploadingRequestExecutingEnd');
        // VueNotifications.error({ message: response.data.description });
        VueNotifications.error({ message: ctx.$t('licenses.notificationWrongLicenseFile') });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  activateLicenseText(ctx, licenseText) {
    ctx.$store.commit('setTextLicenceActivateRequestExecutingBegin');
    const dataForRequest = {action: 'C', items: {license_text: licenseText}};
    Vue.axios.post(`${window.RESTAPI || ''}/api/license_activate_text`, dataForRequest).then(
      (response) => {
        if (response.data.status === 'success' || response.data === 'success') {
          VueNotifications.success({ message: ctx.$t('licenses.notificationNewLicenseSuccessfullyAdded') });
          ctx.successActivateHandler();

        } else if (response.data.status === 'error' || response.data === 'error') {
          if (response.data.description === 'invalid license') {
            VueNotifications.error({ message: ctx.$t('licenses.notificationWrongLicenseCode') });
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        }
        ctx.$store.commit('setTextLicenceActivateRequestExecutingEnd');
      },
      (err) => {
        ctx.$store.commit('setTextLicenceActivateRequestExecutingEnd');
        VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

};
