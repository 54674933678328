<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <!--    <loader-spinner centered v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"></loader-spinner>-->
    <div class="col-lg-3">
      <div class="card card-inverse card-success">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="root + '/static/img/wifi1.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('wlans.wlanWizard') }}</div>
          <div v-if="currentStepIndex === '1'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step1') }}</p>
            <p>{{ $t('wlans.basicParameters') }}</p>
          </div>
          <div v-if="currentStepIndex === '2'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step2') }}</p>
            <p>{{ $t('wlans.securityParameters') }}</p>
          </div>
          <div v-if="currentStepIndex === '3'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step3') }}</p>
            <p>{{ $t('wlans.wizardNetworkEncapsulationStepCaption') }}. {{ $t('wlans.optionalStep') }}</p>
          </div>
          <div v-if="currentStepIndex === '4'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step4') }}</p>
            <p>{{ $t('wlans.wizardRADIUSAccountingStepCaption') }}. {{ $t('wlans.optionalStep') }}</p>
          </div>
          <div v-if="currentStepIndex === '5'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step5') }}</p>
            <p>{{ $t('wlans.wizardRADIUSAdditionalSettingsStepCaption') }}. {{ $t('wlans.optionalStep') }}</p>
          </div>
          <div v-if="currentStepIndex === '6'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step6') }}</p>
            <p>{{ $t('wlans.wizardSpeedControlSettingsStepCaption') }}. {{ $t('wlans.optionalStep') }}</p>
          </div>
          <div v-if="currentStepIndex === '7'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step7') }}</p>
            <p>{{ $t('wlans.wizardGuestControlStepCaption') }}. {{ $t('wlans.optionalStep') }}</p>
          </div>
          <div v-if="currentStepIndex === '8'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step8') }}</p>
            <p>{{ $t('wlans.wizardMobilityStepCaption') }}. {{ $t('wlans.optionalStep') }}</p>
          </div>
          <div v-if="currentStepIndex === '9'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step9') }}</p>
            <p>{{ $t('wlans.wizardWMMStepCaption') }}. {{ $t('wlans.optionalStep') }}</p>
          </div>
          <div v-if="currentStepIndex === 'StepLast'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.summary') }}</p>
            <p>{{ $t('wlans.confirmWlans') }}</p>
          </div>
          <div class="progress progress-white progress-xs mt-1">
            <div
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar"
              :style="{ width: progressWidth + '%', transition: 'all 0.2s' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div :class="currentStepIndex === '2' ||-->
    <!--    currentStepIndex === '3' ||-->
    <!--    currentStepIndex === '7'||-->
    <!--    currentStepIndex === '9' ||-->
    <!--    currentStepIndex === 'StepLast'? 'col-lg-5' : 'col-lg-4'"><router-view></router-view></div>-->
    <div class="wlan-wizard-steps-wrapper" :class="getStepBlockWidthClass(currentStepIndex)">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WlanWizard',
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    root() {
      return window.wimark.root;
    },
    currentStepIndex() {
      if (this.$route.name === 'StepLast') {
        return 'StepLast';
      }
      return this.$route.name.replace('step', '');
    },
    progressWidth() {
      let width;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'step1':
          width = 10;
          break;
        case 'step2':
          width = 20;
          break;
        case 'step3':
          width = 30;
          break;
        case 'step4':
          width = 40;
          break;
        case 'step5':
          width = 50;
          break;
        case 'step6':
          width = 60;
          break;
        case 'step7':
          width = 70;
          break;
        case 'step8':
          width = 80;
          break;
        case 'step9':
          width = 90;
          break;
        case 'StepLast':
          width = 100;
          break;
        default:
          width = 100;
      }
      return width;
    }
  },
  methods: {
    // getStepBlockWidthClass(currentStepIndex) {
    //   if (
    //     currentStepIndex === '7' ||
    //     currentStepIndex === '9') {
    //     return 'col-lg-5';
    //   }
    //   if (currentStepIndex === 'StepLast') {
    //     return 'col-lg-5';
    //   }
    //   return 'col-lg-4';
    // },
    getStepBlockWidthClass(currentStepIndex) {
      return 'col-lg-5';
    },
    nextStep(lastStepClicked = false) {
      let nextStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'step1':
          nextStep = 'step2';
          break;
        case 'step2':
          nextStep = 'step3';
          break;
        case 'step3':
          nextStep = 'step4';
          break;
        case 'step4':
          nextStep = 'step5';
          break;
        case 'step5':
          nextStep = 'step6';
          break;
        case 'step6':
          nextStep = 'step7';
          break;
        case 'step7':
          nextStep = 'step8';
          break;
        case 'step8':
          nextStep = 'step9';
          break;
        case 'step9':
          nextStep = 'StepLast';
          break;
        default:
          nextStep = 'Wlans';
      }
      // this.$router.push({ name: nextStep });
      if (lastStepClicked === false) {
        this.$router.push({ name: nextStep });
      } else {
        this.$store.commit('wlanWizardGoingToLastStepAfterClickSkipButtonStart');
        this.$router.push({ name: nextStep, params: { lastStepClicked: true } });
      }
    },
    prevStep() {
      let prevStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'step1':
          prevStep = 'step1';
          break;
        case 'step2':
          prevStep = 'step1';
          break;
        case 'step3':
          prevStep = 'step2';
          break;
        case 'step4':
          prevStep = 'step3';
          break;
        case 'step5':
          prevStep = 'step4';
          break;
        case 'step6':
          prevStep = 'step5';
          break;
        case 'step7':
          prevStep = 'step6';
          break;
        case 'step8':
          prevStep = 'step7';
          break;
        case 'step9':
          prevStep = 'step8';
          break;
        case 'StepLast':
          prevStep = 'step9';
          break;
        default:
          prevStep = 'step1';
      }
      this.$router.push(prevStep);
    },
    lastStep() {
      this.$store.commit('wlanWizardGoingToLastStepAfterClickSkipButtonStart');
      this.$router.push({ name: 'StepLast', params: { lastStepClicked: true } });
    },
    completeCreation() {
      this.$router.push({ path: '/wlans' });
    }
  },
  beforeCreate() {
    if (this.$store.state.userData.role == 'operator') {
      this.$router.push('../../wlans');
    }
  }
};
</script>
<style lang="scss">
.wizard-wlan-icon {
  height: 80px;
}

.wlan-wizard-steps-wrapper {
  min-height: 655px !important;
}
</style>
