<template>
  <div class="locale-switcher-wrapper">
    <div class="current-locale">
      <span class="flag-icon" :class="`flag-icon-${currentLocaleFlag}`" />
    </div>
    <div class="locale-list">
      <span
        v-for="(flag, locale) in locales"
        :key="locale"
        class="locale-list-item"
        :class="{ selected: flag === currentLocaleFlag }"
        @click="setLocale(flag, locale)"
      >
        <span class="flag-icon" :class="`flag-icon-${flag}`" />
      </span>
    </div>
  </div>
</template>
<script>
import Highcharts from 'highcharts/highstock';
// import highchartsMore from 'highcharts/highcharts-more';
export default {
  name: 'LocaleSwitcher',
  data() {
    return {
      locales: {
        en: 'us',
        ru: 'ru'
      }
    };
  },
  methods: {
    /**
     * @param {'us'|'ru'} flag - для значка языка
     * @param {'en'|'ru'} locale - локали
     */
    setLocale(flag, locale) {
      if (flag === this.currentLocaleFlag) return;
      localStorage.setItem('NMS_LOCALE', locale);
      this.$i18n.locale = locale;
      this.setHighChartsLocale(locale);
      this.$store.commit('setLocaleSwitchedTimeStamp');
      this.$emit('localeSwitcherLocaleSwitched');
    },
    setHighChartsLocale(locale) {
      // используется для переключения языка всех графиков highcharts
      // console.log(locale)
      try {
        if (locale === 'ru' || locale === 'RU') {
          Highcharts.setOptions({
            lang: {
              loading: 'Загрузка...',
              months: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь'
              ],
              weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
              shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
              noData: 'Нет данных для отображения'
              // downloadPNG: 'Скачать PNG',
              // downloadJPEG: 'Скачать JPEG',
              // downloadPDF: 'Скачать PDF',
              // downloadSVG: 'Скачать SVG'
            }
          });
        } else {
          Highcharts.setOptions({
            lang: {
              loading: 'Loading....',
              months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ],
              weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
              shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              noData: 'No data to display'
              // downloadPNG: 'Download PNG',
              // downloadJPEG: 'Download JPEG',
              // downloadPDF: 'Download PDF',
              // downloadSVG: 'Download SVG'
            }
          });
        }
      } catch (e) {
        // console.log(e);
        // console.warn(e);
      }
    }
  },
  computed: {
    currentLocaleFlag() {
      return this.locales[this.$i18n.locale];
    }
  },
  created() {
    // highchartsMore(Highcharts);
  },
  mounted() {
    const lsLocale = localStorage.getItem('NMS_LOCALE');
    if (lsLocale) {
      this.currentLocale = this.locales[lsLocale]; // 'us' | 'ru'
      this.setHighChartsLocale(this.currentLocale);
    }
  }
};
</script>

<style lang="scss">
.locale-switcher-wrapper {
  // position: fixed;
  // bottom: 10px;
  padding: 4px 2px;
  // right: 25px;
  display: inline-block;
  border-radius: 2px;
  font-size: 22px;
  line-height: 0;
  z-index: 9999;
  cursor: pointer;

  .current-locale {
    opacity: 0.5;
  }
  .locale-list {
    display: none;

    &-item {
      padding: 5px;
      border-radius: 2px;

      &:hover {
        transition: all 0.2s;
        background: lightgray;
      }
    }

    &-item:not(:last-of-type) {
      margin-right: 2px;
    }
  }

  &:hover {
    background: #444d58;
    .locale-list {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .current-locale {
      display: none;
    }
  }

  .flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;

    &-ru {
      background-image: url('/static/img/flags/ru.svg');
    }

    &-us {
      background-image: url('/static/img/flags/us.svg');
    }
  }
}
</style>
