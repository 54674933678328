import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  // getSummary(ctx, type) {
  //   ctx.$store.commit(type === 'visitors' ? 'enableLoadingVisitors' : 'enableLoadingSummary');
  //
  //   const params = {
  //     start: moment(ctx.drPickerDate.startDate).unix(),
  //     stop: moment(ctx.drPickerDate.endDate).unix(),
  //     timeout: 600,
  //     period: ctx.filters.dateType,
  //     location: ctx.filters.location.id
  //   };
  //
  //
  //   if (ctx.filters.cpes && ctx.filters.cpes.length) {
  //     params.cpes = [];
  //     ctx.filters.cpes.forEach((cpe) => {
  //       params.cpes.push(cpe.id);
  //     });
  //   }
  //
  //   ctx.VisitorsChart.series[0].data = [];
  //   ctx.VisitorsChart.series[1].data = [];
  //   ctx.vendorsChart.series[0].data = [];
  //   ctx.vendorsChart.series[0].data = [];
  //   ctx.vendorsChart.drilldown.series = [];
  //
  //   Vue.axios.get(`${window.RESTAPI || ''}/apiv2/summary`, { params }).then(
  //     (response) => {
  //       ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setSummary', response.data.data.items);
  //
  //         const data = response.data.data.items;
  //
  //         ctx.visits = data.visits;
  //         ctx.visitors = data.visitors;
  //         ctx.rate = data.rate;
  //         ctx.duration = data.duration;
  //         ctx.duration_avg = data.duration_avg;
  //
  //         if (ctx.visitors.new) {
  //           ctx.visitors.newpercent = Number((ctx.visitors.new / ctx.visitors.all * 100).toFixed(0));
  //           ctx.visitors.returnpercent = 100 - ctx.visitors.newpercent;
  //         }
  //
  //         ctx.RateHalf.series[0].data[0].y = ctx.visitors.returnpercent;
  //         ctx.RateHalf.series[0].data[1].y = ctx.visitors.newpercent;
  //
  //
  //         for (const date in data.period.all) {
  //           let dateData; let
  //             time;
  //           if (params.period === 'h') {
  //             time = new Date(date * 1000);
  //             dateData = Date.UTC(
  //               time.getFullYear(),
  //               time.getMonth(),
  //               time.getDate(),
  //               time.getHours(),
  //               time.getMinutes(),
  //               time.getSeconds()
  //             );
  //           } else if (params.period === 'd') {
  //             time = new Date(((date - 10801) * 1000));
  //             dateData = Date.UTC(
  //               time.getFullYear(),
  //               time.getMonth(),
  //               time.getDate()
  //             );
  //           } else if (params.period === 'm') {
  //             time = new Date(date * 1000);
  //             dateData = Date.UTC(
  //               time.getFullYear(),
  //               time.getMonth()
  //             );
  //           }
  //
  //           ctx.VisitorsChart.series[0].data.push([
  //             dateData,
  //             data.period.all[date] - data.period.new[date]
  //           ]);
  //
  //           ctx.VisitorsChart.series[1].data.push([
  //             dateData,
  //             data.period.new[date]
  //           ]);
  //         }
  //
  //         for (const rate in data.rate) {
  //           switch (rate) {
  //             case '1':
  //               ctx.Returned.series[0].data[0].y = data.rate[rate];
  //               break;
  //             case '2':
  //               ctx.Returned.series[0].data[1].y = data.rate[rate];
  //               break;
  //             case '3':
  //               ctx.Returned.series[0].data[2].y = data.rate[rate];
  //               break;
  //             case '5':
  //               ctx.Returned.series[0].data[3].y = data.rate[rate];
  //               break;
  //             case '10':
  //               ctx.Returned.series[0].data[4].y = data.rate[rate];
  //               break;
  //           }
  //         }
  //
  //         if (data.vendors && data.vendors.all) {
  //           ctx.vendors = data.vendors.all;
  //           let other = 0;
  //           const otherChart = {
  //             name: 'Other',
  //             id: 'Other',
  //             data: []
  //           };
  //           ctx.sortedVendors = Object.keys(data.vendors.all).sort((a, b) => -(data.vendors.all[a] - data.vendors.all[b]));
  //
  //           ctx.allVendors = ctx.sortedVendors.reduce((sum, current) => sum + data.vendors.all[current], 0);
  //
  //           ctx.sortedVendors.forEach((vendor) => {
  //             const currentPercent = data.vendors.all[vendor] / ctx.allVendors * 100;
  //             if (ctx.vendorsChart.series[0].data.length < 19 || ctx.vendorsChart.series[0].data.length < parseFloat(ctx.sortedVendors.length * 0.7)) {
  //               ctx.vendorsChart.series[0].data.push({
  //                 name: vendor,
  //                 y: currentPercent,
  //                 drilldown: null
  //               });
  //             } else {
  //               other += (currentPercent);
  //               otherChart.data.push([vendor, currentPercent]);
  //             }
  //           });
  //
  //           if (other) {
  //             ctx.vendorsChart.series[0].data.push({
  //               name: 'Other',
  //               y: other,
  //               drilldown: 'Other'
  //             });
  //           }
  //           ctx.vendorsChart.drilldown.series.push(otherChart);
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         (typeof window.isShowAxiosErrorInConsole === 'function') &&
  //         window.isShowAxiosErrorInConsole(err)) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //     }
  //   );
  // },

  // getSummary(ctx, type) {
  //   ctx.$store.commit(type === 'visitors' ? 'enableLoadingVisitors' : 'enableLoadingSummary');
  //
  //   const params = {
  //     start: moment(ctx.drPickerDate.startDate).unix(),
  //     stop: moment(ctx.drPickerDate.endDate).unix(),
  //     timeout: 600,
  //     period: ctx.filters.dateType,
  //     location: ctx.filters.location.id
  //   };
  //
  //   if (ctx.filters.cpes && ctx.filters.cpes.length) {
  //     params.cpes = [];
  //     ctx.filters.cpes.forEach((cpe) => {
  //       params.cpes.push(cpe.id);
  //     });
  //   }
  //
  //   // смотрим на то включена ли фильтрация по времени работы и если нужно дабавляем в запрос параметры
  //   if (Object.prototype.hasOwnProperty.call(ctx, 'workTimeFilters')) {
  //     if (
  //       ctx.workTimeFilters.workTimeFiltersEnabled === true &&
  //       ctx.workTimeFilters.startWorkTime !== '' &&
  //       ctx.workTimeFilters.stopWorkTime !== '' &&
  //       ctx.workTimeFilters.time_zone !== ''
  //     ) {
  //       params.with_night_clients = ctx.workTimeFilters.workTimeFiltersEnabled;
  //       params.work_time_range = `${ctx.workTimeFilters.startWorkTime}/${ctx.workTimeFilters.stopWorkTime}`;
  //       params.zone = ctx.workTimeFilters.time_zone;
  //     }
  //   }
  //
  //   ctx.VisitorsChart.series[0].data = [];
  //   ctx.VisitorsChart.series[1].data = [];
  //   ctx.vendorsChart.series[0].data = [];
  //   ctx.vendorsChart.series[0].data = [];
  //   ctx.vendorsChart.drilldown.series = [];
  //
  //   Vue.axios.get(`${window.RESTAPI || ''}/apiv2/summary`, { params }).then(
  //     (response) => {
  //       ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setSummary', response.data.data.items);
  //
  //         const data = response.data.data.items;
  //
  //         ctx.visits = data.visits;
  //         ctx.visitors = data.visitors;
  //         ctx.rate = data.rate;
  //         ctx.duration = data.duration;
  //         ctx.duration_avg = data.duration_avg;
  //
  //         if (ctx.visitors.new) {
  //           ctx.visitors.newpercent = Number(((ctx.visitors.new / ctx.visitors.all) * 100).toFixed(0));
  //           ctx.visitors.returnpercent = 100 - ctx.visitors.newpercent;
  //         }
  //
  //         ctx.RateHalf.series[0].data[0].y = ctx.visitors.returnpercent;
  //         ctx.RateHalf.series[0].data[1].y = ctx.visitors.newpercent;
  //
  //         for (const date in data.period.all) {
  //           let dateData;
  //           let time;
  //           if (params.period === 'h') {
  //             time = new Date(date * 1000);
  //             dateData = Date.UTC(
  //               time.getFullYear(),
  //               time.getMonth(),
  //               time.getDate(),
  //               time.getHours(),
  //               time.getMinutes(),
  //               time.getSeconds()
  //             );
  //           } else if (params.period === 'd') {
  //             time = new Date((date - 10801) * 1000);
  //             dateData = Date.UTC(time.getFullYear(), time.getMonth(), time.getDate());
  //           } else if (params.period === 'm') {
  //             time = new Date(date * 1000);
  //             dateData = Date.UTC(time.getFullYear(), time.getMonth());
  //           }
  //
  //           ctx.VisitorsChart.series[0].data.push([dateData, data.period.all[date] - data.period.new[date]]);
  //
  //           ctx.VisitorsChart.series[1].data.push([dateData, data.period.new[date]]);
  //         }
  //
  //         for (const rate in data.rate) {
  //           switch (rate) {
  //             case '1':
  //               ctx.Returned.series[0].data[0].y = data.rate[rate];
  //               break;
  //             case '2':
  //               ctx.Returned.series[0].data[1].y = data.rate[rate];
  //               break;
  //             case '3':
  //               ctx.Returned.series[0].data[2].y = data.rate[rate];
  //               break;
  //             case '5':
  //               ctx.Returned.series[0].data[3].y = data.rate[rate];
  //               break;
  //             case '10':
  //               ctx.Returned.series[0].data[4].y = data.rate[rate];
  //               break;
  //           }
  //         }
  //
  //         if (data.vendors && data.vendors.all) {
  //           ctx.vendors = data.vendors.all;
  //           let other = 0;
  //           const otherChart = {
  //             name: 'Other',
  //             id: 'Other',
  //             data: []
  //           };
  //           ctx.sortedVendors = Object.keys(data.vendors.all).sort(
  //             (a, b) => -(data.vendors.all[a] - data.vendors.all[b])
  //           );
  //
  //           ctx.allVendors = ctx.sortedVendors.reduce((sum, current) => sum + data.vendors.all[current], 0);
  //
  //           ctx.sortedVendors.forEach((vendor) => {
  //             const currentPercent = (data.vendors.all[vendor] / ctx.allVendors) * 100;
  //             if (
  //               ctx.vendorsChart.series[0].data.length < 19 ||
  //               ctx.vendorsChart.series[0].data.length < parseFloat(ctx.sortedVendors.length * 0.7)
  //             ) {
  //               ctx.vendorsChart.series[0].data.push({
  //                 name: vendor,
  //                 y: currentPercent,
  //                 drilldown: null
  //               });
  //             } else {
  //               other += currentPercent;
  //               otherChart.data.push([vendor, currentPercent]);
  //             }
  //           });
  //
  //           if (other) {
  //             ctx.vendorsChart.series[0].data.push({
  //               name: 'Other',
  //               y: other,
  //               drilldown: 'Other'
  //             });
  //           }
  //           ctx.vendorsChart.drilldown.series.push(otherChart);
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //     }
  //   );
  // },

  getSummary(ctx, type, useLocationFilter= false) {
    ctx.$store.commit(type === 'visitors' ? 'enableLoadingVisitors' : 'enableLoadingSummary');
    const isUseLocationFilters = useLocationFilter;
    // console.log('ctx.filters.location.id', ctx.filters.location.id)
    const params = {
      start: moment(ctx.drPickerDate.startDate).unix(),
      stop: moment(ctx.drPickerDate.endDate).unix(),
      timeout: 600,
      period: ctx.filters.dateType,
      // location: ctx.filters.location.id
    };

    if (!isUseLocationFilters) {
      params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }

    if (ctx.filters?.location?.id && isUseLocationFilters) {
      if (ctx.filters.location === '') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location.id;
        params.with_childs = ctx.filters.with_childs;
      }
    }


    if (ctx.filters.cpes && ctx.filters.cpes.length) {
      params.cpes = [];
      ctx.filters.cpes.forEach((cpe) => {
        params.cpes.push(cpe.id);
      });
    }

    // смотрим на то включена ли фильтрация по времени работы и если нужно дабавляем в запрос параметры
    if (Object.prototype.hasOwnProperty.call(ctx, 'workTimeFilters')) {
      if (
        ctx.workTimeFilters.workTimeFiltersEnabled === true &&
        ctx.workTimeFilters.startWorkTime !== '' &&
        ctx.workTimeFilters.stopWorkTime !== '' &&
        ctx.workTimeFilters.time_zone !== ''
      ) {
        params.with_night_clients = ctx.workTimeFilters.workTimeFiltersEnabled;
        params.work_time_range = `${ctx.workTimeFilters.startWorkTime}/${ctx.workTimeFilters.stopWorkTime}`;
        params.zone = ctx.workTimeFilters.time_zone;
      }
    }

    ctx.VisitorsChart.series[0].data = [];
    ctx.VisitorsChart.series[1].data = [];
    ctx.vendorsChart.series[0].data = [];
    ctx.vendorsChart.series[0].data = [];
    ctx.vendorsChart.drilldown.series = [];

    Vue.axios.get(`${window.RESTAPI || ''}/apiv2/summary`, { params }).then(
      (response) => {
        ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
        if (response.data.status === 'success') {
          ctx.$store.commit('setSummary', response.data.data.items);

          const data = response.data.data.items;

          ctx.visits = data.visits;
          ctx.visitors = data.visitors;
          ctx.rate = data.rate;
          ctx.duration = data.duration;
          ctx.duration_avg = data.duration_avg;

          if (ctx.visitors.new) {
            ctx.visitors.newpercent = Number(((ctx.visitors.new / ctx.visitors.all) * 100).toFixed(0));
            ctx.visitors.returnpercent = 100 - ctx.visitors.newpercent;
          }

          ctx.RateHalf.series[0].data[0].y = ctx.visitors.returnpercent;
          ctx.RateHalf.series[0].data[1].y = ctx.visitors.newpercent;

          for (const date in data.period.all) {
            let dateData;
            let time;
            if (params.period === 'h') {
              time = new Date(date * 1000);
              dateData = Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              );
            } else if (params.period === 'd') {
              time = new Date((date - 10801) * 1000);
              dateData = Date.UTC(time.getFullYear(), time.getMonth(), time.getDate());
            } else if (params.period === 'm') {
              time = new Date(date * 1000);
              dateData = Date.UTC(time.getFullYear(), time.getMonth());
            }

            // ctx.VisitorsChart.series[0].data.push([dateData, data.period.all[date] - data.period.new[date]]);
            //
            // ctx.VisitorsChart.series[1].data.push([dateData, data.period.new[date]]);
            //
            //
            //


            const allForDate = data.period.all[date];
            const newForDate = data.period.new[date] || 0;
            const repeatForDate = allForDate - newForDate;

            ctx.VisitorsChart.series[0].data.push([dateData, repeatForDate]);
            ctx.VisitorsChart.series[1].data.push([dateData, newForDate]);
          }

          for (const rate in data.rate) {
            switch (rate) {
              case '1':
                ctx.Returned.series[0].data[0].y = data.rate[rate];
                break;
              case '2':
                ctx.Returned.series[0].data[1].y = data.rate[rate];
                break;
              case '3':
                ctx.Returned.series[0].data[2].y = data.rate[rate];
                break;
              case '5':
                ctx.Returned.series[0].data[3].y = data.rate[rate];
                break;
              case '10':
                ctx.Returned.series[0].data[4].y = data.rate[rate];
                break;
            }
          }

          if (data.vendors && data.vendors.all) {
            ctx.vendors = data.vendors.all;
            let other = 0;
            const otherChart = {
              name: 'Other',
              id: 'Other',
              data: []
            };
            ctx.sortedVendors = Object.keys(data.vendors.all).sort(
              (a, b) => -(data.vendors.all[a] - data.vendors.all[b])
            );

            ctx.allVendors = ctx.sortedVendors.reduce((sum, current) => sum + data.vendors.all[current], 0);

            ctx.sortedVendors.forEach((vendor) => {
              const currentPercent = (data.vendors.all[vendor] / ctx.allVendors) * 100;
              if (
                ctx.vendorsChart.series[0].data.length < 19 ||
                ctx.vendorsChart.series[0].data.length < parseFloat(ctx.sortedVendors.length * 0.7)
              ) {
                ctx.vendorsChart.series[0].data.push({
                  name: vendor,
                  y: currentPercent,
                  drilldown: null
                });
              } else {
                other += currentPercent;
                otherChart.data.push([vendor, currentPercent]);
              }
            });

            if (other) {
              ctx.vendorsChart.series[0].data.push({
                name: 'Other',
                y: other,
                drilldown: 'Other'
              });
            }
            ctx.vendorsChart.drilldown.series.push(otherChart);
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
      }
    );
  },
  // getSummaryV2ForUseInMarketing(ctx, type, useLocationFilter = false) {
  //   if (ctx.timeOutForShowAvgMinutesLoadingData) {
  //     clearTimeout(ctx.timeOutForShowAvgMinutesLoadingData);
  //   }
  //   ctx.showAvgMinutesLoadingData = false;
  //   ctx.timeOutForShowAvgMinutesLoadingData = setTimeout(() => {
  //     ctx.showAvgMinutesLoadingData = true
  //   }, 1000)
  //
  //   ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //   // ctx.$store.commit('cancelAllRequestsAndClearAllTokensForComponent', { componentName: ctx.$route.name } )
  //
  //
  //   ctx.VisitorsChart.series[0].data = [];
  //   ctx.VisitorsChart.series[1].data = [];
  //   ctx.vendorsChart.series[0].data = [];
  //   ctx.vendorsChart.series[0].data = [];
  //   ctx.vendorsChart.drilldown.series = [];
  //
  //   ctx.RateHalf.series[0].data[0].y = 0;
  //   ctx.RateHalf.series[0].data[1].y = 0;
  //
  //   ctx.Returned.series[0].data[0].y = 0;
  //   ctx.Returned.series[0].data[1].y = 0;
  //   ctx.Returned.series[0].data[2].y = 0;
  //   ctx.Returned.series[0].data[3].y = 0;
  //   ctx.Returned.series[0].data[4].y = 0;
  //
  //   ctx.visits = {};
  //   ctx.visitors = {};
  //   ctx.rate = {};
  //   ctx.duration = {};
  //   ctx.duration_avg = {};
  //   ctx.vendors = {};
  //   ctx.sortedVendors = [];
  //   ctx.allVendors = 0;
  //
  //
  //   const cancelToken = Vue.axios.CancelToken.source();
  //   const requestName = 'getSummaryV2ForUseInMarketing';
  //   const componentName = ctx.$route.name;
  //   ctx.$store.commit('clearAndCancelRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
  //   ctx.$store.commit('addRequestCancelTokenToRequestsCancelTokens', { componentName, requestName, cancelToken })
  //   ctx.$store.commit(type === 'visitors' ? 'enableLoadingVisitors' : 'enableLoadingSummary');
  //   const isUseLocationFilters = useLocationFilter;
  //   // console.log('ctx.filters.location.id', ctx.filters.location.id)
  //   const params = {
  //     start: moment(ctx.drPickerDate.startDate).unix(),
  //     stop: moment(ctx.drPickerDate.endDate).unix(),
  //     timeout: 600,
  //     period: ctx.filters.dateType,
  //     // location: ctx.filters.location.id
  //   };
  //
  //   if (!isUseLocationFilters) {
  //     params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
  //     params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
  //   }
  //
  //   if (ctx.filters?.location?.id && isUseLocationFilters) {
  //     if (ctx.filters.location === '') {
  //       params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
  //       params.with_childs = true;
  //     } else {
  //       params.location = ctx.filters.location.id;
  //       params.with_childs = ctx.filters.with_childs;
  //     }
  //   }
  //
  //
  //   if (ctx.filters.cpes && ctx.filters.cpes.length) {
  //     params.cpes = [];
  //     ctx.filters.cpes.forEach((cpe) => {
  //       params.cpes.push(cpe.id);
  //     });
  //   }
  //
  //   // смотрим на то включена ли фильтрация по времени работы и если нужно дабавляем в запрос параметры
  //   if (Object.prototype.hasOwnProperty.call(ctx, 'workTimeFilters')) {
  //     if (
  //       ctx.workTimeFilters.workTimeFiltersEnabled === true &&
  //       ctx.workTimeFilters.startWorkTime !== '' &&
  //       ctx.workTimeFilters.stopWorkTime !== '' &&
  //       ctx.workTimeFilters.time_zone !== ''
  //     ) {
  //       params.with_night_clients = ctx.workTimeFilters.workTimeFiltersEnabled;
  //       params.work_time_range = `${ctx.workTimeFilters.startWorkTime}/${ctx.workTimeFilters.stopWorkTime}`;
  //       params.zone = ctx.workTimeFilters.time_zone;
  //     }
  //   }
  //
  //
  //
  //   Vue.axios.get(`${window.RESTAPI || ''}/apiv2/summary`, { params, cancelToken: cancelToken.token }).then(
  //     (response) => {
  //       // ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setSummary', response.data.data.items);
  //
  //         const data = response.data.data.items;
  //
  //         ctx.visits = data.visits;
  //         ctx.visitors = data.visitors;
  //         ctx.rate = data.rate;
  //         ctx.duration = data.duration;
  //         ctx.duration_avg = data.duration_avg;
  //
  //         if (ctx.visitors.new) {
  //           ctx.visitors.newpercent = Number(((ctx.visitors.new / ctx.visitors.all) * 100).toFixed(0));
  //           ctx.visitors.returnpercent = 100 - ctx.visitors.newpercent;
  //         }
  //
  //         ctx.RateHalf.series[0].data[0].y = ctx.visitors.returnpercent;
  //         ctx.RateHalf.series[0].data[1].y = ctx.visitors.newpercent;
  //
  //         for (const date in data.period.all) {
  //           let dateData;
  //           let time;
  //           if (params.period === 'h') {
  //             time = new Date(date * 1000);
  //             dateData = Date.UTC(
  //               time.getFullYear(),
  //               time.getMonth(),
  //               time.getDate(),
  //               time.getHours(),
  //               time.getMinutes(),
  //               time.getSeconds()
  //             );
  //           } else if (params.period === 'd') {
  //             time = new Date((date - 10801) * 1000);
  //             dateData = Date.UTC(time.getFullYear(), time.getMonth(), time.getDate());
  //           } else if (params.period === 'm') {
  //             time = new Date(date * 1000);
  //             dateData = Date.UTC(time.getFullYear(), time.getMonth());
  //           }
  //
  //           // ctx.VisitorsChart.series[0].data.push([dateData, data.period.all[date] - data.period.new[date]]);
  //           //
  //           // ctx.VisitorsChart.series[1].data.push([dateData, data.period.new[date]]);
  //           //
  //           //
  //           //
  //
  //
  //           const allForDate = data.period.all[date];
  //           const newForDate = data.period.new[date] || 0;
  //           const repeatForDate = allForDate - newForDate;
  //
  //           ctx.VisitorsChart.series[0].data.push([dateData, repeatForDate]);
  //           ctx.VisitorsChart.series[1].data.push([dateData, newForDate]);
  //         }
  //
  //         for (const rate in data.rate) {
  //           switch (rate) {
  //             case '1':
  //               ctx.Returned.series[0].data[0].y = data.rate[rate];
  //               break;
  //             case '2':
  //               ctx.Returned.series[0].data[1].y = data.rate[rate];
  //               break;
  //             case '3':
  //               ctx.Returned.series[0].data[2].y = data.rate[rate];
  //               break;
  //             case '5':
  //               ctx.Returned.series[0].data[3].y = data.rate[rate];
  //               break;
  //             case '10':
  //               ctx.Returned.series[0].data[4].y = data.rate[rate];
  //               break;
  //           }
  //         }
  //
  //         if (data.vendors && data.vendors.all) {
  //           ctx.vendors = data.vendors.all;
  //           let other = 0;
  //           const otherChart = {
  //             name: 'Other',
  //             id: 'Other',
  //             data: []
  //           };
  //           ctx.sortedVendors = Object.keys(data.vendors.all).sort(
  //             (a, b) => -(data.vendors.all[a] - data.vendors.all[b])
  //           );
  //
  //           ctx.allVendors = ctx.sortedVendors.reduce((sum, current) => sum + data.vendors.all[current], 0);
  //
  //           ctx.sortedVendors.forEach((vendor) => {
  //             const currentPercent = (data.vendors.all[vendor] / ctx.allVendors) * 100;
  //             if (
  //               ctx.vendorsChart.series[0].data.length < 19 ||
  //               ctx.vendorsChart.series[0].data.length < parseFloat(ctx.sortedVendors.length * 0.7)
  //             ) {
  //               ctx.vendorsChart.series[0].data.push({
  //                 name: vendor,
  //                 y: currentPercent,
  //                 drilldown: null
  //               });
  //             } else {
  //               other += currentPercent;
  //               otherChart.name = ctx.$t('general.another');
  //               otherChart.data.push([vendor, currentPercent]);
  //
  //             }
  //           });
  //
  //           if (other) {
  //             ctx.vendorsChart.series[0].data.push({
  //               name: ctx.$t('general.another'),
  //               y: other,
  //               drilldown: 'Other'
  //             });
  //           }
  //           ctx.vendorsChart.drilldown.series.push(otherChart);
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         // ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //       }
  //       if (ctx.timeOutForShowAvgMinutesLoadingData) {
  //         clearTimeout(ctx.timeOutForShowAvgMinutesLoadingData);
  //       }
  //       ctx.showAvgMinutesLoadingData = false;
  //       ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //       ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
  //     },
  //     (err) => {
  //
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       // if (
  //       //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //       //   typeof window.isShowAxiosErrorInConsole === 'function' &&
  //       //   window.isShowAxiosErrorInConsole(err)
  //       // ) {
  //       //   window.showAxiosErrInConsole(err);
  //       // } else {
  //       //   VueNotifications.error({ message: err });
  //       // }
  //
  //
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         if (Vue.axios.isCancel(err)){
  //           console.log(`${requestName} request for ${componentName} is canceled`)
  //           return
  //         }else{
  //           VueNotifications.error({ message: err });
  //         }
  //
  //       }
  //       ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName})
  //       ctx.error = err;
  //       if (ctx.timeOutForShowAvgMinutesLoadingData) {
  //         clearTimeout(ctx.timeOutForShowAvgMinutesLoadingData);
  //       }
  //       ctx.showAvgMinutesLoadingData = false;
  //       ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
  //     }
  //   );
  // },

  getSummaryV2ForUseInMarketing(ctx, type, useLocationFilter = false) {
    if (ctx.isShowingDateRangeLimitExceededError) {
      return;
    }

    if (ctx.timeOutForShowAvgMinutesLoadingData) {
      clearTimeout(ctx.timeOutForShowAvgMinutesLoadingData);
    }
    ctx.showAvgMinutesLoadingData = false;
    ctx.timeOutForShowAvgMinutesLoadingData = setTimeout(() => {
      ctx.showAvgMinutesLoadingData = true
    }, 1000)

    ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
    // ctx.$store.commit('cancelAllRequestsAndClearAllTokensForComponent', { componentName: ctx.$route.name } )


    ctx.VisitorsChart.series[0].data = [];
    ctx.VisitorsChart.series[1].data = [];

    if (type !== 'visitors') {
      ctx.vendorsChart.series[0].data = [];
      ctx.vendorsChart.series[0].data = [];
      ctx.vendorsChart.drilldown.series = [];

      ctx.RateHalf.series[0].data[0].y = 0;
      ctx.RateHalf.series[0].data[1].y = 0;

      ctx.Returned.series[0].data[0].y = 0;
      ctx.Returned.series[0].data[1].y = 0;
      ctx.Returned.series[0].data[2].y = 0;
      ctx.Returned.series[0].data[3].y = 0;
      ctx.Returned.series[0].data[4].y = 0;

      ctx.visits = {};
      ctx.visitors = {};
      ctx.rate = {};
      ctx.duration = {};
      ctx.duration_avg = {};
      ctx.vendors = {};
      ctx.sortedVendors = [];
      ctx.allVendors = 0;
    }




    const cancelToken = Vue.axios.CancelToken.source();
    const requestName = 'getSummaryV2ForUseInMarketing';
    const componentName = ctx.$route.name;
    ctx.$store.commit('clearAndCancelRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
    ctx.$store.commit('addRequestCancelTokenToRequestsCancelTokens', { componentName, requestName, cancelToken })
    ctx.$store.commit(type === 'visitors' ? 'enableLoadingVisitors' : 'enableLoadingSummary');
    const isUseLocationFilters = useLocationFilter;
    // console.log('ctx.filters.location.id', ctx.filters.location.id)
    const params = {
      start: moment(ctx.drPickerDate.startDate).unix(),
      stop: moment(ctx.drPickerDate.endDate).unix(),
      timeout: 600,
      period: ctx.filters.dateType,
      // location: ctx.filters.location.id
    };

    if (!isUseLocationFilters) {
      params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }

    if (ctx.filters?.location?.id && isUseLocationFilters) {
      if (ctx.filters.location === '') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location.id;
        params.with_childs = ctx.filters.with_childs;
      }
    }


    if (ctx.filters.cpes && ctx.filters.cpes.length) {
      params.cpes = [];
      ctx.filters.cpes.forEach((cpe) => {
        params.cpes.push(cpe.id);
      });
    }

    // смотрим на то включена ли фильтрация по времени работы и если нужно дабавляем в запрос параметры
    if (Object.prototype.hasOwnProperty.call(ctx, 'workTimeFilters')) {
      if (
        ctx.workTimeFilters.workTimeFiltersEnabled === true &&
        ctx.workTimeFilters.startWorkTime !== '' &&
        ctx.workTimeFilters.stopWorkTime !== '' &&
        ctx.workTimeFilters.time_zone !== ''
      ) {
        params.with_night_clients = ctx.workTimeFilters.workTimeFiltersEnabled;
        params.work_time_range = `${ctx.workTimeFilters.startWorkTime}/${ctx.workTimeFilters.stopWorkTime}`;
        params.zone = ctx.workTimeFilters.time_zone;
      }
    }



    Vue.axios.get(`${window.RESTAPI || ''}/apiv2/summary`, { params, cancelToken: cancelToken.token }).then(
      (response) => {
        // ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
        if (response.data.status === 'success') {
          ctx.$store.commit('setSummary', response.data.data.items);

          const data = response.data.data.items;

          if (type !== 'visitors') {

            ctx.visits = data.visits;
            ctx.visitors = data.visitors;
            ctx.rate = data.rate;
            ctx.duration = data.duration;
            ctx.duration_avg = data.duration_avg;

            if (ctx.visitors.new) {
              ctx.visitors.newpercent = Number(((ctx.visitors.new / ctx.visitors.all) * 100).toFixed(0));
              ctx.visitors.returnpercent = 100 - ctx.visitors.newpercent;
            }

            ctx.RateHalf.series[0].data[0].y = ctx.visitors.returnpercent;
            ctx.RateHalf.series[0].data[1].y = ctx.visitors.newpercent;
          }




          for (const date in data.period.all) {
            let dateData;
            let time;
            if (params.period === 'h') {
              time = new Date(date * 1000);
              dateData = Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              );
            } else if (params.period === 'd') {
              time = new Date((date - 10801) * 1000);
              dateData = Date.UTC(time.getFullYear(), time.getMonth(), time.getDate());
            } else if (params.period === 'm') {
              time = new Date(date * 1000);
              dateData = Date.UTC(time.getFullYear(), time.getMonth());
            }

            // ctx.VisitorsChart.series[0].data.push([dateData, data.period.all[date] - data.period.new[date]]);
            //
            // ctx.VisitorsChart.series[1].data.push([dateData, data.period.new[date]]);
            //
            //
            //


            const allForDate = data.period.all[date];
            const newForDate = data.period.new[date] || 0;
            const repeatForDate = allForDate - newForDate;

            ctx.VisitorsChart.series[0].data.push([dateData, repeatForDate]);
            ctx.VisitorsChart.series[1].data.push([dateData, newForDate]);
          }

          if (type !== 'visitors') {

            for (const rate in data.rate) {
              switch (rate) {
                case '1':
                  ctx.Returned.series[0].data[0].y = data.rate[rate];
                  break;
                case '2':
                  ctx.Returned.series[0].data[1].y = data.rate[rate];
                  break;
                case '3':
                  ctx.Returned.series[0].data[2].y = data.rate[rate];
                  break;
                case '5':
                  ctx.Returned.series[0].data[3].y = data.rate[rate];
                  break;
                case '10':
                  ctx.Returned.series[0].data[4].y = data.rate[rate];
                  break;
              }
            }

            if (data.vendors && data.vendors.all) {
              ctx.vendors = data.vendors.all;
              let other = 0;
              const otherChart = {
                name: 'Other',
                id: 'Other',
                data: []
              };
              ctx.sortedVendors = Object.keys(data.vendors.all).sort(
                (a, b) => -(data.vendors.all[a] - data.vendors.all[b])
              );

              ctx.allVendors = ctx.sortedVendors.reduce((sum, current) => sum + data.vendors.all[current], 0);

              ctx.sortedVendors.forEach((vendor) => {
                const currentPercent = (data.vendors.all[vendor] / ctx.allVendors) * 100;
                if (
                  ctx.vendorsChart.series[0].data.length < 19 ||
                  ctx.vendorsChart.series[0].data.length < parseFloat(ctx.sortedVendors.length * 0.7)
                ) {
                  ctx.vendorsChart.series[0].data.push({
                    name: vendor,
                    y: currentPercent,
                    drilldown: null
                  });
                } else {
                  other += currentPercent;
                  otherChart.name = ctx.$t('general.another');
                  otherChart.data.push([vendor, currentPercent]);

                }
              });

              if (other) {
                ctx.vendorsChart.series[0].data.push({
                  name: ctx.$t('general.another'),
                  y: other,
                  drilldown: 'Other'
                });
              }
              ctx.vendorsChart.drilldown.series.push(otherChart);
            }
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          // ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
        }
        if (ctx.timeOutForShowAvgMinutesLoadingData) {
          clearTimeout(ctx.timeOutForShowAvgMinutesLoadingData);
        }
        ctx.showAvgMinutesLoadingData = false;
        ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
        ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
      },
      (err) => {

        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        // if (
        //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        //   typeof window.isShowAxiosErrorInConsole === 'function' &&
        //   window.isShowAxiosErrorInConsole(err)
        // ) {
        //   window.showAxiosErrInConsole(err);
        // } else {
        //   VueNotifications.error({ message: err });
        // }


        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          if (Vue.axios.isCancel(err)){
            console.log(`${requestName} request for ${componentName} is canceled`)
            return
          }else{
            VueNotifications.error({ message: err });
          }

        }
        ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName})
        ctx.error = err;
        if (ctx.timeOutForShowAvgMinutesLoadingData) {
          clearTimeout(ctx.timeOutForShowAvgMinutesLoadingData);
        }
        ctx.showAvgMinutesLoadingData = false;
        ctx.$store.commit(type === 'visitors' ? 'disableLoadingVisitors' : 'disableLoadingSummary');
      }
    );
  },

  getCPEsbyLocation(ctx) {
    ctx.isDisableCpes = true;
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes/compact`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        q: {
          base_location: ctx.filters.location.id
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.isDisableCpes = false;
            ctx.$store.commit('setCpesList', response.data.data.itemslist);
          } else if (response.data.status === 'error') {
            ctx.isDisableCpes = false;
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          ctx.isDisableCpes = false;
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },
  getCPEsbyLocationV2ForUseInMarketing(ctx) {
    const cancelToken = Vue.axios.CancelToken.source();
    const requestName = 'getCPEsbyLocationV2ForUseInMarketing';
    const componentName = ctx.$route.name;
    ctx.$store.commit('clearAndCancelRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
    ctx.$store.commit('addRequestCancelTokenToRequestsCancelTokens', { componentName, requestName, cancelToken })
    ctx.isDisableCpes = true;
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes/compact`, {
        action: 'R',
        location: ctx.filters.location.id,
        with_childs: ctx.filters.location.id ? ctx.filters.with_childs : true,
        sort_by: {
          // field: 'connected',
          field: 'config.lbs_config.enabled',
          order: -1
        }
      }, {cancelToken: cancelToken.token })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.isDisableCpes = false;
            ctx.$store.commit('setCpesList', response.data.data.itemslist);
          } else if (response.data.status === 'error') {
            ctx.isDisableCpes = false;
            VueNotifications.error({message: response.data.description});
          }
          ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
        },
        (err) => {
          ctx.isDisableCpes = false;
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
          if (Vue.axios.isCancel(err)){
            console.log(`${requestName} request for ${componentName} is canceled`)
          }else{
            VueNotifications.error({ message: err });
          }

        }
          ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
          ctx.error = err;
        }
      );
  },

  getDashboardVisitors(ctx) {
    ctx.$store.commit('enableLoadingVisitors');

    const params = {
      start: moment().startOf('day').subtract(1, 'days').unix(),
      stop: moment().endOf('day').subtract(1, 'days').unix(),
      timeout: 600,
      period: 'h',
      location: ctx.$store.state.baseLocation,
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };
    if (ctx.$store.state.inHeaderLocationSelectModeEnabled ){
     params.location = ctx.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
     params.with_childs = ctx.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
     if(!params.location) {
       params.location = undefined;
       params.with_childs = true;
     }
    }

    ctx.VisitorsChart.series[0].data = [];
    ctx.VisitorsChart.series[1].data = [];

    Vue.axios.get(`${window.RESTAPI || ''}/apiv2/summary`, { params }).then(
      (response) => {
        ctx.$store.commit('disableLoadingVisitors');
        if (response.data.status === 'success') {
          ctx.$store.commit('setSummary', response.data.data.items);

          const data = response.data.data.items;

          ctx.visits = data.visits;
          ctx.visitors = data.visitors;
          ctx.rate = data.rate;
          ctx.duration = data.duration;
          ctx.duration_avg = data.duration_avg;

          for (const date in data.period.all) {
            let dateData;
            let time;
            if (params.period === 'h') {
              time = new Date(date * 1000);
              dateData = Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              );
            } else if (params.period === 'd') {
              time = new Date((date - 10801) * 1000);
              dateData = Date.UTC(time.getFullYear(), time.getMonth(), time.getDate());
            } else if (params.period === 'm') {
              time = new Date(date * 1000);
              dateData = Date.UTC(time.getFullYear(), time.getMonth());
            }

            // ctx.VisitorsChart.series[0].data.push([dateData, data.period.all[date] - data.period.new[date]]);
            //
            // ctx.VisitorsChart.series[1].data.push([dateData, data.period.new[date]]);

            const allForDate = data.period.all[date];
            const newForDate = data.period.new[date] || 0;
            const repeatForDate = allForDate - newForDate;

            ctx.VisitorsChart.series[0].data.push([dateData, repeatForDate]);
            ctx.VisitorsChart.series[1].data.push([dateData, newForDate]);

          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('disableLoadingVisitors');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('disableLoadingVisitors');
      }
    );
  }
};
