const Radius = () => import(/* webpackChunkName: "Radius" */ '../views/Radius/Radius');
const RadiusWizard = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusWizard');
const RadiusStep1 = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusStep1');
const RadiusStep2 = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusStep2');
const RadiusStep3 = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusStep3');

const RadiusRoute = {
  path: 'radius',
  name: 'Radius',
  component: Radius,
  children: [
    {
      path: 'radius-wizard',
      name: 'radius-wizard',
      redirect: 'radius-wizard/radius-step-1',
      component: RadiusWizard,
      children: [
        {
          path: 'radius-step-1',
          name: 'radius-step-1',
          component: RadiusStep1
        },
        {
          path: 'radius-step-2',
          name: 'radius-step-2',
          component: RadiusStep2
        },
        {
          path: 'radius-step-3',
          name: 'radius-step-3',
          component: RadiusStep3
        }
      ]
    }
  ]
};

export default RadiusRoute;
