import store from '../store';

export default {
  showLocationPartyFromLocationId(id) {
    try {
      const locationsList = store.state.locationsList || [];
      let locationParty;
      locationsList.forEach((location) => {
        if (location.id === id) {
          locationParty = location.party;
        }
      });
      return locationParty;
    } catch (e) {
      console.log(e);
      return [];
    }
  },
  showLocationPartyFromLocationName(name) {
    try {
      const locationsList = store.state.locationsList || [];
      let locationParty;
      locationsList.forEach((location) => {
        if (location.name === name) {
          locationParty = location.party;
        }
      });
      return locationParty;
    } catch (e) {
      console.log(e);
      return [];
    }
  },
  showLocationFromId(id) {
    const locationsList = store.state.locationsList || [];
    let locationName;
    locationsList.forEach((location) => {
      if (location.id === id) {
        locationName = location.name;
      }
    });
    return locationName;
  },
  showLocationWithCompanyNameFromId(id) {
    const locationsList = store.getters.locationsListSorted || [];
    let locationName;
    locationsList.forEach((location) => {
      if (location.id === id) {
        locationName = location.name;
      }
    });
    return locationName;
  },
  locationObjbyId(id) {
    const locationsList = store.state.locationsList || [];
    let locationObj;
    locationsList.forEach((location) => {
      if (location.id === id) {
        locationObj = location;
      }
    });
    return locationObj;
  },

  locationsObjbyIds(ids, withCompanyName = false) {
    let locationsIds = [];
    if (ids === '' || ids === false || ids === undefined) {
      locationsIds = [];
    } else {
      locationsIds = JSON.parse(JSON.stringify(ids));
    }
    let locationsList = [];
    if (withCompanyName !== true) {
      locationsList = store.getters.locationsListSortedWithoutCompanyName || [];
    } else {
      locationsList = store.getters.locationsListSorted || [];
    }
    const locationsObj = [];
    locationsList.forEach((location) => {
      if (locationsIds.includes(location.id)) {
        locationsObj.push(location);
      }
    });
    return locationsObj;
  },

  userNameById(id) {
    const usersList = store.state.usersList || [];
    let userObj;
    usersList.forEach((user) => {
      if (user.id === id) {
        userObj = user;
      }
    });
    if (userObj) {
      return userObj.username;
    }
    return '';
  },
  userObjById(id) {
    const usersList = store.state.usersList || [];
    let userObj;
    usersList.forEach((user) => {
      if (user.id === id) {
        userObj = user;
      }
    });
    if (userObj) {
      return userObj;
    }
    return null;
  },

  cpeNamebyId(id) {
    const cpesList = store.state.cpesList || [];
    let foundCpe = '';

    for (const cpe of cpesList) {
      if (cpe.id === id) {
        foundCpe = cpe.name ? cpe.name : cpe.model.name ? cpe.model.name : 'no data';
        break;
      }
    }

    return foundCpe;
  },
  cpeObjbyId(id) {
    const cpesList = store.state.cpesList || [];
    let foundCpe;

    for (const cpe of cpesList) {
      if (cpe.id === id) {
        foundCpe = cpe;
        break;
      }
    }

    return foundCpe;
  },
  locationObjbyLocId(id) {
    const locationsList = store.state.locationsList || [];
    let foundLocation;

    for (const location of locationsList) {
      if (location.loc_id === id) {
        foundLocation = location;
        break;
      }
    }

    return foundLocation;
  },
  wlanNamebyId(id) {
    const wlansList = store.state.wlansList || [];
    let foundWlan = '';

    for (const wlan of wlansList) {
      if (wlan.id === id) {
        foundWlan = wlan.ssid ? wlan.ssid : 'no data';
        break;
      }
    }

    return foundWlan;
  },
  wlanObjbyId(id) {
    const wlansList = store.state.wlansList || [];
    let foundWlan;

    for (const wlan of wlansList) {
      if (wlan.id === id) {
        foundWlan = wlan;
        break;
      }
    }

    return foundWlan;
  },
  cpeNameForStat(id) {
    let findCPE = id;
    const cpesList = store.state.cpesList || [];
    cpesList.forEach((cpe) => {
      if (cpe.id === id) {
        findCPE = cpe.name || cpe.model.name;
      }
    });
    return findCPE;
  },
  modelObjbyId(id) {
    const modelList = store.state.cpeModelsList || [];
    let modelObj;
    modelList.forEach((model) => {
      if (model.id == id) {
        modelObj = model;
      }
    });
    return modelObj;
  },
  wlanNameForStat(id) {
    let findWLAN = id;
    const wlansList = store.state.wlansList || [];

    wlansList.forEach((wlan) => {
      if (wlan.id === id) {
        findWLAN = wlan.ssid;
      }
    });
    return findWLAN;
  },

  firewallNamebyId(id) {
    const firewallsList = store.state.firewallsList || [];
    let firewallName = id;

    for (const firewall of firewallsList) {
      if (firewall.id === id) {
        firewallName = firewall.name;
        break;
      }
    }
    return firewallName;
  },

  controllerNamebyId(id) {
    const controllersList = store.state.controllersList || [];
    let name = '';

    for (const controller of controllersList) {
      if (controller.id === id) {
        name = controller.name;
        break;
      }
    }
    return name;
  },

  hotspotNamebyId(id) {
    const hotspotsList = store.state.hotspotsList || [];
    let hotspotName = '';

    for (const it of hotspotsList) {
      if (it.id === id) {
        hotspotName = it.name;
        return hotspotName;
      }
    }
  },

  clientObjbyId(id) {
    const clientsList = store.state.clientsList || [];
    let clientObj;
    clientsList.forEach((client) => {
      if (client.id == id) {
        clientObj = client;
      }
    });
    return clientObj;
  },

  getRrmById(id) {
    const rrmList = store.state.rrmList || [];
    let rrmObj;
    for (const rrm of rrmList) {
      if (rrm.id === id) {
        rrmObj = rrm;
        return rrmObj.name;
        break;
      }
    }
    return 'Not found';
  },

  radiusObjbyId(id) {
    const radiusList = store.state.radiusList || [];
    let radiusObj;
    radiusList.forEach((radius) => {
      if (radius.id === id) {
        radiusObj = radius;
      }
    });
    return radiusObj;
  },

  radiusNamebyId(id) {
    const radiusList = store.state.radiusList || [];
    let radiusName;
    radiusList.forEach((radius) => {
      if (radius.id === id) {
        radiusName = radius.name;
      }
    });
    return radiusName;
  },

  templateNameById(id) {
    const templateList = store.state.cpeTemplatesList || [];
    let templateName;
    templateList.forEach((template) => {
      if (template.id === id) {
        templateName = template.name;
      }
    });
    return templateName || 'No name';
  },

  cutName(name) {
    if (!name) {
      return;
    }
    let cutName = name;
    if (cutName.length > 20) {
      cutName = cutName.substring(0, 15);
      cutName += '...';
    }
    return cutName;
  },

  ruleNamebyId(id) {
    const rulesList = store.state.rulesList || [];
    let findRule;
    rulesList.forEach((rule) => {
      if (rule.id === id) {
        findRule = rule;
      }
    });
    return findRule;
  },
  redirectNamebyId(id) {
    const redirectsList = store.state.redirectsList || [];
    let findRedirect;
    redirectsList.forEach((redirect) => {
      if (redirect.id === id) {
        findRedirect = redirect.name;
      }
    });
    return findRedirect;
  },
  formatBytes(bytes, decimals) {
    if (bytes == 0) return '0 Bytes';
    const k = 1000;

    const dm = decimals || 2;

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}\u00A0${sizes[i]}`;
  },
  formatBytesWithI18N(bytes, decimals) {
    const isRULocale = localStorage.getItem('NMS_LOCALE') === 'ru' || localStorage.getItem('NMS_LOCALE') === 'RU'
    if (bytes == 0) {
      if (isRULocale) {
        return '0 Байт';
      } else {
        return '0 Bytes';
      }

    }
    const k = 1000;

    const dm = decimals || 2;

    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (isRULocale) {
      sizes = ['Байт', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}\u00A0${sizes[i]}`;
  }
};
