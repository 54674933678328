<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 mb-xs mb-2">{{ $t('usersDemoWizard.step1Header') }}</div>
          <form action="">
            <div class="form-group">
              <label for="path" class="required-mark">{{ $t('location.path') }}</label>
              <input
                id="path"
                name="location"
                type="text"
                class="input form-control root-location"
                aria-describedby="locationCaption"
                :class="{ 'is-danger': errors.has('location') }"
                v-model="newLocation.name"
                v-validate="'required|location'"
                data-vv-validate-on="input"
                :data-vv-as="$t('location.path')"
              />
              <small id="locationCaption" class="text-muted location-help">
                {{ $t('location.descendant') }}
              </small>
              <!--              <span-->
              <!--                v-tooltip.top-center="{ content: errors.first('location') }"-->
              <!--                v-show="errors.has('location')"-->
              <!--                class="help is-danger error_tooltip"-->
              <!--              >-->
              <!--                <i class="fa fa-warning text-error" />-->
              <!--              </span>-->
              <span v-show="errors.has('location')" class="help is-danger">
                {{ errors.first('location') }}
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <button type="button" class="btn btn-outline-secondary" @click="prevStep">
                  {{ $t('general.back') }}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="handleSaveLocation"
                  :disabled="errors.any()"
                >
                  {{ $t('general.next') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
// import VueNotifications from 'vue-notifications';
import locationService from '../../services/locationService';
import UsersDemoWizardHelpers from './UsersDemoWizardHelpers';

export default {
  name: 'UsersDemoWizardStep1',
  data() {
    return {
      location: {},
      newLocation: {
        name: '',
        manager: '',
        description: '',
        id: '',
        address: '',
        coords: {
          lat: 0,
          lng: 0
        },
        custom_styles_enable: false,
        custom_styles: {
          bodybackgroundcolor: '',
          // bodycolor: '',
          headerbackgroundcolor: '',
          headercolor: '',
          footerbackgroundcolor: '',
          sidebarbackgroundcolor: '',
          sidebarbackgroundactivelink: '',
          sidebarbackgroundhoverlink: '',
          sidebarcolor: ''
        },
        custom_logo: ''
      }
    };
  },
  computed: {},
  methods: {
    getLocations() {
      locationService.getLocations(this);
    },
    handleSaveLocation() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.createLocation(this.newLocation);
        }
      });
    },
    createLocation(location) {
      // console.log('creating loc');
      locationService.createLocationFromUsersDemoWizard(this, location);
    },
    prevStep() {
      // this.$router.push({ name: 'Users' });
      UsersDemoWizardHelpers.goToPrevStep(this, 'UsersDemoWizardStep1');
    },
    nextStep() {
      // this.$parent.nextStep();
      this.getLocations();
      // this.$router.push({ name: 'UsersDemoWizardStep2' });
      UsersDemoWizardHelpers.goToNextStep(this, 'UsersDemoWizardStep1');
    },
    exitFromWizard() {
      // this.$router.push({ name: 'Users' });
      UsersDemoWizardHelpers.exitFromWizard(this);
    }
  },
  created() {}
};
</script>

<style lang="scss"></style>
