const support = {
  header: 'Description of the Platform',
  glossary: {
    header: 'Glossary',
    AP: 'AP — Access Point',
    SOFTWARE: 'SOFT — software',
    BSSID: 'BSSID — Basic Service Set IDentifier (MAC address of the network adapter)',
    // CPE: 'CPE — Customer Premises Equipment (telecommunication equipment located at the subscriber or client premises)',
    // CPE: 'AP — Access Point (telecommunication equipment located at the subscriber or client premises)',
    CPU: 'CPU — Central Processing Unit',
    DHCP:
      'DHCP — Dynamic Host Configuration Protocol (protocol responsible for dynamically issuing ip-addresses to network devices',
    DNS: 'DNS — Domain Name System',
    HTTP: 'HTTP — HyperText Transfer Protocol',
    HTTPS: 'HTTPS — HyperText Transfer Protocol Secure (an HTTP protocol extension that supports encryption)',
    IP: 'IP — Internet Protocol Address (unique network address of a node in a computer network)',
    MAC: 'MAC — Media Access Control (unique identifier assigned to each piece of network equipment)',
    NAS: 'NAS — Network Attached Storage (file-level storage server)',
    NAT: 'NAT — Network Address Translation',
    NMS: 'NMS — Network Management System',
    OS: 'OS — Operating System',
    RADIUS: 'RADIUS — Remote Authentication Dial-In User Service',
    RRM: 'RRM — Radio Resource Management (control of radio transmission characteristics in the system)',
    RSSI: 'RSSI — Received Signal Strength Indicator (total power of the signal received by the receiver)',
    SSID: 'SSID — Service Set Identifier (the symbolic name of the wireless Wi-Fi access point)',
    URL: 'URL — Uniform Resource Locator',
    UUID: 'UUID — Universally Unique Identifier',
    VLAN:
      'VLAN — Virtual Local Area Network (local computer network, which is a group of hosts with a common set of requirements)',
    WLAN: 'WLAN — Wireless Local Area Network (local area network built on the basis of wireless technologies)',
    WPA: 'WPA — Wi-Fi Protected Access (wireless certification program)'
    // WSP: 'WSP — WiMark Systems Platform',
    // WSFW: 'WSFW — WiMark Systems Firmware',
    // WSNMS: 'WSNMS — WiMark Systems Network Management System',
    // WSCPEA: 'WSCPEA — WiMark Systems Customer Premises Equipment Agent'
  },
  nms: {
    locationsHeader: 'System',
    dashboardHeader: 'Dashboard',
    dashboardMain: 'The Dashboard gives information about:',
    dashboard1: "the servers' characteristics (CPU, CPU load, memory);",
    dashboard2: 'events summary 24 hours (level, type, object, status, time of access points);',
    dashboard3: 'the status and characteristics of the overloaded and most active access points;',
    dashboard4: 'the number of new and repeated connections, visits;',
    dashboard5: 'about the devices that were signed in.',

    nmsHeader: 'NMS',
    nmsMain: 'The network management platform includes:',
    nms1: 'the system information about statistics on locations, access points and clients;',
    nms2:
      'events summary 24 hours, rules, connections of access points (model, name, operating time, location), clients, distance of users;',
    nms3: 'the system level management of radio resources (RRM);',
    nms4:
      'information about WiFi networks (name (SSID), security, number of access points, clients, virtual local area networks (VLAN));',
    nms5: 'a networking protocol that provides centralized authentication and authorization (RADIUS);',
    nms6:
      'control and filtering of network traffic passing through the network in accordance with the specified rules (Firewall);',
    nms7:
      'Hotspot 2.0 technology, which allows you to automatically authenticate and gain access to the Wi-Fi network by sending a request to an access point;',
    nms8: 'guest control (allows you to set redirect rules for portals).',

    statistics: 'Statistics',
    systemHeader: 'System',
    systemMain1: 'It collects data from each location. The location combines all APs.',
    systemMain2: 'You can see how much traffic each location consumes, the number of clients, CPU and memory usage.',

    cpesHeader: 'APs',
    cpesMain1: 'Here you will find more details of each AP.',
    cpesMain2:
      'For each characteristic (traffic, number of clients, processor and memory load) there is data for a certain time.',
    wlansHeader: 'WLANs',
    wlansMain:
      'This tab displays information about the WiFi network that is distributed by specific AP - traffic, the number of clients and the location to which it belongs.',

    clientsHeader: 'Clients',
    clientsMain:
      'After the client is connected to the WiFi network, all information about his device will be here - MAC address, IP, distance from the AP, the name of the network to which he is connected (you can see by pressing the show client info button in front of the device), the session time of connecting to a specific router.',
    reportsHeader: 'Reports',
    reportsMain:
      'Here you can configure reports for event, client, access point data, and point aggregate reports (aps_common). And also to download the report by periods. Available formats - JSON, CSV, XLS. For XLS, for aps_common, you can add graphs of a certain value depending on time (load, unload, clients, processor load).',

    monitoring: 'Monitoring',
    eventsHeader: 'Events',
    eventsMain1:
      'The graph shows the events in the last 24 hours, associated with the time and the status of the connected clients.',
    eventsMain2:
      'Also, by clicking on the client, you can view detailed information about the connection session, AP, WiFi network.',

    rulesHeader: 'Rules',
    rulesMain1: 'Each AP can be assigned specific "rules" for connecting users.',
    rulesMain2: 'Conditions are written in Script and connected in the Rules tab.',
    rulesMain3: 'Then you can select the required rule for each AP.',

    cpesConnHeader: 'APs connectivity',
    cpesConnMain:
      'In APs connectivity, you can track the operating time of each point. Filters help you sort by location, date and connection status, which makes monitoring a section very convenient.',

    wifiAnalyzerHeader: 'WiFi Analyzer',
    wifiAnalyzerMain1:
      'This interface scans the access point and gives results of the technical characteristics of its WiFi networks (signal, channel, frequency, offset, width).',
    wifiAnalyzerMain2:
      'Each WiFi network has a schedule. For convenience, you can select all available WLANs or several specific ones.',

    clientDistanceHeader: 'Client Distance',
    clientDistanceMain:
      'You can see how far the client is from the AP. The interface allows you to specify a specific time to find out the position of the connected device with an accuracy of seconds.',
    systemCpesMain1:
      'In this block, you can manage access points - reboot, update software, reset settings and install new ones, create settings templates, as well as all system information about it.',
    systemCpesMain2: 'In the additional settings of the WiFi network, you can generate a report on statistics.',
    SNMPStatHeader: 'SNMP Statistic',
    SNMPStatMain:
      'Allows you to view SNMP statistics obtained from devices configured in the SNMP Monitoring Config section',
    ClientRFHeader: 'Client RF',
    ClientRFMain:
      "Allows you to view the status of the client's network connection on a graph. You can view parameters such as receive rate, baud rate, signal, noise, SNR. You can also view customer ratings by signal quality (you can view SNR average, etc.) ",
    SNMPMonitoringConfigHeader: 'SNMP Monitoring Config',
    SNMPMonitoringConfigMain:
      'Allows you to enable SNMP monitoring for devices, enable monitoring of the agent and its status ',
    RRMHeader: 'RRM',
    RRMMain:
      'RRM - Radio Resource Management - setting up groups to run a patented resource resource management algorithm',
    TemplatesHeader: 'Templates',
    TemplatesMain: 'The section allows you to create templates with settings for their further use on access points ',
    WlansHeader: 'WLANs',
    WlansMain:
      'In this section, you can view and configure Wi-Fi networks. You can configure SSID, network location, security settings, network encapsulation, rate limits and others. For networks with some type of security, it is possible to generate QR to connect to the network ',
    ClientsHeader: 'Clients',
    ClientsMain:
      "In this section, you can view various information about clients connected to the network. For example, the date and time of the first connection, the last connection, the name of the access point and network. It is also possible to view the Health Status of the client, which is scored based on the client's RSSI and SNR ",
    RadiusHeader: 'RADIUS',
    RadiusMain:
      'RADIUS - adding external AAA servers (authentication, authorization and accounting) for organizing a WPA2-Enterprise or SORM network in a WLAN',
    HotspotHeader: 'Hotspot 2.0',
    HotspotMain:
      "Manage Hotspot 2.0 profiles, with which you can connect to closed networks, such as WPA2-Enterprise, using a profile preinstalled on the device, or using the device's SIM card (EAP-SIM, EAP-AKA, EAP-AKA ' )",
    FirewallHeader: 'Firewall',
    FirewallMain:
      'The section allows you to view and edit existing firewall rules and create new ones. Our firewall is based on Iptables. Rules are created in its notation. The documentation for the rules can be found here: ',
    GuestControlHeader: 'Guest Control',
    GuestControlMain:
      'NAS Management - Network Access Server - module responsible for redirecting unauthorized users to the Captive Portal.',
    LicensesHeader: 'Licenses',
    // LicensesMain: 'This section displays information about the current number of access points, the maximum number of access points. It is possible to view statistics on access points, radars and heat maps on a graph for a selected period'
    LicensesMain:
      'This section displays information about the current number of access points, the maximum number of access points. It is possible to view statistics on access points, Wi-Fi target and heat maps on a graph for a selected period'
  },
  portal: {
    portalHeader: 'Portal',
    portalMain:
      'This block contains settings and statistics on authorization portals - special web pages on which users can go through the stages of identification (by SMS or Email) with authorization and displaying ads.   ',
    dashboardHeader: 'Dashboard',
    dashboardMain: 'It is a short summary of the basic data for the Portal section.',
    authHeader: 'Connections, Authentications, Authorizations',
    authMain:
      'Here are statistics of connections, authentication and authorization of new, unique users, browsers, operating systems, types and manufacturers of devices that customers use is located in these sections. All diagrams can be downloaded as images.',
    locationHeader: 'Location',
    locationMain:
      'On the map you can see all locations and descriptions for them: owners, addresses, dates of creation. You can edit each location in a special window.',
    birthdaysHeader: 'Birthdays',
    birthdaysMain: "Here you can find analytics for users' birthdays, their social networks and contacts.",
    adsHeader: 'Advertisement',
    adsMain:
      'Here you can see the statistics of views of advertising and polls, as well as the state of advertising at the moment (showing, planned, etc.).',
    recommendationHeader: 'Recommendations',
    recommendationMain: 'Here are some quick tips on how to add ads and polls to use the platform more efficiently.',

    configuration: 'Configuration',
    profilesHeader: 'Profiles',
    profilesMain1: 'Here you can see all the profiles, by clicking on each you can set certain settings:',
    profilesMain2:
      'For each authorization method, you can configure a session - time, amount, actions that must be performed to access the Internet.',
    profiles1: 'to link a profile to a location;',
    profiles2: 'to set the time zone;',
    profiles3: 'to set working conditions (WLANs, APs);',
    profiles4:
      'to add MAC addresses of users to special access lists - black list (there is no access to the profile), white list (access without authentication and authorization, mainly for devices on which SMS cannot be received), access list (instead of authentication, use a known code or voucher);',
    profiles5:
      'specify the method of authentication (via SMS or Email) and authorization (via social networks, vouchers, etc.)',

    previewHeader: 'Preview',
    previewMain:
      'To view the demo version of the portal, you must select a profile, page and account. Then you can follow the link to the portal and check its work.',

    pagesHeader: 'Pages',
    pagesMain:
      'In this interface, you can customize the visual of the portal page - the position of the logos, background, theme, title.',

    hotelVouchersHeader: 'Hotel vouchers',
    hotelVouchersMain1:
      'To access the Internet without authentication (for example, via SMS) using a special code, you need vouchers.',
    hotelVouchersMain2:
      'All information about available vouchers is displayed on this page - profile, validity time, access code, status (activated or not)',
    hotelVouchersMain3: 'All data can be edited in the window for each voucher.',

    adsAndPollsHeader: 'ADs & Polls',
    adsAndPollsMain1: 'All ad settings are located here.',
    adsAndPollsMain2:
      'You can choose one of the types of advertising for the portal: image, video, poll by topic, poll about user data (for example, specify the date of birth or gender).',
    adsAndPollsMain3: 'In the ad settings, you can specify:',
    adsAndPolls1: 'period of advertising activity;',
    adsAndPolls2: 'priority (the higher the priority, the more often this advertisement will be shown);',
    adsAndPolls3:
      'limit (infinite or limited, after the expiration of the limit, this advertisement will no longer be shown to users);',
    adsAndPolls4: 'total display time and the ability to skip ads;',
    adsAndPolls5: 'display settings by days of the week, time of day;',
    adsAndPolls6: 'showing to certain (all) device models or OS.',

    data: {
      analyticsAndData: 'Analytics & Data',
      accountsHeader: 'Accounts',
      accountsMain:
        'Here are the guests of the Authorization Portals. The guest account is replenished: each guest can have several devices, authorization through different social networks, user sessions. This gives information about birthday and location. All data can be downloaded and saved in PDF, CSV and XLS formats.',
      statisticsHeader: 'Statistics',
      statisticsMain:
        'It contains data on new, repeated users and unique, repeated user connections classified by the type of operating system, device type, languages, manufacturers and, of course, by identification and authorization methods. All data can be downloaded for the period and saved in PDF, CSV and XLS formats.',
      birthdaysMain:
        'Here you can find data about the birthdays of your guests, collected from social networks during login and through surveys. All data can be downloaded in PDF, CSV and XLS.',
      adsAndPollsMain:
        'Here you can see statistics of views by authorization types (free, sponsored, service) in the form of graphs for a specified period of time.',
      portalSessionsHeader: 'Find Session',
      portalSessionsMain:
        'Here you can find data about the sessions of your portal guests. You can find out information about the IP address, OS version, browser and session profile. There is the ability to filter by various parameters',
      portalLocationsStatHeader: 'Locations',
      portalLocationsStatMain:
        'Summary statistics for each of the locations. There is data on clicks, views, visits (first, repeated, unique, all) for each location. It is possible to filter by profile, time interval and locations '
    },

    paidInternet: {
      paidInternetHeader: 'Paid Internet',
      paidInternetMain: 'This block contains the settings for the paid Internet.',

      vouchersHeader: 'Vouchers',
      vouchersMain1: "You can add vouchers with the desired settings - vouchers' validity time and tariff.",
      vouchersMain2:
        'The list shows the status of the voucher, the code for using it, the validity period and the tariff.',

      tariffsHeader: 'Tariffs',
      tariffsMain: 'Here is the update of tariffs and all the settings necessary for it: cost, speed, session length.',

      paymentHeader: 'Payment Systems',
      paymentMain:
        'Here you set up the methods for replenishing the balance: setting the type (external gateway - going to another site for payment, guide - performing a series of actions for payment), image, specifying the merchant - a unique number to provide it to the Payment system supplier.'
    }
  },
  analytics: {
    analyticsHeader: 'Analytics',
    // analyticsMain1:
    //   'The Wi-Fi analytics block contains the results of the analysis of data obtained on the basis of "Wi-Fi radar" - a Wi-Fi radio interface operating in the passive broadcast monitor mode, which collects all connection requests from devices to Wi-Fi networks.',
    analyticsMain1:
      'The Wi-Fi analytics block contains the results of the analysis of data obtained on the basis of "Wi-Fi Target" - a Wi-Fi radio interface operating in the passive broadcast monitor mode, which collects all connection requests from devices to Wi-Fi networks.',
    analyticsMain2: 'Based on this data, we get:',
    analytics1: 'assessment of the passability of a certain point, location;',
    analytics2: 'heat maps;',
    analytics3: 'collecting a list of MAC addresses for uploading to Yandex / myTarget for online targeting.',

    visitorsHeader: 'Visitors',
    visitorsMain1:
      'All customer data collected is reported here. Using filters, you can create a report both for all APs and for specific ones. Based on this report, you can identify repeat visits, duration of visits, customer devices, etc.',
    // visitorsMain2:
    //   'The page contains an estimate of the attendance of the locations in which the radars are installed. A continuous visit is a user visit for 10 minutes. New users are considered relative to the selected period. One user can have multiple visits. It is important to take into account that the data in the analytics is already devoid of noise and random MAC addresses, which makes it possible to more accurately estimate the attendance and not take into account the same users several times.',
    visitorsMain2:
      'The page contains an estimate of the attendance of the locations in which the Wi-Fi Target are installed. A continuous visit is a user visit for 10 minutes. New users are considered relative to the selected period. One user can have multiple visits. It is important to take into account that the data in the analytics is already devoid of noise and random MAC addresses, which makes it possible to more accurately estimate the attendance and not take into account the same users several times.',
    heatmapHeader: 'Heatmap',
    // heatmapMain:
    //   'The heatmap page compares the location data of access points and clients captured by Wi-Fi radar with real maps. Maps allow you to visualize the hottest zones in terms of traffic over the selected period, as well as assess the dynamics.',
    heatmapMain:
      'The heatmap page compares the location data of access points and clients captured by Wi-Fi Target with real maps. Maps allow you to visualize the hottest zones in terms of traffic over the selected period, as well as assess the dynamics.',
    // radarExportHeader: 'Radar Export',
    radarExportHeader: 'Wi-Fi Target',
    // radarExportMain:
    //   'Radar upload is uploading customer data by Email to MyTarget, Yandex Audience for use when displaying ads.'
    radarExportMain:
      'Wi-Fi Target is uploading customer data by Email to MyTarget, Yandex Audience for use when displaying ads.'
  }
};
export default support;
