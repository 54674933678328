<template>
  <section>
    <!--    <div class="loader loader-backdrop" v-if="isVisitorsLoading" />-->

    <div class="card card-accent-info w-100 mb-0">
      <Loader-spinner-small centered v-if="isVisitorsLoading"></Loader-spinner-small>
      <div class="card-header d-flex align-items-center" :class="{'folding-card-header-when-not-open': !isBlockShown }">
        <span class="clickable" @click="toggleBlockDisplaying">
        <span role="button" >
          <i v-tooltip.top-center="{ content: this.$t('general.minimize'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }" v-if="isBlockShown" class="icon-arrow-down icon-padding"></i>
          <i v-tooltip.top-center="{ content: this.$t('general.expand'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }" v-else class="icon-arrow-right icon-padding"></i>
        </span>
        <i class="fa fa-wifi"/>
        <span class="mr-q">{{ $t('dashboard.radar') }}</span>
        </span>
        <span class="text-muted">
          <small>({{ visitorsDate }})</small>
        </span>

        <router-link v-if="$can('read', { route: 'Marketing' })" class="ml-auto custom-padding-for-header" to="/marketing">
          {{ $t('dashboard.toMarketing') }}
        </router-link>
        <div style="position: absolute; top:0px; right: 0.1em">
          <DashboardBlockCloseButton :button-padding-right="'0.7em'"
                                     :button-padding-top="'0.7em'"
                                     :block-section="isCurrentUserMarketer ? 'marketer' : 'general'"
                                     :block-name="'visitors'">
          </DashboardBlockCloseButton>
        </div>

      </div>

      <div class="card-body" v-if="isBlockShown">
        <highcharts :options="VisitorsChart" id="VisitorsChart" class="p-0 w-100" />

        <div class="card-deck">
          <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
            <stats-card :data="visits.all" :data-caption="$t('marketing.cVisits')" class="card-default">
              <template #icon><i class="icon-login" /></template>
            </stats-card>
          </div>

          <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
            <stats-card
              :data="duration_avg.all | moment"
              :data-caption="$t('marketing.avgDwellTime')"
              class="card-returned"
            >
              <template #icon><i class="icon-clock"></i></template>
            </stats-card>
          </div>

          <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
            <stats-card
              :data="isNaN(visits.all / visitors.all) ? '—' : (visits.all / visitors.all).toFixed(2)"
              :data-caption="$t('marketing.avgVisitFreq')"
              class="card-returned"
            >
              <template #icon><i class="icon-fire"></i></template>
            </stats-card>
          </div>

          <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
            <stats-card :data="visitors.all" :data-caption="$t('marketing.uniqueVisitors')" class="card-unique">
              <template #icon><i class="icon-user"></i></template>
            </stats-card>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import marketingService from '../../services/marketingService';
import statsCard from '../Universal/stats-card.vue';
import DashboardBlockCloseButton from './DashboardBlockCloseButton.vue';

export default {
  name: 'DashboardVisitors',
  components: { statsCard, DashboardBlockCloseButton },
   props: {
    isOpened: { type: Boolean, default: true }
    },
  methods: {
    toggleBlockDisplaying(){
      this.isBlockShown = !this.isBlockShown;
      this.$emit('blockDisplayingChange', this.isBlockShown)
    }
  },
  watch: {
    isOpened:{
      handler(val, oldVal) {
        this.isBlockShown = val
      }
    }
  },
  data() {
    return {
      isBlockShown: this.isOpened,
      window,
      VisitorsChart: {
        chart: {
          type: 'column'
        },
        title: {
          text: ' '
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%e. %b',
            year: '%b'
          },
          title: {
            text: `${this.$t('marketing.date')}`
          }
          // labels: {
          //   format: '{value:%H:%M}'
          // },
          // tickInterval: 2 * 1000 * 60 * 60 // 2 hours
        },
        yAxis: {
          min: 0,
          title: {
            text: `${this.$t('marketing.cVisits')}`
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td>{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
          // xDateFormat: '%H:%M:%S %d.%m.%Y'
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        series: [
          {
            name: `${this.$t('marketing.repeat')}`,
            color: 'rgba(255, 143, 7, .8)',
            data: []
          },
          {
            name: `${this.$t('marketing.new')}`,
            color: '#81C784',
            data: []
          }
        ]
      },
      visitorsDate: moment().startOf('day').subtract(1, 'days').format('DD MMM YYYY'),
      visits: {},
      visitors: {},
      rate: {},
      duration: {},
      duration_avg: {}
    };
  },
  computed: {
    isVisitorsLoading() {
      return this.$store.state.loadingVisitors;
    },
    isCurrentUserMarketer() {
      return this.$store.state.userData.role === 'marketer';
    }
  },
  filters: {
    moment(value) {
      let formatted = '-';
      if (value) {
        const duration = moment.duration(value, 'seconds');
        formatted = duration.format('hh:mm:ss');
        return formatted;
      }
    }
  },
  created() {
    marketingService.getDashboardVisitors(this);
  }
};
</script>

<style lang="scss" scoped>
.card-caption {
  margin: 0.25rem 0;

  font-size: 80%;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.custom-padding-for-header {
  padding-right: 0.9em;
  padding-top: 0.3em;
}

</style>
