<template>
  <div>
    <button type="button" class="btn btn-outline-secondary btn-md" @click="generatePasswordAndEmit">
      {{ $t('general.generatePassword') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'PasswordGenerator',
  props: {
    maxSize: {
      type: Number,
      default: 10
    },
    minSize: {
      type: Number,
      default: 8
    },
    characters: {
      type: Array,
      default: () => ['a-z', 'A-Z', '0-9', 'special']
    },
    specialCharacters: {
      type: String,
      // default: '_-#%$&^*@!%'
      default: '_-#$&^*@!'
    }
  },
  methods: {
    generatePasswordAndEmit() {
      const passwordSize = this.getPasswordSize();
      const generatedPassword = this.generatePassword(passwordSize);
      this.$emit('passwordGenerated', generatedPassword);
    },
    getPasswordSize() {
      if (this.minSize === this.maxSize) {
        return this.maxSize;
      }
      // если минимум и максимум длины пароля разные
      // то длина пароля - рандомное число между ними
      return this.getRandomIntInRange(this.minSize, this.maxSize);
    },
    getRandomIntInRange(minInRange, maxinRange) {
      const min = Math.ceil(minInRange);
      const max = Math.floor(maxinRange);
      return Math.floor(Math.random() * (max - min + 1)) + min; // Максимум и минимум включаются
    },
    // generatePassword(passwordSize) {
    //   const charactersArray = this.characters;
    //   let CharacterSet = '';
    //   let password = '';
    //
    //   if (charactersArray.includes('a-z')) {
    //     CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
    //   }
    //   if (charactersArray.includes('A-Z')) {
    //     CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //   }
    //   if (charactersArray.includes('0-9')) {
    //     CharacterSet += '0123456789';
    //   }
    //   if (charactersArray.includes('special')) {
    //     CharacterSet += this.specialCharacters;
    //   }
    //   for (let i = 0; i < passwordSize; i++) {
    //     password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
    //   }
    //   // console.log(CharacterSet);
    //   // console.log(password);
    //   return password;
    // }
    generatePassword(passwordSize) {
      // console.log(passwordSize)
      const charactersArray = this.characters;
      let CharacterSet = '';
      let password = '';

      const lowerCaseSet = 'abcdefghijklmnopqrstuvwxyz';
      const upperCaseSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const digitsSet = '0123456789';

      if (charactersArray.includes('a-z')) {
        CharacterSet += lowerCaseSet;
      }
      if (charactersArray.includes('A-Z')) {
        CharacterSet += upperCaseSet;
      }
      if (charactersArray.includes('0-9')) {
        CharacterSet += digitsSet;
      }
      if (charactersArray.includes('special')) {
        CharacterSet += this.specialCharacters;
      }
      for (let i = 0; i < passwordSize; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }



      // добавляем в пароль по одному символу из каждого необходимого набора, чтобы там гарантировано присутствовали символы всех наборов
      let passwordAsArr = password.split('');
      let addedCharacterCount = 0;
      if (charactersArray.includes('a-z')) {
        addedCharacterCount += 1;
        passwordAsArr.push(lowerCaseSet.charAt(Math.floor(Math.random() * lowerCaseSet.length)))
      }
      if (charactersArray.includes('A-Z')) {
        addedCharacterCount += 1;
        passwordAsArr.push(upperCaseSet.charAt(Math.floor(Math.random() * upperCaseSet.length)))
      }
      if (charactersArray.includes('0-9')) {
        addedCharacterCount += 1;
        passwordAsArr.push(digitsSet.charAt(Math.floor(Math.random() * digitsSet.length)))
      }
      if (charactersArray.includes('special')) {
        addedCharacterCount += 1;
        passwordAsArr.push(this.specialCharacters.charAt(Math.floor(Math.random() * this.specialCharacters.length)));
      }
      // отрубаем от начала пароля добавленое количество символов, чтобы он всё равно вмещался в заданые рамки после добавления символов
      passwordAsArr = passwordAsArr.slice(addedCharacterCount);
      // console.log(passwordAsArr.length);
      passwordAsArr = this.shuffleArray(passwordAsArr);

      password = passwordAsArr.join('')
      return password;
    },
    shuffleArray(array) {
      return array.sort(() => Math.random() - 0.5);
    }
  }
};
</script>

<style scoped lang="scss"></style>
