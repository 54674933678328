<template>
  <div class="row">
    <div>
      <div class="card">
        <loader-spinner v-if="cpesListLoading"></loader-spinner>
        <div class="card-block">
          '
          <div class="h4 mb-xs mb-2">{{ $t('easyInstallWizard.step4Header') }}</div>
          <div class="form-group mt-h">
            <div class="form-group">
              <label for="cpes">{{ $t('easyInstallWizard.step4Hint') }}</label>
              <multiselect
                id="cpes"
                v-model="selectedCpes"
                :multiple="true"
                :placeholder="$t('easyInstallWizard.step4CPESPlaceholder')"
                label="name"
                trackBy="name"
                :options="cpesList || []"
                :hide-selected="true"
                v-validate="''"
                :data-vv-as="$t('general.cpes')"
                name="cpes"
                class="input"
                :class="{ 'is-danger': errors.has('cpes') }"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              ></multiselect>
              <span
                v-tooltip.top-center="{
                  content: errors.first('cpes')
                }"
                v-show="errors.has('cpes')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
          </div>

          <div class="mb-1"></div>
          <!--            colors and themes-->
          <div class="d-flex justify-content-between">
            <div>
              <!--              <button-->
              <!--                type="button"-->
              <!--                class="btn btn-outline-secondary float-left"-->
              <!--                :disabled="$parent.step4SavedProfileObject !== false"-->
              <!--                @click="prevStep"-->
              <!--              >-->
              <!--                {{ $t('general.back') }}-->
              <!--              </button>-->
            </div>
            <div>
              <button
                type="button"
                class="btn btn-outline-primary float-right"
                @click="handleAddWLANtoCPES"
                :disabled="errors.any() || (this.selectedCpes.length === 0 && this.cpesList.length > 0)"
              >
                {{ $t('easyInstallWizard.step4NextButton') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import helpers from '../../helpers';
import cpePollingService from '../../services/cpePollingService';
import EasyInstallWizardHelpers from './EasyInstallWizardHelpers';

export default {
  name: 'EasyInstallWizardstep4',
  components: { Multiselect },
  data() {
    return {
      selectedCpes: []
    };
  },
  computed: {
    cpesListLoading() {
      return this.$store.state.loadingCpes;
    },
    cpesList() {
      return this.$store.state.cpesList;
    },
    wlanId() {
      return this.$store.state.easyInstallWizardStepsData.step1WlanId;
    }
  },
  methods: {
    updateSelectedCpes() {
      // апдейтим выбранные CPE добавляя  туда созданную ранее WLAN
      const selectedWlansArray = [this.wlanId];
      this.$store.commit('toggleMuteCpeEdit', true);
      let updatedItems = JSON.parse(JSON.stringify(this.selectedCpes));
      updatedItems.forEach((item) => {
        if (item.config.wifi) {
          item.config.wifi.forEach((configInterface) => {
            configInterface.wlans = selectedWlansArray;
          });
          item.config.wifi = helpers.transformArrayToObject(item.config.wifi);
        }

        if (item.config.wired) {
          item.config.wired = helpers.transformArrayToObject(item.config.wired);
        }
      });
      updatedItems = helpers.transformArrayToObject(updatedItems);

      const updateCpeQuery = { action: 'U', items: updatedItems };
      // console.log(updateCpeQuery);
      if (this.selectedCpes.length > 0) {
        Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, updateCpeQuery).then(
          (response) => {
            if (response.data.status === 'success') {
              // cpePollingService.hadleOperationResponse(response.data.data.items);
              // перед переходом на следующий шаг запихиваем номера проапдейченых CPE в стор,
              // чтобы пользоватся ими на  следующем шаге
              // но сначала запихивыем их IDшки в отдельный Array
              const updatedCPEs = JSON.parse(JSON.stringify(this.selectedCpes));
              const updatedCPEsIDs = [];
              for (const cpe of updatedCPEs) {
                updatedCPEsIDs.push(cpe.id);
              }
              this.$store.commit('setEasyInstallWizardStep4SavedCPEsWithWLANIDs', updatedCPEsIDs);
              this.nextStep();
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
            this.$store.commit('toggleMuteCpeEdit', false);
          },
          (err) => {
            this.$store.commit('toggleMuteCpeEdit', false);
            VueNotifications.error({ message: err });
          }
        );
      } else {
        this.$store.commit('toggleMuteCpeEdit', false);
        this.$store.commit('setEasyInstallWizardStep4SavedCPEsWithWLANIDs', []);
        this.nextStep();
      }
    },
    getAllCpes() {
      this.$store.commit('toggleLoadingCpes');
      Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, { action: 'R', location: this.$store.getters.getDefaultLocationForRequests(this), with_childs: this.$store.getters.getDefaultWithChildsForRequests(this)  }).then(
        (response) => {
          if (response.data.status === 'success') {
            const itemsList = response.data.data.itemslist;
            itemsList.forEach((cpe) => {
              if (cpe.config.wifi) {
                cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
              }
              if (cpe.config.wired) {
                cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
              }
            });
            this.$store.commit('setCpesList', itemsList);
            this.$store.commit('toggleLoadingCpes');
          } else if (response.data.status === 'error') {
            this.$store.commit('toggleLoadingCpes');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.$store.commit('toggleLoadingCpes');
          this.error = err;
        }
      );
    },
    handleAddWLANtoCPES() {
      this.updateSelectedCpes();
    },
    nextStep() {
      this.$router.push({ name: 'EasyInstallWizardStep5' });
    },
    exitFromWizard() {
      this.$router.push({ name: 'Dashboard' });
    },
    prevStep() {
      this.$router.push({ name: 'EasyInstallWizardStep3' });
    }
  },
  created() {
    // проверяем есть какие шаги пройдены, чтобы знакть куда перенаправлять юзера, если он выходил из визарда
    if (this.$router.currentRoute.name !== EasyInstallWizardHelpers.checkStepForUserReditrect(this)) {
      this.$router.push({ name: EasyInstallWizardHelpers.checkStepForUserReditrect(this) });
    } else {
      this.$store.commit('setCpesList', false);
      if (!this.$store.state.cpesList) {
        this.getAllCpes();
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
