const Hotspot = () => import(/* webpackChunkName: "Hotspot" */ '../views/Hotspot/Hotspot');
const HotspotWizard = () => import(/* webpackChunkName: "HotspotWizard" */ '../views/Hotspot/HotspotWizard');
const HotspotStep1 = () => import(/* webpackChunkName: "HotspotWizard" */ '../views/Hotspot/HotspotStep1');
const HotspotStep2 = () => import(/* webpackChunkName: "HotspotWizard" */ '../views/Hotspot/HotspotStep2');
const HotspotStep3 = () => import(/* webpackChunkName: "HotspotWizard" */ '../views/Hotspot/HotspotStep3');
const HotspotStep4 = () => import(/* webpackChunkName: "HotspotWizard" */ '../views/Hotspot/HotspotStep4');

const HotspotRoute = {
  path: 'hotspot',
  name: 'Hotspot',
  component: Hotspot,
  children: [
    {
      path: 'wizard',
      name: 'hotspot-wizard',
      redirect: 'wizard/step-1',
      component: HotspotWizard,
      children: [
        {
          path: 'step-1',
          name: 'hotspot-step-1',
          component: HotspotStep1
        },
        {
          path: 'step-2',
          name: 'hotspot-step-2',
          component: HotspotStep2
        },
        {
          path: 'step-3',
          name: 'hotspot-step-3',
          component: HotspotStep3
        },
        {
          path: 'step-4',
          name: 'hotspot-step-4',
          component: HotspotStep4
        }
      ]
    }
  ]
};

export default HotspotRoute;
