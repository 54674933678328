<template>
  <navbar v-bind:class="customNavbarZIndex">
    <button class="navbar-toggler mobile-sidebar-toggler hidden-lg-up" type="button" @click="mobileSidebarToggle">
      &#9776;
    </button>
    <div>
      <router-link
        v-if="window.CUSTOM_BUILD"
        :class="{
          'custom-logo': window.CUSTOM_BUILD !== 'WIMARK',
          'custom-logo-beeline': window.CUSTOM_BUILD === 'BEELINE-WIMARK' && customLogoForUserLocation === '',
          'custom-navbar-barnd-for-location': customLogoForUserLocation !== ''
        }"
        :to="headerLink"
        class="navbar-brand"
        :style="headerLinkImg"
      ></router-link>
    </div>
    <ul class="nav navbar-nav hidden-md-down">
      <li class="nav-item">
        <a class="nav-link navbar-toggler sidebar-toggler" href="#" @click="sidebarToggle">&#9776;</a>
      </li>
    </ul>
    <ul v-if="!inHeaderLocationSelectModeEnabled" class="nav navbar-nav nav-info">
      <li class="nav-item" v-if="systemInfo && systemInfo.uptime">
        <i class="fa fa-gear fa-spin fa-spin--slow text-muted mr-q"></i>
        <small class="text-muted">{{ systemInfo.uptime | momentnow }}</small>
      </li>
    </ul>
    <ul v-else>
      <div class="d-flex flex-column justify-content-center h-100 location location-selector mr-h">
        <LocationSelectorInHeader></LocationSelectorInHeader>
      </div>
    </ul>
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <theme-switcher></theme-switcher>
      </li>
      <li>
        <div class="locale-switcher">
          <locale-switcher></locale-switcher>
        </div>
      </li>
    </ul>
    <view-tooltip class="ml-auto" />

    <ul class="nav navbar-nav mx-1">
      <dropdown size="nav" class="nav-item">
        <span slot="button">
          <a class="nav-link mr-2" :class="{ 'mr-3': isDisable }" href="#">
            <i class="icon-refresh" v-if="refreshInterval === '0' || !refreshInterval"></i>
            <i class="fa fa-spin icon-refresh" v-else></i>
            <span class="badge badge-pill badge-default" v-if="refreshInterval === '0' || !refreshInterval">
              {{ $t('header.off') }}
            </span>
            <span class="badge badge-pill badge-info" v-else>{{ refreshInterval }} {{ $t('header.sec') }}</span>
          </a>
        </span>
        <div slot="dropdown-menu" class="refresh dropdown-menu dropdown-menu-right">
          <div class="dropdown-header text-center">
            <strong>{{ $t('header.setRefreshInterval') }}</strong>
            <p class="p-0 m-0">
              <small>
                ( {{ $t('header.max') }} - 600 {{ $t('header.sec') }}, {{ $t('header.off') }} - 0
                {{ $t('header.sec') }} )
              </small>
            </p>
          </div>
          <div class="refresh-block dropdown-header ">
            <div class="refresh-item">
              <div class="form-group m-0">
                <div class="input-group input-group-sm">
                  <label for="refresh-interval" class="hidden"></label>
                  <input
                    type="text"
                    @keyup.13="setRefreshInterval"
                    name="refresh-interval"
                    @focus="refreshIntervalModal = true"
                    @blur="refreshIntervalModal = false"
                    v-model="newRefreshInterval"
                    id="refresh-interval"
                    :placeholder="$t('header.inputIntervalPlaceholder')"
                    class="form-control"
                    v-validate="'required|numeric|max_value:600'"
                    :class="{ input: true, 'is-danger': errors.has('refresh-interval') }"
                    data-vv-validate-on="input"
                  />
                  <span class="text-success input-group-addon" @click="setRefreshInterval">
                    <i class="fa fa-check"></i>
                  </span>
                  <span class="input-group-addon" @click="removeRefreshInterval">
                    <i class="fa fa-stop-circle"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dropdown>

      <dropdown size="nav" class="nav-item" v-if="!isDisable">
        <span slot="button">
          <a class="nav-link" href="#">
            <i class="icon-bell"></i>
            <span class="badge badge-pill badge-danger" v-if="getFullCurrentOperations.length">
              {{ getFullCurrentOperations.length }}
            </span>
          </a>
        </span>
        <div slot="dropdown-menu" class="operations dropdown-menu dropdown-menu-right">
          <div class="dropdown-header text-center">
            <p class="p-0 mb-q">
              <strong class="w-100 d-flex justify-content-evenly align-items-center">
                <span v-if="isRussianLocale" class="mr-q">
                  {{ $t('header.current') }} {{ $t('header.operations') }} {{ getFullOperations.length }}
                </span>
                <span v-else class="mr-q">
                  {{ $t('header.current') }} {{ getFullOperations.length }} {{ $t('header.operations') }}
                </span>
                <i
                  class="fa fa-warning text-muted operaions-filter"
                  @click.prevent="errorOperationsFilter = !errorOperationsFilter"
                  v-if="!errorOperationsFilter"
                  v-tooltip.top-center="{ content: $t('header.tooltipShowErrorsOnly') }"
                ></i>
                <i
                  class="fa fa-warning text-danger operaions-filter"
                  @click.prevent="errorOperationsFilter = !errorOperationsFilter"
                  v-else
                  v-tooltip.top-center="{ content: $t('header.tooltipShowAll') }"
                ></i>
                <i
                  class="fa fa-close text-danger operaions-filter ml-h"
                  @click.prevent="deleteAllOperations()"
                  v-if="getFullOperations.length"
                  v-tooltip.top-center="{ content: $t('header.tooltipDeleteAll') }"
                ></i>
              </strong>
            </p>
            <p class="m-0 p-0 small">
              <span class="mr-q">
                {{ $t('header.total') }}:
                <span class="text-info">
                  {{ operationsTotal }}
                </span>
              </span>
              <span class="mr-q">
                {{ $t('header.pending') }}:
                <span class="text-warning">
                  {{
                    getFullOperations.filter((operation) => {
                      return operation.status === 'pending';
                    }).length
                  }}
                </span>
              </span>
              <span class="mr-q">
                {{ $t('header.success') }}:
                <span class="text-success">
                  {{
                    getFullOperations.filter((operation) => {
                      return operation.status === 'success';
                    }).length
                  }}
                </span>
              </span>
              <span>
                {{ $t('header.errors') }}:
                <span class="text-danger">
                  {{
                    getFullOperations.filter((operation) => {
                      return operation.status === 'error';
                    }).length
                  }}
                </span>
              </span>
            </p>
          </div>

          <div class="operations-block">
            <Loader-spinner-small v-if="loadingOperations" centered></Loader-spinner-small>
            <div class="operations-list">
              <transition-group name="list" tag="div">
                <div
                  v-for="operation in errorOperationsFilter ? getErrorsOperations : getFullOperations"
                  :key="operation.id"
                  class="operation-item"
                  :class="{
                    'operation-item--error': operation.status === 'error',
                    'operation-item--pending': operation.status === 'pending'
                  }"
                >
                  <div class="operation-info w-100">
                    <ul class="list-unstyled">
                      <li>
                        <i
                          class="fa mr-q"
                          :class="{
                            'fa-circle-o-notch fa-spin text-info': operation.status === 'pending',
                            'fa-check text-success': operation.status === 'success',
                            'fa-stop-circle-o text-danger': operation.status === 'error'
                          }"
                        ></i>
                        <small>
                          <strong>{{ getOperationType(operation.type) }}</strong>
                        </small>
                        <i
                          class="fa fa-close text-danger operaions-filter ml-h float-right"
                          @click.prevent="deleteAllOperations(operation.id)"
                          v-if="operation.id"
                          v-tooltip.top-center="{ content: $t('header.tooltipDelete') }"
                        ></i>
                      </li>
                      <li v-if="operation.status === 'error' || operation.status === 'pending'" class="font-italic">
                        <small>{{ operation.description }}</small>
                      </li>
                      <li class="mb-q text-muted">
                        <small>
                          <i class="fa fa-clock-o"></i>
                          {{ operation.insert_time | moment }}
                        </small>
                      </li>
                      <li>
                        <small>
                          <strong>{{ $t('header.type') }}: {{ operation.model.toUpperCase() }}</strong>
                        </small>
                        <ul class="operation-models list-unstyled">
                          <li
                            v-for="obj in getName(operation.model, operation.model_ids)"
                            v-tooltip.top-center="{ content: obj.id,
                            popperOptions: {
                              modifiers: {
                                preventOverflow: {
                                  boundariesElement: 'window'
                                }
                              }
                            }
                            }"
                            :key="obj.id"
                            class="tooltip-text mr-h small"
                            :class="{ 'text-muted': obj.name === 'No data' }"
                          >
                            {{ obj.name }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </transition-group>
              <div class="operation-item" v-if="errorOperationsFilter && !getErrorsOperations.length">
                <div class="operation-info">
                  <ul class="list-unstyled">
                    <li class="text-muted small">{{ $t('misc.no_errors') }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dropdown>

      <dropdown size="nav" class="nav-item nav-user d-flex">
        <span slot="button" class="d-flex">
          <!-- <small slot="before" class="role">{{  $t(`header.role.${currentUserData.role}`)}}</small> -->
          <img :src="currentUserData.photo" class="img-avatar" alt />
          <div class="profile">
            <span class="profile__name hidden-md-down">{{ currentUserData.username }}</span>
            <small class="profile__role hidden-md-down">
              <i class="fa fa-user"></i>
              {{ $t(`general.role.${currentUserData.role}`) }}
            </small>
          </div>
        </span>
        <div slot="dropdown-menu" class="dropdown-menu dropdown-menu-right dropdown-menu-in-header">
          <div class="dropdown-header text-center">
            <strong>{{ $t('header.account') }}</strong>
          </div>
          <router-link class="dropdown-item" routerLink :to="'/settings'">
            <i class="fa fa-wrench"></i>
            {{ $t('header.settings') }}
          </router-link>
          <a class="dropdown-item dropdown-menu-in-header-logout" href="#" @click="logout">
            <i class="fa fa-lock"></i>
            {{ $t('header.logout') }}
          </a>
        </div>
      </dropdown>
      <dropdown size="nav" class="nav-item nav-user d-flex" v-if="isSubordinateLogin">
        <span slot="button" class="d-flex">
          <button type="button" class="btn btn-primary ml-1 logout-as-subordinate-button" @click="logoutAsSubordinate">
            {{ $t('users.logoutAsSubordinateForButton') }} {{ bossUserUsername }}
          </button>
        </span>
      </dropdown>
    </ul>
  </navbar>
</template>
<script>
import Vue from 'vue';
import { dropdown } from 'vue-strap';
import moment from 'moment';
import momentFormat from 'moment-duration-format';
import auth from '../auth/auth';
import operationsService from '../services/operationsService';
import commonService from '../services/commonService';
import radiusService from '../services/radiusService';
import redirectsService from '../services/redirectsService';
import firewallService from '../services/firewallService';
import ViewTooltip from './view-tooltip.vue';
import navbar from './Navbar.vue';
import ThemeSwitcher from './ThemeSwitcher.vue';
import locationService from '../services/locationService';
import helpers from '../helpers';
import LocaleSwitcher from './locale-switcherNew.vue';
import LocationSelectorInHeader from './LocationSelectorInHeader.vue'

Vue.use(require('vue-moment'));

export default {
  name: 'VHeader',
  components: {
    LocaleSwitcher,
    navbar,
    dropdown,
    ViewTooltip,
    ThemeSwitcher,
    LocationSelectorInHeader
  },
  data() {
    return {
      refreshInterval: '',
      newRefreshInterval: '',
      updatedUserData: {},
      operations: [],
      operationsSuccess: 0,
      operationsError: 0,
      wlanOperations: [],
      cpeOperations: [],
      radiusOperations: [],
      firewallOperations: [],
      redirectOperations: [],
      controllerOperations: [],
      showAllOperations: false,
      refreshIntervalModal: false,
      showId: false,
      errorOperationsFilter: false
    };
  },
  computed: {
    inHeaderLocationSelectModeEnabled() {
      return this.$store.state.inHeaderLocationSelectModeEnabled
    },
    isRussianLocale() {
      if (localStorage.getItem('NMS_LOCALE') === 'ru' || localStorage.getItem('NMS_LOCALE') === 'RU') {
        return true;
      }
      return false;
    },
    // во время разработки тут в реальные данные подмешивались нужные для разрабокти,
    // тепреь просто прокидываются реальные данные о локациях
    locationsWithCustomLogo() {
      // const locations = JSON.parse(JSON.stringify(this.$store.state.locationsItems));
      // return locations;
      return this.$store.state.locationsItems;
    },
    // во время разработки тут в реальные данные подмешивались нужные для разрабокти,
    // тепреь просто прокидываются реальные данные о локациях
    locationsWithCustomCss() {
      // const locations = JSON.parse(JSON.stringify(this.$store.state.locationsItems));
      // return locations;
      return this.$store.state.locationsItems;
    },
    customNavbarZIndex() {
      const pageName = this.$route.name;
      let classObject = {};
      const need99998Zindex = (pageName === 'RRM') ? true : false;
      if (need99998Zindex) {
        classObject = {
          'custom-navbar-z-index-for-correct-location-selector-show-99998': true
        };
      } else {
        classObject = {
          'custom-navbar-z-index-for-correct-location-selector-show-99999': true
        };
      }

      return classObject;
    },

    customLogoForUserLocation() {
      if (localStorage.getItem('userData')) {
        const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
        // console.log(userBaseLocation)
        if (this.locationsWithCustomLogo[userBaseLocation]) {
          if (this.locationsWithCustomLogo[userBaseLocation].custom_logo) {
            return this.locationsWithCustomLogo[userBaseLocation].custom_logo;
          }
          return '';
        }
        return '';
      }
      return '';
    },

    customCSSVarsForUserLocation() {
      if (localStorage.getItem('userData')) {
        const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
        // console.log(userBaseLocation)
        if (this.locationsWithCustomCss[userBaseLocation]) {
          if (this.locationsWithCustomCss[userBaseLocation].custom_styles_enable) {
            if (this.locationsWithCustomCss[userBaseLocation].custom_styles) {
              return this.createCustomStyleAsText(this.locationsWithCustomCss[userBaseLocation].custom_styles);
            }
            return '';
          }
          return '';
        }
        return '';
      }
      return '';
    },

    isPortalOnly() {
      if (window.PORTAL_ONLY) {
        return true;
      }
      return false;
    },
    headerLinkImg() {
      if (this.customLogoForUserLocation && this.customLogoForUserLocation !== '') {
        return {
          backgroundImage: `url(${this.customLogoForUserLocation})`,
          backgroundSize: 'contain'
        };
      }
      if (window.CUSTOM_BUILD && window.CUSTOM_BUILD === 'QTECH-WIMARK') {
        return this.themeName === 'dark' ?
          { backgroundImage: 'url(/static/img/qtech_new-dark.png)' } :
          { backgroundImage: 'url(/static/img/qtech_new.png)' };
      }
      if (window.CUSTOM_BUILD && window.CUSTOM_BUILD === 'BULAT-WIMARK') {
        return this.themeName === 'dark' ?
          { backgroundImage: 'url(/static/img/bulat_new-dark.png)' } :
          { backgroundImage: 'url(/static/img/bulat_new.png)' };
      }
      // if (window.CUSTOM_BUILD && window.CUSTOM_BUILD === 'BEELINE-WIMARK') {
      //   return this.themeName === 'dark' ?
      //     { backgroundImage: 'url(/static/img/beeline_new-dark.png)' } :
      //     { backgroundImage: 'url(/static/img/beeline_new.png)' };
      // }
      if (window.CUSTOM_BUILD && window.CUSTOM_BUILD === 'BEELINE-WIMARK') {
        return this.themeName === 'dark' ?
          { backgroundImage: 'url(/static/img/beeline_business_logo_dark.png)' } :
          { backgroundImage: 'url(/static/img/beeline_business_logo.png)' };
      }
      if (window.CUSTOM_BUILD && window.CUSTOM_BUILD === 'MTS-WIMARK') {
        return this.themeName === 'dark' ?
          { backgroundImage: 'url(/static/img/mts_logo.svg)' } :
          { backgroundImage: 'url(/static/img/mts_logo.svg)' };
      }
      if (window.CUSTOM_BUILD && window.CUSTOM_BUILD === 'WIFLY-WIMARK') {
        return this.themeName === 'dark' ?
          { backgroundImage: 'url(/static/img/wifly_new-dark.png)' } :
          { backgroundImage: 'url(/static/img/wifly_new.png)' };
      }
      return this.themeName === 'dark' ?
        { backgroundImage: 'url(/static/img/logo_new-dark.png)' } :
        { backgroundImage: 'url(/static/img/logo_new.svg)' };
    },
    headerLink() {
      const userRoleState = this.$store.state.userData.role;
      if (userRoleState === 'hotel_employee') {
        return '/portal/hotel-vouchers';
      }
      if (userRoleState === 'advertising') {
        return '/portal/ads';
      }
      if (this.isPortalOnly) {
        return '/portal/dashboard';
      }
      return '/dashboard';
    },
    bossUserUsername() {
      const bossUser = JSON.parse(localStorage.getItem('bossUserData'));
      const bossUserName = bossUser.username;
      return bossUserName;
    },
    isSubordinateLogin() {
      return this.$store.state.subordinateLogin;
    },
    window() {
      return window;
    },
    commonService() {
      return commonService;
    },
    // currentUserData() {
    //   this.updatedUserData = JSON.parse(JSON.stringify(this.$store.getters.loadUserData));
    //   return this.$store.state.userData;
    // },
    currentUserData() {
      this.updatedUserData = JSON.parse(JSON.stringify(this.$store.getters.loadUserData));
      this.$store.commit('loadInHeaderLocationSelectBlockDataFromLocalStorage')
      return this.$store.state.userData;
    },
    hideAllOperations() {
      return this.$store.state.hideAllOperations;
    },
    operationsList() {
      return this.$store.state.operationsList;
    },
    getFullCurrentOperations() {
      let fullCurrentOperations = [];
      fullCurrentOperations = [
        ...this.$store.state.cpeOperations,
        ...this.$store.state.wlanOperations,
        ...this.$store.state.radiusOperations,
        ...this.$store.state.firewallOperations,
        ...this.$store.state.redirectOperations,
        ...this.$store.state.hotspotOperations,
        ...this.$store.state.controllerOperations
      ];
      return fullCurrentOperations;
    },
    getFullOperations() {
      let fullOperations = [];
      fullOperations = [
        ...this.$store.state.cpeOperations,
        ...this.$store.state.wlanOperations,
        ...this.$store.state.radiusOperations,
        ...this.$store.state.firewallOperations,
        ...this.$store.state.redirectOperations,
        ...this.$store.state.hotspotOperations,
        ...this.$store.state.controllerOperations,
        ...this.operationsList
      ];
      return fullOperations.slice(0, 100);
    },
    getErrorsOperations() {
      let errorsOperations = [];
      errorsOperations = [
        ...this.$store.state.cpeOperations,
        ...this.$store.state.wlanOperations,
        ...this.$store.state.radiusOperations,
        ...this.$store.state.firewallOperations,
        ...this.$store.state.redirectOperations,
        ...this.$store.state.hotspotOperations,
        ...this.$store.state.controllerOperations,
        ...this.operationsList
      ];
      errorsOperations = errorsOperations.filter((operation) => operation.status === 'error');
      return errorsOperations;
    },
    systemInfo() {
      return this.$store.state.systemTotalObjList.system;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    cpesList() {
      return this.$store.state.cpesList;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    themeName() {
      return this.$store.state.themeName || localStorage.getItem('theme');
    },
    operationsTotal() {
      return this.$store.state.operationsTotal;
    },
    loadingOperations() {
      return this.$store.state.loadingOperations;
    }
  },
  methods: {
    createCustomStyleAsText(stylesObject) {
      let css = ':root {';
      for (const style in stylesObject) {
        if (stylesObject[style] !== '') {
          css += ` --${style}: ${stylesObject[style]};`;
        }
      }
      css = css.substring(0, css.length - 1); // вырезаем последний символ ';'
      css += '}';
      return css;
    },
    logoutAsSubordinate(event) {
      event.stopPropagation();
      const bossUserData = JSON.parse(localStorage.getItem('bossUserData'));
      // console.log(bossUserData);
      // вместо /userChange может быть любое имя, нужно чтобы dashboard(/) перезагрузился
      // (чтобы подгрузилось всякое, что грузится при входе в систему),
      // даже если юзер уже находится на dashboard(/), а потом перекидываем его на список юзеров
      this.$router.push('/userchange', () => {
        localStorage.setItem('id_token', bossUserData.token);
        localStorage.setItem('access_token', bossUserData.token);
        this.axios.defaults.headers.common.Authorization = `Bearer ${bossUserData.token}`;
        this.$store.commit('setUserData', bossUserData);
        this.$store.commit('setBaseLocation', bossUserData.base_location);
        console.warn(`Login as ${bossUserData.username}`);
        localStorage.removeItem('bossUserData');
        this.$store.commit('subordinateLoginSetFalse');
        this.$router.push('/', () => {
          this.$router.push('/settings/users');
        });
      });
    },
    sidebarToggle(e) {
      e.preventDefault();
      // document.body.classList.toggle('sidebar-hidden');
      document.body.classList.toggle('sidebar-show-only-icons');
      this.$store.commit('toggleShowOnlyIcons');
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle('sidebar-mobile-show');
    },
    asideToggle(e) {
      e.preventDefault();
      document.body.classList.toggle('aside-menu-hidden');
    },
    logout(e) {
      e.preventDefault();
      auth.logout(this);
    },
    getOperationType(type) {
      switch (type) {
        case 'U':
          return this.$t('header.typeUPDATE');
        case 'D':
          return this.$t('header.typeDELETE');
        case 'C':
          return this.$t('header.typeCREATE');
        default:
          return type;
      }
    },
    filterLast(items) {
      const lastItems = [];
      for (let i = 0; i < 50; i += 1) {
        lastItems.push(items[i]);
      }
      return lastItems;
    },
    getName(model, ids) {
      let modelIds = ids;

      if (!modelIds) {
        return [];
      }
      if (model === 'wlan') {
        modelIds = ids.map((modelId) => ({
          name: commonService.wlanNamebyId(modelId) || 'No data',
          id: modelId
        }));
      }
      if (model === 'cpe') {
        modelIds = ids.map((modelId) => ({
          name: commonService.cpeNamebyId(modelId) || 'No data',
          id: modelId
        }));
      }
      if (model === 'radius') {
        modelIds = ids.map((modelId) => ({
          name: commonService.radiusNamebyId(modelId) || 'No data',
          id: modelId
        }));
      }
      if (model === 'l2-chain') {
        modelIds = ids.map((modelId) => ({
          name: commonService.firewallNamebyId(modelId) || 'No data',
          id: modelId
        }));
      }
      if (model === 'hotspot-profile') {
        modelIds = ids.map((modelId) => ({
          name: commonService.hotspotNamebyId(modelId) || 'No data',
          id: modelId
        }));
      }
      if (model === 'controller') {
        modelIds = ids.map((modelId) => ({
          name: commonService.controllerNamebyId(modelId) || 'No data',
          id: modelId
        }));
      }
      if (model === 'captive-redirect') {
        modelIds = ids.map((modelId) => ({
          name: commonService.redirectNamebyId(modelId) || 'No data',
          id: modelId
        }));
      }
      return modelIds;
    },
    iShowAllOperations() {
      this.showAllOperations = !this.showAllOperations;
    },
    hideAll() {
      operationsService.hideAllOperations(this);
    },
    setRefreshInterval() {
      this.$validator.validateAll({ 'refresh-interval': this.newRefreshInterval }).then(
        (result) => {
          if (result) {
            this.refreshInterval = this.newRefreshInterval;
            this.$store.commit('setRefreshInterval', this.refreshInterval);
            this.newRefreshInterval = '';
            localStorage.setItem('refreshInterval', this.refreshInterval);

            const dropdown = document.querySelector('.nav-item.open.dropdown');
            if (dropdown) {
              dropdown.classList.remove('open');
            }
          }
        },
        () => {
          console.warn('Validation Failed');
        }
      );
    },
    removeRefreshInterval() {
      this.refreshInterval = '0';
      this.newRefreshInterval = '';
      this.$store.commit('setRefreshInterval', this.refreshInterval);
      localStorage.setItem('refreshInterval', this.refreshInterval);

      const dropdown = document.querySelector('.nav-item.open.dropdown');
      if (dropdown) {
        dropdown.classList.remove('open');
      }
    },
    getOperations() {
      operationsService.getPedingOperations(this);
    },
    deleteAllOperations(id) {
      operationsService.deleteOperations(this, id);
    }
  },
  created() {
    // если список локаций почему-то пуст, то подгружаем, чтобы показать картинку в шапке
    if (Object.keys(this.$store.state.locationsItems).length === 0) {
      // console.log(this.$store.state.locationsItems);
      locationService.getLocations(this);
    }
    if (localStorage.getItem('bossUserData')) {
      this.$store.commit('subordinateLoginSetTrue');
    }
    // if (!localStorage.getItem('refreshInterval')) {
    //   this.refreshInterval = '30';
    //   localStorage.setItem('refreshInterval', this.refreshInterval);
    // } else {
    //   this.refreshInterval = localStorage.getItem('refreshInterval');
    // }

    // изменения для задачи WNE-2900
    // новая логика в том чтобы выставить всем юзерам рефреш интервал по умолчанию  '0', т.е отключить этот самый рефреш
    if (!localStorage.getItem('refreshInterval')) {
      this.refreshInterval = '0';
      localStorage.setItem('refreshInterval', this.refreshInterval);
      localStorage.setItem('refreshIntervalWasChangedToDefault', 'true');
    } else {
      // refreshIntervalWasChangedToDefault нужно чтобы юзер мог выставить 30 сек (бывший ранее интервалом по умолчанию)
      // после того как всем обнулит на новый дефолтный 0
      if (localStorage.getItem('refreshInterval') === '30' &&
        (!localStorage.getItem('refreshIntervalWasChangedToDefault') ||
        localStorage.getItem('refreshIntervalWasChangedToDefault') !== 'true')) {
        this.refreshInterval = '0';
        localStorage.setItem('refreshInterval', '0');
        localStorage.setItem('refreshIntervalWasChangedToDefault', 'true');
      }
      this.refreshInterval = localStorage.getItem('refreshInterval');
    }

    this.$store.commit('setRefreshInterval', this.refreshInterval);
    if (!this.$store.state.radiusList) {
      radiusService.getRadius(this);
    }
    if (!this.$store.state.firewallsList) {
      firewallService.getFirewalls(this);
    }
    if (!this.$store.state.operationsList || !this.$store.state.operationsList.length) {
      operationsService.getPedingOperations(this);
    }
    if (!this.$store.state.redirectsList || !this.$store.state.redirectsList.length) {
      redirectsService.getRedirectsRule(this);
    }
  },
  mounted() {
    // Как это все работает (кастомные цвета для локации):
    // 1.
    // При компиляции scss файлов туда засовываются css переменные по умолчанию, заполненные переменными scss
    // из файла /static/scss/defaultsCustomCSSVarsForLocation.scss
    // На этом этапе они ни на что не влияют, поскоольку на этом этапе в css файлах стилей
    // эти css переменные не используются
    //
    // 2. В компоненте Header есть computed customCSSVarsForUserLocation. Оно смотрит на базовую локацию юзера и на список локаций.
    // Если у локации, с id как у базовой локации юзера включен флаг custom_styles_enable то она возвращает элементы из объекта
    // custom_styles приведенные к виду CSS переменных (вначале добавляется --имясвойства, все обрачивается в :root {})
    //
    // 3. На хуке mounted компонента Header происходит проверка есть ли что в customCSSVarsForUserLocation. Если что-то есть
    // то происходит следующее. В конец элемента header добавляется элемент style, содержащий в себе данные из пункта 2
    // (computed customCSSVarsForUserLocation). Эnо частично или полностью перезаписывает css  перменные из
    // /static/scss/defaultsCustomCSSVarsForLocation.scss. (пункт 1)
    // 4. А далее, в конец все того-же header, происходит вставка линки на css файл "/static/css/customStylesForLocationRewriteCSS.css"
    // Вставка этого самого файла и меняет цвета (в нем выборочно переопределяются некоторые цвета css,
    // поскольку он - последний css вставляемый в готовый dom он может все перекрасить)
    // Внутри него как раз и используются значения css переменных из пункта 1 или 3
    // 5. Также есть функция helpers.clearCustomLocationCss, которая очищает весь вставленный
    // ранее кастомныый css, отрабатывает на хуке mounted компонента Header если в
    // this.customCSSVarsForUserLocation ничего нет (например вышли из логина как подчиненный,
    // в локации у которого есть кастомные цвета, а в локации текущего пользователя нет)
    // (Если для локации нет кастомного CSS, а в DOM он есть, то удаляем его)
    // ------
    // Если для локации нет кастомного CSS, а в DOM он есть, то удаляем его
    // (например заходили под подчиненным из другой локации и вышли)
    if (!this.customCSSVarsForUserLocation) {
      helpers.clearCustomLocationCss();
    }
    // если для локации есть кастомный css  то добавляем его в DOM
    if (this.customCSSVarsForUserLocation && this.customCSSVarsForUserLocation !== '') {
      // если уже есть какой-то css то удаляем перед тем как вставить
      helpers.clearCustomLocationCss();
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'customCssVarsForUserLocation';
      style.appendChild(document.createTextNode(this.customCSSVarsForUserLocation));
      head.appendChild(style);
      // а потом добавляем сами стили для перезаписи основных блоков (таким образом не аффектим стили для локаций нет кастомных стилей)
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = '/static/css/customStylesForLocationRewriteCSS.css';
      link.media = 'all';
      link.id = 'customStylesForLocationRewriteCSS';
      head.appendChild(link);
    }
    // если тема теманя удаляем кастомные стили
    let theme = '';
    if (localStorage.getItem('theme')) {
      theme = localStorage.getItem('theme');
    }
    // если тема темная, удаляем все кастомные сss
    if (theme === 'dark') {
      helpers.clearCustomLocationCss();
    }
  },
  destroyed() {
    helpers.clearCustomLocationCss();
  },
  filters: {
    momentnow(value) {
      if (value) {
        return moment.duration(value, 'seconds').format(' h [h], mm [min]');
      }
    },
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    }
  }
};
</script>

<style lang="scss">
.operations {
  max-width: 400px;
  min-width: 250px;
}

.operations-block {
  max-height: 400px;
  overflow-y: scroll;
}

.operations-block__current {
  max-height: 150px;
}

.operations-block__last {
  max-height: 250px;
}

.operaions-filter {
  cursor: pointer;
}

.operation-item {
  display: flex;
  flex-direction: row;

  padding: 10px;

  border-bottom: 1px solid #e5e5e5;

  &--pending {
    background: rgba(99, 194, 222, 0.1);
  }

  &--error {
    background: rgba(248, 107, 107, 0.1);
  }
}

.operation-models {
  display: flex;
  flex-wrap: wrap;
}

.operation-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 1rem;
}

.operation-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;

  &--pending {
    background-color: #63c2de;
  }
  &--success {
    background-color: #4dbd74;
  }
  &--error {
    background-color: #f86c6b;
  }
}

.operation-action {
  min-width: 3.6rem;
  margin-right: 0.5rem;
}

.operations-show {
  display: flex;
  justify-content: space-between;
}

.operations-total {
  padding: 0 1rem;
  line-height: 40px;
}

.operation-time {
  text-transform: capitalize;
  padding: 0 1rem;
}

.operation-model {
  min-width: 3.6rem;
  margin-right: 0.5rem;
}

.operation-model-name {
  flex-basis: 200px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.operations-list {
  display: flex;
  flex-direction: column;
}

.operation-model-name.operation-model-full {
  flex-basis: auto;
  width: 330px;
  overflow: visible;
  white-space: pre-line;
}

.operation-icon {
  padding: 0 0.5rem;
}

.operation-hide {
  display: block;
  padding: 0.5rem 0;
  text-align: center;
}

.operation-no-current {
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  text-align: center;
}

.operations-enter-active {
  transition: all 0.7s;
}

.operations-leave-active {
  transition: all 0.5s;
}

.operations-enter, .operations-leave-to /* .list-leave-active для <2.1.8 */ {
  opacity: 0;
  transform: translateY(-25px);
}

.refresh {
  min-width: 250px;
}

.refresh-block {
  border-bottom: 1px !important;
  padding: 0px !important;
  padding-bottom: 1em !important;
}

.refresh-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}

.refresh-item .form-control,
.refresh-item .input-group-addon {
  border: 0 solid rgba(0, 0, 0, 0.15);
}

.refresh-item .input-group-addon {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  text-align: center;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.device-logo {
  height: 17px;
}

.device-logo__navbar {
  position: relative;
  top: -3px;
  right: 4px;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active для <2.1.8 */ {
  opacity: 0;
}

.fa-spin--slow {
  -webkit-animation: fa-spin 6s infinite linear;
  animation: fa-spin 6s infinite linear;
}

/* Style for custom logo*/
.custom-header {
  height: 60px !important;
}

.custom-logo {
  background-position: 20px center !important;
  background-size: auto 45px !important;
}

.custom-logo-beeline {
  background-position: 5px center !important;
  /*background-size: auto 24px !important;*/
  background-size: 218px auto !important;
}

.custom-logo::after {
  content: 'Running on Wimark® Platform';
  font-family: 'DIN2014NarrowDemi';
  position: absolute;
  color: #4f5a66;
  top: 42px;
  left: 79px;
  font-size: 13px;
}

.custom-logo-beeline::after {
  content: 'Running on Wimark® Platform';
  font-family: 'DIN2014NarrowDemi';
  position: absolute;
  color: #4f5a66;
  top: 42px;
  left: 86px;
  font-size: 12.5px;
}

.custom-navbar-barnd-for-location::after {
}

/*animation*/
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.profile {
  display: flex;
  flex-flow: column nowrap;
  margin: auto;
}

.profile__role {
  position: relative;
  height: 100%;
  padding-left: 0.7rem;
  margin: auto;
}
.profile__role i {
  position: absolute;
  top: 3px;
  left: 0;
}

.profile__name {
  height: 100%;
  margin: auto;
}
.mobile-sidebar-toggler {
  z-index: 10;
}
</style>
<style scoped>
  /*.custom-navbar-z-index-for-correct-location-selector-show{*/
  /*  z-index: 99999 !important;*/
  /*}*/
  .custom-navbar-z-index-for-correct-location-selector-show-99999{
    z-index: 99999 !important;
  }
  .custom-navbar-z-index-for-correct-location-selector-show-99998{
    z-index: 99998 !important;
  }
  .location-selector{
    position: relative;
    bottom: -10px;
  }
.locale-switcher {
  position: relative;
  top: 0.5px;
  left: -3px;
}
</style>
