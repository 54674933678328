import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import store from '../store';
import operationsService from './operationsService';

export default {
  hadleOperationResponse(items) {
    if (store.state.firewallOperations.indexOf(items) == -1) {
      store.commit('setFirewallOperations', items);
    }
  },
  startPolling(ctx) {
    const queryIds = this.getPollingIds();
    let counter = 0;
    if (queryIds.length) {
      const pollIteration = setInterval(() => {
        Vue.axios.post(`${window.RESTAPI || ''}/api/operations`, { action: 'R', ids: queryIds }).then(
          (response) => {
            counter++;
            response.data.data.itemslist.forEach((operation) => {
              if (operation.status === 'success') {
                let isOperationActual = false;
                store.state.firewallOperations.forEach((storeL2chainOperation) => {
                  if (storeL2chainOperation.id === operation.id) {
                    isOperationActual = true;
                  }
                });
                store.commit('setL2ChainOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                if (isOperationActual) {
                  this.showSuccessPopup(operation, ctx);
                }

                clearInterval(pollIteration);
              }
              if (operation.status === 'error') {
                let isOperationActual = false;

                store.state.firewallOperations.forEach((storeL2chainOperation) => {
                  if (storeL2chainOperation.id === operation.id) {
                    isOperationActual = true;
                  }
                });

                store.commit('setL2ChainOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                // let operationModelName = commonService.radiusNamebyId(operation.model_id);
                VueNotifications.error({ message: ` ${operation.description}` });
              }
            });
            if (!store.state.firewallOperations.length) {
              if (ctx) {
                ctx.$store.state.editFirewallId = '';
                ctx.refreshFirewallsList();
              }
              console.warn('Stop polling');
              operationsService.getPedingOperations();
              clearInterval(pollIteration);
            }
          },
          (err) => {}
        );
      }, 2000);
    }
  },
  getPollingIds() {
    const pollingIds = [];
    store.state.firewallOperations.forEach((operation) => {
      if (!pollingIds.includes(operation.id)) {
        if (operation.status === 'pending') {
          pollingIds.push(operation.id);
        }
      }
    });

    return pollingIds;
  },
  showSuccessPopup(operation, ctx) {
    switch (operation.type) {
      case 'U':
        VueNotifications.success({ message: ctx.$t('firewall.notificationFirewallUpdatedSuccessfully') });
        break;
      case 'C':
        VueNotifications.success({ message: ctx.$t('firewall.notificationFirewallCreatedSuccessfully') });
        break;
      case 'D':
        VueNotifications.success({ message: ctx.$t('firewall.notificationFirewallDeletedSuccessfully') });
        break;
      default:
        VueNotifications.success({ message: ctx.$t('firewall.notificationFirewallOperationSuccessfull') });
    }
  }
};
