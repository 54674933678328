<template>
  <header class="app-header navbar" :class="{ 'custom-header': window.CUSTOM_BUILD }"><slot></slot></header>
</template>
<script>
export default {
  name: 'Navbar',
  created() {
    this._navbar = true;
  },
  computed: {
    window() {
      return window;
    }
  }
};
</script>
