<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class="card h-100">
        <div class="card-block">
          <div class="h4 m-0">
            {{ $t('general.step5') }}
            <button
              type="button"
              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"
              @click="lastStepButtonClickHandler"
            >
              {{ $t('wlans.wizardSkipButton') }}
              <info :content="$t('wlans.wizardSkipButtonInfo')" />
            </button>
          </div>
          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('wlans.wizardRADIUSAdditionalSettings') }}</span>
          <form action="">
            <transition name="wsettings">
              <div class="mt-1">
                <div class="form-group">
                  <label for="nas_id">{{ $t('wlans.NASidentifier') }}</label>
                  <input
                    type="text"
                    id="nas_id"
                    name="nas_id"
                    :placeholder="$t('wlans.enterNASIdentifierPlaceholder')"
                    class="form-control"
                    v-model="newWlanData.nas_id"
                  />
                </div>

                <div class="form-group">
                  <label for="nas_port_id">{{ $t('wlans.NASportid') }}</label>
                  <input
                    type="text"
                    id="nas_port_id"
                    name="nas_port_id"
                    :placeholder="$t('wlans.enterNASPortIdPlaceholder')"
                    class="form-control"
                    v-model="newWlanData.nas_port_id"
                  />
                </div>
              </div>
            </transition>
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="btn btn-outline-success float-right"-->
            <!--              @click="setNewWlanData"-->
            <!--            >-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left"-->
            <!--                    @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
        <div class="buttons-block-warpper">
          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">
            {{ $t('general.next') }}
          </button>
          <!--          <button type="button" class="btn btn-outline-info float-right mr-h"-->
          <!--                  @click="lastStepButtonClickHandler">-->
          <!--            {{ $t('wlans.wizardSkipButton') }}-->
          <!--            <info :content="$t('wlans.wizardSkipButtonInfo')"/>-->
          <!--          </button>-->
          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">
            {{ $t('general.back') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from '../../../components/Universal/info-icon.vue';

export default {
  name: 'Step5',
  components: { Info },
  data() {
    return {
      enableSaveChanges: true,
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      }
      // showWlanPass: false,
      // updatedWlanRadiusAuthentication: [],
      // updatedWlanRadiusAccounting: []
    };
  },
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    }
  },
  methods: {
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    // enableSave() {
    //   this.enableSaveChanges = true;
    // },
    setNewWlanData(event, toLastStepClicked = false) {
      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            if (toLastStepClicked === false) {
              this.$parent.nextStep();
            } else {
              // this.$parent.nextStep(true);
              this.$parent.lastStep();
            }
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    // radiusService.getRadius(this);
    // this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
