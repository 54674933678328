export default {
  getCurrentCapabilities(config, capabilities) {
    const { freqlist } = capabilities;
    const hwmodes = capabilities.hwmodelist;
    const selectedChannel = config.channels[0];
    let calculatedCapabilities = {};
    var htmodes = capabilities.htmodelist;
    const channels = {
      '11g': ['auto', 'auto', true],
      '11a': ['auto', 'auto', true]
    };

    for (let i = 0; i < freqlist.length; i++) {
      channels[freqlist[i].mhz > 2484 ? '11a' : '11g'].push(
        freqlist[i].channel,
        '{0} ({1} MHz)'.format(freqlist[i].channel, freqlist[i].mhz),
        !freqlist[i].restricted
      );
    }

    const modes = ['', 'Legacy', true, 'n', 'N', hwmodes.n, 'ac', 'AC', hwmodes.ac];

    // var htmodes = {
    //   '': ['', '-', true],
    //   a: ['', '-', true],
    //   b: ['', '-', true],
    //   g: ['', '-', true],
    //   n: ['HT20', '20 MHz', htmodes.HT20, 'HT40', '40 MHz', htmodes.HT40],
    //   ac: [
    //     'VHT20',
    //     '20 MHz',
    //     htmodes.VHT20,
    //     'VHT40',
    //     '40 MHz',
    //     htmodes.VHT40,
    //     'VHT80',
    //     '80 MHz',
    //     htmodes.VHT80,
    //     'VHT160',
    //     '160 MHz',
    //     htmodes.VHT160
    //   ]
    // };

    // var htmodes = {
    //   '': ['', '-', true],
    //   a: ['', '-', true],
    //   b: ['', '-', true],
    //   g: ['', '-', true],
    //   n: ['HT20', '20 MHz', htmodes.HT20, 'HT40', '40 MHz', htmodes.HT40],
    //   ac: [
    //     'VHT20',
    //     '20 MHz',
    //     htmodes.VHT20,
    //     'VHT40',
    //     '40 MHz',
    //     htmodes.VHT40,
    //     'VHT80',
    //     '80 MHz',
    //     htmodes.VHT80,
    //     'VHT160',
    //     '160 MHz',
    //     htmodes.VHT160
    //   ],
    //   ax: [
    //     'NOHT',
    //     '',
    //     htmodes.NOHT,
    //     'HT20',
    //     '20 MHz',
    //     htmodes.HT20,
    //     'HT40',
    //     '40 MHz',
    //     htmodes.HT40,
    //     'VHT20',
    //     '20 MHz',
    //     htmodes.VHT20,
    //     'VHT40',
    //     '40 MHz',
    //     htmodes.VHT40,
    //     'VHT80',
    //     '80 MHz',
    //     htmodes.VHT80,
    //     'VHT80+80',
    //     '160 MHz',
    //     htmodes['VHT80+80'],
    //     'VHT160',
    //     '160 MHz',
    //     htmodes.VHT160,
    //     'HE20',
    //     '20 MHz',
    //     htmodes.HE20,
    //     'HE40',
    //     '40 MHz',
    //     htmodes.HE40,
    //     'HE80',
    //     '80 MHz',
    //     htmodes.HE80,
    //     'HE80+80',
    //     '160 MHz',
    //     htmodes['HE80+80'],
    //     'HE160',
    //     '160 MHz',
    //     htmodes.HE160
    //   ]
    // };


    var htmodesFor5 = {
      '': ['', '-', true],
      a: ['', '-', true],
      b: ['', '-', true],
      g: ['', '-', true],
      n: ['HT20', '20 MHz', htmodes.HT20, 'HT40', '40 MHz', htmodes.HT40],
      ac: [
        'VHT20',
        '20 MHz',
        htmodes.VHT20,
        'VHT40',
        '40 MHz',
        htmodes.VHT40,
        'VHT80',
        '80 MHz',
        htmodes.VHT80,
        'VHT160',
        '160 MHz',
        htmodes.VHT160
      ],
      ax: [
        'HE20',
        '20 MHz',
        htmodes.HE20,
        'HE40',
        '40 MHz',
        htmodes.HE40,
        'HE80',
        '80 MHz',
        htmodes.HE80,
        // 'HE80+80',
        // '160 MHz',
        // htmodes['HE80+80'],
        // 'HE160',
        // '160 MHz',
        // htmodes.HE160
      ]
    };
    var htmodesFor2 = {
      '': ['', '-', true],
      a: ['', '-', true],
      b: ['', '-', true],
      g: ['', '-', true],
      n: ['HT20', '20 MHz', htmodes.HT20, 'HT40', '40 MHz', htmodes.HT40],
      ac: [
        'VHT20',
        '20 MHz',
        htmodes.VHT20,
        'VHT40',
        '40 MHz',
        htmodes.VHT40,
        'VHT80',
        '80 MHz',
        htmodes.VHT80,
        'VHT160',
        '160 MHz',
        htmodes.VHT160
      ],
      ax: [
        'HE20',
        '20 MHz',
        htmodes.HE20,
        'HE40',
        '40 MHz',
        htmodes.HE40,
      ]
    };

    // const bands = {
    //   '': [
    //     '11g',
    //     '2.4 GHz',
    //     channels['11g'].length > 3 && config.frequency === '2.4',
    //     '11a',
    //     '5 GHz',
    //     channels['11a'].length > 3 && config.frequency === '5'
    //   ],
    //   a: [
    //     '11g',
    //     '2.4 GHz',
    //     channels['11g'].length > 3 && config.frequency === '2.4',
    //     '11a',
    //     '5 GHz',
    //     channels['11a'].length > 3 && config.frequency === '5'
    //   ],
    //   b: [
    //     '11g',
    //     '2.4 GHz',
    //     channels['11g'].length > 3 && config.frequency === '2.4',
    //     '11a',
    //     '5 GHz',
    //     channels['11a'].length > 3 && config.frequency === '5'
    //   ],
    //   g: [
    //     '11g',
    //     '2.4 GHz',
    //     channels['11g'].length > 3 && config.frequency === '2.4',
    //     '11a',
    //     '5 GHz',
    //     channels['11a'].length > 3 && config.frequency === '5'
    //   ],
    //   n: [
    //     '11g',
    //     '2.4 GHz',
    //     channels['11g'].length > 3 && config.frequency === '2.4',
    //     '11a',
    //     '5 GHz',
    //     channels['11a'].length > 3 && config.frequency === '5'
    //   ],
    //   ac: ['11a', '5 GHz', true]
    // };

    const bands = {
      '': [
        '11g',
        '2.4 GHz',
        channels['11g'].length > 3 && config.frequency === '2.4',
        '11a',
        '5 GHz',
        channels['11a'].length > 3 && config.frequency === '5'
      ],
      a: [
        '11g',
        '2.4 GHz',
        channels['11g'].length > 3 && config.frequency === '2.4',
        '11a',
        '5 GHz',
        channels['11a'].length > 3 && config.frequency === '5'
      ],
      b: [
        '11g',
        '2.4 GHz',
        channels['11g'].length > 3 && config.frequency === '2.4',
        '11a',
        '5 GHz',
        channels['11a'].length > 3 && config.frequency === '5'
      ],
      g: [
        '11g',
        '2.4 GHz',
        channels['11g'].length > 3 && config.frequency === '2.4',
        '11a',
        '5 GHz',
        channels['11a'].length > 3 && config.frequency === '5'
      ],
      n: [
        '11g',
        '2.4 GHz',
        channels['11g'].length > 3 && config.frequency === '2.4',
        '11a',
        '5 GHz',
        channels['11a'].length > 3 && config.frequency === '5'
      ],
      ac: ['11a', '5 GHz', true],
      ax: [
        '11g',
        '2.4 GHz',
        channels['11g'].length > 3 && config.frequency === '2.4',
        '11a',
        '5 GHz',
        channels['11a'].length > 3 && config.frequency === '5'
      ],
    };

    function available_channels(hwMode) {
      const vector = bands[hwMode];
      const chs = [];
      if (vector && vector.length) {
        for (let i = 0; i < vector.length;) {
          if (vector[i + 2]) {
            for (let j = 0; j < channels[vector[i]].length; j++) {
              if (!(j % 3)) {
                chs.push(channels[vector[i]][j]);
              }
            }
          }
          i += 3;
        }
      }

      return chs;
    }

    // function available_bandwidth(hwMode) {
    //   const vector = htmodes[hwMode];
    //   const bandwidthses = [];
    //   if (vector && vector.length) {
    //     for (let i = 0; i < vector.length;) {
    //       if (vector[i + 2]) {
    //         bandwidthses.push(vector[i]);
    //       }
    //       i += 3;
    //     }
    //   }
    //
    //   if (!bandwidthses.length) {
    //     bandwidthses.push('auto');
    //   }
    //   return bandwidthses;
    // }

    function available_bandwidth(hwMode, config) {
      const vector = config?.frequency == '5' ? htmodesFor5[hwMode] : htmodesFor2[hwMode];
      const bandwidthses = [];
      if (vector && vector.length) {
        for (let i = 0; i < vector.length;) {
          if (vector[i + 2]) {
            bandwidthses.push(vector[i]);
          }
          i += 3;
        }
      }

      if (!bandwidthses.length) {
        bandwidthses.push('auto');
      }
      return bandwidthses;
    }

    const choosen = config.bandmode.replace('11', '');
    // const bandwiths = available_bandwidth(choosen);
    const bandwiths = available_bandwidth(choosen, config);
    if (bandwiths.length === 1 && bandwiths[0] === '') {
      bandwiths[0] = 'auto';
    }
    const av_chs = available_channels(choosen);

    calculatedCapabilities = {
      bandwidth: bandwiths,
      channels: av_chs,
      txPower: currentTxMaxPower(capabilities, selectedChannel)[1],
      minTxPower: currentTxMaxPower(capabilities, selectedChannel)[0]
    };

    function currentTxMaxPower(capabilities, selectedChannel) {
      let maxPower;
      let minPower;
      const txpwr = capabilities.txpwrlist.length - 1;
      if (capabilities.txpwrlist.length) {
        const txPowerList = capabilities.txpwrlist.sort(comparePower);
        maxPower = txPowerList[txpwr].dbm;
        minPower = txPowerList[0].dbm;
      } else {
        maxPower = 20;
        minPower = 0;
      }

      // очень сложный кейс с выбором канала и поддержкой текущего txpower для данного канала
      // capabilities.freqlist.forEach(freq => {
      // if (freq.channel == selectedChannel) {
      //     maxPower = freq.max_txpower.dbm
      // }
      // });

      return [minPower, maxPower];
    }

    function comparePower(a, b) {
      return a.dbm - b.dbm;
    }

    return calculatedCapabilities;
  },
  // getCurrentBandwidthChannels(interfaceCapabilities, calculatedCapabilities, condition) {
  //   if (condition === 'HT40') {
  //     calculatedCapabilities.channels = calculatedCapabilities.channels.slice(1, -4);
  //
  //     const avChannels = calculatedCapabilities.channels;
  //     const chanlist = interfaceCapabilities.selected.channels;
  //     const newChannels = [];
  //     chanlist.forEach((channel) => {
  //       if (avChannels.indexOf(channel) === -1) {
  //       } else {
  //         newChannels.push(channel);
  //       }
  //     });
  //     return newChannels;
  //   }
  //   if (condition === 'VHT40/HT40') {
  //     const oldChannels = calculatedCapabilities.channels;
  //     const chanlist = interfaceCapabilities.selected.channels;
  //
  //     if (oldChannels[0] === 'auto') {
  //       oldChannels.splice(0, 1);
  //     }
  //     const oldChannelsStr = oldChannels.join(', ');
  //
  //     const newChannels = [];
  //     const avChanList = [];
  //     const channels38 = '36, 40';
  //     const channels46 = '44, 48';
  //     const channels54 = '52, 56';
  //     const channels62 = '60, 64';
  //     const channels102 = '100, 104';
  //     const channels110 = '108, 112';
  //     const channels118 = '116, 120';
  //     const channels126 = '124, 128';
  //     const channels134 = '132, 136';
  //     const channels142 = '140, 144';
  //     const channels151 = '149, 153';
  //     const channels159 = '157, 161';
  //     if (oldChannelsStr.indexOf(channels38) != -1) {
  //       newChannels.push(parseInt(channels38.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels46) != -1) {
  //       newChannels.push(parseInt(channels46.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels54) != -1) {
  //       newChannels.push(parseInt(channels54.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels62) != -1) {
  //       newChannels.push(parseInt(channels62.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels102) != -1) {
  //       newChannels.push(parseInt(channels102.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels110) != -1) {
  //       newChannels.push(parseInt(channels110.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels118) != -1) {
  //       newChannels.push(parseInt(channels118.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels126) != -1) {
  //       newChannels.push(parseInt(channels126.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels134) != -1) {
  //       newChannels.push(parseInt(channels134.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels142) != -1) {
  //       newChannels.push(parseInt(channels142.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels151) != -1) {
  //       newChannels.push(parseInt(channels151.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels159) != -1) {
  //       newChannels.push(parseInt(channels159.split(', ')[0]));
  //     }
  //
  //     calculatedCapabilities.channels = newChannels;
  //
  //     chanlist.forEach((channel) => {
  //       if (newChannels.indexOf(channel) === -1) {
  //       } else {
  //         avChanList.push(channel);
  //       }
  //     });
  //     return avChanList;
  //   }
  //   if (condition === 'VHT80/HT80') {
  //     const oldChannels = calculatedCapabilities.channels;
  //     const chanlist = interfaceCapabilities.selected.channels;
  //     if (oldChannels[0] === 'auto') {
  //       oldChannels.splice(0, 1);
  //     }
  //     const oldChannelsStr = oldChannels.join(', ');
  //     const newChannels = [];
  //     const avChanList = [];
  //
  //     const channels42 = '36, 40, 44, 48';
  //     const channels58 = '52, 56, 60, 64';
  //     const channels106 = '100, 104, 108, 112';
  //     const channels122 = '116, 120, 124, 128';
  //     const channels138 = '132, 136, 140, 144';
  //     const channels155 = '149, 153, 157, 161';
  //
  //     if (oldChannelsStr.indexOf(channels42) != -1) {
  //       newChannels.push(parseInt(channels42.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels42) != -1) {
  //       newChannels.push(parseInt(channels58.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels106) != -1) {
  //       newChannels.push(parseInt(channels106.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels122) != -1) {
  //       newChannels.push(parseInt(channels122.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels138) != -1) {
  //       newChannels.push(parseInt(channels138.split(', ')[0]));
  //     }
  //
  //     if (oldChannelsStr.indexOf(channels155) != -1) {
  //       newChannels.push(parseInt(channels155.split(', ')[0]));
  //     }
  //     calculatedCapabilities.channels = newChannels;
  //
  //     chanlist.forEach((channel) => {
  //       if (newChannels.indexOf(channel) === -1) {
  //       } else {
  //         avChanList.push(channel);
  //       }
  //     });
  //     return avChanList;
  //   }
  // },

  getCurrentBandwidthChannels(interfaceCapabilities, calculatedCapabilities, condition) {
    if (condition === 'HT40/HE40') {
      calculatedCapabilities.channels = calculatedCapabilities.channels.slice(1, -4);

      const avChannels = calculatedCapabilities.channels;
      const chanlist = interfaceCapabilities.selected.channels;
      const newChannels = [];
      chanlist.forEach((channel) => {
        if (avChannels.indexOf(channel) === -1) {
        } else {
          newChannels.push(channel);
        }
      });
      return newChannels;
    }
    if (condition === 'VHT40/HT40/HE40') {
      const oldChannels = calculatedCapabilities.channels;
      const chanlist = interfaceCapabilities.selected.channels;

      if (oldChannels[0] === 'auto') {
        oldChannels.splice(0, 1);
      }
      const oldChannelsStr = oldChannels.join(', ');

      const newChannels = [];
      const avChanList = [];
      const channels38 = '36, 40';
      const channels46 = '44, 48';
      const channels54 = '52, 56';
      const channels62 = '60, 64';
      const channels102 = '100, 104';
      const channels110 = '108, 112';
      const channels118 = '116, 120';
      const channels126 = '124, 128';
      const channels134 = '132, 136';
      const channels142 = '140, 144';
      const channels151 = '149, 153';
      const channels159 = '157, 161';
      if (oldChannelsStr.indexOf(channels38) != -1) {
        newChannels.push(parseInt(channels38.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels46) != -1) {
        newChannels.push(parseInt(channels46.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels54) != -1) {
        newChannels.push(parseInt(channels54.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels62) != -1) {
        newChannels.push(parseInt(channels62.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels102) != -1) {
        newChannels.push(parseInt(channels102.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels110) != -1) {
        newChannels.push(parseInt(channels110.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels118) != -1) {
        newChannels.push(parseInt(channels118.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels126) != -1) {
        newChannels.push(parseInt(channels126.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels134) != -1) {
        newChannels.push(parseInt(channels134.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels142) != -1) {
        newChannels.push(parseInt(channels142.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels151) != -1) {
        newChannels.push(parseInt(channels151.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels159) != -1) {
        newChannels.push(parseInt(channels159.split(', ')[0]));
      }

      calculatedCapabilities.channels = newChannels;

      chanlist.forEach((channel) => {
        if (newChannels.indexOf(channel) === -1) {
        } else {
          avChanList.push(channel);
        }
      });
      return avChanList;
    }
    if (condition === 'VHT80/HT80/HE80') {
      const oldChannels = calculatedCapabilities.channels;
      const chanlist = interfaceCapabilities.selected.channels;
      if (oldChannels[0] === 'auto') {
        oldChannels.splice(0, 1);
      }
      const oldChannelsStr = oldChannels.join(', ');
      const newChannels = [];
      const avChanList = [];

      const channels42 = '36, 40, 44, 48';
      const channels58 = '52, 56, 60, 64';
      const channels106 = '100, 104, 108, 112';
      const channels122 = '116, 120, 124, 128';
      const channels138 = '132, 136, 140, 144';
      const channels155 = '149, 153, 157, 161';

      if (oldChannelsStr.indexOf(channels42) != -1) {
        newChannels.push(parseInt(channels42.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels42) != -1) {
        newChannels.push(parseInt(channels58.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels106) != -1) {
        newChannels.push(parseInt(channels106.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels122) != -1) {
        newChannels.push(parseInt(channels122.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels138) != -1) {
        newChannels.push(parseInt(channels138.split(', ')[0]));
      }

      if (oldChannelsStr.indexOf(channels155) != -1) {
        newChannels.push(parseInt(channels155.split(', ')[0]));
      }
      calculatedCapabilities.channels = newChannels;

      chanlist.forEach((channel) => {
        if (newChannels.indexOf(channel) === -1) {
        } else {
          avChanList.push(channel);
        }
      });
      return avChanList;
    }
    if (condition === 'VHT80+80/VHT160/HE80+80/HE160') {
      const oldChannels = calculatedCapabilities.channels;
      const chanlist = interfaceCapabilities.selected.channels;
      if (oldChannels[0] === 'auto') {
        oldChannels.splice(0, 1);
      }
      const oldChannelsStr = oldChannels.join(', ');
      const newChannels = [];
      const avChanList = [];

      // console.log('oldChannelsStr', oldChannelsStr)

      const channels50 = '36, 40, 44, 48, 52, 56, 60, 64';
      const channels114 = '100, 104, 108, 112, 116, 120, 124, 128';
      const channels146 = '132, 136, 140, 144, 149, 153, 157, 161';

      if (oldChannelsStr.indexOf(channels50) != -1) {
        newChannels.push(parseInt(channels50.split(', ')[0]));
      }


      if (oldChannelsStr.indexOf(channels114) != -1) {
        newChannels.push(parseInt(channels114.split(', ')[0]));
      }


      if (oldChannelsStr.indexOf(channels146) != -1) {
        newChannels.push(parseInt(channels146.split(', ')[0]));
      }

      calculatedCapabilities.channels = newChannels;

      chanlist.forEach((channel) => {
        if (newChannels.indexOf(channel) === -1) {
        } else {
          avChanList.push(channel);
        }
      });
      return avChanList;
    }
  },

  isValidCaps(caps) {
    let result = true;
    for (const cap in caps) {
      const value = caps[cap];
      if (typeof value === 'string' && value == 'None') {
        result = false;
        break;
      } else if (Array.isArray(value) && !value.length) {
        result = false;
        break;
      } else if (typeof value === 'object' && !Object.keys(value).length) {
        result = false;
        break;
      } else {
        result = true;
        break;
      }
    }
    return result;
  }
};
