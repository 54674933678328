import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  // getPortalAds(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingPortalAds');
  //   const config = props.query ?
  //     {
  //       params: {
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
  //         ad_types: ctx.filters.ad_types
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/ad`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalAdsList', response.data.data);
  //         ctx.$store.commit('pushPortalAdsList', response.data.data);
  //         this.getAdsLocations(ctx, ctx.$store.state.portalAdsList);
  //         ctx.totalResultAds = response.data.total;
  //         if (ctx.$store.state.portalAdsList.length >= response.data.total) {
  //           ctx.canLoadMoreAds = false;
  //         } else {
  //           ctx.canLoadMoreAds = true;
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalAds');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalAds');
  //     }
  //   );
  // },
  getPortalAds(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalAds');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          ad_types: ctx.filters.ad_types,
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        }
      } :
      {
        params: {
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
          with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        }
      };
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    if (props.query && props.useLocationFilter) {
      if (ctx.filters?.location &&
        typeof ctx.filters.location === 'object' &&
        ctx.filters.location.data  &&
        typeof ctx.filters.location.data === 'object' ) {
        if (Object.keys(ctx.filters.location.data).length === 0) {
          config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
          config.params.with_childs = true;
        } else {
          config.params.location = ctx.filters.location.data.id;
          config.params.with_childs = ctx.filters.location.with_childs;
        }
      }
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/ad`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalAdsList', response.data.data);
          ctx.$store.commit('pushPortalAdsList', response.data.data);
          this.getAdsLocations(ctx, ctx.$store.state.portalAdsList);
          ctx.totalResultAds = response.data.total;
          if (ctx.$store.state.portalAdsList.length >= response.data.total) {
            ctx.canLoadMoreAds = false;
          } else {
            ctx.canLoadMoreAds = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalAds');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalAds');
      }
    );
  },
  createAd(ctx, ad) {
    ctx.$store.commit('toggleMutePortalAdEdit');
    const base_location = ad.base_location || ctx.$store.state.userData.base_location;
    // ad.loc_ids = ctx.updatedAdlocIds.map((location) => location.loc_id);
    Vue.axios
      .post(`${RESTAPI || ''}/api/portal/admin/ad`, {
        request: [ad],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('adsprofile.notificationAdPollCreatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalAdEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalAdEdit');
        }
      );
  },
  updateAd(ctx, ad) {
    ctx.$store.commit('toggleMutePortalAdEdit');
    const base_location = ad.base_location || ctx.$store.state.userData.base_location;
    delete ad.base_location;
    // ad.loc_ids = ctx.updatedAdlocIds.map((location) => location.loc_id);
    Vue.axios
      .put(`${RESTAPI || ''}/api/portal/admin/ad`, {
        request: [ad],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('adsprofile.notificationAdPollUpdatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalAdEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalAdEdit');
        }
      );
  },
  massUpdateAd(ctx, adsList) {
    ctx.$store.commit('toggleMutePortalAdEdit');
    const requests = [];
    for (const ad of adsList) {
      requests.push(
        Vue.axios.put(`${RESTAPI || ''}/api/portal/admin/ad`, ad).then(
          (response) => {
            if (response.status === 200) {
              // VueNotifications.success({ message: 'Ad Updated Successfully' });
              // ctx.getWithQuery(ctx);
              // ctx.cancelEditMode();
            } else {
              // VueNotifications.error({ message: response.data.description });
            }
            // ctx.$store.commit('toggleMutePortalAdEdit');
          },
          (err) => {
            // VueNotifications.error({ message: err });
            // ctx.error = err;
            // ctx.$store.commit('toggleMutePortalAdEdit');
          }
        )
      );
    }
    // Promise.all(requests).then(() => {
    //   ctx.getWithQuery(ctx);
    //   ctx.$store.commit('toggleMutePortalAdEdit');
    // });
    Promise.all(requests)
      .then(() => {
        ctx.getWithQueryAfterMassEdit(ctx);
        ctx.$store.commit('toggleMutePortalAdEdit');
        ctx.closeAllMassAdsEditModals();
        VueNotifications.success({ message: ctx.$t('adsprofile.massAdsUpdatedSuccess') });
      })
      .catch((err) => {
        ctx.$store.commit('toggleMutePortalAdEdit');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.closeAllMassAdsEditModals();
      });
  },
  deleteAd(ctx, profile) {
    ctx.deletingPageModal = false;
    ctx.$store.commit('toggleMutePortalAdEdit');
    Vue.axios
      .delete(`${RESTAPI || ''}/api/portal/admin/ad`, {
        params: {
          ids: [profile.id]
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('adsprofile.notificationAdPollDeletedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalAdEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalAdEdit');
        }
      );
  },
  getAdsLocations(ctx, ads) {
    // Запихиват в store объект, где ключи - id реклам и опросов, а значения - id локаций
    ctx.$store.commit('toggleLoadingPortalAdsLocations');
    const AdsIdsArray = [];
    for (const ad of ads) {
      AdsIdsArray.push(ad.id);
    }
    Vue.axios
      .post(`${RESTAPI || ''}/api/base_locations`, {
        action: 'R',
        ids: AdsIdsArray
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            const locationFromApi = response.data.data.itemslist;
            let loctationsToStore = {};
            if (response.data.data.itemslist && response.data.data.itemslist.length > 0) {
              for (const location of locationFromApi) {
                if (location.Obj.model === 'portal_ad') {
                  loctationsToStore[location.Obj.model_id] = location.location_id;
                }
              }
            } else {
              loctationsToStore = false;
            }
            ctx.$store.commit('setPortalAdsLocationsList', loctationsToStore);
            ctx.$store.commit('toggleLoadingPortalAdsLocations');
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('toggleLoadingPortalAdsLocations');
          }
        },
        (err) => {
          ctx.$store.commit('toggleLoadingPortalAdsLocations');
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },
  getAdStatistics(ctx) {
    ctx.$store.commit('toggleLoadingPortalAdsStats');
    const params = {
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/statistic`, {params}).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          ctx.$store.commit('setPortalAdsStatsList', response.data.data);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalAdsStats');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalAdsStats');
      }
    );
  },
  // getPortalAdsForMarketerDashboard(ctx, props = {}) {
  //   ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
  //   const config = props.query ?
  //     {
  //       params: {
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/ad`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         ctx.adsDataForMarketerDashboard = response.data.data;
  //         // ctx.$store.commit('setPortalAdsList', response.data.data);
  //         // ctx.$store.commit('pushPortalAdsList', response.data.data);
  //         // ctx.totalResultAds = response.data.total;
  //         // if (ctx.$store.state.portalAdsList.length >= response.data.total) {
  //         //  ctx.canLoadMoreAds = false;
  //         // } else {
  //         //  ctx.canLoadMoreAds = true;
  //         // }
  //         ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
  //     }
  //   );
  // },
  getPortalAdsForMarketerDashboard(ctx, props = {}) {
    ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
    const config = props.query ?
      {
        params: {
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
        }
      } :
      {};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }

    if (props.useLocationFilter) {
      if (!Object.prototype.hasOwnProperty.call(config, 'params')){
        config.params = {}
      }
      if (ctx.filters.location === 'All locations') {
        config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        config.params.with_childs = true;
      } else {
        config.params.location = ctx.filters.location;
        config.params.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/ad`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          ctx.adsDataForMarketerDashboard = response.data.data;
          // ctx.$store.commit('setPortalAdsList', response.data.data);
          // ctx.$store.commit('pushPortalAdsList', response.data.data);
          // ctx.totalResultAds = response.data.total;
          // if (ctx.$store.state.portalAdsList.length >= response.data.total) {
          //  ctx.canLoadMoreAds = false;
          // } else {
          //  ctx.canLoadMoreAds = true;
          // }
          ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleAdsLoadingForMarketerDashboard');
      }
    );
  },
  // getAdStatisticsForMarketerDashboard(ctx) {
  //   ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/statistic`).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         ctx.adsStatisticForMarketerDashboard = response.data.data;
  //         ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
  //     }
  //   );
  // },
  getAdStatisticsForMarketerDashboard(ctx, props = {}) {
    ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
    const  config = {}

    if (props.useLocationFilter) {
      if (!Object.prototype.hasOwnProperty.call(config, 'params')){
        config.params = {}
      }
      if (ctx.filters.location === 'All locations') {
        config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        config.params.with_childs = true;
      } else {
        config.params.location = ctx.filters.location;
        config.params.with_childs = ctx.filters.with_childs;
      }
    }


    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/statistic`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          ctx.adsStatisticForMarketerDashboard = response.data.data;
          ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleAdsStatsLoadingForMarketerDashboard');
      }
    );
  },
  getPortalAdsWithNoPagination(ctx) {
    // console.log('getPortalAdsWithNoPagination');
    // используется для получения списка реклам из разделов где не нужна пагинация
    // ( т.е не из раздела Реклам и Опросов )
    // нужна для исправления бага из задачи WNE-2282
    ctx.$store.commit('toggleLoadingPortalAds');

    const params = {
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }

    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/ad`, {params}).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          ctx.$store.commit('portalAdsListClean');
          ctx.$store.commit('pushPortalAdsListWithCheckForExistence', response.data.data);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalAds');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalAds');
      }
    );
  },
  async requestAds(searchQuery) {
    const config = {
      params: {
        search: searchQuery.toString(),
        limit: 20,
        sort: 'name',
        with_childs: true
      }
    };
    const json = await Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/ad`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },
  async requestAdsFilteredByLocationAndWithChilds(ctx, searchQuery) {
    const config = {
      params: {
        search: searchQuery.toString(),
        sort: 'name',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
      }
    };
    const json = await Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/ad`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  }
};
