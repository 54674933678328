import Vue from 'vue';
import SettingsService from '../services/settingsService';
import store from '../store';

// Go server URL http://104.199.110.5:8080
const LOGIN_URL = '/auth/login';
const LOGOUT_URL = '/auth/logout';
const SIGNUP_URL = '/users/';
const RESET_PASSWORD_GENERATE_URL = '/auth/resetGenerate';
const RESET_PASSWORD_URL = '/auth/reset';

export default {
  user: {
    authenticated: false
  },
  login(context, creds) {
    return Vue.axios.post(`${window.RESTAPI || ''}${LOGIN_URL}`, creds);
  },
  resetPasswordGenerate(context, username) {
    const payload = {
      username,
      redirect_uri: `${document.location.protocol}//${document.location.host}/resetPassword`
    };
    return Vue.axios.post(`${window.RESTAPI || ''}${RESET_PASSWORD_GENERATE_URL}`, payload);
  },
  newPasswordSet(context, token, username, password) {
    const payload = {
      username,
      token,
      password
    };
    return Vue.axios.post(`${window.RESTAPI || ''}${RESET_PASSWORD_URL}`, payload);
  },
  signup(ctx, creds, redirect) {
    ctx.axios.post(SIGNUP_URL, creds).then(
      (data) => {
        localStorage.setItem('id_token', data.id_token);
        localStorage.setItem('access_token', data.access_token);
        this.user.authenticated = true;
        if (redirect) {
          ctx.$router.push(redirect);
        }
      },
      (err) => {
        ctx.error = err;
      }
    );
  },
  logout(ctx) {
    ctx.axios.post(`${window.RESTAPI || ''}${LOGOUT_URL}`, {});
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    ctx.axios.defaults.headers.common.Authorization = '';
    this.user.authenticated = false;
    ctx.$router.push('/pages/login');
  },
  checkAuth() {
    const jwt = localStorage.getItem('id_token');
    if (!Vue.axios.defaults.headers.common.Authorization && jwt) {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    }
    let checkAuthQuery = null;
    if (store.state.userData.location != '') {
      checkAuthQuery = {};
      checkAuthQuery.ids = [store.state.userData.base_location];
      checkAuthQuery.fields = ['id', 'name'];
    }
    return SettingsService.getLocations(checkAuthQuery);
  },
  getAuthHeader() {
    return {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };
  }
};
