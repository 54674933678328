const Monitor = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/Monitor');
const Rules = () => import(/* webpackChunkName: "Rules" */ '../views/Monitor/Rules');
const Events = () => import(/* webpackChunkName: "Events" */ '../views/Monitor/Events');
const Sessions = () => import(/* webpackChunkName: "Sessions" */ '../views/Monitor/Sessions');
const RuleWizard = () => import(/* webpackChunkName: "RuleWizard" */ '../views/Monitor/RuleWizard');
const RuleStep1 = () => import(/* webpackChunkName: "RuleWizard" */ '../views/Monitor/RuleStep1');
const RuleStep2 = () => import(/* webpackChunkName: "RuleWizard" */ '../views/Monitor/RuleStep2');
const WifiAnalyzer = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/WifiAnalyzer');
const ClientRF = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/ClientRF');
const ClientDistance = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/ClientDistance');
const SNMPMonitoringConfig = () => import(/* webpackChunkName: "SNMPMonitoringConfig" */ '../views/Monitor/SNMPMonitoringConfig');
const DITStat = () => import(/* webpackChunkName: "DITStat" */ '../views/Monitor/DITStat');
const DBState = () => import(/* webpackChunkName: "DBState" */ '../views/Monitor/DBState');
const PeriodicTasks = () => import(/* webpackChunkName: "PeriodicTasks" */ '../views/Monitor/PeriodicTasks');

const MonitorRoute = {
  path: 'monitor',
  name: 'Monitor',
  component: Monitor,
  redirect: 'monitor/events',
  children: [
    {
      path: 'rules',
      name: 'Rules',
      component: Rules
    },
    {
      path: 'events',
      name: 'Events',
      component: Events
    },
    {
      path: 'wifi-analyzer',
      name: 'WifiAnalyzer',
      component: WifiAnalyzer
    },
    {
      path: 'client-rf',
      name: 'ClientRF',
      component: ClientRF
    },
    {
      path: 'client-distance',
      name: 'ClientDistance',
      component: ClientDistance
    },
    {
      path: 'snmp-monitoring-config',
      name: 'SNMPMonitoringConfig',
      component: SNMPMonitoringConfig
    },
    {
      path: 'dit-statistic',
      name: 'DITStat',
      component: DITStat
    },
    {
      path: 'db-state',
      name: 'DBState',
      component: DBState
    },
    {
      path: 'periodic-tasks',
      name: 'PeriodicTasks',
      component: PeriodicTasks
    },
    {
      path: 'sessions',
      name: 'Sessions',
      component: Sessions
    },
    {
      path: 'rule-wizard',
      name: 'rule-wizard',
      redirect: 'rule-wizard/rule-step-1',
      component: RuleWizard,
      children: [
        {
          path: 'rule-step-1',
          name: 'rule-step-1',
          component: RuleStep1
        },
        {
          path: 'rule-step-2',
          name: 'rule-step-2',
          component: RuleStep2
        }
      ]
    }
  ]
};

export default MonitorRoute;
