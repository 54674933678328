import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import cpePollingService from './cpePollingService';
import store from '../store';

const Queries = {
  WIFI_RADAR_ENABLED: { 'config.lbs_config.enabled': true },
  DHCP_CAPTURE_DISABLED: { 'config.dhcpcap_config.enabled': false },
  DHCP_CAPTURE_ENABLED: { 'config.dhcpcap_config.enabled': true },
  WIFI_SCANNING_ENABLED: { 'config.wifi.scanconfig.enabled': true },
  WIRED_NOT_ACTIVE: { 'config.wired.vlans': { $eq: [] } },
  WIRED_ACTIVE: { 'config.wired': { $gt: {} } },
  WIRED: { 'config.wired': { $gt: {} } },
  WIRELESS_ACTIVE: { 'state.wifi.enabled': true },
  WIRELESS_NOT_ACTIVE: { 'state.wifi.enabled': { $ne: true } },
  LAST_ERROR: { 'last_error.type': { $gt: '' } },
  NO_ZERO_TIMESTAMP_FOR_FIRST_CONNECTION: { first_connection: { $gt: 0 } },
  NO_ZERO_TIMESTAMP_FOR_LAST_CONNECTION: { last_connection: { $gt: 0 } },
  NO_ZERO_TIMESTAMP_FOR_LAST_DISCONNECTION: { last_disconnection: { $gt: 0 } },
  // UPDATING_STATUS: { configstatus: { $eq: 'updating' } },
  UPDATING_STATUS: { configstatus: { $in: ['updating', 'upgrading'] } }
};

export default {
  // getQuery(ctx, typeOfQuery) {
  //   let query = {
  //     action: 'R',
  //     with: ['clients', 'rrm_group'],
  //     search: ctx.searchValue,
  //     page: {},
  //     sort_by: {
  //       field: 'connected',
  //       order: -1
  //     },
  //     q: {}
  //   };
  //
  //   if (typeOfQuery === 'paginate') {
  //     query.page.limit = ctx.limit;
  //     query.page.offset = ctx.limit * ctx.offset;
  //   } else if (typeOfQuery === 'update') {
  //     query.page.limit = ctx.limit + ctx.limit * ctx.offset;
  //     query.page.offset = 0;
  //   }
  //
  //   let cpesIdsOfGroup = [];
  //   if (ctx.filters.group && ctx.filters.group !== 'No RRM filter') {
  //     for (const rrm of ctx.rrmList) {
  //       if (rrm.id === ctx.filters.group) {
  //         cpesIdsOfGroup = rrm.cpes;
  //         break;
  //       }
  //     }
  //
  //     query = Object.assign(query, { ids: cpesIdsOfGroup });
  //   }
  //
  //   // if (ctx.filters.hasOwnProperty('company') && ctx.filters.company !== 'All companys') {
  //   //   ctx.filters.location = 'All locations';
  //   //   query.q = Object.assign(query.q, { base_location: ctx.filters.company.locationId });
  //   // }
  //
  //   if (ctx.filters.location !== 'All locations') {
  //     query.q = Object.assign(query.q, { base_location: ctx.filters.location });
  //   }
  //   if (ctx.filters.tag !== 'All tags') {
  //     query.q = Object.assign(query.q, { tag: ctx.filters.tag });
  //   }
  //   if (ctx.filters.models !== 'All models') {
  //     query.q = Object.assign(query.q, { 'model.id': ctx.filters.models });
  //   }
  //   if (ctx.filters.activeFilter !== null) {
  //     switch (ctx.filters.interfacesFilter) {
  //       case 1:
  //       case 2:
  //         if (ctx.filters.activeFilter) {
  //           query.q = Object.assign(query.q, { 'state.wifi.enabled': ctx.filters.activeFilter });
  //         } else {
  //           query.q = Object.assign(query.q, Queries.WIRELESS_NOT_ACTIVE);
  //         }
  //         break;
  //       case 3:
  //         if (ctx.filters.activeFilter) {
  //           query.q = Object.assign(query.q, Queries.WIRED_ACTIVE);
  //         } else {
  //           query.q = Object.assign(query.q, Queries.WIRED_NOT_ACTIVE);
  //         }
  //         break;
  //       default:
  //         if (ctx.filters.activeFilter) {
  //           query.q = Object.assign(query.q, { $or: [Queries.WIRED_ACTIVE, Queries.WIRELESS_ACTIVE] });
  //         } else {
  //           query.q = Object.assign(query.q, { $or: [Queries.WIRED_NOT_ACTIVE, Queries.WIRELESS_NOT_ACTIVE] });
  //         }
  //     }
  //   }
  //   // NOTE:  Эта штука ломала RRM с ней надо быть осторожнее
  //   if ((ctx.filters.lastError === true && ctx.showDistressed) || ctx.filters.lastError === true) {
  //     query.q = Object.assign(query.q, Queries.LAST_ERROR);
  //   }
  //   // console.log(ctx.showDistressed, 'showDistressed');
  //   // console.log(ctx.filters.lastError, 'lastError');
  //
  //   if (ctx.filters.connectedFilter !== null) {
  //     query.q = Object.assign(query.q, { connected: ctx.filters.connectedFilter });
  //   }
  //   if (ctx.filters.interfacesFilter !== null) {
  //     switch (ctx.filters.interfacesFilter) {
  //       case 1:
  //       case 2:
  //         query.q = Object.assign(query.q, { 'state.wifi': { $size: ctx.filters.interfacesFilter } });
  //         break;
  //       case 3:
  //         query.q = Object.assign(query.q, Queries.WIRED);
  //         break;
  //       default:
  //     }
  //   }
  //   if (ctx.filters.radarFilter !== null) {
  //     query.q = Object.assign(query.q, Queries.WIFI_RADAR_ENABLED);
  //   }
  //   if (ctx.filters.scanningFilter !== null) {
  //     query.q = Object.assign(query.q, Queries.WIFI_SCANNING_ENABLED);
  //   }
  //
  //   // если выбрана какая-либо сортировка времени подкл./откл.
  //   if (
  //     ctx.filters.hasOwnProperty('connectionOrDisconnectionTimeFilter') &&
  //     ctx.filters.connectionOrDisconnectionTimeFilter !== 'No Connection Or Disconnection Filter'
  //   ) {
  //     let connectDisconnectSortOrder;
  //     if (ctx.filters.connectionOrDisconnectionTimeFilterOrder === 'desc') {
  //       connectDisconnectSortOrder = -1;
  //     } else {
  //       connectDisconnectSortOrder = 1;
  //     }
  //     let startDate;
  //     let endDate;
  //     // если включена фильтрация по времени переводим выбранные в datepicker'e даты в unix формат
  //     if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
  //       startDate = parseInt((ctx.filters.connectionOrDisconnectionTimeFilterDateRange.startDate / 1000).toFixed(0));
  //       endDate = parseInt((ctx.filters.connectionOrDisconnectionTimeFilterDateRange.endDate / 1000).toFixed(0));
  //     }
  //     // если сортировка по первому подключению
  //     if (ctx.filters.connectionOrDisconnectionTimeFilter === 'sortByFirstConnection') {
  //       if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
  //         query.q = Object.assign(query.q, {
  //           first_connection: {
  //             $gte: startDate,
  //             $lte: endDate
  //           }
  //         });
  //       } else {
  //         query.q = Object.assign(query.q, Queries.NO_ZERO_TIMESTAMP_FOR_FIRST_CONNECTION);
  //       }
  //       query.sort_by = {
  //         field: 'first_connection',
  //         order: connectDisconnectSortOrder
  //       };
  //     }
  //     // если сортировка по последнему подключению
  //     if (ctx.filters.connectionOrDisconnectionTimeFilter === 'sortByLastConnection') {
  //       if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
  //         query.q = Object.assign(query.q, {
  //           last_connection: {
  //             $gte: startDate,
  //             $lte: endDate
  //           }
  //         });
  //       } else {
  //         query.q = Object.assign(query.q, Queries.NO_ZERO_TIMESTAMP_FOR_LAST_CONNECTION);
  //       }
  //       query.sort_by = {
  //         field: 'last_connection',
  //         order: connectDisconnectSortOrder
  //       };
  //     }
  //     // если сортировка по последнему отключению
  //     if (ctx.filters.connectionOrDisconnectionTimeFilter === 'sortByLastDisconnection') {
  //       if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
  //         query.q = Object.assign(query.q, {
  //           last_disconnection: {
  //             $gte: startDate,
  //             $lte: endDate
  //           }
  //         });
  //       } else {
  //         query.q = Object.assign(query.q, Queries.NO_ZERO_TIMESTAMP_FOR_LAST_DISCONNECTION);
  //       }
  //       query.sort_by = {
  //         field: 'last_disconnection',
  //         order: connectDisconnectSortOrder
  //       };
  //     }
  //   }
  //   // филтр по точкам, которые в статусе апдейта
  //   if (ctx.filters.hasOwnProperty('updatingStatusCPEs') && ctx.filters.updatingStatusCPEs !== null) {
  //     query.q = Object.assign(query.q, Queries.UPDATING_STATUS);
  //   }
  //   return query;
  // },

  getQuery(ctx, typeOfQuery) {
    let query = {
      action: 'R',
      with: ['clients', 'rrm_group'],
      search: ctx.searchValue,
      page: {},
      sort_by: {
        field: 'connected',
        order: -1
      },
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      q: {}
    };

    if (typeOfQuery === 'paginate') {
      query.page.limit = ctx.limit;
      query.page.offset = ctx.limit * ctx.offset;
    } else if (typeOfQuery === 'update') {
      query.page.limit = ctx.limit + ctx.limit * ctx.offset;
      query.page.offset = 0;
    }

    let cpesIdsOfGroup = [];
    if (ctx.filters.group && ctx.filters.group !== 'No RRM filter') {
      for (const rrm of ctx.rrmList) {
        if (rrm.id === ctx.filters.group) {
          cpesIdsOfGroup = rrm.cpes;
          break;
        }
      }

      query = Object.assign(query, { ids: cpesIdsOfGroup });
    }

    // if (ctx.filters.hasOwnProperty('company') && ctx.filters.company !== 'All companys') {
    //   ctx.filters.location = 'All locations';
    //   query.q = Object.assign(query.q, { base_location: ctx.filters.company.locationId });
    // }

    // if (ctx.filters.location !== 'All locations') {
    //   query.q = Object.assign(query.q, { base_location: ctx.filters.location });
    // }
    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        // console.log('ctx.userBaseLocation', ctx.userBaseLocation);
        if (ctx.userBaseLocation ) {
          query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        }
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    if (ctx.filters.tag !== 'All tags') {
      query.q = Object.assign(query.q, { tag: ctx.filters.tag });
    }
    if (ctx.filters.models !== 'All models') {
      query.q = Object.assign(query.q, { 'model.id': ctx.filters.models });
    }
    if (ctx.filters.activeFilter !== null) {
      switch (ctx.filters.interfacesFilter) {
        case 1:
        case 2:
          if (ctx.filters.activeFilter) {
            query.q = Object.assign(query.q, { 'state.wifi.enabled': ctx.filters.activeFilter });
          } else {
            query.q = Object.assign(query.q, Queries.WIRELESS_NOT_ACTIVE);
          }
          break;
        case 3:
          if (ctx.filters.activeFilter) {
            query.q = Object.assign(query.q, Queries.WIRED_ACTIVE);
          } else {
            query.q = Object.assign(query.q, Queries.WIRED_NOT_ACTIVE);
          }
          break;
        default:
          if (ctx.filters.activeFilter) {
            query.q = Object.assign(query.q, { $or: [Queries.WIRED_ACTIVE, Queries.WIRELESS_ACTIVE] });
          } else {
            query.q = Object.assign(query.q, { $or: [Queries.WIRED_NOT_ACTIVE, Queries.WIRELESS_NOT_ACTIVE] });
          }
      }
    }
    // NOTE:  Эта штука ломала RRM с ней надо быть осторожнее
    if ((ctx.filters.lastError === true && ctx.showDistressed) || ctx.filters.lastError === true) {
      query.q = Object.assign(query.q, Queries.LAST_ERROR);
    }
    // console.log(ctx.showDistressed, 'showDistressed');
    // console.log(ctx.filters.lastError, 'lastError');
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'DHCPCaptureFilter') &&
      ctx.filters.DHCPCaptureFilter !== null) {
      switch (ctx.filters.DHCPCaptureFilter) {
        case 'enable':
          query.q = Object.assign(query.q, Queries.DHCP_CAPTURE_ENABLED);
          break;
        case 'disable':
          query.q = Object.assign(query.q, Queries.DHCP_CAPTURE_DISABLED);
          break;
        default:
      }
    }

    if (ctx.filters.connectedFilter !== null) {
      query.q = Object.assign(query.q, { connected: ctx.filters.connectedFilter });
    }
    if (ctx.filters.interfacesFilter !== null) {
      switch (ctx.filters.interfacesFilter) {
        case 1:
        case 2:
          query.q = Object.assign(query.q, { 'state.wifi': { $size: ctx.filters.interfacesFilter } });
          break;
        case 3:
          query.q = Object.assign(query.q, Queries.WIRED);
          break;
        default:
      }
    }
    if (ctx.filters.radarFilter !== null) {
      query.q = Object.assign(query.q, Queries.WIFI_RADAR_ENABLED);
    }
    if (ctx.filters.scanningFilter !== null) {
      query.q = Object.assign(query.q, Queries.WIFI_SCANNING_ENABLED);
    }

    // если выбрана какая-либо сортировка времени подкл./откл.
    if (
      ctx.filters.hasOwnProperty('connectionOrDisconnectionTimeFilter') &&
      ctx.filters.connectionOrDisconnectionTimeFilter !== 'No Connection Or Disconnection Filter'
    ) {
      let connectDisconnectSortOrder;
      if (ctx.filters.connectionOrDisconnectionTimeFilterOrder === 'desc') {
        connectDisconnectSortOrder = -1;
      } else {
        connectDisconnectSortOrder = 1;
      }
      let startDate;
      let endDate;
      // если включена фильтрация по времени переводим выбранные в datepicker'e даты в unix формат
      if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
        startDate = parseInt((ctx.filters.connectionOrDisconnectionTimeFilterDateRange.startDate / 1000).toFixed(0));
        endDate = parseInt((ctx.filters.connectionOrDisconnectionTimeFilterDateRange.endDate / 1000).toFixed(0));
      }
      // если сортировка по первому подключению
      if (ctx.filters.connectionOrDisconnectionTimeFilter === 'sortByFirstConnection') {
        if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
          query.q = Object.assign(query.q, {
            first_connection: {
              $gte: startDate,
              $lte: endDate
            }
          });
        } else {
          query.q = Object.assign(query.q, Queries.NO_ZERO_TIMESTAMP_FOR_FIRST_CONNECTION);
        }
        query.sort_by = {
          field: 'first_connection',
          order: connectDisconnectSortOrder
        };
      }
      // если сортировка по последнему подключению
      if (ctx.filters.connectionOrDisconnectionTimeFilter === 'sortByLastConnection') {
        if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
          query.q = Object.assign(query.q, {
            last_connection: {
              $gte: startDate,
              $lte: endDate
            }
          });
        } else {
          query.q = Object.assign(query.q, Queries.NO_ZERO_TIMESTAMP_FOR_LAST_CONNECTION);
        }
        query.sort_by = {
          field: 'last_connection',
          order: connectDisconnectSortOrder
        };
      }
      // если сортировка по последнему отключению
      if (ctx.filters.connectionOrDisconnectionTimeFilter === 'sortByLastDisconnection') {
        if (ctx.filters.connectionOrDisconnectionTimeFilterByDateRange === true) {
          query.q = Object.assign(query.q, {
            last_disconnection: {
              $gte: startDate,
              $lte: endDate
            }
          });
        } else {
          query.q = Object.assign(query.q, Queries.NO_ZERO_TIMESTAMP_FOR_LAST_DISCONNECTION);
        }
        query.sort_by = {
          field: 'last_disconnection',
          order: connectDisconnectSortOrder
        };
      }
    }
    // филтр по точкам, которые в статусе апдейта
    if (ctx.filters.hasOwnProperty('updatingStatusCPEs') && ctx.filters.updatingStatusCPEs !== null) {
      query.q = Object.assign(query.q, Queries.UPDATING_STATUS);
    }
    return query;
  },


  //   getAllCpes(ctx) {
  //   ctx.$store.commit('toggleLoadingCpes');
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, { action: 'R', with: ['clients'] }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         const itemsList = response.data.data.itemslist;
  //         itemsList.forEach((cpe) => {
  //           if (cpe.config.wifi) {
  //             cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
  //           }
  //           if (cpe.config.wired) {
  //             cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
  //           }
  //         });
  //         ctx.cpesList = itemsList;
  //         ctx.$store.commit('setCpesList', itemsList);
  //         ctx.$store.commit('toggleLoadingCpes');
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('toggleLoadingCpes');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingCpes');
  //       ctx.error = err;
  //     }
  //   );
  // },


  getAllCpes(ctx) {
    const cancelToken = Vue.axios.CancelToken.source();
    const requestName = 'getAllCpes';
    const componentName = ctx.$route.name;
    ctx.$store.commit('addRequestCancelTokenToRequestsCancelTokens', { componentName, requestName, cancelToken })
    ctx.$store.commit('toggleLoadingCpes');
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx), with: ['clients']},  {cancelToken: cancelToken.token }).then(
      (response) => {
        if (response.data.status === 'success') {
          const itemsList = response.data.data.itemslist;
          itemsList.forEach((cpe) => {
            if (cpe.config.wifi) {
              cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
            }
            if (cpe.config.wired) {
              cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
            }
          });
          ctx.cpesList = itemsList;
          ctx.$store.commit('setCpesList', itemsList);
          ctx.$store.commit('toggleLoadingCpes');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleLoadingCpes');
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          if (Vue.axios.isCancel(err)){
            console.log(`${requestName} request for ${componentName} is canceled`)
          }else{
            VueNotifications.error({ message: err });
          }

        }

        ctx.$store.commit('toggleLoadingCpes');
        ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName})
        ctx.error = err;
      }
    );
  },
  // // for wifi analyze
  // getPaginatedCpesForWifiAnalyze(ctx, limit, offset) {
  //   ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //   const params = {
  //     action: 'R',
  //     // with: ['clients'],
  //     page: { limit, offset },
  //     q: { 'config.wifi.scanconfig.enabled': true }
  //   };
  //   if (ctx.searchString !== '') {
  //     params.search = ctx.searchString;
  //   }
  //   if (ctx.searchLocationId !== 'All locations') {
  //     params.q.base_location = ctx.searchLocationId;
  //   }
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, params).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.totalResult = response.data.data.total;
  //         const itemsList = response.data.data.itemslist;
  //         itemsList.forEach((cpe) => {
  //           if (cpe.config.wifi) {
  //             cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
  //           }
  //           if (cpe.config.wired) {
  //             cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
  //           }
  //         });
  //         ctx.cpesList = itemsList;
  //         ctx.$store.commit('pushCpesListForWifiAnalyze', itemsList);
  //         if (ctx.$store.state.cpesListForWifiAnalyze.length >= response.data.data.total) {
  //           ctx.canLoadMore = false;
  //         } else {
  //           ctx.canLoadMore = true;
  //         }
  //         ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //       ctx.error = err;
  //     }
  //   );
  // },

  // // for wifi analyze
  // getPaginatedCpesForWifiAnalyze(ctx, limit, offset) {
  //   ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //   const params = {
  //     action: 'R',
  //     // with: ['clients'],
  //     page: { limit, offset },
  //     q: { 'config.wifi.scanconfig.enabled': true }
  //   };
  //   if (ctx.searchString !== '') {
  //     params.search = ctx.searchString;
  //   }
  //   if (ctx.searchLocationId !== 'All locations') {
  //     params.q.base_location = ctx.searchLocationId;
  //   }
  //   if (ctx.filters?.location) {
  //     if (ctx.filters.location === 'All locations') {
  //       params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
  //       params.with_childs = true;
  //     } else {
  //       params.location = ctx.filters.location;
  //       params.with_childs = ctx.filters.with_childs;
  //     }
  //   }
  //
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, params).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.totalResult = response.data.data.total;
  //         const itemsList = response.data.data.itemslist;
  //         itemsList.forEach((cpe) => {
  //           if (cpe.config.wifi) {
  //             cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
  //           }
  //           if (cpe.config.wired) {
  //             cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
  //           }
  //         });
  //         ctx.cpesList = itemsList;
  //         ctx.$store.commit('pushCpesListForWifiAnalyze', itemsList);
  //         if (ctx.$store.state.cpesListForWifiAnalyze.length >= response.data.data.total) {
  //           ctx.canLoadMore = false;
  //         } else {
  //           ctx.canLoadMore = true;
  //         }
  //         ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
  //       ctx.error = err;
  //     }
  //   );
  // },

  // for wifi analyze
  getPaginatedCpesForWifiAnalyze(ctx, limit, offset) {
    ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      // with: ['clients'],
      page: { limit, offset },
      q: { 'config.wifi.scanconfig.enabled': true }
    };
    if (ctx.searchString !== '') {
      params.search = ctx.searchString;
    }
    // if (ctx.searchLocationId !== 'All locations') {
    //   params.q.base_location = ctx.searchLocationId;
    // }
    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          const itemsList = response.data.data.itemslist;
          itemsList.forEach((cpe) => {
            if (cpe.config.wifi) {
              cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
            }
            if (cpe.config.wired) {
              cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
            }
          });
          ctx.cpesList = itemsList;
          ctx.$store.commit('pushCpesListForWifiAnalyze', itemsList);
          if (ctx.$store.state.cpesListForWifiAnalyze.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingCpesForWifiAnalyze');
        ctx.error = err;
      }
    );
  },

  getAllCompactCpes(ctx) {
    ctx.$store.commit('toggleLoadingCpes');
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes/compact`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        sort_by: {
          field: 'connected',
          order: -1
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const itemsList = response.data.data.itemslist;
            ctx.$store.commit('setCpesList', itemsList);
            ctx.$store.commit('toggleLoadingCpes');
          } else if (response.data.status === 'error') {
            ctx.$store.commit('toggleLoadingCpes');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleLoadingCpes');
          ctx.error = err;
        }
      );
  },

  // getAllCompactCpesWithDataRemaining(ctx) {
  //   ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestBegin');
  //   const paramsForRequest = {
  //     action: 'R',
  //     location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  //     with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
  //     sort_by: {
  //       field: 'connected',
  //       order: -1
  //     }
  //   }
  //
  //   Vue.axios
  //     .post(`${window.RESTAPI || ''}/api/cpes/compact`, paramsForRequest)
  //     .then(
  //       (response) => {
  //         if (response.data.status === 'success') {
  //           let loaded;
  //           let total;
  //           const dataFromResponse = [];
  //           if (response.data.data.itemslist[0]) {
  //             // console.log(response);
  //             loaded = response.data.data.count;
  //             total = response.data.data.total;
  //             for (const item of response.data.data.itemslist) {
  //               dataFromResponse.push(item);
  //             }
  //           }
  //           if (loaded && total) {
  //             // console.log('loaded', loaded);
  //             // console.log('total', total);
  //             if (loaded < total) {
  //               const offset = loaded;
  //               const limit = total - loaded;
  //               if (limit > 0 && offset > 0) {
  //                 // console.log('limit', limit);
  //                 // console.log('offset', offset);
  //                 this.getAllCompactCpesRemainingData(ctx, limit, offset, dataFromResponse, paramsForRequest);
  //               } else {
  //                 ctx.$store.commit('setCpesList', dataFromResponse);
  //                 ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //                 // this.clientRFData = dataFromResponse;
  //               }
  //             } else {
  //               ctx.$store.commit('setCpesList', dataFromResponse);
  //               ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //               // this.clientRFData = dataFromResponse;
  //             }
  //           } else {
  //             ctx.$store.commit('setCpesList', dataFromResponse);
  //             ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //             // this.clientRFData = dataFromResponse;
  //           }
  //         } else if (response.data.status === 'error') {
  //           ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //           VueNotifications.error({message: response.data.description});
  //         }
  //       },
  //       (err) => {
  //         VueNotifications.error({message: err});
  //         ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //         ctx.error = err;
  //       }
  //     );
  // },
  //
  // getAllCompactCpesRemainingData(ctx, limit, offset, dataFromFirstReq, paramsForRequestFromFirstReq) {
  //   if (!limit || !offset) {
  //     ctx.$store.commit('setCpesList', dataFromFirstReq);
  //     return;
  //   }
  //   const praramsForRequest = JSON.parse(JSON.stringify(paramsForRequestFromFirstReq));
  //   praramsForRequest.page = {limit, offset};
  //
  //
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, praramsForRequest).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         if (response.data.data.itemslist[0]) {
  //           for (const item of response.data.data.itemslist) {
  //             dataFromFirstReq.push(item);
  //           }
  //         }
  //         ctx.$store.commit('setCpesList', dataFromFirstReq);
  //
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('setCpesList', dataFromFirstReq);
  //
  //         VueNotifications.error({message: response.data.description});
  //       }
  //       ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //     },
  //     (err) => {
  //       ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //       ctx.$store.commit('setCpesList', dataFromFirstReq);
  //       VueNotifications.error({message: err});
  //     }
  //   );
  // },

  // getAllCompactCpesWithDataRemaining(ctx, locationId= undefined, with_childs = undefined) {
  //   ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestBegin');
  //   ctx.$store.commit('loadingCpesRequestBegin');
  //   const paramsForRequest = {
  //     action: 'R',
  //     location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  //     with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
  //     sort_by: {
  //       field: 'connected',
  //       order: -1
  //     }
  //   }
  //   if (locationId !== undefined) {
  //     paramsForRequest.location = locationId;
  //   }
  //   if (with_childs !== undefined) {
  //     paramsForRequest.with_childs = with_childs;
  //   }
  //
  //   Vue.axios
  //     .post(`${window.RESTAPI || ''}/api/cpes/compact`, paramsForRequest)
  //     .then(
  //       (response) => {
  //         if (response.data.status === 'success') {
  //           let loaded;
  //           let total;
  //           const dataFromResponse = [];
  //           if (response.data.data.itemslist[0]) {
  //             // console.log(response);
  //             loaded = response.data.data.count;
  //             total = response.data.data.total;
  //             for (const item of response.data.data.itemslist) {
  //               dataFromResponse.push(item);
  //             }
  //           }
  //           if (loaded && total) {
  //             // console.log('loaded', loaded);
  //             // console.log('total', total);
  //             if (loaded < total) {
  //               const offset = loaded;
  //               const limit = total - loaded;
  //               if (limit > 0 && offset > 0) {
  //                 // console.log('limit', limit);
  //                 // console.log('offset', offset);
  //                 this.getAllCompactCpesRemainingData(ctx, limit, offset, dataFromResponse, paramsForRequest);
  //               } else {
  //                 ctx.$store.commit('setCpesList', dataFromResponse);
  //                 ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //                 ctx.$store.commit('loadingCpesRequestEnd');
  //                 // this.clientRFData = dataFromResponse;
  //               }
  //             } else {
  //               ctx.$store.commit('setCpesList', dataFromResponse);
  //               ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //               ctx.$store.commit('loadingCpesRequestEnd');
  //               // this.clientRFData = dataFromResponse;
  //             }
  //           } else {
  //             ctx.$store.commit('setCpesList', dataFromResponse);
  //             ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //             ctx.$store.commit('loadingCpesRequestEnd');
  //             // this.clientRFData = dataFromResponse;
  //           }
  //         } else if (response.data.status === 'error') {
  //           ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //           ctx.$store.commit('loadingCpesRequestEnd');
  //           VueNotifications.error({message: response.data.description});
  //         }
  //       },
  //       (err) => {
  //         VueNotifications.error({message: err});
  //         ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //         ctx.$store.commit('loadingCpesRequestEnd');
  //         ctx.error = err;
  //       }
  //     );
  // },
  //
  // getAllCompactCpesRemainingData(ctx, limit, offset, dataFromFirstReq, paramsForRequestFromFirstReq) {
  //   if (!limit || !offset) {
  //     ctx.$store.commit('setCpesList', dataFromFirstReq);
  //     ctx.$store.commit('loadingCpesRequestEnd');
  //     ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //     return;
  //   }
  //   const praramsForRequest = JSON.parse(JSON.stringify(paramsForRequestFromFirstReq));
  //   praramsForRequest.page = {limit, offset};
  //
  //
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, praramsForRequest).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         if (response.data.data.itemslist[0]) {
  //           for (const item of response.data.data.itemslist) {
  //             dataFromFirstReq.push(item);
  //           }
  //         }
  //         ctx.$store.commit('setCpesList', dataFromFirstReq);
  //
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('setCpesList', dataFromFirstReq);
  //
  //         VueNotifications.error({message: response.data.description});
  //       }
  //       ctx.$store.commit('loadingCpesRequestEnd');
  //       ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //     },
  //     (err) => {
  //       ctx.$store.commit('loadingCpesRequestEnd');
  //       ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
  //       ctx.$store.commit('setCpesList', dataFromFirstReq);
  //       VueNotifications.error({message: err});
  //     }
  //   );
  // },

  getAllCompactCpesWithDataRemaining(ctx, locationId= undefined, with_childs = undefined) {
    const cancelToken = Vue.axios.CancelToken.source();
    const requestName = 'getAllCompactCpesWithDataRemaining';
    const componentName = ctx.$route.name;
    ctx.$store.commit('addRequestCancelTokenToRequestsCancelTokens', { componentName, requestName, cancelToken })
    ctx.$store.commit('loadingCpesRequestBegin');
    ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestBegin');
    const paramsForRequest = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      sort_by: {
        field: 'connected',
        order: -1
      }
    }
    if (locationId !== undefined) {
      paramsForRequest.location = locationId;
    }
    if (with_childs !== undefined) {
      paramsForRequest.with_childs = with_childs;
    }

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes/compact`, paramsForRequest, {cancelToken: cancelToken.token })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            let loaded;
            let total;
            const dataFromResponse = [];
            if (response.data.data.itemslist[0]) {
              // console.log(response);
              loaded = response.data.data.count;
              total = response.data.data.total;
              for (const item of response.data.data.itemslist) {
                dataFromResponse.push(item);
              }
            }
            if (loaded && total) {
              // console.log('loaded', loaded);
              // console.log('total', total);
              if (loaded < total) {
                const offset = loaded;
                const limit = total - loaded;
                if (limit > 0 && offset > 0) {
                  // console.log('limit', limit);
                  // console.log('offset', offset);
                  this.getAllCompactCpesRemainingData(ctx, limit, offset, dataFromResponse, paramsForRequest, cancelToken, componentName, requestName );
                } else {
                  ctx.$store.commit('setCpesList', dataFromResponse);
                  ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
                  ctx.$store.commit('loadingCpesRequestEnd');
                  ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
                  // this.clientRFData = dataFromResponse;
                }
              } else {
                ctx.$store.commit('setCpesList', dataFromResponse);
                ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
                ctx.$store.commit('loadingCpesRequestEnd');
                ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
                // this.clientRFData = dataFromResponse;
              }
            } else {
              ctx.$store.commit('setCpesList', dataFromResponse);
              ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
              ctx.$store.commit('loadingCpesRequestEnd');
              ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
              // this.clientRFData = dataFromResponse;
            }
          } else if (response.data.status === 'error') {
            ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
            ctx.$store.commit('loadingCpesRequestEnd');
            ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
            VueNotifications.error({message: response.data.description});
          }
        },
        (err) => {
          if (Vue.axios.isCancel(err)){
            console.log(`${requestName} request for ${componentName} is canceled`)
          }else{
            VueNotifications.error({ message: err });
          }
          ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
          ctx.$store.commit('loadingCpesRequestEnd');
          ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
          ctx.error = err;
        }
      );
  },

  getAllCompactCpesRemainingData(ctx, limit, offset, dataFromFirstReq, paramsForRequestFromFirstReq, cancelToken, componentName, requestName) {
    if (!limit || !offset) {
      ctx.$store.commit('setCpesList', dataFromFirstReq);
      ctx.$store.commit('loadingCpesRequestEnd');
      ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
      ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
      return;
    }
    const praramsForRequest = JSON.parse(JSON.stringify(paramsForRequestFromFirstReq));
    praramsForRequest.page = {limit, offset};


    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, praramsForRequest, {cancelToken: cancelToken.token }).then(
      (response) => {
        if (response.data.status === 'success') {
          if (response.data.data.itemslist[0]) {
            for (const item of response.data.data.itemslist) {
              dataFromFirstReq.push(item);
            }
          }
          ctx.$store.commit('setCpesList', dataFromFirstReq);

        } else if (response.data.status === 'error') {
          ctx.$store.commit('setCpesList', dataFromFirstReq);

          VueNotifications.error({message: response.data.description});
        }
        ctx.$store.commit('loadingCpesRequestEnd');
        ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
        ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
      },
      (err) => {
        if (Vue.axios.isCancel(err)){
            console.log(`${requestName} request part 2 for ${componentName} is canceled`)
          }else{
            VueNotifications.error({ message: err });
          }
        ctx.$store.commit('loadingCpesRequestEnd');
        ctx.$store.commit('setGetAllCompactCpesWithDataRemainingRequestEnd');
        ctx.$store.commit('clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', { componentName, requestName })
        ctx.$store.commit('setCpesList', dataFromFirstReq);
       //  VueNotifications.error({message: err});
      }
    );
  },


  getAllCompactCpesWithLocationFiltering(ctx) {
    ctx.$store.commit('toggleLoadingCpes');
    const payload = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      sort_by: {
        field: 'connected',
        order: -1
      }
    }

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        payload.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        payload.with_childs = true;
      } else {
        payload.location = ctx.filters.location;
        payload.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes/compact`, payload)
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const itemsList = response.data.data.itemslist;
            ctx.$store.commit('setCpesList', itemsList);
            ctx.$store.commit('toggleLoadingCpes');
          } else if (response.data.status === 'error') {
            ctx.$store.commit('toggleLoadingCpes');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleLoadingCpes');
          ctx.error = err;
        }
      );
  },

  // getPaginatedCpes(ctx) {
  //   ctx.$store.commit('toggleMuteCpeFilters', true);
  //
  //   ctx.$store.commit('toggleLoadingCpes');
  //   const query = this.getQuery(ctx, 'paginate');
  //
  //   if (query.ids && query.ids.length === 0) {
  //     ctx.$store.commit('setCpesList', []);
  //     ctx.$store.commit('toggleMuteCpeFilters', false);
  //     ctx.$store.commit('toggleLoadingCpes');
  //     return;
  //   }
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, query).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.totalResult = response.data.data.total;
  //         const itemsList = response.data.data.itemslist;
  //         itemsList.forEach((cpe) => {
  //           if (cpe.config.wifi) {
  //             cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
  //           }
  //           if (cpe.config.wired) {
  //             cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
  //           }
  //         });
  //         ctx.$store.commit('pushCpeList', itemsList);
  //         if (ctx.$store.state.cpesList.length >= response.data.data.total) {
  //           ctx.canLoadMore = false;
  //         } else {
  //           ctx.canLoadMore = true;
  //         }
  //         ctx.$store.commit('toggleMuteCpeFilters', false);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('toggleMuteCpeFilters', false);
  //       }
  //       ctx.$store.commit('toggleLoadingCpes');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingCpes');
  //       ctx.error = err;
  //       ctx.$store.commit('toggleMuteCpeFilters', false);
  //     }
  //   );
  // },

  getPaginatedCpes(ctx) {
    ctx.$store.commit('toggleMuteCpeFilters', true);

    ctx.$store.commit('loadingCpesRequestBegin');
    const query = this.getQuery(ctx, 'paginate');

    if (query.ids && query.ids.length === 0) {
      ctx.$store.commit('setCpesList', []);
      ctx.$store.commit('toggleMuteCpeFilters', false);
      ctx.$store.commit('loadingCpesRequestEnd');
      return;
    }
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          const itemsList = response.data.data.itemslist;
          itemsList.forEach((cpe) => {
            if (cpe.config.wifi) {
              cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
            }
            if (cpe.config.wired) {
              cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
            }
          });
          ctx.$store.commit('pushCpeList', itemsList);
          if (ctx.$store.state.cpesList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.$store.commit('toggleMuteCpeFilters', false);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleMuteCpeFilters', false);
        }
        ctx.$store.commit('loadingCpesRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('loadingCpesRequestEnd');
        ctx.error = err;
        ctx.$store.commit('toggleMuteCpeFilters', false);
      }
    );
  },

  getSelectedCpes(ctx, cpesIds) {
    if (ctx.$route.path === '/aps' || ctx.$route.path === '/maps') {
      return;
    }
    ctx.$store.commit('toggleLoadingCpesForStat');
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, { action: 'R', ids: cpesIds, with: ['clients'] }).then(
      (response) => {
        if (response.data.status === 'success') {
          const itemsList = response.data.data.itemslist;
          // itemsList.forEach((cpe)=>{
          //     if(cpe.config.wifi){
          //         cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
          //     };
          //     if(cpe.config.wired){
          //         cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
          //     };
          // })
          ctx.cpesList = itemsList;
          ctx.$store.commit('setCpesList', itemsList);
          ctx.$store.commit('toggleLoadingCpesForStat');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingCpesForStat');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingCpesForStat');
      }
    );
  },

  refreshCpes(ctx, iSpinnerActive) {
    if (iSpinnerActive) {
      ctx.$store.commit('toggleLoadingCpes');
    }

    const query = this.getQuery(ctx, 'update');
    if (query.ids && query.ids.length === 0) {
      ctx.$store.commit('setCpesList', []);
      if (iSpinnerActive) {
        ctx.$store.commit('toggleLoadingCpes');
      }
      return;
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setCpesList', false);
          ctx.totalResult = response.data.data.total;
          const itemsList = response.data.data.itemslist;
          itemsList.forEach((cpe) => {
            if (cpe.config.wifi) {
              cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
            }
            if (cpe.config.wired) {
              cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
            }
          });
          ctx.$store.commit('setCpesList', response.data.data.itemslist);
          if (ctx.$store.state.cpesList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinner = false;
          if (iSpinnerActive) {
            ctx.$store.commit('toggleLoadingCpes');
          }
        } else if (response.data.status === 'error') {
          ctx.searchSpinner = false;
          VueNotifications.error({ message: response.data.description });
          if (iSpinnerActive) {
            ctx.$store.commit('toggleLoadingCpes');
          }
        }
      },
      (err) => {
        // без этого вываливается ошибка уже в окне ввода логина если разлогинится из раздела с CPE
        // так как эта (данная) функция отрабатывает на хуке beforeDestroy раздела с CPE
        // соответсвенно выводим оишбку только если это не страница ввода логина пароля
        if (ctx.$route.fullPath !== '/pages/login') {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }
        }
        ctx.error = err;
        ctx.searchSpinner = false;
        if (iSpinnerActive) {
          ctx.$store.commit('toggleLoadingCpes');
        }
      }
    );
  },

  getCpesTags(ctx) {
    Vue.axios.post(`${window.RESTAPI || ''}/api/tags/cpes`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),  }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setCpesTagsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  updateCpe(ctx) {
    ctx.$store.commit('toggleMuteCpeEdit', true);
    const cpeForUpdate = JSON.parse(JSON.stringify(ctx.updatedCpe));
    cpeForUpdate.config.lbs_config.reportperiod = parseFloat(ctx.updatedCpe.config.lbs_config.reportperiod);
    cpeForUpdate.config.lbs_config.blacklist = ctx.configFilterList.blacklist;
    cpeForUpdate.config.lbs_config.whitelist = ctx.configFilterList.whitelist;
    cpeForUpdate.config.firewall.l3_filter.mac_list = ctx.firewallL3List.mac_list;
    cpeForUpdate.config.firewall.l3_filter.ip_list = ctx.firewallL3List.ip_list;

    if (!cpeForUpdate.config.log_config.log_ip) {
      cpeForUpdate.config.log_config.log_ip = '';
    }
    if (!cpeForUpdate.config.log_config.log_port) {
      cpeForUpdate.config.log_config.log_port = 0;
    }

    if (!cpeForUpdate.config.lbs_config.reportperiod) {
      cpeForUpdate.config.lbs_config.reportperiod = 0;
    }
    if (!cpeForUpdate.config.lbs_config.clienttimeout) {
      cpeForUpdate.config.lbs_config.clienttimeout = 0;
    }
    if (!cpeForUpdate.config.stats_config.reportperiod) {
      cpeForUpdate.config.stats_config.reportperiod = 0;
    }

    // // ntp settings
    // // если enable === true, но в списке серверов пусто, выключаем enable и делаем его равым false
    // if (Object.prototype.hasOwnProperty.call(cpeForUpdate.config, 'ntp_config') && typeof cpeForUpdate.config.ntp_config === 'object' &&
    //   cpeForUpdate.config.ntp_config !== null && Object.prototype.hasOwnProperty.call(cpeForUpdate.config.ntp_config, 'enable') &&
    //   Object.prototype.hasOwnProperty.call(cpeForUpdate.config.ntp_config, 'servers') && cpeForUpdate.config.ntp_config.enable === true &&
    //   Array.isArray(cpeForUpdate.config.ntp_config.servers) && cpeForUpdate.config.ntp_config.servers.length === 0) {
    //   console.log('disable ntp_config ')
    //   cpeForUpdate.config.ntp_config.enable = false;
    // }

    const updateCpeQuery = {
      action: 'U',
      items: { [ctx.cpeSelectedId]: JSON.parse(JSON.stringify(cpeForUpdate)) }
    };

    const updatedInterfaces = Object.keys(ctx.interfaceSelectedWlans);
    for (const item in updateCpeQuery.items) {
      updateCpeQuery.items[item].config.wifi = helpers.transformArrayToObject(updateCpeQuery.items[item].config.wifi);
      updateCpeQuery.items[item].config.wired = helpers.transformArrayToObject(updateCpeQuery.items[item].config.wired);
    }
    if (ctx.updatedCpe.config.wifi) {
      ctx.updatedCpe.config.wifi.forEach((configInterface) => {
        if (updatedInterfaces.includes(configInterface.id)) {
          if (!Object.keys(ctx.interfaceCapabilities).length) {
            return;
          }

          const backgroundConfig = configInterface.scanningconfig;
          configInterface = JSON.parse(JSON.stringify(ctx.interfaceCapabilities[configInterface.id].selected));
          configInterface.scanningconfig = JSON.parse(
            JSON.stringify(ctx.interfaceCapabilities[configInterface.id].selected.scanningconfig)
          );
          for (const option in configInterface) {
            if (configInterface.frequency === '2.4') {
              if (configInterface.bandmode === '11n') {
                configInterface.bandmode = '11g';
              }
            }

            if (configInterface.frequency === '5') {
              configInterface.bandmode = '11a';
            }

            // if (configInterface.frequency === '2.4') {
            //   if (configInterface.bandmode === '11n' || configInterface.bandmode === '11ax') {
            //     configInterface.bandmode = '11g';
            //   }
            // }
            //
            // if (configInterface.frequency === '5') {
            //   configInterface.bandmode = '11a';
            // }


            if (option !== 'channels' && option !== 'wlanconfig' && option !== 'power' && configInterface[option]) {
              configInterface[option] = configInterface[option].toString();
            }
            configInterface.scanningconfig = backgroundConfig;
            configInterface.scanningconfig.reportperiod = 0;
            configInterface.scanningconfig.scannumber = 0;
            configInterface.scanningconfig.scantimeout = 0;
            if (!configInterface.maxclients) {
              configInterface.maxclients = 0;
            } else {
              configInterface.maxclients = parseInt(configInterface.maxclients);
            }
            if (!configInterface.max_inactivity) {
              configInterface.max_inactivity = 0;
            } else {
              configInterface.max_inactivity = parseInt(configInterface.max_inactivity);
            }
            configInterface.txpower = configInterface.txpower.toString();
            if (typeof configInterface.power.range === 'number') {
              configInterface.power.range = [configInterface.power.range];
            }
          }
          configInterface.wlans = ctx.interfaceSelectedWlans[configInterface.id].map((el) => el.id);

          // Resume service WLANs
          configInterface.wlans = configInterface.wlans.concat(ctx.interfaceHiddenWlans[configInterface.id]);
          updateCpeQuery.items[ctx.cpeSelectedId].config.wifi[configInterface.id] = configInterface;
        }
      });
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, updateCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteCpeEdit', false);
        ctx.refreshCpesList();
        this.getCpesTags(ctx);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteCpeEdit', false);
        ctx.error = err;
      }
    );
  },

  createTemplateFromCpe(ctx) {
    const { newTemplateData } = ctx;
    if (newTemplateData.template.cpe_config_template.wired) {
      newTemplateData.template.cpe_config_template.wired = helpers.transformArrayToObject(
        newTemplateData.template.cpe_config_template.wired
      );
    }
    if (newTemplateData.template.cpe_config_template.wifi && newTemplateData.model) {
      newTemplateData.template.cpe_config_template.wifi.forEach((currIterface) => {
        currIterface.scanningconfig.reportperiod = 0;
        currIterface.scanningconfig.scannumber = 0;
        currIterface.scanningconfig.scantimeout = 0;
        if (!currIterface.maxclients) {
          currIterface.maxclients = 0;
        } else {
          currIterface.maxclients = parseInt(currIterface.maxclients);
        }
        currIterface.txpower = currIterface.txpower.toString();
        newTemplateData.template.cpe_config_template.wifi[currIterface.id] = currIterface;
      });
      newTemplateData.template.cpe_config_template.wifi = helpers.transformArrayToObject(
        newTemplateData.template.cpe_config_template.wifi
      );
    }

    if (!newTemplateData.template.cpe_config_template.log_config.enabled) {
      newTemplateData.template.cpe_config_template.log_config.log_port = 0;
      newTemplateData.template.cpe_config_template.log_config.log_ip = '';
    }
    if (!newTemplateData.template.cpe_config_template.log_config.log_port || !cpeForUpdate.config.log_config.log_ip) {
      newTemplateData.template.cpe_config_template.log_config.log_port = 0;
    }

    newTemplateData.template.cpe_config_template.lbs_config.reportperiod = parseFloat(
      newTemplateData.template.cpe_config_template.lbs_config.reportperiod
    );
    newTemplateData.template.cpe_config_template.log_config.reportperiod = parseFloat(
      newTemplateData.template.cpe_config_template.log_config.reportperiod
    );
    newTemplateData.template.cpe_config_template.stats_config.reportperiod = parseFloat(
      newTemplateData.template.cpe_config_template.stats_config.reportperiod
    );
    newTemplateData.template.cpe_config_template.lbs_config.clienttimeout = parseFloat(
      newTemplateData.template.cpe_config_template.lbs_config.clienttimeout
    );

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpe_config_templates`, { action: 'C', items: { 0: newTemplateData } })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('aps.notificationTemplateCreatedSuccessfully') });
            ctx.newTemplateModal = false;
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
  },

  getWebAccess(ctx) {
    ctx.$store.commit('toggleWebAccess', true);

    const webAccessQuery = { action: 'U', ids: [ctx.updatedCpe.id], items: { 0: {} } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/remote`, webAccessQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.WebAccessPort = response.data.data.items.remote_info.http_port;
          ctx.SshAccessPort = response.data.data.items.remote_info.ssh_port;
          ctx.WebAccessModal = true;
          ctx.$store.commit('toggleWebAccess', false);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleWebAccess', false);
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  deleteCpe(ctx) {
    ctx.$store.commit('toggleMuteCpeEdit', true);
    const deleteWlanQuery = { action: 'D', items: { [ctx.cpeForDelete]: {} } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, deleteWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.disableSingleEditMode();
          cpePollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshCpesList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.deletingWlanModal = false;
        ctx.cpeForDelete = '';
        ctx.deletingCpeModal = false;
        ctx.$store.commit('setCpesEditId', null);
        ctx.$store.commit('toggleMuteCpeEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteCpeEdit', false);
        ctx.error = err;
      }
    );
  },

  assignCommonWlan(ctx) {
    const selectedWlansArray = [];
    ctx.commonSelectedWlans.forEach((wlanObject) => {
      selectedWlansArray.push(wlanObject.id);
    });
    ctx.$store.commit('toggleMuteCpeEdit', true);
    let updatedItems = JSON.parse(
      JSON.stringify(ctx.cpesList.filter((cpe, index) => ctx.selectedCpesId.includes(cpe.id)))
    );
    updatedItems.forEach((item) => {
      if (item.config.wifi) {
        item.config.wifi.forEach((configInterface) => {
          configInterface.wlans = selectedWlansArray;
        });
        item.config.wifi = helpers.transformArrayToObject(item.config.wifi);
      }

      if (item.config.wired) {
        item.config.wired = helpers.transformArrayToObject(item.config.wired);
      }
    });
    updatedItems = helpers.transformArrayToObject(updatedItems);

    const updateCpeQuery = { action: 'U', items: updatedItems };

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, updateCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.assignCommonWlanMode = false;
        ctx.$store.commit('toggleMuteCpeEdit', false);
        ctx.resetMultipleEditMode();
        ctx.disableMultipleEditMode();
        ctx.refreshCpesList();
      },
      (err) => {
        ctx.assignCommonWlanMode = false;
        ctx.$store.commit('toggleMuteCpeEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  assignTags(ctx) {
    const selectedWlansArray = [];
    ctx.commonSelectedWlans.forEach((wlanObject) => {
      selectedWlansArray.push(wlanObject.id);
    });
    ctx.$store.commit('toggleMuteCpeEdit', true);
    let updatedItems = JSON.parse(
      JSON.stringify(ctx.cpesList.filter((cpe, index) => ctx.selectedCpesId.includes(cpe.id)))
    );

    updatedItems.forEach((item) => {
      const newTagsArray = item.tags.concat(ctx.newTags);
      item.tags = newTagsArray;
      item.config.wifi = helpers.transformArrayToObject(item.config.wifi);
      item.config.wired = helpers.transformArrayToObject(item.config.wired);
    });

    updatedItems = helpers.transformArrayToObject(updatedItems);

    const updateCpeQuery = { action: 'U', items: updatedItems };

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, updateCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.newTags = [];
        ctx.$store.commit('toggleMuteCpeEdit', false);
        this.getCpesTags(ctx);
        ctx.resetMultipleEditMode();
        ctx.disableMultipleEditMode();
        ctx.refreshCpesList();
      },
      (err) => {
        ctx.assignCommonWlanMode = false;
        ctx.$store.commit('toggleMuteCpeEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  deleteCommonTags(ctx) {
    const selectedWlansArray = [];
    ctx.commonSelectedWlans.forEach((wlanObject) => {
      selectedWlansArray.push(wlanObject.id);
    });
    ctx.$store.commit('toggleMuteCpeEdit', true);
    let updatedItems = JSON.parse(
      JSON.stringify(ctx.cpesList.filter((cpe, index) => ctx.selectedCpesId.includes(cpe.id)))
    );
    updatedItems.forEach((item) => {
      item.tags = [];
      item.config.wifi = helpers.transformArrayToObject(item.config.wifi);
      item.config.wired = helpers.transformArrayToObject(item.config.wired);
    });

    updatedItems = helpers.transformArrayToObject(updatedItems);

    const updateCpeQuery = { action: 'U', items: updatedItems };

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, updateCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.newTags = [];
        ctx.$store.commit('toggleMuteCpeEdit', false);
        ctx.resetMultipleEditMode();
        ctx.disableMultipleEditMode();
        ctx.refreshCpesList();
      },
      (err) => {
        ctx.assignCommonWlanMode = false;
        ctx.$store.commit('toggleMuteCpeEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  deleteCommonWlans(ctx) {
    ctx.$store.commit('toggleMuteCpeEdit', true);
    const clonedCpesList = JSON.parse(JSON.stringify(ctx.cpesList));
    const selectedCpes = clonedCpesList.filter((cpe, index) => ctx.selectedCpesId.includes(cpe.id));

    selectedCpes.forEach((cpe) => {
      cpe.config.wifi.forEach((configInterface) => {
        if (configInterface && configInterface.wlans) {
          configInterface.wlans = [];
        }
      });
      cpe.config.wifi = helpers.transformArrayToObject(cpe.config.wifi);
      if (cpe.config.wired) {
        cpe.config.wired = helpers.transformArrayToObject(cpe.config.wired);
      }
    });

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes`, {
        action: 'U',
        items: helpers.transformArrayToObject(selectedCpes)
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            cpePollingService.hadleOperationResponse(response.data.data.items);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.refreshCpesList();
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.cancelDeleteCommonWlans();
          ctx.error = err;
        }
      );
  },

  deleteCommonCpes(ctx) {
    ctx.$store.commit('toggleMuteCpesDelete', true);
    let cpesForDelete = {};
    for (const id in ctx.selectedCpesId) {
      const elem = { [ctx.selectedCpesId[id]]: {} };
      cpesForDelete = Object.assign(cpesForDelete, elem);
    }
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, { action: 'D', items: cpesForDelete }).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteCpesDelete', false);
        ctx.resetMultipleEditMode();
        ctx.disableMultipleEditMode();
        ctx.refreshCpesList();
        ctx.selectedCpesId = [];
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteCpesDelete', false);
        ctx.cancelDeleteCommonWlans();
        ctx.error = err;
      }
    );
  },

  updateCommonCpes(ctx) {
    ctx.$store.commit('toggleMuteCpeEdit', true);
    const selectedWlansArray = [];

    ctx.commonUpdateCpesData.commonSelectedWlans.forEach((wlanObject) => {
      selectedWlansArray.push(wlanObject.id);
    });

    const clonedCpesList = JSON.parse(JSON.stringify(ctx.cpesList));
    const selectedCpes = clonedCpesList.filter((cpe, index) => ctx.selectedCpesId.includes(cpe.id));

    clonedCpesList.forEach((cpe) => {
      cpe.config.wifi.forEach((configInterface) => {
        configInterface.wlans = selectedWlansArray;
      });
      cpe.config.wifi = helpers.transformArrayToObject(cpe.config.wifi);
      cpe.description = ctx.commonUpdateCpesData.description;
    });

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes`, {
        action: 'U',
        items: helpers.transformArrayToObject(selectedCpes)
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.disableSingleEditMode();
            cpePollingService.hadleOperationResponse(response.data.data.items);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.refreshCpesList();
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.error = err;
        }
      );
  },

  updateCpesRadioSettings(ctx) {
    ctx.$store.commit('toggleMuteCpeEdit', true);
    const newConfigInterfaces = ctx.updatedCpe.config.wifi;

    newConfigInterfaces.forEach((configInterface) => {
      const backgroundConfig = configInterface.scanningconfig;
      for (const option in ctx.interfaceCapabilities[configInterface.id].selected) {
        if (ctx.interfaceCapabilities[configInterface.id].selected.frequency === '2.4') {
          if (ctx.interfaceCapabilities[configInterface.id].selected.bandmode === '11n') {
            ctx.interfaceCapabilities[configInterface.id].selected.bandmode = '11g';
          }
        }
        if (ctx.interfaceCapabilities[configInterface.id].selected.frequency === '5') {
          ctx.interfaceCapabilities[configInterface.id].selected.bandmode = '11a';
        }
        if (
          option !== 'channels' &&
          option !== 'wlanconfig' &&
          option !== 'wlans' &&
          option !== 'power' &&
          ctx.interfaceCapabilities[configInterface.id].selected[option]
        ) {
          ctx.interfaceCapabilities[configInterface.id].selected[option] = ctx.interfaceCapabilities[
            configInterface.id
          ].selected[option].toString();
        }
      }
      configInterface.scanningconfig = backgroundConfig;
      configInterface = ctx.interfaceCapabilities[configInterface.id].selected;

      if (!configInterface.maxclients) {
        configInterface.maxclients = 0;
      } else {
        configInterface.maxclients = parseInt(configInterface.maxclients);
      }

      if (!configInterface.max_inactivity) {
        configInterface.max_inactivity = 0;
      } else {
        configInterface.max_inactivity = parseInt(configInterface.max_inactivity);
      }

      if (typeof configInterface.power.range === 'number') {
        configInterface.power.range = [configInterface.power.range];
      }
    });
    const clonedCpesList = JSON.parse(JSON.stringify(ctx.cpesList));
    const selectedCpes = clonedCpesList.filter((cpe) => ctx.selectedCpesId.includes(cpe.id));

    selectedCpes.forEach((cpe) => {
      if (cpe.config.wired) {
        cpe.config.wired = helpers.transformArrayToObject(cpe.config.wired);
      }
      cpe.config.wifi = helpers.transformArrayToObject(JSON.parse(JSON.stringify(cpe.config.wifi)));
      newConfigInterfaces.forEach((configInterface) => {
        const oldWlans = JSON.parse(JSON.stringify(cpe.config.wifi[configInterface.id].wlans));
        cpe.config.wifi[configInterface.id] = JSON.parse(JSON.stringify(configInterface));
        cpe.config.wifi[configInterface.id].wlans = oldWlans;
      });
    });

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes`, {
        action: 'U',
        items: helpers.transformArrayToObject(selectedCpes)
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.resetMultipleEditMode();
            ctx.disableMultipleEditMode();
            ctx.refreshCpesList();
            cpePollingService.hadleOperationResponse(response.data.data.items);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.refreshCpesList();
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.error = err;
        }
      );
  },

  // setTemplatesToCPEs(ctx, templateId, sourceObject) {
  //   ctx.$store.commit('toggleMuteCpeEdit', true);
  //   if (typeof sourceObject === 'string') {
  //     sourceObject = [sourceObject];
  //   }
  //   let configFromTemplate;
  //   let queryItems;
  //
  //   const clonedCpesList = JSON.parse(JSON.stringify(ctx.cpesList));
  //   const selectedCpes = clonedCpesList.filter((cpe) => sourceObject.includes(cpe.id));
  //
  //   for (const template of ctx.templatesList) {
  //     if (template.id === templateId) {
  //       configFromTemplate = template.template.cpe_config_template;
  //     }
  //   }
  //
  //   selectedCpes.forEach((cpe) => {
  //     cpe.config = configFromTemplate;
  //   });
  //   queryItems = helpers.transformArrayToObject(selectedCpes);
  //
  //   Vue.axios
  //     .post(`${window.RESTAPI || ''}/api/cpes`, {
  //       action: 'U',
  //       items: queryItems
  //     })
  //     .then(
  //       (response) => {
  //         if (response.data.status === 'success') {
  //           ctx.resetMultipleEditMode();
  //           ctx.disableMultipleEditMode();
  //           ctx.refreshCpesList();
  //           ctx.setTemplateId = '';
  //           ctx.setTemplateModal = false;
  //           cpePollingService.hadleOperationResponse(response.data.data.items);
  //         } else if (response.data.status === 'error') {
  //           VueNotifications.error({ message: response.data.description });
  //         }
  //         ctx.$store.commit('toggleMuteCpeEdit', false);
  //         ctx.assignCommonWlanMode = false;
  //         ctx.refreshCpesList();
  //       },
  //       (err) => {
  //         VueNotifications.error({ message: err });
  //         ctx.$store.commit('toggleMuteCpeEdit', false);
  //         ctx.assignCommonWlanMode = false;
  //         ctx.error = err;
  //       }
  //     );
  // },

  setTemplatesToCPEs(ctx, templateId, sourceObject) {
    ctx.$store.commit('toggleMuteCpeEdit', true);
    if (typeof sourceObject === 'string') {
      sourceObject = [sourceObject];
    }
    let configFromTemplate;
    let queryItems;

    let locationFormTemplate;

    const clonedCpesList = JSON.parse(JSON.stringify(ctx.cpesList));
    const selectedCpes = clonedCpesList.filter((cpe) => sourceObject.includes(cpe.id));

    for (const template of ctx.templatesList) {
      if (template.id === templateId) {
        // console.log(template)
        configFromTemplate = template.template.cpe_config_template;
        locationFormTemplate = template.template.location
      }
    }

    selectedCpes.forEach((cpe) => {
      cpe.config = configFromTemplate;
      if (locationFormTemplate) {
        cpe.base_location = locationFormTemplate;
      }
    });
    queryItems = helpers.transformArrayToObject(selectedCpes);

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes`, {
        action: 'U',
        items: queryItems
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.resetMultipleEditMode();
            ctx.disableMultipleEditMode();
            ctx.refreshCpesList();
            ctx.setTemplateId = '';
            ctx.setTemplateModal = false;
            cpePollingService.hadleOperationResponse(response.data.data.items);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.refreshCpesList();
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.error = err;
        }
      );
  },

  updateLogSettings(ctx) {
    ctx.$store.commit('toggleMuteCpeEdit', true);

    const clonedCpesList = JSON.parse(JSON.stringify(ctx.cpesList));
    const selectedCpes = clonedCpesList.filter((cpe) => ctx.selectedCpesId.includes(cpe.id));

    clonedCpesList.forEach((cpe) => {
      cpe.config.wifi = helpers.transformArrayToObject(cpe.config.wifi);
      if (cpe.config.wired) {
        cpe.config.wired = helpers.transformArrayToObject(cpe.config.wired);
      }
    });

    // ntp settings
    // если enable === true, но в списке серверов пусто, выключаем enable и делаем его равым false
    if (Object.prototype.hasOwnProperty.call(ctx.updatedCpe.config, 'ntp_config') && typeof ctx.updatedCpe.config.ntp_config === 'object' &&
      ctx.updatedCpe.config.ntp_config !== null && Object.prototype.hasOwnProperty.call(ctx.updatedCpe.config.ntp_config, 'enable') &&
      Object.prototype.hasOwnProperty.call(ctx.updatedCpe.config.ntp_config, 'servers') && ctx.updatedCpe.config.ntp_config.enable === true &&
      Array.isArray(ctx.updatedCpe.config.ntp_config.servers) && ctx.updatedCpe.config.ntp_config.servers.length === 0) {
      // console.log('disable ntp_config ')
      ctx.updatedCpe.config.ntp_config.enable = false;
    }

    selectedCpes.forEach((el) => {
      const cpe = el;
      cpe.config.stats_config.enabled = ctx.updatedCpe.config.stats_config.enabled;
      if (ctx.updatedCpe.config.stats_config.reportperiod) {
        cpe.config.stats_config.reportperiod = parseFloat(ctx.updatedCpe.config.stats_config.reportperiod);
      } else {
        cpe.config.stats_config.reportperiod = 0;
      }

      cpe.config.log_config.enabled = ctx.updatedCpe.config.log_config.enabled;
      if (ctx.updatedCpe.config.log_config.log_ip) {
        cpe.config.log_config.log_ip = ctx.updatedCpe.config.log_config.log_ip;
      } else {
        cpe.config.log_config.log_ip = '';
      }

      if (ctx.updatedCpe.config.log_config.log_ip && ctx.updatedCpe.config.log_config.log_port) {
        cpe.config.log_config.log_port = ctx.updatedCpe.config.log_config.log_port;
      } else {
        cpe.config.log_config.log_port = 0;
      }

      cpe.config.lbs_config.enabled = ctx.updatedCpe.config.lbs_config.enabled;
      if (ctx.updatedCpe.config.lbs_config.reportperiod) {
        cpe.config.lbs_config.reportperiod = parseFloat(ctx.updatedCpe.config.lbs_config.reportperiod);
      } else {
        cpe.config.lbs_config.reportperiod = 0;
      }

      if (ctx.updatedCpe.config.lbs_config.clienttimeout) {
        cpe.config.lbs_config.clienttimeout = parseFloat(ctx.updatedCpe.config.lbs_config.clienttimeout);
      } else {
        cpe.config.lbs_config.clienttimeout = 0;
      }

      if (ctx.updatedCpe.config.lbs_config.filtermode == 'BlackList') {
        cpe.config.lbs_config.filtermode = ctx.updatedCpe.config.lbs_config.filtermode;
        cpe.config.lbs_config.blacklist = ctx.updatedCpe.config.lbs_config.blacklist;
      } else if (ctx.updatedCpe.config.lbs_config.filtermode == 'WhiteList') {
        cpe.config.lbs_config.filtermode = ctx.updatedCpe.config.lbs_config.filtermode;
        cpe.config.lbs_config.whitelist = ctx.updatedCpe.config.lbs_config.whitelist;
      }
      cpe.config.dhcpcap_config.enabled = ctx.updatedCpe.config.dhcpcap_config.enabled;
      cpe.config.lbs_config.emptywatcher = ctx.updatedCpe.config.lbs_config.emptywatcher;
      cpe.config.lbs_config.maxquiet = ctx.updatedCpe.config.lbs_config.maxquiet;
      cpe.config.lbs_config.maxcachequeue = ctx.updatedCpe.config.lbs_config.maxcachequeue;
      // snmp settings
      if (ctx.updatedCpe.config.hasOwnProperty('wmsnmpd') && ctx.updatedCpe.config.wmsnmpd.hasOwnProperty('default')) {
        if (ctx.updatedCpe.config.wmsnmpd.default.hasOwnProperty('enabled')) {
          cpe.config.wmsnmpd.default.enabled = ctx.updatedCpe.config.wmsnmpd.default.enabled;
        }
        if (ctx.updatedCpe.config.wmsnmpd.default.hasOwnProperty('community')) {
          cpe.config.wmsnmpd.default.community = ctx.updatedCpe.config.wmsnmpd.default.community;
        }
        if (ctx.updatedCpe.config.wmsnmpd.default.hasOwnProperty('location')) {
          cpe.config.wmsnmpd.default.location = ctx.updatedCpe.config.wmsnmpd.default.location;
        }
        if (ctx.updatedCpe.config.wmsnmpd.default.hasOwnProperty('listen_interface')) {
          cpe.config.wmsnmpd.default.listen_interface = ctx.updatedCpe.config.wmsnmpd.default.listen_interface;
        }
        if (ctx.updatedCpe.config.wmsnmpd.default.hasOwnProperty('interfaces')) {
          cpe.config.wmsnmpd.default.interfaces = ctx.updatedCpe.config.wmsnmpd.default.interfaces;
        }
      }
      // config_not_send configNotSend setting for multiedit
      if (ctx.updatedCpe.hasOwnProperty('config_not_send')) {
        cpe.config_not_send = ctx.updatedCpe.config_not_send;
      }

      // ntp setting for multiedit
      if (ctx.updatedCpe.config.hasOwnProperty('ntp_config')) {
        cpe.config.ntp_config = JSON.parse(JSON.stringify(ctx.updatedCpe.config.ntp_config));
      }

    });

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes`, {
        action: 'U',
        items: helpers.transformArrayToObject(selectedCpes)
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            cpePollingService.hadleOperationResponse(response.data.data.items);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.logSettingsMode = false;
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.refreshCpesList();
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.error = err;
        }
      );
    ctx.clearLogSettings();
    ctx.selectedCpesId = [];
    ctx.allChecked = false;
    const checkAll = document.getElementById('checkAll');
    if (checkAll) {
      checkAll.checked = false;
    }
    ctx.disableSingleEditMode();
  },

  updateAccessControlSettings(ctx) {
    ctx.$store.commit('toggleMuteCpeEdit', true);

    const clonedCpesList = JSON.parse(JSON.stringify(ctx.cpesList));
    const selectedCpes = clonedCpesList.filter((cpe) => ctx.selectedCpesId.includes(cpe.id));

    clonedCpesList.forEach((cpe) => {
      cpe.config.wifi = helpers.transformArrayToObject(cpe.config.wifi);
      if (cpe.config.wired) {
        cpe.config.wired = helpers.transformArrayToObject(cpe.config.wired);
      }
    });

    selectedCpes.forEach((cpe) => {
      cpe.config.firewall = ctx.updatedCpe.config.firewall;
    });

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes`, {
        action: 'U',
        items: helpers.transformArrayToObject(selectedCpes)
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            cpePollingService.hadleOperationResponse(response.data.data.items);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.logSettingsMode = false;
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.refreshCpesList();
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.assignCommonWlanMode = false;
          ctx.error = err;
        }
      );
    ctx.clearLogSettings();
    ctx.selectedCpesId = [];
    ctx.allChecked = false;
    const checkAll = document.getElementById('checkAll');
    if (checkAll) {
      checkAll.checked = false;
    }
    ctx.disableSingleEditMode();
  },

  cpesNamebyId(id) {
    const { cpesList } = store.state;
    let cpeName;
    cpesList.forEach((cpe) => {
      if (cpe.id == id) {
        cpeName = cpe.name;
      }
    });
    return cpeName;
  },

  // upgradeFW(ctx) {
  //   ctx.FWUpgradeModal = false;
  //   let cpesIds;
  //   if (ctx.selectedCpesId.length) {
  //     cpesIds = ctx.selectedCpesId;
  //   } else {
  //     cpesIds = [store.state.editCpeId];
  //   }
  //
  //   const upgradeCpeQuery = {
  //     action: 'U',
  //     ids: cpesIds,
  //     items: {
  //       0: {
  //         url: ctx.forceFWObjects.url,
  //         md5sum: ctx.forceFWObjects.md5sum,
  //         mode: 'off'
  //       }
  //     }
  //   };
  //
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/upgrade`, upgradeCpeQuery).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.disableSingleEditMode();
  //         ctx.resetMultipleEditMode();
  //         ctx.disableMultipleEditMode();
  //         ctx.forceFWObjects = false;
  //         setTimeout(() => {
  //           this.refreshCpes(ctx);
  //         }, 3000);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //     }
  //   );
  // },

  upgradeFW(ctx) {
    ctx.FWUpgradeModal = false;
    let cpesIds;
    if (ctx.selectedCpesId.length) {
      cpesIds = ctx.selectedCpesId;
    } else {
      cpesIds = [store.state.editCpeId];
    }

    // далее вытягиваем из строки с адресом ctx.forceFWObjects.url  адрес сервера и schema(http, https)
    // если в объекте customUrlAddressWithSchemaForFWUpgrage заданы другие чем в ctx.forceFWObjects.url
    // значения адреса и schema'ы тогда при формировании строки используем их из customUrlAddressWithSchemaForFWUpgrage
    const urlDefault = ctx.forceFWObjects.url;
    const schemaDefault = urlDefault.split('/')[0].slice(0, urlDefault.split('/')[0].length - 1);
    const addressDefault = urlDefault.split('/')[2];
    const path = urlDefault.split('/').splice(3).join('/');

    const schemaCustom = ctx.customUrlAddressWithSchemaForFWUpgrage?.schema;
    const addressCustom = ctx.customUrlAddressWithSchemaForFWUpgrage?.url;

    let urlForRequest = ctx.forceFWObjects.url;

    if (addressCustom && schemaCustom && (addressCustom !== addressDefault || schemaCustom !== schemaDefault)) {
      // console.log('Use custom Address or Schema');
      urlForRequest = `${schemaCustom}://${addressCustom}/${path}`;
    }
    // console.log(urlForRequest)

    const upgradeCpeQuery = {
      action: 'U',
      ids: cpesIds,
      items: {
        0: {
          url: urlForRequest,
          md5sum: ctx.forceFWObjects.md5sum,
          mode: 'off'
        }
      }
    };

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/upgrade`, upgradeCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.clearCustomUrlAddressWithSchemaForFWUpgrage();
          ctx.forceFWObjects = false;
          setTimeout(() => {
            this.refreshCpes(ctx);
          }, 10000);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  rebootCpeAgent(ctx, cpeIds) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const body = { action: 'U', ids: cpeIds, q: { cmd: 'cpeagent restart &' } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.rebootCpeAgentModal = false;
          setTimeout(() => {
            this.getPaginatedCpes(ctx);
          }, 3000);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  rebootCpeRedundant(ctx, cpeIds) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const body = { action: 'U', ids: cpeIds, q: { cmd: 'reboot 0 ; exit' } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.rebootCpeRedundantModal = false;
          setTimeout(() => {
            this.getPaginatedCpes(ctx);
          }, 3000);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  updateCPEIPsec(ctx, cpeIds, IPsecOption) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const dict = {
      turnOn:
        "(uci set wimark.broker.tunnel='ipsec' && uci set wimark.broker.ipsec_disable_encryption='' && uci commit && cpeagent restart) &",
      turnOnUnencrypted:
        "(uci set wimark.broker.tunnel='ipsec' && uci set wimark.broker.ipsec_disable_encryption='1' && uci commit && cpeagent restart) &",
      turnOff: "(uci set wimark.broker.tunnel='' && uci commit && cpeagent restart) &"
    };
    const body = { action: 'U', ids: cpeIds, q: { cmd: dict[IPsecOption] } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body).then(
      (response) => {
        if (response.data.status === 'success') {
          // cpePollingService.hadleOperationResponse(response.data.data.items);
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.IPsecModal = false;
          setTimeout(() => {
            this.getPaginatedCpes(ctx);
          }, 3000);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  async issueCPECommand(ctx, cpeIds, command) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const body = { action: 'U', ids: cpeIds, q: { cmd: command } };
    ctx.$store.commit('setSendCPEConfigScriptFromCpesExecutingBegin');
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body);

      if (response.data.status === 'success') {
        ctx.$store.commit('setSendCPEConfigScriptFromCpesExecutingEnd');
        // cpePollingService.hadleOperationResponse(response.data.data.items);

        if (cpeIds.length <= 1) {
          const id = cpeIds[0];
          this.selectedCpesId = id;
          ctx.commandResult = response.data.data.items[id].result;
        } else {
          ctx.configModal = false;
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
        }

        setTimeout(() => {
          this.getPaginatedCpes(ctx);
        }, 3000);
      } else if (response.data.status === 'error') {
        ctx.$store.commit('setSendCPEConfigScriptFromCpesExecutingEnd');
        VueNotifications.error({ message: response.data.description });
      }
    } catch (err) {
      ctx.$store.commit('setSendCPEConfigScriptFromCpesExecutingEnd');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({ message: err });
      }

      ctx.error = err;
    }
  },
  async issueSetTimeUsingNTP(ctx, cpeIds, ntpServerAddress) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const command = `ntpd -n -q -p ${ntpServerAddress}`;
    ctx.$store.commit('toggleSetTimeUsingNTPRequestExecuting');
    const body = { action: 'U', ids: cpeIds, q: { cmd: command } };
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body);

      if (response.data.status === 'success') {
        ctx.$store.commit('toggleSetTimeUsingNTPRequestExecuting');
        // cpePollingService.hadleOperationResponse(response.data.data.items);

        if (cpeIds.length <= 1) {
          const id = cpeIds[0];
          this.selectedCpesId = id;
          // ctx.commandResult = response.data.data.items[id].result;
          if (response.data.data.items[id].result !== '') {
            ctx.setTimeUsingNTPResult = response.data.data.items[id].result;
            VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
          } else {
            VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
            ctx.handleCloseSetTimeUsingNTPModal();
          }
        } else {
          VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
          // ctx.configModal = false;
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.handleCloseSetTimeUsingNTPModal();
        }

        setTimeout(() => {
          this.getPaginatedCpes(ctx);
        }, 3000);
      } else if (response.data.status === 'error') {
        ctx.$store.commit('toggleSetTimeUsingNTPRequestExecuting');
        VueNotifications.error({ message: response.data.description });
      }
    } catch (err) {
      ctx.$store.commit('toggleSetTimeUsingNTPRequestExecuting');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({ message: err });
      }

      ctx.error = err;
    }
  },
  async syncTime(ctx, cpeIds) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    if (ctx.syncTimeRequestCancel) {
      ctx.syncTimeRequestCancel.cancel();
      ctx.syncTimeRequestCancel = undefined;
    }
    const cancelToken = Vue.axios.CancelToken.source();
    ctx.syncTimeRequestCancel = cancelToken;
    ctx.$store.commit('setSyncTimeRequestExecutingBegin');
    const body = {action: 'U', ids: cpeIds};
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/timesync`, body, {cancelToken: cancelToken.token});

      if (response.data.status === 'success') {
        if (cpeIds.length === 1) {
          const id = cpeIds[0];
          ctx.syncTimeCommandResult = response.data.data.items[id]?.result;
          if (response.data.data.items[id]?.error) {
            ctx.syncTimeCommandError = response.data.data.items[id]?.error
          }
        } else if (cpeIds.length > 1) {
          const id = cpeIds[0];
          // ctx.syncTimeCommandResult = response.data.data.items[id]?.result;
          // ctx.disableSingleEditMode();
          // ctx.resetMultipleEditMode();
          // ctx.disableMultipleEditMode();
          // ctx.handleCloseSyncTimeModal();
          // const timestamp = response.data.data.items[id]?.result;
          // const timestampAsDateTime = timestamp ? moment(timestamp * 1000).format(' D MMM YYYY, H:mm:ss [GMT]Z') : '';
          // VueNotifications.success({message: `${ctx.$t('aps.syncTimeSuccessMsg')} ${timestampAsDateTime}`});
          // ctx.resetMultipleEditMode();
          // ctx.disableSingleEditMode();
          // ctx.disableMultipleEditMode();
          // ctx.handleCloseSyncTimeModal();
          // const timestamp = response.data.data.items[id]?.result;
          // const timestampAsDateTime = timestamp ? moment(timestamp * 1000).format(' D MMM YYYY, H:mm:ss [GMT]Z') : '';
          // VueNotifications.success({message: `${ctx.$t('aps.syncTimeSuccessMsg')} ${timestampAsDateTime}`});
          ctx.syncTimeCommandResult = response.data.data.items[id]?.result;
          // if (response.data.data.items[id]?.error) {
          //   ctx.syncTimeCommandError = response.data.data.items[id]?.error
          // }
          for (const itemIndex in response.data.data.items) {
            if (response.data.data.items[itemIndex]?.error) {
              ctx.syncTimeCommandError = response.data.data.items[itemIndex].error
            }
          }
        }
        ctx.syncTimeRequestCancel = undefined;
        // VueNotifications.success({ message: ctx.$t('aps.syncTimeSuccessMsg') });
      } else if (response.data.status === 'error') {
        ctx.syncTimeCommandError = response.data.description;
        VueNotifications.error({message: response.data.description});
      }
      ctx.$store.commit('setSyncTimeRequestExecutingEnd');
    } catch (err) {
      ctx.$store.commit('setSyncTimeRequestExecutingEnd');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        if (Vue.axios.isCancel(err)) {
          console.log('syncTime request is canceled');
        } else {
          VueNotifications.error({message: err});
        }
      }

      ctx.error = err;
    }
  },
  async issueCPECommandFromEvents(ctx, cpeIds, command) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    ctx.$store.commit('toggleSendingCPEConfigScriptFromEvents');
    const body = { action: 'U', ids: cpeIds, q: { cmd: command } };
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body);

      if (response.data.status === 'success') {
        ctx.$store.commit('toggleSendingCPEConfigScriptFromEvents');
        // cpePollingService.hadleOperationResponse(response.data.data.items);

        if (cpeIds.length <= 1) {
          const id = cpeIds[0];
          this.selectedCpesId = id;
          ctx.commandResult = response.data.data.items[id].result;
        } else {
          VueNotifications.success({ message: ctx.$t('events.sendConfigDuccessMessage') });
          ctx.configModal = false;
        }
      } else if (response.data.status === 'error') {
        ctx.$store.commit('toggleSendingCPEConfigScriptFromEvents');
        VueNotifications.error({ message: response.data.description });
      }
    } catch (err) {
      ctx.$store.commit('toggleSendingCPEConfigScriptFromEvents');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({ message: err });
      }

      ctx.error = err;
    }
  },

  async getCPEClientsListFromCpeCommand(ctx, cpeIds) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const command =
      "for i in $(iw dev | grep Interface | grep -v mon | grep -v mesh | awk '{print $2}'); do echo $i; iw dev $i station dump; done";
    ctx.$store.commit('getCPEClientsListFromCpeCommandRequestBegin');
    const body = { action: 'U', ids: cpeIds, q: { cmd: command } };
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body);

      if (response.data.status === 'success') {
        ctx.$store.commit('getCPEClientsListFromCpeCommandRequestEnd');
        // cpePollingService.hadleOperationResponse(response.data.data.items);

        if (cpeIds.length <= 1) {
          const id = cpeIds[0];
          this.selectedCpesId = id;
          // ctx.commandResult = response.data.data.items[id].result;
          if (response.data.data.items[id].result !== '') {
            ctx.getCPEClientsListFromCpeCommandResult = response.data.data.items[id].result;
            const clientsListFromCpeCommandResultParsed = this.cpeClientsListFromCpeCommandResultParse(
              response.data.data.items[id].result
            );
            ctx.getCPEClientsListFromCpeCommandResultParsed = clientsListFromCpeCommandResultParsed;
            const wlans = Object.keys(clientsListFromCpeCommandResultParsed);
            // console.log(wlans)
            this.getCPEWLANSInfoFromCpeCommand(ctx, cpeIds, wlans);
            // VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
          } else {
            // VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
            // ctx.handleCloseSetTimeUsingNTPModal();
          }
        } else {
          // VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
          // // ctx.configModal = false;
          // ctx.disableSingleEditMode();
          // ctx.resetMultipleEditMode();
          // ctx.disableMultipleEditMode();
          // ctx.handleCloseSetTimeUsingNTPModal();
        }

        // setTimeout(() => {
        //   this.getPaginatedCpes(ctx);
        // }, 3000);
      } else if (response.data.status === 'error') {
        ctx.$store.commit('getCPEClientsListFromCpeCommandRequestEnd');
        VueNotifications.error({ message: response.data.description });
      }
    } catch (err) {
      ctx.$store.commit('getCPEClientsListFromCpeCommandRequestEnd');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({ message: err });
      }

      ctx.error = err;
    }
  },
  async getCPELogFromCpeCommand(ctx, cpeIds) {
    ctx.getCPELogFromCpeCommandResult = '';
    ctx.requestError = '';
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const command = 'logread';
    ctx.$store.commit('getCPELogsFromCpeCommandRequestBegin');
    const body = { action: 'U', ids: cpeIds, q: { cmd: command } };
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body);

      if (response.data.status === 'success') {
        ctx.$store.commit('getCPELogsFromCpeCommandRequestEnd');
        // cpePollingService.hadleOperationResponse(response.data.data.items);

        if (cpeIds.length <= 1) {
          const id = cpeIds[0];
          if (response.data.data.items[id].result !== '') {
            // console.log(response.data.data.items[id].result)
            ctx.getCPELogFromCpeCommandResult = response.data.data.items[id].result;
          } else {
            ctx.getCPELogFromCpeCommandResult = '';
            console.log('no data')
          }
        }
      } else if (response.data.status === 'error') {
        ctx.$store.commit('getCPELogsFromCpeCommandRequestEnd');
        ctx.requestError = response.data.description;
        VueNotifications.error({message: response.data.description});
      }
    } catch (err) {
      ctx.$store.commit('getCPELogsFromCpeCommandRequestEnd');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({message: err});
        ctx.requestError = err;
      }
    }
  },

  async getCPEWLANSInfoFromCpeCommand(ctx, cpeIds, wlanNames) {
    if (!cpeIds || !cpeIds.length || !Array.isArray(wlanNames) || wlanNames.length === 0) {
      return;
    }
    let command = '';
    for (const wlan of wlanNames) {
      command += `iwinfo ${wlan} info;`;
    }
    // console.log(command);

    ctx.$store.commit('getCPEWLANSInfoFromCpeCommandRequestBegin');
    const body = { action: 'U', ids: cpeIds, q: { cmd: command } };
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/call`, body);

      if (response.data.status === 'success') {
        ctx.$store.commit('getCPEWLANSInfoFromCpeCommandRequestEnd');
        // cpePollingService.hadleOperationResponse(response.data.data.items);

        if (cpeIds.length <= 1) {
          const id = cpeIds[0];
          this.selectedCpesId = id;
          // ctx.commandResult = response.data.data.items[id].result;
          if (response.data.data.items[id].result !== '') {
            ctx.getCPEWLANSInfoFromCpeCommandResult = response.data.data.items[id].result;
            ctx.getCPEWLANSInfoFromCpeCommandResultParsed = this.cpeWlansInfoFromCpeCommandResultParse(
              response.data.data.items[id].result
            );
            // for (const str of ctx.getCPEWLANSInfoFromCpeCommandResult.split('\n')){
            //   console.log(str.trim())
            // }
            // VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
          } else {
            // VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
            // ctx.handleCloseSetTimeUsingNTPModal();
          }
        } else {
          // VueNotifications.success({ message: ctx.$t('aps.setTimeUsingNTPSuccessMsg') });
          // // ctx.configModal = false;
          // ctx.disableSingleEditMode();
          // ctx.resetMultipleEditMode();
          // ctx.disableMultipleEditMode();
          // ctx.handleCloseSetTimeUsingNTPModal();
        }

        // setTimeout(() => {
        //   this.getPaginatedCpes(ctx);
        // }, 3000);
      } else if (response.data.status === 'error') {
        ctx.$store.commit('getCPEWLANSInfoFromCpeCommandRequestEnd');
        VueNotifications.error({ message: response.data.description });
      }
    } catch (err) {
      ctx.$store.commit('getCPEWLANSInfoFromCpeCommandRequestEnd');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({ message: err });
      }

      ctx.error = err;
    }
  },

  rebootCPE(ctx, cpeIds) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const rebootCpeQuery = { action: 'U', ids: cpeIds };
    Vue.axios.post(`${window.RESTAPI || ''}/api/bulk/cpes/reboot`, rebootCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.rebootCpeModal = false;
          setTimeout(() => {
            this.getPaginatedCpes(ctx);
          }, 3000);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  resetCPE(ctx, cpeIds, broker_ip) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const resetCpeQuery = { action: 'U', ids: cpeIds, items: {} };
    if (broker_ip) {
      resetCpeQuery.items[0] = { broker: broker_ip };
    }
    Vue.axios.post(`${window.RESTAPI || ''}/api/bulk/cpes/reset`, resetCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          ctx.resetCpeModal = false;
          ctx.resetBrokerAddress = '';
          setTimeout(() => {
            this.getPaginatedCpes(ctx);
          }, 3000);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  changeCpesLocations(ctx, cpeIds, locationId) {
    if (!cpeIds || !cpeIds.length) {
      return;
    }
    const changeLocationsQuery = { action: 'U', ids: cpeIds, items: { 0: { base_location: locationId } } };
    ctx.$store.commit('toggleMuteChangeLocation');
    Vue.axios.post(`${window.RESTAPI || ''}/api/bulk/locations/cpes`, changeLocationsQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          this.getPaginatedCpes(ctx);
          ctx.disableSingleEditMode();
          ctx.resetMultipleEditMode();
          ctx.disableMultipleEditMode();
          VueNotifications.success({ message: ctx.$t('aps.notificationLocationSuccessfullyChanged') });
          ctx.$store.commit('toggleMuteChangeLocation');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleMuteChangeLocation');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteChangeLocation');
      }
    );
  },
  discardErrorsForCpes(ctx, cpeIdsList) {
    // запрос в ручку /api/cpes/error_discard для удаления ошибок ТД
    // запрос такого вида
    //  {
    //   action: "U",
    //   items: {
    //     cpe_id1: {},
    //     cpe_id2: {},
    //     ...
    //   }
    // }

    if (!cpeIdsList || !cpeIdsList.length) {
      return;
    }
    const cpeItemsListForRequestBody = {};
    cpeIdsList.forEach((cpeID) => {
      cpeItemsListForRequestBody[cpeID] = {};
    });
    const requestBody = {
      action: 'U',
      items: cpeItemsListForRequestBody
    };
    ctx.$store.commit('discardCPEsErrorsBeginRequest');
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/error_discard`, requestBody).then(
      (response) => {
        if (response.data.status === 'success') {
          // ctx.$store.commit('toggleMuteChangeLocation');
          VueNotifications.success({ message: ctx.$t('aps.discardErrorsNotificationErrorsDiscardedSuccessfully') });
          // обновляем  список точек, чтобы  убрался значок об ошибках из таблицы,
          // задизейблились кнопки для сброса ошибок и т.д.
          ctx.refreshCpesList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('discardCPEsErrorsEndRequest');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('discardCPEsErrorsEndRequest');
      }
    );
  },
  updateCpesConfigFromUploadedConfig(ctx, config) {
    ctx.$store.commit('toggleMuteCpeEdit', true);
    ctx.$store.commit('setUpdateCpesConfigFromUploadedConfigBegin');
    const configForReq = config;
    // console.log(configForReq);
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/cpes`, {
        action: 'U',
        items: configForReq
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            cpePollingService.hadleOperationResponse(response.data.data.items);
            // ctx.logSettingsMode = false;
            ctx.resetMultipleEditMode();
            ctx.disableMultipleEditMode();
            ctx.disableSingleEditMode();
            ctx.refreshCpesList();
            ctx.hideSetCpeConfigModal();
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.$store.commit('setUpdateCpesConfigFromUploadedConfigEnd');
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('toggleMuteCpeEdit', false);
          ctx.$store.commit('setUpdateCpesConfigFromUploadedConfigEnd');
          // ctx.assignCommonWlanMode = false;
          ctx.error = err;
        }
      );
    // ctx.clearLogSettings();
    // ctx.selectedCpesId = [];
    // ctx.allChecked = false;
    // const checkAll = document.getElementById('checkAll');
    // if (checkAll) {
    //   checkAll.checked = false;
    // }
    // ctx.disableSingleEditMode();
  },
  getCpesCompactFromUploadedConfig(ctx) {
    ctx.$store.commit('setGetCpesCompactFromUploadedConfigRequestBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx), }).then(
      (response) => {
        if (response.data.status === 'success') {
          const itemsList = response.data.data.itemslist;
          ctx.currentCpesList = itemsList;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('setGetCpesCompactFromUploadedConfigRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('setGetCpesCompactFromUploadedConfigRequestEnd');
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  getCpesCompactForFilter(ctx) {
    ctx.$store.commit('setGetCpesCompactForFilterRequestBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx), }).then(
      (response) => {
        if (response.data.status === 'success') {
          const itemsList = response.data.data.itemslist;
          ctx.cpesCompactListForFilter = itemsList;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('setGetCpesCompactForFilterRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('setGetCpesCompactForFilterRequestEnd');
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  getAllFirmwaresList(ctx) {
    return new Promise((resolve, reject) => {
      ctx.firmwareObjectsListFromApi = [];
      ctx.$store.commit('setGetFirmwareListRequestBegin');
      Vue.axios
        .post(`${window.RESTAPI || ''}/api-static/firmwares/upload`, {
          action: 'R',
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              const itemsList = response.data.data.itemslist;
              // console.log(itemsList)
              ctx.firmwareObjectsListFromApi = JSON.parse(JSON.stringify(itemsList));
              ctx.$store.commit('setGetFirmwareListRequestEnd');
            } else if (response.data.status === 'error') {
              ctx.$store.commit('setGetFirmwareListRequestEnd');
              VueNotifications.error({ message: response.data.description });
            }
            resolve(response);
          },
          (err) => {
            VueNotifications.error({ message: err });
            ctx.$store.commit('setGetFirmwareListRequestEnd');
            // ctx.error = err;
            reject(err);
          }
        );
    });
  },
  async requestCoordsByAddress(address) {
    // console.log('request coords')
    // const data = {
    //   action: 'R',
    //   search: companyName.toString()
    // };
    const params = {
      q: address,
      format: 'json',
      addressdetails: 1
    };

    const json = await Vue.axios.get('https://nominatim.openstreetmap.org/search', { params }).then(
      (response) =>
        // console.log(response);
        response.data,
      (err) => {
        console.log(err);
        return [];
      }
    );
    // if (json.data.status === 'success') {
    //   // return json.data.data.itemslist;
    //   console.log(json.data)
    //   return json.data.data.itemslist;
    // }

    for (const item of json) {
      item.display_name = helpers.cutUnwantedWordsFromAddressStringFromOpenstreetmapResponse(item.display_name)
    }

    return json;
  },
  setCpePositionForMultiEdit(ctx) {
    // const selectedWlansArray = [];
    // ctx.commonSelectedWlans.forEach((wlanObject) => {
    //   selectedWlansArray.push(wlanObject.id);
    // });
    ctx.$store.commit('toggleMuteCpeEdit', true);
    let updatedItems = JSON.parse(
      JSON.stringify(ctx.cpesList.filter((cpe, index) => ctx.selectedCpesId.includes(cpe.id)))
    );

    updatedItems.forEach((item) => {
      item.latitude = ctx.latitudeForMultiEdit;
      item.longitude = ctx.longitudeForMultiEdit;
      item.config.wifi = helpers.transformArrayToObject(item.config.wifi);
      item.config.wired = helpers.transformArrayToObject(item.config.wired);
    });

    updatedItems = helpers.transformArrayToObject(updatedItems);

    const updateCpeQuery = { action: 'U', items: updatedItems };

    Vue.axios.post(`${window.RESTAPI || ''}/api/cpes`, updateCpeQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          cpePollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteCpeEdit', false);
        ctx.resetMultipleEditMode();
        ctx.disableMultipleEditMode();
        ctx.refreshCpesList();
      },
      (err) => {
        ctx.assignCommonWlanMode = false;
        ctx.$store.commit('toggleMuteCpeEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  cpeClientsListFromCpeCommandResultParse(stringWithOnCpeCommandOutput) {
    // используется для распаршивания строк-ответа от точки со списоком клиентов, приконнекченных к ней
    // в js-объект
    try {
      if (stringWithOnCpeCommandOutput === '') {
        return {};
      }
      // const rawString = this.getCPEClientsListFromCpeCommandResult;
      const splitedByNewLineStrings = stringWithOnCpeCommandOutput.split('\n');
      const wlansNames = [];
      const macs = [];

      const result = {};
      for (const str of splitedByNewLineStrings) {
        // парсим wlan'ы
        if (str.slice(0, 4) === 'wlan') {
          result[str] = {};
          wlansNames.push(str);
        }
        // парсим клиентов
        else if (str.slice(0, 7) === 'Station') {
          const mac = str.split(' ')[1];
          // console.log(mac);
          macs.push(mac);
          result[wlansNames[wlansNames.length - 1]][macs[macs.length - 1]] = { mac };
        }
        // парсим данные по клиенту
        else if (str.split(':').length === 2) {
          // const mac = str.split(' ')[1];
          // // console.log(mac);
          // macs.push(mac);
          // result[wlansNames[wlansNames.length - 1]][macs[macs.length - 1]] = str;
          const key = str.split(':')[0];
          const value = str.split(':')[1];
          // console.log(key, value)
          // if (key !== undefined && value !== undefined) {
          //   // тут очищаем от табуляции ('\t') и вносим в итоговый объект
          //   const keyTabCleared = key.replace('\t', '');
          //   const valueTabCleared = value.replace('\t', '');
          //   result[wlansNames[wlansNames.length - 1]][macs[macs.length - 1]][keyTabCleared] = valueTabCleared;
          // }

          // тут очищаем от табуляции ('\t') и вносим в итоговый объект
          const keyTabCleared = key.replace('\t', '').trim();
          const valueTabCleared = value.replace('\t', '').trim();
          // console.log(valueTabCleared);
          result[wlansNames[wlansNames.length - 1]][macs[macs.length - 1]][keyTabCleared] = valueTabCleared;
        }
      }
      return result;
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  cpeWlansInfoFromCpeCommandResultParse(stringWithOnCpeCommandOutput) {
    // используется для распаршивания строк-ответа от точки c информацией по ее wlans
    // в js-объект
    try {
      if (stringWithOnCpeCommandOutput === '') {
        return {};
      }
      // const rawString = this.getCPEClientsListFromCpeCommandResult;
      const splitedByNewLineString = stringWithOnCpeCommandOutput.split('\n');
      const wlansNames = [];

      const result = {};
      for (const str of splitedByNewLineString) {
        if (str.slice(0, 4) === 'wlan' && str.split('ESSID:').length === 2) {
          const wlanName = str.split('ESSID:')[0].trim();
          const wlanESSID = str.split('ESSID:')[1].trim();

          result[wlanName] = { essid: wlanESSID };
          wlansNames.push(wlanName);
        } else if (str.includes('Access Point:') && str.trim().split(' ').length === 3) {
          // обрабатываем строку вида Access Point: 46:D1:F0:12:4C:72 (обрабатываем отдельно поскольку просто разбить по : не получается)
          const key = `${str.trim().split(' ')[0]} ${str.trim().split(' ')[1].replace(':', '')}`;
          const value = str.trim().split(' ')[2];
          result[wlansNames[wlansNames.length - 1]][key] = value;
        } else if (str.includes('Hardware:')) {
          // обрабатываем строку вида Hardware: 168C:003C 0000:0000 [Qualcomm Atheros QCA9880](обрабатываем отдельно поскольку просто разбить по : не получается)
          // console.log(str.trim().split('Hardware:'));
          const key = str.trim().split(':')[0].trim();
          const value = str.trim().split('Hardware:')[1].trim();
          result[wlansNames[wlansNames.length - 1]][key] = value;
        } else if (str.split(':').length === 3) {
          // тут обраюбатывваем  строки где ключ: значение  ключ: значение  (например Mode: Master  Channel: 6 (2.437 GHz))
          const separatedString = str.split(':');
          // console.log(separatedString)

          const key1 = separatedString[0].trim();
          const value1 = separatedString[1].trim().split('  ')[0].trim();
          const key2 = separatedString[1].trim().split('  ')[1].trim();
          const value2 = separatedString[2].trim();

          // console.log('key1', key1);
          // console.log('value1', value1);
          // console.log('key2', key2);
          // console.log('value2', value2);

          result[wlansNames[wlansNames.length - 1]][key1] = value1;
          result[wlansNames[wlansNames.length - 1]][key2] = value2;
        } else if (str.split(':').length === 2) {
          // тут обраюбатывваем простые строки где ключ: значение (например TX power offset: unknown)
          const key = str.split(':')[0].trim();
          const value = str.split(':')[1].trim();
          result[wlansNames[wlansNames.length - 1]][key] = value;
        }
      }
      return result;
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async requestCompactCpesWithSearch(searchQuery, locationId= undefined, with_childs = undefined, limit = undefined) {
    const config = {
      action: 'R',
      location: store.getters.getDefaultLocationForRequests(undefined), with_childs: store.getters.getDefaultWithChildsForRequests(undefined),
      search: searchQuery,
      sort_by: {
        field: 'connected',
        order: -1
      },
      // limit: 10000
      page: { limit: 1000 }
    };
    if (searchQuery === '') {
      config.page = {
        limit: 10
      };
    }
    if (limit) {
      config.page.limit = limit;
    }
    if (locationId !== undefined) {
      config.location = locationId;
    }
    if (with_childs !== undefined) {
      config.with_childs = with_childs;
    }
    const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },
  async requestCompactCpesWithSearchForUseInWiFiTarget(searchQuery, locationId= undefined, with_childs = undefined, limit = undefined, params = undefined) {
    const config = {
      action: 'R',
      location: store.getters.getDefaultLocationForRequests(undefined), with_childs: store.getters.getDefaultWithChildsForRequests(undefined),
      search: searchQuery,
      sort_by: {
        field: 'connected',
        order: -1
      },
      // limit: 10000
      page: { limit: 1000 }
    };
    if (searchQuery === '') {
      config.page = {
        limit: 10
      };
    }
    if (limit) {
      config.page.limit = limit;
    }
    if (locationId !== undefined) {
      config.location = locationId;
    }
    if (with_childs !== undefined) {
      config.with_childs = with_childs;
    }
    // console.log(JSON.parse(JSON.stringify(config)))
    if (params){
      Object.assign(config, params)
    }
    // console.log(JSON.parse(JSON.stringify(config)))
    const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },


  async asyncGetAllCompactCpesWithDataRemaining(ctx) {
    try {
      return await this.asyncGetCompactCpesWithDataRemaining(ctx, undefined);
    } catch (e) {
      console.log(e)
      return [];
    }
  },
  async asyncGetCompactCpesWithDataRemainingByIds(ctx, cpesIds) {
    try {
      return await this.asyncGetCompactCpesWithDataRemaining(ctx, cpesIds);
    } catch (e) {
      console.log(e)
      return [];
    }
  },


  async asyncGetCompactCpesWithDataRemaining(ctx, cpesIds = undefined) {
    try {
      const paramsForRequest = {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        sort_by: {
          field: 'connected',
          order: -1
        }
      }
      // console.log(cpesIds)
      if (cpesIds) {
        paramsForRequest.ids = cpesIds
        paramsForRequest.location = undefined
        paramsForRequest.with_childs = true;
      }
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, paramsForRequest);
      if (response.data.status === 'success') {
        let loaded;
        let total;
        const dataFromResponse = [];
        if (response.data.data.itemslist[0]) {
          // console.log(response);
          loaded = response.data.data.count;
          total = response.data.data.total;
          for (const item of response.data.data.itemslist) {
            dataFromResponse.push(item);
          }
        }
        if (loaded && total) {
          if (loaded < total) {
            const offset = loaded;
            const limit = total - loaded;
            if (limit > 0 && offset > 0) {

              // if (!limit || !offset) {
              //   return [];
              // }

              const praramsForSecondRequest = JSON.parse(JSON.stringify(paramsForRequest));
              praramsForSecondRequest.page = { limit, offset };
              const response2 = await Vue.axios.post(`${window.RESTAPI || ''}/api/cpes/compact`, praramsForSecondRequest);
              if (response2.data.status === 'success') {
                if (response2.data.data.itemslist[0]) {
                  for (const item of response2.data.data.itemslist) {
                    dataFromResponse.push(item);
                  }
                }
                return dataFromResponse

              } else if (response.data.status === 'error') {

                VueNotifications.error({message: response.data.description});
                return dataFromResponse
              }

            } else {
              return dataFromResponse
            }
          } else {
            return dataFromResponse

            // this.clientRFData = dataFromResponse;
          }
        } else {
          return dataFromResponse

          // this.clientRFData = dataFromResponse;
        }
      } else if (response.data.status === 'error') {
        VueNotifications.error({message: response.data.description});
        return []
      }


    } catch (e) {
      console.log(e)
      VueNotifications.error({message: e});
      return [];
    }

  }


};
