import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';

export default {
  getPedingOperations(ctx) {
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/operations`, {
        action: 'R',
        with_childs: true,
        sort_by: { field: 'insert_time', order: -1 },
        fields: ['status', 'model_id', 'was_read', 'model', 'insert_time', 'type', 'description', 'model_ids'],
        page: { limit: 100 },
        count: 20
      })
      .then(
        (response) => {
          const { items, total } = response.data.data;
          const itemsList = response.data.data.itemslist;
          const { cpeOperations } = store.state;
          const { wlanOperations } = store.state;
          const { radiusOperations } = store.state;
          const { firewallOperations } = store.state;
          const { hotspotOperations } = store.state;
          const { redirectOperations } = store.state;
          const { controllerOperations } = store.state;
          const pendingList = itemsList.filter((item) => item.status === 'pending');
          const cpeOperationsIds = helpers.combineIdsArrayFromObjectsArray(cpeOperations);
          const wlanOperationsIds = helpers.combineIdsArrayFromObjectsArray(wlanOperations);
          const radiusOperationsIds = helpers.combineIdsArrayFromObjectsArray(radiusOperations);
          const l2chainOperationsIds = helpers.combineIdsArrayFromObjectsArray(firewallOperations);
          const redirectOperationsIds = helpers.combineIdsArrayFromObjectsArray(redirectOperations);
          const hotspotOperationsIds = helpers.combineIdsArrayFromObjectsArray(hotspotOperations);
          const controllerOperationsIds = helpers.combineIdsArrayFromObjectsArray(controllerOperations);

          pendingList.forEach((operation) => {
            if (operation.model === 'cpe' && !cpeOperationsIds.includes(operation.id)) {
              store.state.cpeOperations.push(operation);
            }
            if (operation.model === 'wlan' && !wlanOperationsIds.includes(operation.id)) {
              store.state.wlanOperations.push(operation);
            }
            if (operation.model === 'radius' && !radiusOperationsIds.includes(operation.id)) {
              store.state.radiusOperations.push(operation);
            }
            if (operation.model === 'hotspot-profile' && !hotspotOperationsIds.includes(operation.id)) {
              store.state.hotspotOperations.push(operation);
            }
            if (operation.model === 'l2-chain' && !l2chainOperationsIds.includes(operation.id)) {
              store.state.firewallOperations.push(operation);
            }
            if (operation.model === 'captive-redirect' && !redirectOperationsIds.includes(operation.id)) {
              store.state.redirectOperations.push(operation);
            }
            if (operation.model === 'controller' && !controllerOperationsIds.includes(operation.id)) {
              store.state.controllerOperations.push(operation);
            }
          });
          const notPending = itemsList.filter((item) => item.status != 'pending');
          const sortedItemsList = notPending.sort((curr, prev) => {
            if (curr.insert_time > prev.insert_time) {
              return -1;
            }
            if (curr.insert_time < prev.insert_time) {
              return 1;
            }
            return 0;
          });
          store.commit('setOperationsList', sortedItemsList);
          store.commit('setOperationsTotal', total);

          const pendingItems = {};
          const successItems = {};
          for (const item in items) {
            if (items[item].status == 'pending') {
              pendingItems[item] = items[item];
            }
          }
          for (const item in items) {
            if (items[item].status == 'success') {
              successItems[item] = items[item];
            }
          }
        },
        (err) => {
          console.warn(err);
        }
      );
  },

  hideAllOperations(ctx) {
    ctx.$store.commit('toggleHideAllOperations', true);
    const opearationsIds = helpers.transformArrayToObject(store.state.operationsList);
    const hideAllOperationsQuery = { action: 'U', items: opearationsIds };
    Vue.axios.post(`${window.RESTAPI || ''}/api/operations`, hideAllOperationsQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('toggleHideAllOperations', false);
          ctx.showAllOperations = false;
          ctx.getOperations();
        }
      },
      (err) => {
        ctx.$store.commit('toggleHideAllOperations', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  async deleteOperations(ctx, id = false) {
    const operationsToDelete = {};
    if (id) {
      operationsToDelete[id] = {};
    } else {
      store.state.operationsList.forEach((el) => {
        operationsToDelete[el.id] = {};
      });
    }

    const deleteAllOperationsQuery = { action: 'U', items: operationsToDelete };
    ctx.$store.commit('toggleLoadingOperations', true);
    try {
      const response = await Vue.axios.post(`${window.RESTAPI || ''}/api/operations`, deleteAllOperationsQuery);

      if (response.data.status === 'success') {
        ctx.getOperations();
      }
    } catch (err) {
      // ctx.$store.commit('toggleLoadingOperations', false);
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({ message: err });
      }

      ctx.error = err;
    } finally {
      ctx.$store.commit('toggleLoadingOperations', false);
    }
  }
};
