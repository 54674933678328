import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';
import operationsService from './operationsService';
import commonService from './commonService';

export default {
  hadleOperationResponse(items) {
    if (store.state.wlanOperations.indexOf(items) == -1) {
      store.commit('setWlanOperations', items);
    }
  },
  // startPolling(ctx) {
  //   const queryIds = this.getPollingIds();
  //   let counter = 0;
  //   if (queryIds.length) {
  //     const pollIteration = setInterval(() => {
  //       Vue.axios.post(`${window.RESTAPI || ''}/api/operations`, { action: 'R', ids: queryIds }).then(
  //         (response) => {
  //           counter++;
  //           // для задачки WNE-2270
  //           // с бэка в response.data.data.total приходит 0 (и это баг) и из-за этого получается что функция вызывается постоянно
  //           // каждые 5 секунд и не останавливается
  //           if (counter >= 5 && response.data.data.total === 0) {
  //             // если приходит response.data.data.total 0 с бэка и это уже 6 запрос
  //             // то нужно остановить эти запросы чтобы не ddos'ить бэк
  //             // чистим все в queryIds,
  //             // и отменяем повторные запросы и прекращаем выполнение функции
  //             this.clearPollingIds(queryIds);
  //             if (ctx) {
  //               ctx.$store.state.editWlanIndex = '';
  //               ctx.refreshWlansList();
  //             }
  //             operationsService.getPedingOperations();
  //             clearInterval(pollIteration);
  //             return;
  //           }
  //           response.data.data.itemslist.forEach((operation) => {
  //             if (operation.status === 'success') {
  //               let isOperationActual = false;
  //               store.state.wlanOperations.forEach((storeWlanOperation) => {
  //                 if (storeWlanOperation.id === operation.id) {
  //                   isOperationActual = true;
  //                 }
  //               });
  //
  //               store.commit('setWlanOperationStatus', {
  //                 model_id: operation.model_id,
  //                 status: operation.status,
  //                 id: operation.id
  //               });
  //               if (isOperationActual) {
  //                 this.showSuccessPopup(operation, ctx);
  //               }
  //
  //               ctx.disableEditMode();
  //               clearInterval(pollIteration);
  //             }
  //             if (operation.status === 'error') {
  //               let isOperationActual = false;
  //
  //               store.state.wlanOperations.forEach((storeWlanOperation) => {
  //                 if (storeWlanOperation.id === operation.id) {
  //                   isOperationActual = true;
  //                 }
  //               });
  //
  //               store.commit('setWlanOperationStatus', {
  //                 model_id: operation.model_id,
  //                 status: operation.status,
  //                 id: operation.id
  //               });
  //               const operationModelName = commonService.wlanNamebyId(operation.model_id);
  //               VueNotifications.error({ message: ` ${operation.description}` });
  //               ctx.disableEditMode();
  //               clearInterval(pollIteration);
  //             }
  //           });
  //           if (!store.state.wlanOperations.length) {
  //             if (ctx) {
  //               ctx.$store.state.editWlanIndex = '';
  //               ctx.refreshWlansList();
  //             }
  //             operationsService.getPedingOperations();
  //           }
  //         },
  //         (err) => {}
  //       );
  //     }, 5000);
  //   }
  // },
  startPolling(ctx) {
    const updatedWlansIds = [];
    const queryIds = this.getPollingIds();
    let counter = 0;
    if (queryIds.length) {
      const pollIteration = setInterval(() => {
        Vue.axios.post(`${window.RESTAPI || ''}/api/operations`, { action: 'R', ids: queryIds }).then(
          (response) => {
            counter++;
            // для задачки WNE-2270
            // с бэка в response.data.data.total приходит 0 (и это баг) и из-за этого получается что функция вызывается постоянно
            // каждые 5 секунд и не останавливается
            if (counter >= 5 && response.data.data.total === 0) {
              // если приходит response.data.data.total 0 с бэка и это уже 6 запрос
              // то нужно остановить эти запросы чтобы не ddos'ить бэк
              // чистим все в queryIds,
              // и отменяем повторные запросы и прекращаем выполнение функции
              this.clearPollingIds(queryIds);
              if (ctx) {
                ctx.$store.state.editWlanIndex = '';
                ctx.refreshWlansList();
              }
              operationsService.getPedingOperations();
              clearInterval(pollIteration);
              return;
            }
            response.data.data.itemslist.forEach((operation) => {
              if (operation.status === 'success') {
                let isOperationActual = false;
                store.state.wlanOperations.forEach((storeWlanOperation) => {
                  if (storeWlanOperation.id === operation.id) {
                    isOperationActual = true;
                    updatedWlansIds.push(storeWlanOperation.model_id);
                  }
                });

                store.commit('setWlanOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                if (isOperationActual) {
                  this.showSuccessPopup(operation, ctx);
                }

                ctx.disableEditModeFromStartPollingFunc(updatedWlansIds);
                clearInterval(pollIteration);
              }
              if (operation.status === 'error') {
                let isOperationActual = false;

                store.state.wlanOperations.forEach((storeWlanOperation) => {
                  if (storeWlanOperation.id === operation.id) {
                    isOperationActual = true;
                    updatedWlansIds.push(storeWlanOperation.model_id);
                  }
                });

                store.commit('setWlanOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                const operationModelName = commonService.wlanNamebyId(operation.model_id);
                VueNotifications.error({ message: ` ${operation.description}` });
                ctx.disableEditModeFromStartPollingFunc(updatedWlansIds);
                clearInterval(pollIteration);
              }
            });
            // console.log(updatedWlansIds)
            if (!store.state.wlanOperations.length) {
              if (ctx) {
                // // ctx.$store.state.editWlanIndex = '';
                // // ctx.refreshWlansList();

                // if (updatedWlansIds.includes(ctx.$store.state.editWlanId)) {
                //   ctx.$store.state.editWlanIndex = '';
                // }
                ctx.refreshWlansListFromStartPollingFunc(updatedWlansIds);
              }
              operationsService.getPedingOperations();
            }
          },
          (err) => {}
        );
      }, 5000);
    }
  },
  getPollingIds() {
    const pollingIds = [];
    store.state.wlanOperations.forEach((operation) => {
      if (!pollingIds.includes(operation.id)) {
        if (operation.status === 'pending') {
          pollingIds.push(operation.id);
        }
      }
    });

    return pollingIds;
  },
  clearPollingIds(ids) {
    // очищаем список с IDшками
    for (const operationIndex in store.state.wlanOperations) {
      const operationId = store.state.wlanOperations[operationIndex].id;
      if (ids.includes(operationId)) {
        // console.log('deleting');
        Vue.delete(store.state.wlanOperations, operationIndex);
      }
    }
  },
  showSuccessPopup(operation, ctx) {
    switch (operation.type) {
      case 'U':
        VueNotifications.success({ message: ctx.$t('wlans.notificationWLANUpdatedSuccessfully') });
        break;
      case 'C':
        VueNotifications.success({ message: ctx.$t('wlans.notificationWLANCreatedSuccessfully') });
        break;
      case 'D':
        VueNotifications.success({ message: ctx.$t('wlans.notificationWLANDeletedSuccessfully') });
        break;
      default:
        VueNotifications.success({ message: ctx.$t('wlans.notificationWLANOperationSuccessfull') });
    }
  }
};
