<template>
  <div class="sidebar">
    <nav class="sidebar-nav">
      <ul class="nav">
        <li
          class="nav-item nav-dropdown"
          disabled="disabled"
          v-if="$can('read', { route: 'Dashboard' }) && !isPortalOnly"
        >
          <router-link :to="'/dashboard'" class="nav-link pl-1" :title="showOnlyIcons ? $t('sidebar.dashboard') : ''">
            <i class="glyphicons glyphicons-dashboard sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.dashboard') }}</span>
            <span v-if="!showOnlyIcons" class="badge-group">
              <span
                class="badge badge-primary badge-sidebar mr-q"
                v-tooltip.top-center="{
                  content:
                    `${$t('sidebar.cpuLoad')}: ` + (systemTotalObjList.system.load.load_core * 100).toFixed(0) + '%'
                }"
                v-if="
                  systemTotalObjList && systemTotalObjList.system.load && this.$store.state.userData.role !== 'marketer'
                "
              >
                {{ $t('sidebar.cpu') }}: {{ (systemTotalObjList.system.load.load_core * 100).toFixed(0) }}%
              </span>
            </span>
          </router-link>
        </li>

        <li
          class="nav-item nav-dropdown"
          disabled="disabled"
          v-if="$can('read', { route: 'Dashboard' }) && !isPortalOnly && this.$store.state.userData.role === 'admin' && showEasyInstallWizardMenuItem"
        >
          <router-link
            :to="'/easy-install-wizard'"
            class="nav-link pl-1"
            :title="showOnlyIcons ? $t('easyInstallWizard.easyInstallButtonInSidebar') : ''"
          >
            <i class="glyphicons glyphicons-road sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons" class="sidebar-item-header">
              {{ $t('easyInstallWizard.easyInstallButtonInSidebar') }}
            </span>
            <span v-if="!showOnlyIcons && !areAllEasyInstallWizardNotInProgress" class="badge-group">
              <span class="badge badge-primary badge-sidebar badge-sidebar-easy-install-wizard mr-q">
                {{ $t('easyInstallWizard.badgeSidebarInProgress') }}
              </span>
            </span>
          </router-link>
        </li>

        <li
          class="nav-item nav-dropdown nav-network"
          :class="{ open: !window.ANALYTICS, 'pt-1': !window.ANALYTICS }"
          disabled="disabled"
          v-if="
            $canSomeInGroup('read', [...RouteGroups.SYSTEM, ...RouteGroups.STATISTICS, ...RouteGroups.MONITORING]) &&
            !isPortalOnly
          "
        >
          <div
            :title="showOnlyIcons ? $t('sidebar.nms') : ''"
            class="nav-link nav-dropdown-toggle pl-1"
            @click.stop="openNetwork"
            v-if="window.ANALYTICS"
          >
            <i class="glyphicons glyphicons-globe sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.nms') }}</span>
          </div>
          <ul class="nav-dropdown-items">
            <li class="nav-title">
              <span v-if="!showOnlyIcons" class="pl-1">{{ $t('sidebar.systemInfo') }}</span>
            </li>
            <!-- <li class="nav-item">
              <router-link :to="'/statistics'" class="nav-link">
                            <i class="glyphicons glyphicons-stats sidebar-item-logo"></i>
                            <span class="sidebar-item-header"> Statistics</span>
                        </router-link>
            </li> -->
            <li
              class="nav-item nav-dropdown nav-statistics"
              disabled="disabled"
              v-if="$canSomeInGroup('read', RouteGroups.STATISTICS)"
            >
              <div
                :title="showOnlyIcons ? $t('sidebar.statistics') : ''"
                class="nav-link nav-dropdown-toggle pl-2"
                @click.stop="openStatistics"
              >
                <i class="glyphicons glyphicons-charts sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.statistics') }}</span>
              </div>
              <ul class="nav-dropdown-items">
                <li class="nav-item" v-if="$can('read', { route: 'Statsystem' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.locations') : ''"
                    :to="'/statistics/statsystem/'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-tree-structure sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.locations') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'Statcpes' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.cpes') : ''"
                    :to="'/statistics/statcpes/'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-router sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.cpes') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'Statwlans' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.wlans') : ''"
                    :to="'/statistics/statwlans'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-wifi sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.wlans') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'Statclients' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.clients') : ''"
                    :to="'/statistics/statclients'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-user sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.clients') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'Statreports' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.reports') : ''"
                    :to="'/statistics/statreports'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-envelope sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.reports') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'SNMPStat' }) && showSNMPStatistic">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.SNMPStat') : ''"
                    :to="'/statistics/snmpstat'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-riflescope sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.SNMPStat') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li
              class="nav-item nav-dropdown nav-monitor"
              disabled="disabled"
              v-if="$canSomeInGroup('read', RouteGroups.MONITORING)"
            >
              <div
                :title="showOnlyIcons ? $t('sidebar.monitor') : ''"
                class="nav-link nav-dropdown-toggle pl-2"
                @click.stop="openMonitor"
              >
                <i class="glyphicons glyphicons-light-beacon sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.monitor') }}</span>
              </div>
              <ul class="nav-dropdown-items">
                <li class="nav-item" v-if="$can('read', { route: 'Events' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.events') : ''"
                    :to="'/monitor/events'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-exclamation-sign sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.events') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'Rules' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.rules') : ''"
                    :to="'/monitor/rules'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-important-day sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.rules') }}</span>
                  </router-link>
                </li>
                <!--<li class="nav-item">-->
                <!--<router-link :to="'/monitor/scanner'" class="nav-link pl-3">-->
                <!--<i class="glyphicons glyphicons-radar sidebar-item-logo"></i> {{ $t("sidebar.scanner") }}-->
                <!--</router-link>-->
                <!--</li>-->
                <li class="nav-item" v-if="$can('read', { route: 'Sessions' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.troubleshooter') : ''"
                    :to="'/monitor/sessions'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-warning-sign sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.troubleshooter') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'WifiAnalyzer' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.wifiAnalyzer') : ''"
                    :to="'/monitor/wifi-analyzer'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-wifi sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.wifiAnalyzer') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'ClientRF' })">
                  <router-link
                    :title="showOnlyIcons ? $t('clientsRF.clientRFSidebar') : ''"
                    :to="'/monitor/client-rf'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-user sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('clientsRF.clientRFSidebar') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'ClientDistance' }) && showClientDistance">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.clientDistance') : ''"
                    :to="'/monitor/client-distance'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-router sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.clientDistance') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'SNMPMonitoringConfig' }) && showSNMPMonitoringConfig">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.SNMPMonitoringConfig') : ''"
                    :to="'/monitor/snmp-monitoring-config'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-riflescope sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.SNMPMonitoringConfig') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'DITExport' }) && showDITExport">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.DITExport') : ''"
                    :to="'/monitor/dit-statistic'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-charts sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.DITExport') }}</span>
                  </router-link>
                </li>

                <li class="nav-item" v-if="$can('read', { route: 'DBState' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.DBState') : ''"
                    :to="'/monitor/db-state'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-database sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.DBState') }}</span>
                  </router-link>
                </li>

                <li class="nav-item" v-if="$can('read', { route: 'PeriodicTasks' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.PeriodicTasks') : ''"
                    :to="'/monitor/periodic-tasks'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-clock sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.PeriodicTasks') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- <li class="nav-item"></li> -->
            <li class="nav-title" v-if="$canSomeInGroup('read', RouteGroups.SYSTEM)">
              <span v-if="!showOnlyIcons" class="pl-1">{{ $t('sidebar.system') }}</span>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'Aps' })">
              <router-link :title="showOnlyIcons ? $t('sidebar.cpes') : ''" :to="'/aps'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-router sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.cpes') }}</span>
                <span v-if="!showOnlyIcons" class="badge-group">
                  <span
                    class="badge badge-default badge-sidebar mr-q"
                    :class="{
                      'badge-sidebar__vertical':
                        systemTotalObjList.cpes.connected > 99 && systemTotalObjList.cpes.total > 99,
                      'mr-q': systemTotalObjList.cpes.connected <= 99 && systemTotalObjList.cpes.total <= 99
                    }"
                    v-tooltip.top-center="{
                      content: `${$t('sidebar.badgeTooltipTotal')}: ` + systemTotalObjList.cpes.total
                    }"
                    v-if="systemTotalObjList"
                  >
                    {{ systemTotalObjList.cpes.total }}
                  </span>
                  <span
                    class="badge badge-success badge-sidebar mr-q"
                    :class="{
                      'badge-sidebar__vertical':
                        systemTotalObjList.cpes.connected > 99 && systemTotalObjList.cpes.total > 99
                    }"
                    v-tooltip.top-center="{
                      content: `${$t('sidebar.badgeTooltipConnected')}: ` + systemTotalObjList.cpes.connected
                    }"
                    v-if="systemTotalObjList"
                  >
                    {{ systemTotalObjList.cpes.connected }}
                  </span>
                </span>
              </router-link>
            </li>
            <li class="nav-item" v-if="window.CISCO && $can('read', { route: 'Controllers' })">
              <router-link
                :title="showOnlyIcons ? $t('sidebar.controllers') : ''"
                :to="'/controllers'"
                class="nav-link pl-3"
              >
                <i class="glyphicons glyphicons-disk-import sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.controllers') }}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'RRM' })">
              <router-link :title="showOnlyIcons ? $t('sidebar.rrm') : ''" :to="'/rrm'" class="nav-link pl-3">
                <i class="glyphicons glyphicons-cluster sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.rrm') }}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'Templates' })">
              <router-link
                :title="showOnlyIcons ? $t('sidebar.template') : ''"
                :to="'/templates'"
                class="nav-link pl-3"
              >
                <i class="glyphicons glyphicons-copy sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.template') }}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'Wlans' })">
              <router-link :title="showOnlyIcons ? $t('sidebar.wlans') : ''" :to="'/wlans'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-wifi sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.wlans') }}</span>
                <span v-if="!showOnlyIcons" class="badge-group">
                  <span
                    class="badge badge-default badge-sidebar mr-q"
                    v-tooltip.top-center="{
                      content: `${$t('sidebar.badgeTooltipTotal')}: ` + systemTotalObjList.wlans.total
                    }"
                    v-if="systemTotalObjList"
                  >
                    {{ systemTotalObjList.wlans.total }}
                  </span>
                </span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'Clients' })">
              <router-link :title="showOnlyIcons ? $t('sidebar.clients') : ''" :to="'/clients'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-user sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.clients') }}</span>
                <span v-if="!showOnlyIcons" class="badge-group">
                  <span
                    class="badge badge-default badge-sidebar"
                    :class="{
                      'badge-sidebar__vertical':
                        systemTotalObjList.clients.connected > 99 && systemTotalObjList.clients.total > 99,
                      'mr-q': systemTotalObjList.clients.connected <= 99 && systemTotalObjList.clients.total <= 99
                    }"
                    v-tooltip.top-center="{
                      content: `${$t('sidebar.badgeTooltip24h')}: ` + systemTotalObjList.clients.total
                    }"
                    v-if="systemTotalObjList"
                  >
                    {{ systemTotalObjList.clients.total }}
                  </span>
                  <span
                    class="badge badge-success badge-sidebar mr-q"
                    :class="{
                      'badge-sidebar__vertical':
                        systemTotalObjList.clients.connected > 99 && systemTotalObjList.clients.total > 99
                    }"
                    v-tooltip.top-center="{
                      content: `${$t('sidebar.badgeTooltipConnected')}: ` + systemTotalObjList.clients.connected
                    }"
                    v-if="systemTotalObjList"
                  >
                    {{ systemTotalObjList.clients.connected }}
                  </span>
                </span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'Radius' })">
<!--              <router-link :title="showOnlyIcons ? $t('sidebar.radius') : ''" :to="'/radius'" class="nav-link pl-2">-->
              <router-link :title="showOnlyIcons ? $t('sidebar.radius') : ''" :to="'/radius'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-radar sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.radius') }}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'Hotspot' }) && isFull">
              <router-link :title="showOnlyIcons ? $t('sidebar.hotspots') : ''" :to="'/hotspot'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-screenshot sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.hotspots') }}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'Firewall' }) && isFull">
              <router-link :title="showOnlyIcons ? $t('sidebar.firewall') : ''" :to="'/firewall'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-firefighters sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.firewall') }}</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$can('read', { route: 'GuestControl' }) && isFull">
              <router-link
                :title="showOnlyIcons ? $t('sidebar.GuestControl') : ''"
                :to="'/GuestControl'"
                class="nav-link pl-2"
              >
                <i class="glyphicons glyphicons-fingerprint-lock sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.GuestControl') }}</span>
              </router-link>
            </li>
<!--            <li class="nav-item" v-if="$can('read', { route: 'System' })">-->
<!--              <router-link :title="showOnlyIcons ? $t('sidebar.licenses') : ''" :to="'/system'" class="nav-link pl-2">-->
<!--                <i class="glyphicons glyphicons-settings sidebar-item-logo"></i>-->
<!--                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.licenses') }}</span>-->
<!--              </router-link>-->
<!--            </li>-->
          </ul>
        </li>
        <!-- Portal -->
        <li
          class="nav-item nav-dropdown nav-portal"
          :class="{ open: isPortalOnly }"
          disabled="disabled"
          v-if="window.PORTAL && $canSomeInGroup('read', RouteGroups.PORTAL)"
        >
          <div
            :title="showOnlyIcons ? $t('sidebar.portal') : ''"
            class="nav-link nav-dropdown-toggle pl-1"
            @click.stop="openPortal"
          >
            <i class="glyphicons glyphicons-tv sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.portal') }}</span>
          </div>
          <ul class="nav-dropdown-items">
            <li class="nav-item" v-if="$can('read', { route: 'PortalDashboard' })">
              <router-link
                :title="showOnlyIcons ? $t('sidebar.portalDashboard') : ''"
                :to="'/portal/dashboard'"
                class="nav-link pl-2"
              >
                <i class="glyphicons glyphicons-modal-window sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons">{{ $t('sidebar.portalDashboard') }}</span>
              </router-link>
            </li>
            <li class="nav-item nav-dropdown nav-portal-configuration" disabled="disabled">
              <div
                :title="showOnlyIcons ? $t('sidebar.portalConfiguration') : ''"
                class="nav-link nav-dropdown-toggle pl-2"
                @click.stop="openPortalConfiguration"
              >
                <i class="glyphicons glyphicons-wrench sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.portalConfiguration') }}</span>
              </div>
              <ul class="nav-dropdown-items">
                <!-- <li class="nav-item" v-if="$can('read', { route: 'PortalProfiles' })">
                                 <router-link :to="'/portal/profiles'" class="nav-link pl-3">
                                   <i class="glyphicons glyphicons-modal-window sidebar-item-logo"></i>
                                   {{ $t('sidebar.profiles') }}
                                 </router-link>
                               </li> -->
                <li class="nav-item" v-if="$can('read', { route: 'PortalProfilesNew' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.profiles') : ''"
                    :to="'/portal/profiles'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-modal-window sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.profiles') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PortalPreview' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.preview') : ''"
                    :to="'/portal/preview'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-eye-open sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.preview') }}</span>
                  </router-link>
                </li>
                <!-- <li class="nav-item" v-if="$can('read', { route: 'PortalPages' })">
                 <router-link :to="'/portal/pages'" class="nav-link pl-3">
                   <i class="glyphicons glyphicons-invoice sidebar-item-logo"></i>
                   {{ $t('sidebar.pages') }}
                 </router-link>
               </li> -->
                <li class="nav-item" v-if="$can('read', { route: 'PortalPagesNew' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.pages') : ''"
                    :to="'/portal/pages'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-invoice sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.pages') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PortalHotelVouchers' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.hotelVouchers') : ''"
                    :to="'/portal/hotel-vouchers'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-home sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.hotelVouchers') }}</span>
                  </router-link>
                </li>
                <!-- <li class="nav-item" v-if="$can('read', { route: 'PortalAds' })">
                  <router-link :to="'/portal/ads'" class="nav-link pl-3">
                    <i class="glyphicons glyphicons-money sidebar-item-logo"></i>
                    {{ $t('sidebar.ads') }}
                  </router-link>
                </li> -->
                <li class="nav-item" v-if="$can('read', { route: 'PortalAdsNew' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.ads') : ''"
                    :to="'/portal/ads'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-money sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.ads') }}</span>
                  </router-link>
                </li>

                <li class="nav-item" v-if="$can('read', { route: 'PortalAccessServers' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.PortalAccessServers') : ''"
                    :to="'/portal/access-servers'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-database sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.PortalAccessServers') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item nav-dropdown nav-portal-statistics" disabled="disabled">
              <div
                :title="showOnlyIcons ? $t('sidebar.analiticsAndData') : ''"
                class="nav-link nav-dropdown-toggle pl-2"
                @click.stop="openPortalStatistics"
                v-if="currentUserData.role !== 'advertising'"
              >
                <i class="glyphicons glyphicons-charts sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.analiticsAndData') }}</span>
              </div>
              <ul class="nav-dropdown-items">
                <li class="nav-item" v-if="$can('read', { route: 'UserAccounts' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.accounts') : ''"
                    :to="'/portal/accounts'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-user-key sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.accounts') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PortalSessions' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.sessions') : ''"
                    :to="'/portal/sessions'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-iphone sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.sessions') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PortalStats' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.statistics') : ''"
                    :to="'/portal/statistics'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-pie-chart sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.statistics') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PortalBirthdays' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.birthdays') : ''"
                    :to="'/portal/birthdays'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-gift sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.birthdays') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PortalAdsStats' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.ads') : ''"
                    :to="'/portal/statistics-ads'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-money sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.ads') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PortalLocationsStat' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.locations') : ''"
                    :to="'/portal/statistics-location'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-globe sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.locations') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              class="nav-item nav-dropdown nav-portal-paid-access"
              disabled="disabled"
              v-if="window.PAID_INTERNET && $canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET)"
            >
              <div
                :title="showOnlyIcons ? $t('sidebar.paidInternet') : ''"
                class="nav-link nav-dropdown-toggle pl-2"
                @click.stop="openPortalPaidAccess"
              >
                <i class="glyphicons glyphicons-usd sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.paidInternet') }}</span>
              </div>
              <ul class="nav-dropdown-items">
                <li class="nav-item" v-if="$can('read', { route: 'Vouchers' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.vouchers') : ''"
                    :to="'/portal/vouchers'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-fees-payments sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.vouchers') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'Tariffs' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.tariffs') : ''"
                    :to="'/portal/tariffs'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-coins sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.tariffs') }}</span>
                  </router-link>
                </li>
                <li class="nav-item" v-if="$can('read', { route: 'PaymentSystems' })">
                  <router-link
                    :title="showOnlyIcons ? $t('sidebar.paymentSystems') : ''"
                    :to="'/portal/payment-systems'"
                    class="nav-link pl-3"
                  >
                    <i class="glyphicons glyphicons-credit-card sidebar-item-logo"></i>
                    <span v-if="!showOnlyIcons">{{ $t('sidebar.paymentSystems') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li
          class="nav-item nav-dropdown nav-analytics"
          disabled="disabled"
          v-if="window.ANALYTICS && $canSomeInGroup('read', RouteGroups.ANALYTICS)"
        >
          <div
            :title="showOnlyIcons ? $t('sidebar.analytics') : ''"
            class="nav-link nav-dropdown-toggle pl-1"
            @click.stop="openAnalytics"
          >
            <i class="glyphicons glyphicons-stats sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.analytics') }}</span>
          </div>
          <ul class="nav-dropdown-items">
            <li class="nav-item" v-if="$can('read', { route: 'Marketing' })">
              <router-link
                :title="showOnlyIcons ? $t('sidebar.visitors') : ''"
                :to="'/marketing/'"
                class="nav-link pl-2"
              >
                <i class="glyphicons glyphicons-user sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons">{{ $t('sidebar.visitors') }}</span>
              </router-link>
            </li>

            <!--            <li class="nav-item">-->
            <!--              <router-link :to="'/buyers/'" class="nav-link pl-3">-->
            <!--                <i class="glyphicons glyphicons-shopping-cart sidebar-item-logo"></i>-->
            <!--                {{ $t('sidebar.buyers') }}-->
            <!--              </router-link>-->
            <!--            </li>-->

            <li class="nav-item" v-if="$can('read', { route: 'maps' })">
              <router-link :title="showOnlyIcons ? $t('sidebar.heatmap') : ''" :to="'/maps'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-map sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons">{{ $t('sidebar.heatmap') }}</span>
              </router-link>
            </li>

            <li class="nav-item" v-if="$can('read', { route: 'Radar' })">
              <router-link :title="showOnlyIcons ? $t('sidebar.radar') : ''" :to="'/target/'" class="nav-link pl-2">
                <i class="glyphicons glyphicons-radar sidebar-item-logo"></i>
                <span v-if="!showOnlyIcons">{{ $t('sidebar.radar') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item nav-dropdown" disabled="disabled" v-if="$can('read', { route: 'Support' })">
          <router-link :title="showOnlyIcons ? $t('sidebar.support') : ''" :to="'/support'" class="nav-link pl-1">
            <i class="glyphicons glyphicons-question-sign sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.support') }}</span>
          </router-link>
        </li>

        <li class="nav-item nav-dropdown" disabled="disabled" v-if="$can('read', { route: 'ConfigExportImport' }) && !isOperator">
          <router-link :title="showOnlyIcons ? $t('sidebar.ConfigExportImport') : ''" :to="'/config_export_import'" class="nav-link pl-1">
            <i class="glyphicons glyphicons-download sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons" class="sidebar-item-header">{{ $t('sidebar.ConfigExportImport') }}</span>
          </router-link>
        </li>

        <li class="nav-item nav-dropdown" v-if="$can('read', { route: 'System' }) && (isAdmin || isOperator)">
          <router-link :title="showOnlyIcons ? $t('sidebar.licenses') : ''" :to="'/system'"
                       class="nav-link pl-1">
            <i class="glyphicons glyphicons-settings sidebar-item-logo"></i>
            <span v-if="!showOnlyIcons"
                  class="sidebar-item-header">{{ $t('sidebar.licenses') }}</span>
          </router-link>
        </li>


        <li class="nav-item sidebar-time text-muted" v-if="systemTotalObjList">
          <div
            v-if="!showOnlyIcons"
            class="sidebar-time__copyright"
            :class="{ 'hide-copyright-info': isBeeline && !isUserSuperAdmin }"
          >
            <!--<small class="text-muted">{{systemTotalObjList.system.current_time | moment}}</small>-->
            <small class="text-muted" v-if="window.BUILD">Version: {{ window.BUILD }}</small>
            <small class="text-muted" v-if="window.BUILD_DATE">Build date: {{ window.BUILD_DATE }}</small>
            <small :class="{ 'hide-copyright-info': isBeeline }">
              <a href="http://wimark.com/" target="_blank">Wimark Systems</a>
              &copy; {{ new Date().getFullYear() }}
            </small>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import router from '../router';
import auth from '../auth/auth';
import dashboardService from '../services/dashboardService';
import RouteGroups from '../access-control/RouteGroups';

Vue.use(require('vue-moment'));

export default {
  name: 'Sidebar',
  data() {
    return {
      windowWidth: null,
      RouteGroups
    };
  },
  computed: {
    isOperator() {
      return this.$store.state.userData.role === 'operator';
    },
    isAdmin() {
      return this.$store.state.userData.role === 'admin';
    },
    areAllEasyInstallWizardNotInProgress() {
      return this.$store.getters.areAllEasyInstallWizardNotInProgress;
    },
    showOnlyIcons() {
      return this.$store.state.showOnlyIcons;
    },
    locations() {
      return this.$store.state.locationsItems;
    },
    currentUserLocationData() {
      if (this.locations.hasOwnProperty(this.currentUserData.base_location)) {
        return this.locations[this.currentUserData.base_location];
      }
      return {};
    },
    currentUserData() {
      return this.$store.state.userData;
    },
    isUserSuperAdmin() {
      // суперадмины - юзеры с ролью admin и локацией '/'
      try {
        const userRole = this.currentUserData.role;
        const userLocationName = this.currentUserLocationData.name;
        if (userRole === 'admin' && userLocationName === '/') {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isBeeline() {
      try {
        if (process.env.VUE_APP_CUSTOM_BUILD === 'BEELINE-WIMARK') {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isPortalOnly() {
      if (window.PORTAL_ONLY) {
        return true;
      }
      return false;
    },
    window() {
      return window;
    },
    isExperementalBuild() {
      return this.$store.getters.isExperementalBuild;
    },
    showSNMPStatistic() {
      return this.isExperementalBuild;
    },
    showClientDistance() {
      return this.isExperementalBuild;
    },
    showSNMPMonitoringConfig() {
      return this.isExperementalBuild;
    },
    isNmsTest() {
      return this.$store.getters.isNmsTest;
    },
    showDITExport() {
      return this.isBeeline;
    },
    systemTotalObjList() {
      return this.$store.state.systemTotalObjList;
    },
    refreshInterval() {
      return this.$store.state.refreshInterval;
    },
    compactInterval() {
      return this.$store.state.intervals.compact;
    },
    isNoPortalBuild() {
      return this.$store.getters.isNOPortalBuild;
    },
    showEasyInstallWizardMenuItem() {
      if (this.isNoPortalBuild) {
        return false;
      } else {
        return true;
      }
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isFull() {
      return !!window.FULL;
    }
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    handleClick(e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle('open');
    },
    openStatistics() {
      const statisticsItem = document.querySelector('.nav-statistics');
      statisticsItem.classList.toggle('open');
    },
    openNetwork() {
      const statisticsItem = document.querySelector('.nav-network');
      statisticsItem.classList.toggle('open');
    },
    openAnalytics() {
      const statisticsItem = document.querySelector('.nav-analytics');
      statisticsItem.classList.toggle('open');
    },
    openMonitor() {
      const statisticsItem = document.querySelector('.nav-monitor');
      statisticsItem.classList.toggle('open');
    },
    openPortal() {
      const statisticsItem = document.querySelector('.nav-portal');
      statisticsItem.classList.toggle('open');
    },
    openPortalConfiguration() {
      const statisticsItem = document.querySelector('.nav-portal-configuration');
      statisticsItem.classList.toggle('open');
    },
    openPortalStatistics() {
      const statisticsItem = document.querySelector('.nav-portal-statistics');
      statisticsItem.classList.toggle('open');
    },
    openPortalPaidAccess() {
      const statisticsItem = document.querySelector('.nav-portal-paid-access');
      statisticsItem.classList.toggle('open');
    },
    resetRefreshInteval() {
      const refreshInterval = parseInt(localStorage.getItem('refreshInterval')) * 1000;
      if (refreshInterval > 0) {
        if (this.compactInterval) {
          clearInterval(this.compactInterval);
          this.$store.commit('assignInterval', {
            name: 'compact',
            instance: null
          });
        }
        const intervalInstance = setInterval(() => {
          if (!localStorage.access_token) {
            clearTimeout(intervalInstance);
            if (this.$route.name !== 'Login') {
              auth.logout(this);
            }
          } else {
            dashboardService.setTotalObjInfo(this);
          }
        }, refreshInterval);

        this.$store.commit('assignInterval', {
          name: 'compact',
          instance: intervalInstance
        });
      } else if (this.compactInterval) {
        clearInterval(this.compactInterval);
        this.$store.commit('assignInterval', {
          name: 'compact',
          instance: null
        });
      }
    }
  },
  watch: {
    refreshInterval() {
      this.resetRefreshInteval();
    },
    windowWidth(newWindowWidth, oldWindowWidth) {
      const windowWidthBreakpoint = 992; // ниже этой ширины окна начанают работу мобильные стили

      // если переходим к маленькому размеру окна
      if (newWindowWidth < windowWidthBreakpoint && oldWindowWidth >= windowWidthBreakpoint) {
        // если ресайза окна с включенным минималистичнм сайдбаром еще не было и сайбар минималистичный
        if (
          this.$store.state.windowResizedFromDesktopToMobileWithMinimalisticSidebar === false &&
          document.body.classList.contains('sidebar-show-only-icons')
        ) {
          // console.log('mobile!');
          document.body.classList.remove('sidebar-show-only-icons');
          this.$store.commit('sidebarShowIconsAndCaptions');
          this.$store.commit('setWindowResizedFromDesktopToMobileWithMinimalisticSidebar', true);
        }
      }
      // если переходим к большому размеру окна
      if (newWindowWidth >= windowWidthBreakpoint && oldWindowWidth < windowWidthBreakpoint) {
        // если ресайз окна был при включенном минималистичном сайдбаре, и мы его обрабатывали,
        // включаем опять минималистичный сайдбар
        if (this.$store.state.windowResizedFromDesktopToMobileWithMinimalisticSidebar === true) {
          this.$store.commit('sidebarShowOnlyIcons');
          document.body.classList.toggle('sidebar-show-only-icons');
          this.$store.commit('setWindowResizedFromDesktopToMobileWithMinimalisticSidebar', false);
        }
      }
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    }
  },
  created() {
    this.$store.commit('setWindowResizedFromDesktopToMobileWithMinimalisticSidebar', false);
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.updateWindowWidth);
    dashboardService.setTotalObjInfo(this);
    this.resetRefreshInteval();
  },
  mounted() {
    // прячем прелоадер, которй прописан в /static/index.html
    document.body.classList.add('loaded-preloader');
    document.body.classList.add('loaded_hiding-preloader');
    // если залогинился рекламный агент сразу разварачиваем разделы до раздела с портальной рекламой, посколько больше он ничего и не видит в меню
    if (this.currentUserData.role === 'advertising') {
      try {
        const portalSection = document.querySelector('.nav-portal');
        portalSection.classList.toggle('open');
        const portalConfigurationSection = document.querySelector('.nav-portal-configuration');
        portalConfigurationSection.classList.toggle('open');
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss">
.sidebar .nav {
  height: 100%;
}

.sidebar .nav .nav-item .nav-link {
  padding: 1rem;
  cursor: pointer;
}

.sidebar-time {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 1.5rem;
  justify-content: flex-end;
}

.sidebar-time__copyright {
  display: flex;
  flex-direction: column;
  /*align-items: flex-end;
    align-content: flex-end;*/
}

.sidebar-item-header {
  width: 100%;
  font-size: 15px;
  vertical-align: text-top;
  line-height: 20px;
  padding-right: 6px;
}

.sidebar-item-logo {
  font-size: 30px;
}

.badge-group {
  right: 10px;
  top: 13px;
  position: absolute;
}
</style>
<style lang="scss" scoped>
.hide-copyright-info {
  visibility: hidden;
}
.badge-sidebar-easy-install-wizard {
  font-size: 0.6em;
  position: relative;
  top: 21px;
}
.badge-group-easy-install-wizard {
  left: 0px;
  bottom: 0px;
  position: absolute;
}
/* .new-feature:after {
  display: inline-block;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  content: 'new';
  background-color: #4dbd74;
  font-size: 60%;
  padding: 0.15rem 0.2rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  float: right;
} */
</style>
