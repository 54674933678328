import i18n from '../locales';

function getCsvTip() {
  return `
        <div class="small text-left">
           <p>${i18n.t('aps.registerCpeFileTip')}</p>
            <p class="mb-h">${i18n.t('aps.registerCpeExample')}:</p>
            <ul class="list-unstyled text-muted small">
              <li>
                991ef838-6711-4ef4-511-b83cb0e398e2
              </li>
               <li>
                aced713c-4c01-4b11-aed2-91e85b6a43a1
              </li>
               <li>
                c1149e1b-bd11-4300-8daa-11b11a4a33e4
              </li>
            </ul>
        </div>
      `;
}

export { getCsvTip };
