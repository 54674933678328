<template>
  <div class="row" v-if="this.$store.state.userData.role === 'admin'">
    <div class="col-lg-3">
      <div class="card card-inverse card-success">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="root + '/static/img/wifi1.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('easyInstallWizard.headerCaption') }}</div>
          <template v-for="stepNumber of wizardSteps">
            <div :key="stepNumber" v-if="currentRouteName === `EasyInstallWizardStep${stepNumber}`">
              <p class="text-muted text-uppercase font-weight-bold">{{ $t(`general.step${stepNumber}`) }}</p>
              <p>{{ $t(`easyInstallWizard.step${stepNumber}Caption`) }}</p>
            </div>
          </template>
          <div class="progress progress-white progress-xs mt-1">
            <div
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar"
              :style="{ width: progressWidth + '%', transition: 'all 0.2s' }"
            ></div>
          </div>
          <hr />
          <div class="where-Is-Looking-For-This-Settings">
            <div class="mb-1">{{ $t('easyInstallWizard.whereIsLookingForThisSettings') }}?</div>
            <template v-for="stepNumber of wizardSteps">
              <div
                :key="`${stepNumber}-whereIsLookingForThisSettings`"
                v-if="currentRouteName === `EasyInstallWizardStep${stepNumber}`"
              >
                {{ $t(`easyInstallWizard.step${stepNumber}WhereIsLookingForThisSettings`) }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div :class="currentRouteName === 'EasyInstallWizardStep3' ? 'col-lg-8' : 'col-lg-5'">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'easyInstallWizard',
  data() {
    return {
      wizardSteps: [1, 2, 3, 4, 5],
      prevRoutePath: null
    };
  },
  computed: {
    root() {
      return window.wimark.root;
    },
    currentRouteName() {
      // console.warn('ROUTE');
      // console.warn(this.$route.name);
      return this.$route.name;
    },
    isNoPortalBuild() {
      return this.$store.getters.isNOPortalBuild;
    },
    progressWidth() {
      let width;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'EasyInstallWizardStep1':
          width = 20;
          break;
        case 'EasyInstallWizardStep2':
          width = 40;
          break;
        case 'EasyInstallWizardStep3':
          width = 60;
          break;
        case 'EasyInstallWizardStep4':
          width = 75;
          break;
        case 'EasyInstallWizardStep5':
          width = 90;
          break;
        default:
          width = 100;
      }
      return width;
    }
  },
  beforeCreate() {
    if (this.$store.state.userData.role !== 'admin' || this.$store.getters.isNOPortalBuild) {
      this.$router.push({ name: 'Dashboard' }).catch((err) => {
        console.log(err);
        this.$nextTick(() => {
          this.$router.push({ name: 'Dashboard' });
        });
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    // console.log('beforeRouteEnter')
    // смотри откуда пришел пользователь, чтобы потом, после последнего шага прокинуть его туда-же
    next((vm) => {
      try {
        if (from !== undefined && from.fullPath !== undefined) {
          vm.prevRoutePath = from.fullPath;
        }
      } catch (e) {
        console.log(e);
      }
    });
  },
  watch: {
    prevRoutePath(val) {
      if (val !== null) {
        console.log(val);
        this.$store.commit('setEasyInstallWizardRouteFromUserWentToWizard', val);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.wizard-user-icon {
  width: 80px;
}
.where-Is-Looking-For-This-Settings {
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}
</style>
