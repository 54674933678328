<template>
  <section class="component">
    <header class="component__header hidden"><h6 class="component__title">WMM</h6></header>

    <div class="component__body">
      <div class="form-group">
        <Switch-component
          v-model="updatedWlan.wmm.disabled"
          :disabled="isDisable"
          :label="$t('general.enable')"
          :id="'wmm-disabled'"
          :inverse-switcher-enabled-logic="true"
        />
      </div>

      <div class="form-group">
        <Switch-component
          v-model="updatedWlan.wmm.uapsd"
          :disabled="isDisable"
          :label="$t('wlans.UAPSD')"
          :id="'wmm-uapsd'"
        />
      </div>

      <div
        class="category"
        v-for="(category, category_key) in CATEGORIES"
        :class="{ 'mb-2': !isCategoryShow(category_key) }"
        :key="category_key"
      >
        <div class="category__header" @click="showCategory(category_key, category)">
          <div class="h6 category__title">
            {{ $t(`wmm.${category_key}`) }}
            <template v-if="showCategoryStausBadges">
              <span
                class="badge badge-default category__status"
                v-if="!wlanEditCurrent.wmm.categories || !wlanEditCurrent.wmm.categories[`${category}`]"
              >
                {{ $t('wmm.default') }}
              </span>
              <span class="badge badge-info category__status" v-else>{{ $t('wmm.custom') }}</span>
            </template>
          </div>
          <button class="btn btn-link new-modern-style-btn-other-btn">
            <i
              :class="{
                'icon-arrow-left': !isCategoryShow(category_key),
                'icon-arrow-down': isCategoryShow(category_key)
              }"
            ></i>
          </button>
        </div>
        <div
          class="category__block mb-2 mt-1"
          v-if="updatedWlan.wmm.categories[category] && isCategoryShow(category_key)"
        >
          <div
            class="form-group"
            v-for="(config, config_key) in CONFIGURATION"
            v-if="config.type === 'int'"
            :key="config_key"
          >
            <div class="category__config">
              <label :for="config_key">{{ config.name }}</label>
              <input
                type="text"
                :disabled="isDisable"
                :name="config_key"
                :id="config_key"
                v-validate="`between:${config.validation}`"
                :placeholder="$t('wmm.configPlaceHolder')"
                class="form-control form-control-sm"
                :class="{ input: true, 'is-danger': errors.has(`${config_key}`) }"
                v-model.number="updatedWlan.wmm.categories[`${category}`][`${config_key}`]"
              />
              <span
                v-tooltip.top-center="{ content: errors.first(`${config_key}`) }"
                v-show="errors.has(`${config_key}`)"
                class="help is-danger category__error"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
          </div>
          <div
            class="form-group"
            v-for="(config, config_key) in CONFIGURATION"
            v-if="config.type === 'float'"
            :key="config_key"
          >
            <div class="category__config">
              <label :for="config_key">{{ config.name }}</label>
              <input
                type="text"
                :disabled="isDisable"
                :name="config_key"
                :id="config_key"
                v-validate="`between:${config.validation}|decimal:${config.decimal}`"
                :placeholder="$t('wmm.configPlaceHolder')"
                class="form-control form-control-sm"
                :class="{ input: true, 'is-danger': errors.has(`${config_key}`) }"
                v-model.number="updatedWlan.wmm.categories[`${category}`][`${config_key}`]"
              />
              <span
                v-tooltip.top-center="{ content: errors.first(`${config_key}`) }"
                v-show="errors.has(`${config_key}`)"
                class="help is-danger category__error"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>
          </div>
          <div
            class="form-group"
            v-for="(config, config_key) in CONFIGURATION"
            v-if="config.type === 'bool'"
            :key="config_key"
          >
            <Switch-component
              v-model="updatedWlan.wmm.categories[`${category}`][`${config_key}`]"
              :disabled="isDisable"
              :label="config.name"
              :id="`wmm-${config_key}`"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import commonService from '../../services/commonService';
import SwitchComponent from '../Universal/Switch-component.vue';

const CATEGORIES = {
  Background: 'BK',
  BestEffort: 'BE',
  Video: 'VI',
  Voice: 'VO'
};

const CONFIGURATION = {
  cli_cw_min: {
    name: 'Client cwMin',
    type: 'int',
    validation: '0,15'
  },
  cli_cw_max: {
    name: 'Client cwMax',
    type: 'int',
    validation: '0,15'
  },
  cli_aifs: {
    name: 'Client AIFS',
    type: 'int',
    validation: '0,255'
  },
  cli_txop: {
    name: 'TXOP Limit',
    type: 'int',
    validation: '0,65535'
  },
  cli_acm: {
    name: 'ACM',
    type: 'bool'
  },
  ac_cw_min: {
    name: 'AC cwMin',
    type: 'int',
    validation: '0,15'
  },
  ac_cw_max: {
    name: 'AC cwMax',
    type: 'int',
    validation: '0,15'
  },
  ac_aifs: {
    name: 'AC AIFS',
    type: 'int',
    validation: '0,255'
  },
  ac_burst: {
    name: 'Max. Burst',
    type: 'float',
    validation: '0.0, 999.0',
    decimal: '1'
  }
};
export default {
  name: 'WMM',
  components: { SwitchComponent },
  inject: ['$validator'],
  props: {
    updatedWlan: {
      type: Object
    },
    wlanEditCurrent: {
      type: Object
    },
    isDisable: {
      type: Boolean
    },
    showCategoryStausBadges: {
      type: Boolean,
      default: true
    },
    isBackgroundShow: {
      type: Boolean
    },
    isBestEffortShow: {
      type: Boolean
    },
    isVideoShow: {
      type: Boolean
    },
    isVoiceShow: {
      type: Boolean
    }
  },
  data() {
    return {
      isBackgroundShowConfig: this.isBackgroundShow,
      isBestEffortShowConfig: this.isBestEffortShow,
      isVideoShowConfig: this.isVideoShow,
      isVoiceShowConfig: this.isVoiceShow
    };
  },
  watch: {},
  computed: {
    commonService() {
      return commonService;
    },
    CATEGORIES() {
      return CATEGORIES;
    },
    CONFIGURATION() {
      return CONFIGURATION;
    }
  },
  methods: {
    showCategory(key, category) {
      this[`is${key}ShowConfig`] = !this[`is${key}ShowConfig`];

      if (!this.updatedWlan.wmm.categories[category]) {
        Vue.set(this.updatedWlan.wmm.categories, category, {});
        for (const config in CONFIGURATION) {
          Vue.set(this.updatedWlan.wmm.categories[category], config, '');
        }
      }
    },
    isCategoryShow(key) {
      return this[`is${key}ShowConfig`];
    }
  },
  created() {
    if (!this.updatedWlan.wmm.categories) {
      this.updatedWlan.wmm.categories = {};
    }
  }
};
</script>

<style lang="scss" scoped>
.category__header {
  display: flex;
  justify-content: space-between;

  cursor: pointer;
}

.category__title {
  position: relative;
  margin-bottom: 0;
}

.category__status {
  margin-left: 10px;
}

.category__config {
  position: relative;
  display: flex;

  & label {
    flex-basis: 25%;
  }
  & input {
    flex-basis: 75%;
  }
}

.category__error {
  position: absolute;
  right: 8px;
  top: 0px;

  cursor: pointer;
}
</style>
