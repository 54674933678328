<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <div class="col-lg-3">
      <div class="card card-inverse card-success">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-cpe-icon" :src="root + '/static/img/wifi.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('aps.registerCPE') }}</div>
          <div>
            <p>{{ $t('aps.registerDescription') }}</p>
          </div>
          <div class="progress progress-white progress-xs mt-1">
            <div
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar"
              style="width: 100%;"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-block cpe-register-container">
              <div class="h4 m-0">{{ $t('aps.registerCPE') }}</div>

              <!--<small class="text-muted" v-if="!massRegisterCpe">{{$t('aps.singleRegistrationMode')}}</small>-->
              <small class="text-muted">{{ $t('aps.massRegistrationMode') }}</small>
              <div class="form-group mt-2">
                <label for="location">{{ $t('general.location') }}</label>
                <select v-model="base_location" name="location" class="form-control" id="location">
                  <option v-for="location in locationsList" :value="location.id" :key="location.id">
                    {{ location.name }}
                  </option>
                </select>
              </div>

              <label for="uuid" class="">UUID</label>
              <div class="input-group">
                <input
                  type="text"
                  name="uuid"
                  id="uuid"
                  class="form-control input-tag"
                  :placeholder="$t('aps.modalAddUUIDPlaceHolder')"
                  data-vv-validate-on="input"
                  v-validate="'uuid'"
                  @keyup.13="addUUIID"
                  v-model="newUuid"
                  :class="{ input: true, 'is-danger': errors.has('uuid') }"
                />
                <div class="input-group-btn">
                  <button class="btn btn-success mb-0" type="button" @click="addUUIID" :disabled="!this.newUuid">
                    {{ $t('general.add') }}
                  </button>
                </div>
                <div class="input-group-btn fileUpload ml-1" v-tooltip.top-center="{ content: this.getCsvTip }">
                  <button
                    class="btn btn-primary mb-0"
                    type="button"
                    :class="{ 'btn-danger': fileTypeError || invalidUuid }"
                  >
                    <i class="fa fa-file-o"></i>
                  </button>
                  <input id="csv" @change="previewUuids" name="csv" type="file" class="upload btn btn-info mb-0" />
                </div>
              </div>
              <span v-if="fileTypeError" class="help is-danger">The field must be a csv file</span>
              <span v-if="invalidUuid" class="help is-danger">Wrong data in file</span>
              <div class="mt-1 mb-1">
                <span
                  class="uuid"
                  @click.prevent="removeUuid"
                  :data-index="index"
                  :key="uuid"
                  v-for="(uuid, index) in UuidsToReistration"
                >
                  {{ uuid }}
                  <i class="fa fa-remove text-danger"></i>
                </span>
              </div>

              <div class="mt-2">
                <button
                  type="button"
                  class="btn btn-success pull-right"
                  :disabled="muteCpesRegistration || errors.any() || !(UuidsToReistration.length || newUuid)"
                  @click="registerCpes"
                >
                  <span :class="{ invisible: muteCpesRegistration }">{{ $t('aps.register') }}</span>
                  <span v-if="muteCpesRegistration" class="loader loader--mini"></span>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary float-left cpe-register-back-button"
                  @click="backToAps"
                >
                  {{ $t('general.back') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="Register CPEs"
      v-model="massUuidsModal"
      @ok="registerCpes"
      class="modal-success"
      @cancel="massUuidsModal = false"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('aps.registerCPEs') }}</h4>
      <p>{{ $t('aps.registerCpeRegisterFollowCPEs') }}?</p>
      <ul>
        <li class="uuid font-italic" v-for="id in uuidsForRegister" :key="id">{{ id }}</li>
      </ul>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary pull-right" @click="massUuidsModal = false">
          {{ $t('general.cancel') }}
        </button>
        <button type="button" class="btn btn-success pull-right" :disabled="muteCpesRegistration" @click="registerCpes">
          <span :class="{ invisible: muteCpesRegistration }">{{ $t('aps.register') }}</span>
          <span v-if="muteCpesRegistration" class="loader loader--mini"></span>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import Papa from 'papaparse';
import cpePollingService from '../services/cpePollingService';
import commonService from '../services/commonService';
import Modal from '../components/Modal.vue';
import helpers from '../helpers';
import { getCsvTip } from '../helpers/tooltip';

export default {
  name: 'RegisterCpe',
  components: {
    Modal
  },
  data() {
    return {
      newCpeUuid: '',
      base_location: '',
      location1: '',
      massRegisterCpe: false,
      error: '',
      massUuids: [],
      UuidsToReistration: [],
      massUuidsModal: false,
      fileTypeError: false,
      newUuid: '',
      invalidUuid: false
    };
  },
  computed: {
    root() {
      return window.wimark.root;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    uuidsForRegister() {
      return this.massUuids;
    },
    muteCpesRegistration() {
      return this.$store.state.muteCpesRegistration;
    }
  },
  methods: {
    getCsvTip,
    registerCpe() {
      this.$validator.validateAll({ uuid: this.newCpeUuid }).then(
        (result) => {
          if (result) {
            Vue.axios
              .post(`${window.RESTAPI || ''}/api/cpes`, {
                action: 'C',
                items: {
                  [this.newCpeUuid]: {
                    config_status: 'empty',
                    base_location: this.base_location
                  }
                }
              })
              .then(
                (response) => {
                  if (response.data.status === 'error') {
                    VueNotifications.error({
                      message: response.data.description
                    });
                  }
                  cpePollingService.hadleOperationResponse(response.data.data.items);
                  this.$router.push('aps');
                },
                (err) => {
                  VueNotifications.error({ message: err });
                  this.error = err;
                }
              );
          }
        },
        () => {
          console.warn('Validation Fialed');
        }
      );
    },
    registerCpes() {
      if (this.newUuid) {
        this.UuidsToReistration.push(this.newUuid);
        this.newUuid = '';
      }
      this.$store.commit('toggleMuteCpesRegistration', true);
      const uuidsForRegister = [];
      this.UuidsToReistration.forEach((uuid) => {
        const query = {
          id: uuid,
          config_status: 'empty',
          base_location: this.base_location
        };
        uuidsForRegister.push(query);
      });
      const uuidsObj = helpers.transformArrayToObject(uuidsForRegister);
      Vue.axios
        .post(`${window.RESTAPI || ''}/api/cpes`, {
          action: 'C',
          items: uuidsObj
        })
        .then(
          (response) => {
            if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
            cpePollingService.hadleOperationResponse(response.data.data.items);
            this.massUuidsModal = false;
            this.$router.push('aps');
            this.$store.commit('toggleMuteCpesRegistration', false);
          },
          (err) => {
            VueNotifications.error({ message: err });
            this.error = err;
            this.$store.commit('toggleMuteCpesRegistration', false);
          }
        );
    },
    addUUIID() {
      this.$validator
        .validateAll({
          uuid: this.newUuid
        })
        .then((result) => {
          if (result) {
            if (!this.UuidsToReistration.includes(this.newUuid)) {
              this.UuidsToReistration.push(this.newUuid);
              this.newUuid = '';
            } else {
              this.newUuid = '';
            }
          }
        });
    },
    removeUuid(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.UuidsToReistration.splice(index, 1);
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    backToAps() {
      this.$router.push('aps');
    },
    previewUuids(event) {
      const that = this;
      function showCsv() {
        const input = event.target;
        if (input.files && input.files[0] && input.files[0].type === 'text/csv') {
          Papa.parse(input.files[0], {
            complete(results) {
              const { data } = results;
              const uuids = data.reduce((acc, val) => acc.concat(val), []);
              const correctUuids = uuids.filter((id) => id.length === 36);

              const newUuidArray = new Set([...that.UuidsToReistration, ...correctUuids]);
              that.UuidsToReistration = Array.from(newUuidArray);

              that.invalidUuid = false;
              that.fileTypeError = false;
              input.value = '';
            }
          });
        } else {
          that.invalidUuid = false;
          that.fileTypeError = true;
        }
      }
      showCsv();
    }
  },
  beforeCreate() {
    if (this.$store.state.userData.role === 'operator') {
      this.$router.push('../../aps');
    }
  },
  created() {
    this.base_location = this.$store.state.userData.base_location;
  }
};
</script>
<style lang="scss">
.wizard-cpe-icon {
  height: 80px;
}
.cpe-register-container {
  min-height: 250px;
}
.cpe-register-next-button {
  top: 10px;
  position: relative;
}
.cpe-register-back-button {
  position: relative;
}
.uuid {
  font-size: 90%;
  display: block;
  margin-bottom: 7px;
  cursor: pointer;
}
</style>
