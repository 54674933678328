import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from "../store";

export default {
  async requestCoordsByAddress(address) {
    // const data = {
    //   action: 'R',
    //   search: companyName.toString()
    // };
    const params = {
      q: address,
      format: 'json',
      addressdetails: 1
    };

    const json = await Vue.axios.get('https://nominatim.openstreetmap.org/search', { params }).then(
      (response) =>
        // console.log(response);
        response.data,
      (err) => {
        console.log(err);
        return [];
      }
    );
    // if (json.data.status === 'success') {
    //   // return json.data.data.itemslist;
    //   console.log(json.data)
    //   return json.data.data.itemslist;
    // }

    for (const item of json) {
      item.display_name = helpers.cutUnwantedWordsFromAddressStringFromOpenstreetmapResponse(item.display_name)
    }

    return json;
  },
  async requestCompany(companyName) {
    const data = {
      action: 'R',
      search: companyName.toString()
    };
    const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/party/suggest`, data).then(
      (response) => {
        // console.log(response);
        if (response.data.status === 'success') {
          // return json.data.data.itemslist;
          response.data.data.itemslist.forEach((company) => {
            // добавляем в спискок с комппаниями поле, содеражащее имя компании  и название города (если город указан),
            // и потом вываодим его в список компаний, а не просто название
            // а то не понятно что выбирать когда находятся несколько компаний с одинаковым именем
            company.nameForShowing = company.value;
            if (company.hasOwnProperty('data')) {
              if (company.data.hasOwnProperty('address')) {
                if (company.data.address.hasOwnProperty('data')) {
                  if (company.data.address.data.hasOwnProperty('city')) {
                    // company.nameForShowing = company.nameForShowing + ' | ' + company.data.address.data.city;
                    company.nameForShowing = `${company.nameForShowing} (${company.data.address.data.city})`;
                  }
                }
              }
            }
          });
          // console.log(response.data.data.itemslist)
          return response.data.data.itemslist;
        }
        return [];
      },
      (err) => {
        console.log(err);
        return [];
      }
    );
    // if (json.data.status === 'success') {
    //   // return json.data.data.itemslist;
    //   return json.data.data.itemslist;
    // }
    return json;
  },
  getLocations(ctx, query = {}) {
    ctx.$store.commit('toggleLoadingLocations');
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/locations`, {
        action: 'R',
        with_childs: true,
        ...query
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const items = helpers.transformArrayToObject(response.data.data.itemslist);
            ctx.$store.commit('setLocationsItems', items);
            ctx.$store.commit('setLocationsList', response.data.data.itemslist);
            ctx.$store.commit('toggleLoadingLocations');
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleLoadingLocations');
          ctx.error = err;
        }
      );
  },

  createLocation(ctx, location) {
    ctx.$store.commit('toggleMuteLocationEdit');
    Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, { action: 'C', items: { 0: location } }).then(
      (response) => {
        if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        } else {
          VueNotifications.success({ message: ctx.$t('location.notificationLocationCreatedSuccessfully') });
        }
        ctx.getLocations();
        ctx.cancelEditMode();
        // если строчка ниже раскомменчена, то после создания локации появляется модалка с Id созданой лоакции
        // ctx.savedLocId = response.data.data.itemslist[0].loc_id;
        ctx.$store.commit('toggleMuteLocationEdit');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteLocationEdit');
      }
    );
  },
  createLocationFromUsersDemoWizard(ctx, location) {
    Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, { action: 'C', items: { 0: location } }).then(
      (response) => {
        if (response.data.status === 'error') {
          if (response.data.description === 'Object with this creds already created') {
            VueNotifications.error({ message: ctx.$t('usersDemoWizard.step1ErrorLocationWithThisNameAlredyExists') });
          } else {
            VueNotifications.error({ message: response.data.description });
            ctx.exitFromWizard();
          }
        } else {
          VueNotifications.success({ message: ctx.$t('location.notificationLocationCreatedSuccessfully') });
          ctx.$store.commit('setUsersDemoWizardStep1SavedLocationObject', response.data.data.itemslist[0]);
          ctx.nextStep();
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.exitFromWizard();
      }
    );
  },

  updateLocation(ctx, location) {
    ctx.$store.commit('toggleMuteLocationEdit');
    const query = {
      action: 'U',
      items: {
        [location.id]: {
          description: location.description,
          manager: location.manager,
          address: location.address,
          coords: location.coords,
          custom_logo: location.custom_logo,
          custom_styles_enable: location.custom_styles_enable,
          custom_styles: location.custom_styles,
          limits: location.limits,
          party: location.party
        }
      }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, query).then(
      (response) => {
        if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        } else {
          VueNotifications.success({ message: ctx.$t('location.notificationLocationUpdatedSuccessfully') });
        }
        ctx.getLocations();
        ctx.cancelEditMode();
        ctx.$store.commit('toggleMuteLocationEdit');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteLocationEdit');
      }
    );
  },

  deleteLocation(ctx, location) {
    ctx.$store.commit('toggleMuteLocationEdit');
    const query = { action: 'D', items: { [location.id]: {} } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, query).then(
      (response) => {
        if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        } else {
          VueNotifications.success({ message: ctx.$t('location.notificationLocationDeletedSuccessfully') });
        }
        ctx.getLocations();
        ctx.cancelEditMode();
        ctx.$store.commit('toggleMuteLocationEdit');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteLocationEdit');
      }
    );
  },
  // async requestLocations(searchQuery) {
  //   const data = {
  //     action: 'R',
  //     search: searchQuery.toString(),
  //     limit: 20
  //   };
  //   const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, data);
  //   if (json.data.status === 'success') {
  //     return json.data.data.itemslist;
  //   }
  //   return [];
  // },
  // async requestLocationsWithSort(searchQuery) {
  //   const data = {
  //     action: 'R',
  //     search: searchQuery.toString(),
  //     limit: 50
  //   };
  //   const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, data);
  //   if (json.data.status === 'success') {
  //     // return json.data.data.itemslist;
  //     return helpers.sortLocationsList(json.data.data.itemslist);
  //   }
  //   return [];
  // }
  async requestLocations(searchQuery) {
    const data = {
      action: 'R',
      with_childs: true,
      search: searchQuery.toString(),
      limit: 20
    };
    const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, data);
    if (json.data.status === 'success') {
      return helpers.addCompanyNamesToLocationNames(json.data.data.itemslist);
    }
    return [];
  },
  async requestLocationsWithSort(searchQuery) {
    const data = {
      action: 'R',
      with_childs: true,
      search: searchQuery.toString(),
      limit: 50
    };
    const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, data);
    if (json.data.status === 'success') {
      // return json.data.data.itemslist;
      return helpers.addCompanyNamesToLocationNames(helpers.sortLocationsList(json.data.data.itemslist));
    }
    return [];
  },

  // async requestLocationsWithSortForLocation(searchQuery) {
  //   const data = {
  //     action: 'R',
  //     location: store.getters.getDefaultLocationForRequests(), with_childs: store.getters.getDefaultWithChildsForRequests(),
  //     search: searchQuery.toString(),
  //     limit: 50
  //   };
  //   const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, data);
  //   if (json.data.status === 'success') {
  //     // return json.data.data.itemslist;
  //     return helpers.addCompanyNamesToLocationNames(helpers.sortLocationsList(json.data.data.itemslist));
  //   }
  //   return [];
  // },

  async requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(ctx, searchQuery) {
    const data = {
      action: 'R',
      search: searchQuery.toString(),
      limit: 5000
    };
    const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, data);
    if (json.data.status === 'success') {
      // return json.data.data.itemslist;
      if (ctx.$store.getters.getDefaultLocationForRequests()) {
        const filtredLocations = [];
        const locationsIdsForFiltering = ctx.$store.state.inHeaderLocationSelectBlockData.selectedLocationAndChildsLocationsIDs
        if (ctx.$store.state.inHeaderLocationSelectBlockData.withChilds === true) {
          for (const location of json.data.data.itemslist) {
            if (locationsIdsForFiltering.includes(location.id)) {
              // console.log('pushed', location)
              filtredLocations.push(location);
            }
          }
        } else {
          for (const location of json.data.data.itemslist) {
            if (ctx.$store.state.inHeaderLocationSelectBlockData.selectedLocationID === location.id) {
              // console.log('pushed', location)
              filtredLocations.push(location);
            }
          }

        }

        if (filtredLocations.length) {
          return helpers.addCompanyNamesToLocationNames(helpers.sortLocationsList(filtredLocations));
        } else {
          return [];
        }
      }
      return helpers.addCompanyNamesToLocationNames(helpers.sortLocationsList(json.data.data.itemslist));
    }
    return [];
  },

  async requestLocationsWithSortForUseInHeaderLocationSelector(searchQuery) {
    const data = {
      action: 'R',
      with_childs: true,
      search: searchQuery.toString(),
    };
    const json = await Vue.axios.post(`${window.RESTAPI || ''}/api/locations`, data);
    if (json.data.status === 'success') {
      // return json.data.data.itemslist;
      return helpers.addCompanyNamesToLocationNames(helpers.sortLocationsList(json.data.data.itemslist));
    }
    return [];
  },

  getCompanys(ctx, query = {}) {
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/party/locations`, {
        action: 'R',
        with_childs: true,
        ...query
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.commit('setCompanysItems', response.data.data.itemslist);
          } else {
            ctx.commit('setCompanysItems', []);
            console.log(response.data);
          }
        },
        (err) => {
          ctx.commit('setCompanysItems', []);
          console.log(err);
        }
      );
  }
};
