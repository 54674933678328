<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <!--    <loader-spinner centered v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"></loader-spinner>-->
    <div class=" card-in-new-wlan-wizard mb-1 ml-1" >
      <div class="card card-inverse card-success card-in-new-wlan-wizard">
        <div class="card-block d-flex flex-column justify-content-center align-items-center">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="root + '/static/img/wifi1.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('wlans.wlanWizardV2') }}</div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 mb-1">
      <div class="card d-flex flex-column justify-content-start align-items-start">
        <div class="custom-tabs-wrapper-in-wlans-wizard">
          <ul class="nav nav-tabs row m-0" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'step1' }"
                @click="setCurrentTabToStepN('step1')"
              >
                {{ $t('general.basicParameters') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'step2' }"
                @click="setCurrentTabToStepN('step2')"
              >
                {{ $t('wlans.security') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'step3' }"
                @click="setCurrentTabToStepN('step3')"
              >
                {{ $t('wlans.dataTrafficManagement') }}
              </a>
            </li>
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                class="nav-link"-->
<!--                role="tab"-->
<!--                :class="{ active: currentTabStep === 'step4' }"-->
<!--                @click="setCurrentTabToStepN('step4')"-->
<!--              >-->
<!--                {{ $t('wlans.wizardRADIUSAccounting') }}-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                class="nav-link"-->
<!--                role="tab"-->
<!--                :class="{ active: currentTabStep === 'step5' }"-->
<!--                @click="setCurrentTabToStepN('step5')"-->
<!--              >-->
<!--                {{ $t('wlans.wizardRADIUSAdditionalSettings') }}-->
<!--              </a>-->
<!--            </li>-->
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'step6' }"
                @click="setCurrentTabToStepN('step6')"
              >
                {{ $t('wlans.wizardSpeedControlSettings') }}
              </a>
            </li>
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                class="nav-link"-->
<!--                role="tab"-->
<!--                :class="{ active: currentTabStep === 'step7' }"-->
<!--                @click="setCurrentTabToStepN('step7')"-->
<!--              >-->
<!--                {{ $t('wlans.wizardGuestControl') }}-->
<!--              </a>-->
<!--            </li>-->
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'step8' }"
                @click="setCurrentTabToStepN('step8')"
              >
                {{ $t('wlans.wizardMobility') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'step9' }"
                @click="setCurrentTabToStepN('step9')"
              >
                {{ $t('wlans.wizardWMM') }}
              </a>
            </li>
          </ul>
        </div>
        <div class="w-100 d-flex justify-content-start">
          <div class="w-75">
            <!--              Step 1-->
            <Step1 ref="step1" @dataNoValid="dataIsNoValidHandler"  @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step1'"></Step1>
            <Step2 ref="step2" @addNewRedirectClicked="newRedirectAddClickHandler('step2')"  @addNewRadiusClicked="newRadiusAddClickHandler('step2')" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step2'"></Step2>
            <Step3 ref="step3" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step3'"></Step3>
<!--            <Step4 ref="step4" @addNewRadiusClicked="newRadiusAddClickHandler('step4')" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step4'"></Step4>-->
<!--            <Step5 ref="step5" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step5'"></Step5>-->
            <Step6 ref="step6" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step6'"></Step6>
<!--            <Step7 ref="step7"  @addNewRadiusClicked="newRadiusAddClickHandler('step7')" @addNewRedirectClicked="newRedirectAddClickHandler('step7')" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step7'"></Step7>-->
            <Step8 ref="step8" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step8'"></Step8>
            <Step9 ref="step9" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step9'"></Step9>
          </div>
        </div>
        <div class="w-100">
            <button type="button"  class="btn btn-success float-right  mr-2 mb-2" @click="createNewWlanButtonClickHandler">
            {{ $t('general.create') }}
          </button>
          </div>

        <!--      <div class="wlan-wizard-steps-wrapper">-->
        <!--        <router-view></router-view>-->
        <!--      </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import Step5 from './Step5.vue';
import Step6 from './Step6.vue';
import Step7 from './Step7.vue';
import Step8 from './Step8.vue';
import Step9 from './Step9.vue';
import VueNotifications from "vue-notifications";
import wlansPollingService from "../../../services/wlansPollingService";



export default {
  name: 'WlanWizardV2',
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
  },
  data() {
    return {
      currentTabStep: 'step1',
      validatorErrorsInTabs: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false,
        step7: false,
        step8: false,
        step9: false
      }
    };
  },
  computed: {
    areValidatorErrors() {
      return Object.values(this.validatorErrorsInTabs).some((item)=>{
        return item === true;
      })
    },
    // step 1
    // locationsList() {
    //   // return this.$store.state.locationsList;
    //   // return helpers.sortLocationsList(this.$store.state.locationsList);
    //   return this.$store.getters.locationsListSorted;
    // },

    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    root() {
      return window.wimark.root;
    },
    currentStepIndex() {
      if (this.$route.name === 'StepLast') {
        return 'StepLast';
      }
      return this.$route.name.replace('step', '');
    },
    progressWidth() {
      let width;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'step1':
          width = 10;
          break;
        case 'step2':
          width = 20;
          break;
        case 'step3':
          width = 30;
          break;
        case 'step4':
          width = 40;
          break;
        case 'step5':
          width = 50;
          break;
        case 'step6':
          width = 60;
          break;
        case 'step7':
          width = 70;
          break;
        case 'step8':
          width = 80;
          break;
        case 'step9':
          width = 90;
          break;
        case 'StepLast':
          width = 100;
          break;
        default:
          width = 100;
      }
      return width;
    }
  },
  methods: {
    newRadiusAddClickHandler(stepName) {
      this.$store.commit('setStepNameForReturningToCreateWLANWizard', stepName)

      this.$router.push({name: 'radius-wizard'})
    },
    newRedirectAddClickHandler(stepName) {
      this.$store.commit('setStepNameForReturningToCreateWLANWizard', stepName)

      this.$router.push({name: 'GuestControl-wizard'})
    },
    createNewWlanButtonClickHandler() {
      this.$refs[`${this.currentTabStep}`].validateAndSetNewWlanData(this.currentTabStep, this.currentTabStep)
      setTimeout(() => {
        if (!this.areValidatorErrors) {
          this.confirmNewWlanAndCreate()
        }
      }, 200)
    },
    confirmNewWlanAndCreate() {
      const transformedQuery = { ... JSON.parse(JSON.stringify(this.$store.state.newWlanData)) };
      // console.log(transformedQuery)
      transformedQuery.vlan = parseInt(transformedQuery.vlan);
      if (!transformedQuery.vlan) {
       transformedQuery.vlan = 0;
      }
      // open wlan case
      if (!transformedQuery.security.type) {
        transformedQuery.security = null;
      } else if (transformedQuery.security.type.indexOf('personal') !== -1) {
        delete transformedQuery.security.data.radius_auth;
      } else if (transformedQuery.security.type.indexOf('enterprise') !== -1) {
        delete transformedQuery.security.data.psk;
      }
      this.axios.post(`${window.RESTAPI || ''}/api/wlans`, { action: 'C', items: { 0: transformedQuery } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewWlanData');
          this.$store.commit('setNewWlanWizardSecurityTab', 'L2');
          this.$router.push({ name: 'Wlans' });
          wlansPollingService.hadleOperationResponse(response.data.data.items);
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    dataIsValidHandler(dataObject){
      const nextTab = dataObject.nextTab
      const currentTab = dataObject.currentTab
      this.switchToTab(nextTab)
      this.setNoValidationErrorsForTab(currentTab);
    },
    dataIsNoValidHandler(dataObject){
      const currentTab = dataObject.currentTab
      this.setValidationErrorsForTab(currentTab);
    },
    switchToTab(n) {
      this.currentTabStep = n;
    },
    setValidationErrorsForTab(n){
      console.log('setValidationErrorsForTab', n)
      this.validatorErrorsInTabs[`${n}`] = true;
    },
    setNoValidationErrorsForTab(n){
      this.validatorErrorsInTabs[`${n}`] = false;
    },
    setCurrentTabToStepN(n) {
      const currentTabStep = this.currentTabStep
      if (currentTabStep === n) {
        console.log('here')
        return;
      }
      this.$refs[`${currentTabStep}`].validateAndSetNewWlanData(currentTabStep, n)

    },
    // getStepBlockWidthClass(currentStepIndex) {
    //   if (
    //     currentStepIndex === '7' ||
    //     currentStepIndex === '9') {
    //     return 'col-lg-5';
    //   }
    //   if (currentStepIndex === 'StepLast') {
    //     return 'col-lg-5';
    //   }
    //   return 'col-lg-4';
    // },
    getStepBlockWidthClass(currentStepIndex) {
      return 'col-lg-5';
    },
    // nextStep(lastStepClicked = false) {
    //   let nextStep;
    //   const currentStepRoute = this.$route.name;
    //
    //   switch (currentStepRoute) {
    //     case 'step1':
    //       nextStep = 'step2';
    //       break;
    //     case 'step2':
    //       nextStep = 'step3';
    //       break;
    //     case 'step3':
    //       nextStep = 'step4';
    //       break;
    //     case 'step4':
    //       nextStep = 'step5';
    //       break;
    //     case 'step5':
    //       nextStep = 'step6';
    //       break;
    //     case 'step6':
    //       nextStep = 'step7';
    //       break;
    //     case 'step7':
    //       nextStep = 'step8';
    //       break;
    //     case 'step8':
    //       nextStep = 'step9';
    //       break;
    //     case 'step9':
    //       nextStep = 'StepLast';
    //       break;
    //     default:
    //       nextStep = 'Wlans';
    //   }
    //   // this.$router.push({ name: nextStep });
    //   if (lastStepClicked === false) {
    //     this.$router.push({ name: nextStep });
    //   } else {
    //     this.$store.commit('wlanWizardGoingToLastStepAfterClickSkipButtonStart');
    //     this.$router.push({ name: nextStep, params: { lastStepClicked: true } });
    //   }
    // },
    // prevStep() {
    //   let prevStep;
    //   const currentStepRoute = this.$route.name;
    //
    //   switch (currentStepRoute) {
    //     case 'step1':
    //       prevStep = 'step1';
    //       break;
    //     case 'step2':
    //       prevStep = 'step1';
    //       break;
    //     case 'step3':
    //       prevStep = 'step2';
    //       break;
    //     case 'step4':
    //       prevStep = 'step3';
    //       break;
    //     case 'step5':
    //       prevStep = 'step4';
    //       break;
    //     case 'step6':
    //       prevStep = 'step5';
    //       break;
    //     case 'step7':
    //       prevStep = 'step6';
    //       break;
    //     case 'step8':
    //       prevStep = 'step7';
    //       break;
    //     case 'step9':
    //       prevStep = 'step8';
    //       break;
    //     case 'StepLast':
    //       prevStep = 'step9';
    //       break;
    //     default:
    //       prevStep = 'step1';
    //   }
    //   this.$router.push(prevStep);
    // },
    lastStep() {
      this.$store.commit('wlanWizardGoingToLastStepAfterClickSkipButtonStart');
      this.$router.push({ name: 'StepLast', params: { lastStepClicked: true } });
    },
    completeCreation() {
      this.$router.push({ path: '/wlans' });
    }
  },
  beforeCreate() {
    if (this.$store.state.userData.role == 'operator') {
      this.$router.push('../../wlans');
    }
  },
  created() {
    if (this.$store.state.stepNameForReturningToCreateWLANWizard){
      this.switchToTab(this.$store.state.stepNameForReturningToCreateWLANWizard)
    }
    this.$store.commit('clearStepNameForReturningToCreateWLANWizard')

  }
};
</script>
<style lang="scss">
  .card-in-new-wlan-wizard{
    width: 200px !important;
    height: 200px !important;
  }
.custom-tabs-wrapper-in-wlans-wizard {
  border-bottom: 0px !important;
  width: 100%;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-link {
  font-size: 0.85em;
  border: 1px solid  rgba(128,128,128, 0.1) !important;
  margin-left: 0.01em !important;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-link:hover {
  background-color: rgba(128,128,128, 0.3) !important;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-item .active {
  /*background-color: rgba(128,128,128, 0.05)   !important;*/
  box-shadow: 2px 2px 3px rgba(128,128,128, 0.5) !important;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-item {
  background-color: rgba(128,128,128, 0.05)   !important
}
.custom-tabs-wrapper-in-wlans-wizard .nav.nav-tabs.row {
  border-radius: 0px 0px 0px 0px !important;
}
.custom-tabs-wrapper-in-wlans-wizard  ul.nav.nav-tabs li.nav-item a{
  border-radius: 12.5px 12.5px 0px 0px !important;
}

.wizard-wlan-icon {
  height: 80px;
}

.wlan-wizard-steps-wrapper {
  min-height: 655px !important;
}
</style>
