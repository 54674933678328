<template>
  <div class="d-flex flex-column gateway-settings__wrapper pr-0" @mouseleave="mouseLeaveGetawaySettingsHandler">
    <div class="col-12 pl-0">
      <div class="row">
        <div class="col-lg-12 p-0">
          <div role="button" class="font-weight-bold" @click.stop.prevent="toggleShowAllGatewaySettingsBlock">
            <i v-if="isShowAllGatewaySettingsBlock" class="icon-arrow-down"></i>
            <i v-else class="icon-arrow-right"></i>
            {{ $t('portal.gatewaySettings.blockHeader') }}
            <a
              v-if="isShowAllGatewaySettingsBlock"
              class="ml-1 small"
              href=""
              role="button"
              @click.prevent.stop="clearSettingsButtonHandler"
            >
              {{ $t('portal.gatewaySettings.clear') }}
            </a>
          </div>
        </div>
      </div>

      <transition name="portalProfileGatewaySettingsTransition">
        <div class="gateway-settings-hideable-block mt-1 pl-h" v-show="isShowAllGatewaySettingsBlock">
          <!--          <transition name="portalProfileGatewaySettingsTransition" >-->
          <Alert v-if="showParamsHintForGETBlock" class="alert-info overflowY-auto mt-1" style="max-height: 500px">
            <template #text>
              <div class="gateway-settings__hint-msg" v-if="isRussianLocale">
                <p class="mb-h">
                  В URL укажите в шаблонном формате API используемого сервиса, обязательно оставив слова {PHONE} и
                  {MESSAGE} в тексте. При отправке одноразового кода (OTP) поля {PHONE} и {MESSAGE} будут изменены на
                  реальные.
                </p>
                <p class="mb-h">Пример для сервиса sms.ru:</p>
                <p class="mb-h">Метод: GET</p>
                <p class="mb-h">
                  URL: https://sms.ru/sms/send?api_id=&#60;ваш id из личного кабинета
                  sms.ru&#62;&to={PHONE}&msg={MESSAGE}&json=1
                </p>
              </div>
              <div class="gateway-settings__hint-msg" v-else>
                <p class="mb-h">
                  In the URL, specify the API of the service you are using in the template format, be sure to leave the
                  words {PHONE} and {MESSAGE} in the text. When sending a one-time code (OTP), the {PHONE} and {MESSAGE}
                  fields will be changed to real ones
                </p>
                <p class="mb-h">Example for sms.ru service:</p>
                <p class="mb-h">Method: GET</p>
                <p class="mb-h">
                  URL: https://sms.ru/sms/send?api_id=&#60;ваш id из личного кабинета
                  sms.ru&#62;&to={PHONE}&msg={MESSAGE}&json=1
                </p>
              </div>
            </template>
          </Alert>
          <!--          </transition>-->
          <!--          <transition name="portalProfileGatewaySettingsTransition">-->
          <Alert v-if="showParamsHintForPOSTBlock" class="alert-info overflowY-auto mt-1" style="max-height: 500px">
            <template #text>
              <div class="gateway-settings__hint-msg" v-if="isRussianLocale">
                <p class="mb-h">
                  В URL и/или Payload укажите в шаблонном формате API используемого сервиса, обязательно оставив слова
                  {PHONE} и {MESSAGE} в тексте. При отправке одноразового кода (OTP) поля {PHONE} и {MESSAGE} будут
                  изменены на реальные.
                </p>
                <p class="mb-h">Пример для сервиса sms.ru:</p>
                <p class="mb-h">Метод: GET</p>
                <p class="mb-h">
                  URL: https://sms.ru/sms/send?api_id=&#60;ваш id из личного кабинета
                  sms.ru&#62;&to={PHONE}&msg={MESSAGE}&json=1
                </p>
              </div>
              <div class="gateway-settings__hint-msg" v-else>
                <p class="mb-q">
                  In the URL and/or Payload, specify the API of the service you are using in the template format, be
                  sure to leave the words {PHONE} and {MESSAGE} in the text. When sending a one-time code (OTP), the
                  {PHONE} and {MESSAGE} fields will be changed to real ones
                </p>
                <p class="mb-q">Example for sms.ru service:</p>
                <p class="mb-q">Method: GET</p>
                <p class="mb-q">
                  URL: https://sms.ru/sms/send?api_id=&#60;ваш id из личного кабинета
                  sms.ru&#62;&to={PHONE}&msg={MESSAGE}&json=1
                </p>
              </div>
            </template>
          </Alert>
          <!--          </transition>-->
          <div class="row">
            <div class="col-12 form-group">
              <label :for="`${authNTypeName}-method`">{{ $t('portal.gatewaySettings.method') }}</label>
              <select
                @change="methodChangeHandler"
                type="select"
                :name="`${authNTypeName}-method`"
                :id="`${authNTypeName}-method`"
                placeholder="In sec"
                class="form-control input"
                :aria-describedby="`${authNTypeName}-method`"
                :class="{ 'is-danger': errors.has(`${authNTypeName}-method`) }"
              >
                <option value="" :selected="portal_request.method === ''">
                  {{ $t('portal.gatewaySettings.methodNoSelected') }}
                </option>
                <option
                  v-for="method of methodTypesAsArray"
                  :value="method"
                  :selected="method === portal_request.method"
                >
                  {{ method }}
                </option>
              </select>
              <span v-show="errors.has(`${authNTypeName}-method`)" class="help is-danger">
                {{ errors.first(`${authNTypeName}-method`) }}
              </span>
            </div>
          </div>
          <transition name="portalProfileGatewaySettingsTransition" mode="out-in">
            <div class="row" v-if="showUrlBlock">
              <div class="col-12 form-group">
                <label :for="`${authNTypeName}-url`">{{ $t('portal.gatewaySettings.url') }}</label>
                <input
                  :data-vv-as="$t('portal.gatewaySettings.url')"
                  type="text"
                  :name="`${authNTypeName}-url`"
                  :id="`${authNTypeName}-url`"
                  :placeholder="$t('portal.gatewaySettings.url')"
                  class="form-control input"
                  :aria-describedby="`${authNTypeName}-url`"
                  v-validate="'required'"
                  :class="{ 'is-danger': errors.has(`${authNTypeName}-url`) }"
                  v-model="portal_request.url"
                />
                <span v-show="errors.has(`${authNTypeName}-url`)" class="help is-danger">
                  {{ errors.first(`${authNTypeName}-url`) }}
                </span>
              </div>
            </div>
          </transition>
          <!--          content type as input-->
          <!--          <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--            <div class="row" v-if="showContentTypeBlock">-->
          <!--              <div class="col-12 form-group">-->
          <!--                <label :for="`${authNTypeName}-content_type`">{{ $t('portal.gatewaySettings.content_type') }}</label>-->
          <!--                <input-->
          <!--                  :data-vv-as="$t('portal.gatewaySettings.content_type')"-->
          <!--                  type="text"-->
          <!--                  :name="`${authNTypeName}-content_type`"-->
          <!--                  :id="`${authNTypeName}-content_type`"-->
          <!--                  :placeholder="$t('portal.gatewaySettings.content_type')"-->
          <!--                  class="form-control input"-->
          <!--                  :aria-describedby="`${authNTypeName}-content_type`"-->
          <!--                  v-validate="'required'"-->
          <!--                  :class="{ 'is-danger': errors.has(`${authNTypeName}-content_type`) }"-->
          <!--                  v-model="portal_request.content_type"-->
          <!--                />-->
          <!--                <span v-show="errors.has(`${authNTypeName}-content_type`)" class="help is-danger">-->
          <!--                  {{ errors.first(`${authNTypeName}-content_type`) }}-->
          <!--                </span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </transition>-->
          <!--          content type as selector-->
          <transition name="portalProfileGatewaySettingsTransition" mode="out-in">
            <div class="row" v-if="showContentTypeBlock">
              <div class="col-12 form-group">
                <label :for="`${authNTypeName}-content_type`">{{ $t('portal.gatewaySettings.content_type') }}</label>
                <select
                  @change="contentTypeChangeHandler"
                  type="select"
                  :name="`${authNTypeName}-content_type`"
                  :id="`${authNTypeName}-content_type`"
                  placeholder="In sec"
                  class="form-control input"
                  :aria-describedby="`${authNTypeName}-content_type`"
                  :class="{ 'is-danger': errors.has(`${authNTypeName}-content_type`) }"
                >
                  <option
                    v-for="contenType of contentTypesAsArray"
                    :value="contenType"
                    :selected="contenType === portal_request.content_type"
                  >
                    {{ contenType }}
                  </option>
                </select>
                <span v-show="errors.has(`${authNTypeName}-content_type`)" class="help is-danger">
                  {{ errors.first(`${authNTypeName}-content_type`) }}
                </span>
              </div>
            </div>
          </transition>
          <!--                           payload as textarea-->
          <!--          <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--            <div class="row" v-if="showPayloadTextarea">-->
          <!--              <div class="col-12 form-group">-->
          <!--                <label :for="`${authNTypeName}-payload_textarea`">-->
          <!--                  {{ $t('portal.gatewaySettings.payload_textarea') }}-->
          <!--                </label>-->
          <!--                <textarea-->
          <!--                  :data-vv-as="$t('portal.gatewaySettings.payload_textarea')"-->
          <!--                  type="text"-->
          <!--                  :name="`${authNTypeName}-payload_textarea`"-->
          <!--                  :id="`${authNTypeName}-payload_textarea`"-->
          <!--                  :placeholder="$t('portal.gatewaySettings.payload_textarea')"-->
          <!--                  class="form-control input"-->
          <!--                  :aria-describedby="`${authNTypeName}-payload_textarea`"-->
          <!--                  :class="{ 'is-danger': errors.has(`${authNTypeName}-payload_textarea`) }"-->
          <!--                  v-model="portal_request.payload"-->
          <!--                />-->
          <!--                <span v-show="errors.has(`${authNTypeName}-payload_textarea`)" class="help is-danger">-->
          <!--                  {{ errors.first(`${authNTypeName}-payload_textarea`) }}-->
          <!--                </span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </transition>-->
          <!--                           payload as textarea-->
          <transition>
            <div class="row" v-if="showPayloadAsJsonTextarea">
              <div class="col-12 form-group">
                <label :for="`${authNTypeName}-payloadAsJson_textarea`">
                  {{ $t('portal.gatewaySettings.payload_textarea') }}
                </label>
                <textarea
                  rows="7"
                  @change="payloadAsJsonChangeHandler"
                  @mouseleave="payloadAsJsonChangeHandler"
                  :data-vv-as="$t('portal.gatewaySettings.payload_textarea')"
                  type="text"
                  :name="`${authNTypeName}-payloadAsJson_textarea`"
                  :id="`${authNTypeName}-payloadAsJson_textarea`"
                  :placeholder="$t('portal.gatewaySettings.payload_textarea')"
                  class="form-control input"
                  :aria-describedby="`${authNTypeName}-payloadAsJson_textarea`"
                  :class="{ 'is-danger': errors.has(`${authNTypeName}-payloadAsJson_textarea`) }"
                  v-model="payloadAsJson"
                />
                <span v-show="errors.has(`${authNTypeName}-payloadAsJson_textarea`)" class="help is-danger">
                  {{ errors.first(`${authNTypeName}-payloadAsJson_textarea`) }}
                </span>
              </div>
            </div>
          </transition>
          <transition>
            <div class="row" v-if="showPayloadAsXMLTextarea">
              <div class="col-12 form-group">
                <label :for="`${authNTypeName}-payloadAsXML_textarea`">
                  {{ $t('portal.gatewaySettings.payload_textarea') }}
                </label>
                <textarea
                  rows="7"
                  @change="payloadAsXMLChangeHandler"
                  @mouseleave="payloadAsXMLChangeHandler"
                  :data-vv-as="$t('portal.gatewaySettings.payload_textarea')"
                  type="text"
                  :name="`${authNTypeName}-payloadAsXML_textarea`"
                  :id="`${authNTypeName}-payloadAsXML_textarea`"
                  :placeholder="$t('portal.gatewaySettings.payload_textarea')"
                  class="form-control input"
                  :aria-describedby="`${authNTypeName}-payloadAsXML_textarea`"
                  :class="{ 'is-danger': errors.has(`${authNTypeName}-payloadAsXML_textarea`) }"
                  v-model="payloadAsXML"
                />
                <span v-show="errors.has(`${authNTypeName}-payloadAsXML_textarea`)" class="help is-danger">
                  {{ errors.first(`${authNTypeName}-payloadAsXML_textarea`) }}
                </span>
              </div>
            </div>
          </transition>
          <!--          <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--            <div class="row" v-if="showHeadersBlock">-->
          <!--              <div class="col-12 form-group">-->
          <!--                <label>{{ $t('portal.gatewaySettings.headers') }}</label>-->
          <!--                <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--                  <a-->
          <!--                    v-if="!isShowAddHeadersFields"-->
          <!--                    class="ml-1 small"-->
          <!--                    href=""-->
          <!--                    role="button"-->
          <!--                    @click.prevent.stop="showAddHeaderFields"-->
          <!--                  >-->
          <!--                    {{ $t('portal.gatewaySettings.add') }}-->
          <!--                  </a>-->
          <!--                </transition>-->
          <!--                <div class="gateway-settings-params-_wrapper">-->
          <!--                  &lt;!&ndash;        added headers fields&ndash;&gt;-->
          <!--                  <div v-if="headersLength !== 0" class="gateway-settings-params-list__item m-0">-->
          <!--                    <div class="row h-100 align-items-center m-0">-->
          <!--                      <div-->
          <!--                        class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data m-0 pl-0"-->
          <!--                      >-->
          <!--                        {{ $t('portal.gatewaySettings.header') }}-->
          <!--                      </div>-->
          <!--                      <div-->
          <!--                        class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data m-0"-->
          <!--                      >-->
          <!--                        {{ $t('portal.gatewaySettings.value') }}-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div-->
          <!--                    class="gateway-settings-params-list__item"-->
          <!--                    :class="{ 'gateway-settings-params-list__item-when-inputs-show': editHeaderFieldKey === key }"-->
          <!--                    v-for="key of headersOrderAsArray"-->
          <!--                    :key="key"-->
          <!--                  >-->
          <!--                    <div class="row h-100 align-items-center">-->
          <!--                      <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--                        <div-->
          <!--                          v-if="editHeaderFieldKey !== key"-->
          <!--                          class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data"-->
          <!--                          v-tooltip.top-center="{-->
          <!--                            content: key,-->
          <!--                            popperOptions: {-->
          <!--                              modifiers: {-->
          <!--                                preventOverflow: {-->
          <!--                                  boundariesElement: 'window'-->
          <!--                                }-->
          <!--                              }-->
          <!--                            }-->
          <!--                          }"-->
          <!--                        >-->
          <!--                          {{ key }}-->
          <!--                        </div>-->
          <!--                        <div v-else class="col-5 form-group gateway-settings-params-list-string__item">-->
          <!--                          <input-->
          <!--                            :data-vv-as="$t('portal.gatewaySettings.header')"-->
          <!--                            type="text"-->
          <!--                            :name="`${authNTypeName}-header-key-forEdit`"-->
          <!--                            :id="`${authNTypeName}-header-key-forEdit`"-->
          <!--                            :placeholder="$t('portal.gatewaySettings.header')"-->
          <!--                            class="form-control input gateway-settings&#45;&#45;params-list-string-input"-->
          <!--                            :aria-describedby="`${authNTypeName}-header-key-forEdit`"-->
          <!--                            v-validate="'required'"-->
          <!--                            :class="{ 'is-danger': errors.has(`${authNTypeName}-header-key-forEdit`) }"-->
          <!--                            v-model="editHeaderFieldNewKey"-->
          <!--                          />-->
          <!--                          &lt;!&ndash;                          <span v-show="errors.has(`${authNTypeName}-header-key-forEdit`)" class="help is-danger">&ndash;&gt;-->
          <!--                          &lt;!&ndash;                            {{ errors.first(`${authNTypeName}-header-key-forEdit`) }}&ndash;&gt;-->
          <!--                          &lt;!&ndash;                          </span>&ndash;&gt;-->
          <!--                        </div>-->
          <!--                      </transition>-->
          <!--                      <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--                        &lt;!&ndash; added header value &ndash;&gt;-->
          <!--                        <div-->
          <!--                          v-if="editHeaderFieldKey !== key"-->
          <!--                          class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data"-->
          <!--                          v-tooltip.top-center="{-->
          <!--                            content: portal_request.headers[key],-->
          <!--                            popperOptions: {-->
          <!--                              modifiers: {-->
          <!--                                preventOverflow: {-->
          <!--                                  boundariesElement: 'window'-->
          <!--                                }-->
          <!--                              }-->
          <!--                            }-->
          <!--                          }"-->
          <!--                        >-->
          <!--                          {{ portal_request.headers[key] }}-->
          <!--                        </div>-->
          <!--                        <div v-else class="col-5 form-group gateway-settings-params-list-string__item">-->
          <!--                          &lt;!&ndash; edit header value input &ndash;&gt;-->
          <!--                          <input-->
          <!--                            :data-vv-as="$t('portal.gatewaySettings.value')"-->
          <!--                            type="text"-->
          <!--                            :name="`${authNTypeName}-header-value-forEdit`"-->
          <!--                            :id="`${authNTypeName}-header-value-forEdit`"-->
          <!--                            :placeholder="$t('portal.gatewaySettings.value')"-->
          <!--                            class="form-control input gateway-settings&#45;&#45;params-list-string-input"-->
          <!--                            :aria-describedby="`${authNTypeName}-header-value-forEdit`"-->
          <!--                            v-validate="'required'"-->
          <!--                            :class="{ 'is-danger': errors.has(`${authNTypeName}-header-value-forEdit`) }"-->
          <!--                            v-model="editHeaderFieldValue"-->
          <!--                          />-->
          <!--                          &lt;!&ndash;                          <span v-show="errors.has(`${authNTypeName}-header-value-forEdit`)" class="help is-danger">&ndash;&gt;-->
          <!--                          &lt;!&ndash;                            {{ errors.first(`${authNTypeName}-header-value-forEdit`) }}&ndash;&gt;-->
          <!--                          &lt;!&ndash;                          </span>&ndash;&gt;-->
          <!--                        </div>-->
          <!--                      </transition>-->

          <!--                      <div class="col-2 form-group d-flex flex-column justify-content-end align-items-end">-->
          <!--                        <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--                          <div class="text-right d-flex flex-column align-items-end" v-if="editHeaderFieldKey !== key">-->
          <!--                            &lt;!&ndash; buttons if value showing &ndash;&gt;-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="editHeaderButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.edit') }}-->
          <!--                            </a>-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="delHeaderButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.delete') }}-->
          <!--                            </a>-->
          <!--                          </div>-->
          <!--                          <div class="text-right d-flex flex-column align-items-end" v-else>-->
          <!--                            &lt;!&ndash; buttons if value edit input showing &ndash;&gt;-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="cancelHeaderButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.cancel') }}-->
          <!--                            </a>-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="saveHeaderButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.save') }}-->
          <!--                            </a>-->
          <!--                          </div>-->
          <!--                        </transition>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    &lt;!&ndash;            <div><hr /></div>&ndash;&gt;-->
          <!--                  </div>-->

          <!--                  <div-->
          <!--                    v-if="headersLength === 0 && !isShowAddHeadersFields"-->
          <!--                    class="gateway-settings-params-list__item"-->
          <!--                    key="noHeaders"-->
          <!--                  >-->
          <!--                    <div class="row d-flex flex-column justify-content-center align-items-center">-->
          <!--                      <span class="text-muted">{{ $t('portal.gatewaySettings.noHeaders') }}</span>-->
          <!--                    </div>-->
          <!--                  </div>-->

          <!--                  <div-->
          <!--                    class="gateway-settings-params-list__item gateway-settings-params-list__item-when-inputs-show gateway-settings-params-list__item-when-fields-for-add-show"-->
          <!--                    v-if="isShowAddHeadersFields"-->
          <!--                    key="addHeader"-->
          <!--                  >-->
          <!--                    &lt;!&ndash;          add headers fields&ndash;&gt;-->
          <!--                    <div class="row d-flex align-items-center">-->
          <!--                      <div class="col-5 form-group">-->
          <!--                        &lt;!&ndash;              <label :for="`${authNTypeName}-header-key`">{{ $t('portal.gatewaySettings.header') }}</label>&ndash;&gt;-->
          <!--                        <input-->
          <!--                          :data-vv-as="$t('portal.gatewaySettings.header')"-->
          <!--                          type="text"-->
          <!--                          :name="`${authNTypeName}-header-key`"-->
          <!--                          :id="`${authNTypeName}-header-key`"-->
          <!--                          :placeholder="$t('portal.gatewaySettings.header')"-->
          <!--                          class="form-control input gateway-settings&#45;&#45;params-list-string-input"-->
          <!--                          :aria-describedby="`${authNTypeName}-header-key`"-->
          <!--                          v-validate="'required'"-->
          <!--                          :class="{ 'is-danger': errors.has(`${authNTypeName}-header-key`) }"-->
          <!--                          v-model="addHeadersFieldsKey"-->
          <!--                        />-->
          <!--                      </div>-->
          <!--                      <div class="col-5 form-group">-->
          <!--                        &lt;!&ndash;              <label :for="`${authNTypeName}-header-value`">{{ $t('portal.gatewaySettings.value') }}</label>&ndash;&gt;-->
          <!--                        <input-->
          <!--                          :data-vv-as="$t('portal.gatewaySettings.value')"-->
          <!--                          type="text"-->
          <!--                          :name="`${authNTypeName}-header-value`"-->
          <!--                          :id="`${authNTypeName}-header-value`"-->
          <!--                          :placeholder="$t('portal.gatewaySettings.value')"-->
          <!--                          class="form-control input gateway-settings&#45;&#45;params-list-string-input gateway-settings&#45;&#45;params-list-string-input-for-add-second-column"-->
          <!--                          :aria-describedby="`${authNTypeName}-header-value`"-->
          <!--                          v-validate="'required'"-->
          <!--                          :class="{ 'is-danger': errors.has(`${authNTypeName}-header-value`) }"-->
          <!--                          v-model="addHeadersFieldsValue"-->
          <!--                        />-->
          <!--                      </div>-->
          <!--                      <div class="col-2 form-group d-flex flex-column justify-content-between align-items-end">-->
          <!--                        <a-->
          <!--                          class="small m-0 p-0"-->
          <!--                          href=""-->
          <!--                          role="button"-->
          <!--                          @click.prevent.stop="hideAddHeaderFieldsButtonHandler"-->
          <!--                        >-->
          <!--                          {{ $t('portal.gatewaySettings.cancel') }}-->
          <!--                        </a>-->

          <!--                        <a class="small m-0 p-0" href="" role="button" @click.prevent.stop="addHeaderButtonHandler">-->
          <!--                          {{ $t('portal.gatewaySettings.add') }}-->
          <!--                        </a>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </transition>-->
          <!--          <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--            <div class="row" v-if="showParamsBlock">-->
          <!--              <div class="col-12 form-group">-->
          <!--                <label>{{ $t('portal.gatewaySettings.params') }}</label>-->
          <!--                <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--                  <a-->
          <!--                    v-if="!isShowAddParamsFields"-->
          <!--                    class="ml-1 small"-->
          <!--                    href=""-->
          <!--                    role="button"-->
          <!--                    @click.prevent.stop="showAddParamFields"-->
          <!--                  >-->
          <!--                    {{ $t('portal.gatewaySettings.add') }}-->
          <!--                  </a>-->
          <!--                </transition>-->
          <!--                <div class="gateway-settings-params-_wrapper">-->
          <!--                  &lt;!&ndash;        added Params fields&ndash;&gt;-->
          <!--                  <div v-if="paramsLength !== 0" class="gateway-settings-params-list__item m-0">-->
          <!--                    <div class="row h-100 align-items-center m-0">-->
          <!--                      <div-->
          <!--                        class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data m-0 pl-0"-->
          <!--                      >-->
          <!--                        {{ $t('portal.gatewaySettings.param') }}-->
          <!--                      </div>-->
          <!--                      <div-->
          <!--                        class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data m-0"-->
          <!--                      >-->
          <!--                        {{ $t('portal.gatewaySettings.value') }}-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div-->
          <!--                    class="gateway-settings-params-list__item"-->
          <!--                    :class="{ 'gateway-settings-params-list__item-when-inputs-show': editParamFieldKey === key }"-->
          <!--                    v-for="key of paramsOrderAsArray"-->
          <!--                    :key="key"-->
          <!--                  >-->
          <!--                    <div class="row h-100 align-items-center">-->
          <!--                      <div-->
          <!--                        v-if="editParamFieldKey !== key"-->
          <!--                        class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data"-->
          <!--                        v-tooltip.top-center="{-->
          <!--                          content: key,-->
          <!--                          popperOptions: {-->
          <!--                            modifiers: {-->
          <!--                              preventOverflow: {-->
          <!--                                boundariesElement: 'window'-->
          <!--                              }-->
          <!--                            }-->
          <!--                          }-->
          <!--                        }"-->
          <!--                      >-->
          <!--                        {{ key }}-->
          <!--                      </div>-->
          <!--                      <div v-else class="col-5 form-group gateway-settings-params-list-string__item">-->
          <!--                        <input-->
          <!--                          :data-vv-as="$t('portal.gatewaySettings.param')"-->
          <!--                          type="text"-->
          <!--                          :name="`${authNTypeName}-param-key-forEdit`"-->
          <!--                          :id="`${authNTypeName}-param-key-forEdit`"-->
          <!--                          :placeholder="$t('portal.gatewaySettings.param')"-->
          <!--                          class="form-control input gateway-settings&#45;&#45;params-list-string-input"-->
          <!--                          :aria-describedby="`${authNTypeName}-param-key-forEdit`"-->
          <!--                          v-validate="'required'"-->
          <!--                          :class="{ 'is-danger': errors.has(`${authNTypeName}-param-key-forEdit`) }"-->
          <!--                          v-model="editParamFieldNewKey"-->
          <!--                        />-->
          <!--                        &lt;!&ndash;                        <span v-show="errors.has(`${authNTypeName}-param-key-forEdit`)" class="help is-danger">&ndash;&gt;-->
          <!--                        &lt;!&ndash;                          {{ errors.first(`${authNTypeName}-param-key-forEdit`) }}&ndash;&gt;-->
          <!--                        &lt;!&ndash;                        </span>&ndash;&gt;-->
          <!--                      </div>-->
          <!--                      <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--                        &lt;!&ndash; added Param value &ndash;&gt;-->
          <!--                        <div-->
          <!--                          v-if="editParamFieldKey !== key"-->
          <!--                          class="col-5 form-group gateway-settings-params-list-string__item gateway-settings-params-list-string-field-for-show-data"-->
          <!--                          v-tooltip.top-center="{-->
          <!--                            content: portal_request.params[key],-->
          <!--                            popperOptions: {-->
          <!--                              modifiers: {-->
          <!--                                preventOverflow: {-->
          <!--                                  boundariesElement: 'window'-->
          <!--                                }-->
          <!--                              }-->
          <!--                            }-->
          <!--                          }"-->
          <!--                        >-->
          <!--                          {{ portal_request.params[key] }}-->
          <!--                        </div>-->
          <!--                        <div v-else class="col-5 form-group gateway-settings-params-list-string__item">-->
          <!--                          &lt;!&ndash; edit Param value input &ndash;&gt;-->
          <!--                          <input-->
          <!--                            :data-vv-as="$t('portal.gatewaySettings.value')"-->
          <!--                            type="text"-->
          <!--                            :name="`${authNTypeName}-param-value-forEdit`"-->
          <!--                            :id="`${authNTypeName}-param-value-forEdit`"-->
          <!--                            :placeholder="$t('portal.gatewaySettings.value')"-->
          <!--                            class="form-control input gateway-settings&#45;&#45;params-list-string-input"-->
          <!--                            :aria-describedby="`${authNTypeName}-param-value-forEdit`"-->
          <!--                            v-validate="'required'"-->
          <!--                            :class="{ 'is-danger': errors.has(`${authNTypeName}-param-value-forEdit`) }"-->
          <!--                            v-model="editParamFieldValue"-->
          <!--                          />-->
          <!--                          &lt;!&ndash;                          <span v-show="errors.has(`${authNTypeName}-param-value-forEdit`)" class="help is-danger">&ndash;&gt;-->
          <!--                          &lt;!&ndash;                            {{ errors.first(`${authNTypeName}-param-value-forEdit`) }}&ndash;&gt;-->
          <!--                          &lt;!&ndash;                          </span>&ndash;&gt;-->
          <!--                        </div>-->
          <!--                      </transition>-->

          <!--                      <div class="col-2 form-group d-flex flex-column justify-content-end align-items-end">-->
          <!--                        <transition name="portalProfileGatewaySettingsTransition" mode="out-in">-->
          <!--                          <div class="text-right d-flex flex-column align-items-end" v-if="editParamFieldKey !== key">-->
          <!--                            &lt;!&ndash; buttons if value showing &ndash;&gt;-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="editParamButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.edit') }}-->
          <!--                            </a>-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="delParamButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.delete') }}-->
          <!--                            </a>-->
          <!--                          </div>-->
          <!--                          <div class="text-right d-flex flex-column align-items-end" v-else>-->
          <!--                            &lt;!&ndash; buttons if value edit input showing &ndash;&gt;-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="cancelParamButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.cancel') }}-->
          <!--                            </a>-->
          <!--                            <a class="small" href="" role="button" @click.prevent.stop="saveParamButtonHandler(key)">-->
          <!--                              {{ $t('portal.gatewaySettings.save') }}-->
          <!--                            </a>-->
          <!--                          </div>-->
          <!--                        </transition>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    &lt;!&ndash;            <div><hr /></div>&ndash;&gt;-->
          <!--                  </div>-->

          <!--                  <div-->
          <!--                    v-if="paramsLength === 0 && !isShowAddParamsFields"-->
          <!--                    class="gateway-settings-params-list__item"-->
          <!--                    key="noParams"-->
          <!--                  >-->
          <!--                    <div class="row d-flex flex-column justify-content-center align-items-center">-->
          <!--                      <span class="text-muted">{{ $t('portal.gatewaySettings.noParams') }}</span>-->
          <!--                    </div>-->
          <!--                  </div>-->

          <!--                  <div-->
          <!--                    class="gateway-settings-params-list__item gateway-settings-params-list__item-when-inputs-show gateway-settings-params-list__item-when-fields-for-add-show"-->
          <!--                    v-if="isShowAddParamsFields"-->
          <!--                    key="addParam"-->
          <!--                  >-->
          <!--                    &lt;!&ndash;          add Params fields&ndash;&gt;-->
          <!--                    <div class="row align-items-center">-->
          <!--                      <div class="col-5 form-group">-->
          <!--                        <input-->
          <!--                          :data-vv-as="$t('portal.gatewaySettings.param')"-->
          <!--                          type="text"-->
          <!--                          :name="`${authNTypeName}-param-key`"-->
          <!--                          :id="`${authNTypeName}-param-key`"-->
          <!--                          :placeholder="$t('portal.gatewaySettings.param')"-->
          <!--                          class="form-control input gateway-settings&#45;&#45;params-list-string-input"-->
          <!--                          :aria-describedby="`${authNTypeName}-param-key`"-->
          <!--                          v-validate="'required'"-->
          <!--                          :class="{ 'is-danger': errors.has(`${authNTypeName}-param-key`) }"-->
          <!--                          v-model="addParamsFieldsKey"-->
          <!--                        />-->
          <!--                      </div>-->
          <!--                      <div class="col-5 form-group">-->
          <!--                        <input-->
          <!--                          :data-vv-as="$t('portal.gatewaySettings.value')"-->
          <!--                          type="text"-->
          <!--                          :name="`${authNTypeName}-param-value`"-->
          <!--                          :id="`${authNTypeName}-param-value`"-->
          <!--                          :placeholder="$t('portal.gatewaySettings.value')"-->
          <!--                          class="form-control input gateway-settings&#45;&#45;params-list-string-input gateway-settings&#45;&#45;params-list-string-input-for-add-second-column"-->
          <!--                          :aria-describedby="`${authNTypeName}-param-value`"-->
          <!--                          v-validate="'required'"-->
          <!--                          :class="{ 'is-danger': errors.has(`${authNTypeName}-param-value`) }"-->
          <!--                          v-model="addParamsFieldsValue"-->
          <!--                        />-->
          <!--                      </div>-->
          <!--                      <div class="col-2 form-group d-flex flex-column justify-content-between align-items-end">-->
          <!--                        <a-->
          <!--                          class="small m-0 p-0"-->
          <!--                          href=""-->
          <!--                          role="button"-->
          <!--                          @click.prevent.stop="hideAddParamFieldsButtonHandler"-->
          <!--                        >-->
          <!--                          {{ $t('portal.gatewaySettings.cancel') }}-->
          <!--                        </a>-->

          <!--                        <a class="small m-0 p-0" href="" role="button" @click.prevent.stop="addParamButtonHandler">-->
          <!--                          {{ $t('portal.gatewaySettings.add') }}-->
          <!--                        </a>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </transition>-->
<!--          <div class="mt-2">-->
<!--            <pre>{{ portalRequest }}</pre>-->
<!--          </div>-->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Universal/alert/alert.vue';

const METHOD_TYPES = { GET: 'GET', POST: 'POST' };
const CONTENT_TYPES = {
  'application/json': 'application/json',
  'application/xml': 'application/xml',
  'text/xml': 'text/xml'
};

export default {
  name: 'PortalProfileGatewaySettings',
  components: {
    Alert
  },
  data() {
    return {
      isShowAllGatewaySettingsBlock: false,
      isShowAddHeadersFields: false,
      addHeadersFieldsKey: '',
      addHeadersFieldsValue: '',
      headersOrderAsArray: [], // используется для сохранения порядка отображения в списке в случае если ключ меняют
      editHeaderFieldKey: '',
      editHeaderFieldNewKey: '',
      editHeaderFieldValue: '',
      isShowAddParamsFields: false,
      addParamsFieldsKey: '',
      addParamsFieldsValue: '',
      paramsOrderAsArray: [], // используется для сохранения порядка отображения в списке в случае если ключ меняют
      editParamFieldKey: '',
      editParamFieldNewKey: '',
      editParamFieldValue: '',
      payloadAsJson: '',
      payloadAsXML: ''
    };
  },
  props: {
    portal_request: {
      // cюда передается объект с настройками gateway
      type: Object,
      required: true
    },
    authNTypeName: {
      // сюда передается название типа аутентификации для которой показывается данный компонент
      // нужно для присваивания id'шкам полей ввода уникальных значений
      // также для emit'a событий об ошибках валидации, тоже для идентификацйии компонентов
      // так как их может быть несколько на странице
      type: String,
      required: true
    }
  },
  computed: {
    isRussianLocale() {
      if (localStorage.getItem('NMS_LOCALE') === 'ru' || localStorage.getItem('NMS_LOCALE') === 'RU') {
        return true;
      }
      return false;
    },
    portalRequest() {
      return this.portal_request;
    },
    headersLength() {
      if (
        Object.prototype.hasOwnProperty.call(this.portal_request, 'headers') &&
        typeof this.portal_request.headers === 'object' &&
        this.portal_request.headers !== null &&
        Object.keys(this.portal_request.headers).length
      ) {
        return Object.keys(this.portal_request.headers).length;
      }
      return 0;
    },
    paramsLength() {
      if (
        Object.prototype.hasOwnProperty.call(this.portal_request, 'params') &&
        typeof this.portal_request.params === 'object' &&
        this.portal_request.params !== null &&
        Object.keys(this.portal_request.params).length
      ) {
        return Object.keys(this.portal_request.params).length;
      }
      return 0;
    },
    methodTypes() {
      return METHOD_TYPES;
    },
    contentTypes() {
      return CONTENT_TYPES;
    },
    methodTypesAsArray() {
      return Object.keys(this.methodTypes);
    },
    contentTypesAsArray() {
      return Object.keys(this.contentTypes);
    },
    isGETMethodSelected() {
      return this.portal_request.method === this.methodTypes.GET;
    },
    isPOSTMethodSelected() {
      return this.portal_request.method === this.methodTypes.POST;
    },
    showContentTypeBlock() {
      return this.isPOSTMethodSelected;
    },
    showPayloadTextarea() {
      return this.isPOSTMethodSelected;
    },
    showPayloadAsJsonTextarea() {
      return this.isPOSTMethodSelected && this.portal_request.content_type === this.contentTypes['application/json'];
    },
    showPayloadAsXMLTextarea() {
      return (
        this.isPOSTMethodSelected &&
        (this.portal_request.content_type === this.contentTypes['application/xml'] ||
          this.portal_request.content_type === this.contentTypes['text/xml'])
      );
    },
    showHeadersBlock() {
      return this.isPOSTMethodSelected;
    },
    showParamsBlock() {
      return this.isPOSTMethodSelected;
    },
    showParamsHintForGETBlock() {
      return this.isGETMethodSelected;
    },
    showParamsHintForPOSTBlock() {
      return this.isPOSTMethodSelected;
    },
    showUrlBlock() {
      return this.isPOSTMethodSelected || this.isGETMethodSelected;
    }
  },
  methods: {
    isValidStringFotJSONParse(stringForCheck) {
      try {
        const json = JSON.parse(stringForCheck);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    payloadAsJsonChangeHandler() {
      try {
        this.portal_request.payload = JSON.stringify(JSON.parse(this.payloadAsJson.replace(/\n/g, '')));
      } catch (e) {
        console.log(e);
        this.portal_request.payload = this.payloadAsJson;
      }
    },
    payloadAsXMLChangeHandler() {
      try {
        this.portal_request.payload = this.payloadAsXML.replace(/\n/g, '');
        // console.log(this.portal_request.payload.replace(/\\"/g, '"'))
      } catch (e) {
        console.log(e);
        this.portal_request.payload = this.payloadAsXML;
      }
    },
    // parseTextToJSON(text) {
    //   try {
    //     return JSON.parse(text);
    //   } catch (e) {
    //     console.log(e);
    //     return text;
    //   }
    // },
    stringifyJSONForTextArea(jsonForStringify) {
      try {
        return JSON.stringify(jsonForStringify, undefined, 4);
      } catch (e) {
        console.log(e);
        return jsonForStringify;
      }
    },
    // stringifyXMLForTextArea(jsonForStringify) {
    //   try {
    //     const xmlStr = jsonForStringify;
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(xmlStr, 'text/xml');
    //     console.log(doc);
    //     const errorNode = doc.querySelector('parsererror');
    //     if (errorNode) {
    //       console.log('error while parsing');
    //       console.log(errorNode);
    //     } else {
    //       console.log(doc.documentElement.nodeName);
    //     }
    //     const XMLS = new XMLSerializer();
    //     return XMLS.serializeToString(doc);
    //   } catch (e) {
    //     console.log(e);
    //     return jsonForStringify;
    //   }
    // },
    mouseLeaveGetawaySettingsHandler() {
      this.validateAll();
    },
    validateAll() {
      // console.log('validating');
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.$emit('hasNoErrors', this.authNTypeName);
          } else {
            this.$emit('hasErrors', this.authNTypeName);
          }
        },
        () => {
          this.$emit('hasNoErrors', this.authNTypeName);
          console.warn('Validation failed');
        }
      );
    },
    toggleShowAllGatewaySettingsBlock() {
      this.fillHeadersOrderAsArray();
      this.fillParamsOrderAsArray();
      this.validateAll();
      if (this.isShowAllGatewaySettingsBlock === true) {
        this.isShowAllGatewaySettingsBlock = false;
      } else {
        this.isShowAllGatewaySettingsBlock = true;
      }
    },
    showAddHeaderFields() {
      if (!this.portal_request.headers) {
        this.portal_request.headers = {};
      }
      this.isShowAddHeadersFields = true;
    },
    hideAddHeaderFields() {
      this.isShowAddHeadersFields = false;
    },
    addHeaderButtonHandler() {
      if (this.addHeadersFieldsKey && this.addHeadersFieldsValue) {
        this.$set(this.portal_request.headers, this.addHeadersFieldsKey, this.addHeadersFieldsValue);
        this.headersOrderAsArray.push(this.addHeadersFieldsKey);
        this.clearHeaderInAddHeaderFields();
        this.hideAddHeaderFields();
      }
    },
    hideAddHeaderFieldsButtonHandler() {
      this.clearHeaderInAddHeaderFields();
      this.hideAddHeaderFields();
    },
    delHeaderButtonHandler(header) {
      // console.log(header)
      this.$delete(this.portal_request.headers, header);
      const indexOfkey = this.headersOrderAsArray.findIndex((x) => x === header);
      this.$delete(this.headersOrderAsArray, indexOfkey);
    },
    editHeaderButtonHandler(headerKey) {
      this.editHeaderFieldKey = headerKey;
      this.editHeaderFieldNewKey = headerKey;
      this.editHeaderFieldValue = this.portal_request.headers[headerKey];
    },
    cancelHeaderButtonHandler(headerKey) {
      this.clearHeaderInEditHeaderFields();
    },
    saveHeaderButtonHandler(headerKey) {
      if (this.editHeaderFieldNewKey && this.editHeaderFieldValue) {
        // this.portal_request.headers[headerKey] = this.editHeaderFieldValue;
        this.$delete(this.portal_request.headers, headerKey);
        this.$set(this.portal_request.headers, this.editHeaderFieldNewKey, this.editHeaderFieldValue);
        const indexOfOldkey = this.headersOrderAsArray.findIndex((x) => x === headerKey);
        const newHeadersOrderAsArray = this.headersOrderAsArray;
        newHeadersOrderAsArray[indexOfOldkey] = this.editHeaderFieldNewKey;
        this.headersOrderAsArray = newHeadersOrderAsArray;
        this.clearHeaderInEditHeaderFields();
      }
    },
    clearHeaderInAddHeaderFields() {
      this.addHeadersFieldsKey = '';
      this.addHeadersFieldsValue = '';
    },
    clearHeaderInEditHeaderFields() {
      this.editHeaderFieldKey = '';
      this.editHeaderFieldNewKey = '';
      this.editHeaderFieldValue = '';
    },
    clearPayload() {
      this.portal_request.payload = '';
    },
    showAddParamFields() {
      if (!this.portal_request.params) {
        this.portal_request.params = {};
      }
      this.isShowAddParamsFields = true;
    },
    hideAddParamFields() {
      this.isShowAddParamsFields = false;
    },
    addParamButtonHandler() {
      if (this.addParamsFieldsKey && this.addParamsFieldsValue) {
        this.$set(this.portal_request.params, this.addParamsFieldsKey, this.addParamsFieldsValue);
        this.paramsOrderAsArray.push(this.addParamsFieldsKey);
        this.clearParamInAddParamFields();
        this.hideAddParamFields();
      }
    },
    hideAddParamFieldsButtonHandler() {
      this.clearParamInAddParamFields();
      this.hideAddParamFields();
    },
    delParamButtonHandler(param) {
      this.$delete(this.portal_request.params, param);
      const indexOfkey = this.paramsOrderAsArray.findIndex((x) => x === param);
      this.$delete(this.paramsOrderAsArray, indexOfkey);
    },
    editParamButtonHandler(paramKey) {
      this.editParamFieldKey = paramKey;
      this.editParamFieldNewKey = paramKey;
      this.editParamFieldValue = this.portal_request.params[paramKey];
    },
    cancelParamButtonHandler(paramKey) {
      this.clearParamInEditParamFields();
    },
    saveParamButtonHandler(paramKey) {
      if (this.editParamFieldValue && this.editParamFieldKey) {
        // this.portal_request.params[paramKey] = this.editParamFieldValue;
        this.$delete(this.portal_request.params, paramKey);
        this.$set(this.portal_request.params, this.editParamFieldNewKey, this.editParamFieldValue);
        const indexOfOldkey = this.paramsOrderAsArray.findIndex((x) => x === paramKey);
        const newParamsOrderAsArray = this.paramsOrderAsArray;
        newParamsOrderAsArray[indexOfOldkey] = this.editParamFieldNewKey;
        this.paramsOrderAsArray = newParamsOrderAsArray;
        this.clearParamInEditParamFields();
      }
    },
    clearHeadersOrderAsArray() {
      this.headersOrderAsArray = [];
    },
    clearParamsOrderAsArray() {
      this.paramsOrderAsArray = [];
    },
    clearParamInAddParamFields() {
      this.addParamsFieldsKey = '';
      this.addParamsFieldsValue = '';
    },
    clearParamInEditParamFields() {
      this.editParamFieldKey = '';
      this.editParamFieldNewKey = '';
      this.editParamFieldValue = '';
    },
    // clearSettingsButtonHandler() {
    //   this.clearHeadersOrderAsArray();
    //   this.clearParamsOrderAsArray();
    //   this.clearHeaderInAddHeaderFields();
    //   this.clearHeaderInEditHeaderFields();
    //   this.hideAddHeaderFields();
    //   this.clearParamInAddParamFields();
    //   this.clearParamInEditParamFields();
    //   this.hideAddParamFields();
    //   const blankSettingsObject = {
    //     method: '',
    //     url: '',
    //     content_type: '',
    //     headers: null,
    //     params: null
    //   };
    //   Object.assign(this.portal_request, blankSettingsObject);
    // },
    clearSettingsButtonHandler() {
      this.portal_request.method = '';
      this.portal_request.url = '';
      this.portal_request.content_type = '';
      this.portal_request.payload = '';
    },
    contentTypeChangeHandler(e) {
      // this.clearPayload();
      // this.payloadAsJson = this.portal_request.payload;
      // this.payloadAsXML = this.portal_request.payload;

      const selectedContentType = e.target.value;
      this.portal_request.content_type = selectedContentType;
      this.fillPayloadViewsFields();
    },
    // methodChangeHandler(e) {
    //   const selectedMethod = e.target.value;
    //   this.clearPayload();
    //   this.clearHeadersOrderAsArray();
    //   this.clearParamsOrderAsArray();
    //   this.clearHeaderInAddHeaderFields();
    //   this.clearHeaderInEditHeaderFields();
    //   this.hideAddHeaderFields();
    //   this.clearParamInAddParamFields();
    //   this.clearParamInEditParamFields();
    //   this.hideAddParamFields();
    //   // console.log(selectedMethod);
    //   if (selectedMethod === this.methodTypes.POST) {
    //     const blankPOSTObject = {
    //       method: 'POST',
    //       url: '',
    //       content_type: 'application/json',
    //       payload: ''
    //       // headers: null,
    //       // params: null
    //     };
    //     Object.assign(this.portal_request, blankPOSTObject);
    //   } else if (selectedMethod === this.methodTypes.GET) {
    //     const blankGETObject = {
    //       method: 'GET',
    //       url: '',
    //       content_type: '',
    //       payload: ''
    //       // headers: null,
    //       // params: null
    //     };
    //     Object.assign(this.portal_request, blankGETObject);
    //   } else if (selectedMethod === '') {
    //     const blankSettingsObject = {
    //       method: '',
    //       url: '',
    //       content_type: '',
    //       payload: ''
    //       // headers: null,
    //       // params: null
    //     };
    //     Object.assign(this.portal_request, blankSettingsObject);
    //   }
    // },
    methodChangeHandler(e) {
      const selectedMethod = e.target.value;
      this.clearPayload();
      this.fillPayloadViewsFields();
      // this.clearHeadersOrderAsArray();
      // this.clearParamsOrderAsArray();
      // this.clearHeaderInAddHeaderFields();
      // this.clearHeaderInEditHeaderFields();
      // this.hideAddHeaderFields();
      // this.clearParamInAddParamFields();
      // this.clearParamInEditParamFields();
      // this.hideAddParamFields();
      // console.log(selectedMethod);
      if (selectedMethod === this.methodTypes.POST) {
        this.portal_request.method = this.methodTypes.POST;
        this.portal_request.content_type = 'application/json';
      } else if (selectedMethod === this.methodTypes.GET) {
        this.portal_request.method = this.methodTypes.GET;
        this.portal_request.content_type = '';
      } else if (selectedMethod === '') {
        this.portal_request.method = '';
        this.portal_request.content_type = '';
        this.portal_request.url = '';
      }
    },
    fillHeadersOrderAsArray() {
      if (
        Object.prototype.hasOwnProperty.call(this.portal_request, 'headers') &&
        typeof this.portal_request.headers === 'object' &&
        this.portal_request.headers !== null
      ) {
        this.headersOrderAsArray = Object.keys(this.portal_request.headers);
      } else {
        this.headersOrderAsArray = [];
      }
    },
    fillParamsOrderAsArray() {
      if (
        Object.prototype.hasOwnProperty.call(this.portal_request, 'params') &&
        typeof this.portal_request.params === 'object' &&
        this.portal_request.params !== null
      ) {
        this.paramsOrderAsArray = Object.keys(this.portal_request.params);
      } else {
        this.paramsOrderAsArray = [];
      }
    },
    fillPayloadViewsFields() {
      if (this.portal_request.method === this.methodTypes.POST) {
        if (this.portal_request.content_type === this.contentTypes['application/json']) {
          // this.payloadAsJson = this.portal_request.payload;
          if (this.isValidStringFotJSONParse(this.portal_request.payload)) {
            this.payloadAsJson = this.stringifyJSONForTextArea(JSON.parse(this.portal_request.payload));
          } else {
            this.payloadAsJson = this.portal_request.payload;
          }
        } else if (
          this.portal_request.content_type === this.contentTypes['application/xml'] ||
          this.portal_request.content_type === this.contentTypes['text/xml']
        ) {
          this.payloadAsXML = this.portal_request.payload;
        }
      }
    }
  },

  created() {
    // console.log('created ', this.authNTypeName)
    // this.fillHeadersOrderAsArray();
    // this.fillParamsOrderAsArray();
    this.fillPayloadViewsFields();
  }
};
</script>

<style scoped>
.gateway-settings__hint-msg {
  font-size: 0.8em;
}
.gateway-settings__wrapper {
  width: 100%;
}
.gateway-settings-params-_wrapper {
  padding: 1em;
  border: 0.5px dotted rgba(175, 175, 175, 0.5);
  border-radius: 10px;
}
.gateway-settings-params-list-string__item {
  overflow: hidden;
  text-overflow: ellipsis;
}
.gateway-settings-params-list__item {
  padding: 0.25em;
  padding-left: 1em;
  height: 2.6em;
  margin-bottom: 0.5em;
}
.gateway-settings-params-list__item-when-inputs-show {
  /*height: 3em;*/
}
.gateway-settings-params-list__item-when-fields-for-add-show {
  /*padding-left: 0.25em;*/
}
.gateway-settings-params-_wrapper .gateway-settings-params-list__item:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.025);
}
.gateway-settings-hideable-block {
  padding-left: 1.5em;
}
.gateway-settings--params-list-string-input {
  padding: 0.2em;
}
.gateway-settings--params-list-string-input-for-add-second-column {
  /*margin-left: 0.45em;*/
}
.gateway-settings-params-list-string-field-for-show-data {
  line-height: 1em;
}
</style>
<style>
.portalProfileGatewaySettingsTransition-enter-active,
.portalProfileGatewaySettingsTransition-leave-active {
  transition: opacity 0.3s;
}

.portalProfileGatewaySettingsTransition-enter,
.portalProfileGatewaySettingsTransition-leave-to {
  opacity: 0;
}
</style>
