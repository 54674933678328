import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';

export default {
  // getPortalProfiles(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingPortalProfiles');
  //   const config = props.query ?
  //     {
  //       params: {
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/profile`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalProfilesList', response.data.data);
  //         ctx.$store.commit('pushPortalProfilesList', response.data.data);
  //         this.getProfilesLocations(ctx, ctx.$store.state.portalProfilesList);
  //         ctx.totalResultPortalProfiles = response.data.total;
  //         if (ctx.$store.state.portalProfilesList.length >= response.data.total) {
  //           ctx.canLoadMorePortalProfiles = false;
  //         } else {
  //           ctx.canLoadMorePortalProfiles = true;
  //         }
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalProfiles');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalProfiles');
  //     }
  //   );
  // },

  getPortalProfiles(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalProfiles');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
                  location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {        params: {
                  location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    // console.log('ctx.filters.location', ctx.filters.location)
    if (props.query && props.useLocationFilter) {
      if (ctx.filters?.location &&
        typeof ctx.filters.location === 'object' &&
        ctx.filters.location.data  &&
        typeof ctx.filters.location.data === 'object' ) {
        if (Object.keys(ctx.filters.location.data).length === 0) {
          // console.log('is zero ')
          config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
          config.params.with_childs = true;
        } else {
          config.params.location = ctx.filters.location.data.id;
          config.params.with_childs = ctx.filters.location.with_childs;
        }
      }
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/profile`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalProfilesList', response.data.data);
          ctx.$store.commit('pushPortalProfilesList', response.data.data);
          this.getProfilesLocations(ctx, ctx.$store.state.portalProfilesList);
          ctx.totalResultPortalProfiles = response.data.total;
          if (ctx.$store.state.portalProfilesList.length >= response.data.total) {
            ctx.canLoadMorePortalProfiles = false;
          } else {
            ctx.canLoadMorePortalProfiles = true;
          }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalProfiles');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalProfiles');
      }
    );
  },

  async getPortalProfilesFromTariffs(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalProfiles');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
                  location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {params: {
               location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    await Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/profile`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalProfilesList', response.data.data);
          ctx.$store.commit('pushPortalProfilesList', response.data.data);
          // this.getProfilesLocations(ctx, ctx.$store.state.portalProfilesList);
          ctx.totalResultPortalProfiles = response.data.total;
          if (ctx.$store.state.portalProfilesList.length >= response.data.total) {
            ctx.canLoadMorePortalProfiles = false;
          } else {
            ctx.canLoadMorePortalProfiles = true;
          }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalProfiles');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalProfiles');
      }
    );
  },

  getPortalProfilesFromPaymentSystems(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalProfiles');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
                  location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {        params: {
                  location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    // console.log('ctx.filters.location', ctx.filters.location)
    if (props.query && props.useLocationFilter) {
      if (ctx.filters?.location &&
        typeof ctx.filters.location === 'object' &&
        ctx.filters.location.data  &&
        typeof ctx.filters.location.data === 'object' ) {
        if (Object.keys(ctx.filters.location.data).length === 0) {
          // console.log('is zero ')
          config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
          config.params.with_childs = true;
        } else {
          config.params.location = ctx.filters.location.data.id;
          config.params.with_childs = ctx.filters.location.with_childs;
        }
      }
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/profile`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalProfilesList', response.data.data);
          ctx.$store.commit('pushPortalProfilesList', response.data.data);
          // this.getProfilesLocations(ctx, ctx.$store.state.portalProfilesList);
          ctx.totalResultPortalProfiles = response.data.total;
          if (ctx.$store.state.portalProfilesList.length >= response.data.total) {
            ctx.canLoadMorePortalProfiles = false;
          } else {
            ctx.canLoadMorePortalProfiles = true;
          }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalProfiles');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalProfiles');
      }
    );
  },

  getAuthentications(ctx) {
    const params = {
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    ctx.$store.commit('loadingAuthenticationsRequestBegin');
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/authentication`, {params}).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          ctx.$store.commit('setPortalAuthentications', response.data.data);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingAuthenticationsRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('loadingAuthenticationsRequestEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  getAuthorizations(ctx) {
        const params = {
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    ctx.$store.commit('loadingAuthorizationsRequestBegin');
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/authorization`, {params}).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          ctx.$store.commit('setPortalAuthorizations', response.data.data);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingAuthorizationsRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        ctx.$store.commit('loadingAuthorizationsRequestEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  createProfile(ctx, profile) {
    profile.condition.wlan = ctx.conditionWlans.map((wlan) => wlan.id);
    profile.condition.cpe = ctx.conditionCpes.map((cpe) => cpe.id);
    profile.condition.loc_id = ctx.conditionLocId.map((location) => location.loc_id);

    profile.authentications = helpers.transformObjectToArrayByKey(ctx.currentAuthentications, 'type');
    profile.authorizations = helpers.transformObjectToArrayByKey(ctx.currentAuthorizations, 'type');
    const { base_location } = profile;

    ctx.$store.commit('toggleMutePortalProfileEdit');
    Vue.axios
      .post(`${RESTAPI || ''}/api/portal/admin/profile`, {
        request: [profile],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('portalProfiles.notificationProfileCreatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalProfileEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalProfileEdit');
        }
      );
  },
  createProfileFromUsersDemoWizard(ctx, profile) {
    // profile.condition.wlan = ctx.conditionWlans.map((wlan) => wlan.id);
    // profile.condition.cpe = ctx.conditionCpes.map((cpe) => cpe.id);
    // profile.condition.loc_id = ctx.conditionLocId.map((location) => location.loc_id);

    profile.authentications = helpers.transformObjectToArrayByKey(ctx.currentAuthentications, 'type');
    profile.authorizations = helpers.transformObjectToArrayByKey(ctx.currentAuthorizations, 'type');
    const { base_location } = profile;
    Vue.axios
      .post(`${RESTAPI || ''}/api/portal/admin/profile`, {
        request: [profile],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('portalProfiles.notificationProfileCreatedSuccessfully') });
            // console.log(response.data.data)
            ctx.$store.commit('setUsersDemoWizardStep4SavedProfileObject', response.data.data[0]);
            ctx.nextStep();
          } else {
            VueNotifications.error({ message: response.data.description });
            // ctx.exitFromWizard();
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          // ctx.exitFromWizard();
        }
      );
  },
  createProfileFromEasyInstallWizard(ctx, profile) {
    // profile.condition.wlan = ctx.conditionWlans.map((wlan) => wlan.id);
    // profile.condition.cpe = ctx.conditionCpes.map((cpe) => cpe.id);
    // profile.condition.loc_id = ctx.conditionLocId.map((location) => location.loc_id);

    profile.authentications = helpers.transformObjectToArrayByKey(ctx.currentAuthentications, 'type');
    profile.authorizations = helpers.transformObjectToArrayByKey(ctx.currentAuthorizations, 'type');
    const { base_location } = profile;
    // console.log(profile)
    // console.log(base_location)
    Vue.axios
      .post(`${RESTAPI || ''}/api/portal/admin/profile`, {
        request: [profile],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('portalProfiles.notificationProfileCreatedSuccessfully') });
            // console.log(response.data.data)
            // чистим данные для визарда в store, чтобы понять что юзер закончил визард
            ctx.nextStep();
          } else {
            VueNotifications.error({ message: response.data.description });
            // ctx.exitFromWizard();
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          // ctx.exitFromWizard();
        }
      );
  },
  updateProfile(ctx, profile) {
    profile.condition.wlan = ctx.conditionWlans.map((wlan) => wlan.id);

    profile.condition.cpe = ctx.conditionCpes.map((cpe) => cpe.id);

    profile.condition.loc_id = ctx.conditionLocId.map((location) => location.loc_id);

    profile.authentications = helpers.transformObjectToArrayByKey(ctx.currentAuthentications, 'type');
    profile.authorizations = helpers.transformObjectToArrayByKey(ctx.currentAuthorizations, 'type');
    const { base_location } = profile;
    delete profile.base_location;

    ctx.$store.commit('toggleMutePortalProfileEdit');
    Vue.axios
      .put(`${RESTAPI || ''}/api/portal/admin/profile`, {
        request: [profile],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('portalProfiles.notificationProfileUpdatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalProfileEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalProfileEdit');
        }
      );
  },
  deleteProfile(ctx, profile) {
    ctx.deletingProfileModal = false;
    ctx.$store.commit('toggleMutePortalProfileEdit');
    Vue.axios
      .delete(`${RESTAPI || ''}/api/portal/admin/profile`, {
        params: {
          ids: [profile.id]
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('portalProfiles.notificationProfileDeletedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalProfileEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalProfileEdit');
        }
      );
  },
  getProfilesLocations(ctx, profiles) {
    // Запихиват в store объект, где ключи - id профилей, а значения - id локаций
    ctx.$store.commit('toggleLoadingPortalProfilesLocations');
    const profilesIdsArray = [];
    for (const profile of profiles) {
      profilesIdsArray.push(profile.id);
    }
    Vue.axios
      .post(`${RESTAPI || ''}/api/base_locations`, {
        action: 'R',
        ids: profilesIdsArray
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            const locationFromApi = response.data.data.itemslist;
            let loctationsToStore = {};
            if (response.data.data.itemslist && response.data.data.itemslist.length > 0) {
              for (const location of locationFromApi) {
                if (location.Obj.model === 'portal_profile') {
                  loctationsToStore[location.Obj.model_id] = location.location_id;
                }
              }
            } else {
              loctationsToStore = false;
            }
            // for (const location of locationFromApi){
            //   if (location.Obj.model === 'portal_profile'){
            //     loctationsToStore[location.Obj.model_id] = location.location_id;
            //   }
            // };
            ctx.$store.commit('setPortalProfilesLocationsList', loctationsToStore);
            ctx.$store.commit('toggleLoadingPortalProfilesLocations');
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('toggleLoadingPortalProfilesLocations');
          }
        },
        (err) => {
          ctx.$store.commit('toggleLoadingPortalProfilesLocations');
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },
  async requestProfiles(searchQuery) {
    const config = {
      params: {
        search: searchQuery,
        limit: 20,
        sort: 'name',
        with_childs: true,
      }
    };
    const json = await Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/profile`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },
  async requestProfilesFilteredByLocationAndWithChilds(ctx, searchQuery) {
    const config = {
      params: {
        search: searchQuery,
        limit: 20,
        sort: 'name',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
      }
    };
    const json = await Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/profile`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  }
};
