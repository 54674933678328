import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  getRange(ctx, object, adId) {
    ctx.$store.commit('enableLoadingSummary');

    const params = {
      start: moment(ctx.drPickerDate.startDate).unix(),
      stop: moment(ctx.drPickerDate.endDate).unix(),
      accounts: [],
      profiles:
        ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
          ctx.selectedProfileInMultiselectForRequest :
          [],
      // location_id: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      location: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      with_childs: !(ctx.filters && ctx.filters.hasOwnProperty('with_childs') && ctx.filters.with_childs === false),
      object
    };
    Vue.axios.get(`${window.RESTAPI || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log('from server: ');
          // console.log(response.data.data)
          if (response.data.data === null) {
            ctx.isNoData = true;
          }
          // console.log( 'adId ' + adId);
          let rawResponseData;
          // если не передан параметр adId то ищем статистику по всем рекламам,
          // если передан то сначала ищем объекты с конкретной рекламой
          if (adId === undefined || adId === '') {
            rawResponseData = response.data.data;
          } else if (response.data.data === null || response.data.data === undefined) {
            rawResponseData = null;
          } else {
            rawResponseData = response.data.data.filter((dataItem) => dataItem.id_ad === adId);
          }
          // console.log('raw response data ' );
          // console.log(rawResponseData)
          if (rawResponseData === undefined) {
            rawResponseData = null;
          }
          const resultCounterStatsData = this.extractDaysAdStatCounter(rawResponseData);
          // console.log('counter : ');
          // console.log(resultCounterStatsData)
          if (resultCounterStatsData !== null) {
            // наполняем основной график с общей статистикой просмотров
            const allViews = {
              name: ctx.$t('adsprofile.adsStatsAllAuthorizationTypesShort'),
              data: ctx.filters.ads.length ? [] : resultCounterStatsData.allforChart,
              color: '#5c7df8',
              stack: 'all'
            };
            const allClicks = {
              name: ctx.$t('adsprofile.adsStatsAllFollowsAuthorizationTypesShort'),
              data: ctx.filters.ads.length ? [] : resultCounterStatsData.allFollowsforChart,
              color: '#F91155',
              stack: 'all'
            };
            if (!ctx.filters.ads.length) {
              ctx.allAdsViewChartSeries = [allViews, allClicks];
            }
            // const seriesForAllAuthChart = ctx.allAdsViewChartSeries;
            // seriesForAllAuthChart[0].data = resultCounterStatsData.allforChart;
            // seriesForAllAuthChart[1].data = resultCounterStatsData.allFollowsforChart;
            // console.info(ctx.allAdsViewsChart.series);
            // наполняем график со статистикой просомтров в зависимости от auth type
            //
            // const seriesFreeAuthSeparateChart = ctx.separeteAuthTypeAdsViewsChart.series[0];
            // const seriesSponsorAuthSeparateChart = ctx.separeteAuthTypeAdsViewsChart.series[1];
            // const seriesVoucherAuthSeparateChart = ctx.separeteAuthTypeAdsViewsChart.series[2];
            // const seriesVkAuthSeparateChart = ctx.separeteAuthTypeAdsViewsChart.series[3];
            // const seriesFacebookAuthSeparateChart = ctx.separeteAuthTypeAdsViewsChart.series[4];
            // const seriesInstagramAuthSeparateChart = ctx.separeteAuthTypeAdsViewsChart.series[5];
            // const seriesStaffAuthSeparateChart = ctx.separeteAuthTypeAdsViewsChart.series[6];
            // seriesFreeAuthSeparateChart.data = resultCounterStatsData.freeforChart;
            // seriesSponsorAuthSeparateChart.data = resultCounterStatsData.sponsorforChart;
            // seriesVoucherAuthSeparateChart.data = resultCounterStatsData.voucherforChart;
            // seriesVkAuthSeparateChart.data = resultCounterStatsData.vkforChart;
            // seriesFacebookAuthSeparateChart.data = resultCounterStatsData.facebookforChart;
            // seriesInstagramAuthSeparateChart.data = resultCounterStatsData.instagramforChart;
            // seriesStaffAuthSeparateChart.data = resultCounterStatsData.staffforChart;
            //
            // наполняем график со статистикой просомтров в зависимости от auth type
            const seriesToAuthSeparateChart = [];
            const seriesResult = [
              {
                name: ctx.$t('adsprofile.adsStatsSepareteFree'),
                data: resultCounterStatsData.freeforChart
              },
              {
                name: ctx.$t('adsprofile.adsStatsSepareteSponsor'),
                data: resultCounterStatsData.sponsorforChart
              },
              {
                name: ctx.$t('adsprofile.adsStatsSepareteVoucher'),
                data: resultCounterStatsData.voucherforChart
              },
              {
                name: ctx.$t('adsprofile.adsStatsSepareteVk'),
                data: resultCounterStatsData.vkforChart
              },
              {
                name: ctx.$t('adsprofile.adsStatsSepareteFacebook'),
                data: resultCounterStatsData.facebookforChart
              },
              {
                name: ctx.$t('adsprofile.adsStatsSepareteInstagram'),
                data: resultCounterStatsData.instagramforChart
              },
              {
                name: ctx.$t('adsprofile.adsStatsSepareteStaff'),
                data: resultCounterStatsData.staffforChart
              }
            ];
            for (const series of seriesResult) {
              if (series.data.length > 0) {
                seriesToAuthSeparateChart.push(series);
              }
            }
            ctx.separeteAuthTypeAdsViewsChartSeries = seriesToAuthSeparateChart;
          }
          ctx.$store.commit('disableLoadingSummary');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('disableLoadingSummary');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('disableLoadingSummary');
      }
    );
  },
  // getRangeForMarketerDashBoard(ctx) {
  //   const adId = '';
  //   ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
  //   const start = moment().startOf('month').valueOf();
  //   const stop = moment().endOf('month').valueOf();
  //
  //   const params = {
  //     start: moment(start).unix(),
  //     stop: moment(stop).unix(),
  //     accounts: [],
  //     profiles: [],
  //     object: 'ad'
  //   };
  //   Vue.axios.get(`${window.RESTAPI || ''}/api/portal/admin/range`, { params }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         // console.log('from server: ');
  //         // console.log(response.data.data)
  //         if (response.data.data === null) {
  //           ctx.isNoData = true;
  //         }
  //         // console.log( 'adId ' + adId);
  //         let rawResponseData;
  //         // если не передан параметр adId то ищем статистику по всем рекламам,
  //         // если передан то сначала ищем объекты с конкретной рекламой
  //         if (adId === undefined || adId === '') {
  //           rawResponseData = response.data.data;
  //         } else if (response.data.data === null) {
  //           rawResponseData = null;
  //         } else {
  //           rawResponseData = response.data.data.filter((dataItem) => dataItem.id_ad === adId);
  //         }
  //         // console.log('raw response data ' );
  //         // console.log(rawResponseData)
  //         if (rawResponseData === undefined) {
  //           rawResponseData = null;
  //         }
  //         const resultCounterStatsData = this.extractDaysAdStatCounterForMarketerDashboard(rawResponseData);
  //         // console.log('counter : ');
  //         // console.log(resultCounterStatsData)
  //         if (resultCounterStatsData !== null) {
  //           // console.log(resultCounterStatsData);
  //           ctx.adsViewsDataForMarketerDasboard = resultCounterStatsData;
  //         }
  //         ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
  //     }
  //   );
  // },
  getRangeForMarketerDashBoard(ctx, props = {}) {
    const adId = '';
    ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
    const start = moment().startOf('month').valueOf();
    const stop = moment().endOf('month').valueOf();

    const params = {
      start: moment(start).unix(),
      stop: moment(stop).unix(),
      accounts: [],
      profiles: [],
      object: 'ad',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };
    if (props.useLocationFilter) {
      if (ctx.filters.location === 'All locations') {
        // params.location_id = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        // params.location_id = ctx.filters.location;
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      };
    }
    Vue.axios.get(`${window.RESTAPI || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log('from server: ');
          // console.log(response.data.data)
          if (response.data.data === null) {
            ctx.isNoData = true;
          }
          // console.log( 'adId ' + adId);
          let rawResponseData;
          // если не передан параметр adId то ищем статистику по всем рекламам,
          // если передан то сначала ищем объекты с конкретной рекламой
          if (adId === undefined || adId === '') {
            rawResponseData = response.data.data;
          } else if (response.data.data === null) {
            rawResponseData = null;
          } else {
            rawResponseData = response.data.data.filter((dataItem) => dataItem.id_ad === adId);
          }
          // console.log('raw response data ' );
          // console.log(rawResponseData)
          if (rawResponseData === undefined) {
            rawResponseData = null;
          }
          const resultCounterStatsData = this.extractDaysAdStatCounterForMarketerDashboard(rawResponseData);
          // console.log('counter : ');
          // console.log(resultCounterStatsData)
          if (resultCounterStatsData !== null) {
            // console.log(resultCounterStatsData);
            ctx.adsViewsDataForMarketerDasboard = resultCounterStatsData;
          }
          ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingAdsViewsDataForMarketerDashboard');
      }
    );
  },

  extractDaysAdStatCounter(raw) {
    if (raw === null) {
      return null;
    }
    const result = {};
    result.all = {};
    result.allFollows = {};
    result.free = {};
    result.sponsor = {};
    result.voucher = {};
    result.vk = {};
    result.facebook = {};
    result.instagram = {};
    result.staff = {};
    result.allforChart = [];
    result.allFollowsforChart = [];
    result.freeforChart = [];
    result.sponsorforChart = [];
    result.voucherforChart = [];
    result.vkforChart = [];
    result.facebookforChart = [];
    result.instagramforChart = [];
    result.staffforChart = [];
    for (const item of raw) {
      const ts = new Date(item.create_at * 1000);
      ts.setHours(0);
      ts.setMinutes(0);
      ts.setSeconds(0);
      const tsForKey = ts.valueOf();

      if (!result.all[tsForKey]) {
        result.all[tsForKey] = item.counter;
      } else {
        result.all[tsForKey] = result.all[tsForKey] + item.counter;
      }
      if (!result.allFollows[tsForKey]) {
        result.allFollows[tsForKey] = item.follows;
      } else {
        result.allFollows[tsForKey] = result.allFollows[tsForKey] + item.follows;
      }

      if (item.authorization === 'free') {
        if (!result.free[tsForKey]) {
          result.free[tsForKey] = item.counter;
        } else {
          result.free[tsForKey] = result.free[tsForKey] + item.counter;
        }
      }
      if (item.authorization === 'sponsor') {
        if (!result.sponsor[tsForKey]) {
          result.sponsor[tsForKey] = item.counter;
        } else {
          result.sponsor[tsForKey] = result.sponsor[tsForKey] + item.counter;
        }
      }
      if (item.authorization === 'voucher') {
        if (!result.voucher[tsForKey]) {
          result.voucher[tsForKey] = item.counter;
        } else {
          result.voucher[tsForKey] = result.voucher[tsForKey] + item.counter;
        }
      }
      if (item.authorization === 'vk') {
        if (!result.vk[tsForKey]) {
          result.vk[tsForKey] = item.counter;
        } else {
          result.vk[tsForKey] = result.vk[tsForKey] + item.counter;
        }
      }
      if (item.authorization === 'facebook') {
        if (!result.facebook[tsForKey]) {
          result.facebook[tsForKey] = item.counter;
        } else {
          result.facebook[tsForKey] = result.facebook[tsForKey] + item.counter;
        }
      }
      if (item.authorization === 'instagram') {
        if (!result.instagram[tsForKey]) {
          result.instagram[tsForKey] = item.counter;
        } else {
          result.instagram[tsForKey] = result.instagram[tsForKey] + item.counter;
        }
      }
      if (item.authorization === 'staff') {
        if (!result.staff[tsForKey]) {
          result.staff[tsForKey] = item.counter;
        } else {
          result.staff[tsForKey] = result.staff[tsForKey] + item.counter;
        }
      }
    }

    for (const entery of Object.entries(result.all)) {
      result.allforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.allFollows)) {
      result.allFollowsforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.free)) {
      result.freeforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.sponsor)) {
      result.sponsorforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.voucher)) {
      result.voucherforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.vk)) {
      result.vkforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.facebook)) {
      result.facebookforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.instagram)) {
      result.instagramforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    for (const entery of Object.entries(result.staff)) {
      result.staffforChart.push([parseInt(entery[0]), parseInt(entery[1])]);
    }
    return result;
  },
  extractDaysAdStatCounterForMarketerDashboard(raw) {
    if (raw === null) {
      return null;
    }
    const result = {};
    result.all = {};
    result.allforDashBoardStat = [];

    for (const item of raw) {
      const ts = new Date(item.create_at * 1000);
      ts.setHours(0);
      ts.setMinutes(0);
      ts.setSeconds(0);
      const tsForKey = ts.valueOf();

      if (!result.all[tsForKey]) {
        result.all[tsForKey] = item.counter;
      } else {
        result.all[tsForKey] = result.all[tsForKey] + item.counter;
      }
    }

    for (const entery of Object.entries(result.all)) {
      result.allforDashBoardStat.push([parseInt(entery[0]), parseInt(entery[1])]);
    }

    return result;
  },
  getAdCounts(ctx, adId) {
    ctx.$store.commit('enableLoadingSummary');
    const adsIds = ctx.filters.ads.map((ad) => ad.id);
    const params = {
      start: moment(ctx.drPickerDate.startDate).unix(),
      stop: moment(ctx.drPickerDate.endDate).unix(),
      accounts: [],
      profiles:
        ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
          ctx.selectedProfileInMultiselectForRequest :
          [],
      ids: adsIds.length ? adsIds : [],
      object: 'ad_count',
      // location_id: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      location: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      with_childs: !(ctx.filters && ctx.filters.hasOwnProperty('with_childs') && ctx.filters.with_childs === false)
    };
    // console.log(params);
    // if (params.ids.length === 0) {
    //   console.log('delete');
    //   delete params.ids;
    // }
    Vue.axios.get(`${window.RESTAPI || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log('from server: ');
          // console.log(response.data.data)
          if (true) {
            const views = response.data.data.views ?
              response.data.data.views.map((ad) => ({
                name: `${ctx.$t('adsprofile.adsStatsAllAuthorizationTypesShort')} (${ctx.getAdName(ad.id)})`,
                data: ad.data.map((coords) => [coords[0] * 1000, coords[1]]),
                stack: ad.id
              })) :
              [];
            const clicks = response.data.data.clicks ?
              response.data.data.clicks.map((ad) => ({
                name: `${ctx.$t('adsprofile.adsStatsAllFollowsAuthorizationTypesShort')} (${ctx.getAdName(ad.id)})`,
                data: ad.data.map((coords) => [coords[0] * 1000, coords[1]]),
                stack: ad.id
              })) :
              [];
            // console.log('views:', views);
            // console.log('clicks:', clicks);

            ctx.allAdsViewChartSeries = [...views, ...clicks];
          }
          ctx.$store.commit('disableLoadingSummary');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('disableLoadingSummary');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('disableLoadingSummary');
      }
    );
  }
};
