<template>
  <div>
    <div class="searching-block-in-support-wrapper">
      <div class="searching-block-in-support">
        <div class="input-group input-group-sm searching-block-input">
          <span class="input-group-addon">
            <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
            <i class="fa fa-search" v-else></i>
          </span>
          <input
            id="search"
            name="search"
            :placeholder="$t('aps.search')"
            @input="searchInput"
            class="form-control filters__input"
            v-model.trim="queriesForSearchInputedInSearchField"
          />
          <span class="clear-input text-muted">
            <i class="fa fa-remove" @click="searchClear"></i>
          </span>
        </div>
        <div class="pl-1 searching-block-in-support-item">
          <span v-if="!searchSpinner && queriesForSearchInputedInSearchField.length > 0">
            {{ $t('general.found') }}: {{ domHighlightedElementsFoundLength }}
          </span>
        </div>
        <div
          class="pl-1 searching-block-in-support-item"
          v-if="
            !searchSpinner && queriesForSearchInputedInSearchField.length > 0 && domHighlightedElementsFoundLength > 0
          "
        >
          <button class="btn btn-sm btn-outline-secondary mr-1" @click.stop="goToPrevFoundElement" role="button">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          <span>
            {{ currentDomHighlightedElementIndex + 1 }} {{ $t('general.of') }} {{ domHighlightedElementsFoundLength }}
          </span>
          <button class="btn btn-sm btn-outline-secondary ml-1" @click.stop="goToNextFoundElement" role="button">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="card">
        <h1 class="card-header mb-1">{{ $t('support.header') }}</h1>
        <div class="row" key="row">
          <folding-category
            class="folding-category-new-style-glossary"
            :open-when-seraching="showingAllFoldingCategoryOpened"
            tag="h2"
            :title="$t('support.glossary.header')"
            opened
          >
            <div class="row my-2 mx-h glossary-text" key="glossary">
              <div class="col-6" key="glossary-left">
                <ul>
                  <li v-for="(row, index) in glossaryLeft" :key="index" class="mt-q">
                    <text-highlight :queries="queriesForSearch">{{ row }}</text-highlight>
                  </li>
                </ul>
              </div>
              <div class="col-6" key="glossary-right">
                <ul>
                  <li v-for="(row, index) in glossaryRight" :key="index" class="mt-q">
                    <text-highlight :queries="queriesForSearch">{{ row }}</text-highlight>
                  </li>
                </ul>
              </div>
            </div>
          </folding-category>
        </div>
        <div class="row folding-category-new-style-other-categories-wrapper">
          <folding-category
            :open-when-seraching="showingAllFoldingCategoryOpened"
            v-if="
              $canSomeInGroup('read', [...RouteGroups.SYSTEM, ...RouteGroups.STATISTICS, ...RouteGroups.MONITORING]) &&
              !isPortalOnly
            "
            tag="h2"
            :title="$t('support.nms.nmsHeader')"
          >
            <!-- Dashboard -->
            <glossary-card
              :queries-for-search="queriesForSearch"
              :header-link="'Dashboard'"
              v-if="$can('read', { route: 'Dashboard' })"
              :header="$t('support.nms.dashboardHeader')"
              key="Dashboard"
              :rawText="$t('support.nms.dashboardMain')"
              :listedText="dashboard"
              :imgUrls="['./static/img/support/dashboard.png']"
              class="my-2"
            />
            <!-- NMS -->
            <glossary-card
              :queries-for-search="queriesForSearch"
              v-if="
                $canSomeInGroup('read', [...RouteGroups.SYSTEM, ...RouteGroups.STATISTICS, ...RouteGroups.MONITORING])
              "
              :header="$t('support.nms.nmsHeader')"
              key="NMS"
              :rawText="$t('support.nms.nmsMain')"
              :listedText="nms"
              :imgUrls="['./static/img/support/nms.png']"
              class="my-2"
            />
            <folding-category
              :open-when-seraching="showingAllFoldingCategoryOpened"
              v-if="$canSomeInGroup('read', RouteGroups.STATISTICS)"
              tag="h3"
              :title="$t('support.nms.statistics')"
              key="Statistics"
              second-level
            >
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Statsystem'"
                v-if="$can('read', { route: 'Statsystem' })"
                :header="$t('support.nms.locationsHeader')"
                key="System"
                :imgUrls="['./static/img/support/stat-system.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.nms.systemMain1') }}
                    <br />
                    {{ $t('support.nms.systemMain2') }}
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Statcpes'"
                v-if="$can('read', { route: 'Statcpes' })"
                :header="$t('support.nms.cpesHeader')"
                key="CPEs"
                :imgUrls="['./static/img/support/stat-cpes.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.nms.cpesMain1') }}
                    <br />
                    {{ $t('support.nms.cpesMain2') }}
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Statwlans'"
                v-if="$can('read', { route: 'Statwlans' })"
                :header="$t('support.nms.wlansHeader')"
                key="WLANs"
                :rawText="$t('support.nms.wlansMain')"
                :imgUrls="['./static/img/support/stat-wlans.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Statclients'"
                v-if="$can('read', { route: 'Statclients' })"
                :header="$t('support.nms.clientsHeader')"
                key="Clients"
                :rawText="$t('support.nms.clientsMain')"
                :imgUrls="['./static/img/support/stat-clients.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Statreports'"
                v-if="$can('read', { route: 'Statreports' })"
                :header="$t('support.nms.reportsHeader')"
                key="Reports"
                :rawText="$t('support.nms.reportsMain')"
                :imgUrls="['./static/img/support/stat-reports.png', './static/img/support/stat-reports-2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'SNMPStat'"
                v-if="$can('read', { route: 'SNMPStat' })"
                :header="$t('support.nms.SNMPStatHeader')"
                key="SNMPStat"
                :rawText="$t('support.nms.SNMPStatMain')"
                :imgUrls="['./static/img/support/SNMPStat.png']"
                class="my-2"
                second-level
              ></glossary-card>
            </folding-category>
            <folding-category
              :open-when-seraching="showingAllFoldingCategoryOpened"
              v-if="$canSomeInGroup('read', RouteGroups.MONITORING)"
              tag="h3"
              :title="$t('support.nms.monitoring')"
              key="Monitoring"
              second-level
            >
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Events'"
                :header="$t('support.nms.eventsHeader')"
                key="events"
                :rawText="$t('support.nms.eventsMain1')"
                :imgUrls="['./static/img/support/monitoring-events.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                key="events-2"
                :rawText="$t('support.nms.eventsMain2')"
                :imgUrls="['./static/img/support/monitoring-events-2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <!-- Rules -->
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Rules'"
                :header="$t('support.nms.rulesHeader')"
                key="rules"
                :imgUrls="['./static/img/support/monitoring-rules.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.nms.rulesMain1') }}
                    <br />
                    {{ $t('support.nms.rulesMain2') }}
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                key="rules-2"
                :rawText="$t('support.nms.rulesMain3')"
                :imgUrls="['./static/img/support/monitoring-rules-2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Sessions'"
                :header="$t('support.nms.cpesConnHeader')"
                key="cpes-conn"
                :rawText="$t('support.nms.cpesConnMain')"
                :imgUrls="['./static/img/support/monitoring-cpes-conn.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'WifiAnalyzer'"
                :header="$t('support.nms.wifiAnalyzerHeader')"
                key="wifi-analyzer"
                :imgUrls="['./static/img/support/monitoring-wifi-analyzer.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.nms.wifiAnalyzerMain1') }}
                    <!--                  <br />-->
                    <!--                  {{ $t('support.nms.wifiAnalyzerMain2') }}-->
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'ClientRF'"
                :header="$t('support.nms.ClientRFHeader')"
                key="ClientRF"
                :rawText="$t('support.nms.ClientRFMain')"
                :imgUrls="['./static/img/support/ClientRF.png', './static/img/support/ClientRF2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'ClientDistance'"
                :header="$t('support.nms.clientDistanceHeader')"
                key="client-distance"
                :rawText="$t('support.nms.clientDistanceMain')"
                :imgUrls="['./static/img/support/monitoring-client-distance.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'SNMPMonitoringConfig'"
                :header="$t('support.nms.SNMPMonitoringConfigHeader')"
                key="SNMPMonitoringConfig"
                :rawText="$t('support.nms.SNMPMonitoringConfigMain')"
                :imgUrls="['./static/img/support/SNMPMonitoring.png']"
                class="my-2"
                second-level
              ></glossary-card>
            </folding-category>
            <folding-category
              :open-when-seraching="showingAllFoldingCategoryOpened"
              v-if="$canSomeInGroup('read', RouteGroups.SYSTEM)"
              tag="h3"
              :title="$t('support.nms.systemHeader')"
              key="System"
              second-level
            >
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Aps'"
                :header="$t('support.nms.cpesHeader')"
                key="system-cpes"
                :rawText="$t('support.nms.systemCpesMain1')"
                :imgUrls="['./static/img/support/system-cpes.png', './static/img/support/system-cpes-2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <!--            <glossary-card-->
              <!--              key="system-cpes-2"-->
              <!--              :rawText="$t('support.nms.systemCpesMain2')"-->
              <!--              :imgUrls="['./static/img/support/system-cpes-2.png']"-->
              <!--              class="my-2"-->
              <!--            ></glossary-card>-->
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'RRM'"
                :header="$t('support.nms.RRMHeader')"
                key="RRM"
                :rawText="$t('support.nms.RRMMain')"
                :imgUrls="['./static/img/support/RRM.png', './static/img/support/RRM2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Templates'"
                :header="$t('support.nms.TemplatesHeader')"
                key="Templates"
                :rawText="$t('support.nms.TemplatesMain')"
                :imgUrls="['./static/img/support/Templates.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Wlans'"
                :header="$t('support.nms.WlansHeader')"
                key="Wlans"
                :rawText="$t('support.nms.WlansMain')"
                :imgUrls="['./static/img/support/WLANS.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Clients'"
                :header="$t('support.nms.ClientsHeader')"
                key="Clients"
                :rawText="$t('support.nms.ClientsMain')"
                :imgUrls="['./static/img/support/Clients.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Radius'"
                :header="$t('support.nms.RadiusHeader')"
                key="Radius"
                :rawText="$t('support.nms.RadiusMain')"
                :imgUrls="['./static/img/support/RADIUS.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Hotspot'"
                :header="$t('support.nms.HotspotHeader')"
                key="Hotspot"
                :rawText="$t('support.nms.HotspotMain')"
                :imgUrls="['./static/img/support/Hotspot20.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Firewall'"
                :header="$t('support.nms.FirewallHeader')"
                key="Firewall"
                :imgUrls="['./static/img/support/Firewall.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.nms.FirewallMain') }}
                    <a href="https://wiki.archlinux.org/index.php/Iptables">
                      https://wiki.archlinux.org/index.php/Iptables
                    </a>
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'GuestControl'"
                :header="$t('support.nms.GuestControlHeader')"
                key="GuestControl"
                :rawText="$t('support.nms.GuestControlMain')"
                :imgUrls="['./static/img/support/GuestControl.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'System'"
                :header="$t('support.nms.LicensesHeader')"
                key="Licenses"
                :rawText="$t('support.nms.LicensesMain')"
                :imgUrls="['./static/img/support/Licenses.png', './static/img/support/Licenses2.png']"
                class="my-2"
                second-level
              ></glossary-card>
            </folding-category>
          </folding-category>
          <folding-category
            :open-when-seraching="showingAllFoldingCategoryOpened"
            v-if="window.PORTAL && $canSomeInGroup('read', RouteGroups.PORTAL)"
            tag="h2"
            :title="$t('support.portal.portalHeader')"
          >
            <div class="col-12" key="portal">
              <div class="col-6 glossary-text">
                {{ $t('support.portal.portalMain') }}
              </div>
            </div>
            <glossary-card
              :queries-for-search="queriesForSearch"
              :header-link="'PortalDashboard'"
              v-if="$can('read', { route: 'PortalDashboard' })"
              :header="$t('support.portal.dashboardHeader')"
              key="portal-dashboard"
              :rawText="$t('support.portal.dashboardMain')"
              :imgUrls="['./static/img/support/portal-dashboard.png']"
              class="my-2"
            ></glossary-card>
            <!--          <glossary-card-->
            <!--            :header-link="'PortalStats'"-->
            <!--            v-if="$can('read', { route: 'PortalDashboard' })"-->
            <!--            :header="$t('support.portal.authHeader')"-->
            <!--            key="portal-auth"-->
            <!--            :rawText="$t('support.portal.authMain')"-->
            <!--            :imgUrls="['./static/img/support/portal-auth.png']"-->
            <!--            class="my-2"-->
            <!--          ></glossary-card>-->
            <!--          <glossary-card-->
            <!--            :header-link="'Location'"-->
            <!--            v-if="$can('read', { route: 'PortalDashboard' })"-->
            <!--            :header="$t('support.portal.locationHeader')"-->
            <!--            key="portal-location"-->
            <!--            :rawText="$t('support.portal.locationMain')"-->
            <!--            :imgUrls="['./static/img/support/portal-location.png']"-->
            <!--            class="my-2"-->
            <!--          ></glossary-card>-->
            <!--          <glossary-card-->
            <!--            :header-link="'PortalBirthdays'"-->
            <!--            v-if="$can('read', { route: 'PortalDashboard' })"-->
            <!--            :header="$t('support.portal.birthdaysHeader')"-->
            <!--            key="portal-birthdays"-->
            <!--            :rawText="$t('support.portal.birthdaysMain')"-->
            <!--            :imgUrls="['./static/img/support/portal-birthdays.png']"-->
            <!--            class="my-2"-->
            <!--          ></glossary-card>-->
            <!--          <glossary-card-->
            <!--            :header-link="'PortalAdsNew'"-->
            <!--            v-if="$can('read', { route: 'PortalDashboard' })"-->
            <!--            :header="$t('support.portal.adsHeader')"-->
            <!--            key="portal-ads"-->
            <!--            :rawText="$t('support.portal.adsMain')"-->
            <!--            :imgUrls="['./static/img/support/portal-ads.png']"-->
            <!--            class="my-2"-->
            <!--          ></glossary-card>-->
            <glossary-card
              :queries-for-search="queriesForSearch"
              v-if="$can('read', { route: 'PortalDashboard' })"
              :header="$t('support.portal.recommendationHeader')"
              key="portal-recommendation"
              :rawText="$t('support.portal.recommendationMain')"
              :imgUrls="[
                './static/img/support/portal-recommendation.png',
                './static/img/support/portal-recommendation-2.png'
              ]"
              class="my-2"
            ></glossary-card>
            <folding-category
              :open-when-seraching="showingAllFoldingCategoryOpened"
              v-if="$canSomeInGroup('read', RouteGroups.PORTAL_CONFIGURATION)"
              tag="h3"
              :title="$t('support.portal.configuration')"
              key="portal-config"
              second-level
            >
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalProfilesNew'"
                v-if="$can('read', { route: 'PortalProfilesNew' })"
                :header="$t('support.portal.profilesHeader')"
                key="portal-profiles"
                :rawText="$t('support.portal.profilesMain1')"
                :listedText="profiles"
                :imgUrls="['./static/img/support/portal-profiles.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                v-if="$can('read', { route: 'PortalProfilesNew' })"
                key="portal-profiles-2"
                :rawText="$t('support.portal.profilesMain2')"
                :imgUrls="['./static/img/support/portal-profiles-2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalPreview'"
                v-if="$can('read', { route: 'PortalPreview' })"
                :header="$t('support.portal.previewHeader')"
                key="portal-preview"
                :rawText="$t('support.portal.previewMain')"
                :imgUrls="['./static/img/support/portal-preview.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalPagesNew'"
                v-if="$can('read', { route: 'PortalPagesNew' })"
                :header="$t('support.portal.pagesHeader')"
                key="portal-pages"
                :rawText="$t('support.portal.pagesMain')"
                :imgUrls="['./static/img/support/portal-pages.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalHotelVouchers'"
                v-if="$can('read', { route: 'PortalHotelVouchers' })"
                :header="$t('support.portal.hotelVouchersHeader')"
                key="portal-hotel-vouchers"
                :imgUrls="['./static/img/support/portal-hotel-vouchers.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.portal.hotelVouchersMain1') }}
                    <br />
                    {{ $t('support.portal.hotelVouchersMain2') }}
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                v-if="$can('read', { route: 'PortalHotelVouchers' })"
                key="portal-hotel-vouchers-2"
                :rawText="$t('support.portal.hotelVouchersMain3')"
                :imgUrls="['./static/img/support/portal-hotel-vouchers-2.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalAdsNew'"
                v-if="$can('read', { route: 'PortalAdsNew' })"
                :header="$t('support.portal.adsAndPollsHeader')"
                key="portal-ads-and-polls"
                :imgUrls="['./static/img/support/portal-ads-and-polls.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.portal.adsAndPollsMain1') }}
                    <br />
                    {{ $t('support.portal.adsAndPollsMain2') }}
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                v-if="$can('read', { route: 'PortalAdsNew' })"
                key="portal-ads-and-polls-2"
                :rawText="$t('support.portal.adsAndPollsMain3')"
                :listedText="adsAndPolls"
                :imgUrls="[
                  './static/img/support/portal-ads-and-polls-2.png',
                  './static/img/support/portal-ads-and-polls-3.png'
                ]"
                class="my-2"
                second-level
              ></glossary-card>
            </folding-category>

            <folding-category
              :open-when-seraching="showingAllFoldingCategoryOpened"
              tag="h3"
              :title="$t('support.portal.data.analyticsAndData')"
              key="portal-data"
              v-if="$canSomeInGroup('read', RouteGroups.PORTAL_ANALYTICS)"
              second-level
            >
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'UserAccounts'"
                v-if="$can('read', { route: 'UserAccounts' })"
                :header="$t('support.portal.data.accountsHeader')"
                key="portal-accounts"
                :rawText="$t('support.portal.data.accountsMain')"
                :imgUrls="['./static/img/support/portal-accounts.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalSessions'"
                v-if="$can('read', { route: 'PortalSessions' })"
                :header="$t('support.portal.data.portalSessionsHeader')"
                key="portal-sessions"
                :rawText="$t('support.portal.data.portalSessionsMain')"
                :imgUrls="['./static/img/support/PortalSessions.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalStats'"
                v-if="$can('read', { route: 'PortalStats' })"
                :header="$t('support.portal.data.statisticsHeader')"
                key="portal-stats"
                :rawText="$t('support.portal.data.statisticsMain')"
                :imgUrls="['./static/img/support/portal-stats.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalBirthdays'"
                v-if="$can('read', { route: 'PortalBirthdays' })"
                :header="$t('support.portal.birthdaysHeader')"
                key="portal-data-birthdays"
                :rawText="$t('support.portal.data.birthdaysMain')"
                :imgUrls="['./static/img/support/portal-birthdays.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalAdsStats'"
                v-if="$can('read', { route: 'PortalAdsStats' })"
                :header="$t('support.portal.adsAndPollsHeader')"
                key="portal-data-ads-and-polls"
                :rawText="$t('support.portal.data.adsAndPollsMain')"
                :imgUrls="['./static/img/support/portal-data-ads-and-polls.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PortalLocationsStat'"
                v-if="$can('read', { route: 'PortalLocationsStat' })"
                :header="$t('support.portal.data.portalLocationsStatHeader')"
                key="portalLocationsStat"
                :rawText="$t('support.portal.data.portalLocationsStatMain')"
                :imgUrls="['./static/img/support/PortalLocationsStat.png']"
                class="my-2"
                second-level
              ></glossary-card>
            </folding-category>
            <folding-category
              :open-when-seraching="showingAllFoldingCategoryOpened"
              v-if="window.PAID_INTERNET && $canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET)"
              tag="h3"
              :title="$t('support.portal.paidInternet.paidInternetHeader')"
              key="portal-paid-internet"
              second-level
            >
              <!--            <div class="col-6" key="portal-paid-internet-text">-->
              <!--              <div class="col-12 glossary-text">-->
              <!--                {{ $t('support.portal.paidInternet.paidInternetMain') }}-->
              <!--              </div>-->
              <!--            </div>-->
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Vouchers'"
                v-if="$can('read', { route: 'Vouchers' })"
                :header="$t('support.portal.paidInternet.vouchersHeader')"
                key="portal-vouchers"
                :imgUrls="['./static/img/support/portal-vouchers.png']"
                class="my-2"
                second-level
              >
                <template #rawText>
                  <div class="col-12">
                    {{ $t('support.portal.paidInternet.vouchersMain1') }}
                    <br />
                    {{ $t('support.portal.paidInternet.vouchersMain2') }}
                  </div>
                </template>
              </glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'Tariffs'"
                v-if="$can('read', { route: 'Tariffs' })"
                :header="$t('support.portal.paidInternet.tariffsHeader')"
                key="portal-tariffs"
                :rawText="$t('support.portal.paidInternet.tariffsMain')"
                :imgUrls="['./static/img/support/portal-tariffs.png']"
                class="my-2"
                second-level
              ></glossary-card>
              <glossary-card
                :queries-for-search="queriesForSearch"
                :header-link="'PaymentSystems'"
                v-if="$can('read', { route: 'PaymentSystems' })"
                :header="$t('support.portal.paidInternet.paymentHeader')"
                key="portal-payment"
                :rawText="$t('support.portal.paidInternet.paymentMain')"
                :imgUrls="['./static/img/support/portal-payment.png']"
                class="my-2"
                second-level
              ></glossary-card>
            </folding-category>
          </folding-category>

          <folding-category
            :open-when-seraching="showingAllFoldingCategoryOpened"
            v-if="window.ANALYTICS && $canSomeInGroup('read', RouteGroups.ANALYTICS)"
            tag="h2"
            :title="$t('support.analytics.analyticsHeader')"
            key="portal-analytics"
          >
            <div class="col-6" key="portal-analytics-text">
              <div class="col-12 glossary-text">
                {{ $t('support.analytics.analyticsMain1') }}

                <br />
                {{ $t('support.analytics.analyticsMain2') }}
                <ul>
                  <li v-for="(row, index) in analytics" :key="index">
                    <text-highlight :queries="queriesForSearch">{{ row }}</text-highlight>
                  </li>
                </ul>
              </div>
            </div>
            <glossary-card
              :queries-for-search="queriesForSearch"
              :header-link="'Marketing'"
              v-if="$can('read', { route: 'Marketing' })"
              :header="$t('support.analytics.visitorsHeader')"
              key="analytics-visitors"
              :imgUrls="['./static/img/support/portal-visitors.png']"
              class="my-2"
            >
              <template #rawText>
                <div class="col-12">
                  <text-highlight :queries="queriesForSearch">
                    {{ $t('support.analytics.visitorsMain1') }}
                  </text-highlight>
                  <br />
                  <text-highlight :queries="queriesForSearch">
                    {{ $t('support.analytics.visitorsMain2') }}
                  </text-highlight>
                </div>
              </template>
            </glossary-card>
            <glossary-card
              :queries-for-search="queriesForSearch"
              :header-link="'maps'"
              v-if="$can('read', { route: 'maps' })"
              :header="$t('support.analytics.heatmapHeader')"
              key="analytics-heatmap"
              :rawText="$t('support.analytics.heatmapMain')"
              :imgUrls="['./static/img/support/portal-heatmap.png']"
              class="my-2"
            ></glossary-card>
            <glossary-card
              :queries-for-search="queriesForSearch"
              :header-link="'Radar'"
              v-if="$can('read', { route: 'Radar' })"
              :header="$t('support.analytics.radarExportHeader')"
              key="analytics-radar-export"
              :rawText="$t('support.analytics.radarExportMain')"
              :imgUrls="['./static/img/support/portal-radar-export.png']"
              class="my-2"
            ></glossary-card>
          </folding-category>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FoldingCategory from '../../components/Support/folding-category.vue';
import GlossaryCard from '../../components/Support/glossary-card.vue';
import RouteGroups from '../../access-control/RouteGroups';

export default {
  name: 'Support',
  components: { GlossaryCard, FoldingCategory },
  data() {
    return {
      RouteGroups,
      queriesForSearch: [],
      queriesForSearchInputedInSearchField: '',
      showingAllFoldingCategoryOpened: false,
      domHighlightedElementsFoundLength: 0,
      currentDomHighlightedElementIndex: 0,
      delayTimer: null,
      searchSpinner: false
    };
  },
  methods: {
    goToPrevFoundElement() {
      this.domHighlightedElementsFoundBySearch[this.currentDomHighlightedElementIndex].classList.remove(
        'text__highlight-current'
      );
      this.currentDomHighlightedElementIndex -= 1;
      if (this.currentDomHighlightedElementIndex < 0) {
        this.currentDomHighlightedElementIndex = this.domHighlightedElementsFoundLength - 1;
        this.goToHighlightedElement(this.currentDomHighlightedElementIndex);
      } else {
        this.goToHighlightedElement(this.currentDomHighlightedElementIndex);
      }
    },
    goToNextFoundElement() {
      this.domHighlightedElementsFoundBySearch[this.currentDomHighlightedElementIndex].classList.remove(
        'text__highlight-current'
      );
      this.currentDomHighlightedElementIndex += 1;
      if (this.currentDomHighlightedElementIndex > this.domHighlightedElementsFoundLength - 1) {
        this.currentDomHighlightedElementIndex = 0;
        this.goToHighlightedElement(this.currentDomHighlightedElementIndex);
      } else {
        this.goToHighlightedElement(this.currentDomHighlightedElementIndex);
      }
    },
    goToHighlightedElement(index) {
      this.domHighlightedElementsFoundBySearch[index].classList.add('text__highlight-current');
      // const nearestSpan = this.domHighlightedElementsFoundBySearch[index].closest('span');
      // nearestSpan.scrollIntoView({ block: 'center', behavior: 'smooth' });
      this.domHighlightedElementsFoundBySearch[index].scrollIntoView({ block: 'center', behavior: 'smooth' });
    },
    getDomHighlightedElementsFoundLength() {
      let length = 0;
      for (const item of this.domHighlightedElementsFoundBySearch) {
        length++;
      }
      return length;
    },
    searchClear() {
      this.currentDomHighlightedElementIndex = 0;
      this.domHighlightedElementsFoundLength = 0;
      this.queriesForSearchInputedInSearchField = '';
      this.showingAllFoldingCategoryOpened = false;
      this.queriesForSearch = [];
    },
    searchInput() {
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.currentDomHighlightedElementIndex = 0;
        this.domHighlightedElementsFoundLength = 0;
        this.showingAllFoldingCategoryOpened = true;
        this.$nextTick(() => {
          this.queriesForSearch = [];
          const inputedAsArray = [];
          inputedAsArray.push(this.queriesForSearchInputedInSearchField);
          //  queriesForSearch будет прокинут как пропса в компоненты для поиска
          this.queriesForSearch = inputedAsArray;
          // console.log(this.queriesForSearch);
          this.$nextTick(() => {
            // записываем сколько ээлементов нашлось
            this.domHighlightedElementsFoundLength = this.getDomHighlightedElementsFoundLength();
            if (this.domHighlightedElementsFoundLength > 0) {
              // если что-то нашлось сразу прокручиваем туда
              this.goToHighlightedElement(0);
            }
            this.searchSpinner = false;
          });
        });
      }, 500);
    }
  },
  computed: {
    domHighlightedElementsFoundBySearch() {
      // почему-то не работает как computed, не обновляется
      return document.getElementsByClassName('text__highlight');
    },
    isPortalOnly() {
      if (window.PORTAL_ONLY) {
        return true;
      }
      return false;
    },
    window() {
      return window;
    },
    glossaryData() {
      return [
        // 'ТД, Access Point, AP — Точка Доступа',
        // 'ПО — Программное обеспечение',
        // 'BSSID — Basic Service Set IDentifier (MAC адрес сетевого адаптера)',
        // 'CPE — Customer Premises Equipment (телекоммуникационное оборудование, расположенное в помещении абонента/клиента)',
        // 'CPU — Central Processing Unit (центральный процессор)',
        // 'DHCP — Dynamic Host Configuration Protocol (протокол, отвечающий за динамическую выдачу ip-адресов устройствам сети)',
        // 'DNS — Domain Name System (система доменных имён)',
        // 'HTTP — HyperText Transfer Protocol (протокол передачи гипертекста)',
        // 'HTTPS — HyperText Transfer Protocol Secure (расширение протокола HTTP, поддерживающее шифрование)',
        // 'IP — Internet Protocol Address (уникальный сетевой адрес узла в компьютерной сети)',
        // 'MAC — Media Access Control (уникальный идентификатор, присваиваемый каждой единице сетевого оборудования)',
        // 'NAS — Network Attached Storage (сервер для хранения данных на файловом уровне)',
        // 'NAT — Network Address Translation (преобразование сетевых адресов)',
        // 'NMS — Network Management System (система управления сетью)',
        // 'OS — Operating System (операционная система)',
        // 'RADIUS — Remote Authentication Dial-In User Service (служба дистанционной аутентификации пользователей по коммутируемым линиям)',
        // 'RRM — Radio Resource Management (управление характеристиками радиопередачи в системе)',
        // 'RSSI — Received Signal Strength Indicator (полная мощность принимаемого приёмником сигнала)',
        // 'SSID — Service Set Identifier (символьное название беспроводной точки доступа Wi-Fi)',
        // 'URL — Uniform Resource Locator (система унифицированных адресов электронных ресурсов)',
        // 'UUID — Universally Unique Identifier (универсальный уникальный идентификатор)',
        // 'VLAN — Virtual Local Area Network (локальная компьютерная сеть, представляющая собой группу хостов с общим набором требований)',
        // 'WLAN — Wireless Local Area Network (локальная сеть, построенная на основе беспроводных технологий)',
        // 'WPA — Wi-Fi Protected Access (программа сертификации устройств беспроводной связи)',
        // 'WSP — WiMark Systems Platform',
        // 'WSFW — WiMark Systems Firmware',
        // 'WSNMS — WiMark Systems Network Management System',
        // 'WSCPEA — WiMark Systems Customer Premises Equipment Agent',
        this.$t('support.glossary.AP'),
        this.$t('support.glossary.SOFTWARE'),
        this.$t('support.glossary.BSSID'),
        // this.$t('support.glossary.CPE'),
        this.$t('support.glossary.CPU'),
        this.$t('support.glossary.DHCP'),
        this.$t('support.glossary.DNS'),
        this.$t('support.glossary.HTTP'),
        this.$t('support.glossary.HTTPS'),
        this.$t('support.glossary.IP'),
        this.$t('support.glossary.MAC'),
        this.$t('support.glossary.NAS'),
        this.$t('support.glossary.NAT'),
        this.$t('support.glossary.NMS'),
        this.$t('support.glossary.OS'),
        this.$t('support.glossary.RADIUS'),
        this.$t('support.glossary.RRM'),
        this.$t('support.glossary.RSSI'),
        this.$t('support.glossary.SSID'),
        this.$t('support.glossary.URL'),
        this.$t('support.glossary.UUID'),
        this.$t('support.glossary.VLAN'),
        this.$t('support.glossary.WLAN'),
        this.$t('support.glossary.WPA')
        // this.$t('support.glossary.WSP'),
        // this.$t('support.glossary.WSFW'),
        // this.$t('support.glossary.WSNMS'),
        // this.$t('support.glossary.WSCPEA')
      ];
    },
    glossaryLeft() {
      return this.glossaryData.filter((row, index) => index < this.glossaryHalf);
    },
    glossaryRight() {
      return this.glossaryData.filter((row, index) => index >= this.glossaryHalf);
    },
    glossaryHalf() {
      return Math.floor(this.glossaryData.length / 2);
    },
    dashboard() {
      return [
        this.$t('support.nms.dashboard1'),
        this.$t('support.nms.dashboard2'),
        this.$t('support.nms.dashboard3'),
        this.$t('support.nms.dashboard4'),
        this.$t('support.nms.dashboard5')
      ];
    },
    nms() {
      return [
        this.$t('support.nms.nms1'),
        this.$t('support.nms.nms2'),
        this.$t('support.nms.nms3'),
        this.$t('support.nms.nms4'),
        this.$t('support.nms.nms5'),
        this.$t('support.nms.nms6'),
        this.$t('support.nms.nms7'),
        this.$t('support.nms.nms8')
      ];
    },
    profiles() {
      return [
        this.$t('support.portal.profiles1'),
        this.$t('support.portal.profiles2'),
        this.$t('support.portal.profiles3'),
        this.$t('support.portal.profiles4'),
        this.$t('support.portal.profiles5')
      ];
    },
    adsAndPolls() {
      return [
        this.$t('support.portal.adsAndPolls1'),
        this.$t('support.portal.adsAndPolls2'),
        this.$t('support.portal.adsAndPolls3'),
        this.$t('support.portal.adsAndPolls4'),
        this.$t('support.portal.adsAndPolls5'),
        this.$t('support.portal.adsAndPolls6')
      ];
    },
    analytics() {
      return [
        this.$t('support.analytics.analytics1'),
        this.$t('support.analytics.analytics2'),
        this.$t('support.analytics.analytics3')
      ];
    }
  }
};
</script>

<style lang="scss">
.glossary-text {
  font-size: 18px;
}

.searching-block-in-support {
  width: 100%;
  background-color: var(--bodybackgroundcolor);
  /*padding-bottom: 1em;*/
  display: flex;
  /*margin-top: 0.2em;*/
  align-items: flex-end;
}
.searching-block-in-support-item {
  height: 30px !important;
  display: flex;
  align-items: flex-end;
  margin-top: -2px;
  font-size: 1.1em;
  font-weight: 500;
}

.searching-block-in-support-wrapper {
  background-color: var(--bodybackgroundcolor);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 2;
  width: 100%;
  position: fixed;
  /*<!--top: -30px;-->*/
  top: 57px;
  padding: 1rem;
  margin-bottom: 1em;
}
.searching-block-input {
  width: 300px !important;
}

.text__highlight-current {
  background-color: #ffa900 !important;
}
</style>
