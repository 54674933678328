import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import moment from 'moment';

export default {
  // getExports(ctx) {
  //   ctx.$store.commit('getRadarExportsReqBegin');
  //   Vue.axios
  //     .post(`${window.RESTAPI || ''}/api/radar/export`, {
  //       action: 'R',
  //       sort_by: {
  //         field: 'name',
  //         order: 1
  //       }
  //     })
  //     .then(
  //       (response) => {
  //         if (response.data.status === 'success') {
  //           ctx.$store.commit('setRadarExportList', response.data.data.itemslist);
  //         } else if (response.data.status === 'error') {
  //           VueNotifications.error({ message: response.data.description });
  //         }
  //         ctx.$store.commit('getRadarExportsReqEnd');
  //       },
  //       (err) => {
  //         ctx.$store.commit('getRadarExportsReqEnd');
  //         // VueNotifications.error({ message: err });
  //
  //         // show errors in the console instead of the alert window
  //         if (
  //           Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //           typeof window.isShowAxiosErrorInConsole === 'function' &&
  //           window.isShowAxiosErrorInConsole(err)
  //         ) {
  //           window.showAxiosErrInConsole(err);
  //         } else {
  //           VueNotifications.error({ message: err });
  //         }
  //
  //         ctx.error = err;
  //       }
  //     );
  // },
    getExports(ctx, useLocationFilter= false) {
      const isUseLocationFilters = useLocationFilter;
    ctx.$store.commit('getRadarExportsReqBegin');
    const query = {
              action: 'R',
        sort_by: {
          field: 'name',
          order: 1
        },
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
    }

        if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        console.log('ctx.userBaseLocation', ctx.userBaseLocation);
        if (ctx.userBaseLocation ) {
          query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        }
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }


    Vue.axios
      .post(`${window.RESTAPI || ''}/api/radar/export`, query)
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('setRadarExportList', response.data.data.itemslist);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('getRadarExportsReqEnd');
        },
        (err) => {
          ctx.$store.commit('getRadarExportsReqEnd');
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },
  // getExportsFiltered(ctx) {
  //   const query = this.getQuery(ctx);
  //   ctx.$store.commit('getRadarExportsReqBegin');
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/radar/export`, query).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setRadarExportList', response.data.data.itemslist);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('getRadarExportsReqEnd');
  //     },
  //     (err) => {
  //       ctx.$store.commit('getRadarExportsReqEnd');
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //     }
  //   );
  // },
  getExportsFiltered(ctx, useLocationFilter= false) {
      const isUseLocationFilters = useLocationFilter;
    const query = this.getQuery(ctx, isUseLocationFilters);
    ctx.$store.commit('getRadarExportsReqBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api/radar/export`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setRadarExportList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('getRadarExportsReqEnd');
      },
      (err) => {
        ctx.$store.commit('getRadarExportsReqEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  // getQuery(ctx) {
  //   const query = {
  //     action: 'R',
  //     search: ctx.filters.query,
  //     q: {},
  //     sort_by: {
  //       field: 'name',
  //       order: 1
  //     }
  //   };
  //   if (ctx.filters.sortOrder === 'asc') {
  //     query.sort_by.order = 1;
  //   } else if (ctx.filters.sortOrder === 'desc') {
  //     query.sort_by.order = -1;
  //   }
  //   if (ctx.filters.sortByFilter === 'Name') {
  //     query.sort_by.field = 'name';
  //   } else if (ctx.filters.sortByFilter === 'Created') {
  //     query.sort_by.field = 'create_at';
  //   } else if (ctx.filters.sortByFilter === 'Updated') {
  //     query.sort_by.field = 'last_at';
  //   }
  //   // if (ctx.filters.hasOwnProperty('company') && ctx.filters.company !== 'All companys') {
  //   //   ctx.filters.location = 'All locations'
  //   //   query.q = Object.assign(query.q, { base_location: ctx.filters.company.locationId });
  //   // }
  //   if (ctx.filters.location !== 'All locations') {
  //     query.q = Object.assign(query.q, { base_location: ctx.filters.location });
  //   }
  //   if (ctx.filters.status !== '') {
  //     query.q = Object.assign(query.q, { status: ctx.filters.status });
  //   }
  //   if (ctx.filters.typeFilter !== '') {
  //     query.q = Object.assign(query.q, { type: ctx.filters.typeFilter });
  //   }
  //   if (ctx.filters.isDateRangeFilterEnabled) {
  //     const startDateTime = moment(ctx.filters.drPickerDate.startDate).unix();
  //     const endDateTime = moment(ctx.filters.drPickerDate.endDate).unix();
  //     if (ctx.filters.dataRangeFilterType === 'Created') {
  //       query.q = Object.assign(query.q, {
  //         create_at: {
  //           $gte: startDateTime,
  //           $lte: endDateTime
  //         }
  //       });
  //     } else if (ctx.filters.dataRangeFilterType === 'Updated') {
  //       query.q = Object.assign(query.q, {
  //         last_at: {
  //           $gte: startDateTime,
  //           $lte: endDateTime
  //         }
  //       });
  //     }
  //   }
  //
  //   return query;
  // },
  getQuery(ctx, useLocationFilter = false) {
      const isUseLocationFilters = useLocationFilter;
    const query = {
      action: 'R',
      search: ctx.filters.query,
      q: {},
      sort_by: {
        field: 'name',
        order: 1
      }
    };
    if (ctx.filters.sortOrder === 'asc') {
      query.sort_by.order = 1;
    } else if (ctx.filters.sortOrder === 'desc') {
      query.sort_by.order = -1;
    }
    if (ctx.filters.sortByFilter === 'Name') {
      query.sort_by.field = 'name';
    } else if (ctx.filters.sortByFilter === 'Created') {
      query.sort_by.field = 'create_at';
    } else if (ctx.filters.sortByFilter === 'Updated') {
      query.sort_by.field = 'last_at';
    }
    // if (ctx.filters.hasOwnProperty('company') && ctx.filters.company !== 'All companys') {
    //   ctx.filters.location = 'All locations'
    //   query.q = Object.assign(query.q, { base_location: ctx.filters.company.locationId });
    // }

    // if (ctx.filters.location !== 'All locations' && !useLocationFilter) {
    //   query.q = Object.assign(query.q, { base_location: ctx.filters.location });
    // }

    if (!isUseLocationFilters) {
      query.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      query.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }
    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        console.log('ctx.userBaseLocation', ctx.userBaseLocation);
        if (ctx.userBaseLocation ) {
          query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        }
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }
    if (ctx.filters.status !== '') {
      query.q = Object.assign(query.q, { status: ctx.filters.status });
    }
    if (ctx.filters.typeFilter !== '') {
      query.q = Object.assign(query.q, { type: ctx.filters.typeFilter });
    }
    if (ctx.filters.isDateRangeFilterEnabled) {
      const startDateTime = moment(ctx.filters.drPickerDate.startDate).unix();
      const endDateTime = moment(ctx.filters.drPickerDate.endDate).unix();
      if (ctx.filters.dataRangeFilterType === 'Created') {
        query.q = Object.assign(query.q, {
          create_at: {
            $gte: startDateTime,
            $lte: endDateTime
          }
        });
      } else if (ctx.filters.dataRangeFilterType === 'Updated') {
        query.q = Object.assign(query.q, {
          last_at: {
            $gte: startDateTime,
            $lte: endDateTime
          }
        });
      }
    }

    return query;
  },
  crateExport(ctx, newExport) {
    const cpesId = ctx.exportCpes.map((cpe) => cpe.id);
    newExport.cpes = cpesId;

    if (ctx.newShareEmail && newExport.creds.share) {
      newExport.creds.share.push(ctx.newShareEmail);
    }

    newExport.period.start = +(ctx.drPickerDateForExport.startDate / 1000).toFixed(0);
    newExport.period.stop = +(ctx.drPickerDateForExport.endDate / 1000).toFixed(0);

    ctx.$store.commit('toggleMuteExportEdit');
    const updateExportQuery = { action: 'C', items: { 0: newExport } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/radar/export`, updateExportQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('radar.notificationRadarExportCreatedSuccessfully') });
          ctx.refreshExportList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.cancelEditMode();
        ctx.$store.commit('toggleMuteExportEdit');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteExportEdit');
        ctx.error = err;
      }
    );
  },
  updateExport(ctx, updatedExport) {
    const cpesId = ctx.exportCpes.map((cpe) => cpe.id);
    updatedExport.cpes = cpesId;

    if (ctx.newShareEmail && updatedExport.creds.share) {
      updatedExport.creds.share.push(ctx.newShareEmail);
    }

    updatedExport.period.start = +(ctx.drPickerDateForExport.startDate / 1000).toFixed(0);
    updatedExport.period.stop = +(ctx.drPickerDateForExport.endDate / 1000).toFixed(0);

    ctx.$store.commit('toggleMuteExportEdit');
    const updateExportQuery = { action: 'U', items: { [updatedExport.id]: updatedExport } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/radar/export`, updateExportQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('radar.notificationRadarExportUpdatedSuccessfully') });
          ctx.refreshExportList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.cancelEditMode();
        ctx.$store.commit('toggleMuteExportEdit');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteExportEdit');
        ctx.error = err;
      }
    );
  },
  deleteExport(ctx, updatedExport) {
    ctx.$store.commit('toggleMuteExportEdit');
    const updateExportQuery = { action: 'D', items: { [updatedExport.id]: updatedExport } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/radar/export`, updateExportQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('radar.notificationRadarExportDeletedSuccessfully') });
          ctx.refreshExportList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.cancelEditMode();
        ctx.$store.commit('toggleMuteExportEdit');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteExportEdit');
        ctx.error = err;
      }
    );
  }
};
