<template>
  <div class="view-tooltip">
    <button
      class="view-tooltip__button icon-question"
      :class="{ 'view-tooltip__button--pressed': isOpen }"
      @click="handleTooltipClick"
    />
    <p v-if="isOpen" class="view-tooltip__message">
      {{ $te(`tooltip.${tooltipKey}`) ? $t(`tooltip.${tooltipKey}`) : $t('tooltip.NotFound') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'view-tooltip',
  data() {
    return {
      tooltipKey: '',
      isOpen: false
    };
  },
  watch: {
    $route() {
      this.isOpen = false;
    }
  },
  methods: {
    handleTooltipClick() {
      this.tooltipKey = this.$route.name;
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="scss">
.view-tooltip {
  position: relative;

  align-self: center;
}

.view-tooltip__button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  color: #bcc5d0;

  background-color: transparent;
  border: none;
}

.view-tooltip__message {
  position: absolute;
  top: 35px;
  left: 50%;

  padding: 10px;
  width: 220px;

  background-color: white;
  text-align: center;
  border: 1px solid #e3e8ec;

  transform: translateX(-50%);
}
</style>
