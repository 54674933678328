<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class=" h-100">
        <div class="card-block">
<!--          <div class="h4 m-0">-->
<!--            {{ $t('general.step4') }}-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"-->
<!--              @click="lastStepButtonClickHandler"-->
<!--            >-->
<!--              {{ $t('wlans.wizardSkipButton') }}-->
<!--              <info :content="$t('wlans.wizardSkipButtonInfo')" />-->
<!--            </button>-->
<!--          </div>-->
          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('wlans.wizardRADIUSAccounting') }}</span>
          <form action="">
            <transition name="wsettings mt-1">
              <div class="mt-1">
                <div class="form-group mb-1">
                  <Switch-component
                    v-model="newWlanData.radius_acct_mirroring"
                    :label="$t('wlans.mirroring')"
                    id="radius-acct-mirroring"
                  />
                  <info class="ml-q" :content="$t('wlans.mirroringHint')"/>
                </div>

                <div class="form-group">
                  <label for="radius_acc">{{ $t('wlans.radiusAccountingForWLANModalAAA') }}</label> <small><a href="#" class="ml-q mb-1"  @click.stop.prevent="saveCurrentDataToStoreAndGoToRadiusCreate">{{$t('wlans.addRADIUS')}}</a></small>
                  <Multiselect
                    id="radius_acc"
                    class="rule-multi"
                    data-vv-name="radius_acc"
                    v-model="updatedWlanRadiusAccounting"
                    :multiple="true"
                    :options="radiusList || []"
                    :max="8"
                    trackBy="id"
                    label="name"
                    @input="enableSave()"
                    :class="{
                      input: true,
                      'is-danger': errors.has('radius_acc')
                    }"
                    :placeholder="$t('wlans.selectRADIUSPlaceholder')"
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                    :deselectLabel="$t('general.multiSelectDeselectLabel')"
                    :selectedLabel="$t('general.multiSelectSelected')"
                  ></Multiselect>
                </div>
                <div class="form-group mt-1" v-if="isBeeline || isExperementalBuild">
                  <label for="vlan">
                    {{ $t('wlans.rsnaSettingsV2') }}
                  </label>
                  <input
                    :data-vv-as="'Calling Station ID'"
                    type="text"
                    id="RSNA"
                    name="RSNA"
                    :class="{ input: true, 'is-danger': errors.has('RSNA') }"
                    v-validate="'max:16'"
                    class="form-control"
                    v-model="newWlanData.beeline_accountng_type"
                  />
                  <small class="text-muted">
                    {{ $t('wlans.rsnaCapture') }}
                  </small>
                </div>
                <span v-show="errors.has('RSNA')" class="help is-danger">
                  {{ errors.first('RSNA') }}
                </span>
              </div>
            </transition>
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="btn btn-outline-success float-right"-->
            <!--              @click="setNewWlanData"-->
            <!--            >-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left"-->
            <!--                    @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
<!--        <div class="buttons-block-warpper">-->
<!--          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
<!--            {{ $t('general.next') }}-->
<!--          </button>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-outline-info float-right mr-h"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @click="lastStepButtonClickHandler">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t('wlans.wizardSkipButton') }} <info :content="$t('wlans.wizardSkipButtonInfo')"/>&ndash;&gt;-->
<!--          &lt;!&ndash;          </button>&ndash;&gt;-->
<!--          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
<!--            {{ $t('general.back') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import radiusService from '../../../services/radiusService';
import helpers from '../../../helpers';
import SwitchComponent from '../../../components/Universal/Switch-component.vue';
import Info from '../../../components/Universal/info-icon.vue';

export default {
  name: 'Step4',
  components: { Multiselect, SwitchComponent, Info },
  data() {
    return {
      enableSaveChanges: true,
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      },
      // showWlanPass: false,
      // updatedWlanRadiusAuthentication: [],
      updatedWlanRadiusAccounting: []
    };
  },
  computed: {
    isExperementalBuild(){
      return this.$store.getters.isExperementalBuild;
    },
    isBeeline() {
      return this.$store.getters.isBeeline;
    },
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    radiusList() {
      return this.$store.state.radiusList;
    }
  },
  methods: {
    saveCurrentDataToStoreAndGoToRadiusCreate() {
      const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAccounting);
      this.newWlanData.radius_acct_servers = radiusAcctServersIds;
      this.$store.commit('setNewWlanData', this.newWlanData);

      this.$emit('addNewRadiusClicked')
    },
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    enableSave() {
      this.enableSaveChanges = true;
    },
    // setNewWlanData(event, toLastStepClicked = false) {
    //   const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAccounting);
    //   this.newWlanData.radius_acct_servers = radiusAcctServersIds;
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         // this.$parent.nextStep();
    //         if (toLastStepClicked === false) {
    //           this.$parent.nextStep();
    //         } else {
    //           // this.$parent.nextStep(true);
    //           this.$parent.lastStep();
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // }
    validateAndSetNewWlanData(currentTab, nextTab){
      this.setNewWlanData(currentTab, nextTab)
    },
    setNewWlanData(currentTab, nextTab) {
      const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAccounting);
      this.newWlanData.radius_acct_servers = radiusAcctServersIds;
      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
          }else{
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          }
        },
        () => {
          this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    radiusService.getRadius(this);
    // this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
    // цикл ниже чтобы заполннить поле 'RADIUS сервер учета' прежними значениями если возвращаемся на этот шаг
    // из какого-нибудь другого шага
    for (const radiusId of this.newWlanData.radius_acct_servers) {
      for (const radiusObj of this.radiusList) {
        if (radiusId === radiusObj.id) {
          this.updatedWlanRadiusAccounting.push(radiusObj);
        }
      }
    }
    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
