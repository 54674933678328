<template>
  <div id="app" class="app">
    <Header />
    <Alert
      :class="{'high-z-index-for-licences-alerts-in-header' : addHighZIndexForLicencesAlerts}"
      v-if="showLicenseAlertOnSessionStart"
      closable
      class="alert-warning alert-license"
      @alert-closed="closeLicenseAlert"
    >
      <template #text>
        <h6 class="text-center m-0">
          {{ $t('alert.licenseEnds.text') }}
          <a href="#" class="alert-link" @click="goToAccount">
            {{ $t('alert.licenseEnds.link') }}
          </a>
        </h6>
      </template>
    </Alert>
<!--    точки-->
    <Alert
      :class="{'high-z-index-for-licences-alerts-in-header': addHighZIndexForLicencesAlerts}"
      v-if="showingLowOfLicensesAlert"
      closable
      class="alert-success alert-license"
      @alert-closed="closeLowOfLicensesAlert"
    >
      <template #text>
        <h6 class="text-center m-0">
          {{ $t('alert.lowOfLicenses.text') }}
          <a href="#" class="alert-link" @click="goToLicenses()">
            {{ $t('alert.lowOfLicenses.link') }}
          </a>
        </h6>
      </template>
    </Alert>
<!--    порталы-->
    <Alert
      :class="{'high-z-index-for-licences-alerts-in-header': addHighZIndexForLicencesAlerts}"
      v-if="showingLowOfLicensesPortalsAlert"
      closable
      class="alert-success alert-license"
      @alert-closed="closeLowOfLicensesPortalsAlert"
    >
      <template #text>
        <h6 class="text-center m-0">
          {{ $t('alert.lowOfLicensesPortals.text') }}
          <a href="#" class="alert-link" @click="goToLicensesPortals()">
            {{ $t('alert.lowOfLicensesPortals.link') }}
          </a>
        </h6>
      </template>
    </Alert>
    <!--    аналитики-->
    <Alert
      :class="{'high-z-index-for-licences-alerts-in-header': addHighZIndexForLicencesAlerts}"
      v-if="showingLowOfLicensesAnalyticsAlert"
      closable
      class="alert-success alert-license"
      @alert-closed="closeLowOfLicensesAnalyticsAlert"
    >
      <template #text>
        <h6 class="text-center m-0">
          {{ $t('alert.lowOfLicensesAnalytics.text') }}
          <a href="#" class="alert-link" @click="goToLicensesAnalytics()">
            {{ $t('alert.lowOfLicensesAnalytics.link') }}
          </a>
        </h6>
      </template>
    </Alert>
    <div class="app-body" :class="{ 'app-body--custom': window.CUSTOM_BUILD }">
      <UserActivityWatcher></UserActivityWatcher>
      <Sidebar />
      <main class="main">
        <NewVersionChecker v-if="isNewVersionCheckerEnabled"></NewVersionChecker>
        <!--        <PaywallForAuthFreemium v-if="isAppAuthFreemiumEnabled">-->
        <!--        </PaywallForAuthFreemium>-->
        <div class="container-fluid" id="container-fluid" v-if="showMainContainer">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  Header, Sidebar, Footer, Alert
} from '../components';
import systemService from '../services/systemService';
// import PaywallForAuthFreemium from '../components/PaywallForAuthFreemium.vue';
import NewVersionChecker from '../components/NewVersionChecker.vue';
import UserActivityWatcher from '../components/UserActivityWatcher.vue';

export default {
  name: 'Full',
  components: {
    Header,
    Sidebar,
    Footer,
    Alert,
    // PaywallForAuthFreemium,
    NewVersionChecker,
    UserActivityWatcher
  },
  data() {
    return { showLicenseAlert: false, showingLowOfLicensesAlert: false, showingLowOfLicensesPortalsAlert: false, showingLowOfLicensesAnalyticsAlert: false, showMainContainer: true  };
  },
  computed: {
    addHighZIndexForLicencesAlerts() {
      return this.showLicenseAlertOnSessionStart || this.showingLowOfLicensesAlert ||
        this.showingLowOfLicensesPortalsAlert || this.showingLowOfLicensesAnalyticsAlert
    },
    inHeaderLocationSelectBlockDataChangedTimestamp() {
      return this.$store.state.inHeaderLocationSelectBlockDataChangedTimestamp
    },
    isNewVersionCheckerEnabled() {
      return this.$store.state.isNewVersionCheckerEnabled;
    },
    isAppAuthFreemiumEnabled() {
      return this.$store.state.isAppAuthFreemiumEnabled;
    },
    isAdmin() {
      try {
        return this.$store.state.userData.role === 'admin';
      } catch (e) {
        return false;
      }
    },
    licences() {
      try {
        return JSON.parse(JSON.stringify(this.$store.state.license.license));
      } catch (e) {
        return undefined;
      }
    },
    window() {
      return window;
    },
    limit() {
      return JSON.parse(localStorage.getItem('userData')).active_until;
    },
    showLicenseAlertOnSessionStart() {
      const session = JSON.parse(sessionStorage.getItem('showLicenseAlert'));
      return this.showLicenseAlert && session;
    }
  },
  methods: {
    toggleMainContainerWrapper() {
      this.showMainContainer = false;
      this.$nextTick(()=>{
        this.showMainContainer = true;
      })
    },
    enableOrDisableAppAuthFreemiumMode() {
      // включаем или отлючаем режим с бесплатной авторизацией по звонку и пейволом на определенных раздалах
      try {
        if (process.env.VUE_APP_AUTH_FREEMIUM !== undefined && process.env.VUE_APP_AUTH_FREEMIUM !== '') {
          this.$store.commit('appAuthFreemiumEnabledSetTrue');
        } else {
          this.$store.commit('appAuthFreemiumEnabledSetFalse');
        }
      } catch (e) {
        this.$store.commit('appAuthFreemiumEnabledSetFalse');
      }
    },
    enableOrDisableNewVersionChecker() {
      // включаем или отлючаем режим проверки новых версий
      try {
        // if (window.VUEMODE === 'production') {
        //   this.$store.commit('newVersionCheckerEnabledSetTrue');
        // } else {
        //   this.$store.commit('newVersionCheckerEnabledSetFalse');
        // }
        this.$store.commit('newVersionCheckerEnabledSetTrue');
      } catch (e) {
        this.$store.commit('newVersionCheckerEnabledSetFalse');
      }
    },
    goToAccount() {
      this.closeLicenseAlert();
      // this.$router.push('settings/account');
      this.$router.push('settings/account').catch((err) => {
        // console.log(err);
        this.$nextTick(() => {
          this.$router.push('settings/account');
        });
      });
    },
    goToLicenses() {
      this.closeLowOfLicensesAlert();
      if (this.$route.name !== 'System') {
        this.$router.push({name: 'System'}).catch((err) => {
          // console.log(err);
          this.$nextTick(() => {
            this.$router.push({name: 'System'});
          });
        });
      }
    },
    goToLicensesPortals() {
      this.closeLowOfLicensesPortalsAlert();
      if (this.$route.name !== 'System') {
        this.$router.push({name: 'System'}).catch((err) => {
          // console.log(err);
          this.$nextTick(() => {
            this.$router.push({name: 'System'});
          });
        });
      }
    },
    goToLicensesAnalytics() {
      this.closeLowOfLicensesAnalyticsAlert();
      if (this.$route.name !== 'System') {
        this.$router.push({name: 'System'}).catch((err) => {
          // console.log(err);
          this.$nextTick(() => {
            this.$router.push({name: 'System'});
          });
        });
      }
    },
    closeLowOfLicensesAlert() {
      this.showingLowOfLicensesAlert = false;
    },
    closeLowOfLicensesPortalsAlert() {
      this.showingLowOfLicensesPortalsAlert = false;
    },
    closeLowOfLicensesAnalyticsAlert() {
      this.showingLowOfLicensesAnalyticsAlert = false;
    },
    openLowOfLicensesAlert() {
      if (this.isAdmin && this.$route.name !== 'System') {
        const nowTimestamp = Math.floor(+new Date() / 1000);
        const timeRange = 604800; // неделя
        // console.log(nowTimestamp);
        // console.log(timeRange);

        const alertShowedTimestamp = localStorage.getItem('lowOfLicensesAlertShowedTimestamp');
        // если еще вообще не показывали или показывали больше недели назад
        if (alertShowedTimestamp === null || nowTimestamp - timeRange >= alertShowedTimestamp) {
          if (this.licences !== undefined) {
            const diff = 1; // устанавливаем  при какой разнице между  точками и лимитом лицензий выводить уведомление
            const { current } = this.licences;
            const { limit } = this.licences;
            if (current !== 0 && limit !== 0 && (limit - current <= diff)) {
              // Eсли имеется мало лицензий, то показываем уведомление, и записывем в session storage
              // что уведомление показывалось  (чтобы больше его не показывать в ближайшее время)
              this.showingLowOfLicensesAlert = true;
              localStorage.setItem('lowOfLicensesAlertShowedTimestamp', nowTimestamp);
            }
          }
        }
      }
    },
    openLowOfLicensesPortalsAlert() {
      try {
        if (this.isAdmin && this.$route.name !== 'System') {
          const nowTimestamp = Math.floor(+new Date() / 1000);
          const timeRange = 604800; // неделя

          const alertShowedTimestamp = localStorage.getItem('lowOfLicensesPortalsAlertShowedTimestamp');
          // если еще вообще не показывали или показывали больше недели назад
          if (alertShowedTimestamp === null || nowTimestamp - timeRange >= alertShowedTimestamp) {
            if (this.licences !== undefined) {
              const diff = 1; // устанавливаем  при какой разнице между  порталами и лимитом лицензий по порталам выводить уведомление
              const { current_portal } = this.licences;
              const  {portal } = this.licences;
              if (current_portal !== undefined && portal !== undefined && (portal - current_portal <= diff)) {
                // Eсли имеется мало лицензий, то показываем уведомление, и записывем в session storage
                // что уведомление показывалось  (чтобы больше его не показывать в ближайшее время)
                this.showingLowOfLicensesPortalsAlert = true;
                localStorage.setItem('lowOfLicensesPortalsAlertShowedTimestamp', nowTimestamp);
              }
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    openLowOfLicensesAnalyticsAlert() {
      try {
        if (this.isAdmin && this.$route.name !== 'System') {
          const nowTimestamp = Math.floor(+new Date() / 1000);
          const timeRange = 604800; // неделя

          const alertShowedTimestamp = localStorage.getItem('lowOfLicensesAnalyticsAlertShowedTimestamp');
          // если еще вообще не показывали или показывали больше недели назад
          if (alertShowedTimestamp === null || nowTimestamp - timeRange >= alertShowedTimestamp) {
            if (this.licences !== undefined) {
              const diff = 1; // устанавливаем  при какой разнице между  аналитиками и лимитом лицензий по аналитикам выводить уведомление
              const { analytics } = this.licences;
              const  {current_analytics } = this.licences;
              if (current_analytics !== undefined && analytics !== undefined && (analytics - current_analytics <= diff)) {
                // Eсли имеется мало лицензий, то показываем уведомление, и записывем в session storage
                // что уведомление показывалось  (чтобы больше его не показывать в ближайшее время)
                this.showingLowOfLicensesAnalyticsAlert = true;
                localStorage.setItem('lowOfLicensesAnalyticsAlertShowedTimestamp', nowTimestamp);
              }
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    openAllLowOfLicensesAlerts() {
      // console.log('callback called')
      this.openLowOfLicensesAlert();
      this.openLowOfLicensesPortalsAlert();
      this.openLowOfLicensesAnalyticsAlert();
    },

    closeLicenseAlert() {
      sessionStorage.setItem('showLicenseAlert', false);
      this.showLicenseAlert = false;
    },
    openLicenseAlert() {
      const limit = this.$store.state.userData.active_until ?? this.limit;

      if (limit) {
        const now = Math.floor(+new Date() / 1000);
        const week = 604800;

        if (limit - now <= week) {
          if (sessionStorage.getItem('showLicenseAlert') === null) {
            sessionStorage.setItem('showLicenseAlert', true);
          }
          this.showLicenseAlert = true;
        }
      }
    }
  },
  created() {
    // systemService.getLicense(this);
    systemService.getLicenseForShowAlertsInFullViewComponent(this, this.openAllLowOfLicensesAlerts);
    this.openLicenseAlert();
    // this.openLowOfLicensesAlert();
    // this.openLowOfLicensesPortalsAlert();
    // this.openLowOfLicensesAnalyticsAlert();
    this.enableOrDisableAppAuthFreemiumMode();
    this.enableOrDisableNewVersionChecker();
  },
  watch: {
    inHeaderLocationSelectBlockDataChangedTimestamp() {
      // смотрим что в селекторе в хедере переключили локацию или withChilds
      // и перерисовываем раздел
      this.toggleMainContainerWrapper()
    },
    licences() {
      // иногда лицензии не успевают прогрузится к моменту отрисовки компонента
      // на этот случай следим за ними и запускам функцию для проверки и показа уведомления
      // когда они меняются
      // console.log('change')
      this.openLowOfLicensesAlert();
    }
  }
};
</script>

<style lang="scss" scoped>
.app-body--custom {
  /* margin-top: 70px; */
}
.main .container-fluid {
  padding: 30px;
  padding-top: 30px;
  height: calc(100vh - 60px); // footer 50px + header 60px
  overflow: auto;
}
.alert-license {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  height: 60px;
  align-items: center;
  margin: 0;
}

.main .container-fluid .container-fluid {
  padding: 0px;
}
</style>

<style lang="scss">
  .high-z-index-for-licences-alerts-in-header {
    z-index: 9999999 !important;
  }
.switcher-with-childs-for-use-in-filters label.switch {
  margin-bottom: 0 !important;
}
.switcher-with-childs-for-use-in-filters label.switch {
  margin: 0 !important;
}
.switcher-with-childs-for-use-in-filters label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-with-childs-for-use-in-filters span.switch-handle,
.switcher-with-childs-for-use-in-filters span.switch-label {
  height: 27px !important;
}
.switcher-with-childs-for-use-in-filters-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  margin-left: 5px !important;
  height: 27px !important;
}
.switcher-with-childs-for-use-in-filters-caption {
  /*margin: 0px !important;*/
 /* margin-left: -12px !important */
   margin-left: 5px;
  text-align: center;
}
html {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
// Прячем скролл html, который показывает footer
html::-webkit-scrollbar {
  /* chrome based */
  width: 0px; /* ширина scrollbar'a */
  background: transparent; /* опционально */
}
</style>
