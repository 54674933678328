import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';

export default {
  // getUsers(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingUsers');
  //   Vue.axios
  //     .post(`${window.RESTAPI || ''}/api/users`, {
  //       action: 'R',
  //       fields: [
  //         'base_location',
  //         'created_by',
  //         'created_at',
  //         'email',
  //         'first_login',
  //         'id',
  //         'last_login',
  //         'location',
  //         'password',
  //         'role',
  //         'username',
  //         'phone',
  //         'company',
  //         'description',
  //         'mac_mask',
  //         'active_until',
  //         'block'
  //       ]
  //     })
  //     .then(
  //       (response) => {
  //         if (response.data.status === 'success') {
  //           ctx.$store.commit('setUsersList', response.data.data.itemslist);
  //           ctx.$store.commit('toggleLoadingUsers');
  //           if (!props.onlyLoad) {
  //             ctx.usersList = response.data.data.itemslist;
  //             ctx.editVisible = false;
  //             ctx.newUserData = { role: 'admin', base_location: ctx.$store.state.userData.base_location };
  //             ctx.$validator.reset();
  //           }
  //         }
  //       },
  //       (err) => {
  //         // VueNotifications.error({ message: err });
  //
  //         // show errors in the console instead of the alert window
  //         if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //           (typeof window.isShowAxiosErrorInConsole === 'function') &&
  //           window.isShowAxiosErrorInConsole(err)) {
  //           window.showAxiosErrInConsole(err);
  //         } else {
  //           VueNotifications.error({ message: err });
  //         }
  //
  //         ctx.$store.commit('toggleLoadingUsers');
  //         ctx.error = err;
  //       }
  //     );
  // },
  isBeeline(ctx) {
    return ctx.$store.getters.isBeeline;
  },
  isNmsTest(ctx) {
    return ctx.$store.getters.isNmsTest;
  },
  // getUsers(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingUsers');
  //   const dataForReq = {
  //     action: 'R',
  //     fields: [
  //       'base_location',
  //       'created_by',
  //       'created_at',
  //       'email',
  //       'first_login',
  //       'id',
  //       'last_login',
  //       'location',
  //       'password',
  //       'role',
  //       'username',
  //       'phone',
  //       'company',
  //       'description',
  //       'mac_mask',
  //       'active_until',
  //       'block'
  //     ]
  //   };
  //   if (this.isBeeline(ctx) || this.isNmsTest(ctx)) {
  //     dataForReq.fields.push('bee_pro_ctn');
  //     dataForReq.fields.push('bee_pro_bis_id');
  //   }
  //   Vue.axios.post(`${window.RESTAPI || ''}/api/users`, dataForReq).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setUsersList', response.data.data.itemslist);
  //         ctx.$store.commit('toggleLoadingUsers');
  //         if (!props.onlyLoad) {
  //           ctx.usersList = response.data.data.itemslist;
  //           ctx.editVisible = false;
  //           ctx.newUserData = {
  //             role: 'admin',
  //             base_location: ctx.$store.state.userData.base_location
  //           };
  //           ctx.$validator.reset();
  //         }
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingUsers');
  //       ctx.error = err;
  //     }
  //   );
  // },

  getUsers(ctx, props = {}, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    ctx.$store.commit('toggleLoadingUsers');
    const dataForReq = {
      action: 'R',
      fields: [
        'base_location',
        'created_by',
        'created_at',
        'email',
        'first_login',
        'id',
        'last_login',
        'location',
        'password',
        'role',
        'username',
        'phone',
        'company',
        'description',
        'mac_mask',
        'active_until',
        'block'
      ]
    };
    if (this.isBeeline(ctx) || this.isNmsTest(ctx)) {
      dataForReq.fields.push('bee_pro_ctn');
      dataForReq.fields.push('bee_pro_bis_id');
    }
    if (!isUseLocationFilters) {
      dataForReq.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      dataForReq.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }

    if (ctx.filterByLocation?.location && isUseLocationFilters) {
      if (ctx.filterByLocation?.location === 'All locations') {
        dataForReq.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        dataForReq.with_childs = true;
      } else {
        dataForReq.location = ctx.filterByLocation.location;
        dataForReq.with_childs = ctx.filterByLocation.with_childs;
      }
    }
    Vue.axios.post(`${window.RESTAPI || ''}/api/users`, dataForReq).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setUsersList', response.data.data.itemslist);
          ctx.$store.commit('toggleLoadingUsers');
          if (!props.onlyLoad) {
            ctx.usersList = response.data.data.itemslist;
            ctx.editVisible = false;
            ctx.newUserData = {
              role: 'admin',
              base_location: ctx.$store.state.userData.base_location
            };
            ctx.$validator.reset();
          }
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingUsers');
        ctx.error = err;
      }
    );
  },

  createNewUser(ctx) {
    ctx.$validator
      .validateAll({
        'newuser-username': ctx.newUserData.username,
        'newuser-email': ctx.newUserData.email,
        'newuser-password': ctx.newUserData.password
      })
      .then((result) => {
        if (result) {
          ctx.$store.commit('toggleLoadingUsers');
          Vue.axios.post(`${window.RESTAPI || ''}/api/users`, { action: 'C', items: { 0: ctx.newUserData } }).then(
            (response) => {
              if (response.data.status === 'error') {
                VueNotifications.error({ message: response.data.description });
                ctx.isUserModalOpen = false;
              } else {
                VueNotifications.success({ message: ctx.$t('users.notificationUserCreatedSuccessfully') });
                ctx.isUserModalOpen = false;
                if (ctx.resetAllFilters && typeof ctx.resetAllFilters === 'function') {
                  ctx.resetAllFilters();
                }
              }
              ctx.$store.commit('toggleLoadingUsers');
              ctx.addUser = false;
              // ctx.getUsers();
            },
            (err) => {
              // VueNotifications.error({ message: err });

              // show errors in the console instead of the alert window
              if (
                Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
                typeof window.isShowAxiosErrorInConsole === 'function' &&
                window.isShowAxiosErrorInConsole(err)
              ) {
                window.showAxiosErrInConsole(err);
              } else {
                VueNotifications.error({ message: err });
              }

              ctx.$store.commit('toggleLoadingUsers');
              ctx.error = err;
            }
          );
        }
      });
  },
  createNewUserFromUsersDemoWizard(ctx) {
    ctx.$validator
      .validateAll({
        'newuser-username': ctx.newUserData.username,
        // 'newuser-email': ctx.newUserData.email,
        'newuser-password': ctx.newUserData.password
      })
      .then((result) => {
        if (result) {
          Vue.axios.post(`${window.RESTAPI || ''}/api/users`, { action: 'C', items: { 0: ctx.newUserData } }).then(
            (response) => {
              if (response.data.status === 'error') {
                if (response.data.description === 'Object with this creds already created') {
                  VueNotifications.error({ message: ctx.$t('usersDemoWizard.step2ErrorUserWithThisNameAlredyExists') });
                } else {
                  VueNotifications.error({ message: response.data.description });
                  // ctx.exitFromWizard();
                }
              } else {
                // console.log(response.data);
                ctx.$store.commit('setUsersDemoWizardStep2SavedUserObject', response.data.data.itemslist[0]);
                VueNotifications.success({ message: ctx.$t('users.notificationUserCreatedSuccessfully') });
                ctx.nextStep();
              }
            },
            (err) => {
              // VueNotifications.error({ message: err });

              // show errors in the console instead of the alert window
              if (
                Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
                typeof window.isShowAxiosErrorInConsole === 'function' &&
                window.isShowAxiosErrorInConsole(err)
              ) {
                window.showAxiosErrInConsole(err);
              } else {
                VueNotifications.error({ message: err });
              }

              ctx.error = err;
              // ctx.exitFromWizard();
            }
          );
        }
      });
  },
  updateUser(ctx) {
    ctx.$validator
      .validateAll({
        username: ctx.updatedUser.username,
        email: ctx.updatedUser.email,
        password: ctx.updatedUser.password
      })
      .then((result) => {
        if (result) {
          ctx.$store.commit('toggleLoadingUsers');
          const updateUserQuery = { action: 'U', items: { [ctx.userEditCurrentId]: ctx.updatedUser } };
          Vue.axios.post(`${window.RESTAPI || ''}/api/users`, updateUserQuery).then(
            (response) => {
              if (response.data.status === 'error') {
                VueNotifications.error({ message: response.data.description });
              } else {
                VueNotifications.success({ message: ctx.$t('users.notificationUserUpdatedSuccessfully') });
              }
              ctx.$store.state.editUserIndex = false;
              ctx.$store.commit('toggleLoadingUsers');
              ctx.getUsers();
            },
            (err) => {
              // VueNotifications.error({ message: err });

              // show errors in the console instead of the alert window
              if (
                Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
                typeof window.isShowAxiosErrorInConsole === 'function' &&
                window.isShowAxiosErrorInConsole(err)
              ) {
                window.showAxiosErrInConsole(err);
              } else {
                VueNotifications.error({ message: err });
              }

              ctx.$store.commit('toggleLoadingUsers');
              ctx.error = err;
            }
          );
        }
      });
  },
  deleteUser(ctx) {
    ctx.$store.commit('toggleLoadingUsers');
    const deleteUserQuery = { action: 'D', items: { [ctx.userEditCurrentId]: ctx.updatedUser } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/users`, deleteUserQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.deletingUserModal = false;
          VueNotifications.success({ message: ctx.$t('users.notificationUserDeletedSuccessfully') });
          if (!this.deletingUserModal) {
            ctx.getUsers();
          }
          ctx.$store.commit('toggleLoadingUsers');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.state.editUserIndex = false;
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingUsers');
        ctx.error = err;
      }
    );
  },

  getTokenForLoginAsSubordinate(subordinateName, subordinateId) {
    return Vue.axios.post(`${window.RESTAPI || ''}/auth/loginSlave`, {
      username: subordinateName,
      id: subordinateId
    });
  },
  blockUser(ctx, user) {
    const userForBlocking = JSON.parse(JSON.stringify(user));
    userForBlocking.block = true;
    const updateUserQuery = { action: 'U', items: { [userForBlocking.id]: userForBlocking } };
    ctx.$store.commit('userBlockOrUnblockRequestBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api/users`, updateUserQuery).then(
      (response) => {
        if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        } else {
          VueNotifications.success({ message: ctx.$t('users.notificationUserBlockedSuccessfully') });
          if (ctx.$store.state.editUserIndex !== false) {
            ctx.$store.state.editUserIndex = false;
          }
          ctx.getUsers();
        }
        ctx.$store.state.editUserIndex = false;
        ctx.$store.commit('userBlockOrUnblockRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('userBlockOrUnblockRequestEnd');
        ctx.error = err;
      }
    );
  },
  unblockUser(ctx, user) {
    const userForUnblocking = JSON.parse(JSON.stringify(user));
    userForUnblocking.block = false;
    const updateUserQuery = { action: 'U', items: { [userForUnblocking.id]: userForUnblocking } };
    ctx.$store.commit('userBlockOrUnblockRequestBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api/users`, updateUserQuery).then(
      (response) => {
        if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        } else {
          VueNotifications.success({ message: ctx.$t('users.notificationUserUnblockedSuccessfully') });
          if (ctx.$store.state.editUserIndex !== false) {
            ctx.$store.state.editUserIndex = false;
          }
          ctx.getUsers();
        }
        ctx.$store.state.editUserIndex = false;
        ctx.$store.commit('userBlockOrUnblockRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.$store.commit('userBlockOrUnblockRequestEnd');
        ctx.error = err;
      }
    );
  },
  getUsersWithBeeProBisId(ctx) {
    ctx.$store.commit('loadingUsersWithBeeProBisIdBegin');
    const dataForReq = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      fields: ['base_location', 'email', 'id', 'role', 'username', 'block', 'bee_pro_ctn', 'bee_pro_bis_id'],
      q: { bee_pro_bis_id: { $gt: 0 } }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/users`, dataForReq).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.usersWithBeeProBisId = response.data.data.itemslist;
          ctx.$store.commit('loadingUsersWithBeeProBisIdEnd');
        } else {
          ctx.$store.commit('loadingUsersWithBeeProBisIdEnd');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('loadingUsersWithBeeProBisIdEnd');
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingUsers');
        ctx.error = err;
      }
    );
  },

  getUsersWithBeeProCTN(ctx) {
    ctx.usersWithBeeProCTN = [];
    ctx.$store.commit('loadingUsersWithBeeProCTNBegin');
    const dataForReq = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      fields: ['base_location', 'email', 'id', 'role', 'username', 'block', 'bee_pro_ctn', 'bee_pro_bis_id'],
      q: { bee_pro_ctn: { $exists: true, $not: { $size: 0 } }, bee_pro_bis_id: { $gt: 0 } }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/users`, dataForReq).then(
      (response) => {
        if (response.data.status === 'success') {
          // ctx.usersWithBeeProCTN = response.data.data.itemslist;
          const usersWithCTN = [];
          for (const user of response.data.data.itemslist) {
            if (user.bee_pro_ctn !== '') {
              usersWithCTN.push(user);
            }
          }
          ctx.usersWithBeeProCTN = usersWithCTN;
          ctx.$store.commit('loadingUsersWithBeeProCTNEnd');
        } else {
          ctx.$store.commit('loadingUsersWithBeeProCTNEnd');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('loadingUsersWithBeeProCTNEnd');
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingUsers');
        ctx.error = err;
      }
    );
  }
};
