<template>
  <div class="app flex-row align-items-center">
    <!--    <div class="loader loader-backdrop-loginpage" v-if="isSpinnerShow"></div>-->
    <!--    <div v-if="isSpinnerShow"><Loader-spinner centered></Loader-spinner></div>-->
    <!--    <div class = 'locale-switcher-on-login-page'>-->
    <!--      <localeSwitcher @localeSwitcherLocaleSwitched = 'localeSwitched'></localeSwitcher>-->
    <!--    </div>-->
    <!--    <div class = "copyright-info-on-login-page">-->
    <!--      <div class="sidebar-time__copyright">-->
    <!--        <small  v-if="window.BUILD">Version: {{ window.BUILD }}</small>-->
    <!--        <small  v-if="window.BUILD_DATE">Build date: {{ window.BUILD_DATE }}</small>-->
    <!--        <small>-->
    <!--          <a href="http://wimark.com/" target="_blank">Wimark Systems</a>-->
    <!--          &copy; {{ new Date().getFullYear() }}-->
    <!--        </small>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class = "support-contacts-on-login-page">-->
    <!--        <small>{{ $t('login.support') }}-->
    <!--        <a href="mailto:info@wimark.com">info@wimark.com</a>-->
    <!--        </small>-->

    <!--    </div>-->
    <div class="footer-on-login-page">
      <div class="copyright-info-on-login-page" :class="{ 'hide-copyright-info-on-login-page': isBeeline }">
<!--        <div class="sidebar-time__copyright">-->
<!--          <small v-if="window.BUILD">Version: {{ window.BUILD }}</small>-->
<!--          <small v-if="window.BUILD_DATE">Build date: {{ window.BUILD_DATE }}</small>-->
<!--          <small>-->
<!--            <a href="http://wimark.com/" target="_blank">Wimark Systems</a>-->
<!--            &copy; {{ new Date().getFullYear() }}-->
<!--          </small>-->
<!--        </div>-->
      </div>
      <div class="support-contacts-on-login-page">
<!--        <small>-->
<!--          {{ $t('login.support') }}-->
<!--          <a :href="`mailto:${getContactEmail()}`">{{ getContactEmail() }}</a>-->
<!--        </small>-->
      </div>
      <div class="locale-switcher-on-login-page">
        <localeSwitcher @localeSwitcherLocaleSwitched="localeSwitched"></localeSwitcher>
      </div>
    </div>
    <div class="container">
      <main class="main" v-if="showInactivityLogoutInfoModal">
        <Modal v-if="showInactivityLogoutInfoModal"
               :value="showInactivityLogoutInfoModal"
               centered-position
               :backdrop="true"
               title="Inactive logout"
               class="modal-info"
               effect="fade/zoom"
               @close="closeInactivityLogoutInfoModal"
        >
          <h4 slot="modal-title" class="modal-title">
            {{$t('login.inactivityLogoutInfoModalHeader')}}</h4>
          <div>
            <p>{{$t('login.inactivityLogoutInfoModalText', {minutes:
              envInactivityTimeBeforeLogout})}}</p>
          </div>
          <div slot="modal-footer" class="modal-footer modal-btn-right">
            <button type="button" class="btn btn-secondary" @click="closeInactivityLogoutInfoModal">
              {{ $t('general.close') }}
            </button>
          </div>
        </Modal>
      </main>
      <div v-if="isResetPasswordShow === false" class="row justify-content-center">
        <div class="col-md-6">
          <div class="card-group mb-0">
            <div class="card p-2 block-in-login">
              <div class="card-block">
                <h1>{{ $t('login.loginHeader') }}</h1>
                <p class="text-muted">{{ $t('login.signHeader') }}</p>
                <div class="mb-1">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="icon-user"></i></span>
                    <input
                      name="username"
                      type="text"
                      v-model.trim="credentials.username"
                      class="form-control"
                      :placeholder="$t('login.username')"
                      data-vv-validate-on="input"
                      :class="{ input: true, 'is-danger': errors.has('username') }"
                      :disabled="loginLoading"
                      autocomplete="on"
                      @keyup.enter="submit"
                    />
                  </div>
                  <span v-show="errors.has('username')" class="help is-danger">{{ errors.first('username') }}</span>
                </div>
                <div class="mb-2">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="icon-lock"></i></span>
                    <input
                      type="password"
                      name="password"
                      v-model.trim="credentials.password"
                      class="form-control"
                      :placeholder="$t('login.password')"
                      :disabled="loginLoading"
                      data-vv-validate-on="input"
                      :class="{ input: true, 'is-danger': errors.has('password') }"
                      autocomplete="on"
                      @keyup.enter="submit"
                    />
                  </div>
                  <span v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</span>
                </div>
                <div class="row">
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-primary px-2 btn-in-login"
                      @click="submit()"
                      :disabled="loginLoading || errors.any() ? true : false"
                    >
                      <span :class="{ invisible: loading }">{{ $t('login.login') }}</span>
                      <span v-if="loading" class="loader loader--mini"></span>
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-primary px-2 float-right btn-in-login"
                      @click="showResetPassword"
                      :disabled="loginLoading"
                    >
                      <span :class="{ invisible: loading }">{{ $t('login.resetPassword') }}</span>
                      <span v-if="loading" class="loader loader--mini"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isResetPasswordShow === true" class="row justify-content-center">
        <div class="col-md-6">
          <div class="card-group mb-0">
            <div class="card p-2 block-in-login">
              <div class="card-block">
                <h1>{{ $t('login.resetPasswordHeader') }}</h1>
                <p class="text-muted">{{ $t('login.resetPasswordHeaderCaption') }}</p>
                <div class="mb-1">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="icon-user"></i></span>
                    <input
                      name="username"
                      type="text"
                      v-model.trim="userNameForPasswordReset"
                      class="form-control"
                      :placeholder="$t('login.username')"
                      data-vv-validate-on="input"
                      :class="{ input: true, 'is-danger': errors.has('username') }"
                      :disabled="loginLoading"
                      autocomplete="on"
                    />
                  </div>
                  <span v-show="errors.has('username')" class="help is-danger">{{ errors.first('username') }}</span>
                </div>
                <div class="row">
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-primary px-2 btn-in-login"
                      @click="hideResetPassword"
                      :disabled="loginLoading"
                    >
                      <span :class="{ invisible: loading }">{{ $t('login.resetPasswordPageBackButton') }}</span>
                      <span v-if="loading" class="loader loader--mini"></span>
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-primary px-2 float-right btn-in-login"
                      @click="submitPasswordReset()"
                      :disabled="loginLoading || !isUserNameForPasswordResetLength"
                    >
                      <span :class="{ invisible: loading }">{{ $t('login.resetPasswordPageSendButton') }}</span>
                      <span v-if="loading" class="loader loader--mini"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
// import VeeValidate from 'vee-validate';
import auth from '../../auth/auth';
// import Router from '../../router';
import localeSwitcher from '../../components/locale-switcher.vue';
import helpers from '../../helpers';
import Modal from '../../components/Modal.vue';

export default {
  name: 'Login',
  components: {
    localeSwitcher,
    Modal
  },
  data() {
    return {
      userNameForPasswordReset: '',
      isResetPasswordShow: false,
      isSpinnerShow: false,
      credentials: {
        username: '',
        password: ''
      },
      error: '',
      loginLoading: false
    };
  },
  methods: {
    closeInactivityLogoutInfoModal() {
      this.$store.commit('setShowInactivityLogoutInfoModal', false)
    },
    getContactEmail() {
      return helpers.getContactEmail();
    },
    hideResetPassword() {
      this.isResetPasswordShow = false;
    },
    showResetPassword() {
      this.isResetPasswordShow = true;
    },
    localeSwitched() {
      this.isSpinnerShow = true;
    },
    submit() {
      this.$validator.validateAll({ username: this.credentials.username, password: this.credentials.password }).then(
        (result) => {
          if (result) {
            const credentials = {
              username: this.credentials.username,
              password: this.credentials.password
            };
            this.loginLoading = true;
            auth.login(this, credentials).then(
              (response) => {
                if (response.data.status === 'error') {
                  VueNotifications.error({ message: response.data.description });
                  this.loginLoading = false;
                } else {
                  localStorage.setItem('id_token', response.data.data.items.token);
                  localStorage.setItem('access_token', response.data.data.items.token);
                  this.axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.items.token}`;
                  if (response.data.data.items.photo === '') {
                    response.data.data.items.photo = '/static/img/avatars/user.svg';
                  }
                  this.$store.commit('setUserData', response.data.data.items);
                  if (this.$store.state.userData.role === 'hotel_employee') {
                    this.$router.push({ path: '/portal/hotel-vouchers' });
                  } else if (this.$store.state.userData.role === 'advertising') {
                    this.$router.push({ path: '/portal/ads' });
                  } else if (this.isPortalOnly) {
                    this.$router.push({ path: '/portal/dashboard' });
                  } else {
                    this.$router.push({ path: '/dashboard' });
                  }

                  this.loginLoading = false;
                }
              },
              (err) => {
                VueNotifications.error({ message: err });
                this.error = err;
                this.loginLoading = false;
              }
            );
          }
        },
        () => {
          //                    console.warn('Validation Fialed')
        }
      );
    },
    submitPasswordReset() {
      this.$validator.validateAll({ username: this.userNameForPasswordReset }).then(
        (result) => {
          if (result) {
            const username = this.userNameForPasswordReset;
            this.loginLoading = true;
            auth.resetPasswordGenerate(this, username).then(
              (response) => {
                if (response.data.status === 'error') {
                  this.loginLoading = false;
                  VueNotifications.error({ message: response.data.description });
                } else {
                  this.loginLoading = false;
                  VueNotifications.success({ message: `${this.$t('login.resetPasswordPageSuccessMessage')}` });
                  this.userNameForPasswordReset = '';
                }
              },
              (err) => {
                VueNotifications.error({ message: err });
                this.error = err;
                this.loginLoading = false;
              }
            );
          }
        },
        () => {
          //                    console.warn('Validation Fialed')
        }
      );
    }
  },
  computed: {

    envInactivityTimeBeforeLogout() {
      return window.LOGOUT_AFTER_USER_INACTIVITY_TIME;
    },
    showInactivityLogoutInfoModal() {
      return this.$store.state.showInactivityLogoutInfoModal
    },
    isBeeline() {
      try {
        if (process.env.VUE_APP_CUSTOM_BUILD === 'BEELINE-WIMARK') {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isPortalOnly() {
      if (window.PORTAL_ONLY) {
        return true;
      }
      return false;
    },
    isUserNameForPasswordResetLength() {
      if (this.userNameForPasswordReset.length > 0) {
        return true;
      }
      return false;
    },
    window() {
      return window;
    },
    loading() {
      return this.loginLoading;
    }
  },
  created() {
    this.isSpinnerShow = false;
  },
  mounted() {
    // убираем прелоадер, которй прописан в /static/index.html
    document.body.classList.add('loaded-preloader');
    document.body.classList.add('loaded_hiding-preloader');
  }
};
</script>

<style lang="scss" scoped>
.hide-copyright-info-on-login-page {
  visibility: hidden;
}
.locale-switcher-on-login-page {
  position: relative;
  bottom: 1px;
  right: 1px;
}
.copyright-info-on-login-page {
}
.loader-backdrop-loginpage {
  position: absolute;
  top: 0px;
}
.support-contacts-on-login-page {
}
.support-contacts-on-login-page a {
  display: inline;
}
.footer-on-login-page {
  position: fixed;
  left: 5px;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
</style>
<style scoped lang="scss">
/*стили для cкругления углов и доавления теней ( модернизации внешнего вида ) (задача WNE-2454) */

/*перменные*/
$border-radius-for-new-style-blocks-in-login: 25px;
$border-radius-for-new-style-btns-in-login: 10px;

/*создаем  наборы свойств для дальнейшего наследования*/
/*кнопки*/
%new-modern-style-btn-in-login {
  border-radius: $border-radius-for-new-style-btns-in-login !important;
  transition: 0.2s ease-in-out;
  /*box-shadow: 3px 3px 4px rgba(0, 0, 0, .15);*/
}

/*блоки*/
%new-modern-style-block-in-login-shadow {
  box-shadow: 0 30px 30px -20px rgb(0 0 0 / 20%);
}

%new-modern-style-block-in-login-border-radius {
  border-radius: $border-radius-for-new-style-blocks-in-login;
}

.btn-in-login {
  @extend %new-modern-style-btn-in-login;
}

.btn-in-login:hover {
  @extend %new-modern-style-btn-in-login;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-in-login:active {
  @extend %new-modern-style-btn-in-login;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1);
}
.block-in-login {
  @extend %new-modern-style-block-in-login-shadow;
  @extend %new-modern-style-block-in-login-border-radius;
}
</style>
