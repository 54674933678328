<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class="card h-100">
        <div class="card-block">
          <div class="h4 m-0">
            {{ $t('general.step3') }}
            <button
              type="button"
              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"
              @click="lastStepButtonClickHandler"
            >
              {{ $t('wlans.wizardSkipButton') }}
              <info :content="$t('wlans.wizardSkipButtonInfo')" />
            </button>
          </div>
          <span class="text-muted step-caption-in-wlan-wizard" :class="{ 'not-for-cisco': window.CISCO }">
            {{ $t('wlans.networkEncapsulation') }}
          </span>
          <div v-if="window.CISCO">
            <small class="text-danger p-0" v-if="window.CISCO">* {{ $t('wlans.notAvailableForCisco') }}</small>
          </div>
          <form action="">
            <div name="wsettings mt-1">
              <div>
                <div class="row mt-2">
                  <div class="form-group col-sm-6 mb-0">
                    <Switch-component
                      v-model="newWlanData.nat"
                      @input="$validator.reset()"
                      :label="'NAT'"
                      id="nat"
                      @change="checkNat"
                    />
                  </div>
                </div>
                <div v-if="newWlanData.nat === true && newWlanData.hasOwnProperty('nat_network')">
                  <span class="text-muted">{{ $t('wlans.natNetworkInfoMsg') }}</span>
                  <div class="form-group mt-1" v-show="newWlanData.nat === true">
                    <label for="nat_network_ipaddr">
                      {{ $t('wlans.natNetworkIpaddr') }}
                    </label>
                    <input
                      type="text"
                      :key="'nat_network_ipaddr'"
                      id="nat_network_ipaddr"
                      autocomplete="off"
                      name="nat_network_ipaddr"
                      v-validate="'ipv4ForNatNetwork'"
                      data-vv-validate-on="change"
                      :class="{ input: true, 'is-danger': errors.has('nat_network_ipaddr') }"
                      :placeholder="$t('wlans.natNetworkIpaddrPlaceholder')"
                      class="form-control"
                      v-model="newWlanData.nat_network.ipaddr"
                    />
                    <span v-show="errors.has('nat_network_ipaddr')" class="help is-danger">
                      {{ errors.first('nat_network_ipaddr') }}
                    </span>
                  </div>
                  <div class="form-group mt-1 mb-2" v-show="newWlanData.nat === true">
                    <label for="nat_network_netmask">
                      {{ $t('wlans.natNetworkNetmask') }}
                    </label>
                    <input
                      type="text"
                      :key="'nat_network_netmask'"
                      id="nat_network_netmask"
                      autocomplete="off"
                      name="nat_network_netmask"
                      v-validate="'maskForNatNetwork'"
                      data-vv-validate-on="change"
                      :class="{ input: true, 'is-danger': errors.has('nat_network_netmask') }"
                      :placeholder="$t('wlans.natNetworkNetmaskPlaceholder')"
                      class="form-control"
                      v-model="newWlanData.nat_network.netmask"
                    />
                    <span v-show="errors.has('nat_network_netmask')" class="help is-danger">
                      {{ errors.first('nat_network_netmask') }}
                    </span>
                  </div>
                </div>

                <div v-if="!newWlanData.nat">
                  <div class="row mt-2">
                    <div class="form-group col-sm-8 mb-0">
                      <Switch-component
                        v-model="newWlanData.tunneling"
                        :disabled="isNatActive"
                        :label="$t('wlans.tunneling')"
                        @change="newWlanData.default_tunnel = ''"
                        @input="$validator.reset()"
                        id="tunneling"
                      />
                    </div>
                  </div>
                  <!--                              Proto fields for EoGRE -->
                  <div class="form-group mt-1 mb-1" v-if="newWlanData.tunneling && newWlanData.hasOwnProperty('proto')">
                    <label for="proto" class="">{{ $t('wlans.proto') }}</label>
                    <select
                      @change="changeProtoInSelector"
                      class="form-control"
                      name="proto"
                      v-model="newWlanData.proto"
                      id="proto"
                      :class="{
                        input: true,
                        'select-disabled': !newWlanData.tunneling
                      }"
                    >
                      <!--                                  <option value="" disabled selected></option>-->
                      <option :value="proto.value" :key="proto.value" v-for="proto in typesOfProto">
                        {{ proto.name }}
                      </option>
                    </select>
                  </div>


                  <div
                    class="form-group mb-2"
                    v-if="
                      hostsList[0] && interfacesWithDetailsFromAllHostsAsArray.length && newWlanData.tunneling && newWlanData.proto !== 'gretap'
                    "
                  >
                    <label for="default_tunnel" class="">{{ $t('wlans.interface') }}</label>
<!--                    <Multiselect-->
<!--                      :customLabel="customLabelForTunnelMiltiselect"-->
<!--                      v-model="newWlanData.default_tunnel"-->
<!--                      :multiple="false"-->
<!--                      :options="interfacesWithDetailsFromAllHostsAsArray.map(interfaceWithdetails=>interfaceWithdetails.name) || []"-->
<!--                      :closeOnSelect="true"-->
<!--                      :hideSelected="true"-->
<!--                      v-validate="'required'"-->
<!--                      :data-vv-as="$t('wlans.interface')"-->
<!--                      id="default_tunnel"-->
<!--                      data-vv-name="Interface"-->
<!--                      :placeholder="$t('wlans.selectSingleInterface')"-->
<!--                      :selectLabel="$t('aps.wlansSelectorTip')"-->
<!--                      :disabled="!newWlanData.tunneling"-->
<!--                      :class="{ 'multiselect-disabled': !newWlanData.tunneling}"-->
<!--                      :allowEmpty="true"-->
<!--                    >-->
<!--                      <template v-slot:noOptions>-->
<!--                        {{ $t('general.noDataToDisplay') }}-->
<!--                      </template>-->
<!--                      <template v-slot:singleLabel>-->
<!--                        {{labelForSelectedInTunnelMiltiselect()}}-->
<!--                      </template>-->
<!--                      <template v-slot:noResult>-->
<!--                        {{ $t('general.noResultForSearch') }}-->
<!--                      </template>-->
<!--                    </Multiselect>-->
                    <Multiselect
                      label="nameWithType"
                      trackBy="name"
                      v-model="newWlanDataDefaultTunnel"
                      :multiple="false"
                      @input="selectWlanDefaultTunnel"
                      :options="interfacesWithDetailsFromAllHostsAsArray ||  []"
                      :closeOnSelect="true"
                      :hideSelected="true"
                      v-validate="'required|multiSelectWithInterfaceNotEmpty'"
                      :data-vv-as="$t('wlans.interface')"
                      id="default_tunnel"
                      data-vv-name="Interface"
                      :placeholder="$t('wlans.selectSingleInterface')"
                      :selectLabel="$t('aps.wlansSelectorTip')"
                      :disabled="!newWlanData.tunneling"
                      :class="{ 'multiselect-disabled': !newWlanData.tunneling}"
                      :allowEmpty="true"
                    >
                      <template v-slot:noOptions>
                        {{ $t('general.noDataToDisplay') }}
                      </template>
<!--                      <template v-slot:singleLabel>-->
<!--                        {{labelForSelectedInTunnelMiltiselect()}}-->
<!--                      </template>-->
                      <template v-slot:noResult>
                        {{ $t('general.noResultForSearch') }}
                      </template>
                    </Multiselect>
                    <a v-if="!(!newWlanData.tunneling)" href="#"
                       class="ml-q "
                       @click.prevent="clearDefaultTunnel">
                      <span>{{ $t('general.clear') }}</span>
                    </a>
                    <span v-show="errors.has('Interface')" class="help is-danger">
                      {{ errors.first('Interface') }}
                    </span>
                    <small v-show="newWlanData.tunneling" class="help is-danger">
                      {{ $t('wlans.bridge') }}
                    </small>
                  </div>

                  <div v-else-if="(!hostsList[0] || !interfacesWithDetailsFromAllHostsAsArray.length)">
                    {{ $t('wlans.noInterfaces') }}
                  </div>




<!--                  <div-->
<!--                    class="form-group mb-2"-->
<!--                    v-if="-->
<!--                      hostsList[0] && hostsList[0].interfaces && newWlanData.tunneling && newWlanData.proto !== 'gretap'-->
<!--                    "-->
<!--                  >-->
<!--                    <label for="default_tunnel" class="">{{ $t('wlans.interface') }}</label>-->
<!--                    <select-->
<!--                      class="form-control"-->
<!--                      name="hosts_interface"-->
<!--                      v-model="newWlanData.default_tunnel"-->
<!--                      id="default_tunnel"-->
<!--                      data-vv-name="Interface"-->
<!--                      v-validate="'required'"-->
<!--                      :disabled="!newWlanData.tunneling"-->
<!--                      :class="{-->
<!--                        input: true,-->
<!--                        'is-danger': errors.has('Interface'),-->
<!--                        'select-disabled': !newWlanData.tunneling-->
<!--                      }"-->
<!--                    >-->
<!--                      <option value="" disabled selected>{{ $t('wlans.selectInterface') }}</option>-->
<!--                      <option-->
<!--                        v-for="hostInterface in hostsList[0].interfaces"-->
<!--                        :value="hostInterface"-->
<!--                        :key="hostInterface"-->
<!--                      >-->
<!--                        {{ hostInterface }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                    <span v-show="errors.has('Interface')" class="help is-danger">-->
<!--                      {{ errors.first('Interface') }}-->
<!--                    </span>-->
<!--                    <small v-show="newWlanData.tunneling" class="help is-danger">-->
<!--                      {{ $t('wlans.bridge') }}-->
<!--                    </small>-->
<!--                  </div>-->

<!--                  <div v-else-if="(!hostsList[0] || !hostsList[0].interfaces)">-->
<!--                    {{ $t('wlans.noInterfaces') }}-->
<!--                  </div>-->
                  <!--                              peerAddress aka EoGRE server address-->
                  <div
                    class="form-group mt-1"
                    v-if="newWlanData.hasOwnProperty('peer_address') && newWlanData.proto === 'gretap' && newWlanData.tunneling"
                  >
                    <label for="peerAddress">
                      {{ $t('wlans.peerAddress') }}
                    </label>
                    <input
                      key="peerAddress"
                      type="text"
                      :data-vv-as="$t('wlans.peerAddress')"
                      id="peerAddress"
                      name="peerAddress"
                      :class="{ input: true, 'is-danger': errors.has('peerAddress') }"
                      v-validate="'required|addressForEoGREServer'"
                      :placeholder="$t('wlans.peerAddressPlaceholder')"
                      data-vv-validate-on="change"
                      class="form-control"
                      v-model="newWlanData.peer_address"
                      :disabled="isNatActive"
                    />
                    <span v-show="errors.has('peerAddress')" class="help is-danger">
                      {{ errors.first('peerAddress') }}
                    </span>
                  </div>

                  <div class="form-group mt-1">
                    <label for="vlan">
                      VLAN
                      <span class="text-muted" v-if="!newWlanData.vlan">{{ $t('wlans.vlanNative') }}</span>
                    </label>
                    <input
                      type="text"
                      id="vlan"
                      name="vlan"
                      :class="{ input: true, 'is-danger': errors.has('vlan') }"
                      v-validate="'numeric|between:0,4094'"
                      :placeholder="$t('wlans.enterVLANPlaceHolder')"
                      class="form-control"
                      v-model="newWlanData.vlan"
                      :disabled="isNatActive"
                    />
                    <span v-show="errors.has('vlan')" class="help is-danger">
                      {{ errors.first('vlan') }}
                    </span>
                  </div>
                </div>
                <div v-if="newWlanData.nat">
                  <div class="row mt-2">
                    <div class="form-group col-sm-6 mb-0">
                      <Switch-component
                        v-model="newWlanData.firewall.nat_access"
                        :label="$t('wlans.SSHWebAccess')"
                        @input="$validator.reset()"
                        id="nat-access"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
        <div class="buttons-block-warpper">
          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">
            {{ $t('general.next') }}
          </button>
          <!--          <button type="button" class="btn btn-outline-info float-right mr-h"-->
          <!--                  @click="lastStepButtonClickHandler">-->
          <!--            {{ $t('wlans.wizardSkipButton') }} <info :content="$t('wlans.wizardSkipButtonInfo')"/>-->
          <!--          </button>-->
          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">
            {{ $t('general.back') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchComponent from '../../../components/Universal/Switch-component.vue';
import wlanService from '../../../services/wlanService';
import Info from '../../../components/Universal/info-icon.vue';
import Multiselect from 'vue-multiselect';
import helpers from "../../../helpers";

export default {
  name: 'Step3',
  components: {
    SwitchComponent,
    Info,
    Multiselect
  },
  data() {
    return {
      typesOfProto: [
        {
          name: 'l2tpv3',
          value: 'l2tpv3'
        },
        {
          name: 'gretap',
          value: 'gretap'
        }
      ],
      newWlanDataDefaultTunnel: '',
      isNatActive: false,
      newWlanData: {
        // default_tunnel: '',
        // nat: false,
        vlan: '',
        radius: ''
        // tunneling: false,
        // firewall: {
        //   nat_access: false,
        // },
      }
    };
  },
  computed: {
    interfacesWithDetailsFromAllHostsAsArray() {
      return Object.values(this.createInterfacesWithDetailsFromAllHostsList(this.hostsList));
    },
    interfacesWithDetailsFromAllHostsAsObjectWithIdKeys() {
      return this.createInterfacesWithDetailsFromAllHostsList(this.hostsList);
    },
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    window() {
      return window;
    },
    hostsList: {
      get() {
        return this.$store.state.hostsList;
      },
      set() {}
    }
  },
  methods: {
    customLabelForTunnelMiltiselect(tunnelName) {
      try {
        return this.createTunnelCaptionByName(tunnelName)
      } catch (e) {
        return tunnelName;
      }
    },
    selectWlanDefaultTunnel(payload) {
      // console.log('payload', payload)
      // this.newWlanData.default_tunnel = payload.name;
      this.$set(this.newWlanData, 'default_tunnel', payload.name);
    },
    labelForSelectedInTunnelMiltiselect() {
      const tunnelName = this.newWlanData.default_tunnel
      return this.createTunnelCaptionByName(tunnelName);
    },
    createTunnelCaptionByName(tunnelName) {
      try {
        return `${tunnelName} (${this.$t('general.type').toLowerCase()} ${this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[tunnelName].type})`
      } catch (e) {
        return tunnelName
      }
    },
    clearDefaultTunnel() {
      this.newWlanData.default_tunnel = '';
      // this.newWlanDataDefaultTunnel = ''
      this.$set(this, 'newWlanDataDefaultTunnel', '');
      this.$store.commit('setNewWlanDataDefaultTunnelClear');
    },
    // createInterfacesWithDetailsFromAllHostsList(hosts) {
    //   return helpers.createInterfacesWithDetailsFromAllHostsList(hosts)
    //   // try {
    //   //   const result = {}
    //   //   for (const host of hosts) {
    //   //     if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
    //   //       Array.isArray(host.interfaces_details) &&
    //   //       host.interfaces_details.length > 0) {
    //   //       for (const interfaceWithDetails of host.interfaces_details) {
    //   //         if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
    //   //           interfaceWithDetails.name !== '' &&
    //   //           !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
    //   //           result[interfaceWithDetails.name] = interfaceWithDetails;
    //   //         }
    //   //       }
    //   //     }
    //   //   }
    //   //   return result;
    //   // } catch (e) {
    //   //   console.log(e)
    //   //   return {}
    //   // }
    // },
    createInterfacesWithDetailsFromAllHostsList(hosts) {
      return helpers.createInterfacesWithDetailsAndNameWithTypeFromAllHostsList(this, hosts)
      // try {
      //   const result = {}
      //   for (const host of hosts) {
      //     if (Object.hasOwnProperty.call(host, 'interfaces_details') &&
      //       Array.isArray(host.interfaces_details) &&
      //       host.interfaces_details.length > 0) {
      //       for (const interfaceWithDetails of host.interfaces_details) {
      //         if (Object.prototype.hasOwnProperty.call(interfaceWithDetails, 'name') &&
      //           interfaceWithDetails.name !== '' &&
      //           !Object.prototype.hasOwnProperty.call(result, interfaceWithDetails.name)) {
      //           result[interfaceWithDetails.name] = interfaceWithDetails;
      //         }
      //       }
      //     }
      //   }
      //   return result;
      // } catch (e) {
      //   console.log(e)
      //   return {}
      // }
    },
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    changeProtoInSelector() {
      // если выбриаем proto = gretap то сбрасываем тунель
      if (this.newWlanData.hasOwnProperty('proto') && this.newWlanData.proto === 'gretap') {
        // console.log('clear tunnel')
        this.newWlanData.default_tunnel = '';
        // this.newWlanDataDefaultTunnel = '';
        this.$set(this, 'newWlanDataDefaultTunnel', '');
        this.$store.commit('setNewWlanDataDefaultTunnelClear');
      }
    },
    checkNat() {
      if (!this.newWlanData) {
        return;
      }

      if (this.newWlanData.nat) {
        this.newWlanData.tunneling = false;
        this.newWlanData.vlan = '';
        this.isNatActive = true;
      } else {
        this.newWlanData.firewall.nat_access = false;
        this.isNatActive = false;
      }
    },
    setNewWlanData(event, toLastStepClicked = false) {
      if (this.newWlanData.vlan) {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              if (this.newWlanData.hidden === 'true') {
                this.newWlanData.hidden = true;
              }
              if (this.newWlanData.hidden === 'false') {
                this.newWlanData.hidden = false;
              }
              this.$store.commit('setNewWlanData', this.newWlanData);
              // this.$parent.nextStep();
              if (toLastStepClicked === false) {
                this.$parent.nextStep();
              } else {
                // this.$parent.nextStep(true);
                this.$parent.lastStep();
              }
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
      } else {
        this.$validator.validateAll().then(
          (result) => {
            if (result) {
              if (this.newWlanData.hidden === 'true') {
                this.newWlanData.hidden = true;
              }
              if (this.newWlanData.hidden === 'false') {
                this.newWlanData.hidden = false;
              }
              this.$store.commit('setNewWlanData', this.newWlanData);
              // this.$parent.nextStep();
              if (toLastStepClicked === false) {
                this.$parent.nextStep();
              } else {
                // this.$parent.nextStep(true);
                this.$parent.lastStep();
              }
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
      }
    }
  },
  created() {
    wlanService.getHosts(this);
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));
    const currentDefaultTunnel = this.newWlanData.default_tunnel;
    // console.log('currentDefaultTunnel', currentDefaultTunnel)
    // this.newWlanDataDefaultTunnel = '';
    this.$set(this, 'newWlanDataDefaultTunnel', '');
    // this.$store.commit('setNewWlanDataDefaultTunnelClear');
    if (currentDefaultTunnel && currentDefaultTunnel !== '') {
      const currentDefaultTunnelAsObject = this.interfacesWithDetailsFromAllHostsAsObjectWithIdKeys[currentDefaultTunnel];
      if (currentDefaultTunnelAsObject) {
        this.newWlanDataDefaultTunnel = JSON.parse(JSON.stringify(currentDefaultTunnelAsObject));
      } else {
        this.newWlanDataDefaultTunnel = {
          name: this.newWlanData.default_tunnel,
          nameWithType: this.newWlanData.default_tunnel
        }
      }
    }

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
