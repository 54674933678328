<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 mb-xs mb-2">{{ $t('easyInstallWizard.step2Header') }}</div>
          <div class="form-group mt-h">
            <!--              Name-->
            <label class="required-mark" for="name">{{ $t('easyInstallWizard.step2FieldPageName') }}</label>
            <input
              type="text"
              name="name"
              id="name"
              :placeholder="$t('easyInstallWizard.step2FieldPageName')"
              :data-vv-as="$t('easyInstallWizard.step2FieldPageName')"
              v-validate="'required'"
              class="form-control"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="updatedPage.name"
            />
            <span v-show="errors.has('name')" class="help is-danger mt-0 mb-1">
              {{ errors.first('name') }}
            </span>
          </div>
          <!--            Logo-->
          <label for="logo">{{ $t('pageprofile.logo') }}</label>
          <div class="mb-1">
            <div class="logo-img">
              <div @mouseover="logoTopChangerMouseOver" class="logo-top-change-wrapper">
                <div @mouseleave="logoTopChangerMouseLeave" v-show="logoTopChangerShow" class="logo-top-change">
                  <div class="img-recommendations">{{ $t('imgAndVideoRecommendations.pagesLogoTop') }}</div>
                  <div class="form-group mt-h d-flex">
                    <div class="mr-auto">
                      <div class="text-center">
                        <h5 class="logo-changers-color d-block">{{ $t('pageprofile.logo') }}</h5>
                      </div>
                      <div class="d-flex align-items-start">
                        <div>
                          <span class="fileUpload btn btn-primary">
                            <i class="icon-camera userphoto-icon big"></i>
                            <!--                        <span :class="{ invisible: logoMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
                            <span v-if="logoMuteUpload" class="loader loader--mini"></span>
                            <input
                              id="logo-upload-in-preview"
                              name="files"
                              @change="uploadImageToServer('logo', 'logo-in-preview')"
                              type="file"
                              class="upload logo-in-preview"
                            />
                          </span>
                          <small class="logo-changers-photo-formats-to-left logo-changers-color text-muted d-block">
                            (jpg, png)
                          </small>
                        </div>
                        <button
                          :disabled="!updatedPage.interface.logo && !updatedPage.interface.logo_url"
                          class="ml-h btn btn-danger"
                          @click="(updatedPage.interface.logo = ''), (updatedPage.interface.logo_url = '')"
                        >
                          <!--                      {{ $t('general.delete') }}-->
                          <i class="fa fa-remove text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                v-if="updatedPage.interface.logo || updatedPage.interface.logo_url"
                :src="`${baseURL}/api/portal${
                  isUploaded.logo
                    ? `/static/${updatedPage.interface.logo}`
                    : `-preview/${updatedPage.url_id}/${updatedPage.interface.logo_url}`
                }`"
                alt="Upload new"
                class="img-responsive img-thumbnail img-thumbnail-in-modal"
                id="logo"
              />
              <img
                v-else
                src="/static/img/no-image-for-portal-pages.png"
                alt="Upload new"
                class="img-responsive img-thumbnail img-thumbnail-in-modal"
              />
            </div>
          </div>
          <!--            colors and themes-->
          <div class="form-group">
            <Portal-palette
              :selected-color="updatedPage.interface.theme_color"
              @select-color="handleSelectColor"
              :selected-type="updatedPage.interface.theme_type"
              @select-type="handleSelectType"
              class="portal-palette-wrapper-in-new-user-wizard"
            />
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <!--              <button-->
              <!--                type="button"-->
              <!--                class="btn btn-outline-secondary float-left"-->
              <!--                :disabled="$parent.step4SavedProfileObject !== false"-->
              <!--                @click="prevStep"-->
              <!--              >-->
              <!--                {{ $t('general.back') }}-->
              <!--              </button>-->
            </div>
            <div>
              <button
                type="button"
                class="btn btn-outline-primary float-right"
                @click="handleSavePage"
                :disabled="errors.any()"
              >
                {{ $t('easyInstallWizard.step2NextButton') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
// import VueNotifications from 'vue-notifications';
// import locationService from '../../services/locationService';
// import usersService from '../../services/usersService';
import PortalPalette from '../../components/Portal/portal-palette.vue';
import portalPagesService from '../../services/portalPagesService';
import EasyInstallWizardHelpers from './EasyInstallWizardHelpers';

export default {
  name: 'EasyInstallWizardstep2',
  components: {
    PortalPalette
  },
  data() {
    return {
      baseURL: `${window.RESTAPI ? '' : `${window.location.protocol}//`}${
        window.RESTAPI ? window.RESTAPI : window.location.host
      }`,
      isUploaded: {
        logo: false,
        logo_footer: false,
        background: false
      },
      logoTopChangerShow: false,
      logoMuteUpload: false,
      updatedPage: {
        base_location: '',
        name: '',
        description: '',
        title: '',
        interface: {
          logo: '',
          logo_footer: '',
          background: '',
          theme_type: 'light',
          theme_color: '',
          theme: window.CUSTOM_BUILD === 'BEELINE-WIMARK' ? 'default-beeline' : 'default'
        },
        // agreement: '',
        support: ''
      }
    };
  },
  computed: {},
  methods: {
    handleSavePage() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // console.log(this.newUserData);
          this.createNewPage(this.updatedPage);
        }
      });
    },
    createNewPage(Page) {
      // this.updatedPage.base_location = this.$parent.step1SavedLocationObject.id;
      this.updatedPage.base_location = this.$store.state.easyInstallWizardStepsData.step1locationId;
      // console.log(this.updatedPage);
      // usersService.createNewUserFromUsersDemoWizard(this);
      if (!this.hasAgreement) {
        Page.agreement = '';
      }
      portalPagesService.createPageFromEasyInsatllWizard(this, Page);
    },
    logoTopChangerMouseOver() {
      // console.log('over');
      this.logoTopChangerShow = true;
    },
    logoTopChangerMouseLeave() {
      // console.log('over');
      if (!this.logoMuteUpload) {
        this.logoTopChangerShow = false;
      }
    },
    uploadImageToServer(type, selector = '') {
      // console.log('checked file!')
      if (selector === '') {
        selector = type;
      }
      this[`${type}MuteUpload`] = true;
      const images = document.querySelector(`.${selector}`);
      if (!images.files.length) {
        this[`${type}MuteUpload`] = false;
        return;
      }

      const formData = new FormData();
      formData.append(images.name, images.files[0]);
      this.axios.post(`${window.RESTAPI || ''}/api/portal/admin/upload`, formData).then(
        (response) => {
          // console.log('response:', response);
          this[`${type}MuteUpload`] = false;
          this.isUploaded[type] = true;
          this.updatedPage.interface[type] = response.data.data.file;
          // покзываем уведомление, что картинки еще не сохранены, а будут сохранены только после
          // нажатия кнопки сохранить
          if (this.iframeNotificationShowed === false) {
            this.iframeNotificationShowed = true;
            this.showIframeNotification = true;
            setTimeout(() => {
              this.showIframeNotification = false;
            }, 3000);
          }
        },
        (err) => {
          this[`${type}MuteUpload`] = false;
          console.warn(err);
        }
      );
    },
    handleSelectColor(payload) {
      if (this.updatedPage === false || this.updatedPage.interface.theme_color === payload) {
        return;
      }
      this.updatedPage.interface.theme_color = payload;
    },
    handleSelectType(payload) {
      if (this.updatedPage === false || this.updatedPage.interface.theme_type === payload) {
        return;
      }
      this.updatedPage.interface.theme_type = payload;
    },

    nextStep() {
      this.$router.push({ name: 'EasyInstallWizardStep3' });
    },
    exitFromWizard() {
      this.$router.push({ name: 'Dashboard' });
    },
    prevStep() {
      this.$router.push({ name: 'EasyInstallWizardStep1' });
    }
  },
  created() {
    // // если не создали локацию на 1 шаге визарда, делать тут нечего, редиректим на шаг 1 :)
    // if (this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject === false ||
    //   this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject.id === false) {
    //   this.$router.push({ name: 'UsersDemoWizardStep1' });
    // }
    // проверяем есть какие шаги пройдены, чтобы знакть куда перенаправлять юзера, если он выходил из визарда
    if (this.$router.currentRoute.name !== EasyInstallWizardHelpers.checkStepForUserReditrect(this)) {
      this.$router.push({ name: EasyInstallWizardHelpers.checkStepForUserReditrect(this) });
    }
  }
};
</script>

<style lang="scss">
.portal-palette-wrapper-in-new-user-wizard > div {
  margin-left: 0px !important;
}
</style>

<style scoped lang="scss">
.logo-top-change-wrapper {
  height: 100%;
  left: 0%;
  position: absolute;
  width: 200px;
}
.logo-img {
  width: 200px;

  position: relative;
}
.logo-img img {
  width: 200px;
}
.logo-top-change {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 200px;
  left: 0%;
  top: 0px;
  position: absolute;

  background-color: rgba(0, 0, 0, 0.46);
}
.img-recommendations {
  position: absolute;
  bottom: 5px;
  color: #ffffff;
  font-size: 0.7rem;
  text-shadow: 4px 5px 60px rgba(0.2, 0.2, 0.2, 0.8);
}
.logo-changers-color {
  color: white !important;
}
.logo-footer-change span,
.logo-top-change span,
.background-change span {
  background-color: transparent !important;
  border-width: 0px;
}
.logo-footer-change button,
.logo-top-change button,
.background-change button {
  background-color: transparent !important;
  border-width: 0px;
}
.logo-footer-change i,
.logo-top-change i,
.background-change i {
  font-size: 3em;
}
.logo-changers-photo-formats-to-left {
  position: relative;
  left: 12px;
}
</style>
