import Vue from 'vue';
import moment from 'moment';
import VueI18n from 'vue-i18n';
import localeEN from './i18-locale-en';
import localeRU from './i18-locale-ru';
import store from '../store';

window.moment = moment;
Vue.prototype.moment = moment;

Vue.use(VueI18n);

const messages = {
  en: localeEN,
  ru: localeRU
};

window.LOCALE = process.env.VUE_APP_LOCALE;
const lsLOCALE = localStorage.getItem('NMS_LOCALE');

if (!lsLOCALE && !process.env.VUE_APP_LOCALE) {
  localStorage.setItem('NMS_LOCALE', 'ru');
  window.LOCALE = 'ru';
} else if (lsLOCALE && lsLOCALE !== process.env.VUE_APP_LOCALE) {
  window.LOCALE = lsLOCALE;
} else if (!lsLOCALE && process.env.VUE_APP_LOCALE) {
  window.LOCALE = process.env.VUE_APP_LOCALE.toLowerCase();
  localStorage.setItem('NMS_LOCALE', window.LOCALE);
}

if (!window.LOCALE || (window.LOCALE && window.LOCALE.toLowerCase() === 'en')) {
  moment.locale('en-gb');
} else {
  moment.locale(window.LOCALE.toLowerCase());
}

// const LOCALE = process.env.VUE_APP_LOCALE || '';
const i18n = new VueI18n({
  locale: window.LOCALE ? window.LOCALE.toLowerCase() : 'en',
  messages
});
export default i18n;
