<template>
  <div class="row users-demo-wizard-setup-step4-wrapper">
    <div class="col-lg-12">
      <div class="card">
        <loader-spinner v-if="showSpinnerConditionsAreSatisfied"></loader-spinner>
        <div class="card-block">
          <div class="h4 mb-xs mb-1">{{ $t('easyInstallWizard.step5Header') }}</div>
          <form action="">
            <div class="form-group"><img src="" alt="" class="map-preview img img-responsive" /></div>
            <div class="form-group">
              <label class="required-mark" for="name">{{ $t('usersDemoWizard.step4FieldProfileName') }}</label>
              <input
                type="text"
                name="name"
                id="name"
                :placeholder="$t('usersDemoWizard.step4FieldProfileName')"
                v-validate="'required'"
                :data-vv-as="$t('usersDemoWizard.step4FieldProfileName')"
                class="form-control input"
                :class="{ 'is-danger': errors.has('name') }"
                v-model="updatedProfile.name"
                :readonly="isDisable"
              />
              <span v-show="errors.has('name')" class="help is-danger mt-0 mb-1">
                {{ errors.first('name') }}
              </span>
            </div>
            <div>
              <!--            authn_skip-->
              <div class="d-flex justify-content-start align-items-center flex-wrap mb-0">
                <div class="d-flex justify-content-start align-items-start w-100 flex-column">
                  <!--                <div>-->
                  <!--                  <span class="default-authZ-caption">{{$t('portalProfiles.authzDefaultCaption')}}: </span>-->
                  <!--                </div>-->
                  <div class="ml-0 mt-h">
                    <Switch-component
                      v-model="updatedProfile.authn_skip"
                      :disabled="isDisable"
                      :label="$t('portalProfiles.authnSkipCaption')"
                      @input="authNSkipSwitched"
                    />
                  </div>
                </div>
              </div>

              <!-- Authentication -->
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mt-2 mb-2">
                  {{ $t('portal.authentication') }}
                  <info :content="$t('portalProfiles.authenInfo')" />
                </h6>
              </div>
              <div>
                <div
                  id="checkAuthNsWhenAuthenSkipIsFalseError"
                  class="mb-1 validatorErrors"
                  v-if="hasCheckAuthNsWhenAuthenSkipIsFalseError"
                >
                  <span>{{ this.checkAuthNsWhenAuthenSkipIsFalseError }}</span>
                </div>
                <div
                  class="interface-block mb-1"
                  :class="{
                    'authentication-list-in-portal-profiles-in-easy-install-wiz5-locked': isAllAuhNsSwitchersLocked
                  }"
                >
                  <div
                    v-for="(authentication, key) in portalAuthenticationsList"
                    v-if="isShowThisAuthenticationType(key)"
                    :key="key"
                    class="authentication-block mx-1"
                  >
                    <div class="form-group mt-1 mb-q">
                      <Switch-component
                        class="authN-switcher-in-easy-install-wiz5"
                        v-model="currentAuthentications[key]"
                        :disabled="isDisable || authNDisable(key) || isAllAuhNsSwitchersLocked"
                        :label="$t(`portalProfiles.authenType${key}`) ? $t(`portalProfiles.authenType${key}`) : key"
                        :id="key"
                        @input="toggleAuthentication(key)"
                      />

                      <info class="float-right" :content="$t(`portalProfiles.${key}Info`)" />
                      <div class="div-for-settings-button-for-authn-and-authz">
                        <span
                          v-if="currentAuthentications[key]"
                          @click="toggleAuthenticationItemVisability(key)"
                          class="settings-button-for-authn-and-authz"
                          :class="{
                            'authentication-list-in-portal-profiles-in-easy-install-wiz5-locked-settings-button-for-authn': isAllAuhNsSwitchersLocked
                          }"
                        >
                          <i v-if="openedAuthentications[key]" class="icon-arrow-down"></i>
                          <i v-else class="icon-arrow-right"></i>
                          {{ $t('portalProfiles.settingsForAuthZandAuthN') }}
                        </span>
                      </div>
                    </div>

                    <div v-if="currentAuthentications[authentication.type]">
                      <div v-show="openedAuthentications[authentication.type]">
                        <div class="col-12">
                          <div
                            v-if="Object.prototype.hasOwnProperty.call(currentAuthentications[key], 'portal_http_request')"
                            class="row w-100 m-0 p-0 ml-0">
                            <div class="col-12 form-group mt-1 pr-0">
                              <PortalProfileGatewaySettings
                                @hasNoErrors="hasNoErrorsProfileGatewaySettingsHandler"
                                @hasErrors="hasErrorsProfileGatewaySettingsHandler"
                                :authNTypeName="authentication.type"
                                :portal_request="currentAuthentications[key].portal_http_request">

                              </PortalProfileGatewaySettings>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12 col-5 form-group">
                              <label :for="`${key}-remember`">{{ $t('portal.remember') }}</label>
                              <input
                                type="text"
                                :name="`${key}-remember`"
                                :id="`${key}-remember`"
                                placeholder="In sec"
                                :readonly="isDisable"
                                class="form-control input"
                                :aria-describedby="`${key}-remember-caption`"
                                v-validate="'required|numeric'"
                                :class="{ 'is-danger': errors.has(`${key}-remember`) }"
                                v-model.number="currentAuthentications[authentication.type].remember"
                                size="10"
                              />
                              <span v-show="errors.has(`${key}-remember`)" class="help is-danger">
                                {{ errors.first(`${key}-remember`) }}
                              </span>
                              <small class="mt-h text-muted d-block" :id="`${key}-remember-caption`">
                                {{ $t('portal.rememberCaption') }}
                              </small>
                            </div>
                            <!--                            <div v-if="!isDisable" class="col-xs-12 col-7 form-group">-->
                            <!--                              <label-->
                            <!--                                v-if="-->
                            <!--                                  !chooseIntervalShowingForAuthentication[authentication.type] ||-->
                            <!--                                  chooseIntervalShowingForAuthentication[authentication.type] === false-->
                            <!--                                "-->
                            <!--                              >-->
                            <!--                                {{ $t('drPicker.chooseInterval') }}-->
                            <!--                              </label>-->
                            <!--                              <div-->
                            <!--                                v-if="-->
                            <!--                                  !chooseIntervalShowingForAuthentication[authentication.type] ||-->
                            <!--                                  chooseIntervalShowingForAuthentication[authentication.type] === false-->
                            <!--                                "-->
                            <!--                                class="row"-->
                            <!--                              >-->
                            <!--                                <div class="col-6">-->
                            <!--                                  <button-->
                            <!--                                    class="btn btn-success btn-block"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="-->
                            <!--                                      currentAuthentications[authentication.type].remember = moment(0)-->
                            <!--                                        .add(12, 'hours')-->
                            <!--                                        .unix()-->
                            <!--                                    "-->
                            <!--                                  >-->
                            <!--                                    {{ $t('general.duration12h') }}-->
                            <!--                                  </button>-->
                            <!--                                </div>-->
                            <!--                                <div class="col-6">-->
                            <!--                                  <button-->
                            <!--                                    class="btn btn-success btn-block"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="-->
                            <!--                                      currentAuthentications[authentication.type].remember = moment(0)-->
                            <!--                                        .add(1, 'days')-->
                            <!--                                        .unix()-->
                            <!--                                    "-->
                            <!--                                  >-->
                            <!--                                    {{ $t('general.durationDay') }}-->
                            <!--                                  </button>-->
                            <!--                                </div>-->
                            <!--                                <div class="col-6 mt-h">-->
                            <!--                                  <button-->
                            <!--                                    class="btn btn-success btn-block"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="-->
                            <!--                                      currentAuthentications[authentication.type].remember = moment(0)-->
                            <!--                                        .add(31, 'days')-->
                            <!--                                        .unix()-->
                            <!--                                    "-->
                            <!--                                  >-->
                            <!--                                    {{ $t('general.durationMonth') }}-->
                            <!--                                  </button>-->
                            <!--                                </div>-->
                            <!--                                <div class="col-6 mt-h">-->
                            <!--                                  <button-->
                            <!--                                    class="btn btn-success btn-block"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="-->
                            <!--                                      currentAuthentications[authentication.type].remember = moment(0)-->
                            <!--                                        .add(365, 'days')-->
                            <!--                                        .unix()-->
                            <!--                                    "-->
                            <!--                                  >-->
                            <!--                                    {{ $t('general.durationYear') }}-->
                            <!--                                  </button>-->
                            <!--                                </div>-->
                            <!--                                <div class="col-12 mt-h">-->
                            <!--                                  <button-->
                            <!--                                    class="btn btn-success btn-block"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="$set(chooseIntervalShowingForAuthentication, authentication.type, true)"-->
                            <!--                                  >-->
                            <!--                                    {{ $t('portalProfiles.customTimeInterval') }}-->
                            <!--                                  </button>-->
                            <!--                                </div>-->
                            <!--                              </div>-->
                            <!--                              <div-->
                            <!--                                v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"-->
                            <!--                                class="row"-->
                            <!--                              >-->
                            <!--                                <label for="amount-time">-->
                            <!--                                  {{ $t('portalProfiles.chooseCustomInterval') }}-->
                            <!--                                  <i-->
                            <!--                                    class="fa fa-remove text-danger ml-q"-->
                            <!--                                    @click="$set(chooseIntervalShowingForAuthentication, authentication.type, false)"-->
                            <!--                                  ></i>-->
                            <!--                                </label>-->
                            <!--                                <div-->
                            <!--                                  v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"-->
                            <!--                                  class="col-12 form-group"-->
                            <!--                                >-->
                            <!--                                  <div class="row">-->
                            <!--                                    &lt;!&ndash; <div class="col-12"> &ndash;&gt;-->
                            <!--                                    <input-->
                            <!--                                      v-model="currentAuthentications[authentication.type].amount"-->
                            <!--                                      name="amount-time"-->
                            <!--                                      placeholder="Choose amount"-->
                            <!--                                      class="form-control col-4"-->
                            <!--                                      size="1"-->
                            <!--                                    />-->

                            <!--                                    <select-->
                            <!--                                      v-model="currentAuthentications[authentication.type].metrics"-->
                            <!--                                      name="metrics-time"-->
                            <!--                                      class="form-control col-4"-->
                            <!--                                      placeholder="of units"-->
                            <!--                                    >-->
                            <!--                                      <option :value="unit" :key="unit" v-for="unit in timeUnitNames">-->
                            <!--                                        {{ $t(`drPicker.${unit}`) }}-->
                            <!--                                      </option>-->
                            <!--                                    </select>-->
                            <!--                                    <button-->
                            <!--                                      class="btn btn-success col-4"-->
                            <!--                                      @click="handleCustomTimeAuthentication(authentication.type)"-->
                            <!--                                    >-->
                            <!--                                      {{ $t('drPicker.apply') }}-->
                            <!--                                    </button>-->
                            <!--                                    &lt;!&ndash; </div> &ndash;&gt;-->
                            <!--                                  </div>-->
                            <!--                                </div>-->
                            <!--                              </div>-->
                            <!--                            </div>-->
                            <!--                          interval selection buttons for AuthN-->
                            <div v-if="!isDisable" class="col-xs-12 col-7 form-group">
                              <label
                                v-if="
                                  !chooseIntervalShowingForAuthentication[authentication.type] ||
                                  chooseIntervalShowingForAuthentication[authentication.type] === false
                                "
                              >
                                {{ $t('drPicker.chooseInterval') }}
                              </label>
                              <div
                                v-if="
                                  !chooseIntervalShowingForAuthentication[authentication.type] ||
                                  chooseIntervalShowingForAuthentication[authentication.type] === false
                                "
                                class="d-flex justify-content-start flex-wrap"
                              >
                                <button
                                  class="btn btn-success btn-xs portal-profiles-interval-select-buttons"
                                  type="button"
                                  @click="
                                    currentAuthentications[authentication.type].remember = moment(0)
                                      .add(12, 'hours')
                                      .unix()
                                  "
                                >
                                  {{ $t('general.duration12h') }}
                                </button>

                                <button
                                  class="btn btn-success btn-xs portal-profiles-interval-select-buttons"
                                  type="button"
                                  @click="
                                    currentAuthentications[authentication.type].remember = moment(0)
                                      .add(1, 'days')
                                      .unix()
                                  "
                                >
                                  {{ $t('general.durationDay') }}
                                </button>

                                <button
                                  class="btn btn-success btn-xs portal-profiles-interval-select-buttons"
                                  type="button"
                                  @click="
                                    currentAuthentications[authentication.type].remember = moment(0)
                                      .add(31, 'days')
                                      .unix()
                                  "
                                >
                                  {{ $t('general.durationMonth') }}
                                </button>

                                <button
                                  class="btn btn-success btn-xs portal-profiles-interval-select-buttons"
                                  type="button"
                                  @click="
                                    currentAuthentications[authentication.type].remember = moment(0)
                                      .add(365, 'days')
                                      .unix()
                                  "
                                >
                                  {{ $t('general.durationYear') }}
                                </button>

                                <button
                                  class="btn btn-success btn-xs portal-profiles-interval-select-buttons"
                                  type="button"
                                  @click="$set(chooseIntervalShowingForAuthentication, authentication.type, true)"
                                >
                                  {{ $t('portalProfiles.customTimeInterval') }}
                                </button>
                              </div>
                              <div
                                v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"
                                class="row"
                              >
                                <label for="amount-time">
                                  {{ $t('portalProfiles.chooseCustomInterval') }}
                                  <i
                                    class="fa fa-remove text-danger ml-q"
                                    role="button"
                                    @click="$set(chooseIntervalShowingForAuthentication, authentication.type, false)"
                                  ></i>
                                </label>
                                <div
                                  v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"
                                  class="col-12 form-group"
                                >
                                  <div class="row">
                                    <!-- <div class="col-12"> -->
                                    <input
                                      v-model="currentAuthentications[authentication.type].amount"
                                      name="amount-time"
                                      placeholder="Choose amount"
                                      class="form-control col-4"
                                      size="1"
                                    />

                                    <select
                                      v-model="currentAuthentications[authentication.type].metrics"
                                      name="metrics-time"
                                      class="form-control col-4"
                                      placeholder="of units"
                                    >
                                      <!--                                    <option value="seconds">{{ $t('drPicker.seconds') }}</option>-->
                                      <!--                                    <option value="minutes">{{ $t('drPicker.minutes') }}</option>-->
                                      <!--                                    <option value="hours">{{ $t('drPicker.hours') }}</option>-->
                                      <!--                                    <option value="days">{{ $t('drPicker.days') }}</option>-->
                                      <!--                                    <option value="weeks">{{ $t('drPicker.weeks') }}</option>-->
                                      <!--                                    <option value="months">{{ $t('drPicker.months') }}</option>-->
                                      <!--                                    <option value="years">{{ $t('drPicker.years') }}</option>-->
                                      <option :value="unit" :key="unit" v-for="unit in timeUnitNames">
                                        {{ $t(`drPicker.${unit}`) }}
                                      </option>
                                    </select>
                                    <button
                                      class="btn btn-success portal-profiles-interval-select-buttons"
                                      @click="handleCustomTimeAuthentication(authentication.type)"
                                    >
                                      {{ $t('drPicker.apply') }}
                                    </button>
                                    <!-- </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="col-6 form-group mt-h"
                              v-if="
                                authentication.type !== 'esia' &&
                                authentication.type !== 'callfront' &&
                                authentication.type !== 'userpass'
                              "
                            >
                              <label :for="`${key}-otp_length`">{{ $t('portal.otp') }}</label>
                              <input
                                type="text"
                                :name="`${key}-otp_length`"
                                :id="`${key}-otp_length`"
                                placeholder="Length of password"
                                :readonly="isDisable"
                                class="form-control input"
                                v-validate="'required|numeric|between:4,10'"
                                :class="{ 'is-danger': errors.has(`${key}-otp_length`) }"
                                v-model.number="currentAuthentications[authentication.type].otp_length"
                                size="1"
                              />
                              <span v-show="errors.has(`${key}-otp_length`)" class="help is-danger">
                                {{ errors.first(`${key}-otp_length`) }}
                              </span>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="
                                currentAuthentications[authentication.type].validate && authentication.type === 'email'
                              "
                              class="col-lg-6 col-sm-10 form-group mt-h"
                            >
                              <label :for="`${key}-regex`">{{ $t('portal.regex') }}</label>
                              <input
                                type="text"
                                name="regex"
                                :id="`${key}-regex`"
                                :readonly="isDisable"
                                :aria-describedby="`${key}-regex-caption`"
                                class="form-control input"
                                placeholder=".*@gmail\.com"
                                v-model="currentAuthentications[authentication.type].validate.regex"
                              />
                              <small class="mt-h text-muted d-block" :id="`${key}-regex-caption`">
                                {{ $t('portal.regexCaption') }}
                              </small>
                            </div>
                            <div
                              v-if="
                                currentAuthentications[authentication.type].validate && authentication.type === 'email'
                              "
                              class="col-lg-6 col-sm-10 form-group mt-h"
                            >
                              <label :for="`${key}-error-message`">{{ $t('portal.errorMessage') }}</label>
                              <input
                                type="text"
                                name="error-message"
                                :id="`${key}-error-message`"
                                :readonly="isDisable"
                                :aria-describedby="`${key}-error-message-caption`"
                                class="form-control input"
                                v-model="currentAuthentications[authentication.type].validate.error"
                              />
                              <small class="mt-h text-muted d-block" :id="`${key}-error-message-caption`">
                                {{ $t('portal.errorMessageCaption') }}
                              </small>
                            </div>
                          </div>
                          <div class="row mb-1">
                            <div class="col-4">
                              <!--                              <button-->
                              <!--                                class="btn btn-success btn-block"-->
                              <!--                                type="button"-->
                              <!--                                @click="closeAuthenticationItem(key)"-->
                              <!--                              >-->
                              <!--                                <i class="fa fa-long-arrow-up"></i>-->
                              <!--                                {{ $t('portalProfiles.cutDownButton') }}-->
                              <!--                                <i class="fa fa-long-arrow-up"></i>-->
                              <!--                              </button>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <transition name="fade">
                    <!-- SMS code (OTP CODE) -->
                    <div v-if="isauthNEnabled && isAuthNNeedingOTPCodeEnabled && !isAppAuthFreemiumEnabled" class="row">
                      <div class="col-12 form-group">
                        <label for="otp-code-text">
                          {{ $t('portalProfiles.otpCodeText') }}
                          <info :content="$t(`portalProfiles.otpInfo`)" />
                        </label>
                        <input
                          type="text"
                          name="otp-code-text"
                          id="otp-code-text"
                          placeholder="Code: {CODE}"
                          :readonly="isDisable"
                          class="form-control input"
                          v-validate="'required|sms_code'"
                          :data-vv-as="$t('portal.otpCodeText')"
                          size="2"
                          :class="{ 'is-danger': errors.has('otp-code-text') }"
                          v-model="updatedProfile.push_text.otp"
                        />
                        <span
                          v-tooltip.top-center="{
                            content: errors.first('otp-code-text')
                          }"
                          v-show="errors.has('otp-code-text')"
                          class="help is-danger error_tooltip"
                        >
                          <i class="fa fa-warning text-error"></i>
                        </span>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>

              <!-- Authorization -->
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <h6 class="mt-2 mb-2">
                    {{ $t('portal.authorization') }}
                    <info :content="$t(`portalProfiles.authInfo`)" />
                  </h6>
                </div>
              </div>

              <div class="interface-block mb-1">
                <div
                  v-for="(authorization, key) in portalAuthorizationsList"
                  v-if="isShowThisAuthorizationType(key)"
                  :key="key"
                  class="authorization-block mx-1"
                >
                  <div class="form-group mt-1 mb-q">
                    <Switch-component
                      v-model="currentAuthorizations[key]"
                      :disabled="isDisable || authZDisable(key)"
                      :label="$te(`pageprofile.${key}`) ? $t(`pageprofile.${key}`) : key"
                      :id="key"
                      @input="toggleAuthorization(key)"
                    />
                    <info class="float-right" :content="$t(`portalProfiles.${key}Info`)" />
                    <div class="div-for-settings-button-for-authn-and-authz">
                      <span
                        v-if="currentAuthorizations[key]"
                        @click="toggleAuthorizationItemVisability(key)"
                        class="settings-button-for-authn-and-authz"
                      >
                        <i v-if="openedAuthorizations[key]" class="icon-arrow-down"></i>
                        <i v-else class="icon-arrow-right"></i>
                        {{ $t('portalProfiles.settingsForAuthZandAuthN') }}
                      </span>
                    </div>
                    <div v-if="key === 'voucher'" style="float: right;"></div>
                  </div>

<!--                  <div v-if="currentAuthorizations[key]">-->
<!--                    <div v-show="openedAuthorizations[key]">-->
<!--                      &lt;!&ndash; filteredSettings used to temporarily exclude download_limit & auth_timeout keys &ndash;&gt;-->
<!--                      <template v-if="key !== 'voucher'">-->
<!--                        <div class="col-12">-->
<!--                          <div class="mb-1 authorization-categories">{{ $t('portalProfiles.sessionSettings') }}:</div>-->
<!--                          <div>-->
<!--                            <div class="row" v-for="(translate, setting) in filteredSettings" :key="setting">-->
<!--                              <div class="col-xs-12 col-5 form-group">-->
<!--                                <label class="authZ-settings-fields-height-hack" :for="key">-->
<!--                                  {{ $t(`${translate}`) }}-->
<!--                                </label>-->
<!--                                <input-->
<!--                                  type="number"-->
<!--                                  :name="`${key}-${setting}`"-->
<!--                                  :id="setting"-->
<!--                                  :placeholder="$t(`${translate}`)"-->
<!--                                  :readonly="isDisable"-->
<!--                                  class="form-control input"-->
<!--                                  v-validate="'required|numeric'"-->
<!--                                  :class="{ 'is-danger': errors.has(`${key}-${setting}`) }"-->
<!--                                  v-model.number="currentAuthorizations[key].config[setting]"-->
<!--                                />-->
<!--                                <small v-if="hasCaption(translate)" class="text-muted">-->
<!--                                  {{ $t(`${translate}Caption`) }}-->
<!--                                </small>-->
<!--                              </div>-->
<!--                              <div v-if="setting !== 'max_sessions'" class="col-xs-12 col-7 form-group">-->
<!--                                &lt;!&ndash;                                <template&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  v-if="&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    !chooseIntervalShowingForAuthorizations ||&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    !chooseIntervalShowingForAuthorizations[key] ||&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    !chooseIntervalShowingForAuthorizations[key][setting]&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  "&ndash;&gt;-->
<!--                                &lt;!&ndash;                                >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  <label for="amount-time">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    {{ $t('drPicker.chooseInterval') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  </label>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  <div class="row">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <div class="col-6">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <button&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="btn btn-success btn-block"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        type="button"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        @click="&ndash;&gt;-->
<!--                                &lt;!&ndash;                                          currentAuthorizations[key].config[setting] = moment(0).add(12, 'hours').unix()&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        "&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        {{ $t('general.duration12h') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      </button>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <div class="col-6">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <button&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="btn btn-success btn-block"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        type="button"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        @click="&ndash;&gt;-->
<!--                                &lt;!&ndash;                                          currentAuthorizations[key].config[setting] = moment(0).add(1, 'days').unix()&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        "&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        {{ $t('general.durationDay') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      </button>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <div class="col-6 mt-h">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <button&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="btn btn-success btn-block"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        type="button"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        @click="&ndash;&gt;-->
<!--                                &lt;!&ndash;                                          currentAuthorizations[key].config[setting] = moment(0).add(31, 'days').unix()&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        "&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        {{ $t('general.durationMonth') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      </button>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <div class="col-6 mt-h">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <button&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="btn btn-success btn-block"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        type="button"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        @click="&ndash;&gt;-->
<!--                                &lt;!&ndash;                                          currentAuthorizations[key].config[setting] = moment(0).add(365, 'days').unix()&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        "&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        {{ $t('general.durationYear') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      </button>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <div class="col-12 mt-h">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <button&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="btn btn-success btn-block"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        type="button"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        @click="setChooseIntervalShowingForAuthorizations(key, setting)"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        {{ $t('portalProfiles.customTimeInterval') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      </button>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </template>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                <template&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  v-if="&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    chooseIntervalShowingForAuthorizations &&&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    chooseIntervalShowingForAuthorizations[key] &&&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    chooseIntervalShowingForAuthorizations[key][setting] === true&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  "&ndash;&gt;-->
<!--                                &lt;!&ndash;                                >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  <label for="amount-time">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    {{ $t('portalProfiles.chooseCustomInterval') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <i&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      class="fa fa-remove text-danger ml-q"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      @click="$set(chooseIntervalShowingForAuthorizations[key], setting, false)"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    ></i>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  </label>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  <div class="col-12">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <div class="row">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <input&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        v-model="currentAuthorizations[key].customTimeInterval[setting].amount"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        name="amount-time"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        placeholder="Choose amount"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="col-4 form-control"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        size="1"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      />&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <select&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        v-model="currentAuthorizations[key].customTimeInterval[setting].metrics"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        name="metrics-time"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="col-4 form-control"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        placeholder="of units"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        <option :value="unit" :key="unit" v-for="unit in timeUnitNames">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                          {{ $t(`drPicker.${unit}`) }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        </option>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      </select>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      <button&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="col-4 btn btn-success"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        @click="handleCustomTimeAuthorization(key, setting)"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        {{ $t('drPicker.apply') }}&ndash;&gt;-->
<!--                                &lt;!&ndash;                                      </button>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </template>&ndash;&gt;-->
<!--                                <template-->
<!--                                  v-if="-->
<!--                                    !chooseIntervalShowingForAuthorizations ||-->
<!--                                    !chooseIntervalShowingForAuthorizations[key] ||-->
<!--                                    !chooseIntervalShowingForAuthorizations[key][setting] ||-->
<!--                                    chooseIntervalShowingForAuthorizations[key][setting] === false-->
<!--                                  "-->
<!--                                >-->
<!--                                  <label class="authZ-settings-fields-height-hack" for="amount-time">-->
<!--                                    {{ $t('drPicker.chooseInterval') }}-->
<!--                                  </label>-->
<!--                                  <div class="d-flex justify-content-start flex-wrap">-->
<!--                                    <button-->
<!--                                      class="btn btn-success portal-profiles-interval-select-buttons"-->
<!--                                      type="button"-->
<!--                                      @click="-->
<!--                                        currentAuthorizations[key].config[setting] = moment(0).add(12, 'hours').unix()-->
<!--                                      "-->
<!--                                    >-->
<!--                                      {{ $t('general.duration12h') }}-->
<!--                                    </button>-->

<!--                                    <button-->
<!--                                      class="btn btn-success portal-profiles-interval-select-buttons"-->
<!--                                      type="button"-->
<!--                                      @click="-->
<!--                                        currentAuthorizations[key].config[setting] = moment(0).add(1, 'days').unix()-->
<!--                                      "-->
<!--                                    >-->
<!--                                      {{ $t('general.durationDay') }}-->
<!--                                    </button>-->

<!--                                    <button-->
<!--                                      class="btn btn-success portal-profiles-interval-select-buttons"-->
<!--                                      type="button"-->
<!--                                      @click="-->
<!--                                        currentAuthorizations[key].config[setting] = moment(0).add(31, 'days').unix()-->
<!--                                      "-->
<!--                                    >-->
<!--                                      {{ $t('general.durationMonth') }}-->
<!--                                    </button>-->

<!--                                    <button-->
<!--                                      class="btn btn-success portal-profiles-interval-select-buttons"-->
<!--                                      type="button"-->
<!--                                      @click="-->
<!--                                        currentAuthorizations[key].config[setting] = moment(0).add(365, 'days').unix()-->
<!--                                      "-->
<!--                                    >-->
<!--                                      {{ $t('general.durationYear') }}-->
<!--                                    </button>-->

<!--                                    <button-->
<!--                                      class="btn btn-success portal-profiles-interval-select-buttons"-->
<!--                                      type="button"-->
<!--                                      @click="setChooseIntervalShowingForAuthorizations(key, setting)"-->
<!--                                    >-->
<!--                                      {{ $t('portalProfiles.customTimeInterval') }}-->
<!--                                    </button>-->
<!--                                  </div>-->
<!--                                </template>-->
<!--                                <template-->
<!--                                  v-if="-->
<!--                                    chooseIntervalShowingForAuthorizations &&-->
<!--                                    chooseIntervalShowingForAuthorizations[key] &&-->
<!--                                    chooseIntervalShowingForAuthorizations[key][setting] === true-->
<!--                                  "-->
<!--                                >-->
<!--                                  <label class="authZ-settings-fields-height-hack" for="amount-time">-->
<!--                                    {{ $t('portalProfiles.chooseCustomInterval') }}-->
<!--                                    <i-->
<!--                                      role="button"-->
<!--                                      class="fa fa-remove text-danger ml-q"-->
<!--                                      @click="$set(chooseIntervalShowingForAuthorizations[key], setting, false)"-->
<!--                                    ></i>-->
<!--                                  </label>-->
<!--                                  <div class="col-12">-->
<!--                                    <div class="row">-->
<!--                                      <input-->
<!--                                        v-model="currentAuthorizations[key].customTimeInterval[setting].amount"-->
<!--                                        name="amount-time"-->
<!--                                        placeholder="Choose amount"-->
<!--                                        class="col-4 form-control"-->
<!--                                        size="1"-->
<!--                                      />-->
<!--                                      <select-->
<!--                                        v-model="currentAuthorizations[key].customTimeInterval[setting].metrics"-->
<!--                                        name="metrics-time"-->
<!--                                        class="col-4 form-control"-->
<!--                                        placeholder="of units"-->
<!--                                      >-->
<!--                                        &lt;!&ndash;                                      <option value="seconds">{{ $t('drPicker.seconds') }}</option>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                      <option value="minutes">{{ $t('drPicker.minutes') }}</option>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                      <option value="hours">{{ $t('drPicker.hours') }}</option>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                      <option value="days">{{ $t('drPicker.days') }}</option>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                      <option value="weeks">{{ $t('drPicker.weeks') }}</option>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                      <option value="months">{{ $t('drPicker.months') }}</option>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                      <option value="years">{{ $t('drPicker.years') }}</option>&ndash;&gt;-->
<!--                                        <option :value="unit" :key="unit" v-for="unit in timeUnitNames">-->
<!--                                          {{ $t(`drPicker.${unit}`) }}-->
<!--                                        </option>-->
<!--                                      </select>-->
<!--                                      <button-->
<!--                                        class="btn btn-success portal-profiles-interval-select-buttons"-->
<!--                                        @click="handleCustomTimeAuthorization(key, setting)"-->
<!--                                      >-->
<!--                                        {{ $t('drPicker.apply') }}-->
<!--                                      </button>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </template>-->
<!--                                <span v-show="errors.has(`${key}-${setting}`)" class="help is-danger">-->
<!--                                  {{ errors.first(`${key}-${setting}`) }}-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <template v-if="key === 'voucher'">-->
<!--                        &lt;!&ndash; SMS code (voucher code) &ndash;&gt;-->
<!--                        <div class="col-12">-->
<!--                          <div class="authorization-categories">{{ $t('portal.voucherCodeText') }}:</div>-->
<!--                          <div class="row">-->
<!--                            <div class="col-6">-->
<!--                              &lt;!&ndash;<label class = 'authorization-categories' :for="'voucher-code-text'">{{ $t('portal.voucherCodeText') }}</label>&ndash;&gt;-->
<!--                              <input-->
<!--                                type="text"-->
<!--                                name="voucher-code-text"-->
<!--                                id="voucher-code-text"-->
<!--                                placeholder="Code: {CODE}"-->
<!--                                :readonly="isDisable"-->
<!--                                class="form-control input"-->
<!--                                v-validate="'required|sms_code'"-->
<!--                                :data-vv-as="$t('portal.voucherCodeText')"-->
<!--                                size="1"-->
<!--                                :class="{ 'is-danger': errors.has('voucher-code-text') }"-->
<!--                                v-model="updatedProfile.push_text.voucher"-->
<!--                              />-->
<!--                              <span-->
<!--                                v-tooltip.top-center="{-->
<!--                                  content: errors.first('voucher-code-text')-->
<!--                                }"-->
<!--                                v-show="errors.has('voucher-code-text')"-->
<!--                                class="help is-danger error_tooltip"-->
<!--                              >-->
<!--                                <i class="fa fa-warning text-error"></i>-->
<!--                              </span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; Payment system &ndash;&gt;-->
<!--                        <div class="col-12">-->
<!--                          <div class="authorization-categories">{{ $t('portal.paymentSystems') }}:</div>-->
<!--                          <div class="row">-->
<!--                            <div class="col-12">-->
<!--                              <multiselect-->
<!--                                :class="{-->
<!--                                  'is-danger': errors.has('payment-systems'),-->
<!--                                  'multiselect-disabled': isDisable-->
<!--                                }"-->
<!--                                :custom-label="-->
<!--                                  (option) => getSystemByID(option).identity || $t('general.noDataToDisplay')-->
<!--                                "-->
<!--                                :data-vv-name="$t('portal.paymentSystems')"-->
<!--                                :disabled="isDisable"-->
<!--                                :hide-selected="true"-->
<!--                                :multiple="true"-->
<!--                                :options="paymentSystemsIDs || []"-->
<!--                                :placeholder="$t('portal.paymentSystems')"-->
<!--                                class="input"-->
<!--                                id="payment-systems"-->
<!--                                v-model="updatedProfile.payment_systems"-->
<!--                              >-->
<!--                                <template slot="option" slot-scope="props">-->
<!--                                  <div class="d-flex">-->
<!--                                    <div-->
<!--                                      class="payment-logo-preview"-->
<!--                                      :style="`background-image: url('${getSystemByID(props.option).image}')`"-->
<!--                                    />-->

<!--                                    <div class="ml-h">-->
<!--                                      <span class="d-block mb-h">{{ getSystemByID(props.option).identity }}</span>-->
<!--                                      <small v-if="getSystemByID(props.option).name">-->
<!--                                        {{ $t('paymentSystems.name') }}: {{ getSystemByID(props.option).name }}-->
<!--                                      </small>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </template>-->
<!--                              </multiselect>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <div class="col-12">-->
<!--                        <div v-if="!socials.includes(key)" class="authorization-categories">-->
<!--                          {{ $t('portalProfiles.advertismentSettings') }}:-->
<!--                        </div>-->
<!--                        <div v-if="!socials.includes(key)" class="form-group">-->
<!--                          <label :for="`${key}-ads`">{{ $t('adsprofile.ads') }}</label>-->
<!--                          <multiselect-->
<!--                            data-vv-name="ads"-->
<!--                            v-model="currentAuthorizations[key].ads"-->
<!--                            :options="portalAdsList || []"-->
<!--                            label="name"-->
<!--                            :id="`${key}-ads`"-->
<!--                            track-by="name"-->
<!--                            :multiple="true"-->
<!--                            :hide-selected="true"-->
<!--                            class="input"-->
<!--                            :class="{ 'is-danger': errors.has('ads'), 'multiselect-disabled': isDisable }"-->
<!--                            :placeholder="$t('adsprofile.ads')"-->
<!--                            :disabled="isDisable"-->
<!--                          >-->
<!--                            <template slot="option" slot-scope="props">-->
<!--                              <span class="d-block mb-h">{{ props.option.name }}</span>-->
<!--                              <small>-->
<!--                                {{ $t('adsprofile.priority') }}: {{ props.option.priority }},-->
<!--                                {{ getAdStatus(props.option) }}-->
<!--                              </small>-->
<!--                            </template>-->
<!--                          </multiselect>-->
<!--                        </div>-->

<!--                        <div-->
<!--                          v-if="!socials.includes(key) && currentAuthorizations[key].hasOwnProperty('ads_to_watch')"-->
<!--                          class="row"-->
<!--                        >-->
<!--                          <div class="col-xs-12 col-lg-4">-->
<!--                            <label for="ads_to_watch">{{ $t('portal.ads_to_watch') }}</label>-->
<!--                            <input-->
<!--                              :class="{ 'is-danger': errors.has(`${key}-ads_to_watch`) }"-->
<!--                              :name="`${key}-ads_to_watch`"-->
<!--                              :placeholder="$t('portal.ads_to_watch')"-->
<!--                              :readonly="isDisable"-->
<!--                              class="form-control input"-->
<!--                              :data-vv-as="$t('portal.ads_to_watch')"-->
<!--                              id="ads_to_watch"-->
<!--                              type="number"-->
<!--                              v-model.number="currentAuthorizations[key].ads_to_watch"-->
<!--                              v-validate="`required|between:0,${currentAuthorizations[key].ads.length}`"-->
<!--                            />-->
<!--                            <span v-show="errors.has(`${key}-ads_to_watch`)" class="help is-danger">-->
<!--                              {{ errors.first(`${key}-ads_to_watch`) }}-->
<!--                            </span>-->
<!--                          </div>-->
<!--                          <div class="col-xs-12 col-lg-8 form-group mb-0">-->
<!--                            <div class="mt-1">-->
<!--                              <Switch-component-->
<!--                                v-model="currentAuthorizations[key].enable_rotation"-->
<!--                                :disabled="isDisable"-->
<!--                                :label="$t('portal.adsRotation')"-->
<!--                                :id="`ads-rotation-${key}`"-->
<!--                              />-->
<!--                            </div>-->
<!--                            <div class="mt-1">-->
<!--                              <Switch-component-->
<!--                                v-model="currentAuthorizations[key].enable_ad_follow"-->
<!--                                :disabled="isDisable"-->
<!--                                :label="$t('portal.adsFollow')"-->
<!--                                :id="`ads-follow-${key}`"-->
<!--                              />-->
<!--                            </div>-->
<!--                            <div class="mt-1">-->
<!--                              <Switch-component-->
<!--                                v-model="currentAuthorizations[key].skip_final_page"-->
<!--                                :disabled="isDisable"-->
<!--                                :label="$t('portal.skipFinalPage')"-->
<!--                                :id="`skip-final-page-${key}`"-->
<!--                              />-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div class="d-flex flex-column" v-if="key === 'vk' || key === 'facebook'">-->
<!--                          <div class="btn-group float-left" data-toggle="button">-->
<!--                            <label class="btn btn-outline-primary" :class="{ active: socialAuth[key].type === 'auth' }">-->
<!--                              <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth')" />-->
<!--                              {{ $t('portal.authOnly') }}-->
<!--                            </label>-->
<!--                            <label-->
<!--                              class="btn btn-outline-primary"-->
<!--                              :class="{ active: socialAuth[key].type === 'auth_post' }"-->
<!--                            >-->
<!--                              <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth_post')" />-->
<!--                              {{ $t('portal.authPost') }}-->
<!--                            </label>-->
<!--                            <label-->
<!--                              class="btn btn-outline-primary"-->
<!--                              :class="{ active: socialAuth[key].type === 'auth_group' }"-->
<!--                            >-->
<!--                              <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth_group')" />-->
<!--                              {{ $t('portal.authGroup') }}-->
<!--                            </label>-->
<!--                          </div>-->

<!--                          <div v-if="socialAuth[key].type === 'auth'" class="form-group mt-h">-->
<!--                            <label :for="`${key}-social-redirect_url`" class="required-mark">-->
<!--                              {{ $t('portal.auth.redirectURL') }}-->
<!--                            </label>-->
<!--                            <input-->
<!--                              :class="{ 'is-danger': errors.has(`${key}-social-redirect_url`) }"-->
<!--                              :data-vv-as="`${$t('portal.auth.redirectURL')}`"-->
<!--                              :id="`${key}-social-redirect_url`"-->
<!--                              :name="`${key}-social-redirect_url`"-->
<!--                              :readonly="isDisable"-->
<!--                              class="form-control input"-->
<!--                              placeholder="Redirect URL"-->
<!--                              type="text"-->
<!--                              v-model="currentAuthorizations[key].social_network_config.redirect_url"-->
<!--                              v-validate="{ required: true, min: 3 }"-->
<!--                            />-->
<!--                            <span v-show="errors.has(`${key}-social-redirect_url`)" class="help is-danger">-->
<!--                              {{ errors.first(`${key}-social-redirect_url`) }}-->
<!--                            </span>-->
<!--                          </div>-->

<!--                          <div v-if="socialAuth[key].type === 'auth_post'">-->
<!--                            <div class="form-group mt-h">-->
<!--                              <label :for="`${key}-social-post-message`" class="required-mark">-->
<!--                                {{ $t('portal.auth.message') }}-->
<!--                              </label>-->
<!--                              <textarea-->
<!--                                :class="{ 'is-danger': errors.has(`${key}-social-post-message`) }"-->
<!--                                :data-vv-as="$t('portal.auth.message')"-->
<!--                                :id="`${key}-social-post-message`"-->
<!--                                :name="`${key}-social-post-message`"-->
<!--                                :readonly="isDisable"-->
<!--                                class="form-control input"-->
<!--                                placeholder="Post message"-->
<!--                                rows="2"-->
<!--                                type="text"-->
<!--                                v-model="currentAuthorizations[key].social_network_config.post.message"-->
<!--                                v-validate="{ required: true, min: 3 }"-->
<!--                              />-->
<!--                              <span v-show="errors.has(`${key}-social-post-message`)" class="help is-danger">-->
<!--                                {{ errors.first(`${key}-social-post-message`) }}-->
<!--                              </span>-->
<!--                            </div>-->

<!--                            <div class="form-group mt-h">-->
<!--                              <label :for="`${key}-social-post-url`" class="required-mark">-->
<!--                                {{ $t('portal.auth.attachedURL') }}-->
<!--                              </label>-->
<!--                              <input-->
<!--                                :class="{ 'is-danger': errors.has(`${key}-social-post-url`) }"-->
<!--                                :data-vv-as="$t('portal.auth.attachedURL')"-->
<!--                                :id="`${key}-social-post-url`"-->
<!--                                :name="`${key}-social-post-url`"-->
<!--                                :readonly="isDisable"-->
<!--                                class="form-control input"-->
<!--                                placeholder="Post URL"-->
<!--                                rows="2"-->
<!--                                type="text"-->
<!--                                v-model="currentAuthorizations[key].social_network_config.post.attached_url"-->
<!--                                v-validate="{ required: true, min: 3 }"-->
<!--                              />-->
<!--                              <span v-show="errors.has(`${key}-social-post-url`)" class="help is-danger">-->
<!--                                {{ errors.first(`${key}-social-post-url`) }}-->
<!--                              </span>-->
<!--                            </div>-->
<!--                          </div>-->

<!--                          <div v-if="socialAuth[key].type === 'auth_group'" class="form-group mt-h">-->
<!--                            <label :for="`${key}-social-group_url`" class="required-mark">-->
<!--                              {{ $t('portal.auth.groupURL') }}-->
<!--                            </label>-->
<!--                            <input-->
<!--                              :class="{ 'is-danger': errors.has(`${key}-social-group_url`) }"-->
<!--                              :data-vv-as="$t('portal.auth.groupURL')"-->
<!--                              :id="`${key}-social-group_url`"-->
<!--                              :name="`${key}-social-group_url`"-->
<!--                              :readonly="isDisable"-->
<!--                              class="form-control input"-->
<!--                              placeholder="Group URL"-->
<!--                              type="text"-->
<!--                              v-model="currentAuthorizations[key].social_network_config.group_url"-->
<!--                              v-validate="{ required: true, socialAuthURL: true, min: 3 }"-->
<!--                            />-->
<!--                            <span v-show="errors.has(`${key}-social-group_url`)" class="help is-danger">-->
<!--                              {{ errors.first(`${key}-social-group_url`) }}-->
<!--                            </span>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div v-if="!socials.includes(key)" class="form-group mt-h">-->
<!--                          <label :for="`${key}-redirect_url`">{{ $t('portal.redirect') }}</label>-->
<!--                          <input-->
<!--                            :class="{ 'is-danger': errors.has(`${key}-redirect_url`) }"-->
<!--                            :data-vv-as="$t('portal.redirect')"-->
<!--                            :id="`${key}-redirect_url`"-->
<!--                            :name="`${key}-redirect_url`"-->
<!--                            :readonly="isDisable"-->
<!--                            class="form-control input"-->
<!--                            placeholder="Redirect URL"-->
<!--                            type="text"-->
<!--                            v-model="currentAuthorizations[key].redirect_url"-->
<!--                            v-validate="'required'"-->
<!--                          />-->
<!--                          <span v-show="errors.has(`${key}-redirect_url`)" class="help is-danger">-->
<!--                            {{ errors.first(`${key}-redirect_url`) }}-->
<!--                          </span>-->
<!--                        </div>-->

<!--                        <div v-if="key === 'voucher'" class="form-group mt-h">-->
<!--                          <div class="authorization-categories">{{ $t('portalProfiles.vouchersSettings') }}:</div>-->
<!--                          <div class="form-group mt-h">-->
<!--                            <label :for="`${key}-header-text`">{{ $t('portal.voucherBuyHeader') }}</label>-->
<!--                            <textarea-->
<!--                              :class="{ 'is-danger': errors.has(`${key}-header-text`) }"-->
<!--                              :data-vv-as="$t('portal.voucherBuyHeader')"-->
<!--                              :id="`${key}-header-text`"-->
<!--                              :name="`${key}-header-text`"-->
<!--                              :readonly="isDisable"-->
<!--                              class="form-control input"-->
<!--                              placeholder="Header"-->
<!--                              rows="2"-->
<!--                              type="text"-->
<!--                              v-model="currentAuthorizations[key].header"-->
<!--                              v-validate="'required'"-->
<!--                            />-->
<!--                            <span v-show="errors.has(`${key}-header-text`)" class="help is-danger">-->
<!--                              {{ errors.first(`${key}-header-text`) }}-->
<!--                            </span>-->
<!--                          </div>-->
<!--                          <div class="form-group mt-h">-->
<!--                            <label :for="`${key}-header-info`">{{ $t('portal.voucherBuyInfo') }}</label>-->
<!--                            <textarea-->
<!--                              :class="{ 'is-danger': errors.has(`${key}-header-info`) }"-->
<!--                              :data-vv-as="$t('portal.voucherBuyInfo')"-->
<!--                              :id="`${key}-header-info`"-->
<!--                              :name="`${key}-header-info`"-->
<!--                              :readonly="isDisable"-->
<!--                              class="form-control input"-->
<!--                              placeholder="Information"-->
<!--                              rows="4"-->
<!--                              type="text"-->
<!--                              v-model="currentAuthorizations[key].info"-->
<!--                              v-validate="'required'"-->
<!--                            />-->
<!--                            <span v-show="errors.has(`${key}-header-info`)" class="help is-danger">-->
<!--                              {{ errors.first(`${key}-header-info`) }}-->
<!--                            </span>-->
<!--                          </div>-->
<!--                          <div class="form-group mt-1 mb-q">-->
<!--                            <Switch-component-->
<!--                              v-model="currentAuthorizations[key].notification.enable"-->
<!--                              :disabled="isDisable"-->
<!--                              :label="$t('portal.notification')"-->
<!--                              :id="`notification-${key}`"-->
<!--                            />-->
<!--                          </div>-->
<!--                          <template v-if="currentAuthorizations[key].notification.enable">-->
<!--                            <div class="form-group mt-h ml-1">-->
<!--                              <label :for="`${key}-notification-text`">{{ $t('portal.notificationText') }}</label>-->
<!--                              <input-->
<!--                                :class="{ 'is-danger': errors.has(`${key}-notification-text`) }"-->
<!--                                :data-vv-as="$t('portal.notificationText')"-->
<!--                                :id="`${key}-notification-text`"-->
<!--                                :name="`${key}-notification-text`"-->
<!--                                :readonly="isDisable"-->
<!--                                class="form-control input"-->
<!--                                placeholder="SMS text"-->
<!--                                size="2"-->
<!--                                type="text"-->
<!--                                v-model="currentAuthorizations[key].notification.text"-->
<!--                                v-validate="'required'"-->
<!--                              />-->
<!--                              <span v-show="errors.has(`${key}-notification-text`)" class="help is-danger">-->
<!--                                {{ errors.first(`${key}-notification-text`) }}-->
<!--                              </span>-->
<!--                            </div>-->
<!--                            <div class="form-group mt-h ml-1">-->
<!--                              <label :for="`${key}-notification-time`">{{ $t('portal.notificationTime') }}</label>-->
<!--                              <input-->
<!--                                :data-vv-as="$t('portal.notificationTime')"-->
<!--                                :class="{ 'is-danger': errors.has(`${key}-notification-time`) }"-->
<!--                                :id="`${key}-notification-time`"-->
<!--                                :name="`${key}-notification-time`"-->
<!--                                :readonly="isDisable"-->
<!--                                class="form-control input"-->
<!--                                placeholder="10"-->
<!--                                size="1"-->
<!--                                type="number"-->
<!--                                v-model.number="currentAuthorizations[key].notification.seconds_before_stop"-->
<!--                              />-->
<!--                              <span v-show="errors.has(`${key}-notification-time`)" class="help is-danger">-->
<!--                                {{ errors.first(`${key}-notification-time`) }}-->
<!--                              </span>-->
<!--                            </div>-->
<!--                          </template>-->
<!--                        </div>-->
<!--                        <div class="row mb-1">-->
<!--                          <div class="col-4">-->
<!--                            &lt;!&ndash;                            <button&ndash;&gt;-->
<!--                            &lt;!&ndash;                              class="btn btn-success btn-block"&ndash;&gt;-->
<!--                            &lt;!&ndash;                              type="button"&ndash;&gt;-->
<!--                            &lt;!&ndash;                              @click="closeAuthorizationItem(key)"&ndash;&gt;-->
<!--                            &lt;!&ndash;                            >&ndash;&gt;-->
<!--                            &lt;!&ndash;                              <i class="fa fa-long-arrow-up"></i>&ndash;&gt;-->
<!--                            &lt;!&ndash;                              {{ $t('portalProfiles.cutDownButton') }}&ndash;&gt;-->
<!--                            &lt;!&ndash;                              <i class="fa fa-long-arrow-up"></i>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </button>&ndash;&gt;-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->


                  <div v-if="currentAuthorizations[key]">
                    <div v-show="openedAuthorizations[key]">
                      <!-- filteredSettings used to temporarily exclude download_limit & auth_timeout keys -->
                      <template v-if="key !== 'voucher' &&  key !=='subscription'" >
                        <div class="col-12">
                          <div class="mb-1 authorization-categories">{{ $t('portalProfiles.sessionSettings') }}:</div>
                          <div>
                            <div class="row" v-for="(translate, setting) in filteredSettings" :key="setting">
                              <div class="col-xs-12 col-5 form-group">
                                <label class="authZ-settings-fields-height-hack" :for="key">
                                  {{ $t(`${translate}`) }}
                                </label>
                                <input
                                  type="number"
                                  :name="`${key}-${setting}`"
                                  :id="setting"
                                  :placeholder="$t(`${translate}`)"
                                  :readonly="isDisable"
                                  class="form-control input"
                                  v-validate="'required|numeric'"
                                  :class="{ 'is-danger': errors.has(`${key}-${setting}`) }"
                                  v-model.number="currentAuthorizations[key].config[setting]"
                                />
                                <small v-if="hasCaption(translate)" class="text-muted">
                                  {{ $t(`${translate}Caption`) }}
                                </small>
                              </div>
                              <div v-if="setting !== 'max_sessions'" class="col-xs-12 col-7 form-group">
                                <!--                                <template-->
                                <!--                                  v-if="-->
                                <!--                                    !chooseIntervalShowingForAuthorizations ||-->
                                <!--                                    !chooseIntervalShowingForAuthorizations[key] ||-->
                                <!--                                    !chooseIntervalShowingForAuthorizations[key][setting]-->
                                <!--                                  "-->
                                <!--                                >-->
                                <!--                                  <label for="amount-time">-->
                                <!--                                    {{ $t('drPicker.chooseInterval') }}-->
                                <!--                                  </label>-->
                                <!--                                  <div class="row">-->
                                <!--                                    <div class="col-6">-->
                                <!--                                      <button-->
                                <!--                                        class="btn btn-success btn-block"-->
                                <!--                                        type="button"-->
                                <!--                                        @click="-->
                                <!--                                          currentAuthorizations[key].config[setting] = moment(0).add(12, 'hours').unix()-->
                                <!--                                        "-->
                                <!--                                      >-->
                                <!--                                        {{ $t('general.duration12h') }}-->
                                <!--                                      </button>-->
                                <!--                                    </div>-->
                                <!--                                    <div class="col-6">-->
                                <!--                                      <button-->
                                <!--                                        class="btn btn-success btn-block"-->
                                <!--                                        type="button"-->
                                <!--                                        @click="-->
                                <!--                                          currentAuthorizations[key].config[setting] = moment(0).add(1, 'days').unix()-->
                                <!--                                        "-->
                                <!--                                      >-->
                                <!--                                        {{ $t('general.durationDay') }}-->
                                <!--                                      </button>-->
                                <!--                                    </div>-->
                                <!--                                    <div class="col-6 mt-h">-->
                                <!--                                      <button-->
                                <!--                                        class="btn btn-success btn-block"-->
                                <!--                                        type="button"-->
                                <!--                                        @click="-->
                                <!--                                          currentAuthorizations[key].config[setting] = moment(0).add(31, 'days').unix()-->
                                <!--                                        "-->
                                <!--                                      >-->
                                <!--                                        {{ $t('general.durationMonth') }}-->
                                <!--                                      </button>-->
                                <!--                                    </div>-->
                                <!--                                    <div class="col-6 mt-h">-->
                                <!--                                      <button-->
                                <!--                                        class="btn btn-success btn-block"-->
                                <!--                                        type="button"-->
                                <!--                                        @click="-->
                                <!--                                          currentAuthorizations[key].config[setting] = moment(0).add(365, 'days').unix()-->
                                <!--                                        "-->
                                <!--                                      >-->
                                <!--                                        {{ $t('general.durationYear') }}-->
                                <!--                                      </button>-->
                                <!--                                    </div>-->
                                <!--                                    <div class="col-12 mt-h">-->
                                <!--                                      <button-->
                                <!--                                        class="btn btn-success btn-block"-->
                                <!--                                        type="button"-->
                                <!--                                        @click="setChooseIntervalShowingForAuthorizations(key, setting)"-->
                                <!--                                      >-->
                                <!--                                        {{ $t('portalProfiles.customTimeInterval') }}-->
                                <!--                                      </button>-->
                                <!--                                    </div>-->
                                <!--                                  </div>-->
                                <!--                                </template>-->
                                <!--                                <template-->
                                <!--                                  v-if="-->
                                <!--                                    chooseIntervalShowingForAuthorizations &&-->
                                <!--                                    chooseIntervalShowingForAuthorizations[key] &&-->
                                <!--                                    chooseIntervalShowingForAuthorizations[key][setting] === true-->
                                <!--                                  "-->
                                <!--                                >-->
                                <!--                                  <label for="amount-time">-->
                                <!--                                    {{ $t('portalProfiles.chooseCustomInterval') }}-->
                                <!--                                    <i-->
                                <!--                                      class="fa fa-remove text-danger ml-q"-->
                                <!--                                      @click="$set(chooseIntervalShowingForAuthorizations[key], setting, false)"-->
                                <!--                                    ></i>-->
                                <!--                                  </label>-->
                                <!--                                  <div class="col-12">-->
                                <!--                                    <div class="row">-->
                                <!--                                      <input-->
                                <!--                                        v-model="currentAuthorizations[key].customTimeInterval[setting].amount"-->
                                <!--                                        name="amount-time"-->
                                <!--                                        placeholder="Choose amount"-->
                                <!--                                        class="col-4 form-control"-->
                                <!--                                        size="1"-->
                                <!--                                      />-->
                                <!--                                      <select-->
                                <!--                                        v-model="currentAuthorizations[key].customTimeInterval[setting].metrics"-->
                                <!--                                        name="metrics-time"-->
                                <!--                                        class="col-4 form-control"-->
                                <!--                                        placeholder="of units"-->
                                <!--                                      >-->
                                <!--                                        <option :value="unit" :key="unit" v-for="unit in timeUnitNames">-->
                                <!--                                          {{ $t(`drPicker.${unit}`) }}-->
                                <!--                                        </option>-->
                                <!--                                      </select>-->
                                <!--                                      <button-->
                                <!--                                        class="col-4 btn btn-success"-->
                                <!--                                        @click="handleCustomTimeAuthorization(key, setting)"-->
                                <!--                                      >-->
                                <!--                                        {{ $t('drPicker.apply') }}-->
                                <!--                                      </button>-->
                                <!--                                    </div>-->
                                <!--                                  </div>-->
                                <!--                                </template>-->
                                <template
                                  v-if="
                                    !chooseIntervalShowingForAuthorizations ||
                                    !chooseIntervalShowingForAuthorizations[key] ||
                                    !chooseIntervalShowingForAuthorizations[key][setting] ||
                                    chooseIntervalShowingForAuthorizations[key][setting] === false
                                  "
                                >
                                  <label class="authZ-settings-fields-height-hack" for="amount-time">
                                    {{ $t('drPicker.chooseInterval') }}
                                  </label>
                                  <div class="d-flex justify-content-start flex-wrap">
                                    <button
                                      class="btn btn-success portal-profiles-interval-select-buttons"
                                      type="button"
                                      @click="
                                        currentAuthorizations[key].config[setting] = moment(0).add(12, 'hours').unix()
                                      "
                                    >
                                      {{ $t('general.duration12h') }}
                                    </button>

                                    <button
                                      class="btn btn-success portal-profiles-interval-select-buttons"
                                      type="button"
                                      @click="
                                        currentAuthorizations[key].config[setting] = moment(0).add(1, 'days').unix()
                                      "
                                    >
                                      {{ $t('general.durationDay') }}
                                    </button>

                                    <button
                                      class="btn btn-success portal-profiles-interval-select-buttons"
                                      type="button"
                                      @click="
                                        currentAuthorizations[key].config[setting] = moment(0).add(31, 'days').unix()
                                      "
                                    >
                                      {{ $t('general.durationMonth') }}
                                    </button>

                                    <button
                                      class="btn btn-success portal-profiles-interval-select-buttons"
                                      type="button"
                                      @click="
                                        currentAuthorizations[key].config[setting] = moment(0).add(365, 'days').unix()
                                      "
                                    >
                                      {{ $t('general.durationYear') }}
                                    </button>

                                    <button
                                      class="btn btn-success portal-profiles-interval-select-buttons"
                                      type="button"
                                      @click="setChooseIntervalShowingForAuthorizations(key, setting)"
                                    >
                                      {{ $t('portalProfiles.customTimeInterval') }}
                                    </button>
                                  </div>
                                </template>
                                <template
                                  v-if="
                                    chooseIntervalShowingForAuthorizations &&
                                    chooseIntervalShowingForAuthorizations[key] &&
                                    chooseIntervalShowingForAuthorizations[key][setting] === true
                                  "
                                >
                                  <label class="authZ-settings-fields-height-hack" for="amount-time">
                                    {{ $t('portalProfiles.chooseCustomInterval') }}
                                    <i
                                      role="button"
                                      class="fa fa-remove text-danger ml-q"
                                      @click="$set(chooseIntervalShowingForAuthorizations[key], setting, false)"
                                    ></i>
                                  </label>
                                  <div class="col-12">
                                    <div class="row">
                                      <input
                                        v-model="currentAuthorizations[key].customTimeInterval[setting].amount"
                                        name="amount-time"
                                        placeholder="Choose amount"
                                        class="col-4 form-control"
                                        size="1"
                                      />
                                      <select
                                        v-model="currentAuthorizations[key].customTimeInterval[setting].metrics"
                                        name="metrics-time"
                                        class="col-4 form-control"
                                        placeholder="of units"
                                      >
                                        <!--                                      <option value="seconds">{{ $t('drPicker.seconds') }}</option>-->
                                        <!--                                      <option value="minutes">{{ $t('drPicker.minutes') }}</option>-->
                                        <!--                                      <option value="hours">{{ $t('drPicker.hours') }}</option>-->
                                        <!--                                      <option value="days">{{ $t('drPicker.days') }}</option>-->
                                        <!--                                      <option value="weeks">{{ $t('drPicker.weeks') }}</option>-->
                                        <!--                                      <option value="months">{{ $t('drPicker.months') }}</option>-->
                                        <!--                                      <option value="years">{{ $t('drPicker.years') }}</option>-->
                                        <option :value="unit" :key="unit" v-for="unit in timeUnitNames">
                                          {{ $t(`drPicker.${unit}`) }}
                                        </option>
                                      </select>
                                      <button
                                        class="btn btn-success portal-profiles-interval-select-buttons"
                                        @click="handleCustomTimeAuthorization(key, setting)"
                                      >
                                        {{ $t('drPicker.apply') }}
                                      </button>
                                    </div>
                                  </div>
                                </template>
                                <span v-show="errors.has(`${key}-${setting}`)" class="help is-danger">
                                  {{ errors.first(`${key}-${setting}`) }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </template>
                      <template v-if="key === 'voucher'">
                        <!-- SMS code (voucher code) -->
                        <div class="col-12">
                          <div class="authorization-categories">{{ $t('portal.voucherCodeText') }}:</div>
                          <div class="row">
                            <div class="col-6">
                              <!--<label class = 'authorization-categories' :for="'voucher-code-text'">{{ $t('portal.voucherCodeText') }}</label>-->
                              <input
                                type="text"
                                name="voucher-code-text"
                                id="voucher-code-text"
                                placeholder="Code: {CODE}"
                                :readonly="isDisable"
                                class="form-control input"
                                v-validate="'required|sms_code'"
                                :data-vv-as="$t('portal.voucherCodeText')"
                                size="1"
                                :class="{ 'is-danger': errors.has('voucher-code-text') }"
                                v-model="updatedProfile.push_text.voucher"
                              />
                              <span
                                v-tooltip.top-center="{
                                  content: errors.first('voucher-code-text')
                                }"
                                v-show="errors.has('voucher-code-text')"
                                class="help is-danger error_tooltip"
                              >
                                <i class="fa fa-warning text-error"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- Payment system -->
                        <div class="col-12">
                          <div class="authorization-categories">{{ $t('portal.paymentSystems') }}:</div>
                          <div class="row">
                            <div class="col-12">
                              <multiselect
                                :class="{
                                  'is-danger': errors.has('payment-systems'),
                                  'multiselect-disabled': isDisable
                                }"
                                :custom-label="
                                  (option) => getSystemByID(option).identity || $t('general.noDataToDisplay')
                                "
                                :data-vv-name="$t('portal.paymentSystems')"
                                :disabled="isDisable"
                                :hide-selected="true"
                                :multiple="true"
                                :options="paymentSystemsIDs || []"
                                :placeholder="$t('portal.paymentSystems')"
                                class="input"
                                id="payment-systems"
                                v-model="updatedProfile.payment_systems"
                              >
                                <template slot="option" slot-scope="props">
                                  <div class="d-flex">
                                    <div
                                      class="payment-logo-preview"
                                      :style="`background-image: url('${getSystemByID(props.option).image}')`"
                                    />

                                    <div class="ml-h">
                                      <span class="d-block mb-h">{{ getSystemByID(props.option).identity }}</span>
                                      <small v-if="getSystemByID(props.option).name">
                                        {{ $t('paymentSystems.name') }}: {{ getSystemByID(props.option).name }}
                                      </small>
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </template>
                                                                          <div class="col-xs-12 col-6 form-group mt-2">
                            <Switch-component
                              v-model="currentAuthorizations[key].transferable"
                              :disabled="isDisable"
                              :label="$t(`portal.transferable`)"
                              :id="`authz-general-settings-transferable`"
                            />
                            <info class="ml-h" :content="$t('portal.transferableInfo')"/>
                          </div>
                      <div class="col-12">
                        <div v-if="!socials.includes(key)" class="authorization-categories">
                          {{ $t('portalProfiles.advertismentSettings') }}:
                        </div>
                        <div v-if="!socials.includes(key)" class="form-group">
                          <label :for="`${key}-ads`">{{ $t('adsprofile.ads') }}</label>
                          <multiselect
                            data-vv-name="ads"
                            v-model="currentAuthorizations[key].ads"
                            :options="portalAdsList || []"
                            label="name"
                            :id="`${key}-ads`"
                            track-by="name"
                            :multiple="true"
                            :hide-selected="true"
                            class="input"
                            :class="{ 'is-danger': errors.has('ads'), 'multiselect-disabled': isDisable }"
                            :placeholder="$t('adsprofile.ads')"
                            :disabled="isDisable"
                          >
                            <template slot="option" slot-scope="props">
                              <span class="d-block mb-h">{{ props.option.name }}</span>
                              <small>
                                {{ $t('adsprofile.priority') }}: {{ props.option.priority }},
                                {{ getAdStatus(props.option) }}
                              </small>
                            </template>
                          </multiselect>
                        </div>

                        <div
                          v-if="!socials.includes(key) && currentAuthorizations[key].hasOwnProperty('ads_to_watch')"
                          class="row"
                        >
                          <div class="col-xs-12 col-lg-4">
                            <label for="ads_to_watch">{{ $t('portal.ads_to_watch') }}</label>
                            <input
                              :class="{ 'is-danger': errors.has(`${key}-ads_to_watch`) }"
                              :name="`${key}-ads_to_watch`"
                              :placeholder="$t('portal.ads_to_watch')"
                              :readonly="isDisable"
                              class="form-control input"
                              :data-vv-as="$t('portal.ads_to_watch')"
                              id="ads_to_watch"
                              type="number"
                              v-model.number="currentAuthorizations[key].ads_to_watch"
                              v-validate="`required|between:0,${currentAuthorizations[key].ads.length}`"
                            />
                            <span v-show="errors.has(`${key}-ads_to_watch`)" class="help is-danger">
                              {{ errors.first(`${key}-ads_to_watch`) }}
                            </span>
                          </div>
                          <div class="col-xs-12 col-lg-8 form-group mb-0">
                            <div class="mt-1">
                              <Switch-component
                                v-model="currentAuthorizations[key].enable_rotation"
                                :disabled="isDisable"
                                :label="$t('portal.adsRotation')"
                                :id="`ads-rotation-${key}`"
                              />
                            </div>
                            <div class="mt-1">
                              <Switch-component
                                v-model="currentAuthorizations[key].enable_ad_follow"
                                :disabled="isDisable"
                                :label="$t('portal.adsFollow')"
                                :id="`ads-follow-${key}`"
                              />
                            </div>
                            <div class="mt-1">
                              <Switch-component
                                v-model="currentAuthorizations[key].skip_final_page"
                                :disabled="isDisable"
                                :label="$t('portal.skipFinalPage')"
                                :id="`skip-final-page-${key}`"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="d-flex flex-column" v-if="key === 'vk' || key === 'facebook'">
                          <div class="btn-group float-left" data-toggle="button">
                            <label class="btn btn-outline-primary" :class="{ active: socialAuth[key].type === 'auth' }">
                              <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth')" />
                              {{ $t('portal.authOnly') }}
                            </label>
                            <label
                              class="btn btn-outline-primary"
                              :class="{ active: socialAuth[key].type === 'auth_post' }"
                            >
                              <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth_post')" />
                              {{ $t('portal.authPost') }}
                            </label>
                            <label
                              class="btn btn-outline-primary"
                              :class="{ active: socialAuth[key].type === 'auth_group' }"
                            >
                              <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth_group')" />
                              {{ $t('portal.authGroup') }}
                            </label>
                          </div>

                          <div v-if="socialAuth[key].type === 'auth'" class="form-group mt-h">
                            <label :for="`${key}-social-redirect_url`" class="required-mark">
                              {{ $t('portal.auth.redirectURL') }}
                            </label>
                            <input
                              :class="{ 'is-danger': errors.has(`${key}-social-redirect_url`) }"
                              :data-vv-as="`${$t('portal.auth.redirectURL')}`"
                              :id="`${key}-social-redirect_url`"
                              :name="`${key}-social-redirect_url`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="Redirect URL"
                              type="text"
                              v-model="currentAuthorizations[key].social_network_config.redirect_url"
                              v-validate="{ required: true, min: 3 }"
                            />
                            <span v-show="errors.has(`${key}-social-redirect_url`)" class="help is-danger">
                              {{ errors.first(`${key}-social-redirect_url`) }}
                            </span>
                          </div>

                          <div v-if="socialAuth[key].type === 'auth_post'">
                            <div class="form-group mt-h">
                              <label :for="`${key}-social-post-message`" class="required-mark">
                                {{ $t('portal.auth.message') }}
                              </label>
                              <textarea
                                :class="{ 'is-danger': errors.has(`${key}-social-post-message`) }"
                                :data-vv-as="$t('portal.auth.message')"
                                :id="`${key}-social-post-message`"
                                :name="`${key}-social-post-message`"
                                :readonly="isDisable"
                                class="form-control input"
                                placeholder="Post message"
                                rows="2"
                                type="text"
                                v-model="currentAuthorizations[key].social_network_config.post.message"
                                v-validate="{ required: true, min: 3 }"
                              />
                              <span v-show="errors.has(`${key}-social-post-message`)" class="help is-danger">
                                {{ errors.first(`${key}-social-post-message`) }}
                              </span>
                            </div>

                            <div class="form-group mt-h">
                              <label :for="`${key}-social-post-url`" class="required-mark">
                                {{ $t('portal.auth.attachedURL') }}
                              </label>
                              <input
                                :class="{ 'is-danger': errors.has(`${key}-social-post-url`) }"
                                :data-vv-as="$t('portal.auth.attachedURL')"
                                :id="`${key}-social-post-url`"
                                :name="`${key}-social-post-url`"
                                :readonly="isDisable"
                                class="form-control input"
                                placeholder="Post URL"
                                rows="2"
                                type="text"
                                v-model="currentAuthorizations[key].social_network_config.post.attached_url"
                                v-validate="{ required: true, min: 3 }"
                              />
                              <span v-show="errors.has(`${key}-social-post-url`)" class="help is-danger">
                                {{ errors.first(`${key}-social-post-url`) }}
                              </span>
                            </div>
                          </div>

                          <div v-if="socialAuth[key].type === 'auth_group'" class="form-group mt-h">
                            <label :for="`${key}-social-group_url`" class="required-mark">
                              {{ $t('portal.auth.groupURL') }}
                            </label>
                            <input
                              :class="{ 'is-danger': errors.has(`${key}-social-group_url`) }"
                              :data-vv-as="$t('portal.auth.groupURL')"
                              :id="`${key}-social-group_url`"
                              :name="`${key}-social-group_url`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="Group URL"
                              type="text"
                              v-model="currentAuthorizations[key].social_network_config.group_url"
                              v-validate="{ required: true, socialAuthURL: true, min: 3 }"
                            />
                            <span v-show="errors.has(`${key}-social-group_url`)" class="help is-danger">
                              {{ errors.first(`${key}-social-group_url`) }}
                            </span>
                          </div>
                        </div>

                        <div v-if="!socials.includes(key)" class="form-group mt-h">
                          <label :for="`${key}-redirect_url`">{{ $t('portal.redirect') }}</label>
                          <input
                            :class="{ 'is-danger': errors.has(`${key}-redirect_url`) }"
                            :data-vv-as="$t('portal.redirect')"
                            :id="`${key}-redirect_url`"
                            :name="`${key}-redirect_url`"
                            :readonly="isDisable"
                            class="form-control input"
                            placeholder="Redirect URL"
                            type="text"
                            v-model="currentAuthorizations[key].redirect_url"
                            v-validate="'required'"
                          />
                          <span v-show="errors.has(`${key}-redirect_url`)" class="help is-danger">
                            {{ errors.first(`${key}-redirect_url`) }}
                          </span>
                        </div>

                        <div v-if="key === 'voucher'" class="form-group mt-h">
                          <div class="authorization-categories">{{ $t('portalProfiles.vouchersSettings') }}:</div>
                          <div class="form-group mt-h">
                            <label :for="`${key}-header-text`">{{ $t('portal.voucherBuyHeader') }}</label>
                            <textarea
                              :class="{ 'is-danger': errors.has(`${key}-header-text`) }"
                              :data-vv-as="$t('portal.voucherBuyHeader')"
                              :id="`${key}-header-text`"
                              :name="`${key}-header-text`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="Header"
                              rows="2"
                              type="text"
                              v-model="currentAuthorizations[key].header"
                              v-validate="'required'"
                            />
                            <span v-show="errors.has(`${key}-header-text`)" class="help is-danger">
                              {{ errors.first(`${key}-header-text`) }}
                            </span>
                          </div>
                          <div class="form-group mt-h">
                            <label :for="`${key}-header-info`">{{ $t('portal.voucherBuyInfo') }}</label>
                            <textarea
                              :class="{ 'is-danger': errors.has(`${key}-header-info`) }"
                              :data-vv-as="$t('portal.voucherBuyInfo')"
                              :id="`${key}-header-info`"
                              :name="`${key}-header-info`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="Information"
                              rows="4"
                              type="text"
                              v-model="currentAuthorizations[key].info"
                              v-validate="'required'"
                            />
                            <span v-show="errors.has(`${key}-header-info`)" class="help is-danger">
                              {{ errors.first(`${key}-header-info`) }}
                            </span>
                          </div>
                          <div class="form-group mt-1 mb-q">
                            <Switch-component
                              v-model="currentAuthorizations[key].notification.enable"
                              :disabled="isDisable"
                              :label="$t('portal.notification')"
                              :id="`notification-${key}`"
                            />
                          </div>
                          <template v-if="currentAuthorizations[key].notification.enable">
                            <div class="form-group mt-h ml-1">
                              <label :for="`${key}-notification-text`">{{ $t('portal.notificationText') }}</label>
                              <input
                                :class="{ 'is-danger': errors.has(`${key}-notification-text`) }"
                                :data-vv-as="$t('portal.notificationText')"
                                :id="`${key}-notification-text`"
                                :name="`${key}-notification-text`"
                                :readonly="isDisable"
                                class="form-control input"
                                placeholder="SMS text"
                                size="2"
                                type="text"
                                v-model="currentAuthorizations[key].notification.text"
                                v-validate="'required'"
                              />
                              <span v-show="errors.has(`${key}-notification-text`)" class="help is-danger">
                                {{ errors.first(`${key}-notification-text`) }}
                              </span>
                            </div>
                            <div class="form-group mt-h ml-1">
                              <label :for="`${key}-notification-time`">{{ $t('portal.notificationTime') }}</label>
                              <input
                                :data-vv-as="$t('portal.notificationTime')"
                                :class="{ 'is-danger': errors.has(`${key}-notification-time`) }"
                                :id="`${key}-notification-time`"
                                :name="`${key}-notification-time`"
                                :readonly="isDisable"
                                class="form-control input"
                                placeholder="10"
                                size="1"
                                type="number"
                                v-model.number="currentAuthorizations[key].notification.seconds_before_stop"
                              />
                              <span v-show="errors.has(`${key}-notification-time`)" class="help is-danger">
                                {{ errors.first(`${key}-notification-time`) }}
                              </span>
                            </div>
                          </template>
                        </div>
                        <div class="row mb-1">
                          <div class="col-4">
                            <!--                            <button-->
                            <!--                              class="btn btn-success btn-block"-->
                            <!--                              type="button"-->
                            <!--                              @click="closeAuthorizationItem(key)"-->
                            <!--                            >-->
                            <!--                              <i class="fa fa-long-arrow-up"></i>-->
                            <!--                              {{ $t('portalProfiles.cutDownButton') }}-->
                            <!--                              <i class="fa fa-long-arrow-up"></i>-->
                            <!--                            </button>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center validatorErrors mb-1" v-if="validatorErrors">
              <div>{{ $t('portalProfiles.validatorErrors') }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <!--                <button-->
                <!--                  type="button"-->
                <!--                  class="btn btn-outline-secondary float-left"-->
                <!--                  :disabled="$parent.step3SavedWlanObject !== false"-->
                <!--                  @click="prevStep"-->
                <!--                >-->
                <!--                  {{ $t('general.back') }}-->
                <!--                </button>-->
              </div>
              <div class="">
                <div class="validatorErrors mb-1"
                     v-if="errors.any() || isValidationErrorsInPortalProfileGateWaySettingsComponents">
                  {{ $t('portalProfiles.validatorErrors') }}
                </div>
                <button
                  type="button"
                  class="btn btn-outline-primary float-right"
                  @click="handleSaveProfile"
                  :disabled="errors.any() || isValidationErrorsInPortalProfileGateWaySettingsComponents"
                >
                  {{ $t('easyInstallWizard.step5NextButton') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
// import VueNotifications from 'vue-notifications';
// import locationService from '../../services/locationService';
// import usersService from '../../services/usersService';
import { Multiselect } from 'vue-multiselect';
import portalProfilesService from '../../services/portalProfilesService';
import portalPaymentSystemsService from '../../services/portalPaymentSystemsService';
import cpeService from '../../services/cpeService';
import wlanService from '../../services/wlanService';
import helpers from '../../helpers';
import getAdStatus from '../../helpers/getAdStatus';
import Info from '../../components/Universal/info-icon.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
// import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
import portalAdsService from '../../services/portalAdsService';
import EasyInstallWizardHelpers from './EasyInstallWizardHelpers';
import PortalProfileGatewaySettings from '../../components/Portal/PortalProfileGatewaySettings.vue';

export default {
  name: 'EasyInstallWizardstep5',
  components: {
    SwitchComponent,
    Info,
    Multiselect,
    PortalProfileGatewaySettings
    // MultiSelectComponent
  },
  data() {
    return {
      hasValidationErrorsInPortalProfileGateWaySettingsComponents: {
        // если в компонентах portalProfileGatewaySettings появляется ошибка валидатора
        // то тут создается записиь вида имя: true
        // а computed isValidationErrorsInPortalProfileGateWaySettingsComponents следит за этим
        // и с его помощью блокируются кнопки Сохранить и Создать в модалках редактировния профилей
      },
      checkAuthNsWhenAuthenSkipIsFalseError: '',
      isAllAuhNsSwitchersLocked: false,
      validatorErrors: false,
      timeUnitNames: ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'],
      socialAuth: {
        vk: { type: 'auth' },
        facebook: { type: 'auth' }
      },
      socials: ['facebook', 'vk', 'instagram'],
      authorizationSettings: {
        timeout: 'portal.timeout',
        auth_timeout: 'portal.auth_timeout',
        block_after: 'portal.block_after',
        block_expire: 'portal.block_expire',
        download_limit: 'portal.download_limit',
        max_sessions: 'portal.max_sessions'
      },
      chooseIntervalShowingForAuthentication: {},
      chooseIntervalShowingForAuthorizations: {},
      updatedProfile: {
        authn_skip: false,
        base_location: '',
        name: '',
        description: '',
        access_list: {},
        black_list: {},
        white_list: {},
        push_text: { otp: '', voucher: '' },
        terms_of_service: { enable: false },
        authentications: [],
        authorizations: [],
        condition: {
          cpe: [],
          wlan: [],
          nas_id: [],
          loc_id: []
        },
        msisdn_config: {
          prefix: {}
        },
        updatedProfile: [],
        random_mac_block_page: false
      },
      currentAuthentications: {},
      currentAuthorizations: {},
      openedAuthorizations: {},
      openedAuthentications: {}
    };
  },
  computed: {
    isValidationErrorsInPortalProfileGateWaySettingsComponents() {
      if (Object.keys(this.hasValidationErrorsInPortalProfileGateWaySettingsComponents).length) {
        return true;
      }
      return false;
    },
    showSpinnerConditionsAreSatisfied() {
      if (
        // this.isWlansLoading ||
        this.isPortalAdsLoading ||
        this.isPortalPaymentSystemsLoading ||
        this.isAuthenticationsLoading ||
        this.isAuthorizationsLoading
      ) {
        return true;
      }
      return false;
    },
    // isCpeLoading() {
    //   return this.$store.state.loadingCpes;
    // },
    isWlansLoading() {
      return this.$store.state.loadingWlans;
    },
    isPortalAdsLoading() {
      return this.$store.state.loadingPortalAds;
    },
    isPortalPaymentSystemsLoading() {
      return this.$store.state.loadingPortalPaymentSystems;
    },
    isAuthenticationsLoading() {
      return this.$store.state.loadingAuthentications;
    },
    isAuthorizationsLoading() {
      return this.$store.state.loadingAuthorizations;
    },
    hasCheckAuthNsWhenAuthenSkipIsFalseError() {
      if (this.checkAuthNsWhenAuthenSkipIsFalseError !== '' && !this.checkAuthNsWhenAuthenSkipIsFalse()) {
        return true;
      }
      return false;
    },
    isSelectedAuthNsWhenAuthenSkipIsFalse() {
      // проверяем выбрана ли хотя бы одна аутентификация если выключен свитчер authn_skip
      // другими словами выбрано ли достаточно аутентификаций:
      // при authn_skip === false должно быть выключено больше нуля
      // при authn_skip === true неважно? может быть и ноль
      // если условия удовлетворяются возвращаем true, иначе false
      try {
        if (this.updatedProfile.authn_skip !== true) {
          if (Object.entries(this.currentAuthentications).length > 0) {
            return true;
          }
          return false;
        }
        return true;
      } catch (e) {
        return true;
      }
    },
    isAppAuthFreemiumEnabled() {
      return this.$store.state.isAppAuthFreemiumEnabled;
    },
    portalAdsList() {
      return this.$store.state.portalAdsList;
    },
    filteredSettings() {
      const settings = { ...this.authorizationSettings };
      delete settings.download_limit;
      delete settings.auth_timeout;
      return settings;
    },
    isauthNEnabled() {
      if (this.updatedProfile && Object.entries(this.currentAuthentications).length > 0) {
        return true;
      }
      return false;
    },
    isAuthNNeedingOTPCodeEnabled() {
      if (
        this.isauthNEnabled &&
        (Object.keys(this.currentAuthentications).includes('sms') ||
          Object.keys(this.currentAuthentications).includes('email'))
      ) {
        return true;
      }
      return false;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    paymentSystemsList() {
      return this.$store.state.portalPaymentSystemsList;
    },
    paymentSystemsIDs() {
      return this.paymentSystemsList.map((it) => (it.id ? it.id : ''));
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    portalAuthorizationsList() {
      return helpers.transformArrayToObjectByKey(this.$store.state.portalAuthorizationsList, 'type');
    },
    portalAuthenticationsList() {
      return helpers.transformArrayToObjectByKey(this.$store.state.portalAuthenticationsList, 'type');
    }
  },
  methods: {
    hasNoErrorsProfileGatewaySettingsHandler(authNTypeName) {
      // console.log('here no errors')
      this.$delete(this.hasValidationErrorsInPortalProfileGateWaySettingsComponents, authNTypeName)
    },
    hasErrorsProfileGatewaySettingsHandler(authNTypeName) {
      // console.log('here errors')
      this.$set(this.hasValidationErrorsInPortalProfileGateWaySettingsComponents, authNTypeName, true)
    },
    authNSkipSwitched(switchedValue) {
      // вызывается не только на самом свитчере но и из функций типа sameAsSelectedForAuthN
      // console.log(switcherValue)
      if (switchedValue === true) {
        // блочим все свитчеры
        this.lockAllAuhNsSwitchers();
        // закрываем все раскрытые настройки
        this.closeAllAuthNBlocksSettings();
        this.closeGeneralAuthNSettingsBlock();
      } else {
        this.unlockAllAuhNSwitchers();
      }
    },
    lockAllAuhNsSwitchers() {
      this.isAllAuhNsSwitchersLocked = true;
    },
    closeAllAuthNBlocksSettings() {
      // закрываем все равернутые настройки в списке всех аутнтификаций
      for (const openAuthNSettingsName in this.openedAuthentications) {
        this.$set(this.openedAuthentications, openAuthNSettingsName, false);
      }
    },
    closeGeneralAuthNSettingsBlock() {
      this.isGeneralAuthNSettingsBlockOpened = false;
    },
    unlockAllAuhNSwitchers() {
      this.isAllAuhNsSwitchersLocked = false;
    },
    checkAuthNsWhenAuthenSkipIsFalse() {
      if (this.isSelectedAuthNsWhenAuthenSkipIsFalse === true) {
        return true;
      }
      return false;
    },
    checkAuthNsWhenAuthenSkipIsFalseSetError() {
      this.checkAuthNsWhenAuthenSkipIsFalseError = this.$t('portalProfiles.checkAuthNsWhenAuthenSkipIsFalseErrorText');
      this.$nextTick(() => {
        const error = document.getElementById('checkAuthNsWhenAuthenSkipIsFalseError');
        error.scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
    },
    checkAuthNsWhenAuthenSkipIsFalseClearError() {
      this.checkAuthNsWhenAuthenSkipIsFalseError = '';
    },
    isShowThisAuthorizationType(authorizationType) {
      // для режима AuthFreemium показываем только free-авторизацию
      if (this.isAppAuthFreemiumEnabled) {
        if (authorizationType === 'free') {
          return true;
        }
        return false;
      }
      return true;
    },
    isShowThisAuthenticationType(authenticationType) {
      // для режима AuthFreemium показываем только callfront
      if (this.isAppAuthFreemiumEnabled) {
        if (authenticationType === 'callfront') {
          return true;
        }
        return false;
      }
      return true;
    },
    handleSaveProfile() {
      // this.updatedProfile.base_location = this.$parent.step1SavedLocationObject.id;
      // this.updatedProfile.base_location = this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject.id;
      this.createProfile(this.updatedProfile);
    },
    createProfile(profile) {
      if (!this.checkAuthNsWhenAuthenSkipIsFalse()) {
        // слишком мало аутентификаций выставляем ошибку
        this.checkAuthNsWhenAuthenSkipIsFalseSetError();
        return;
      }
      this.checkAuthNsWhenAuthenSkipIsFalseClearError();

      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // console.log(profile);
            portalProfilesService.createProfileFromEasyInstallWizard(this, profile);
          } else {
            this.validatorErrors = true;
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    handleCustomTimeAuthentication(type) {
      const { amount, metrics } = this.currentAuthentications[type];
      this.currentAuthentications[type].remember = this.moment(0).add(amount, metrics).unix();
      this.$set(this.chooseIntervalShowingForAuthentication, type, false);
    },
    handleCustomTimeAuthorization(key, setting) {
      const { amount, metrics } = this.currentAuthorizations[key].customTimeInterval[setting];
      this.currentAuthorizations[key].config[setting] = this.moment(0).add(amount, metrics).unix();
      this.$set(this.chooseIntervalShowingForAuthorizations[key], setting, false);
    },
    setChooseIntervalShowingForAuthorizations(key, setting) {
      if (!this.chooseIntervalShowingForAuthorizations[key]) {
        // console.log('create!')
        this.$set(this.chooseIntervalShowingForAuthorizations, key, {});
      }
      this.$set(this.chooseIntervalShowingForAuthorizations[key], setting, true);
    },
    authNDisable(key) {
      return !this.currentAuthentications[key] && Object.keys(this.currentAuthentications).length >= 5;
    },
    authZDisable(key) {
      return !this.currentAuthorizations[key] && Object.keys(this.currentAuthorizations).length >= 5;
    },
    toggleAuthentication(type) {
      this.checkAuthNsWhenAuthenSkipIsFalseClearError();
      if (!this.currentAuthentications[type]) {
        Vue.delete(this.currentAuthentications, type);
      } else {
        // Vue.set(this.currentAuthentications, type, this.portalAuthenticationsList[type]);
        Vue.set(this.currentAuthentications, type, JSON.parse(JSON.stringify(this.portalAuthenticationsList[type])));
        // Здесь создаем два объекта, чтобы использовать быстрый выбор времени.
        Vue.set(this.currentAuthentications[type], 'amount', 1);
        Vue.set(this.currentAuthentications[type], 'metrics', 'days');
        this.openAuthenticationItem(type);
      }
    },
    // toggleAuthorization(type) {
    //   if (!this.currentAuthorizations[type]) {
    //     Vue.delete(this.currentAuthorizations, type);
    //   } else {
    //     Vue.set(this.currentAuthorizations, type, this.portalAuthorizationsList[type]);
    //     // Здесь создаем два объекта, чтобы использовать быстрый выбор времени.
    //     Vue.set(this.currentAuthorizations[type], 'customTimeInterval', {
    //       auth_timeout: { amount: 1, metrics: 'days' },
    //       block_after: { amount: 1, metrics: 'days' },
    //       block_expire: { amount: 1, metrics: 'days' },
    //       block_timeout: { amount: 1, metrics: 'days' },
    //       timeout: { amount: 1, metrics: 'days' }
    //     });
    //     // сразу разварачиваем для возможность настройки
    //     this.openAuthorizationItem(type);
    //   }
    // },

    toggleAuthorization(type) {
      if (!this.currentAuthorizations[type]) {
        Vue.delete(this.currentAuthorizations, type);
      } else {
        // Vue.set(this.currentAuthorizations, type, this.portalAuthorizationsList[type]);
        Vue.set(this.currentAuthorizations, type, JSON.parse(JSON.stringify(this.portalAuthorizationsList[type])));
        // Здесь создаем два объекта, чтобы использовать быстрый выбор времени.
        Vue.set(this.currentAuthorizations[type], 'customTimeInterval', {
          auth_timeout: { amount: 1, metrics: 'days' },
          block_after: { amount: 1, metrics: 'days' },
          block_expire: { amount: 1, metrics: 'days' },
          block_timeout: { amount: 1, metrics: 'days' },
          timeout: { amount: 1, metrics: 'days' }
        });

        // тут добавляем ко включаемой авторизации  свойство transferable, если его нет, и ставим в false,
          // чтобы потом нормально работала реактивность итд в остальных местах
        // console.log(this.currentAuthorizations[type]);

        if (!Object.prototype.hasOwnProperty.call(this.currentAuthorizations[type], 'transferable')) {
          Vue.set(this.currentAuthorizations[type].config, 'transferable', false);
        }

        // сразу разварачиваем для возможность настройки
        this.openAuthorizationItem(type);
      }
    },


    toggleAuthorizationItemVisability(type) {
      if (!this.openedAuthorizations[type]) {
        Vue.set(this.openedAuthorizations, type, true);
      } else {
        Vue.set(this.openedAuthorizations, type, false);
      }
    },
    closeAuthorizationItem(type) {
      Vue.set(this.openedAuthorizations, type, false);
    },
    openAuthorizationItem(type) {
      Vue.set(this.openedAuthorizations, type, true);
    },
    // тут функции, которые при сворчаиванни блока вызывают валидатор
    // toggleAuthenticationItemVisability(type) {
    //   if (!this.openedAuthentications[type]) {
    //     Vue.set(this.openedAuthentications, type, true);
    //   } else {
    //     this.$validator.validateAll().then(
    //       (result) => {
    //         if (result) {
    //           Vue.set(this.openedAuthentications, type, false);
    //         }
    //       },
    //       () => {
    //         console.warn('Validation failed');
    //       }
    //     );
    //   }
    // },
    // closeAuthenticationItem(type) {
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         if (this.openedAuthentications[type]) {
    //           Vue.set(this.openedAuthentications, type, false);
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // },
    toggleAuthenticationItemVisability(type) {
      if (this.isAllAuhNsSwitchersLocked === true) {
        return;
      }
      if (!this.openedAuthentications[type]) {
        Vue.set(this.openedAuthentications, type, true);
      } else {
        Vue.set(this.openedAuthentications, type, false);
      }
    },
    closeAuthenticationItem(type) {
      Vue.set(this.openedAuthentications, type, false);
    },
    openAuthenticationItem(type) {
      Vue.set(this.openedAuthentications, type, true);
    },
    hasCaption(path) {
      return this.$te(`${path}Caption`);
    },
    getSystemByID(id) {
      const result = this.paymentSystemsList.find((it) => it.id === id);
      return result || {};
    },
    getAdStatus(ad) {
      if (!ad.hasOwnProperty('schedule')) {
        return `${this.$t('general.noDataToDisplay')}`;
      }
      const status = getAdStatus(moment().unix(), ad.schedule.start, ad.schedule.stop);
      const statusLocalized = this.$t(`adsprofile.${status}Ad`);
      const pattern = 'DD MMM YYYY';

      switch (status) {
        case 'active':
          return `${statusLocalized} ${this.$t('general.till')} ${moment.unix(ad.schedule.stop).format(pattern)}`;
        case 'planned':
          return `${statusLocalized} ${this.$t('general.from')} ${moment.unix(ad.schedule.start).format(pattern)}`;
        default:
          return statusLocalized;
      }
    },

    nextStep() {
      // this.$parent.nextStep();
      // console.log(this.$store.state.easyInstallWizardStepsData.routeFromUserWentToWizard)
      if (this.$store.state.easyInstallWizardStepsData.routeFromUserWentToWizard !== false) {
        this.$router.push(this.$store.state.easyInstallWizardStepsData.routeFromUserWentToWizard);
        this.$store.commit('clearEasyInstallWizardStepsData');
      } else {
        this.$router.push({ name: 'Dashboard' });
        this.$store.commit('clearEasyInstallWizardStepsData');
      }
    },
    exitFromWizard() {
      this.$router.push({ name: 'Dashboard' });
    },
    prevStep() {
      this.$router.push({ name: 'EasyInstallWizardStep4' });
    }
  },
  created() {
    // // если не создали локацию на 1 шаге визарда, делать тут нечего, редиректим на шаг 1 :)
    // if (this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject === false ||
    //   this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject.id === false) {
    //   this.$router.push({ name: 'UsersDemoWizardStep1' });
    // }
    // this.$store.commit('setCpesList', false);
    this.$store.commit('portalPaymentSystemsClean');
    portalProfilesService.getAuthentications(this);
    portalProfilesService.getAuthorizations(this);
    // portalAdsService.getPortalAds(this);
    portalAdsService.getPortalAdsWithNoPagination(this);
    if (this.paymentSystemsList.length < 1) portalPaymentSystemsService.getPaymentSystems(this);
    // if (!this.$store.state.cpesList) {
    //   cpeService.getAllCompactCpes(this);
    // }

    // wlanService.getAllWlans(this);

    this.updatedProfile.base_location = this.$store.state.easyInstallWizardStepsData.step1locationId || '';
    this.updatedProfile.condition.cpe = this.$store.state.easyInstallWizardStepsData.step4CpesWithWLANIDs || [];
    this.updatedProfile.condition.wlan = [this.$store.state.easyInstallWizardStepsData.step1WlanId] || [];

    // проверяем есть какие шаги пройдены, чтобы знакть куда перенаправлять юзера, если он выходил из визарда
    if (this.$router.currentRoute.name !== EasyInstallWizardHelpers.checkStepForUserReditrect(this)) {
      this.$router.push({ name: EasyInstallWizardHelpers.checkStepForUserReditrect(this) });
    }
  }
};
</script>

<style scoped lang="scss">
.authZ-settings-fields-height-hack {
  min-height: 2.7em !important;
  line-height: 97% !important;
}
.portal-profiles-interval-select-buttons {
  font-size: 0.9em !important;
  border-right: 0.5px;
  border-left: 0.5px;
  border-style: solid;
  border-color: rgba(236, 236, 236, 0.45);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -0.5px;
}
.div-for-settings-button-for-authn-and-authz {
  display: inline;
  margin-left: 1em;
}
.settings-button-for-authn-and-authz {
  font-size: 0.8em;
  cursor: pointer;
}
.users-demo-wizard-setup-step4-wrapper {
  min-width: 600px !important;
}

.validatorErrors {
  color: red;
  text-align: center;
  width: 100%;
}
</style>
<style>
.authentication-list-in-portal-profiles-in-easy-install-wiz5-locked {
  background: rgba(0, 0, 0, 0.04);
  color: gray !important;
  cursor: not-allowed !important;
}

.authentication-list-in-portal-profiles-in-easy-install-wiz5-locked
  .authN-switcher-in-easy-install-wiz5
  .switch-input:checked
  ~ .switch-label {
  background: rgba(40, 40, 40, 0.2) !important;
  color: gray !important;
}
.authentication-list-in-portal-profiles-in-easy-install-wiz5-locked-settings-button-for-authn {
  cursor: not-allowed !important;
}
</style>
