import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import cpeService from './cpeService';

export default {
  getCpeLatestStats(ctx, cpeIds) {
    ctx.errorFromResponse = '';
    // console.log('call APi')
    if (cpeIds === '') {
      return;
    }
    let arrayWithCpeIds = [];
    if (cpeIds !== undefined) {
      if (Array.isArray(cpeIds)) {
        arrayWithCpeIds = cpeIds;
      } else {
        arrayWithCpeIds.push(cpeIds);
      }
    }

    const query = {
      action: 'R'
    };
    if (arrayWithCpeIds.length > 0) {
      query.ids = arrayWithCpeIds;
    }
    ctx.$store.commit('setCpeLatestStatsRequestExecutingBegin');
    Vue.axios.post(`${window.RESTAPI || ''}/api/stat/latest/cpes `, query).then(
      (response) => {
        if (response.data.status === 'success') {
          const dataFromResponse = response.data.data.itemslist;
          ctx.rawDataFromGetCpeLatestStats = dataFromResponse;
          if (!Array.isArray(dataFromResponse) || dataFromResponse.length === 0) {
            ctx.errorFromResponse = 'noData';
            ctx.$store.commit('setCpeLatestStatsRequestExecutingEnd');
            return;
          }
          // console.log(dataFromResponse[0])
          if (
            Object.prototype.hasOwnProperty.call(dataFromResponse[0], 'data') &&
            Array.isArray(dataFromResponse[0].data) &&
            dataFromResponse[0].data.length > 0
          ) {
            const dataForCPE = dataFromResponse[0].data;
            // console.log(dataForCPE)
            // сортируем по timestamp
            ctx.sortedByTimeStatsDataForCPE = dataForCPE.sort(this.compareStatItemsByTimestamp);
            // тут захардкожено преобразование в байты, соответсвенно в компонент записываются значения в байтах
            // а перевеод в другие единицы уже осуществляется в computed ствойствах компонента
            const seriesForCharts = this.makeSeriesForCharts(ctx.sortedByTimeStatsDataForCPE, 'B');
            // console.log(seriesForCharts)
            // ctx.cpuChart.series[0].data = seriesForCharts.cpu;
            // ctx.ramChart.series[0].data = seriesForCharts.ram.total;
            // ctx.ramChart.series[1].data = seriesForCharts.ram.free;
            ctx.dataForCharts = seriesForCharts;
            // console.log(seriesForCharts)
          } else {
            ctx.errorFromResponse = 'noData';
          }
        } else if (response.data.status === 'error') {
          ctx.errorFromResponse = response.data.description;
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('setCpeLatestStatsRequestExecutingEnd');
      },
      (err) => {
        ctx.$store.commit('setCpeLatestStatsRequestExecutingEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  // changeUnits(ctx) {
  //   try {
  //     const seriesForCharts = this.makeSeriesForCharts(ctx.sortedByTimeStatsDataForCPE, ctx.selectedUnits);
  //     ctx.dataForCharts = seriesForCharts;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  compareStatItemsByTimestamp(a, b) {
    if (a.timestamp < b.timestamp) {
      return -1;
    }
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    return 0;
  },
  makeSeriesForCharts(sortedByTimeItems, selectedUnits) {
    let units;
    if (selectedUnits === undefined) {
      units = 'B';
    } else {
      units = selectedUnits;
    }
    // тут готовим таймсерии для графиков
    const result = {};
    result.cpu = [];
    result.ram = {
      total: [], buffered: [], shared: [], free: []
    };
    result.interfaces = {};
    result.interfacesNames = [];
    if (!Array.isArray(sortedByTimeItems) || sortedByTimeItems.length === 0) {
      return result;
    }
    for (const item of sortedByTimeItems) {
      const timestamp = item.timestamp * 1000;
      // cpu
      const cpu = Number((item.cpu * 100).toFixed(2));
      result.cpu.push([timestamp, cpu]);
      // ram
      const ramTotal = this.bytesToMBytes(item.ram.total);
      result.ram.total.push([timestamp, ramTotal]);
      const ramFree = this.bytesToMBytes(item.ram.free);
      result.ram.free.push([timestamp, ramFree]);
      const ramBuffered = this.bytesToMBytes(item.ram.buffered);
      result.ram.buffered.push([timestamp, ramBuffered]);
      const ramShared = this.bytesToMBytes(item.ram.shared);
      result.ram.shared.push([timestamp, ramShared]);

      // interfaces stat
      const interfacesStatFromApi = item.interfaces;
      // создаем масиив с именами всех прилетевших интерфейсов
      const interfacesStatFromApiInterfacesNames = Object.keys(interfacesStatFromApi);
      result.interfacesNames = interfacesStatFromApiInterfacesNames;
      // console.log(interfacesStatFromApiInterfacesNames);
      // и итерируемся по нему
      for (const interfaceName of interfacesStatFromApiInterfacesNames) {
        // смотрим есть ли ключик с названием этого интерфейса в объекте с данным для графиков, если нет создаем
        if (!Object.prototype.hasOwnProperty.call(result.interfaces, interfaceName)) {
          result.interfaces[interfaceName] = {};
        }
        // смотрим есть ли ключик с названием нужного типа данных, если нет создаем и пихаем туда пустой массив
        // куда позднее будем пихать данные
        if (!Object.prototype.hasOwnProperty.call(result.interfaces[interfaceName], 'tx_speed')) {
          result.interfaces[interfaceName].tx_speed = [];
        }
        if (!Object.prototype.hasOwnProperty.call(result.interfaces[interfaceName], 'rx_speed')) {
          result.interfaces[interfaceName].rx_speed = [];
        }
        if (!Object.prototype.hasOwnProperty.call(result.interfaces[interfaceName], 'tx')) {
          result.interfaces[interfaceName].tx = [];
        }
        if (!Object.prototype.hasOwnProperty.call(result.interfaces[interfaceName], 'rx')) {
          result.interfaces[interfaceName].rx = [];
        }
        if (!Object.prototype.hasOwnProperty.call(result.interfaces[interfaceName], 'tx_delta')) {
          result.interfaces[interfaceName].tx_delta = [];
        }
        if (!Object.prototype.hasOwnProperty.call(result.interfaces[interfaceName], 'rx_delta')) {
          result.interfaces[interfaceName].rx_delta = [];
        }
        // а тепреь наполняем данными статистики эти массивы
        // сначала смотрим есть ли в этом item данных такой ключ c именм нужного свойства, если есть добавляем к данным для графика
        if (Object.prototype.hasOwnProperty.call(interfacesStatFromApi[interfaceName], 'tx_speed')) {
          const txSpeed = this.bytesConvert(interfacesStatFromApi[interfaceName].tx_speed, units);
          result.interfaces[interfaceName].tx_speed.push([timestamp, txSpeed]);
        }

        if (Object.prototype.hasOwnProperty.call(interfacesStatFromApi[interfaceName], 'rx_speed')) {
          const rxSpeed = this.bytesConvert(interfacesStatFromApi[interfaceName].rx_speed, units);
          result.interfaces[interfaceName].rx_speed.push([timestamp, rxSpeed]);
        }

        if (Object.prototype.hasOwnProperty.call(interfacesStatFromApi[interfaceName], 'tx')) {
          const tx = this.bytesConvert(interfacesStatFromApi[interfaceName].tx, units);
          result.interfaces[interfaceName].tx.push([timestamp, tx]);
        }

        if (Object.prototype.hasOwnProperty.call(interfacesStatFromApi[interfaceName], 'rx')) {
          const rx = this.bytesConvert(interfacesStatFromApi[interfaceName].rx, units);
          result.interfaces[interfaceName].rx.push([timestamp, rx]);
        }
        if (Object.prototype.hasOwnProperty.call(interfacesStatFromApi[interfaceName], 'tx_delta')) {
          const tx_delta = this.bytesConvert(interfacesStatFromApi[interfaceName].tx_delta, units);
          result.interfaces[interfaceName].tx_delta.push([timestamp, tx_delta]);
        }
        if (Object.prototype.hasOwnProperty.call(interfacesStatFromApi[interfaceName], 'rx_delta')) {
          const rx_delta = this.bytesConvert(interfacesStatFromApi[interfaceName].rx_delta, units);
          result.interfaces[interfaceName].rx_delta.push([timestamp, rx_delta]);
        }
      }
    }
    return result;
  },
  // formatBytes(bytes, decimals) {
  //   if (bytes === 0) return '0 Bytes';
  //   const k = 1000;
  //
  //   const dm = decimals || 2;
  //
  //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  //
  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`;
  // },
  bytesToMBytes(bytes) {
    try {
      if (Number((bytes / 1024 / 1024).toFixed(3)) === 0) {
        // если округлилось так, что получился 0 то пробуем округлить более точно
        return Number((bytes / 1024 / 1024).toFixed(7));
      }
      // а если не в 0, то выводим с тремя после запятой
      return Number((bytes / 1024 / 1024).toFixed(3));
    } catch (e) {
      console.log(e);
      return bytes;
    }
  },
  bytesToKBytes(bytes) {
    try {
      if (Number((bytes / 1024).toFixed(3)) === 0) {
        // если округлилось так, что получился 0 то пробуем округлить более точно
        return Number((bytes / 1024).toFixed(7));
      }
      // а если не в 0, то выводим с тремя после запятой
      return Number((bytes / 1024).toFixed(3));
    } catch (e) {
      console.log(e);
      return bytes;
    }
  },
  bytesToGBytes(bytes) {
    try {
      if (Number((bytes / 1024 / 1024 / 1024).toFixed(3)) === 0) {
        // если округлилось так, что получился 0 то пробуем округлить более точно
        return Number((bytes / 1024 / 1024 / 1024).toFixed(5));
      }
      // а если не в 0, то выводим с тремя после запятой
      return Number((bytes / 1024 / 1024 / 1024).toFixed(3));
    } catch (e) {
      console.log(e);
      return bytes;
    }
  },
  bytesConvert(bytes, selectedUnits) {
    try {
      const units = selectedUnits;
      if (units === 'MB') {
        return this.bytesToMBytes(bytes);
      } if (units === 'KB') {
        return this.bytesToKBytes(bytes);
      } if (units === 'GB') {
        return this.bytesToGBytes(bytes);
      }
      return Number(bytes.toFixed(3));
    } catch (e) {
      console.log(e);
      return bytes;
    }
  }
};
