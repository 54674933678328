<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class=" h-100">
        <div class="card-block">
<!--          <div class="h4 m-0">-->
<!--            {{ $t('general.step8') }}-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"-->
<!--              @click="lastStepButtonClickHandler"-->
<!--            >-->
<!--              {{ $t('wlans.wizardSkipButton') }}-->
<!--              <info :content="$t('wlans.wizardSkipButtonInfo')" />-->
<!--            </button>-->
<!--          </div>-->
          <span class="text-muted step-caption-in-wlan-wizard" :class="{ 'not-for-cisco': window.CISCO }">
            {{ $t('wlans.wizardMobility') }}
          </span>
          <div v-if="window.CISCO">
            <small class="text-danger p-0" v-if="window.CISCO">* {{ $t('wlans.notAvailableForCisco') }}</small>
          </div>
          <form action="">
            <div class="">
              <div class="row mt-1">
                <div class="form-group col-sm-12" :class="{ 'mb-h': newWlanData.roam80211r === true }">
                  <Switch-component
                    v-model="newWlanData.roam80211r"
                    v-tooltip.auto="{ content: `${$t('wlans.notForOpen')}` }"
                    :disabled="newWlanData.security.type == 'open' || newWlanData.security.type == ''"
                    :label="$t('wlans.fastTransition')"
                    id="roam80211r"
                    @input="roam80211rSwitcherChange"
                  />
                </div>
              </div>
              <!--              -->
              <transition name="wsettings">
                <div
                  class="row subswitchers-warpper-in-mobility-part"
                  v-if="newWlanData.hasOwnProperty('roam80211r') && newWlanData.roam80211r === true"
                >
                  <!--                              ft_over_ds ft over ds-->
                  <div class="form-group col-sm-12 mb-h ml-0 pl-0" v-if="newWlanData.hasOwnProperty('ft_over_ds')">
                    <Switch-component
                      v-model="newWlanData.ft_over_ds"
                      :disabled="newWlanData.security.type == 'open' || newWlanData.security.type == ''"
                      :label="$t('wlans.overTheDS')"
                      id="ft_over_ds"
                    />
                  </div>
                  <!--                                nas_generate nas generate-->
                  <div class="form-group col-sm-12 mb-h ml-0 pl-0" v-if="newWlanData.hasOwnProperty('nas_generate')">
                    <Switch-component
                      v-model="newWlanData.nas_generate"
                      :disabled="newWlanData.security.type == 'open' || newWlanData.security.type == ''"
                      :label="$t('wlans.nas_generateCaption')"
                      id="nas_generate"
                    />
                    <info class="ml-q" :content="$t('wlans.nas_generateInfo')" />
                  </div>
                </div>
              </transition>

              <div class="row">
                <div class="form-group col-sm-12" v-if="newWlanData.security.type.indexOf('enterprise') == -1">
                  <Switch-component
                    v-model="newWlanData.pmkcaching"
                    v-tooltip.auto="{ content: `${$t('wlans.onlyForWPAEnterprise')}` }"
                    :disabled="newWlanData.security.type.indexOf('enterprise') == -1"
                    :label="$t('wlans.PMKcaching')"
                    id="pmkcaching_wpae"
                  />
                </div>
                <div class="form-group col-sm-12" v-else>
                  <Switch-component
                    v-model="newWlanData.pmkcaching"
                    :disabled="newWlanData.security.type.indexOf('enterprise') == -1"
                    :label="$t('wlans.PMKcaching')"
                    id="pmkcaching"
                  />
                </div>
              </div>
              <!--                            ieee80211k-->
              <div class="row" v-if="newWlanData.hasOwnProperty('ieee80211k')">
                <div class="form-group col-sm-12" :class="{ 'mb-h': newWlanData.ieee80211k === true }">
                  <Switch-component v-model="newWlanData.ieee80211k" :label="$t('wlans.ieee80211k')" id="ieee80211k" />
                </div>
              </div>
              <transition name="wsettings">
                <div
                  class="row subswitchers-warpper-in-mobility-part"
                  v-if="newWlanData.hasOwnProperty('ieee80211k') && newWlanData.ieee80211k === true"
                >
                  <!--                              rrm neighbor report-->
                  <div
                    class="form-group col-sm-12 mb-h ml-0 pl-0"
                    v-if="newWlanData.hasOwnProperty('rrm_neighbor_report')"
                  >
                    <Switch-component
                      v-model="newWlanData.rrm_neighbor_report"
                      :label="$t('wlans.rrm_neighbor_report')"
                      id="rrm_neighbor_report"
                    />
                  </div>
                  <div class="form-group col-sm-12 ml-0 pl-0" v-if="newWlanData.hasOwnProperty('rrm_beacon_report')">
                    <!--                                rrm beacon report-->
                    <Switch-component
                      v-model="newWlanData.rrm_beacon_report"
                      :label="$t('wlans.rrm_beacon_report')"
                      id="rrm_beacon_report"
                    />
                  </div>
                </div>
              </transition>
              <!--                            ieee80211v-->
              <div class="row" v-if="newWlanData.hasOwnProperty('ieee80211v')">
                <div class="form-group col-sm-12" :class="{ 'mb-h': newWlanData.ieee80211v === true }">
                  <Switch-component v-model="newWlanData.ieee80211v" :label="$t('wlans.ieee80211v')" id="ieee80211v" />
                </div>
              </div>
              <transition name="wsettings">
                <div
                  class="row subswitchers-warpper-in-mobility-part"
                  v-if="newWlanData.hasOwnProperty('ieee80211v') && newWlanData.ieee80211v === true"
                >
                  <!--                              wnm sleep mode-->
                  <div class="form-group col-sm-12 ml-0 pl-0 mb-h" v-if="newWlanData.hasOwnProperty('wnm_sleep_mode')">
                    <Switch-component
                      v-model="newWlanData.wnm_sleep_mode"
                      :label="$t('wlans.wnm_sleep_mode')"
                      id="rrm_neighbor_report"
                    />
                    <info class="info-tooltip-in-wlans-wmm" :content="$t('wlans.wnm_sleep_modeInfo')" />
                  </div>
                  <div class="form-group col-sm-12 ml-0 pl-0" v-if="newWlanData.hasOwnProperty('bss_transition')">
                    <!--                                bss transition-->
                    <Switch-component
                      v-model="newWlanData.bss_transition"
                      :label="$t('wlans.bss_transition')"
                      id="bss_transition"
                    />
                  </div>
                </div>
              </transition>

              <!--                            RSSI threshold rssi_threshold-->
              <div class="row" v-if="newWlanData.hasOwnProperty('rssi_threshold')">
                <div class="form-group col-sm-12">
                  <!--                                <Switch-component-->
                  <!--                                  v-model="newWlanData.rssi_threshold"-->
                  <!--                                  :disabled="isDisable"-->
                  <!--                                  label="rssi_threshold"-->
                  <!--                                  id="rssi_threshold"-->
                  <!--                                />-->
                  <label for="rssi_threshold">
                    {{ $t('wlans.rssiThreshold') }}
                    <info class="" :content="$t('wlans.rssiThresholdHintForInfo')" />
                    <small class="ml-q">({{ $t('wlans.rssiThresholdHintRange') }})</small>
                  </label>
                  <input
                    type="number"
                    id="rssi_threshold"
                    name="rssi_threshold"
                    :placeholder="$t('wlans.rssiThresholdPlaceholder')"
                    class="form-control"
                    v-validate="'required|integer|rssiThresholdValueForWlan'"
                    v-model.number="newWlanData.rssi_threshold"
                    :data-vv-as="$t('wlans.rssiThreshold')"
                  />
                  <span v-show="errors.has('rssi_threshold')" class="help is-danger">
                    {{ errors.first('rssi_threshold') }}
                  </span>
                </div>
              </div>

              <!--                            Signal caption-->
              <div class="row" v-if="newWlanData.hasOwnProperty('signal_stay')">
                <div class="form-group col-sm-12 mb-q">
                  <span>{{ $t('wlans.signalBlockCaption') }}:</span>
                </div>
              </div>

              <!--                               Signal Stay  signal_stay -->
              <div class="row" v-if="newWlanData.hasOwnProperty('signal_stay')">
                <div class="form-group col-sm-12">
                  <label for="signal_stay">
                    {{ $t('wlans.signalStay') }}
                    <info class="" :content="$t('wlans.signalStayForInfo')" />
                    <small class="ml-q">({{ $t('wlans.signalStayHint') }})</small>
                  </label>
                  <input
                    type="number"
                    id="signal_stay"
                    name="signal_stay"
                    :placeholder="$t('wlans.signalStayPlaceholder')"
                    class="form-control"
                    v-validate="'required|integer|signalStayValueForWlan'"
                    v-model.number="newWlanData.signal_stay"
                    :data-vv-as="$t('wlans.signalStay')"
                  />
                  <span v-show="errors.has('signal_stay')" class="help is-danger">
                    {{ errors.first('signal_stay') }}
                  </span>
                </div>
              </div>

              <!--                               Signal Strikes signal_strikes -->
              <div class="row" v-if="newWlanData.hasOwnProperty('signal_strikes')">
                <div class="form-group col-sm-12">
                  <label for="signal_strikes">
                    {{ $t('wlans.signalStrikes') }}
                    <info class="" :content="$t('wlans.signalStrikesForInfo')" />
                    <small class="ml-q">({{ $t('wlans.signalStrikesHint') }})</small>
                  </label>
                  <input
                    type="number"
                    id="signal_strikes"
                    name="signal_strikes"
                    :placeholder="$t('wlans.signalStrikesPlaceholder')"
                    class="form-control"
                    v-validate="'integer|signalStrikesValueForWlan'"
                    v-model.number="newWlanData.signal_strikes"
                    :data-vv-as="$t('wlans.signalStrikes')"
                  />
                  <span v-show="errors.has('signal_strikes')" class="help is-danger">
                    {{ errors.first('signal_strikes') }}
                  </span>
                </div>
              </div>

              <!--                               signal Poll Time signal_poll_time -->
              <div class="row" v-if="newWlanData.hasOwnProperty('signal_strikes')">
                <div class="form-group col-sm-12">
                  <label for="signal_poll_time">
                    {{ $t('wlans.signalPollTime') }}
                    <!--                                  <info class=""-->
                    <!--                                        :content="$t('wlans.signalPollTimeForInfo')"/>-->
                    <small class="ml-q">({{ $t('wlans.signalPollTimeHint') }})</small>
                  </label>
                  <input
                    type="number"
                    id="signal_poll_time"
                    name="signal_poll_time"
                    :placeholder="$t('wlans.signalPollTimePlaceholder')"
                    class="form-control"
                    v-validate="'integer|signalPollValueForWlan'"
                    v-model.number="newWlanData.signal_poll_time"
                    :data-vv-as="$t('wlans.signalPollTime')"
                  />
                  <span v-show="errors.has('signal_poll_time')" class="help is-danger">
                    {{ errors.first('signal_poll_time') }}
                  </span>
                </div>
              </div>

              <!--                               signal Drop Reason signal_drop_reason -->
              <div class="row" v-if="newWlanData.hasOwnProperty('signal_drop_reason')">
                <div class="form-group col-sm-12">
                  <label for="signal_drop_reason">
                    {{ $t('wlans.signalDropReason') }}
                    <info @clicked="showDropReasonModal" class="" :content="$t('wlans.signalDropReasonForInfo')" />
                    <small class="ml-q">
                      ({{ $t('wlans.signalDropReasonHint') }}.
                      <span style="text-decoration: underline;" @click="showDropReasonModal" role="button">
                        {{ $t('wlans.signalDropReasonMoreInfo') }})
                      </span>
                    </small>
                  </label>
                  <input
                    type="number"
                    id="signal_drop_reason"
                    name="signal_drop_reason"
                    :placeholder="$t('wlans.signalDropReasonPlaceholder')"
                    class="form-control"
                    v-validate="'integer|signalDropReasonForWlan'"
                    v-model.number="newWlanData.signal_drop_reason"
                    :data-vv-as="$t('wlans.signalDropReason')"
                  />
                  <span v-show="errors.has('signal_drop_reason')" class="help is-danger">
                    {{ errors.first('signal_drop_reason') }}
                  </span>
                </div>
              </div>

              <!--                     Band Steering band_steering-->
              <div class="row" v-if="newWlanData.hasOwnProperty('band_steering')">
                <div class="form-group col-sm-12 mt-h">
                  <Switch-component
                    v-model="newWlanData.band_steering"
                    :label="$t('wlans.bandSteering')"
                    id="band_steering"
                  />
                </div>
              </div>
<!--              &lt;!&ndash; Load Balancing load_balancing &ndash;&gt;-->
<!--              <div class="row">-->
<!--                <div class="form-group col-sm-12">-->
<!--                  <Switch-component-->
<!--                    v-model="newWlanData.load_balancing"-->
<!--                    :label="$t('wlans.loadBalancing')"-->
<!--                    id="load_balancing"-->
<!--                  />-->
<!--                  <info class="ml-q" :content="$t('wlans.loadBalancingInfoMsg')" />-->
<!--                </div>-->
<!--              </div>-->
              <!-- Load Balancing load_balancing -->
              <div class="row" v-show="isShowLoadBalancingSwitcher">
                <div class="form-group col-sm-12">
                  <Switch-component
                    v-model="newWlanData.load_balancing"
                    :label="$t('wlans.loadBalancing')"
                    id="load_balancing"
                  />
                  <info class="ml-q" :content="$t('wlans.loadBalancingInfoMsg')" />
                </div>
              </div>

            </div>
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="btn btn-outline-success float-right"-->
            <!--              @click="setNewWlanData"-->
            <!--            >-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left"-->
            <!--                    @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
<!--        <div class="buttons-block-warpper">-->
<!--          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
<!--            {{ $t('general.next') }}-->
<!--          </button>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-outline-info float-right mr-h"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @click="lastStepButtonClickHandler">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t('wlans.wizardSkipButton') }}&ndash;&gt;-->
<!--          &lt;!&ndash;            <info :content="$t('wlans.wizardSkipButtonInfo')"/>&ndash;&gt;-->
<!--          &lt;!&ndash;          </button>&ndash;&gt;-->
<!--          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
<!--            {{ $t('general.back') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
    <Modal title="About Drop Reason" class="modal-info" v-model="showingDropReasonModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('wlans.signalDropReason') }}</h4>
      <div>
        <div>
          <span>{{ $t('wlans.signalDropReasonMoreInfoForModal') }}:</span>
        </div>
        <div>
          <span>
            <a href="https://blogs.arubanetworks.com/industries/802-11-reason-codes-and-status-codes/">
              https://blogs.arubanetworks.com/industries/802-11-reason-codes-and-status-codes/
            </a>
          </span>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <button type="button" class="btn btn-secondary" @click="showingDropReasonModal = false">
          {{ $t('general.close') }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import SwitchComponent from '../../../components/Universal/Switch-component.vue';
import Info from '../../../components/Universal/info-icon.vue';
import Modal from '../../../components/Modal.vue';

export default {
  name: 'Step8',
  components: { SwitchComponent, Info, Modal },
  data() {
    return {
      showingDropReasonModal: false,
      // enableSaveChanges: true,
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      }
      // showWlanPass: false,
      // updatedWlanRadiusAuthentication: [],
      // updatedWlanRadiusAccounting: []
    };
  },
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    window() {
      return window;
    },
    isShowLoadBalancingSwitcher() {
      return this.$store.getters.isShowLoadBalancingSwitcher;
    }
  },
  methods: {
    showDropReasonModal() {
      // console.log('show modal');
      this.showingDropReasonModal = true;
    },
    roam80211rSwitcherChange(newVal) {
      if (newVal === true) {
        // устанавливаем ft_over_ds во включеное состояние
        // во время переключения roam80211r в true
        if (Object.prototype.hasOwnProperty.call(this.newWlanData.hasOwnProperty, 'ft_over_ds')) {
          this.newWlanData.ft_over_ds = true;
        } else {
          this.$set(this.newWlanData, 'ft_over_ds', true);
        }

        // устанавливаем nas_generate во включеное состояние
        // во время переключения roam80211r в true
        if (Object.prototype.hasOwnProperty.call(this.newWlanData.hasOwnProperty, 'nas_generate')) {
          this.newWlanData.nas_generate = true;
        } else {
          this.$set(this.newWlanData, 'nas_generate', true);
        }
      }
    },
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    // enableSave() {
    //   this.enableSaveChanges = true;
    // },
    // setNewWlanData(event, toLastStepClicked = false) {
    //   // ставим значнеия по умолчанию для полей signal_ если они не заданы WNE-2400
    //   if (
    //     Object.prototype.hasOwnProperty.call(this.newWlanData, 'signal_strikes') &&
    //     this.newWlanData.signal_strikes === ''
    //   ) {
    //     console.log('signal_strikes');
    //     this.newWlanData.signal_strikes = 3;
    //   }
    //   if (
    //     Object.prototype.hasOwnProperty.call(this.newWlanData, 'signal_poll_time') &&
    //     this.newWlanData.signal_poll_time === ''
    //   ) {
    //     this.newWlanData.signal_poll_time = 5;
    //   }
    //   if (
    //     Object.prototype.hasOwnProperty.call(this.newWlanData, 'signal_drop_reason') &&
    //     this.newWlanData.signal_drop_reason === ''
    //   ) {
    //     this.newWlanData.signal_drop_reason = 3;
    //   }
    //
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         // this.$parent.nextStep();
    //         if (toLastStepClicked === false) {
    //           this.$parent.nextStep();
    //         } else {
    //           // this.$parent.nextStep(true);
    //           this.$parent.lastStep();
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // }
    validateAndSetNewWlanData(currentTab, nextTab){
      this.setNewWlanData(currentTab, nextTab)
    },
    setNewWlanData(currentTab, nextTab) {
      // ставим значнеия по умолчанию для полей signal_ если они не заданы WNE-2400
      if (
        Object.prototype.hasOwnProperty.call(this.newWlanData, 'signal_strikes') &&
        this.newWlanData.signal_strikes === ''
      ) {
        console.log('signal_strikes');
        this.newWlanData.signal_strikes = 3;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.newWlanData, 'signal_poll_time') &&
        this.newWlanData.signal_poll_time === ''
      ) {
        this.newWlanData.signal_poll_time = 5;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.newWlanData, 'signal_drop_reason') &&
        this.newWlanData.signal_drop_reason === ''
      ) {
        this.newWlanData.signal_drop_reason = 3;
      }

      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
          } else {
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          }
        },
        () => {
          this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    // radiusService.getRadius(this);
    // this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
.subswitchers-warpper-in-mobility-part {
  margin-left: 1em !important;
}
</style>
