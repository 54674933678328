<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 mb-xs mb-2">{{ $t('easyInstallWizard.step1Header') }}</div>
          <form action="">
            <div class="form-group">
              <div class="form-group">
                <label class="required-mark" for="ssid">SSID</label>
                <input
                  type="text"
                  name="ssid"
                  id="ssid"
                  :placeholder="$t('wlans.ssidPlaceholder')"
                  class="form-control"
                  v-validate="'required|ssid'"
                  :class="{ input: true, 'is-danger': errors.has('ssid') }"
                  v-model="newWlanData.ssid"
                  data-vv-as="SSID"
                />
                <span v-show="errors.has('ssid')" class="help is-danger">{{ errors.first('ssid') }}</span>
              </div>
            </div>
            <div class="form-group">
              <transition name="infoMessageForWPA3InEasyInsatllWizard" mode="out-in">
              <div v-if="checkWPA3Type(newWlanData.security.type)"
                   class="form-group mt-1">
                <Alert class="alert-info">
                  <template #text>
                    <span class="mr-h">{{ $t('wlans.wpa3IsNotSupportedOnAllCPE') }}</span>
                  </template>
                </Alert>
              </div>
            </transition>
              <label for="wlan-security">{{ $t('wlans.security') }}</label>
              <select
                id="wlan-security"
                class="form-control"
                v-model="newWlanData.security.type"
                @change="setSecurityType(newWlanData.security.type)"
              >
                <option value="" :selected="newWlanData.security.type === ''">{{ $t('wlans.securityOpen') }}</option>
                <option value="wpapersonal" :selected="newWlanData.security.type === 'wpapersonal'">
                  WPA Personal
                </option>
                <option value="wpaenterprise" :selected="newWlanData.security.type === 'wpaenterprise'">
                  WPA Enterprise
                </option>
                <option value="wpa2personal" :selected="newWlanData.security.type === 'wpa2personal'">
                  WPA2 Personal
                </option>
                <option value="wpa2enterprise" :selected="newWlanData.security.type === 'wpa2enterprise'">
                  WPA2 Enterprise
                </option>
                <option value="wpa3personal"
                        :selected="newWlanData.security.type === 'wpa3personal'">
                  WPA3 Personal
                </option>
                <option value="wpa3enterprise"
                        :selected="newWlanData.security.type === 'wpa3enterprise'">
                  WPA3 Enterprise
                </option>
                <option value="wpa23personal"
                        :selected="newWlanData.security.type === 'wpa23personal'">
                  WPA2/3 Personal
                </option>
                <option value="wpa23enterprise"
                        :selected="newWlanData.security.type === 'wpa23enterprise'">
                  WPA2/3 Enterprise
                </option>
              </select>
            </div>

            <div class="form-group" v-if="newWlanData.security.type.indexOf('enterprise') !== -1">
              <label for="radius">{{ $t('radius.radius') }}</label>
              <multiselect
                class="rule-multi"
                id="radius"
                v-model="updatedWlanRadiusAuthentication"
                :multiple="true"
                :options="radiusList || []"
                :max="8"
                track-by="id"
                v-validate="'required'"
                label="name"
                name="RADIUS Authentication"
                :class="{ input: true, 'is-danger': errors.has('RADIUS Authentication') }"
                select-label="add"
                deselect-label="remove"
                placeholder="Select radius"
                :data-vv-as="$t('radius.radius')"
              ></multiselect>
              <span v-show="errors.has('RADIUS Authentication')" class="help is-danger mt-0 mb-1">
                {{ errors.first('RADIUS Authentication') }}
              </span>
            </div>

            <div role="group" class="form-group row" v-if="newWlanData.security.type.indexOf('personal') !== -1">
              <div class="col-12">
                <label for="wlan_password">{{ $t('general.password') }}</label>
                <div role="group" class="input-group">
                  <input
                    :type="showWlanPass ? 'text' : 'password'"
                    id="wlan_password"
                    autocomplete="off"
                    name="wlan_password"
                    placeholder="Enter WLAN password"
                    class="form-control"
                    v-validate="'required|min:8|max:63'"
                    :class="{ input: true, 'is-danger': errors.has('wlan_password') }"
                    v-model="newWlanData.security.data.psk"
                    :data-vv-as="$t('general.password')"
                  />
                  <div class="input-group-addon hovered" @click="showWlanPass = !showWlanPass">
                    <i class="fa fa-eye" v-if="showWlanPass"></i>
                    <i class="fa fa-eye-slash" v-if="!showWlanPass"></i>
                  </div>
                  <Password-generator
                    @passwordGenerated="passwordGeneratedForCreatedWlan"
                    class="ml-1"
                  ></Password-generator>
                </div>
                <span v-show="errors.has('wlan_password')" class="help is-danger">
                  {{ errors.first('wlan_password') }}
                </span>
              </div>
            </div>

            <div class="form-group" v-if="newWlanData.security.type !== ''">
              <label for="suites">{{ $t('wlans.suites') }}</label>
              <multiselect
                id="suites"
                v-model="newWlanData.security.data.suites"
                :multiple="true"
                :options="(newWlanData.security.type.indexOf('2') !== -1 || newWlanData.security.type.indexOf('3') !== -1) ? ['aes', 'tkip'] : ['tkip']"
                placeholder="Suites"
                :class="{ input: true, 'is-danger': isSuitesValidation }"
              ></multiselect>
              <span v-show="isSuitesValidation" class="text-danger">
                {{ $t('easyInstallWizard.step1SuiteRequiredsError') }}
              </span>
            </div>
            <div class="form-group">
              <label for="location">{{ $t('general.location') }}</label>
              <select v-model="newWlanData.base_location" id="location" name="location" class="form-control">
                <option v-for="location in locationsList" :key="location.id" :value="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <!--                <button-->
                <!--                  type="button"-->
                <!--                  class="btn btn-outline-secondary float-left"-->
                <!--                  :disabled="$parent.step2SavedUserObject !== false"-->
                <!--                  @click="prevStep"-->
                <!--                >-->
                <!--                  {{ $t('general.back') }}-->
                <!--                </button>-->
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-outline-primary float-right"
                  @click="handleSaveWlan"
                  :disabled="errors.any()"
                >
                  {{ $t('easyInstallWizard.step1NextButton') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
// import locationService from '../../services/locationService';
// import usersService from '../../services/usersService';
import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import radiusService from '../../services/radiusService';
import wlansPollingService from '../../services/wlansPollingService';
import helpers from '../../helpers';
import PasswordGenerator from '../../components/PasswordGenerator.vue';
import commonService from '../../services/commonService';
import EasyInstallWizardHelpers from './EasyInstallWizardHelpers';

import Alert from '@/components/Universal/alert/alert.vue';

// const WPA3_TYPES = ['wpa3personal', 'wpa3enterprise', 'wpa23personal', 'wpa23enterprise']

export default {
  name: 'EasyInstallWizardstep1',
  components: { Multiselect, PasswordGenerator, Alert },
  data() {
    return {
      newWlanData: {
        base_location: '',
        ssid: '',
        description: '',
        security: {
          data: {
            suites: ['aes'],
            psk: ''
          },
          type: ''
        },
        vlan: '',
        hidden: false
      },
      // radiusList: false,
      showWlanPass: false,
      updatedWlanRadiusAuthentication: []
    };
  },
  computed: {
    wpa3Types() {
      // return WPA3_TYPES;
      return this.$store.getters.getWPA3Types;
    },
    isSuitesValidation() {
      return this.newWlanData.security.data.suites.length === 0;
    },
    radiusList() {
      return this.$store.state.radiusList;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    }
  },
  methods: {
    checkWPA3Type(type) {
      if (this.wpa3Types.includes(type)) {
        return true;
      }
      return false
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    passwordGeneratedForCreatedWlan(generatedPassword) {
      // показываем пароль если был скрыт
      this.showWlanPass = true;
      // console.log(generatedPassword);
      this.newWlanData.security.data.psk = generatedPassword;
    },
    // setSecurityType(type) {
    //   if (type !== 'open' && type.indexOf('2') === -1) {
    //     const isAesSelected = this.newWlanData.security.data.suites.indexOf('aes');
    //     if (isAesSelected !== -1) {
    //       this.newWlanData.security.data.suites = ['tkip'];
    //     }
    //   }
    //   if (type !== 'open' && type.indexOf('2') != -1) {
    //     this.newWlanData.security.data.suites = ['aes'];
    //   }
    // },
    setSecurityType(type) {
      // console.log(type)
      if (type !== 'open' && type.indexOf('2') === -1) {
        const isAesSelected = this.newWlanData.security.data.suites.indexOf('aes');
        if (isAesSelected !== -1) {
          this.newWlanData.security.data.suites = ['tkip'];
        }
      }
      if (type !== 'open' && (type.indexOf('2') != -1 || type.indexOf('3') != -1)) {
        this.newWlanData.security.data.suites = ['aes'];
      }
    },
    handleSaveWlan() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // console.log(this.newUserData);
          this.createNewWlan();
        }
      });
    },
    createNewWlan() {
      let fieldsToValidate = {};
      if (this.newWlanData.security.type.indexOf('personal') !== -1) {
        fieldsToValidate = {
          password: this.newWlanData.security.data.psk
        };
      }
      if (this.newWlanData.security.type.indexOf('enterprise') !== -1) {
        const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(this.updatedWlanRadiusAuthentication);
        this.newWlanData.security.data.radiusauthentication = radiusAuthServersIds;
      }
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.confirmNewWlanAndCreate();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    confirmNewWlanAndCreate() {
      const transformedQuery = { ...this.newWlanData };
      transformedQuery.vlan = parseInt(transformedQuery.vlan);
      if (!this.newWlanData.vlan) {
        this.newWlanData.vlan = 0;
      }
      // open wlan case
      if (!transformedQuery.security.type) {
        transformedQuery.security = null;
      } else if (transformedQuery.security.type.indexOf('personal') !== -1) {
        delete transformedQuery.security.data.radius_auth;
      } else if (transformedQuery.security.type.indexOf('enterprise') !== -1) {
        delete transformedQuery.security.data.psk;
      }
      this.axios.post(`${window.RESTAPI || ''}/api/wlans`, { action: 'C', items: { 0: transformedQuery } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            // this.exitFromWizard();
          } else if (response.data.status === 'success') {
            // this.$parent.nextStep();
            // wlansPollingService.hadleOperationResponse(response.data.data.items);
            // console.log(response.data.data)
            this.$store.commit('setEasyInstallWizardStep1SavedWlanObject', response.data.data.items);
            // далее вытаскиваем из созданой wlan id локации и id созданного wlan;
            const keys = Object.keys(response.data.data.items);
            const createdWlan = response.data.data.items[keys[0]];
            const createdWlanLocationId = createdWlan.model_location;
            const createdWlanId = createdWlan.model_id;
            this.$store.commit('setEasyInstallWizardStep1LocationId', createdWlanLocationId);
            this.$store.commit('setEasyInstallWizardStep1WlanId', createdWlanId);
            this.$router.push({ name: 'EasyInstallWizardStep2' });
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
          // this.exitFromWizard();
        }
      );
    },
    nextStep() {
      // this.$parent.nextStep();
      this.$router.push({ name: 'EasyInstallWizardStep2' });
    },
    exitFromWizard() {
      this.$router.push({ name: 'Dashboard' });
    },
    prevStep() {
      this.$router.push({ name: 'Dashboard' });
    }
  },
  created() {
    // // если не создали локацию на 1 шаге визарда, делать тут нечего, редиректим на шаг 1 :)
    // if (this.$store.state.EasyInstallWizardStepsData.step1SavedLocationObject === false ||
    //   this.$store.state.EasyInstallWizardStepsData.step1SavedLocationObject.id === false) {
    //   this.$router.push({ name: 'EasyInstallWizardStep1' });
    // }
    radiusService.getRadius(this);
    if (!this.newWlanData.base_location) {
      this.newWlanData.base_location = this.$store.state.userData.base_location;
    }
    if (!this.$store.getters.areAllEasyInstallWizardNotInProgress) {
      // если не все ключи пустые, значит юзер вышел из визарда, не дойдя до конца,
      // смотрим куда его прокинуть дальше
      if (this.$router.currentRoute.name !== EasyInstallWizardHelpers.checkStepForUserReditrect(this)) {
        this.$router.push({ name: EasyInstallWizardHelpers.checkStepForUserReditrect(this) });
      }
    }
  }
};
</script>

<style lang="scss">
      .infoMessageForWPA3InEasyInsatllWizard-enter-active,
.infoMessageForWPA3InEasyInsatllWizard-leave-active {
  transition: opacity 0.3s;
}

.infoMessageForWPA3InEasyInsatllWizard-enter,
.infoMessageForWPA3InEasyInsatllWizard-leave-to {
  opacity: 0;
}
</style>
