const Clients = () => import(/* webpackChunkName: "Clients" */ '../views/Clients');

const ClientsRoute = {
  path: 'clients',
  name: 'Clients',
  component: Clients,
  props: true
};

export default ClientsRoute;
