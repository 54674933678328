<template>
  <div class="save-png">
    <button
      class="save-png__button"
      type="button"
      @click="saveFile"
      @mouseenter="hasHover = true"
      @focus="hasHover = true"
      @mouseleave="hasHover = false"
      @blur="hasHover = false"
    >
      <svg class="save-png__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path
          fill="currentColor"
          d="M384 121.941V128H256V0h6.059a24 24 0 0 1 16.97 7.029l97.941 97.941a24.002 24.002 0 0 1 7.03 16.971zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zm-135.455 16c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.491-48 48-48zm208 240h-256l.485-48.485L104.545 328c4.686-4.686 11.799-4.201 16.485.485L160.545 368 264.06 264.485c4.686-4.686 12.284-4.686 16.971 0L320.545 304v112z"
        />
      </svg>
    </button>
    <p v-if="hasHover" class="save-png__tooltip">
      {{ $t('general.downloadPng') }}
    </p>
  </div>
</template>

<script>
import saveSvgAsPng from 'save-svg-as-png';
import helpers from '../helpers';

export default {
  name: 'SavePngButton',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: 'chart'
    }
  },
  data() {
    return {
      hasHover: false
    };
  },
  methods: {
    saveFile() {
      const chart = document.getElementById(this.id);
      saveSvgAsPng.saveSvgAsPng(chart.querySelector('svg'), `${this.getFilename()}.png`, { scale: 2 });
    },
    getFilename() {
      // return `${this.name}_${helpers.getTimestamp()}`;
      return `${this.name}`;
    }
  }
};
</script>

<style lang="scss">
.save-png {
  position: relative;
  z-index: 100;
}

.save-png__button {
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
  width: 30px;
  height: 30px;

  color: inherit;

  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    opacity: 0.65;
  }
}

.save-png__icon {
  width: auto;
  height: 100%;
}

.save-png__tooltip {
  position: absolute;
  top: 30px;
  left: 50%;

  margin: 0;
  padding: 5px;

  color: #444d58;

  background-color: white;
  text-align: center;
  border: 1px solid #94a0b2;

  transform: translateX(-50%);
}
</style>
